/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import scss from './Tabs.scss'
import Tab from './Tab'

import { generateRandomString } from '../../utilities/util'

const Tabs = React.memo(({ children, customClass }) => {
  const [activeTab, setActiveTab] = useState(null)
  const [newChildren, setNewChildren] = useState(null)
  const tabUUID = generateRandomString(8)
  // if children are updated, process children into newChildren
  useEffect(() => {
    if (!Array.isArray(children)) children = [children]
    setNewChildren(children)
  }, [children])

  // On initial load, set active tab to first tab
  useEffect(() => {
    if (!Array.isArray(children)) children = [children]
    setActiveTab(children[0].props.label)
  }, [])

  let directionClass = 'row'
  let tabDisplay = ''
  let tabContentMinHeight = ''

  if (!newChildren) return null

  return (
    <div className={[scss.tabs, scss[tabDisplay]].join(' ')}>
      <ol
        data-uuid={tabUUID}
        className={[scss.tabList, scss[directionClass], scss[customClass]].join(
          ' '
        )}
      >
        {newChildren.map(child => {
          if (!child) return null
          const {
            icon,
            label,
            useManageAttributesIcon,
            displayManageAttributesMenu,
            setDisplayManageAttributesMenu,
            setContextMenuY,
          } = child.props

          return (
            <Tab
              key={label}
              activeTab={activeTab}
              icon={icon}
              useManageAttributesIcon={useManageAttributesIcon}
              displayManageAttributesMenu={displayManageAttributesMenu}
              setDisplayManageAttributesMenu={setDisplayManageAttributesMenu}
              setContextMenuY={setContextMenuY}
              label={label}
              column={directionClass}
              customClass={customClass}
              onClick={tabLabel => {
                setActiveTab(tabLabel)
              }}
            />
          )
        })}
      </ol>
      <div className={[scss.tabContent, scss[tabContentMinHeight]].join(' ')}>
        {newChildren.map(child => {
          if (!child) return null
          if (child.props.label !== activeTab) return undefined
          return child.props.children
        })}
      </div>
    </div>
  )
})

export default Tabs
