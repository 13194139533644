/* eslint-disable react/jsx-filename-extension */
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext } from 'react-beautiful-dnd'

// utils
import { unVal, deepCopy } from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { setLayerVisibility } from '../../../utilities/mapStyle'
import { fromJS } from '../../../../node_modules/immutable'
import { getLayerConfigUsingTocId } from '../../../utilities/dataConfig'
import { apis } from '../../../config/apiConfig'
import { apiFetch } from '../../../utilities/api'
import {
  setToolConfig,
  updateMapStyle,
  toggleMapStyle,
  openNotes,
} from '../../../actions/index'

// components
import AddMapView from './AddMapView'
import MapViewList from './MapViewList'
import EditMapView from './EditMapView'
import SaveMapView from './SaveMapView'
import AddMapViewGroup from '../MapViewGroups/AddMapViewGroup'
import MapViewsActivateLayersModal from './MapViewsActivateLayersModal'
import MapViewsPermissionLayers from './MapViewsPermissionLayersModal'
import ZoomToPoint from '../../../components/ZoomToPoint/ZoomToPoint'

// scss files
import scss from './MapViews.scss'
import _ from 'lodash'

class MapViews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapViewGroup: null,
      collapsed: false,
      loading: false,
      mapViewName: '',
      modalState: null,
      mapViewNameError: '',
      mapViews: [],
      activeMapView: null,
      zoomToPointKey: null,
      mapviewEditId: null,
      mountKey: 1,
      saveMapViewKey: null,
      addModalState: null,
      viewList: null,
      fetchObjects: null,
      permissionModal: false,
      inactiveModal: false,
      inactiveHasBeenDisplayed: false,
      permissionHasBeenDisplayed: false,
    }
  }

  componentDidMount = () => {
    this.updateMapViews()
  }


  handleInactiveModalClose = () => {
    this.setState({ inactiveModal: false })
    this.setState({ inactiveHasBeenDisplayed: true })
    if (
      !this.state.permissionHasBeenDisplayed &&
      this.state.needPermissionLayers.length > 0
    ) {
      this.setState({ permissionModal: true })
    }
  }

  handlePermissionModalClose = () => {
    this.setState({ permissionModal: false })
    this.setState({ permissionHasBeenDisplayed: true })
  }

  processViews = clonedMapviews => {
    if (clonedMapviews) {
      let groupsArray = clonedMapviews.filter(view => view.groupId)
      let viewsArray = clonedMapviews.filter(view => !view.groupId)

      groupsArray.forEach(group => {
        group.children.layerIds.forEach(id => {
          viewsArray = viewsArray.filter(view => view.id !== id)
        })
        group.children.groupIds.forEach(id => {
          groupsArray = groupsArray.filter(group => group.id !== id)
        })
      })

      return clonedMapviews.filter(view => {
        if (groupsArray.includes(view)) {
          return true
        }
        if (viewsArray.includes(view)) {
          return true
        }
        return false
      })
    }
    return {}
  }

  onDragEnd = result => {
    const { currentOrder } = this.state

    const { source, destination, draggableId } = result

    if (!destination) return
    if (destination.index === source.index) return

    const currentListOrder = currentOrder

    const array_move = (arr, old_index, new_index) => {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    }

    const array_add = (arr, new_index, new_value) => {
      arr.splice(new_index, 0, new_value)
    }

    const array_remove = (arr, index) => {
      arr.splice(index, 1)
    }

    const findMovingItem = (currentOrder, sourceId, targetId) => {
      console.log('currentOrder', currentOrder)
      const result = currentOrder.forEach(obj => {
        let f
        if (obj.id === sourceId) {
          console.log('SUCCESS', targetId)
          f = obj.children.order.filter(item => item.id === targetId)
        } else if (obj.children)
          findMovingItem(obj.children.order, sourceId, targetId)
        return f
      })
      return result
    }

    // ====================================================
    // Moving and order logic
    // ====================================================

    if (result.type === 'root') {
      array_move(currentListOrder, source.index, destination.index)
    } else {
      // ? moving inside group
      if (destination.droppableId === source.droppableId) {
        currentListOrder.forEach(obj => {
          if (obj.id === destination.droppableId) {
            const { order } = obj.children
            array_move(order, source.index, destination.index)
          }
        })
      }

      // ? moving from group to group
      if (destination.droppableId !== source.droppableId) {
        // find moving item

        const movingItem = findMovingItem(
          currentListOrder,
          source.droppableId,
          draggableId
        )
        console.log('currentListOrder', currentListOrder)
        console.log('movingItem', movingItem)

        // delete item from source list and add to destination list
        currentListOrder.forEach(obj => {
          if (obj.id === source.droppableId) {
            const { order } = obj.children
            array_remove(order, source.index)
          }

          if (obj.id === destination.droppableId) {
            const { order } = obj.children
            array_add(order, destination.index, movingItem)
          }
        })
      }

      /*
      !FUTURE LOGIC FOR NESTED GROUP MOVEMENT, AS OF NOW NOT SUPPORTED THROUGH DnD LIBRARY
      
      // ? move from default level into group
      if (
        source.droppableId === "mapviewDroppable" &&
        destination.droppableId !== 'mapviewDroppable'
      ) {
        console.log('Moving from default to group')

        // find moving item
        const movingItem = currentListOrder.filter(item => item.id === draggableId)

        // find destination and update
        currentListOrder.forEach(obj => {
          if (obj.id === destination.droppableId) {
            const { order } = obj.children
            array_add(order, destination.index, movingItem[0])
          }
        })

        // update root list
        currentListOrder = currentListOrder.filter(obj => obj.id !== draggableId)
        console.log('finished listorder', currentListOrder)
      }

      // ? move from a group into default level
      if (
        source.droppableId !== 'mapviewDroppable' &&
        destination.droppableId === 'mapviewDroppable'
      ) {
        console.log('Moving from group to default')

        // find moving item
        console.log('currentListOrder', currentListOrder)
        let movingItem
        currentListOrder.forEach(obj => {
          if (obj.id === source.droppableId) {
            movingItem = obj.children.order.filter(item => item.id === draggableId)
          }
        })
        console.log('movingItem', movingItem[0])

        // delete item from source list
        currentListOrder.forEach(obj => {
          if (obj.id === source.droppableId) {
            const { order } = obj.children
            array_remove(order, source.index)
          }
        })

        // update root list
        array_add(currentListOrder, destination.index, movingItem[0])
      } */
    }

    // * Finish Logic

    const orderPayload = []

    currentListOrder.forEach((viewObj, index) => {
      const groupChildren = []
      const orderIds = []

      if (viewObj.children) {
        // if (viewObj.children.layerIds.length) {
        //   viewObj.children.layerIds.forEach(childMapview => {
        //     groupChildren.push({ mapview: childMapview })
        //   })
        // }

        // if (viewObj.children.groupIds.length) {
        //   viewObj.children.groupIds.forEach(childGroup => {
        //     groupChildren.push({ group: childGroup })
        //   })
        // }

        if (viewObj.children.order.length) {
          viewObj.children.order.forEach(obj => {
            if (obj != null) {
              orderIds.push(obj.id)
              if (obj.id.substr(0, 6) === 'group_') {
                groupChildren.push({ group: obj.id })
              } else {
                groupChildren.push({ mapview: obj.id })
              }
            }
          })
        }

        orderPayload.push({
          group: viewObj.id,
          children: groupChildren,
        })
      } else {
        orderPayload.push({ mapview: viewObj.id })
      }
    })

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'mapviews/order'
    const body = { order: orderPayload }

    const newState = {
      ...this.state,
      currentOrder: currentListOrder,
      fetchObjects: [{ url, method, body }],
    }

    const newKey = this.state.mountKey + 1
    this.setState({ mountKey: newKey })
    this.setState(newState)
  }

  fetchFinished = () => {
    this.setState({ fetchObjects: null })
  }

  updateMapViews = () => {
    const method = 'GET'
    const url =
      apis.apiDatabase.uri + 'mapviews/user?mapID=' + this.props.user.mapID
    const bodyParams = {}

    this.setState({ loading: true }, () => {
      apiFetch(url, method, bodyParams, result => {
        const mapViews = result.data
        const currentOrder = this.processViews(mapViews)

        const newCurrentOrder = this.processState(currentOrder, mapViews)

        let needActivateLayers = mapViews.filter(
          mapview => mapview.needActivationLayers
        )

        let needPermissionLayers = mapViews.filter(
          mapview => mapview.needPermissionLayers
        )

        // need activate layers and permission may be nested
        needActivateLayers = needActivateLayers[0].needActivationLayers
          ? needActivateLayers[0].needActivationLayers
          : []

        needPermissionLayers = needPermissionLayers[0].needPermissionLayers
          ? needPermissionLayers[0].needPermissionLayers
          : []

        if (needActivateLayers.length > 0) {
          this.setState({ inactiveModal: true })
        } else if (needPermissionLayers.length > 0) {
          this.setState({ permissionModal: true })
        }

        this.setState({
          mapViews,
          currentOrder: newCurrentOrder,
          loading: false,
          needActivateLayers,
          needPermissionLayers,
        })
      })
    })
  }

  processState = (currentOrder, mapViews) => {
    if (typeof currentOrder !== 'object' || !Array.isArray(currentOrder))
      return []

    currentOrder.forEach(obj => {
      const newOrder = []
      const views = []

      if (typeof obj === 'object' && obj.children) {
        obj.children.groupIds.forEach(id => views.push(id))
        obj.children.layerIds.forEach(id => views.push(id))

        if (obj.children.order && obj.children.order.length) {
          let unprocessedChildren = views
          if (views.length) {
            obj.children.order.forEach(orderID => {
              if (views.includes(orderID)) {
                unprocessedChildren = unVal(unprocessedChildren, orderID)

                const viewObj = mapViews.filter(view => view.id === orderID)
                newOrder.push(viewObj[0])
              }
            })
          }

          // Process the rest of them that were not in the order array.
          if (unprocessedChildren.length) {
            unprocessedChildren.forEach(view => {
              const viewObj = mapViews.filter(mapview => mapview.id === view)
              newOrder.push(viewObj[0])
            })
          }
        } else {
          views.forEach(view => {
            const viewObj = mapViews.filter(mapview => mapview.id === view)
            newOrder.push(viewObj[0])
          })
        }
        obj.children.order = newOrder
        this.processState(obj.children.order, mapViews)
      }
    })
    return currentOrder
  }

  closeTool = () => {
    const { toolConfig } = this.props
    const newConfig = deepCopy(toolConfig)
    newConfig.forEach(tool => {
      if (tool.name === config.name) {
        tool.visible = false
      }
    })

    this.props.setToolConfig(newConfig)
    let newKey = this.state.mountKey
    newKey++
    this.setState({ mountKey: newKey })
  }

  setToolCollapsed = collapsed => {
    const { onCollapse, config } = this.props
    onCollapse(config.name, collapsed)
  }

  doZoomToPoint = location => {
    let { zoomToPointKey } = this.state
    if (!zoomToPointKey) zoomToPointKey = 0
    zoomToPointKey++
    const zoomToCoordinates = [location.longitude, location.latitude]
    const newZoom = location.zoom
    this.setState({ zoomToPointKey, zoomToCoordinates, newZoom })
  }

  clearMapLayers = (style, dataConfig) => {
    let layerIds = []
    dataConfig.tocLayers.forEach(config => {
      if (config.children) {
        config.children.items.forEach(childObj => {
          if (childObj.layersArray) {
            const layers = []
            childObj.layersArray.forEach(layerObj => {
              layers.push(layerObj.layer.id)
              if (layerObj.outline) layers.push(layerObj.outline.id)
              if (layerObj.symbol) layers.push(layerObj.symbol.id)
            })
            layerIds = [...layerIds, ...layers]
          }
        })
      }
      if (config.layersArray) {
        const layers = []
        config.layersArray.forEach(layerObj => {
          layers.push(layerObj.layer.id)
          if (layerObj.outline) layers.push(layerObj.outline.id)
          if (layerObj.symbol) layers.push(layerObj.symbol.id)
        })
        layerIds = [...layerIds, ...layers]
      }
    })

    dataConfig.imageryLayers
      .filter(config => config.layersArray)
      .forEach(config => {
        const layers = config.layersArray.map(layerObj => layerObj.layer.id)
        layerIds = [...layerIds, ...layers]
      })
    layerIds.forEach(layerId => {
      style = setLayerVisibility(style, layerId, 'none')
    })
    return style
  }

  // This needs to be rework at a point where every layer not activated for the user,
  // it must load it automatically
  toggleMapViewLayers = (style, dataConfig, visibleTocLayers, visible) => {
    if (visibleTocLayers) {
      let visibleLayers = []
      visibleTocLayers.forEach(visibleLayer => {
        const layer = getLayerConfigUsingTocId(dataConfig, visibleLayer)
        const layers = []
        if (layer && layer.layersArray) {
          layer.layersArray.forEach(layerObj => {
            layers.push(layerObj.layer.id)
            if (layerObj.outline) layers.push(layerObj.outline.id)
            if (layerObj.symbol) layers.push(layerObj.symbol.id)
          })
          visibleLayers = [...visibleLayers, ...layers]
        }
      })
      visibleLayers.forEach(layerId => {
        style = setLayerVisibility(style, layerId, visible)
      })
    } else console.warn('Visible Toc Layers not found:', visibleTocLayers)
    return style
  }

  toggleImageryLayers = (style, dataConfig, visibleImageryLayers, visible) => {
    let visibleLayers = []
    dataConfig
      .filter(config => config.layersArray)
      .forEach(config => {
        const layers = config.layersArray.map(layerObj => layerObj.layer.id)
        if (visibleImageryLayers.includes(config.toc.id)) {
          visibleLayers = [...visibleLayers, ...layers]
        }
      })
    visibleLayers.forEach(layerId => {
      style = setLayerVisibility(style, layerId, visible)
    })
    return style
  }

  // openDeleteMapView = mapViewID => this.setState({ mapviewDeleteId: mapViewID });
  // closeMapViewDelete = () => this.setState({ mapviewDeleteId: null });
  openEditMapView = mapViewID =>
    this.setState({ mapviewEditId: mapViewID, editNotesActive: mapViewID })

  closeMapViewEdit = () =>
    this.setState({ mapviewEditId: null, editNotesActive: false })

  openEditSettings = mapViewID => {
    const { toolConfig } = this.props
    toolConfig.forEach(tool => {
      if (tool.name === 'MapViewSettings') {
        tool.visible = true
        this.setState({ editMapViewActive: true })
      }
    })
  }

  saveMapView = (mapViewName, mapViewGroup) => {
    if (mapViewName === '') {
      this.setState({ mapViewNameError: 'error' })
      return
    }

    this.setState({
      saveMapViewKey: 'saveMapView',
      mapViewName,
      mapViewGroup,
      doingSave: true,
    })
  }

  setMapView = id => {
    const { mapViews, activeMapView } = this.state
    const { mapStyle, dataConfig } = this.props
    let style = mapStyle.toJS()
    const mapView = mapViews.filter(mapView => mapView.id === id)
    style = this.clearMapLayers(style, dataConfig)
    if (activeMapView && activeMapView.id === id) {
      // TOGGLE MAP VIEW OFF
      style = this.toggleMapViewLayers(
        style,
        dataConfig,
        mapView[0].visibleLayers,
        'none'
      )
      style = this.toggleImageryLayers(
        style,
        dataConfig.imageryLayers,
        mapView[0].visibleImagery,
        'none'
      )
      this.setState({ activeMapView: null })
      this.props.openNotes(null)

      // Update Style and mapStyle back to default
      this.props.updateMapStyle(fromJS(style))
      this.props.toggleMapStyle('basic')
    } else {
      // TOGGLE MAP VIEW ON
      this.doZoomToPoint(mapView[0].location)
      style = this.toggleMapViewLayers(
        style,
        dataConfig,
        mapView[0].visibleLayers,
        'visible'
      )
      style = this.toggleImageryLayers(
        style,
        dataConfig.imageryLayers,
        mapView[0].visibleImagery,
        'visible'
      )
      this.setState({ activeMapView: mapView[0] })
      let notesVisible = false
      if (mapView[0].notes) {
        this.props.openNotes(mapView[0])
        notesVisible = true
      }
      const { toolConfig } = this.props
      toolConfig.forEach(tool => {
        if (tool.name === 'Notes') {
          tool.visible = notesVisible
        }
      })

      // to maintain immutable redux state we need to clone toolConfig and send cloned to reducer
      const config = JSON.parse(JSON.stringify(toolConfig))
      this.props.setToolConfig(config)

      //Update Style & set mapStyle
      this.props.updateMapStyle(fromJS(style))
      this.props.toggleMapStyle(mapView[0].activeMapStyle)
    }
  }

  handleInputChange = e => {
    this.setState({ mapViewName: e.target.value })
  }

  closeTool = () => {
    const { toolConfig } = this.props
    this.props.setToolConfig(_.cloneDeep(toolConfig))
    let newKey = this.state.mountKey
    newKey++
    this.setState({ mountKey: newKey })
  }

  setToolCollapsed = collapsed => {
    const { onCollapse, config } = this.props
    onCollapse(config.name, collapsed)
    this.setState({ collapsed })
  }

  render() {
    const {
      mountKey,
      zoomToPointKey,
      zoomToCoordinates,
      newZoom,
      // mapviewDeleteId,
      mapviewEditId,
      saveMapViewKey,
      mapViews,
      mapViewGroup,
      mapViewName,
      fetchObjects,
    } = this.state
    const { mapStyle, viewport, dataConfig, addVisible, addGroup } = this.props

    return (
      <div>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={this.fetchFinished}
          />
        )}
        <ZoomToPoint
          key={zoomToPointKey + '_zoomToPoint'}
          viewport={viewport}
          coordinates={zoomToCoordinates}
          zoom={newZoom}
        />
        {addVisible && (
          <AddMapView
            mapViews={mapViews}
            mapViewGroups={this.state.mapViewGroups}
            onSave={this.saveMapView}
            onClose={() => {
              this.props.onModalClose()
            }}
          />
        )}
        {addGroup && (
          <AddMapViewGroup
            key={addGroup}
            mountKey={this.props.mountKey}
            onClose={newKey => {
              this.setState({ mountKey: newKey })
              this.props.onModalClose(newKey)
            }}
          />
        )}
        {saveMapViewKey && (
          <SaveMapView
            key={saveMapViewKey}
            mapViews={mapViews}
            mapStyle={mapStyle}
            mapViewName={mapViewName}
            mapViewGroup={mapViewGroup}
            viewport={viewport}
            dataConfig={dataConfig}
            mountKey={mountKey}
            onFinish={mountKey => {
              this.setState({ saveMapViewKey: null, mountKey })
              this.updateMapViews()
            }}
          />
        )}
        {mapviewEditId && (
          <EditMapView
            key={mapviewEditId}
            mapViewId={mapviewEditId}
            mapViews={mapViews}
            onClose={this.closeMapViewEdit}
            onFinish={() => this.updateMapViews()}
            mountKey={this.props.mountKey}
          />
        )}
        {this.state.loading ? (
          <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
        ) : (
          <div className={scss.toolGroup}>
            {this.state.mapViews.length <= 2 ? (
              <div>No Map Views were found. </div>
            ) : null}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <MapViewList
                mountKey={this.state.mountKey}
                mapViews={this.state.mapViews}
                currentOrder={this.state.currentOrder}
                closeTool={this.closeTool}
                updateMapViews={this.updateMapViews}
                // mapViewGroups={mapViewGroups}
                activeMapView={this.state.activeMapView}
                editNotesActive={this.state.editNotesActive}
                editMapViewActive={this.state.editMapViewActive}
                setMapView={this.setMapView}
                editSettings={this.openEditSettings}
                editMapView={this.openEditMapView}
                deleting={this.state.deleting}
                sendViewList={viewList => {
                  this.setState({ viewList })
                }}
              />
            </DragDropContext>
          </div>
        )}

        {this.state.permissionModal ? (
          <MapViewsPermissionLayers
            needPermissionLayers={this.state.needPermissionLayers}
            onHandleClose={this.handlePermissionModalClose}
          />
        ) : null}

        {this.state.inactiveModal ? (
          <MapViewsActivateLayersModal
            needActivationLayers={this.state.needActivateLayers}
            mapID={this.props.user.mapID}
            onFinish={() => {
              this.updateMapViews()
            }}
            onHandleClose={this.handleInactiveModalClose}
          />
        ) : null}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToolConfig,
      updateMapStyle,
      toggleMapStyle,
      openNotes,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    mountKey: state.mountMapViews,
    toolConfig: state.toolConfig,
    user: state.user,
    mapStyle: state.mapStyle,
    viewport: state.viewport,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViews)
