import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GeoCode from './GeoCode/GeoCode'
import Coordinate from './Coordinate/Coordinate'
import ReactTooltip from 'react-tooltip'

import appScss from '../../App/App.scss'
import scss from './Search.scss'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'GEOCODE',
      collapsed: true,
    }
  }

  toggleSearchMode = mode => {
    this.setState({ mode: mode })
  }

  getSearchUi = () => {
    const { mode } = this.state
    const { mapStyle, viewport } = this.props
    const geoCodeActive = mode === 'GEOCODE' ? 'active' : ''
    const cordinateActive = mode === 'COORDINATE' ? 'active' : ''
    const plssActive = mode === 'PLSS' ? 'active' : ''
    return (
      <div>
        <div
          className={[scss.activeSearchButtonsContainer, scss.addOnGroup].join(
            ' '
          )}
          onMouseEnter={this.props.disableMap}
          onMouseLeave={this.props.enableMap}
        >
          <button
            name='GEOCODE'
            onClick={() => this.toggleSearchMode('GEOCODE')}
            className={[scss.btn, scss[geoCodeActive]].join(' ')}
            data-tip='Address'
            data-for='geocode'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <FontAwesomeIcon icon={['fas', 'map-marked-alt']} size='sm' />
          </button>
          <div className={appScss.reactToolTip}>
            <ReactTooltip id='geocode' getContent={dataTip => `${dataTip}`} />
          </div>
          <button
            name='COORDINATE'
            onClick={() => this.toggleSearchMode('COORDINATE')}
            className={[scss.btn, scss[cordinateActive]].join(' ')}
            data-tip='Latitude and Longitude'
            data-for='coordinate'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <FontAwesomeIcon icon={['fal', 'globe']} size='sm' />
          </button>
          <div className={appScss.reactToolTip}>
            <ReactTooltip
              id='coordinate'
              getContent={dataTip => `${dataTip}`}
            />
          </div>
        </div>
        <div
          className={scss.mapTopControlGroup}
          onMouseEnter={this.props.disableMap}
          onMouseLeave={this.props.enableMap}
        >
          <div className={scss.activeSearchContainer}>
            {mode === 'GEOCODE' && (
              <GeoCode
                mapStyle={mapStyle}
                viewport={viewport}
                enableMap={this.props.enableMap}
                disableMap={this.props.disableMap}
              />
            )}
            {mode === 'COORDINATE' && (
              <Coordinate
                mapStyle={mapStyle}
                viewport={viewport}
                enableMap={this.props.enableMap}
                disableMap={this.props.disableMap}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
  render() {
    let ui = (ui = this.getSearchUi())
    return ui
  }
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
    viewport: state.viewport,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
