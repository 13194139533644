/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './ContextMenuStyle.scss'
import * as ContextMenuUtil from './ContextMenuUtil'


const ContextMenu = ({
  x,
  y,
  row,
  setTriggerRefresh,
  setGenerateNewFileTree,
  setRenamePopupVisible
}) => {
  const style = { top: y + 'px', left: x + 'px' }

  const handleEraseElement = () => {
    ContextMenuUtil.eraseFolder(row.original.awsPath)
    setTriggerRefresh(true)
    setGenerateNewFileTree(true)
  }

  const displayRenamePopup = () => {
    setRenamePopupVisible(true)
  }

  return (
    <>
      <div className={scss.ContextMenu} style={style}>
        <button
          type='button'
          className={scss.ButtonStyle}
          onClick={displayRenamePopup}
        >
          <FontAwesomeIcon
            icon='pencil'
            style={{ color: 'white', marginRight: '10px' }}
          />
          Rename
        </button>
        <button
          type='button'
          className={scss.ButtonStyle}
          onClick={() => {
            handleEraseElement(row)
          }}
        >
          <FontAwesomeIcon
            icon='trash-alt'
            style={{ color: 'white', marginRight: '10px' }}
          />
          Delete
        </button>
      </div>
    </>
  )
}

export default ContextMenu
