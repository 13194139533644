import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as utils from '../../../utilities/util'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import { MuiButton } from '../../../components/MaterialUi/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  userID,
  firstName,
  lastName,
  title,
  emailAddress,
  userRole,
  visible,
}) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [firstNameState, setFirstName] = useState(
    firstName == null ? '' : firstName
  )
  const [lastNameState, setLastName] = useState(
    lastName == null ? '' : lastName
  )
  const [titleState, setTitle] = useState(title == null ? '' : title)
  const [emailAddressState, setEmailAddress] = useState(
    emailAddress == null ? '' : emailAddress
  )
  const [userRoleState, setRole] = useState(userRole == null ? false : userRole)

  // AsyncFetch state variables
  const [userAdd, setUserAdd] = useState(null)
  const [accountAdd, setAccountAdd] = useState(null)
  const [accountFinal, setAccountFinal] = useState(null)
  const [updateObject, setUpdateObject] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  // ==========================================================================
  // ASYNCFETCH SETUP
  // ==========================================================================
  // This loops through buildFetchParams --> AsyncFetch --> fetchFinished
  // Starting with a call to handleSave()

  let currentResults = {}

  const buildFetchParams = id => {
    if (userID == null) {
      var newUserID

      if (id === 'user') {
        const method = 'POST'
        const url = apis['apiDatabase'].uri + 'user/add'
        const body = {
          firstName: firstNameState,
          lastName: lastNameState,
          title: titleState,
          emailAddress: emailAddressState,
        }

        setFetching(true)
        setUserAdd([{ url, method, body }])
      }

      if (id === 'account') {
        if (utils.verifyResult(currentResults)) {
          newUserID = utils.resultReturn(currentResults)
          const method = 'POST'
          const url = apis['apiDatabase'].uri + 'account/user/add'
          const body = {
            accountID: user.accountID,
            userID: newUserID,
          }

          setAccountAdd([{ url, method, body }])
        }
      }

      if (id === 'final') {
        if (utils.verifyResult(currentResults)) {
          const method = 'POST'
          const url = apis['apiDatabase'].uri + 'account/user/role'
          const body = {
            accountID: user.accountID,
            userID: newUserID,
            isAdmin: userRoleState,
          }
          setAccountFinal([{ url, method, body }])
        }
      }
    } else {
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'user/update'
      const body = {
        userID,
        firstName: firstNameState,
        lastName: lastNameState,
        title: titleState,
        // emailAddress: emailAddressState,
      }

      setFetching(true)
      setUpdateObject([{ url, method, body }])
    }
  }

  const updateFinish = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      setUpdateObject(null)
      handleOnClose()
    })
  }

  const fetchFinished = results => {
    return results.map(result => {
      if (userAdd) {
        currentResults = result
        setUserAdd(null)
        buildFetchParams('account')
      }

      if (accountAdd) {
        currentResults = result
        setAccountAdd(null)
        buildFetchParams('final')
      }

      if (accountFinal) {
        refreshDetails(user, newUser => {
          dispatch({ type: 'REFRESH', payload: newUser })
          handleOnClose()

          // reset all
          currentResults = {}
          setAccountFinal(null)
        })
      }
    })
  }

  const handleSave = () => {
    buildFetchParams('user')
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const handleFirstName = e => setFirstName(e.target.value)
  const handleLastName = e => setLastName(e.target.value)
  const handleUserTitle = e => setTitle(e.target.value)
  const handleEmail = e => setEmailAddress(e.target.value)
  const handleRole = e => setRole(e.target.value)

  //  const changeRole = (userRole) => {

  //   if (userRole == true) {
  //     roleBtn = <select
  //         title="Select a role"
  //         className={scss.selectRole}
  //         onChange={handleRole}
  //         value={u.isAdmin ? 'Admin' : 'User'}
  //       >
  //         <option key={"defaul"} value={"default"}>
  //           {u.isAdmin ? 'Admin' : 'User'}
  //         </option>
  //         <option
  //           // key={role.id}
  //           value={true}
  //         >
  //           Admin
  //         </option>
  //         <option
  //           // key={role.id}
  //           value={false}
  //         >
  //           Full User
  //         </option>
  //       </select>
  //   } else {
  //     roleBtn = <select
  //         title="Select a role"
  //         className={scss.selectRole}
  //         onChange={handleRole}
  //         value={userRole}
  //       >
  //         <option key={"defaul"} value={userRole}>
  //           Full User
  //         </option>
  //       </select>
  //   }

  //  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {userAdd && (
        <AsyncFetch fetchObjects={userAdd} fetchFinished={fetchFinished} />
      )}
      {accountAdd && (
        <AsyncFetch fetchObjects={accountAdd} fetchFinished={fetchFinished} />
      )}
      {accountFinal && (
        <AsyncFetch fetchObjects={accountFinal} fetchFinished={fetchFinished} />
      )}
      {updateObject && (
        <AsyncFetch fetchObjects={updateObject} fetchFinished={updateFinish} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-user-dialog'
        open={open}
      >
        <DialogTitle id='add-user-dialog'>
          {userID == null ? 'Add New' : 'Edit'} User
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <MuiFormControlText
            disabled={false}
            label='First Name'
            onChange={handleFirstName}
            onSubmit={fetching ? null : handleSave}
            value={firstNameState}
          />
          <MuiFormControlText
            disabled={false}
            label='Last Name'
            onChange={handleLastName}
            onSubmit={fetching ? null : handleSave}
            value={lastNameState}
          />
          <MuiFormControlText
            disabled={false}
            label='Title'
            onChange={handleUserTitle}
            onSubmit={fetching ? null : handleSave}
            value={titleState}
          />
          <MuiFormControlText
            disabled={userID ? true : false}
            label='Email'
            onChange={handleEmail}
            onSubmit={fetching ? null : handleSave}
            value={emailAddressState}
          />

          {!userID && (
            <select
              title='Select a role'
              className={scss.selectRole}
              onChange={handleRole}
              defaultValue={userID ? userRole : false}
            >
              <option value={true}>Admin</option>
              <option value={false}>Full User</option>
            </select>
          )}
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={scss.sendButton}
            // disabled={!enableSave}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : userID == null ? (
              'Send Invite'
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AddUserModal(props) {
  return <SimpleDialog {...props} />
}
