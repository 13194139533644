import React, { PureComponent } from 'react'
import { get } from 'lodash'
import scss from './ElementStyle.scss'
import checkboxScss from '../../../CSSModules/Checkbox.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlockPicker } from 'react-color'
import myAssetMapColors from '../../../../utilities/componentConstants/myAssetMapColors.js'
import ElementOrder from './ElementOrder'

const Bold = props => {
  return (
    <svg
      {...props}
      aria-hidden='true'
      data-prefix='fas'
      data-icon='bold'
      className='prefix__svg-inline--fa prefix__fa-bold prefix__fa-w-12'
      viewBox='0 0 384 512'
      width='0.875em'
      height='0.875em'
    >
      <path
        fill='currentColor'
        d='M333.49 238a122 122 0 0027-65.21C367.87 96.49 308 32 233.42 32H34a16 16 0 00-16 16v48a16 16 0 0016 16h31.87v288H34a16 16 0 00-16 16v48a16 16 0 0016 16h209.32c70.8 0 134.14-51.75 141-122.4 4.74-48.45-16.39-92.06-50.83-119.6zM145.66 112h87.76a48 48 0 010 96h-87.76zm87.76 288h-87.76V288h87.76a56 56 0 010 112z'
      />
    </svg>
  )
}
const Italic = props => {
  return (
    <svg
      {...props}
      aria-hidden='true'
      data-prefix='fas'
      data-icon='italic'
      className='prefix__svg-inline--fa prefix__fa-italic prefix__fa-w-10'
      viewBox='0 0 320 512'
      width='0.875em'
      height='0.875em'
    >
      <path
        fill='currentColor'
        d='M320 48v32a16 16 0 01-16 16h-62.76l-80 320H208a16 16 0 0116 16v32a16 16 0 01-16 16H16a16 16 0 01-16-16v-32a16 16 0 0116-16h62.76l80-320H112a16 16 0 01-16-16V48a16 16 0 0116-16h192a16 16 0 0116 16z'
      />
    </svg>
  )
}
const Underline = props => {
  return (
    <svg
      {...props}
      aria-hidden='true'
      data-prefix='fas'
      data-icon='underline'
      className='prefix__svg-inline--fa prefix__fa-underline prefix__fa-w-14'
      viewBox='0 0 448 512'
      width='0.875em'
      height='0.875em'
    >
      <path
        fill='currentColor'
        d='M32 64h32v160c0 88.22 71.78 160 160 160s160-71.78 160-160V64h32a16 16 0 0016-16V16a16 16 0 00-16-16H272a16 16 0 00-16 16v32a16 16 0 0016 16h32v160a80 80 0 01-160 0V64h32a16 16 0 0016-16V16a16 16 0 00-16-16H32a16 16 0 00-16 16v32a16 16 0 0016 16zm400 384H16a16 16 0 00-16 16v32a16 16 0 0016 16h416a16 16 0 0016-16v-32a16 16 0 00-16-16z'
      />
    </svg>
  )
}
/**
 * @description ElementStyle component
 * @param changeStyle(object)		// function for change style of edit element, object - data of object with new style
 * @param object								// edit element
 */

export class ElementStyle extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showBackgroundPicker: false,
      showTextPicker: false,
      showBorderPicker: false,
    }
  }

  handleColorPicker = pickerType => {
    // Shows or Hides Color Picker
    this.setState(prevState => ({
      [`show${pickerType}Picker`]: !prevState[`show${pickerType}Picker`],
    }))
  }

  /**
   * @description Set style property for components:
   * All elements: 	borderColor	borderWidth borderTransparent
   * Text area elements addition props:	bgColor	fontSize fontFamily	textColor opacity
   * @param e onChange event
   * @param property - name of object property
   */
  setProperty = property => e => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, [`${property}`]: e.target.value }
    changeStyle(newObject)
  }
  setColorProperty = (color, property) => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, [`${property}`]: color }
    changeStyle(newObject)
  }

  /**
   * @description Set Text Horizontal Align
   * @param value - flex-start || center || flex-end
   */
  setTextHorizontalAlign = value => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, horizontalAligment: value }
    changeStyle(newObject)
  }

  /**
   * @description Set Text Horizontal Align
   * @param value - flex-start || center || flex-end
   */
  setTextVerticalAlign = value => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, verticalAligment: value }
    changeStyle(newObject)
  }
  /**
   * @description Set Font Decoration
   * @param value - bold || italic || underline
   */
  setFontDecoration = value => {
    const { object, changeStyle } = this.props
    let newObject = {}
    if (value === 'bold') {
      value = get(object, 'fontWeight', '') === 'normal' ? 'bold' : 'normal'
      newObject = { ...object, fontWeight: value }
    }
    if (value === 'italic') {
      value = get(object, 'fontStyle', '') === 'normal' ? 'italic' : 'normal'
      newObject = { ...object, fontStyle: value }
    }
    if (value === 'underline') {
      value =
        get(object, 'textDecoration', '') === 'none' ? 'underline' : 'none'
      newObject = { ...object, textDecoration: value }
    }
    changeStyle(newObject)
  }

  /**
   * @description Set Text Background Transparent
   * @param e - onChange event for checkBox
   */
  setBgTransparent = e => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, bgTransparent: !e.target.checked }
    changeStyle(newObject)
  }

  /**
   * @description Set Border Background Transparent
   * @param e - onChange event for checkBox
   */
  setBorderTransparent = e => {
    const { object, changeStyle } = this.props
    const newObject = { ...object, borderTransparent: !e.target.checked }
    changeStyle(newObject)
  }

  /**
   * @description Saving position changes of Edit Tool element
   * @param d -  DraggableData,
   */
  handleEditDragStop = d => {
    const { setEditToolPosition } = this.props
    const newToolPosition = { x: d.x, y: d.y }
    setEditToolPosition(newToolPosition)
  }

  render() {
    const { object } = this.props
    if (!object) return null
    return (
      <>
        <div className={[scss.container]}>
          <div className={scss.toolExpanded}>
            <div className={[scss.toolBody, 'edit'].join(' ')}>
              <div className={scss.printFlyoutFont}>
                {/* CHECK IF THE ELEMENT IS TEXT OR DATE, THEN DISPLAY THE FULL PRINT FLYOUT OPTIONS */}
                {(object.type === 'text' || object.type === 'date') && (
                  <>
                    <div className={scss.styleHeading}>Text</div>

                    <div className={scss.printFlyoutFontRow}>
                      <div
                        className={scss.inputContainer}
                        style={{ width: '100%' }}
                      >
                        <select
                          className={scss.printFullInput}
                          onChange={this.setProperty('fontFamily')}
                          value={object.fontFamily}
                        >
                          <option value='Gilroy'>Gilroy</option>
                          <option value='Montserrat'>Montserrat</option>
                          <option value='open sans'>Open Sans</option>
                        </select>
                      </div>
                    </div>

                    <div className={scss.printFlyoutFontRow}>
                      <div className={scss.inputContainer}>
                        <select
                          className={scss.printNumber}
                          onChange={this.setProperty('fontWeight')}
                          value={object.fontWeight}
                        >
                          <option value='200'>Thinner</option>
                          <option value='300'>Thin</option>
                          <option value='normal'>Regular</option>
                          <option value='bold'>Bold</option>
                          <option value='800'>Bolder</option>
                        </select>
                      </div>
                      <div
                        className={scss.inputContainer}
                        style={{ marginLeft: '8px' }}
                      >
                        <input
                          className={scss.printNumber}
                          type='number'
                          onChange={this.setProperty('fontSize')}
                          value={object.fontSize}
                        />
                      </div>
                    </div>

                    <div className={scss.printFlyoutFontRow}>
                      <div className={scss.inputContainer}>
                        <div>
                          <input
                            className={scss.printColorSmall}
                            type='color'
                            value={object.textColor}
                            onClick={() => this.handleColorPicker('Text')} // <--- Open/hide on click
                            onFocus={() => this.handleColorPicker('Text')} //<--- Open on focus
                          />
                          {this.state.showTextPicker ? (
                            <div className={scss.colorPopover}>
                              <div
                                className={scss.colorCover}
                                onClick={() => this.handleColorPicker('Text')}
                              />
                              <BlockPicker
                                colors={myAssetMapColors}
                                triangle='hide'
                                type='color'
                                color={object.textColor}
                                onChangeComplete={color =>
                                  this.setColorProperty(color.hex, 'textColor')
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className={scss.inputContainer}>
                        <div className={scss.addOnGroup}>
                          <button
                            className={scss.printButton}
                            onClick={() => this.setFontDecoration('bold')}
                          >
                            <Bold />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() => this.setFontDecoration('italic')}
                          >
                            <Italic />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() => this.setFontDecoration('underline')}
                          >
                            <Underline />
                          </button>
                        </div>
                      </div>

                      <div className={scss.inputContainer}>
                        <div className={scss.addOnGroup}>
                          <button
                            className={scss.printButton}
                            onClick={() =>
                              this.setTextHorizontalAlign('flex-start')
                            }
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'align-left']}
                              size='1x'
                            />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() =>
                              this.setTextHorizontalAlign('center')
                            }
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'align-center']}
                              size='1x'
                            />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() =>
                              this.setTextHorizontalAlign('flex-end')
                            }
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'align-right']}
                              size='1x'
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={scss.printFlyoutFontRow}>
                      <div className={scss.inputContainer}>
                        <div className={scss.addOnGroup}>
                          <button
                            className={scss.printButton}
                            onClick={() =>
                              this.setTextVerticalAlign('flex-start')
                            }
                          >
                            <FontAwesomeIcon
                              icon={['far', 'arrow-from-top']}
                              // icon={["fal", "align-left"]}
                              size='1x'
                            />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() => this.setTextVerticalAlign('center')}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'align-center']}
                              size='1x'
                            />
                          </button>
                          <button
                            className={scss.printButton}
                            onClick={() =>
                              this.setTextVerticalAlign('flex-end')
                            }
                          >
                            <FontAwesomeIcon
                              icon={['far', 'arrow-from-bottom']}
                              // icon={["fal", "align-right"]}
                              size='1x'
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={scss.styleHeading}>Background</div>

                    <div className={scss.printFlyoutSubHeading}>
                      <span>Fill</span>
                    </div>

                    <div className={scss.printFlyoutBackgroundRow}>
                      {/* ALT CUSTOM CHECKBOX BELOW */}
                      <div className={scss.printCheckbox}>
                        <label
                          className={[
                            checkboxScss.checkboxContainer,
                            checkboxScss.altCheckboxContainer,
                          ].join(' ')}
                        >
                          <input
                            className={checkboxScss.checkbox}
                            type='checkbox'
                            id='bgText'
                            checked={!object.bgTransparent}
                            onChange={this.setBgTransparent}
                          />
                          <span
                            className={[
                              checkboxScss.checkboxMark,
                              checkboxScss.altCheckboxMark,
                            ].join(' ')}
                          ></span>
                        </label>
                      </div>

                      <div className={scss.inputContainer}>
                        <div>
                          <input
                            className={scss.printColorLarge}
                            type='color'
                            value={object.bgColor}
                            onClick={() => this.handleColorPicker('Background')} // <--- Open/hide on click
                            onFocus={() => this.handleColorPicker('Background')} //<--- Open on focus
                          />
                          {this.state.showBackgroundPicker ? (
                            <div className={scss.colorPopover}>
                              <div
                                className={scss.colorCover}
                                onClick={() =>
                                  this.handleColorPicker('Background')
                                }
                              />
                              <BlockPicker
                                colors={myAssetMapColors}
                                triangle='hide'
                                type='color'
                                color={object.bgColor}
                                onChangeComplete={color =>
                                  this.setColorProperty(color.hex, 'bgColor')
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className={[scss.inputContainer, scss.borderInput].join(
                          ' '
                        )}
                      >
                        <label className={scss.printLargeNumberLabel}>
                          Opacity
                        </label>
                        <input
                          type='number'
                          onChange={this.setProperty('opacity')}
                          value={object.opacity > 100 ? 100 : object.opacity}
                          className={[
                            scss.printNumber,
                            scss.printLargeNumber,
                          ].join(' ')}
                        />
                      </div>
                    </div>

                    <div className={scss.printFlyoutFontRow}></div>
                  </>
                )}

                {/* CHECK IF THE ELEMENT IS A LEGEND, THEN DISPLAY THE OTHER PRINT FLYOUT OPTIONS */}
                {object.type === 'legend' && (
                  <>
                    <div className={scss.styleHeading}>Text and Spacing</div>

                    <div className={scss.printFlyoutSubHeading}>
                      <span>Text</span>
                    </div>

                    <div className={scss.printFlyoutBackgroundRow}>
                      <div className={scss.inputContainer}>
                        <div>
                          <input
                            className={scss.printColorLarge}
                            type='color'
                            value={object.textColor}
                            // onChange={this.setProperty("textColor")}
                            onClick={() => this.handleColorPicker('Text')} // <--- Open/hide on click
                            onFocus={() => this.handleColorPicker('Text')} //<--- Open on focus
                          />
                          {this.state.showTextPicker ? (
                            <div className={scss.colorPopover}>
                              <div
                                className={scss.colorCover}
                                onClick={() => this.handleColorPicker('Text')}
                              />
                              <BlockPicker
                                colors={myAssetMapColors}
                                triangle='hide'
                                type='color'
                                color={object.textColor}
                                onChangeComplete={color =>
                                  this.setColorProperty(color.hex, 'textColor')
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={[scss.inputContainer, scss.borderInput].join(
                          ' '
                        )}
                      >
                        <label className={scss.printLargeNumberLabel}>
                          Padding
                        </label>
                        <input
                          type='number'
                          onChange={this.setProperty('padding')}
                          value={object.padding}
                          className={[
                            scss.printNumber,
                            scss.printLargeNumber,
                          ].join(' ')}
                        />
                      </div>
                    </div>

                    <div className={scss.styleHeading}>Background</div>

                    <div className={scss.printFlyoutSubHeading}>
                      <span>Fill</span>
                    </div>

                    <div className={scss.printFlyoutBackgroundRow}>
                      {/* ALT CUSTOM CHECKBOX BELOW */}
                      <div className={scss.printCheckbox}>
                        <label
                          className={[
                            checkboxScss.checkboxContainer,
                            checkboxScss.altCheckboxContainer,
                          ].join(' ')}
                        >
                          <input
                            className={checkboxScss.checkbox}
                            type='checkbox'
                            id='bgText'
                            checked={!object.bgTransparent}
                            onChange={this.setBgTransparent}
                          />
                          <span
                            className={[
                              checkboxScss.checkboxMark,
                              checkboxScss.altCheckboxMark,
                            ].join(' ')}
                          ></span>
                        </label>
                      </div>

                      <div className={scss.inputContainer}>
                        <div>
                          <input
                            className={scss.printColorLarge}
                            type='color'
                            value={object.bgColor}
                            // onChange={this.setProperty("textColor")}
                            onClick={() => this.handleColorPicker('Background')} // <--- Open/hide on click
                            onFocus={() => this.handleColorPicker('Background')} //<--- Open on focus
                          />
                          {this.state.showBackgroundPicker ? (
                            <div className={scss.colorPopover}>
                              <div
                                className={scss.colorCover}
                                onClick={() =>
                                  this.handleColorPicker('Background')
                                }
                              />
                              <BlockPicker
                                colors={myAssetMapColors}
                                triangle='hide'
                                type='color'
                                color={object.bgColor}
                                onChangeComplete={color =>
                                  this.setColorProperty(color.hex, 'bgColor')
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className={[scss.inputContainer, scss.borderInput].join(
                          ' '
                        )}
                      >
                        <label className={scss.printLargeNumberLabel}>
                          Opacity
                        </label>
                        <input
                          type='number'
                          onChange={this.setProperty('opacity')}
                          value={object.opacity > 100 ? 100 : object.opacity}
                          className={[
                            scss.printNumber,
                            scss.printLargeNumber,
                          ].join(' ')}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* CHECK IF THE ELEMENT IS TEXT OR DATE, THEN DISPLAY THE HEADING AND SUBHEADING */}
                {object.type === 'text' ||
                object.type === 'date' ||
                object.type === 'legend' ? (
                  <div className={scss.printFlyoutSubHeading}>
                    <span>Border</span>
                  </div>
                ) : (
                  <>
                    <div className={scss.styleHeading}>Background</div>
                    <div className={scss.printFlyoutSubHeading}>
                      <span>Border</span>
                    </div>
                  </>
                )}

                <div className={scss.printFlyoutFontRow}>
                  {/* ALT CUSTOM CHECKBOX BELOW */}
                  <div className={scss.printCheckbox}>
                    <label
                      className={[
                        checkboxScss.checkboxContainer,
                        checkboxScss.altCheckboxContainer,
                      ].join(' ')}
                    >
                      <input
                        className={checkboxScss.checkbox}
                        type='checkbox'
                        id='bgText'
                        checked={!object.borderTransparent}
                        onChange={this.setBorderTransparent}
                      />
                      <span
                        className={[
                          checkboxScss.checkboxMark,
                          checkboxScss.altCheckboxMark,
                        ].join(' ')}
                      ></span>
                    </label>
                  </div>

                  {/* BORDER COLOR PICKER */}
                  <div className={scss.inputContainer}>
                    <div>
                      <input
                        className={scss.printColorLarge}
                        type='color'
                        value={object.borderColor}
                        onClick={() => this.handleColorPicker('Border')} // <--- Open/hide on click
                        onFocus={() => this.handleColorPicker('Border')} //<--- Open on focus
                      />
                      {this.state.showBorderPicker ? (
                        <div className={scss.colorPopover}>
                          <div
                            className={scss.colorCover}
                            onClick={() => this.handleColorPicker('Border')}
                          />
                          <BlockPicker
                            colors={myAssetMapColors}
                            triangle='hide'
                            type='color'
                            color={object.borderColor}
                            onChangeComplete={color =>
                              this.setColorProperty(color.hex, 'borderColor')
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* BORDER WIDTH */}

                  <div
                    className={[scss.inputContainer, scss.borderInput].join(
                      ' '
                    )}
                  >
                    <label className={scss.printLargeNumberLabel}>Width</label>
                    <input
                      type='number'
                      onChange={this.setProperty('borderWidth')}
                      value={object.borderWidth}
                      className={[scss.printNumber, scss.printLargeNumber].join(
                        ' '
                      )}
                    />
                  </div>
                </div>
                <ElementOrder
                  elements={this.props.elements}
                  activeElement={object}
                  handleUpdateElements={this.props.handleUpdateElements}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ElementStyle
