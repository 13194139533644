import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ firstName, lastName, visible }) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [firstNameState, setFirstName] = useState(firstName)
  const [lastNameState, setLastName] = useState(lastName)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/update'
    let body = {
      firstName: firstNameState,
      lastName: lastNameState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const onFirstNameChange = e => {
    setFirstName(e.target.value)
    setEnableSave(true)
  }
  const onLastNameChange = e => {
    setLastName(e.target.value)
    setEnableSave(true)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='export-to-csv-dialog'
        open={open}
      >
        <DialogTitle id='export-to-csv'>
          Edit Name{' '}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          <MuiFormControlText
            disabled={false}
            label='First Name'
            placeholder='Enter First Name'
            onChange={onFirstNameChange}
            onSubmit={fetching ? null : handleSave}
            value={firstNameState}
          />
          <MuiFormControlText
            disabled={false}
            label='Last Name'
            placeholder='Enter Last Name'
            onChange={onLastNameChange}
            onSubmit={fetching ? null : handleSave}
            value={lastNameState}
          />
        </DialogContent>

        <DialogActions>
          {/* <MuiButton
            color="default"
            variant="outlined"
            onClick={handleCancel}
            label="Cancel"
            size="large"
          />
          <MuiButton
            color="primary"
            disabled={!enableSave}
            variant="outlined"
            onClick={fetching ? null : handleSave}
            label={fetching ? <CircularProgress size={10} /> : "Save"}
            size="large"
          /> */}

          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>
          <button
            className={appScss.blueButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileName(props) {
  return <SimpleDialog {...props} />
}
