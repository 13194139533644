import React from 'react'
import { useSelector } from 'react-redux'
import MouseTooltip from '../../components/MouseTooltip/MouseTooltip'

const MapToolTip = React.memo(({ tipHtml }) => {
  const overPanel = useSelector(state => state.setOverPanel)
  const tipStyle = {
    zIndex: '1000',
    padding: '2px',
    fontSize: '10px',
    color: 'black',
    backgroundColor: 'white',
    border: 'solid',
    borderWidth: '1px',
    borderColor: 'black',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: '.8',
  }
  return overPanel ? null : (
    <MouseTooltip
      visible={overPanel ? false : true}
      offsetX={10}
      offsetY={15}
      style={tipStyle}
    >
      {tipHtml}
    </MouseTooltip>
  )
})

export default MapToolTip
