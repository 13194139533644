export const CLICKED_FEATURE = {
  sourcesArray: [
    {
      id: 'clicked_feature',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      beforeLayer: null,
      layer: {
        id: 'clicked_feature',
        type: 'line',
        source: 'clicked_feature',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#FFFF00',
          'line-opacity': 1,
          'line-width': 2,
        },
        metadata: {},
      },
    },
  ],
}
