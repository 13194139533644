import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import appScss from '../../App/App.scss'
import { apis } from '../../../config/apiConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SaveSelectedFeatures = ({ targetLayer, disabled = false }) => {
  const [fetchObjects, setFetchObjects] = useState(null)
  const [saving, setSaving] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const mapStyle = useSelector(state => state.mapStyle)
  const saveSelectedAsLayer = () => {
    setSaving(true)
    let style = mapStyle.toJS()
    let mamIds = style.sources.selected_features.data.features.map(
      feature => feature.properties.mamid
    )
    //const layerId = selectedFeature.layer.id;
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/copy'
    const body = {
      layerID: isNaN(targetLayer.layerId)
        ? targetLayer.value
        : targetLayer.layerId,
      featureID: mamIds,
      duplicateData: true,
    }
    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    if (results[0] && results[0].success) {
      setMountMakeDataConfig(true)
      setFetchObjects(null)
    }
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
      setSaving(false)
    },
    [mountMakeDataConfig]
  )

  const dataConfigFinish = () => {
    console.log('FINISHED')
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={dataConfigFinish} />
      )}
      <button
        onClick={saving || disabled ? null : saveSelectedAsLayer}
        className={disabled ? appScss['disabled-button'] : null}
        data-tip='Save Selected Features to New Layer'
        data-for='save-selected-features'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        {saving ? (
          <FontAwesomeIcon icon={'spinner'} size='1x' spin />
        ) : (
          <FontAwesomeIcon icon={['fa', 'save']} size='1x' />
        )}
      </button>
      <div className={appScss.reactToolTip}>
        <ReactTooltip
          id={'save-selected-features'}
          getContent={dataTip => `${dataTip}`}
        />
      </div>
    </>
  )
}

export default SaveSelectedFeatures
