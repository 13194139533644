import React, { useState, useEffect } from 'react'
import {
  getVisibleTocLayers,
  removeDuplicatesFromArray,
  getVisibleImageryLayers,
  generateUID,
} from '../../../utilities/mapStyle'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'

export default function SaveMapView({
  mapStyle,
  viewport,
  mapViewName,
  mapViewGroup,
  dataConfig,
  mountKey,
  onFinish,
}) {
  const [fetchObjects, setFetchObjects] = useState(null)

  const getVisibleLayers = (style, config) => {
    const visibleLAyers = getVisibleTocLayers(style, config)
    return removeDuplicatesFromArray(visibleLAyers)
  }

  const getMapLocation = viewport => {
    const location = {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: viewport.zoom,
    }
    return location
  }

  const getMapViewId = name => {
    const uid = generateUID()
    return `${name}_${uid}`
  }

  const style = mapStyle.toJS()
  const visibleLayers = getVisibleLayers(style, dataConfig['tocLayers'])
  const activeMapStyle = style.metadata.id
  const visibleImagery = getVisibleImageryLayers(
    style,
    dataConfig['imageryLayers']
  )

  const location = getMapLocation(viewport)
  const id = getMapViewId(mapViewName)

  const mapView = {
    id: id,
    label: mapViewName,
    visibleLayers: visibleLayers,
    activeMapStyle: activeMapStyle,
    visibleImagery: visibleImagery,
    location: location,
    showMapName: true,
    notes: '',
  }

  const buildFetchParams = () => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'mapview/add'
    let body = mapView

    if (mapViewGroup) {
      body.groupID = mapViewGroup
    }

    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    results.forEach(result => {
      console.log('new group result: ', result)
    })

    mountKey++
    onFinish(mountKey)
  }

  // Fetch
  useEffect(() => {
    if (mapViewName) buildFetchParams()
    console.log('mapView', mapView)

    return () => {
      console.log('UNMOUNTING COMPONENT')
    }
  }, [])

  return (
    fetchObjects && (
      <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
    )
  )
}
