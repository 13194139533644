import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const LayerZoomLevel = ({layer,setDisabled}) => {
  const viewport = useSelector(state => state.viewport)
  let viewportRef = useRef(viewport)

  const checkLayerMinZoom = () => {
    let isDisabled = false
    const minZoom = Math.min.apply(
      null,
      layer.layersArray.map(layer => layer.layer.minzoom)
    )
    if (minZoom > viewport.zoom) {
      isDisabled = true
    }
    setDisabled(isDisabled)
  }

  useEffect(() => {
    if (viewportRef && viewport) {
      const refZoom =
        viewportRef && viewportRef.current
          ? viewportRef.current.zoom.toFixed(0)
          : null
      const mapZoom = viewport.zoom.toFixed(0)
      if (refZoom !== mapZoom) {
        viewportRef.current = viewport
        checkLayerMinZoom()
      }
    }
  }, [viewport])

  return null
}

export default LayerZoomLevel
