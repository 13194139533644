import {
  SET_DRAW_MODE,
  SET_SNAP_SETTINGS,
  SET_DRAW_MEASURE_SETTINGS,
} from '../actions/types'

export default function DrawMode(state = null, action) {
  switch (action.type) {
    case SET_DRAW_MODE: {
      return action.payload
    }
    default:
      return state
  }
}

export function SnapSettings(
  state = {
    snapLine: false,
    snapVertex: false,
    snapLayer: false,
  },
  action
) {
  switch (action.type) {
    case SET_SNAP_SETTINGS: {
      return Object.assign({}, state, action.payload)
    }
    default:
      return state
  }
}

export function DrawMeasureSettings(
  state = {
    measureLine: false,
    measureArea: false,
    measurePoint: false,
  },
  action
) {
  switch (action.type) {
    case SET_DRAW_MEASURE_SETTINGS: {
      return Object.assign({}, state, action.payload)
    }
    default:
      return state
  }
}
