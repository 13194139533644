import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { apiFetch } from '../../../utilities/api'
import { apis } from '../../../config/apiConfig'

import { BASIC_STYLE } from '../../../mapStyles/basic/style'
import { CLICKED_FEATURE } from '../../../data/Layers/Auxiliary/ClickedFeature'
import { DELETE } from '../../../data/Layers/Auxiliary/Delete'
import { DRAW } from '../../../data/Layers/Auxiliary/Draw'
import { GEO_SPATIAL_RESULTS } from '../../../data/Layers/Auxiliary/GeoSpatialResults'
import { MEASURE } from '../../../data/Layers/Auxiliary/Measure'
import { MEASURE_CONTROL } from '../../../data/Layers/Auxiliary/MeasureControl'
import { COORDINATE_SEARCH } from '../../../data/Layers/Auxiliary/Search'
import { GEOCODE } from '../../../data/Layers/Auxiliary/Search'
import { SELECTED_FEATURES } from '../../../data/Layers/Auxiliary/SelectedFeatures'

let intervalID

const UrlParams = ({ history, setUrlParams }) => {
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const userPreferences = useSelector(state => state.user.preferences)
  let viewportRef = useRef(viewport)

  let [initialViewport, setInitialViewport] = useState(null)
  let [urlVisibleLayers, setUrlVisibleLayers] = useState(null)
  let [params, setParams] = useState(null)

  const didMapMove = () => {
    const refLat = viewportRef.current.latitude.toFixed(1)
    const refLng = viewportRef.current.longitude.toFixed(1)
    const refZoom = viewportRef.current.zoom.toFixed(0)
    const mapLat = viewport.latitude.toFixed(1)
    const mapLng = viewport.longitude.toFixed(1)
    const mapZoom = viewport.zoom.toFixed(0)
    let mapMoved = false
    if (JSON.stringify(refLat, refLng) !== JSON.stringify(mapLat, mapLng)) {
      viewportRef.current = viewport
      mapMoved = true
    }
    if (refZoom !== mapZoom) {
      viewportRef.current = viewport
      mapMoved = true
    }
    return mapMoved
  }

  const getUrlParams = () => {
    const { latitude, longitude, zoom } = viewport
    let layers = `visibleLayers=[${urlVisibleLayers.join(',')}]`

    setParams({ latitude, longitude, zoom, layers })
  }

  const updateUrl = (latitude, longitude, zoom, layers) => {
    history.replace(
      `/?lat=${latitude.toFixed(5)}&lng=${longitude.toFixed(
        5
      )}&zoom=${zoom.toFixed(0)}&${layers}`
    )
  }


  let previousLocation = null
  useEffect(() => {
    let { lat, lng, zoom, visibleLayers } = queryString.parse(
      history.location.search
    )

    if (
      typeof lat === 'undefined' &&
      typeof lng === 'undefined' &&
      typeof zoom === 'undefined' &&
      userPreferences && 
      userPreferences['location.history'] && 
      userPreferences['location.history'][0] &&
      userPreferences['location.history'][0].value
    ) {
      ({ lat, lng, zoom } = userPreferences['location.history'][0].value)
    }

    if (typeof intervalID === 'undefined') {
      intervalID = setInterval(() => {
        let value = {
          lat: viewportRef.current.latitude,
          lng: viewportRef.current.longitude,
          zoom: viewportRef.current.zoom,
        }
        if (JSON.stringify(previousLocation) !== JSON.stringify(value) && previousLocation) {
          apiFetch(
            apis['userPreferences'].uri + 'upsert',
            'POST', 
            {
              key: 'location.history',
              name: 'location',
              value,
            },
            () => {}
          )
        }
        previousLocation = value
      }, 3000)
    } 

    if (!visibleLayers) {
      visibleLayers = []
    } else {
      visibleLayers = visibleLayers.replace('[', '')
      visibleLayers = visibleLayers.replace(']', '')
      visibleLayers = visibleLayers.split(',')
    }
    setUrlVisibleLayers(visibleLayers)
    setUrlParams({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      zoom: parseFloat(zoom),
    })

    if (lat && lng && zoom && visibleLayers) {
      let layers = `visibleLayers=[${visibleLayers.join(',')}]`
      updateUrl(parseFloat(lat), parseFloat(lng), parseFloat(zoom), layers)
    }
    return () => {
      history.replace()
      clearInterval(intervalID)
      intervalID = undefined
    }
  }, [])

  useEffect(() => {
    if (!initialViewport && !viewport) {
      return
    }
    if (!initialViewport && viewport.latitude) {
      setInitialViewport(viewport)
    }

    if (!viewportRef.current) {
      viewportRef.current = viewport
    }
    if (viewportRef.current.latitude && viewportRef.current.latitude) {
      const mapMoved = didMapMove()
      if (mapMoved) {
        getUrlParams()
      }
    }
  }, [viewport])

  useEffect(() => {
    if (initialViewport) {
      viewportRef.current = initialViewport
      getUrlParams()
    }
  }, [initialViewport])

  useEffect(() => {
    if (initialViewport) {
      getUrlParams()
    }
  }, [urlVisibleLayers])

  useEffect(() => {
    if (params) {
      const { latitude, longitude, zoom, layers } = params
      updateUrl(latitude, longitude, zoom, layers)
    }
  }, [params])

  useEffect(() => {
    const baseLayers = BASIC_STYLE.layers.map(layer => layer.id)
    const ClickedFeature = CLICKED_FEATURE.layersArray.map(
      layerObj => layerObj.layer.id
    )
    const Delete = DELETE.layersArray.map(layerObj => layerObj.layer.id)
    const Draw = DRAW.layersArray.map(layerObj => layerObj.layer.id)
    const GeoSpatialResults = GEO_SPATIAL_RESULTS.layersArray.map(
      layerObj => layerObj.layer.id
    )
    const Measure = MEASURE.layersArray.map(layerObj => layerObj.layer.id)
    const MeasureControl = MEASURE_CONTROL.layersArray.map(
      layerObj => layerObj.layer.id
    )
    const Search = COORDINATE_SEARCH.layersArray.map(
      layerObj => layerObj.layer.id
    )
    const Geocode = GEOCODE.layersArray.map(layerObj => layerObj.layer.id)
    const SelectedFeatures = SELECTED_FEATURES.layersArray.map(
      layerObj => layerObj.layer.id
    )

    const excluded = [
      ...baseLayers,
      ...ClickedFeature,
      ...Delete,
      ...Draw,
      ...GeoSpatialResults,
      ...Measure,
      ...MeasureControl,
      ...Search,
      ...Geocode,
      ...SelectedFeatures,
      'measure_control_area_cold_outline',
    ]

    if (mapStyle) {
      const style = mapStyle.toJS()
      const visible = style.layers
        .filter(layer => layer.layout && layer.layout.visibility === 'visible')
        .filter(layer => !excluded.includes(layer.id))
        .map(layer => layer.id)
      setUrlVisibleLayers(visible)
    }
  }, [mapStyle])

  return null
}

export default UrlParams
