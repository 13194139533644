/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import scss from './MapPopup.scss'
import { useDispatch } from 'react-redux'

import { apiFetch } from '../../utilities/api'
import { apis } from '../../config/apiConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Alert } from '../../actions'

import DocumentBrowserV2 from '../DocumentBrowserV2/DocumentBrowserV2'

import { getDocument } from './Utilities'

const DocumentNoTab = ({
  config,
  layerProperties,
  layerId,
  featureProperties,
  popupInfo,
  index,
  dataConfigLayer,
  handleDocumentChange,
  getProperties,
  addDocumentRowVisible,
  handleAddDocumentRowVisible,
  reRender,
}) => {
  let rows = []
  let keys = Object.keys(featureProperties)
  let n = keys.length
  let lowerCaseObj = {}
  const featureId = popupInfo.features[index].properties.mamid
  const [documentFeature, setDocumentFeature] = useState(null)
  const [typeDocumentKey, setTypeDocumentKey] = useState(null)

  // Add Document Row States
  // const [addDocumentRowVisible, setAddDocumentRowVisible] = useState(false)
  const [addDocumentRow, setAddDocumentRow] = useState(null)
  const [addDocumentRowName, setAddDocumentRowName] = useState('')
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  const handleSuccess = msg => dispatch(Alert({ success: msg }))
  const handleError = error => dispatch(Alert({ error }))

  while (n--) {
    let key = keys[n]
    lowerCaseObj[key.toLowerCase()] = featureProperties[key]
  }

  let canEdit = false
  if (dataConfigLayer && dataConfigLayer.toc)
    canEdit = dataConfigLayer.toc.canEdit

  const setDocumentInfo = key => {
    setDocumentFeature(featureId)
    setTypeDocumentKey(key)
  }

  /**
   * @brief Add the document row by passing the name
   **/
  const handleAddDocumentRow = () => {
    setSaving(true)
    let documentName = addDocumentRowName.trim()
    if (documentName === '') return

    setAddDocumentRow(false)
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/properties/add'
    // const value = targetProperty.value
    const bodyParams = {
      default: '',
      layerID: layerId,
      name: addDocumentRowName,
      type: 'document',
      value: [],
    }
    apiFetch(url, method, bodyParams, result => {
      getProperties()
      setSaving(false)
      setAddDocumentRowName('')
      handleAddDocumentRowVisible()
      handleSuccess(
        'Document Attribute has been added. Please go to the tab settings to place it in a tab'
      )
      reRender()
    })
  }
  // TODO: Nathan handle update cancel function - Cancel button attr tab

  const getPopupRow = (property, propertyConfigName = null) => {
    //NOTE : propertyConfigName is used by Common Layers to select and override propertie keys in the data
    // this allows us to display friendlier data in popup without manipulating the data

    return getDocument({
      lowerCaseObj,
      property,
      propertyConfigName,
      featureProperties,
      setDocumentInfo,
      handleDocumentChange,
      canEdit,
    })
  }

  if (layerProperties[layerId]) {
    if (config.properties) {
      rows = layerProperties[layerId]
        .filter(property => config.properties[property.key])
        .map(property => getPopupRow(property, config.properties[property.key]))
    } else {
      rows = layerProperties[layerId].map(property => {
        return getPopupRow(property)
      })
    }
  }

  const checkForAttributes = () => {
    if (!layerProperties[layerId]) return false

    let attributeList = layerProperties[layerId].filter(
      attr => attr && attr.type === 'document (Feature)'
    )

    return attributeList.length > 0
  }

  return (
    <>
      {checkForAttributes() || addDocumentRowVisible ? (
        <>
          <div className={scss['feature-document-grid']}>
            {rows}
            {addDocumentRowVisible ? (
              <div className={scss['feature-add-document-grid-row']}>
                <input
                  onChange={e => setAddDocumentRowName(e.target.value)}
                  // onKeyUp={handleAddDocumentRow}
                  value={addDocumentRowName}
                  className={scss['feature-document-grid-input']}
                  autoFocus
                />
                <button
                  className={scss['feature-document-grid-button']}
                  onClick={() => {
                    setAddDocumentRowName('')
                    handleAddDocumentRowVisible()
                  }}
                >
                  <FontAwesomeIcon icon='times' />
                </button>
                <button
                  className={scss['feature-document-grid-button']}
                  onClick={handleAddDocumentRow}
                >
                  <FontAwesomeIcon icon='check' />
                </button>
              </div>
            ) : null}
          </div>
          {documentFeature && (
            <DocumentBrowserV2
              featureProperties={featureProperties}
              layerId={layerId}
              mamId={featureId}
              documentFeature={documentFeature}
              layerProperties={layerProperties}
              onClose={() => setDocumentFeature(null)}
              isSelectDocument={true}
              typeDocumentKey={typeDocumentKey}
              handleDocumentChange={handleDocumentChange}
              popupKey={layerId}
              canEdit={canEdit}
              linkMode
            />
          )}
        </>
      ) : (
        <div className={scss['feature-empty-grid']}>
          <div className={scss['feature-empty-square']}>
            <FontAwesomeIcon icon='question' />
          </div>
          <span className={scss['feature-empty-title']}>
            No documents found
          </span>
          <span className={scss['feature-empty-description']}>
            Use the button below to add a document type.
          </span>
          {/* <a>Help?</a> */}
        </div>
      )}
    </>
  )
}

export default DocumentNoTab
