import React, { useState, useEffect } from 'react'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'

// scss files
import scss from '../ParentGroupSelect/ParentGroupSelect.scss'

const ParentMapViewGroupSelect = ({ config, closeTool }) => {
  // state
  const mapViewRootOption = { label: 'None', value: 'mapview_root' }
  const [parentGroup, setParentGroup] = useState(null)
  const [location, setLocation] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectOptions, setSelectOptions] = useState([])
  const [fetching, setFetching] = useState(null)
  const [fetchObjects, setFetchObjects] = useState(null)

  const currentView = config.targetMapView
  const currentList = config.mapViewList

  const buildFetchParams = (parentGroupID = '--') => {
    const method = 'POST'
    let url
    let body

    if (parentGroupID === 'mapview_root') {
      parentGroupID = null
    }

    if (currentView.id.substr(0, 6) === 'group_') {
      url = apis['apiDatabase'].uri + 'mapview/group/update'
      body = {
        parentGroupID,
        groupID: currentView.id,
      }
    } else {
      url = apis['apiDatabase'].uri + 'mapview/update'
      body = {
        groupID: parentGroupID,
        mapViewID: currentView.id,
      }
    }

    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = () => {
    setFetchObjects(null)
    setFetching(null)
    config.updateMapViews()
    closeTool()
  }

  const getGroups = () => {
    return currentList.filter(view => view.children)
  }

  const getGroupOptions = groups => {
    let groupOptions = []

    const buildGroupOptions = (group, groupOptions) => {
      if (!group) return

      if (typeof group !== 'string') {
        const option = { label: group.label, value: group.groupId }
        groupOptions.push(option)

        if (group.children.groupIds) {
          group.children.groupIds.forEach(group => {
            buildGroupOptions(group, groupOptions)
          })
        }
      }
    }

    groups.forEach(group => {
      buildGroupOptions(group, groupOptions)
    })

    return groupOptions
  }

  const getCurrentGroupParent = (groups, currentGroupId) => {
    let targetGroupParent = null

    const checkGroupChildren = groupObj => {
      // check each group's children for group ids
      if (groupObj.children.groupIds.length) {
        // if the group ids array has anything in it, and the
        // id is the same as the group that is being checked,
        // the current group ID is supplied as the group parent
        groupObj.children.groupIds.forEach(groupId => {
          if (groupId === currentGroupId) {
            targetGroupParent = groupObj
          }
        })
      } else return null
    }

    groups.forEach(groupObj => {
      checkGroupChildren(groupObj)
    })

    if (targetGroupParent) {
      return targetGroupParent
    }
    return null
  }

  const buildOptionsForGroup = () => {
    let groups = getGroups()
    groups = groups.filter(group => {
      if (group.groupId === currentView.id) {
        return false
      } else {
        return true
      }
    })
    let optionsForSelect = getGroupOptions(groups)

    const targetGroupParent = getCurrentGroupParent(groups, currentView.groupId)

    const targetOption = targetGroupParent
      ? { label: targetGroupParent.label, value: targetGroupParent.groupId }
      : mapViewRootOption

    optionsForSelect.unshift(mapViewRootOption)

    setParentGroup(targetOption.value)
    setSelectOptions(optionsForSelect)
    setSelectedOption(targetOption.value)
  }

  const getViewGroup = (groups, currentId) => {
    let viewGroup = null

    const checkGroupChildren = groupObj => {
      if (groupObj.children.layerIds.length) {
        groupObj.children.layerIds.forEach(layerId => {
          if (layerId === currentId) {
            viewGroup = groupObj
          }
        })
      } else return null
    }

    groups.forEach(groupObj => {
      checkGroupChildren(groupObj)
    })
    return viewGroup
  }

  const buildOptionsForView = () => {
    const groups = getGroups()
    const viewGroup = getViewGroup(groups, currentView.id)
    const optionsForSelect = getGroupOptions(groups)

    const targetOption = viewGroup
      ? { label: viewGroup.label, value: viewGroup.groupId }
      : mapViewRootOption

    optionsForSelect.unshift(mapViewRootOption)

    setParentGroup(targetOption.value)
    setSelectedOption(targetOption.value)
    setSelectOptions(optionsForSelect)
  }

  useEffect(() => {
    if (parentGroup) {
      if (selectedOption !== parentGroup) {
        buildFetchParams(selectedOption)
        setFetching(true)
      }
    } else if (selectedOption === location) {
      console.log('NO CHANGE')
    } else if (!parentGroup) {
      if (selectedOption.label && selectedOption.label === '--') {
        return
      } else {
        // add to group
        buildFetchParams(selectedOption)
        setFetching(true)
      }
    }
  }, [selectedOption])

  useEffect(() => {
    if (currentView.id.includes('group')) {
      buildOptionsForGroup()
    } else {
      buildOptionsForView()
    }
  }, [])

  const ui = () => {
    let placeholder = 'Select Parent'
    let disabled = false
    let options = selectOptions
    let currentOption = selectedOption

    if (selectOptions.length == 1) {
      placeholder = 'No Groups Found'
      disabled = true
      currentOption = null
      options = []
    } else if (selectOptions.length > 1 && selectedOption == 'mapview_root') {
      delete options[0]
      currentOption = null
    }

    currentOption = options.filter(option => option.value === currentOption)
    if (Array.isArray(currentOption)) {
      currentOption = currentOption[0]
    }

    return (
      selectedOption && (
        <div className={scss['parent-group']}>
          {fetchObjects && (
            <AsyncFetch
              fetchObjects={fetchObjects}
              fetchFinished={fetchFinished}
            />
          )}
          <ReactSelect
            options={options}
            onChange={(values, selected) => {
              selected.value === '--'
                ? setSelectedOption(mapViewRootOption.value)
                : setSelectedOption(selected.value)
            }}
            defaultValue={currentOption}
            disabled={disabled}
            isFixed
          />
        </div>
      )
    )
  }

  return ui()
}

export default ParentMapViewGroupSelect
