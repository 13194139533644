import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const mainModalTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),

    color: '#fff',
    fontSize: 22,
  },

  palette: {
    primary: {
      main: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#fff',
    },
    background: {
      paper: '#1a2937',
      default: '#1a2937',
    },
  },

  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '400px',
      },
      paperWidthMd: {
        maxWidth: '700px',
      },
      paperWidthLg: {
        maxWidth: '1000px',
      },
    },

    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: '#1a2937',
        color: '#fff',
        padding: '8px 12px 6.5px',
        borderBottom: 'solid 1px #626971',
        borderRadius: '4px 4px 0 0',
      },
    },
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '1.29',
        letterSpacing: '-0.4px',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: '#1a2937',
        color: '#fff',
        overflowY: 'hidden',
        padding: '20.5px 20px 33px 20px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 20px',
        borderTop: 'solid 1px #626971',
        justifyContent: 'space-between',
      },
    },

    MuiButton: {
      label: {
        color: '#fff !important',
      },
    },
    MuiButtonBase: {
      root: {
        // Name of the rule
        height: '33px',
        color: '#fff !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          //Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      root: {
        // padding: "0 0px 0 0px !important",
      },
    },
    MuiFormLabel: {
      root: {
        // Name of the rule
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '12px !important',
      },
    },
    MuiInputBase: {
      root: {
        //padding: "9px !important",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#41678b',
      },
      root: {
        borderColor: '#41678b',
        width: '100%',
        '&&:hover': {
          borderColor: '#24c0db',

          '& *': {
            borderColor: '#24c0db',
          },
        },
        '&&:focused': {
          borderColor: '#24c0db',

          '& *': {
            borderColor: '#24c0db',
          },
        },
      },
    },

    MuiSvgIcon: {
      root: {
        color: '#fff',
        fontSize: '19px',
        top: 'calc(50% - 9px)',
      },
    },

    MuiInput: {
      underline: {
        border: 'none !important',
        '&&:before': {
          border: 'none !important',
        },
      },
    },

    MuiListItem: {
      root: {
        backgroundColor: '#213445',
        '&&:hover': {
          backgroundColor: '#d8d8d815',
        },
        '&$selected': {
          backgroundColor: '#88c0d630',
        },
      },
    },

    MuiSelect: {
      icon: {
        color: '#fff',
        fontSize: '19px',
        top: 'calc(50% - 9px)',
      },
      select: {
        backgroundColor: '#213445',
        border: 'solid 1px #41678b',
        borderRadius: '4px !important',
        padding: '9px !important',
        width: '100%',
        '&&:hover': {
          borderColor: '#24c0db',

          '& *': {
            borderColor: '#24c0db',
          },
        },
        '&&:focused': {
          borderColor: '#24c0db',

          '& *': {
            borderColor: '#24c0db',
          },
        },
      },
    },
  },
})

export default mainModalTheme
