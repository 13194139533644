import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './ImageryPickerBtn.scss'

function ImageryBtn({ toggleImagery, imageryPickerVisible }) {
  return (
    <button
      className={[
        scss.imageryBtn,
        imageryPickerVisible ? scss.imageryBtnActive : '',
      ].join(' ')}
      onClick={toggleImagery}
      title='Imagery'
    >
      <span>
        <FontAwesomeIcon icon={['fal', 'globe']} size='1x' />
        Imagery
      </span>
      <FontAwesomeIcon
        icon={['fa', imageryPickerVisible ? 'chevron-up' : 'chevron-down']}
        size='1x'
      />
    </button>
  )
}

export default ImageryBtn