import { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  ref,
  setDisplayUseDataDrivenStylesMapPopup
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (typeof event.path[0].childNodes[0].data === 'undefined') {
        setDisplayUseDataDrivenStylesMapPopup(false)
        return
      }

      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayUseDataDrivenStylesMapPopup(false)
        return
      }

      if (ref.current && !ref.current.contains(event.target)) {
        // ignores the actions of the buttons that the pop up opened on top of it
        setDisplayUseDataDrivenStylesMapPopup(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
