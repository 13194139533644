/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useMemo } from 'react'
import { useTable, useSortBy, useRowSelect, useExpanded } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as DocumentBrowserUtil from '../../../DocumentBrowserV2/DocumentBrowserV2Util'
import { apiFetch } from '../../../../utilities/api'
import { apis } from '../../../../config/apiConfig'
import DocumentRow from './DocumentRow/DocumentRow'
import DocumentGroupTable from './DocumentGroupTable/DocumentGroupTable'
import EditDocument from './DocumentRow/EditDocument/EditDocument'
import DeleteDocumentPopup from './DocumentRow/DeleteDocumentPopup/DeleteDocumentPopup'
import AddDocument from './AddDocument/AddDocument'
import {
  AttributeSkeletonLoader,
  GenerateAddAttributeButton,
} from '../ManageAttributes/ManageAttributes'

import LayerSettingsV2 from '../LayerSettingsV2.scss'
import scss from './ManageDocuments.scss'

const ManageDocuments = ({ config }) => {
  const [layerProperties, setLayerProperties] = useState(null)
  const [currentFilter, setCurrentFilter] = useState('')
  const [displayEditDocumentTab, setDisplayEditDocumentTab] = useState(false)
  const [displayAddDocument, setDisplayAddDocument] = useState(false)

  const [displayManageDocuments, setDisplayManageDocuments] = useState(true)

  const [loading, setLoading] = useState(false)
  const [numHeaderClicks, setNumHeaderClicks] = useState(1)
  const [data, setData] = useState([])
  const [displayDeleteDocumentPopup, setDisplayDeleteDocumentPopup] =
    useState(false)

  const getProperties = () => {
    setLoading(true)

    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const bodyParams = {
      layerID: layerId,
    }
    apiFetch(url, method, bodyParams, result => {
      setLoading(false)
      if (result.success) {
        setLayerProperties(result.data)
      } else {
        setLayerProperties([])
      }
    })
  }

  const Table = ({ columns, data, handleHeaderClick, useBigTimeGrouping }) => {
    const getIconBasedOnSortType = (column, useBigTimeTypeGroupingIcon) => {
      // This grouping has no icon for the time being
      if (useBigTimeTypeGroupingIcon) return ' '

      if (currentFilter !== column.id) return ''

      return column.isSorted ? (
        column.isSortedDesc ? (
          <FontAwesomeIcon
            className='fa-lg sort-icon'
            icon='caret-down'
            style={{
              color: 'white',
              marginLeft: '5px',
            }}
          />
        ) : (
          <FontAwesomeIcon
            className='fa-lg sort-icon'
            icon='caret-up'
            style={{
              color: 'white',
              marginLeft: '5px',
            }}
          />
        )
      ) : (
        ''
      )
    }

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
      useTable(
        {
          columns,
          data,
        },
        useExpanded
      )

    let tableContents =
      rows && rows.length > 0 ? (
        <>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <DocumentRow
                key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                row={row}
                setDisplayEditDocumentTab={setDisplayEditDocumentTab}
                setTargetProperty={setTargetProperty}
                setDisplayDeleteDocumentPopup={setDisplayDeleteDocumentPopup}
                setDisplayManageDocuments={setDisplayManageDocuments}
              />
            )
          })}
        </>
      ) : (
        <tr style={{ display: 'flex', height: '300px' }}>
          <td style={{ border: 'none', width: '10%' }}></td>
          <td
            style={{
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              width: '100%',
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
            }}
          >
            <GenerateAddAttributeButton
              type='circle'
              onClick={() => {
                setDisplayAddDocument(true)
                setDisplayManageDocuments(false)
              }}
            />
            No document attributes were found.
            <br />
            Would you like to create one?
          </td>
        </tr>
      )

    return (
      <>
        {useBigTimeGrouping ? (
          <DocumentGroupTable
            headerGroups={headerGroups}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            getIconBasedOnSortType={getIconBasedOnSortType}
            handleHeaderClick={handleHeaderClick}
            useBigTimeGrouping={useBigTimeGrouping}
            prepareRow={prepareRow}
            rows={rows}
            setDisplayEditDocumentTab={setDisplayEditDocumentTab}
            setTargetProperty={setTargetProperty}
            setDisplayDeleteDocumentPopup={setDisplayDeleteDocumentPopup}
            setDisplayManageDocuments={setDisplayManageDocuments}
          />
        ) : (
          <table
            className={LayerSettingsV2.attributeTable}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                      onClick={() => {
                        handleHeaderClick(column.id)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{getIconBasedOnSortType(column, null)}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!loading ? tableContents : <AttributeSkeletonLoader />}
            </tbody>
          </table>
        )}
        {rows && rows.length > 0 ? (
          <GenerateAddAttributeButton
            onClick={() => {
              setDisplayAddDocument(true)
              setDisplayManageDocuments(false)
            }}
          />
        ) : null}
      </>
    )
  }

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (layerProperties) {
      let dataValues = layerProperties

      dataValues = dataValues
        .map(row => {
          if (!['document (Layer)', 'document (Feature)'].includes(row.type))
            return null

          if (row.type === 'document (Layer)') {
            row.type = 'Layer'
          } else if (row.type === 'document (Feature)') {
            row.type = 'Feature'
          }

          return row
        })
        .filter(row => row !== null)

      dataValues = dataValues.map(row => {
        delete row.id
        row.attrKey = row.key
        // key is a reserved word for JS objects
        delete row.key
        delete row.Layer
        delete row.value
        return row
      })

      setData(dataValues)
    }
  }, [layerProperties])

  const [columns, setColumns] = useState([
    {
      Header: 'Name',
      accessor: 'name',
      isSorted: false,
      isSortedDesc: false,
    },
    {
      Header: 'Level',
      accessor: 'type',
      isSorted: false,
      isSortedDesc: false,
    },
  ])

  const [useBigTimeGrouping, setUseBigTimeGrouping] = useState(false)
  const [targetProperty, setTargetProperty] = useState(null)

  /**
   * Three modes of organizing table data con be achieved with different number of clicks:
   * 1 click - sorts in ascending order
   * 2 click - sorts in descending order
   * 3 click - Only applicable to documents, groups by level
   * @param {} filterType = Name or type
   */
  const handleHeaderClick = filterType => {
    setCurrentFilter(filterType)

    let columnsValues
    let dataValues
    if (numHeaderClicks === 0) {
      columnsValues = columns.map(column => {
        column.isSorted = false
        column.isSortedDesc = false
        return column
      })

      getProperties()
      setColumns(columnsValues)
      setUseBigTimeGrouping(false)
    } else if (numHeaderClicks === 1) {
      columnsValues = columns.map(column => {
        column.isSorted = true
        column.isSortedDesc = false
        return column
      })
      dataValues = data
      dataValues = dataValues.sort((a, b) => {
        const textA = a[filterType].toUpperCase()
        const textB = b[filterType].toUpperCase()
        return textA > textB ? -1 : textA < textB ? 1 : 0
      })
      setData(dataValues)
      setColumns(columnsValues)
    } else if (numHeaderClicks === 2) {
      columnsValues = columns.map(column => {
        column.isSorted = true
        column.isSortedDesc = true
        return column
      })
      dataValues = data
      dataValues = dataValues.sort((a, b) => {
        const textA = a[filterType].toUpperCase()
        const textB = b[filterType].toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      setData(dataValues)
      setColumns(columnsValues)
      setUseBigTimeGrouping(false)
    } else if (numHeaderClicks === 3) {
      columnsValues = columns.map(column => {
        column.isSorted = false
        column.isSortedDesc = false
        return column
      })
      setColumns(columnsValues)
      setUseBigTimeGrouping(true)
    }

    setNumHeaderClicks(numHeaderClicks + 1)
    if (numHeaderClicks >= 3) setNumHeaderClicks(0)
  }

  return (
    <>
      {displayAddDocument ? (
        <AddDocument
          config={config}
          getProperties={getProperties}
          finishUpdate={() => {
            setDisplayManageDocuments(true)
            setDisplayAddDocument(false)
          }}
          targetProperty={targetProperty}
          cancelEdit={() => {
            setDisplayManageDocuments(true)
            setDisplayAddDocument(false)
          }}
        />
      ) : null}
      {displayDeleteDocumentPopup ? (
        <DeleteDocumentPopup
          config={config}
          targetProperty={targetProperty}
          setDisplayDeleteDocumentPopup={setDisplayDeleteDocumentPopup}
          getProperties={getProperties}
        />
      ) : null}
      {displayEditDocumentTab ? (
        <EditDocument
          config={config}
          getProperties={getProperties}
          finishUpdate={() => {
            setDisplayManageDocuments(true)
            setDisplayEditDocumentTab(false)
          }}
          cancelEdit={() => {
            setDisplayManageDocuments(true)
            setDisplayEditDocumentTab(false)
          }}
          targetProperty={targetProperty}
        />
      ) : null}
      {displayManageDocuments ? (
        <>
          <Table
            columns={columns}
            data={data}
            handleHeaderClick={handleHeaderClick}
            useBigTimeGrouping={useBigTimeGrouping}
          />
        </>
      ) : null}
    </>
  )
}

export default React.memo(ManageDocuments)
