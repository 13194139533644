import { LOGIN, REFRESH, LOGOUT, GET_INFO, ERROR } from '../actions/types'

export default function UserReducer(state = null, action) {
  switch (action.type) {
    case LOGIN: {
      return action.payload
    }
    case REFRESH: {
      return action.payload
    }
    case LOGOUT: {
      return action.payload
    }
    case GET_INFO: {
      return action.payload
    }
    case ERROR: {
      //ERROR HANDLING
      return state
    }

    default:
      return state
  }
}
