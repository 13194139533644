import React, { useState, useEffect } from 'react'
import scss from './ElementOrder.scss'
import appScss from '../../../App/App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { clone } from '../../../../utilities/geospatial'
const ElementOrder = ({ elements, activeElement, handleUpdateElements }) => {
  const [currentPostion, setCurrentPostion] = useState(null)
  const bringElementForward = () => {
    if (currentPostion === elements.length - 1) return
    const newElements = array_move(
      clone(elements),
      currentPostion,
      currentPostion + 1
    )
    handleUpdateElements(newElements)
  }

  const sendElementBackwrad = () => {
    if (currentPostion === 0) return
    const newElements = array_move(
      clone(elements),
      currentPostion,
      currentPostion - 1
    )
    handleUpdateElements(newElements)
  }

  const getElementPosition = () =>
    elements.findIndex(element => element.id === activeElement.id)

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  useEffect(() => {
    const position = getElementPosition()
    setCurrentPostion(position)
  }, [elements])

  return (
    <>
      <div className={scss.styleHeading}> ELEMENT ORDER</div>

      <div className={scss.inputContainer}>
        <button
          className={scss.printButton}
          onClick={bringElementForward}
          data-tip='Bring Forward'
          data-for='print-element-bring-forward'
          data-effect='solid'
          data-arrow-color='rgba(0, 0, 0, 0.68)'
        >
          <FontAwesomeIcon icon={['fas', 'bring-forward']} size='1x' />
        </button>
        <button
          className={scss.printButton}
          onClick={sendElementBackwrad}
          data-tip='Send Backward'
          data-for='print-element-send-backward'
          data-effect='solid'
          data-arrow-color='rgba(0, 0, 0, 0.68)'
        >
          <FontAwesomeIcon icon={['fas', 'send-backward']} size='1x' />
        </button>
        <div className={scss.printFlyoutSubHeading}>
          {currentPostion !== 'null' && (
            <span>
              Order: {currentPostion + 1} of {elements.length}{' '}
            </span>
          )}
        </div>
      </div>
      <div className={appScss.reactToolTip}>
        <ReactTooltip
          id='print-element-bring-forward'
          getContent={dataTip => `${dataTip}`}
        />
        <ReactTooltip
          id='print-element-send-backward'
          getContent={dataTip => `${dataTip}`}
        />
      </div>
    </>
  )
}

export default ElementOrder
