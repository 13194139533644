import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updateMapStyle,
  setDoubleClickZoom,
  setMapToolTip,
  setMapCursor,
} from '../../../actions/index'
import {
  clone,
  getMouseCoords,
  getMapCenter,
} from '../../../utilities/geospatial'
import { POINT_FEATURE } from '../../../data/Constants/Constants'
import { fromJS } from 'immutable'
import {
  MEASURE_ACTIVE_POINT,
  MEASURE_POINT_COLD,
  MEASURE_LABEL_HOT,
  MEASURE_LABEL_COLD,
} from './MeasureConstants'
import { numberFormat } from '../../../utilities/util'
const UNITS_OF_PERCISION = 2

// NOTE:  We are using React Class component due to
// complexity and issues with hooks and window events
// this is not ideal but for now delivers the best results
class MeasurePoint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mouseDownCenter: null,
    }
  }

  handleMouseDown = e => {
    if (this.props.overPanel) return
    const mouseDownCenter = getMapCenter(this.props.viewport)
    this.setState({ mouseDownCenter })
  }

  handleMouseUp = e => {
    if (this.props.overPanel) return
    if (this.state.mouseDownCenter !== getMapCenter(this.props.viewport)) return
    this.addPointToHotMeasureSource(e)
  }

  handleMouseMove = e => {
    if (this.props.overPanel) {
      const style = this.props.mapStyle.toJS()
      const activePoint = style.sources[MEASURE_ACTIVE_POINT]
      if (activePoint.data.features.length) {
        this.clearHotLayers(style)
        this.props.updateMapStyle(fromJS(style))
      }
    } else {
      this.updateHotMeasureLayers(e)
    }
  }

  addPointToHotMeasureSource = e => {
    const style = this.props.mapStyle.toJS()
    const coords = getMouseCoords(
      e.layerX || e.offsetX,
      e.layerY || e.offsetY,
      this.props.viewport
    )

    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    const coldMeasurePoint = style.sources[MEASURE_POINT_COLD]
    const coldMeasureLabel = style.sources[MEASURE_LABEL_COLD]
    if (!activePoint.data.features.length) {
      activePoint.data.features.push(clone(POINT_FEATURE))
    }
    const point = clone(POINT_FEATURE)
    point.geometry.coordinates = coords
    coldMeasurePoint.data.features.push(point)
    const featureCoords = this.getFeatureCoords(point)
    coldMeasureLabel.data.features.push(featureCoords)
    this.props.updateMapStyle(fromJS(style))
  }

  updateHotMeasureLayers = e => {
    const style = this.props.mapStyle.toJS()
    const coords = getMouseCoords(
      e.layerX || e.offsetX,
      e.layerY || e.offsetY,
      this.props.viewport
    )

    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    // check for features in coldData, if no features add an empty one
    if (!activePoint.data.features.length) {
      activePoint.data.features.push(clone(POINT_FEATURE))
    }

    activePoint.data.features[0].geometry.coordinates = coords

    this.props.updateMapStyle(fromJS(style))
  }

  getFeatureCoords = feature => {
    const coords = feature.geometry.coordinates
    const lon = numberFormat(coords[0], 5)
    const lat = numberFormat(coords[1], 5)
    feature.properties['measurement'] = `${lat}`
    feature.properties['measurementSecondLine'] = `${lon}`
    return feature
  }

  getAreaFeature = feature => {
    const area = getArea(feature)
    const formattedArea = this.formatArea(area)
    const center = getCenterOfMass(feature)
    center.properties['measurement'] = formattedArea
    return center
  }

  formatArea = area => {
    let formatted = area
    formatted = formatted * 10.7639
    if (formatted > 43560) {
      formatted = formatted / 43560
      formatted = formatted.toFixed(UNITS_OF_PERCISION)
      formatted = formatted + ' ac'
    } else {
      formatted = formatted.toFixed(0)
      formatted = formatted + ' sq ft'
    }

    return formatted
  }

  clearHotLayers = style => {
    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    const hotMeasureLabel = style.sources[MEASURE_LABEL_HOT]
    if (activePoint.data.features.length) {
      activePoint.data.features = []

      hotMeasureLabel.data.features = []
    }
  }

  showMeasureToolTip = () => {
    if (!this.props.mapToolTip.show) {
      this.props.setMapToolTip({
        show: true,
        tipHtml: (
          <>
            <div>Double Click</div>
            <div>to Finish</div>
          </>
        ),
      })
    }
  }

  hideMeausureToolTip = () => {
    if (this.props.mapToolTip.show) {
      this.props.setMapToolTip({
        show: false,
        tipHtml: null,
      })
    }
  }
  enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousedown', this.handleMouseDown, true)
    main.addEventListener('mouseup', this.handleMouseUp, true)
    main.addEventListener('mousemove', this.handleMouseMove, true)
    main.addEventListener('dblclick', this.handleDoubleClick, true)
  }

  disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousedown', this.handleMouseDown, true)
    main.removeEventListener('mouseup', this.handleMouseUp, true)
    main.removeEventListener('mousemove', this.handleMouseMove, true)
    main.removeEventListener('dblclick', this.handleDoubleClick, true)
  }

  componentDidMount = () => {
    this.enableEventListeners()
    this.props.setMapCursor('crosshair')
  }

  componentWillUnmount = () => {
    let style = this.props.mapStyle.toJS()
    this.clearHotLayers(style)
    this.disableEventListeners()
    this.props.updateMapStyle(fromJS(style))
    this.props.setMapCursor('grab')
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
      setDoubleClickZoom: setDoubleClickZoom,
      setMapToolTip: setMapToolTip,
      setMapCursor: setMapCursor,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
    viewport: state.viewport,
    overPanel: state.setOverPanel,
    mapToolTip: state.mapToolTip,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasurePoint)
