export const defaultToolConfig = [
  {
    name: 'Draw',
    title: 'Draw',
    icon: 'pencil-alt',
    toc: true,
    visible: false,
    fixedTop: true
  },
  {
    name: 'Legend',
    title: 'Legend',
    icon: ['fal', 'list-alt'],
    toc: true,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: null,
    minWidth: 230,
  },
  {
    name: 'LayerSettings',
    title: 'Layer Settings',
    icon: ['fal', 'cog'],
    toc: false,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: null,
    layer: null,
    minHeight: 568,
    minWidth: 389,
  },
  {
    name: 'GroupSettings',
    title: 'Group Settings',
    icon: ['fal', 'cog'],
    toc: false,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: null,
    group: null,
    minHeight: 492,
    minWidth: 389,
  },
  {
    name: 'MapViewSettings',
    title: 'Map View Settings',
    icon: ['fal', 'map-marked-alt'],
    toc: false,
    visible: false,
    positionX: null,
    positionY: null,
    mapViewGroup: null,
    minHeight: 400,
    minWidth: 389,
  },
  {
    name: 'DataImport',
    title: 'Data Import',
    icon: ['fal', 'cloud-upload-alt'],
    toc: true,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: 40,
  },
  {
    name: 'Notes',
    title: 'Notes',
    icon: ['fal', 'map'],
    toc: false,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: 40,
    minWidth: 305,
  },
  {
    name: 'SelectFeatures',
    title: 'Select Features',
    icon: ['fal', 'mouse-pointer'],
    toc: false,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: 40,
  },
  {
    name: 'Documents',
    title: 'Documents',
    icon: ['fal', 'file'],
    toc: false,
    visible: false,
    collapsed: false,
    positionX: null,
    positionY: 40,
  },
]
