/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import scss from '../PropertyTabV2.scss'

// Create and export the TabItem component
export const TabItemV2 = props => {
  const { item, isClone, index } = props

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // isClone={ isClone }
          ref={provided.innerRef}
          className={[
            snapshot.isDragging ? scss.itemBeingHeld : '',
            scss.item,
          ].join(' ')}
          // isDragging={snapshot.isDragging}
          title='Drag and drop attribute'
        >
          {/* The content of the TabItem */}
          {item.content}
        </div>
      )}
    </Draggable>
  )
}
