import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DocumentKey from '../DocumentBrowser/DocumentKey'
import DocumentBrowserV2 from '../DocumentBrowserV2/DocumentBrowserV2'
import scss from './PopupHeader.scss'
import appScss from '../App/App.scss'

import * as utils from '../../utilities/util'

const AttributeDocumentButtons = ({
  mamId,
  featureProperties,
  closePopup,
  config,
  popupInfo,
  index,
  layerId,
  dataConfigLayer,
  active,
  layerProperties,
}) => {
  const [documentFeature, setDocumentFeature] = useState(null)
  const [openDocumentKey, setOpenDocumentKey] = useState(null)
  const [fetchingDocuments, setFetchingDocuments] = useState(null)

  let canEdit = false
  if (dataConfigLayer && dataConfigLayer.toc)
    canEdit = dataConfigLayer.toc.canEdit

  const openDocuments = () => {
    const featureId = popupInfo.features[index].properties.mamid
    setDocumentFeature(featureId)
  }

  let userPopupButtons =
    dataConfigLayer && dataConfigLayer.toc.userLayer ? (
      <div className={scss.measurementButtonRow}>
        {fetchingDocuments ? (
          <button>
            <FontAwesomeIcon icon='spinner' size='1x' spin />
          </button>
        ) : (
          <>
            {canEdit && (
              <button
                onClick={() => setOpenDocumentKey(true)}
                className={scss['icon-custom-file-key']}
                data-tip='Document Key'
                data-for='document-key'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
                style={{ display: 'none' }}
                disabled
              >
                <FontAwesomeIcon
                  className={scss['icon-custom-file']}
                  icon='file'
                />
                <FontAwesomeIcon
                  className={scss['icon-custom-key']}
                  icon='key'
                />
              </button>
            )}
            <button
              onClick={openDocuments}
              data-tip='Document Browser'
              data-for='document-browser'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
              style={{ display: 'none' }}
              disabled
            >
              <FontAwesomeIcon icon='folder-tree' />
            </button>
            <div className={appScss.reactToolTip}>
              <ReactTooltip
                id='document-browser'
                getContent={dataTip => `${dataTip}`}
              />
              <ReactTooltip
                id='document-key'
                getContent={dataTip => `${dataTip}`}
              />
            </div>
          </>
        )}
      </div>
    ) : null

  return (
    <>
      {openDocumentKey && (
        <DocumentKey
          key={'documentKey_docPanel'}
          layerId={layerId}
          layerProperties={layerProperties[layerId]}
          modal
          onClose={() => setOpenDocumentKey(false)}
        />
      )}
      {documentFeature && (
        <DocumentBrowserV2
          featureProperties={featureProperties}
          layerId={layerId}
          mamId={mamId}
          documentFeature={documentFeature}
          layerProperties={layerProperties}
          onClose={() => setDocumentFeature(null)}
          isSelectDocument={false}
          popupKey={true}
          canEdit={canEdit}
        />
      )}
      {userPopupButtons}
    </>
  )
}
export default AttributeDocumentButtons
