import React, { useState, useEffect } from 'react'

function Scale(props) {
  const { viewport, object, mapRef, handleChangeScale } = props

  const getDecimalRoundNum = d => {
    const multiplier = Math.pow(10, Math.ceil(-Math.log(d) / Math.LN10))
    return Math.round(d * multiplier) / multiplier
  }

  const getRoundNum = num => {
    const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1)
    let d = num / pow10

    d =
      d >= 10
        ? 10
        : d >= 5
        ? 5
        : d >= 3
        ? 3
        : d >= 2
        ? 2
        : d >= 1
        ? 1
        : getDecimalRoundNum(d)

    return pow10 * d
  }

  const wrapperStyle = {
    borderColor: !object.borderTransparent
      ? `${object.borderColor}`
      : 'transparent',
    borderWidth: object.borderWidth + 'px',
    borderStyle: 'solid',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  const maxWidth = 100
  const [ratio, setRatio] = useState(null)
  const [distance, setDistance] = useState(null)
  const [units, setUnits] = useState('feet')

  useEffect(() => {
    if (mapRef) {
      const map = mapRef.getMap()
      if (!map) return

      const y = map._container.clientHeight / 2
      const left = map.unproject([0, y])
      const right = map.unproject([maxWidth, y])
      const maxMeters = left.distanceTo(right)

      let maxDistance = 3.2808 * maxMeters
      let u = units
      if (maxDistance > 5280) {
        maxDistance = maxDistance / 5280
        u = 'miles'
      }

      let d = getRoundNum(maxDistance)
      let r = d / maxDistance
      setDistance(d)
      setRatio(r)
      setUnits(u)
      handleChangeScale(d, r, u, maxWidth)
    }
  }, [mapRef, viewport])

  return (
    <>
      <div style={wrapperStyle}>
        {maxWidth && (
          <div>
            <div
              style={{
                fontSize: `10px`,
              }}
            >
              {distance} {units}
            </div>
            <div
              style={{
                width: `${maxWidth * ratio}px`,
                height: `10px`,
                background: `white`,
                border: `solid 1px black`,
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  )
}
export default Scale
