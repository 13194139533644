import { combineReducers } from 'redux'
import SetAccountKey from './reducer_account'
import ViewPortReducer from './reducer_viewport'
import MapStyleReducer, { MapRefReducer } from './reducer_mapstyle'
import ToolReducer, {
  MountLayerSettings,
  MountMapViews,
  OpenNotes,
} from './reducer_tools'
import {
  DoubleClickZoom,
  SetDragPan,
  SetMapZoom,
  SetScrollZoom,
  SetOverPanel,
  DisablePopup,
} from './reducer_map_interactions'
import PopupReducer, { DockPopupReducer } from './reducer_popup'
import SetMapCursor from './reducer_map_cursor'
import AlertReducer from './reducer_alert'
import UserReducer from './reducer_user'
import SetMapMode from './reducer_map_mode'
import UpdateDataConfig from './reducer_data_config'
import SwitchPage from './reducer_page'
import {
  TocCollapsed,
  TocFilter,
  GroupClicked,
  LayerClicked,
  FilterChanged,
} from './reducer_toc'
import { FooterHeight } from './reducer_footer'
import { AsideWidth } from './reducer_aside'
import { DataTable } from './reducer_data_table'
import { SelectFeatures } from './reducer_set_select_features'
import { CommonLayerLabels } from './reducer_labels'
import MapToolTip from './reducer_map_tool_tip'
import DrawMode, { SnapSettings, DrawMeasureSettings } from './reducer_draw'
import MeasureMode from './reducer_measure_mode'
import { VisibleLayers } from './reducer_group_toggle'
import ExportSettings from './reducer_export'
import Geolocation from './reducer_geolocate'

const rootReducer = combineReducers({
  accountKey: SetAccountKey,
  viewport: ViewPortReducer,
  mapStyle: MapStyleReducer,
  mapRef: MapRefReducer,
  toolConfig: ToolReducer,
  mountLayerSettings: MountLayerSettings,
  mountMapViews: MountMapViews,
  mapViewNotes: OpenNotes,
  doubleClickZoom: DoubleClickZoom,
  setDragPan: SetDragPan,
  setMapZoom: SetMapZoom,
  setScrollZoom: SetScrollZoom,
  setOverPanel: SetOverPanel,
  popupReducer: PopupReducer,
  popupDocked: DockPopupReducer,
  mapCursor: SetMapCursor,
  disablePopup: DisablePopup,
  alert: AlertReducer,
  user: UserReducer,
  mapMode: SetMapMode,
  updateDataConfig: UpdateDataConfig,
  switchPage: SwitchPage,
  tocCollapsed: TocCollapsed,
  tocFilter: TocFilter,
  groupClicked: GroupClicked,
  layerClicked: LayerClicked,
  footerHeight: FooterHeight,
  asideWidth: AsideWidth,
  dataTable: DataTable,
  selectFeatures: SelectFeatures,
  commonLayerLabels: CommonLayerLabels,
  mapToolTip: MapToolTip,
  drawMode: DrawMode,
  measureMode: MeasureMode,
  snapSettings: SnapSettings,
  exportSettings: ExportSettings,
  drawMeasureSettings: DrawMeasureSettings,
  visibleLayers: VisibleLayers,
  filterChanged: FilterChanged,
  geolocation: Geolocation,
})

export default rootReducer
