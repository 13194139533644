import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMapStyle,
  setVisibleLayers,
  setMapRef,
} from '../../../actions/index'
import { BASIC_STYLE } from '../../../mapStyles/basic/style.js'
import { fromJS } from 'immutable'
import queryString from 'query-string'
import {
  addLayerToStyle,
  addSourceToStyle,
  addTocLayersToMapStyle2,
} from '../../../utilities/mapStyle'
import { clone } from '../../../utilities/geospatial'

const InitMapStyle = ({ setMapLoaded, location, mapRef }) => {
  const dispatch = useDispatch()
  const dataConfig = useSelector(state => state.updateDataConfig)
  const user = useSelector(state => state.user)

  const getUrlLayers = () => {
    let { visibleLayers } = queryString.parse(location.search)
    if (!visibleLayers) {
      visibleLayers = []
    } else {
      visibleLayers = visibleLayers.replace('[', '')
      visibleLayers = visibleLayers.replace(']', '')
      visibleLayers = visibleLayers.split(',')
    }

    return visibleLayers
  }
  const AddUserLayersToStyle = () => {
    let visibleLayers = []

    if (location && location.search) {
      visibleLayers = getUrlLayers()
    } else if (user && user.preferences && user.preferences['layer.visibility.history'] && user.preferences['layer.visibility.history'].length > 0) {
      visibleLayers = user.preferences['layer.visibility.history'][0].value ?? []
    }

    const dConfig = clone(dataConfig)
    let mapStyle = clone(BASIC_STYLE)

    mapStyle = addTocLayersToMapStyle2(mapStyle, null, dConfig, visibleLayers)

    dConfig['auxiliaryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = layerObj.layer.layout.visibility
          mapStyle = addLayerToStyle(mapStyle, layerObj, visible)
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source =>
            (mapStyle = addSourceToStyle(
              mapStyle,
              source,
              config.layersArray[0].source
            ))
        )
      }
    })
    dConfig['imageryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = 'none'
          if (visibleLayers && visibleLayers.includes(layerObj.layer.id)) {
            visible = 'visible'
          }
          mapStyle = addLayerToStyle(mapStyle, layerObj, visible)
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (mapStyle = addSourceToStyle(mapStyle, source))
        )
      }
    })
    dispatch(setMapStyle(fromJS(mapStyle)))
    dispatch(setVisibleLayers(visibleLayers))
    setMapLoaded()
  }

  useEffect(() => {
    AddUserLayersToStyle()
  }, [])

  //Handle the setup of mapRef in dispatch
  useEffect(() => {
    if (mapRef) dispatch(setMapRef(mapRef))
  }, [mapRef])

  return null
}

export default InitMapStyle
