import { BASIC_STYLE } from './basic/style.js'
import { TERRAIN_STYLE } from './terrain/style.js'
import { TONER_STYLE } from './toner/style.js'
import { BLANK_STYLE } from './blank/style.js'
import { fromJS } from 'immutable'
import mapBasicIcon from '../assets/icons/mapBasic.svg'
import mapTerrainIcon from '../assets/icons/mapTerrain.svg'
import mapTonerIcon from '../assets/icons/mapToner.svg'
import mapBlankIcon from '../assets/icons/mapBlank.svg'

// styleSwitcher is used in reducer_mapstyle.js to toggle between map styles
export const styleToggleObject = {}
styleToggleObject.basic = fromJS(BASIC_STYLE)
styleToggleObject.terrain = fromJS(TERRAIN_STYLE)
styleToggleObject.toner = fromJS(TONER_STYLE)
styleToggleObject.blank = fromJS(BLANK_STYLE)

// mapStyles array is used in TOC.js to create the TOC buttons for map styles
export const MAP_STYLE_ARRAY = [
  {
    toc: 'styleLayer',
    id: 'basic',
    layerLabel: 'Basic',
    type: 'style',
    sourceType: 'style',
    icon: mapBasicIcon,
  },
  {
    toc: 'styleLayer',
    id: 'terrain',
    layerLabel: 'Terrain',
    type: 'style',
    sourceType: 'style',
    icon: mapTerrainIcon,
  },
  {
    toc: 'styleLayer',
    id: 'toner',
    layerLabel: 'Toner',
    type: 'style',
    sourceType: 'style',
    icon: mapTonerIcon,
  },
  {
    toc: 'styleLayer',
    id: 'blank',
    layerLabel: 'Blank',
    type: 'style',
    sourceType: 'style',
    icon: mapBlankIcon,
  },
]
