import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from '../../Map/Map.scss'

const FeatureSelectBtn = React.memo(({ toggleFeatureSelect }) => {
  return (
    <button
      className={scss['map-button']}
      onClick={toggleFeatureSelect}
      data-tip='Select Features'
      data-for='map-control'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <FontAwesomeIcon icon='mouse-pointer' />
    </button>
  )
})

export default FeatureSelectBtn
