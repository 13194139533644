import environment from './base'
const url = 'https://egd4avkav9.execute-api.us-east-1.amazonaws.com/prod/'
const keys = {
  google: 'AIzaSyDLEtv-r4eeZGEhvRE3kM8j6UBUdd98iIM', // prd google api key
  agGrid:
    'CompanyName=MyAssetMap,LicensedApplication=MyAssetMap,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011367,ExpiryDate=22_October_2021_[v2]_MTYzNDg1NzIwMDAwMA==8ee51042d12d1f00dc41e3b2da595d2f',
}
const env = environment(url, keys)

export default {
  ...env,
  apis: {
    ...env.apis,
  },
  keys: {
    ...env.keys,
  },
  env: 'prd',
  isPrd: true,
  isQa: false,
  isDev: false,
}
