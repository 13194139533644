import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './FeedbackButton.scss'

const FeedbackButton = ({ toggleFeedbackVisible }) => {
  const handleClick = () => {
    toggleFeedbackVisible()
  }

  return (
    <button
      className={scss.feedbackButton}
      data-tip='Submit Feedback'
      data-for='feedbackTooltip'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
      onClick={handleClick}
    >
      <FontAwesomeIcon icon='question-circle' size='1x' />
    </button>
  )
}

export default FeedbackButton
