import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import scss from '../ProfileStyling/ProfileModal.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  paymentCard,
  cardNumber,
  cardExpiration,
  cardSecurityCode,
  cardCountry,
  cardZip,
  visible,
}) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [paymentCardState, setPaymentCard] = useState(paymentCard)
  const [cardNumberState, setCardNumber] = useState(cardNumber)
  const [cardExpirationState, setCardExpiration] = useState(cardExpiration)
  const [cardSecurityCodeState, setCardSecurityCode] =
    useState(cardSecurityCode)
  const [cardCountryState, setCardCountry] = useState(cardCountry)
  const [cardZipState, setCardZip] = useState(cardZip)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/update'
    let body = {
      paymentCard: paymentCardState,
      cardNumber: cardNumberState,
      cardExpiration: cardExpirationState,
      cardSecurityCode: cardSecurityCodeState,
      cardCountry: cardCountryState,
      cardZip: cardZipState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const onPaymentCardChange = e => {
    setPaymentCard(e.target.value)
    setEnableSave(true)
  }
  const onCardNumberChange = e => {
    setCardNumber(e.target.value)
    setEnableSave(true)
  }
  const onCardExpirationChange = e => {
    setCardExpiration(e.target.value)
    setEnableSave(true)
  }
  const onCardSecurityCodeChange = e => {
    setCardSecurityCode(e.target.value)
    setEnableSave(true)
  }
  const onCardCountryChange = e => {
    setCardCountry(e.target.value)
    setEnableSave(true)
  }
  const onCardZipChange = e => {
    setCardZip(e.target.value)
    setEnableSave(true)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='export-to-csv-dialog'
        open={open}
        className={modalScss.lightModal}
        maxWidth='lg'
      >
        <DialogTitle id='export-to-csv'>
          Update Billing Information{' '}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div
            className={[scss.flexRow, scss.flexCenter, scss.paymentRadios].join(
              ' '
            )}
          >
            {/* Credit Card */}
            <input
              type='radio'
              id='creditCard'
              name='payment'
              value='creditCard'
            />
            <input
              min='0'
              max='4'
              className={scss.paymentCardInput}
              type='number'
            />
            <input
              min='0'
              max='4'
              className={scss.paymentCardInput}
              type='number'
            />
            <input
              min='0'
              max='4'
              className={scss.paymentCardInput}
              type='number'
            />
            <input
              min='0'
              max='4'
              className={scss.paymentCardInput}
              type='number'
            />

            {/* Alternative Payment */}
            <input type='radio' id='altPay' name='payment' value='altPay' />
            <input className={scss.paymentAltInput} type='number' />
          </div>

          <div
            className={[
              scss.flexRow,
              scss.flexCenter,
              scss.flexJustify,
              scss.paymentTopInput,
            ].join(' ')}
          >
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '50%' }}
            >
              <label for='creditCardNumber'>Credit card number</label>
              <input
                max='16'
                type='number'
                id='creditCardNumber'
                placeholder='1111 2222 3333 4444'
                value={cardNumberState}
                onSubmit={fetching ? null : handleSave}
                onChange={onCardNumberChange}
              />
            </div>
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '20%' }}
            >
              <label for='expiration'>Expiration date</label>
              <input
                max='5'
                id='expiration'
                placeholder='MM/YY'
                value={cardExpirationState}
                onSubmit={fetching ? null : handleSave}
                onChange={onCardExpirationChange}
              />
            </div>
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '20%' }}
            >
              <label for='security'>Security Code</label>
              <input
                max='3'
                type='number'
                id='security'
                placeholder=' '
                value={cardSecurityCodeState}
                onSubmit={fetching ? null : handleSave}
                onChange={onCardSecurityCodeChange}
              />
            </div>
            {/* <MuiFormControlText
              disabled={false}
              label="Credit card number"
              placeholder=" "
              onChange={onCardNumberChange}
              onSubmit={fetching ? null : handleSave}
              value={cardNumberState}
            />
            <MuiFormControlText
              disabled={false}
              label="Expiration date"
              placeholder="MM/YY"
              onChange={onCardExpirationChange}
              onSubmit={fetching ? null : handleSave}
              value={cardExpirationState}
            />
            <MuiFormControlText
              disabled={false}
              label="Security Code"
              placeholder=" "
              onChange={onCardSecurityCodeChange}
              onSubmit={fetching ? null : handleSave}
              value={cardSecurityCodeState}
            /> */}
          </div>

          <div
            className={[
              scss.flexRow,
              scss.flexCenter,
              scss.flexJustify,
              scss.paymentBottomInput,
            ].join(' ')}
          >
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '75%' }}
            >
              <label for='country'>Country</label>
              <select id='country' className={modalScss.lightAltSelect}>
                <option>United States</option>
              </select>
            </div>

            {/* <MuiFormControlText
              disabled={false}
              label="Billing ZIP"
              placeholder=" "
              onChange={onCardZipChange}
              onSubmit={fetching ? null : handleSave}
              value={cardZipState}
            /> */}
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '15%' }}
            >
              <label for='zip'>Billing Zip</label>
              <input
                max='5'
                type='number'
                id='zip'
                placeholder=' '
                value={cardZipState}
                onSubmit={fetching ? null : handleSave}
                onChange={onCardZipChange}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>
          <button
            className={appScss.blueButton}
            // onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileLanguage(props) {
  return <SimpleDialog {...props} />
}
