import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle } from '../../actions/index'
import {
  getCenterOfMass,
  getLineSegments,
  getLineMidPoint,
} from '../../utilities/geospatial'
import { fromJS } from 'immutable'
import { deepCopy, numberFormat } from '../../utilities/util'
import { profileDateFormat } from '../Profile/ProfileDate'

const LabelFeatures = () => {
  const dispatch = useDispatch()
  const mapRef = useSelector(state => state.mapRef)
  const mapStyle = useSelector(state => state.mapStyle)
  const viewport = useSelector(state => state.viewport)
  const user = useSelector(state => state.user)
  const commonLayerLabels = useSelector(state => state.commonLayerLabels)
  const [lastViewPort, setLastViewPort] = useState(null)

  const buildLabels = () => {
    if (!mapRef) return
    let style = mapStyle.toJS()
    // get minzoom of all labels
    const labelZooms = commonLayerLabels.map(labelObj => labelObj.zoom)
    const minZoom = Math.min(...labelZooms)

    // set minzoom to label layers
    commonLayerLabels.forEach(labelObj => {
      style = changeSymbolLayerMinZoom(style, labelObj)
    })

    // if map is zoomed out beyond minzoom no need to build lables
    if (viewport.zoom < minZoom) {
      dispatch(updateMapStyle(fromJS(style)))
      return
    }
    commonLayerLabels.forEach(labelObj => {
      if (!mapRef) return
      if (!mapRef.getMap()) return
      if (labelObj.attribute) {
        const renderedFeatures = mapRef
          .getMap()
          .queryRenderedFeatures({ layers: [labelObj.layerId] })

        let mamIds = []
        let unique = []

        for (const feature of renderedFeatures) {
          if (!mamIds.includes(feature.properties.mamid)) {
            mamIds.push(feature.properties.mamid)
            unique.push(feature)
          }
        }

        if (!unique.length) return
        const labelFeats = unique.map(feature => {
          let labelPoint = null

          if (
            feature.geometry.type === 'Polygon' ||
            feature.geometry.type === 'MultiPolygon'
          ) {
            labelPoint = getCenterOfMass(feature)
          }
          if (feature.geometry.type === 'MultiPolygon') {
            labelPoint = getCenterOfMass(feature)
          }
          if (feature.geometry.type === 'LineString') {
            labelPoint = getLineMidPoint(feature)
          }
          if (feature.geometry.type === 'MultiLineString') {
            const segments = getLineSegments(feature)
            labelPoint = getLineMidPoint(segments.features[0])
          }
          if (
            feature.geometry.type === 'Point' ||
            feature.geometry.type === 'MultiPoint'
          ) {
            const pointFeature = {
              type: 'Feature',
              geometry: feature.geometry,
            }
            labelPoint = pointFeature
          }
          if (labelPoint) {
            labelPoint.geometry.coordinates = [
              labelPoint.geometry.coordinates[0].toFixed(5), // truncate to help reduce size
              labelPoint.geometry.coordinates[1].toFixed(5), // truncate to help reduce size
            ]
            let featureAttributes = deepCopy(feature.properties)
            const featureAttributeType = labelObj.attributeType
            const featureAttributeKey = labelObj.attribute
            if (featureAttributes[featureAttributeKey] != null) {
              if (featureAttributeType === 'date')
                featureAttributes[featureAttributeKey] = profileDateFormat({
                  dateFormat: user.profile.dateFormat,
                  date: featureAttributes[featureAttributeKey],
                })
              if (featureAttributeType === 'currency')
                featureAttributes[featureAttributeKey] =
                  '$' + numberFormat(featureAttributes[featureAttributeKey], 2)
            }
            labelPoint.properties = featureAttributes
          } else {
            // labelPoint was not returned from turf function,
            // create a null feature to return
            labelPoint = {
              type: 'Feature',
              geometry: { type: 'Point', coordinates: [0, 0] },
              properties: {},
            }
          }

          return labelPoint
        })

        style = setSymbolLayer(style, labelObj)
        style = setSymbolLayerSource(style, labelObj, labelFeats)
      } else {
        style = setSymbolLayerSource(style, labelObj, [])
      }
    })

    dispatch(updateMapStyle(fromJS(style)))
  }

  const setSymbolLayer = (style, labelObj) => {
    let symbolLayer = style.layers.filter(
      styleLayer => styleLayer.id === labelObj.layerId + '_symbol'
    )

    if (symbolLayer[0]) {
      symbolLayer[0].source = labelObj.layerId + '_dynamic_labels'
      symbolLayer[0].layout['text-field'] = ['get', labelObj.attribute]
      symbolLayer[0].layout['text-font'] = ['Noto Sans Regular']
      symbolLayer[0].layout['text-allow-overlap'] = false
      if (!symbolLayer[0].layout['text-size']) {
        symbolLayer[0].layout['text-size'] = [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          12,
          24,
          14,
        ]
      }

      symbolLayer[0].minzoom = labelObj.zoom

      symbolLayer[0].paint['text-halo-color'] = '#ffffff'

      if (typeof symbolLayer[0].layout['text-allow-overlap'] === 'undefined')
        symbolLayer[0].layout['text-allow-overlap'] = false

      if (typeof symbolLayer[0].paint['text-halo-width'] === 'undefined') {
        symbolLayer[0].paint['text-halo-width'] = [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          1,
          14,
          2,
        ]
      }

      if (!symbolLayer[0].paint['text-color']) {
        symbolLayer[0].paint['text-color'] = '#000000'
      }

      if (symbolLayer[0]['source-layer']) {
        delete symbolLayer[0]['source-layer']
      }
    }
    const layers = style.layers.filter(
      layer => layer.id !== labelObj.layerId + '_symbol'
    )
    const index = layers.length - 1
    layers.splice(index, 0, symbolLayer[0])
    style.layers = layers
    return style
  }

  const setSymbolLayerSource = (style, labelObj, labelFeats) => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: [...labelFeats],
    }

    style.sources[labelObj.layerId + '_dynamic_labels'] = {
      type: 'geojson',
      data: featureCollection,
      buffer: 0,
      maxzoom: 15,
    }

    return style
  }

  const changeSymbolLayerMinZoom = (style, labelObj) => {
    let symbolLayer = style.layers.filter(
      styleLayer => styleLayer.id === labelObj.layerId + '_symbol'
    )
    symbolLayer[0].minzoom = labelObj.zoom
    return style
  }

  useEffect(() => {
    setLastViewPort({
      lat: viewport.latitude.toFixed(3),
      lng: viewport.longitude.toFixed(3),
    })
    buildLabels()
  }, [])

  useEffect(() => {
    if (!lastViewPort) return // viewport has not been set
    if (
      viewport.latitude.toFixed(3) !== lastViewPort.lat ||
      viewport.longitude.toFixed(3) !== lastViewPort.lng
    ) {
      // rebuild lables if viewport has changed
      // not we truncate lat and lang to 3 decimals to prevent updates on minor viewport changes
      setLastViewPort({
        lat: viewport.latitude.toFixed(3),
        lng: viewport.longitude.toFixed(3),
      })
      buildLabels()
    }
  }, [viewport])

  useEffect(() => {
    if (commonLayerLabels.length) {
      buildLabels()
    }
  }, [commonLayerLabels])

  return null
}

export default LabelFeatures
