import React, { useState, useEffect, useCallback } from 'react'
import SelectFeaturesForDelete from './SelectFeaturesForDelete'
import DeleteFeatures from './DeleteFeatures'
import DeleteHotKeys from './DeleteHotKeys'

const Delete = ({ targetLayer, geometry, mapRef, clearEdits }) => {
  const [deleteMode, setDeleteMode] = useState('SELECT_FEATURES_FOR_DELETE')

  const finishDelete = useCallback(() => {
    setDeleteMode('SELECT_FEATURES_FOR_DELETE')
  })

  const cancelDelete = useCallback(() => {
    setDeleteMode('SELECT_FEATURES_FOR_DELETE')
  })

  const handleDeleteClick = () => setDeleteMode('DELETE_FEATURES')

  useEffect(() => {
    if (clearEdits) {
      setDeleteMode('SELECT_FEATURES_FOR_DELETE')
    }
  }, [clearEdits])

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <>
      <DeleteHotKeys
        cancelDelete={cancelDelete}
        handleDeleteClick={handleDeleteClick}
      />
      {deleteMode === 'SELECT_FEATURES_FOR_DELETE' ? (
        <SelectFeaturesForDelete
          id={targetLayer.value}
          geometry={geometry}
          mapRef={mapRef}
        />
      ) : null}
      {deleteMode === 'DELETE_FEATURES' ? (
        <DeleteFeatures
          geometry={geometry}
          id={targetLayer.value}
          finishDelete={finishDelete}
        />
      ) : null}
    </>
  )
}

export default Delete
