import React, { Component } from 'react'
import scss from './PopupSettings.scss'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { PropertyTab } from './PropertyTab/PropertyTab'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddTabButton from './PropertyTab/AddTabButton'

import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class PopupSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      layoutName: '',
      validLayoutName: '',
      doingSave: false,
      items: {},
      tabs: {},
      tabOrder: ['propertiesHolder'],
      urlObjects: null,
      mountDataConfig: null,
    }
  }

  componentDidMount = () => {
    const { layerProperties, config } = this.props
    let items = {}
    const itemsIds = []
    const tabOrder = []
    layerProperties.sort((a, b) => (a.key > b.key ? 1 : -1))
    layerProperties.forEach(property => {
      const id = property.id.toString()
      items[id] = { id: id, content: property.name }
      itemsIds.push(id)
    })

    const propertiesHolder = {
      id: 'propertiesHolder',
      title: 'Unassigned',
      itemsIds: itemsIds,
    }

    let tabs = {
      propertiesHolder: propertiesHolder,
    }

    if (config.tabs) {
      tabs = config.tabs
    }

    for (const tab in tabs) {
      tabOrder.push(tab)
    }

    tabs = this.addNewPropsToTabs(tabs, layerProperties)
    tabs = this.removeDeletedPropsFromTabs(tabs, layerProperties)

    this.setState({ items, tabs, tabOrder })
  }

  addNewPropsToTabs = (tabs, layerProperties) => {
    // adds any newly added properties to tabs placeholder
    layerProperties.forEach(property => {
      let propertyFound = false
      let id = property.id.toString()
      for (let tab in tabs) {
        const itemsIds = tabs[tab].itemsIds
        const found = itemsIds.includes(id)
        if (found) propertyFound = true
      }

      if (!propertyFound) {
        tabs.propertiesHolder.itemsIds.push(id)
      }
    })
    return tabs
  }

  removeDeletedPropsFromTabs = (tabs, layerProperties) => {
    // removes any deleted properties from tabs
    let allIds = []
    for (let tab in tabs) {
      allIds = [...allIds, ...tabs[tab].itemsIds]
    }

    allIds.forEach(id => {
      const found = layerProperties.filter(
        property => property.id === parseInt(id)
      )
      if (!found.length) {
        // property has been deleted, remove from tabs
        for (let tab in tabs) {
          const newIds = tabs[tab].itemsIds.filter(itemId => itemId !== id)
          tabs[tab].itemsIds = newIds
        }
      }
    })
    return tabs
  }

  addTab = () => {
    let { tabs, tabOrder } = this.state
    let tabCount = Object.keys(tabs).length
    const tabId = 'tab-' + tabCount++
    const newTab = {
      id: tabId,
      title: 'Tab ' + tabCount++,
      itemsIds: [],
    }

    tabs[tabId] = newTab
    tabOrder.push(tabId)
    this.setState({ tabs, tabOrder })
  }

  deleteTab = id => {
    let { tabs, tabOrder } = this.state
    const itemsIds = tabs[id].itemsIds
    const propertiesHolderIds = tabs['propertiesHolder'].itemsIds
    tabs['propertiesHolder'].itemsIds = [...propertiesHolderIds, ...itemsIds]
    delete tabs[id]
    tabOrder = tabOrder.filter(tab => tab !== id)
    this.setState({ tabs, tabOrder })
  }

  tabReorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  handleTitleChange = e => {
    let { tabs } = this.state
    tabs[e.target.name].title = e.target.value
    this.setState({ tabs })
  }

  onClose = () => this.setState({ doingSave: false })

  getExistingMetadata = () => {
    let style = this.props.mapStyle.toJS()
    const layer = style.layers.filter(layer => layer.id === this.props.layerId)
    const metadata = layer[0].metadata || {}
    return metadata
  }

  saveConfiguration = () => {
    const { tabs, tabOrder } = this.state
    const { config, layerId } = this.props
    let tabConfig = {}

    if (typeof tabs['propertiesHolder'] !== 'undefined') {
      tabConfig['propertiesHolder'] = tabs['propertiesHolder']
    }

    tabOrder.forEach(tab => {
      if (tab in tabs) {
        tabConfig[tab] = tabs[tab]
      }
    })

    config.tabs = tabConfig

    let existingMetaData = this.getExistingMetadata()
    existingMetaData.popup = config

    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/update/layer'
    const body = {
      layerID: layerId,
      metadata: existingMetaData,
    }

    const urlObjects = [{ url, body, method }]
    this.setState({ doingSave: true, urlObjects: urlObjects }, () => {
      this.setState({ doingSave: true, urlObjects: null })
    })
  }

  fetchFinished = () => {
    this.setState({ mountDataConfig: true }, () => {
      this.setState({ mountDataConfig: false })
    })
  }

  dataConfigUpdated = () => {
    this.setState({ doingSave: false })
    this.setState({ open: false })
    this.props.onClose()
  }

  // Handle tab drag and drop
  onTabMove = result => {
    // dropped outside of list
    if (!result.destination) {
      return
    }

    const tabOrder = this.tabReorder(
      this.state.tabOrder,
      result.source.index,
      result.destination.index
    )

    this.setState({
      tabOrder,
    })
  }

  // Handle drag & drop
  onDragEnd = result => {
    const { source, destination, draggableId } = result

    // Do nothing if item is dropped outside the list
    if (!destination) {
      return
    }

    // Do nothing if the item is dropped into the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const tabOrder = this.tabReorder(
        this.state.tabOrder,
        source.index,
        destination.index
      )

      this.setState({ tabOrder })
      return
    }

    // Find column from which the item was dragged from
    const columnStart = this.state.tabs[source.droppableId]

    // Find column in which the item was dropped
    const columnFinish = this.state.tabs[destination.droppableId]

    // Moving items in the same list
    if (columnStart === columnFinish) {
      // Get all item ids in currently active list
      const newItemsIds = Array.from(columnStart.itemsIds)

      // Remove the id of dragged item from its original position
      newItemsIds.splice(source.index, 1)

      // Insert the id of dragged item to the new position
      newItemsIds.splice(destination.index, 0, draggableId)

      // Create new, updated, object with data for tabs
      const newColumnStart = {
        ...columnStart,
        itemsIds: newItemsIds,
      }

      // Create new board state with updated data for tabs
      const newState = {
        ...this.state,
        tabs: {
          ...this.state.tabs,
          [newColumnStart.id]: newColumnStart,
        },
      }

      // Update the board state with new data
      this.setState(newState)
    } else {
      // Moving items from one list to another
      // Get all item ids in source list
      const newStartItemsIds = Array.from(columnStart.itemsIds)

      // Remove the id of dragged item from its original position
      newStartItemsIds.splice(source.index, 1)

      // Create new, updated, object with data for source column
      const newColumnStart = {
        ...columnStart,
        itemsIds: newStartItemsIds,
      }

      // Get all item ids in destination list
      const newFinishItemsIds = Array.from(columnFinish.itemsIds)

      // Insert the id of dragged item to the new position in destination list
      newFinishItemsIds.splice(destination.index, 0, draggableId)

      // Create new, updated, object with data for destination column
      const newColumnFinish = {
        ...columnFinish,
        itemsIds: newFinishItemsIds,
      }

      // Create new board state with updated data for both, source and destination tabs
      const newState = {
        ...this.state,
        tabs: {
          ...this.state.tabs,
          [newColumnStart.id]: newColumnStart,
          [newColumnFinish.id]: newColumnFinish,
        },
      }
      // Update the board state with new data
      this.setState(newState)
    }
  }
  handleCancel = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  handleOnClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  render() {
    const { tabs, doingSave, open, urlObjects, mountDataConfig } = this.state
    const { config, useClone } = this.props

    const board = (
      <Droppable droppableId='board' type='COLUMN' direction='horizontal'>
        {provided => (
          <div
            className={scss.settingsContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {this.state.tabOrder.map((key, index) => {
              // get id of the current tab
              const column = this.state.tabs[key]

              // get items belonging to current tab
              const items = column.itemsIds.map(
                itemId => this.state.items[itemId]
              )

              // Render
              return (
                <PropertyTab
                  key={column.id}
                  index={index}
                  title={key}
                  items={items}
                  handleTitleChange={this.handleTitleChange}
                  deleteTab={this.deleteTab}
                  column={column}
                  useClone={useClone}
                />
              )
            })}
            {provided.placeholder}
            <AddTabButton addTab={this.addTab} />
          </div>
        )}
      </Droppable>
    )

    if (!Object.keys(tabs).length) return null

    return (
      <ThemeProvider theme={mainModalTheme}>
        {urlObjects && (
          <AsyncFetch
            fetchObjects={urlObjects}
            fetchFinished={this.fetchFinished}
          />
        )}
        {mountDataConfig && (
          <MakeDataConfigComponent onFinish={this.dataConfigUpdated} />
        )}
        <Dialog
          onClose={this.handleOnClose}
          aria-labelledby='popup-settings-dialog'
          open={open}
          maxWidth='md'
        >
          <DialogTitle id='popup-settings'>
            {' '}
            Layer: {config.label}
            <FontAwesomeIcon
              onClick={this.handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>
          <DialogContent>
            <p className={appScss.textItalic}>
              Drag tabs or attributes to rearrange and organize attributes panel
              for selected data layer.
            </p>
            <p>&nbsp;</p>
            <DragDropContext onDragEnd={this.onDragEnd}>
              {board}
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <button
              className={appScss.altBlueButton}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={doingSave ? null : this.saveConfiguration}
            >
              {doingSave ? <CircularProgress size={10} /> : 'Save'}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}
export default PopupSettings
