import { Component } from 'react'
import { FlyToInterpolator } from 'react-map-gl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setViewPort } from '../../actions/index'

class ZoomToPoint extends Component {
  componentDidMount() {
    if (!this.props.viewport) return
    const { coordinates, zoom = 14 } = this.props
    const { height, width } = this.props.viewport
    if (coordinates && zoom && height && width) {
      this.doZoom(coordinates[1], coordinates[0], zoom, height, width)
    }
  }
  /**
   * doFly - set map viewport with new FlyToInterpolator
   * @param {float} latitude - latitude of viewport
   * @param {float} longitude - longitude of viewport
   * @param {integer} zoom - zoom of viewport
   * @param {integer} height - height of viewport
   * @param {integer} width - width of viewport
   */
  doZoom(latitude, longitude, zoom, height, width) {
    const viewport = {
      latitude: latitude,
      longitude: longitude,
      zoom: zoom,
      bearing: 0,
      pitch: 0,
      width: width,
      height: height,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    }
    // set the map viewPort with new viewPort and transition map using the FlyToInterpolator
    this.props.setViewPort(viewport)
  }

  // renderless container return null
  render() {
    return null
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setViewPort: setViewPort,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    viewport: state.viewport
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ZoomToPoint)
