/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
// components
import ProfileDate, {
  processDateFormat,
  profileDateFormat,
} from '../../../../Profile/ProfileDate'

import scss from '../../TabTemplate.scss'
import inputScss from './InputStyle.scss'

const CustomDatePicker = ({
  currentValue,
  currentValueDisplay,
  featureProperties,
  setFeatureProperties,
  propertyKey,
  handleAllChanges,
  CustomDateValue,
}) => {
  const [value, setValue] = useState(CustomDateValue)
  const [originalValue] = useState(CustomDateValue)
  const user = useSelector(state => state.user)

  // We do not know if the user profile will include a date format,
  // we check with ternary
  const [datePickerFormat, setDatePickerFormat] = useState(
    user && user.profile && user.profile.dateFormat
      ? processDateFormat(user.profile.dateFormat)
      : 'MM/DD/YYYY'
  )

  const [datePickerPlaceholder, setDatePickerPlaceholder] = useState(
    profileDateFormat({
      date: currentValueDisplay,
      dateFormat: datePickerFormat,
    }) || ''
  )

  let displayDate = ''
  if (currentValue && currentValue !== '') {
    const testDate = new Date(currentValue)
    if (testDate instanceof Date && !Number.isNaN(testDate))
      displayDate = testDate //If invalid date, keep it as null
  }

  useEffect(() => {
    if (value && value.includes('T')) {
      let newDate = value.split('T')
      newDate = newDate[0]
      newDate = newDate.split('-')
      newDate = newDate[1] + '/' + newDate[2] + '/' + newDate[0]
      setValue(newDate)
      // Check for update and update value.
    } else if (originalValue !== value) {
      featureProperties[propertyKey] = value
      setFeatureProperties(featureProperties)
      handleAllChanges()
    }
  }, [value])

  return (
    <DatePicker
      name=''
      className={[scss.CustomDatePicker, inputScss.defaultInput].join(' ')}
      value={value}
      selected={displayDate}
      placeholderText={datePickerPlaceholder || 'MM/DD/YYYY'}
      onChange={date => {
        let isoDate = null
        if (date && date instanceof Date)
          isoDate = date.toISOString().substring(0, 10)

        setValue(isoDate)
      }}
      dateFormat={datePickerFormat}
      isClearable
    />
  )
}

export default CustomDatePicker
