import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modalScss from '../../CSSModules/Modal.scss'
import dropdownScss from '../../CSSModules/Dropdown.scss'
import * as utils from '../../../utilities/util'

const UserPermissionRow = ({ itemId, user, handleUpdatePermissions }) => {
  const [showRoleDropdown, setShowRoleDropdown] = useState(false)

  const [profileImgURL, setProfileImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/user.png'
  )

  useEffect(() => {
    async function getProfileImage() {
      const imgURL = await utils.getImage(user.profileImg, 'user.png')

      setProfileImgURL(imgURL)
    }
    getProfileImage()
  }, [user])

  return (
    <div className={modalScss.tripleGrid}>
      <div>
        <div className={modalScss.profilePic}>
          <img src={profileImgURL}></img>
        </div>
        {user.displayName}
      </div>

      {/* ----------------------------- ROLE DROPDOWN ---------------------------- */}
      <div
        className={[dropdownScss.dropdown].join(' ')}
        onClick={() => setShowRoleDropdown(!showRoleDropdown)}
      >
        <span style={{ color: showRoleDropdown ? '#91dfed' : '' }}>
          {utils.getRole(user.role)}&nbsp;&nbsp;
        </span>
        <FontAwesomeIcon
          style={{ color: showRoleDropdown ? '#91dfed' : '' }}
          icon={['fal', 'angle-' + (showRoleDropdown ? 'up' : 'down')]}
          size='1x'
        />
        {showRoleDropdown && (
          <div
            className={[
              dropdownScss.dropdownContent,
              modalScss.dropdownContent,
            ].join(' ')}
          >
            <div
              className={[
                dropdownScss.assignDropdownItem,
                modalScss.dropdownItem,
              ].join(' ')}
            >
              <div
                onClick={() =>
                  handleUpdatePermissions(itemId, user.id, 'owner')
                }
              >
                <p style={{ marginBottom: '-7px' }}>
                  Make {utils.getRole('owner')}
                </p>
                <span className={modalScss.dropdownItemSubText}>
                  Full + Edit Permissions
                </span>
              </div>
            </div>

            <div
              className={[
                dropdownScss.assignDropdownItem,
                modalScss.dropdownItem,
              ].join(' ')}
            >
              <div
                onClick={() =>
                  handleUpdatePermissions(itemId, user.id, 'full-user')
                }
              >
                <p style={{ marginBottom: '-7px' }}>
                  {utils.getRole('full-user')}
                </p>
                <span className={modalScss.dropdownItemSubText}>
                  Editor + Delete
                </span>
              </div>
            </div>

            <div
              className={[
                dropdownScss.assignDropdownItem,
                modalScss.dropdownItem,
              ].join(' ')}
            >
              <div
                onClick={() =>
                  handleUpdatePermissions(itemId, user.id, 'editor')
                }
              >
                <p style={{ marginBottom: '-7px' }}>
                  {utils.getRole('editor')}
                </p>
                <span className={modalScss.dropdownItemSubText}>
                  Upload, Download, Edit
                </span>
              </div>
            </div>

            <div
              className={[
                dropdownScss.assignDropdownItem,
                modalScss.dropdownItem,
              ].join(' ')}
            >
              <div
                onClick={() =>
                  handleUpdatePermissions(itemId, user.id, 'viewer')
                }
              >
                <p style={{ marginBottom: '-7px' }}>
                  {utils.getRole('viewer')}
                </p>
                <span className={modalScss.dropdownItemSubText}>Read Only</span>
              </div>
            </div>

            <div
              className={[
                dropdownScss.assignDropdownItem,
                modalScss.dropdownItem,
              ].join(' ')}
            >
              <div
                onClick={() =>
                  handleUpdatePermissions(itemId, user.id, 'no-access')
                }
              >
                <p style={{ marginBottom: '-7px' }}>
                  {utils.getRole('no-access')}
                </p>
                <span className={modalScss.dropdownItemSubText}>
                  Remove Access
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* -------------------------------------------------------------------------- */}

      <div
        onClick={() => handleUpdatePermissions(itemId, user.id, null)}
        style={{ cursor: 'pointer' }}
      >
        <FontAwesomeIcon
          style={{ fontSize: '9px' }}
          icon={['fa', 'trash-alt']}
          size='1x'
        />
      </div>
    </div>
  )
}

export default UserPermissionRow
