export const HOT_SOURCES = [
  'draw_polygon_hot',
  'draw_polygon_outline_hot',
  'draw_line_hot',
  'draw_verticies_hot',
  'draw_midpoints_hot',
  'draw_clicked_verticie',
]

export const DELETE_SOURCES = ['delete_line', 'delete_circle']
export const DRAW_SOURCES = {
  line: [
    'draw_line_hot',
    'draw_verticies_hot',
    'draw_midpoints_hot',
    'draw_clicked_verticie',
    'draw_line_cold',
  ],
  fill: [
    'draw_polygon_hot',
    'draw_polygon_outline_hot',
    'draw_verticies_hot',
    'draw_midpoints_hot',
    'draw_clicked_verticie',
    'draw_polygon_cold',
    'draw_polygon_outline_cold',
  ],
  circle: [
    'draw_verticies_hot',
    'draw_clicked_verticie',
    'draw_verticies_cold',
  ],
}
