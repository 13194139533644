"use strict";
/* eslint-disable camelcase */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const pintura_1 = require("@pqina/pintura");
const react_pintura_1 = require("@pqina/react-pintura");
// Doka
require("@pqina/pintura/pintura.css");
(0, pintura_1.setPlugins)(pintura_1.plugin_crop, pintura_1.plugin_finetune, pintura_1.plugin_filter, pintura_1.plugin_annotate);
const editorDefaults = {
    utils: ['crop', 'finetune', 'filter', 'annotate'],
    imageReader: (0, pintura_1.createDefaultImageReader)(),
    imageWriter: (0, pintura_1.createDefaultImageWriter)(),
    shapePreprocessor: (0, pintura_1.createDefaultShapePreprocessor)(),
    ...pintura_1.plugin_finetune_defaults,
    ...pintura_1.plugin_filter_defaults,
    ...pintura_1.markup_editor_defaults,
    locale: {
        ...pintura_1.locale_en_gb,
        ...pintura_1.plugin_crop_locale_en_gb,
        ...pintura_1.plugin_finetune_locale_en_gb,
        ...pintura_1.plugin_filter_locale_en_gb,
        ...pintura_1.plugin_annotate_locale_en_gb,
        ...pintura_1.markup_editor_locale_en_gb,
    },
};
const ImageEditor = ({ image, onCancel, onFinish }) => {
    (0, react_1.useEffect)(() => {
        console.log(image);
    }, [image]);
    console.log(image);
    return (react_1.default.createElement(react_pintura_1.PinturaEditorModal, { ...editorDefaults, imageCropAspectRatio: 1, src: image, 
        // onLoad={res => console.log('load modal image', res)}
        onHide: () => onCancel(), onProcess: ({ dest }) => onFinish(dest) }));
};
exports.default = ImageEditor;
