import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { setSnapSettings } from '../../../../../../../actions'
import scss from '../Snapping.scss'

const Line = () => {
  const dispatch = useDispatch()
  const snapLine = useSelector(state => state.snapSettings.snapLine)
  const [checked, setChecked] = useState(snapLine)
  const handleChange = () => setChecked(!checked)

  useEffect(() => {
    dispatch(setSnapSettings({ snapLine: checked }))
  }, [checked])

  return (
    <div className={scss.wrapper}>
      <div className={scss.label}>Snap To Line:</div>
      <Switch
        checked={checked}
        onChange={handleChange}
        style={{ marginTop: '2px' }}
      />
    </div>
  )
}

export default Line
