import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import ReactCodeInput from 'react-verification-code-input'
import queryString from 'query-string'

import LogoIcon from '../../assets/logo/drk-trim-main.svg'
import { apiFetch } from '../../utilities/api'
import { apis } from '../../config/apiConfig'

import { Alert } from '../../actions'

// scss files
import appScss from '../App/App.scss'
import scss from './Login.scss'

export default function Verify(props) {
  const parsed = queryString.parse(props.location.search)

  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState(parsed.email != null ? parsed.email : '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordResetNeeded, setPasswordResetNeeded] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(
    parsed.code != null ? parsed.code : ''
  )
  const [verifySuccess, setVerifySuccess] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = scss.boxedFormPage
    return () => {
      document.getElementsByTagName('body')[0].className = ''
    }
  }, [])

  const validateForm = () => {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    )
  }

  const validateCodeForm = () => {
    return email.length > 0 && confirmationCode.length > 5
  }

  const handleError = e => {
    setIsLoading(false)

    // console.warn("e is: ",e);
    // console.log("EMAIL",email,"CODE",confirmationCode)

    switch (e.code) {
      case 'UserNotFoundException':
        dispatch(
          Alert({
            error: 'User does not exist: Please check your email address.',
          })
        )
        break
      case 'NotAuthorizedException':
        if (e.message == 'User is disabled.') {
          dispatch(
            Alert({
              error:
                'Disabled: This account is disabled. Please contact support.',
            })
          )
        } else if (
          e.message == 'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          setPasswordResetNeeded(true)
        } else if (
          e.message ==
          'User cannot be confirmed. Current status is RESET_REQUIRED'
        ) {
          setPasswordResetNeeded(true)
        } else
          dispatch(
            Alert({
              error:
                "Not Authorized: You're not authorized to perform this action: " +
                e.message,
            })
          )
        break
      case 'ExpiredCodeException':
        dispatch(
          Alert({
            error:
              'Invalid Confirmation Code: Please check the message you received or request a new code.',
          })
        )
        break
      default:
        dispatch(Alert({ error: 'Confirmation Failed: ' + e.message }))
        break
    }
  }

  const handleResendEmail = () => {
    if (email.length <= 0)
      return dispatch(
        Alert({
          error: 'Please enter your email address above prior to resending.',
        })
      )

    Auth.resendSignUp(email)
      .then(e => {
        if (e && e.CodeDeliveryDetails) {
          dispatch(
            Alert({
              success:
                'Email Verification Sent: \n\nEmail Verification Code has been sent!',
            })
          )
        }
      })
      .catch(e => {
        console.error('ResendEmail', e)
        dispatch(
          Alert({ error: 'Email Verification Failed: \n\n' + e.message })
        )
      })
  }

  const handleSignIn = () => {
    Auth.signIn(email, password)
      .then(loginInfo => {
        setIsLoading(false)
        if (props.location.pathname === '/login') props.history.push('/')

        const userPayload = loginInfo.signInUserSession.idToken.payload
        props.handleLoginAttempt(true, userPayload)
      })
      .catch(function (e) {
        dispatch(Alert({ error: 'Login Failed: ' + e.message }))
      })
  }

  const handleResetPassword = e => {
    e.preventDefault()

    if (password === '') {
      dispatch(Alert({ error: 'Please enter your new password.' }))
    } else {
      if (password === confirmPassword) {
        //Reset Password
        Auth.forgotPasswordSubmit(email, confirmationCode, password)
          .then(function () {
            dispatch(Alert({ success: 'Good to go! Logging you in!' }))

            handleSignIn()
          })
          .catch(function (err) {
            handleError(err)
          })
      } else
        dispatch(
          Alert({ error: 'Your two passwords do not match. Please try again.' })
        )
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'user/update'
    const bodyParams = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    }
    setIsLoading(false)

    apiFetch(url, method, bodyParams, result => {
      let data = result.data

      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.changePassword(user, 'MyAssetMap1!', password)
            .then(data => {
              dispatch(
                Alert({ success: 'Good to go! Your password has been reset.' })
              )

              handleSignIn()
            })
            .catch(err => {
              handleError(err)
            })
        })
        .catch(err => {
          handleError(err)
        })
    })
  }

  const handleConfirmationSubmit = e => {
    e.preventDefault()

    setIsLoading(true)

    //Confirm Sign Up
    Auth.confirmSignUp(email, confirmationCode)
      .then(function () {
        setIsLoading(false)

        Auth.signIn(email, 'MyAssetMap1!')
          .then(loginInfo => {
            setIsLoading(false)
            setVerifySuccess(true)
          })
          .catch(function (err) {
            setIsLoading(false)
          })
      })
      .catch(function (err) {
        handleError(err)
      })
  }

  const handleResetPasswordEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleResetPassword(e)
    }
  }

  const handleSubmitEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSubmit(e)
    }
  }

  const handleConfirmationSubmitEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleConfirmationSubmit(e)
    }
  }

  // ==================================================================================== //
  // ======== RENDER THE FIRST SCREEN ALLOWING USERS TO ENTER THEIR EMAIL & CODE ======== //
  // ==================================================================================== //

  const renderVerifyForm = () => {
    return (
      <>
        <label
          htmlFor='verify-email'
          className={appScss['input-notched']}
          style={{ marginBottom: '24px' }}
        >
          <span>Email</span>
          <input
            autoFocus
            id='verify-email'
            onChange={e => setEmail(e.target.value)}
            onKeyDown={
              passwordResetNeeded
                ? handleResetPasswordEnter
                : handleConfirmationSubmitEnter
            }
            value={email}
            type='text'
          />
        </label>
        <span
          className={scss['login-helper-text']}
          style={{ marginBottom: '17px' }}
        >
          Enter confirmation code.&nbsp;
          <a
            className={[scss['login-link']].join(' ')}
            onClick={handleResendEmail}
          >
            Resend?
          </a>
        </span>

        <ReactCodeInput
          className={scss['react-single-code-inputs']}
          type={'number'}
          fields={6}
          values={confirmationCode.split('')}
          // onComplete={handleConfirmationSubmit}
          onChange={setConfirmationCode}
        />

        {passwordResetNeeded && (
          <>
            <label
              htmlFor='verify-password'
              className={appScss['input-notched']}
              style={{ marginTop: '32px' }}
            >
              <span>Password</span>
              <input
                id='verify-password'
                onChange={e => setPassword(e.target.value)}
                onKeyDown={
                  passwordResetNeeded
                    ? handleResetPasswordEnter
                    : handleConfirmationSubmitEnter
                }
                value={password}
                type='password'
              />
            </label>
            <label
              htmlFor='verify-password-confirm'
              className={appScss['input-notched']}
              style={{ marginTop: '32px' }}
            >
              <span>Confirm Password</span>
              <input
                id='verify-password-confirm'
                onChange={e => setConfirmPassword(e.target.value)}
                onKeyDown={
                  passwordResetNeeded
                    ? handleResetPasswordEnter
                    : handleConfirmationSubmitEnter
                }
                value={confirmPassword}
                type='password'
              />
            </label>
          </>
        )}
        <button
          type='button'
          onClick={
            passwordResetNeeded ? handleResetPassword : handleConfirmationSubmit
          }
          disabled={!validateCodeForm()}
          className={[
            scss['login-submit-button'],
            scss[isLoading ? 'login-submit-button-loading' : ''],
          ].join(' ')}
          style={{ marginTop: '24px' }}
        >
          {isLoading ? 'Verifying...' : 'Verify'}
        </button>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/login'
        >
          Back to login
        </Link>
      </>
    )
  }

  const renderUserUpdateForm = () => {
    return (
      <>
        <label
          htmlFor='verify-first-name'
          className={appScss['input-notched']}
          style={{ marginBottom: '33px' }}
        >
          <span>First Name</span>
          <input
            autoFocus
            id='verify-first-name'
            onChange={e => setFirstName(e.target.value)}
            onKeyDown={handleSubmitEnter}
            value={firstName}
            type='text'
          />
        </label>

        <label
          htmlFor='verify-last-name'
          className={appScss['input-notched']}
          style={{ marginBottom: '33px' }}
        >
          <span>Last Name</span>
          <input
            id='verify-last-name'
            onChange={e => setLastName(e.target.value)}
            onKeyDown={handleSubmitEnter}
            value={lastName}
            type='text'
          />
        </label>

        <label
          htmlFor='verify-email'
          className={appScss['input-notched']}
          style={{ marginBottom: '33px' }}
        >
          <span>Email</span>
          <input
            id='verify-email'
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleSubmitEnter}
            value={email}
            disabled={true}
            type='text'
          />
        </label>

        <label
          htmlFor='verify-password'
          className={appScss['input-notched']}
          style={{ marginBottom: '33px' }}
        >
          <span>Password</span>
          <input
            id='verify-password'
            onChange={e => setPassword(e.target.value)}
            onKeyDown={handleSubmitEnter}
            value={password}
            type='password'
          />
        </label>

        <label
          htmlFor='verify-password-confirm'
          className={appScss['input-notched']}
        >
          <span>Confirm Password</span>
          <input
            id='verify-password-confirm'
            onChange={e => setConfirmPassword(e.target.value)}
            onKeyDown={handleSubmitEnter}
            value={confirmPassword}
            type='password'
          />
        </label>

        <button
          type='button'
          onClick={handleSubmit}
          disabled={!validateForm()}
          className={[
            scss['login-submit-button'],
            scss[isLoading ? 'login-submit-button-loading' : ''],
          ].join(' ')}
          style={{ marginTop: '24px' }}
        >
          {isLoading ? 'Creating...' : 'Create'}
        </button>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/login'
        >
          Back to login
        </Link>
      </>
    )
  }

  return (
    <div className={scss['login-container']}>
      <img
        className={scss['login-logo']}
        style={{ marginBottom: '54px' }}
        src={LogoIcon}
        alt='My Asset Map Logo'
      />
      {!verifySuccess ? renderVerifyForm() : renderUserUpdateForm()}
    </div>
  )
}
