import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import scss from './PopupFooter.scss'
import appScss from '../App/App.scss'
import AsyncFetch from '../../utilities/AsyncFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { apis } from '../../config/apiConfig'
import { updateMapStyle, setPopupDocked } from '../../actions/index'
import { fromJS } from 'immutable'
import DocumentBrowserV2 from '../DocumentBrowserV2/DocumentBrowserV2'

const PopupFooter = ({
  mamId,
  layerId,
  featureProperties,
  layerProperties,
  popupInfo,
  index,
  dataConfigLayer,
  fetchingSettings,
  edit,
  popupBackward,
  popupForward,
  makePopupEditable,
  cancelEdits,
  forceSave,
  forceSaveFinish,
  openSettings,
  closePopup,
  documentTab,
  handleAddDocumentRowVisible,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const mapStyle = useSelector(state => state.mapStyle)
  const popupDocked = useSelector(state => state.popupDocked)
  const clickedFeatureCount = popupInfo.features.length
  const activeFeature = index + 1
  const [urlObjects, setUrlObjects] = useState(null)
  const [savingEdits, setSavingEdits] = useState(false)

  let canEdit = false
  if (dataConfigLayer && dataConfigLayer.toc)
    canEdit = dataConfigLayer.toc.canEdit

  // Add Document Button
  const [documentFeature, setDocumentFeature] = useState(null)
  const openDocuments = () => {
    const featureId = popupInfo.features[index].properties.mamid
    setDocumentFeature(featureId)
  }

  const deleteFeature = () => {
    const style = mapStyle.toJS()
    const feature = popupInfo.features[index]
    const sourceId = feature.layer.source
    const source = style.sources[sourceId]
    const newFeatures = source.data.features.filter(
      sourceFeature => sourceFeature.properties.id !== feature.properties.id
    )
    source.data.features = newFeatures
    closePopup()
    dispatch(updateMapStyle(fromJS(style)))
  }

  const saveEdits = () => {
    const featureId = popupInfo.features[index].properties.mamid
    const layerId = popupInfo.features[index].layer.id
    const json = {
      type: 'Feature',
      properties: featureProperties,
      geometry: {},
    }
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/update'
    const body = {
      layerID: layerId,
      featureID: featureId,
      json: json,
    }
    setSavingEdits(true)
    setUrlObjects([{ url, body, method }])
    cancelEdits()
  }

  //Force Save Logic
  useEffect(() => {
    if (forceSave) {
      saveEdits()
      if (typeof forceSaveFinish === 'function') forceSaveFinish()
    }
  }, [forceSave])

  const fetchFinished = results => {
    if (savingEdits) {
      saveFinished()
    }
  }

  const saveFinished = () => {
    const layerId = popupInfo.features[index].layer.id
    let style = mapStyle.toJS()
    let sourceId = style.layers
      .filter(layer => layer.id === layerId)
      .map(layer => layer.source)
    setUrlObjects(null)
    setSavingEdits(false)
    style.sources[sourceId[0]].tiles = style.sources[sourceId[0]].tiles.map(
      tileUrl => {
        if (tileUrl.indexOf('?') === -1) {
          return tileUrl + `?dt=${Date.now()}`
        } else {
          let url = tileUrl.replace(/&dt.*$/, '')
          return url + `&dt=${Date.now()}`
        }
      }
    )
    dispatch(updateMapStyle(fromJS(style)))
  }

  const handleDocumentRowVisible = () => {
    setAddDocumentRowVisiblePopup(!addDocumentRowVisiblePopup)
  }

  let tabsButton =
    dataConfigLayer && dataConfigLayer.toc.userLayer ? (
      fetchingSettings ? (
        <button className={scss.featureFooterCorner} onClick={null}>
          <FontAwesomeIcon icon='spinner' size='1x' spin />
        </button>
      ) : canEdit ? (
        <div>
          <button
            className={scss.featureFooterCorner}
            onClick={openSettings}
            data-tip={'Tabs'}
            data-for={'featureTabs'}
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0)'
            data-place='left'
          >
            <FontAwesomeIcon icon='cog' size='1x' />
          </button>
          <div className={scss.reactToolTip}>
            <ReactTooltip
              id={'featureTabs'}
              getContent={dataTip => `${dataTip}`}
            />
          </div>
        </div>
      ) : (
        <div className={scss.featureFooterCornerEmpty} />
      )
    ) : (
      <div className={scss.featureFooterCornerEmpty} />
    )

  let userPopupButtons =
    dataConfigLayer && dataConfigLayer.toc.userLayer ? (
      <>
        {edit && !documentTab ? (
          <>
            <button
              className={[
                appScss.transparentButton,
                scss.featureFooterButton,
              ].join(' ')}
              onClick={cancelEdits}
              style={{ marginRight: '12px' }}
            >
              Cancel
            </button>
            <button
              className={[
                appScss.transparentButton,
                scss.featureFooterButton,
              ].join(' ')}
              onClick={saveEdits}
            >
              {savingEdits ? (
                <FontAwesomeIcon icon={'spinner'} spin size='1x' />
              ) : (
                'Save'
              )}
            </button>
          </>
        ) : layerProperties[layerId] &&
          layerProperties[layerId].length > 0 &&
          canEdit ? (
          <>
            {/*
                  Only Render this button when properties exist for the layer AND
                  the current user can edit the properties
                */}
            {
              !documentTab && (
                <button
                  className={[
                    appScss.transparentButton,
                    scss.featureFooterButton,
                  ].join(' ')}
                  onClick={makePopupEditable}
                >
                  Edit
                  <FontAwesomeIcon icon='pencil' />
                </button>
              )
              // : (
              //   <button
              //     className={[
              //       appScss.transparentButton,
              //       scss.featureFooterButton,
              //     ].join(' ')}
              //     onClick={handleAddDocumentRowVisible}
              //   >
              //     Add
              //     <FontAwesomeIcon icon='plus' />
              //   </button>
              // )
            }
          </>
        ) : null}
      </>
    ) : (
      ''
    )

  let navButtons = (
    <>
      <button onClick={popupBackward}>
        <FontAwesomeIcon icon='chevron-left' />
      </button>
      <span className={scss.featureFooterNumbers}>
        {activeFeature}/{clickedFeatureCount}
      </span>
      <button onClick={popupForward}>
        <FontAwesomeIcon icon='chevron-right' />
      </button>
    </>
  )

  return (
    <>
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
      {documentFeature && (
        <DocumentBrowserV2
          mamId={mamId}
          layerId={layerId}
          layerProperties={layerProperties}
          featureProperties={featureProperties}
          documentFeature={documentFeature}
          onClose={() => setDocumentFeature(null)}
          isSelectDocument={false}
          popupKey={layerId}
          canEdit={canEdit}
        />
      )}
      <div className={scss.featureFooter}>
        {tabsButton}
        <div className={scss.featureFooterRight}>
          <div className={scss.featureRightNav}>{navButtons}</div>
          <div className={scss.featureRightButtons}>
            {userPopupButtons}
            {layerId === 'geocode_results' ||
            layerId === 'coordiantes_results' ? (
              <button
                className={[
                  appScss.transparentButton,
                  scss.featureFooterButton,
                ].join(' ')}
                onClick={deleteFeature}
              >
                Delete
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupFooter
