/* eslint-disable react/jsx-filename-extension */
import React, { useState, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { DialogTitle } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import mainModalTheme from '../../../../../../utilities/componentConstants/mainModalTheme'
import scss from './UseDataDrivenStylesConfirmationPopup.scss'
import * as UseDataDrivenStylesConfirmationPopupUtil from './UseDataDrivenStylesConfirmationPopupUtil.js'

const UseDataDrivenStylesConfirmationPopup = ({
  setUseDataDrivenStyle,
  setDisplayUseDataDrivenStylesMapPopup,
}) => {
  const wrapperRef = useRef(null)

  UseDataDrivenStylesConfirmationPopupUtil.useOutsideAlerter(
    wrapperRef,
    setDisplayUseDataDrivenStylesMapPopup
  )

  return (
    <ThemeProvider
      theme={mainModalTheme}
      style={{
        background: '#314D68',
      }}
    >
      <Dialog
        onClose={() => {}}
        open
        aria-labelledby='document-browser-dialog'
        maxWidth='lg'
      >
        <DialogTitle
          id='document-browser-title'
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderStyle: 'none',
            background: '#314d68',
          }}
          className={scss.NewFolderPopup}
        >
          Confirm
        </DialogTitle>
        <div
          style={{
            height: '75px',
            background: 'rgb(49, 77, 104)',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'center',
            color: '#314d68',
            width: '350px',
          }}
        >
          x
          <form
            style={{ width: '80%', color: 'white' }}
            onSubmit={() => {
              setUseDataDrivenStyle(false)
              setDisplayUseDataDrivenStylesMapPopup(false)
            }}
          >
            Turning off Data Driven Styles will erase the styles. Are you sure
            you want to continue?
          </form>
        </div>
        <DialogActions
          style={{
            borderStyle: 'none',
            background: '#314d68',
          }}
        >
          <button
            type='button'
            className={scss.CancelButton}
            onClick={() => {
              setDisplayUseDataDrivenStylesMapPopup(false)
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={scss.CreateButton}
            onClick={() => {
              setUseDataDrivenStyle(false)
              setDisplayUseDataDrivenStylesMapPopup(false)
            }}
          >
            TURN OFF
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default UseDataDrivenStylesConfirmationPopup
