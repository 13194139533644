import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utilities
import * as utils from '../../utilities/util'
import AsyncFetch from '../../utilities/AsyncFetch'
import { getBBox } from '../../utilities/geospatial'
import { setPopupDocked } from '../../actions/index'
import { apis } from '../../config/apiConfig'

// components
import ZoomToBounds from '../../components/ZoomToBounds/ZoomToBounds'
import Measure from '../Tools/FeatureMeasure/Measure'

// scss files
import scss from './PopupHeader.scss'
import modalScss from '../CSSModules/Modal.scss'
import appScss from '../App/App.scss'

const PopupHeader = ({
  mamId,
  featureProperties,
  closePopup,
  label,
  popupInfo,
  index,
  layerId,
  dataConfigLayer,
  active,
  layerProperties,
}) => {
  const dispatch = useDispatch()
  const popupDocked = useSelector(state => state.popupDocked)


  const title = label || ''
  const viewport = useSelector(state => state.viewport)
  var geomType = ''
  if (typeof popupInfo.features[index] !== 'undefined')
    geomType = popupInfo.features[index].geometry.type

  const [measureType, setMeasureType] = useState(null)
  const [activeMeasureType, setActiveMeasureType] = useState([])
  const [measurementFeature, setMeasurementFeature] = useState(null)
  const [urlObjects, setUrlObjects] = useState(null)
  const [fetchingMeasurements, setFetchingMeasurements] = useState(false)
  const [fetchingBounds, setFetchingBounds] = useState(null)
  const [fetchingDocuments, setFetchingDocuments] = useState(null)
  const [zoomToIndex, setZoomIndex] = useState(null)
  const [zoomToBoundsKey, setZoomToBoundsKey] = useState(null)
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [documentFeature, setDocumentFeature] = useState(null)
  const [fetchObjects, setFetchObjects] = useState(null)

  const buildFetchParams = () => {
    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }

    setFetchingBounds(true)
    setFetchObjects([{ url, method, body }])
  }

  const apiFinished = results => {
    return results.map(result => {
      if (result.success && result.data.features[0]) {
        doZoomToBounds(result.data.features[0])
      }

      setFetchingBounds(false)
      setFetchObjects(null)
    })
  }

  const zoomToPopupFeature = () => {
    buildFetchParams()
  }

  const doZoomToBounds = feature => {
    if (!feature.geometry.type === 'Polygon') return
    let newZoomIndex = zoomToIndex ? zoomToIndex : 1
    const bounds = getBBox(feature)
    const formattedBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    const zoomToBoundsKey = JSON.stringify(formattedBounds) + newZoomIndex
    const zoomToBounds = formattedBounds
    newZoomIndex++
    setZoomIndex(newZoomIndex)
    setZoomToBounds(zoomToBounds)
    setZoomToBoundsKey(zoomToBoundsKey)
  }

  const getMeasurement = mType => {
    setMeasureType(mType)
    setActiveMeasureType(utils.toggleVal(activeMeasureType, mType[0]))

    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }
    setFetchingMeasurements(true)
    setUrlObjects([{ url, body, method }])
  }

  const fetchFinished = results => {
    if (fetchingMeasurements) {
      measurementFetchFinished(results)
    }
  }

  const measurementFetchFinished = results => {
    return results.map(result => {
      setMeasurementFeature(result.data.features[0])
      setFetchingMeasurements(false)
      setUrlObjects(null)
    })
  }

  const openDocuments = () => {
    const featureId = popupInfo.features[index].properties.mamid
    setDocumentFeature(featureId)
  }

  // Dock or Float Button
  const handleDockPopup = () => dispatch(setPopupDocked(!popupDocked))
  let dockButton = dataConfigLayer ? (
    <div>
      <button
        className={[
          scss['feature-header-corner'],
          popupDocked ? scss['feature-header-corner-active'] : '',
        ].join(' ')}
        onClick={handleDockPopup}
        data-tip={popupDocked ? 'Undock' : 'Dock'}
        data-for={'popupDocked'}
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0)'
        data-place='bottom'
      >
        <FontAwesomeIcon icon='border-style' />
      </button>
      <div className={scss.reactToolTip}>
        <ReactTooltip id={'popupDocked'} getContent={dataTip => `${dataTip}`} />
      </div>
    </div>
  ) : (
    <div className={scss['feature-header-corner-empty']} />
  )

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={apiFinished} />
      )}
      <ZoomToBounds
        key={zoomToBoundsKey}
        viewport={viewport}
        bounds={zoomToBounds}
      />
      <div className={[scss['feature-header'], 'handle'].join(' ')}>
        <div className={scss['feature-header-left']}>
          {dockButton}
          <span
            className={scss['feature-header-title']}
            data-tip={title}
            data-for='feature-title'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            {title}
            <div className={scss.reactToolTip}>
              <ReactTooltip
                id='feature-title'
                getContent={dataTip => `${dataTip}`}
              />
            </div>
          </span>
          {featureProperties != null ? (
            <>
              <FontAwesomeIcon
                icon='info-circle'
                className={scss['feature-header-info']}
                data-tip={['mamid: ', mamId].join(' ')}
                data-for='mamid'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              />
              <div className={scss.reactToolTip}>
                <ReactTooltip id='mamid' getContent={dataTip => `${dataTip}`} />
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        <button
          onClick={closePopup}
          className={[modalScss.modalX, scss['feature-header-close']].join(' ')}
        >
          <FontAwesomeIcon icon='times' />
        </button>
      </div>
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
    </>
  )
}
export default PopupHeader
