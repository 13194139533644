/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as DocumentBrowserUtil from '../../../DocumentBrowserV2/DocumentBrowserV2Util'
import scss from './Documents.scss'
import attributesScss from './Attributes.scss'

const DocumentPropertyRow = ({
  property,
  propertyName,
  propertyLink,
  documentName,
  propertyDocumentTypeIcon,
  propertyLevel,
  setCurrentDocumentLayerProperty,
  handleDocumentUnlink,
  setTargetProperty,
  setDisplayFileBrowser,
  setEditMode,
}) => {
  const openFileOnAnotherWindow = async awsPath => {
    awsPath = awsPath.replace('|', '')
    const signedURL = await Storage.get(awsPath)
    window.open(signedURL)
  }

  const [penStyle, setPenStyle] = useState({ display: 'none' })

  return (
    <div
      key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(10)}
      className={[
        scss.layerDocumentsGridRow,
        attributesScss.layerPropertiesGridRow,
      ].join(' ')}
      style={{
        height: '4.5vh',
      }}
      onMouseEnter={e => {
        setPenStyle({
          display: 'block',
          borderStyle: 'none',
          background: 'none',
        })
      }}
      onMouseLeave={e => {
        setPenStyle({ display: 'none' })
      }}
    >
      <div
        style={{
          fontSize: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <div style={{ display: 'inline-flex', width: '40%' }}>
          <div
            title={propertyName}
            style={{
              maxWidth: '70%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {propertyName}
          </div>
        </div>

        <span
          style={{
            display: 'flex',
            width: property.type !== 'document (Layer)' ? '' : '60%',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'none !important',
          }}
        >
          {property.type === 'document (Layer)' ? (
            propertyLink ? (
              <button
                type='button'
                target='_blank'
                style={{
                  borderStyle: 'none',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  width: '80%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginRight: '10px',
                }}
                className={scss.linkedFile}
                onClick={() => {
                  openFileOnAnotherWindow(propertyLink)
                }}
                title={'Click to open ' + documentName}
              >
                {propertyDocumentTypeIcon}
                <div
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span title={'Click to open ' + documentName}>
                    {documentName || null}
                  </span>
                </div>
              </button>
            ) : (
              <span style={{ fontSize: 'smaller' }}>
                Click <FontAwesomeIcon icon='link' /> to link file
              </span>
            )
          ) : null}

          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {property.type === 'document (Layer)' ? (
              propertyLink ? (
                <>
                  <FontAwesomeIcon
                    icon='unlink'
                    onClick={e => {
                      e.stopPropagation()
                      setCurrentDocumentLayerProperty(property)
                      handleDocumentUnlink(property.id, property.key)
                    }}
                    className={scss.linkFile}
                    title='Unlink file'
                  />
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon='link'
                    onClick={e => {
                      e.stopPropagation()
                      setCurrentDocumentLayerProperty(property)
                      setDisplayFileBrowser(true)
                    }}
                    className={scss.linkedFile}
                    title='Link file'
                  />
                </>
              )
            ) : null}
          </span>
        </span>
      </div>

      {/* <button
        type='button'
        style={penStyle}
        className={scss.EditAttributePencil}
        title='Edit attribute'
        onClick={() => {
          setTargetProperty(property)
          setEditMode(true)
        }}
      >
        <FontAwesomeIcon icon='pencil' />
      </button> */}
    </div>
  )
}

export default DocumentPropertyRow
