import React, { useState, useEffect } from 'react'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import { makeUniqueIDNumbersOnly } from '../../../../../utilities/util'

const AttributeSelector = ({
  layerId,
  updateSelectedAttribute,
  selectedAttribute,
}) => {
  const [properties, setProperties] = useState([])
  const [fetchObjects, setFetchObjects] = useState(null)
  const handleChange = e => updateSelectedAttribute(e.target.value)

  properties.sort((a, b) => (a.name > b.name ? 1 : -1))
  const fetchFinished = results => {
    results.forEach(res => {
      if (res.success === true) {
        if (res.data.length) {
          const filtered = res.data.filter(dataObj => {
            if (dataObj.type === 'document' || dataObj.type === 'image') {
              return false
            }
            return true
          })

          setProperties(filtered)
          if (!selectedAttribute) {
            updateSelectedAttribute(filtered[0].key)
          }
        }
      }
    })
    setFetchObjects(null)
  }

  useEffect(() => {
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const method = 'POST'
    const body = { layerID: layerId /* unique: true */ }
    setFetchObjects([{ url, method, body }])
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <select value={selectedAttribute || ''} onChange={handleChange}>
        {properties.map(obj => (
          <option key={makeUniqueIDNumbersOnly(10)} value={obj.key}>
            {obj.name}
          </option>
        ))}
      </select>
    </>
  )
}

export default AttributeSelector
