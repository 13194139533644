import React, { useState, useEffect } from 'react'
import Fill from './Fill'
import Point from './Point'
import Line from './Line'

/*
  @param {targetFeature} - geojson feature to get measurements from
  @param {measureType} - string indicating type of measurement area || areaSegments || lineSegments || lineLength || coordinates
*/
export default function Measure({ layerId, targetFeature, measureType }) {
  const [geometry, setGeometry] = useState(null)
  useEffect(() => {
    setGeometry(targetFeature.geometry.type)
  }, [targetFeature])
  return (
    <>
      {geometry === 'Polygon' || geometry === 'MultiPolygon' ? (
        <Fill
          layerId={layerId}
          targetFeature={targetFeature}
          measureType={measureType}
          precision={2}
        />
      ) : null}

      {geometry === 'Point' || geometry === 'MultiPoint' ? (
        <Point
          layerId={layerId}
          targetFeature={targetFeature}
          measureType={measureType}
          precision={6}
        />
      ) : null}
      {geometry === 'LineString' || geometry === 'MultiLineString' ? (
        <Line
          layerId={layerId}
          targetFeature={targetFeature}
          measureType={measureType}
          precision={2}
        />
      ) : null}
    </>
  )
}
