import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle } from '../../actions'
import { fromJS } from 'immutable'
import { CLICKED_FEATURE } from '../../data/Layers/Auxiliary/ClickedFeature'
const CLICKED_FEATURE_LAYER = CLICKED_FEATURE.layersArray[0].layer
const ClickedFeature = ({ feature, clearClickedFeature }) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const getClickedFeatureSource = feature => {
    // build null experience
    let obj = {
      layerId: 'clicked_feature',
      sourceId: 'clicked_feature',
      expression: ['match', ['get', 'id'], ['null'], true, false],
    }
    if (feature) {
      let type = feature.layer.type

      let expression = ['all']
      const mamid = feature.properties.mamid
      if (mamid) {
        let temp = ['==', ['get', 'mamid'], mamid]
        expression.push(temp)
      } else {
        /* 
         when no mamid exists create expression using all of the feature properties
        */
        Object.keys(feature.properties).forEach(function (key) {
          let temp = ['==', ['get', key], feature.properties[key]]
          expression.push(temp)
        })
      }
      obj.layerId = 'clicked_feature'
      obj.sourceId = feature.layer.source
      obj.expression = expression
      obj.sourceLayer = feature.layer['source-layer'] || null
      obj.type = type
      if (type === 'circle') {
        obj['circle-radius'] = feature.layer.paint['circle-radius'] + 1
      }
    }
    return obj
  }

  const buildClickedFeature = () => {
    const clickedFeatureObj = getClickedFeatureSource(feature)
    let style = mapStyle.toJS()
    let clickedFeatureLayer = style.layers.filter(
      layer => layer.id === 'clicked_feature'
    )

    if (clickedFeatureLayer[0]) {
      clickedFeatureLayer[0].source = clickedFeatureObj.sourceId
    }

    if (clickedFeatureObj.sourceLayer) {
      clickedFeatureLayer[0]['source-layer'] = clickedFeatureObj.sourceLayer
    }
    if (clickedFeatureObj.type === 'circle') {
      clickedFeatureLayer[0].type = 'circle'
      clickedFeatureLayer[0].paint = {
        'circle-color': CLICKED_FEATURE_LAYER.paint['line-color'],
        'circle-opacity': 0,
        'circle-radius': clickedFeatureObj['circle-radius'],
        'circle-stroke-color': CLICKED_FEATURE_LAYER.paint['line-color'],
        'circle-stroke-width': 3,
      }
    } else {
      clickedFeatureLayer[0].type = 'line'
      clickedFeatureLayer[0].paint = CLICKED_FEATURE_LAYER.paint
    }

    clickedFeatureLayer[0].filter = clickedFeatureObj.expression
    dispatch(updateMapStyle(fromJS(style)))
    // Note: clearClickedFeature clears clickedFeature from Map.js State
    // doing this ensures this component will remount everytime a feature is clicked.
    clearClickedFeature()
  }

  const setLayerVisibility = () => {
    // get the visiblity of the clicked feature layer and set it to the highlight layer
    let style = mapStyle.toJS()
    let clickedLayer = style.layers.filter(
      layer => layer.id === feature.layer.id
    )
    let highlight = style.layers.filter(layer => layer.id === 'clicked_feature')

    if (
      clickedLayer[0] &&
      clickedLayer[0].layout.visibility !== highlight[0].layout.visibility
    ) {
      style.layers.forEach(layer => {
        if (layer.id === 'clicked_feature') {
          layer.layout.visibility = clickedLayer[0].layout.visibility
        }
      })
      dispatch(updateMapStyle(fromJS(style)))
    }
  }

  useEffect(() => {
    buildClickedFeature()
  }, [])

  useEffect(() => {
    setLayerVisibility()
  }, [mapStyle])

  return null
}

export default ClickedFeature
