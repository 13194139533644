/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input'
import scss from './InputStyle.scss'

const Currency = ({
  key,
  featureProperties,
  setFeatureProperties,
  CustomCurrencyValue,
  handleAllChanges,
  propertyKey,
  readOnly,
}) => {
  const [originalValue] = useState(CustomCurrencyValue)
  const [value, setValue] = useState(CustomCurrencyValue)
  return (
    <CurrencyInput
      readOnly={readOnly}
      className={scss.defaultInput}
      prefix='$'
      name={key}
      placeholder={value || '$'}
      value={value || null}
      onChangeEvent={e => {
        setValue(e.target.value)
      }}
      onBlur={() => {
        if (originalValue !== value) {
          featureProperties[propertyKey] = value
          setFeatureProperties(featureProperties)
          handleAllChanges()
        }
      }}
    />
  )
}

export default Currency
