/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BlockPicker } from 'react-color'
import { fromJS } from 'immutable'
import { setMapStyle } from '../../../../../actions'
import myAssetMapColors from '../../../../../utilities/componentConstants/myAssetMapColors.js'
import colorPickerScss from '../../../../CSSModules/ColorPicker.scss'

const LabelColor = ({
  layerId,
  textColor,
  textBorder,
  textBorderWidth,
  setDisplaySaveButtonOnMenu,
}) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const style = mapStyle.toJS()
  const [showColorPickerForLabel, setShowColorPickerForLabel] = useState(false)
  const [showColorPickerForBorderLabel, setShowColorPickerForBorderLabel] =
    useState(false)

  const [borderWidth, setBorderWidth] = useState(textBorderWidth)

  const handleClickForLabel = () =>
    setShowColorPickerForLabel(!showColorPickerForLabel)

  const handleClickForBorderLabel = () =>
    setShowColorPickerForBorderLabel(!showColorPickerForBorderLabel)

  const handleFontColorChange = color => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.paint['text-color'] = color.hex
      })

    setDisplaySaveButtonOnMenu(true)
    dispatch(setMapStyle(fromJS(style)))
  }

  const handleBorderColorChange = color => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.paint['text-halo-color'] = color.hex
      })
    setDisplaySaveButtonOnMenu(true)
    dispatch(setMapStyle(fromJS(style)))
  }

  const handleBorderWidthChange = widthValue => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.paint['text-halo-width'] = parseInt(widthValue) || 1
      })
    setDisplaySaveButtonOnMenu(true)
    dispatch(setMapStyle(fromJS(style)))
  }

  return (
    <div style={{ display: 'flex', paddingTop: '15px' }}>
      <div>
        <div style={{ fontSize: '14px', width: '100%' }}>Label</div>
        <div
          style={{
            display: 'flex',
            fontSize: 'smaller',
            flexDirection: 'column',
          }}
        >
          <div>Color</div>
          {!showColorPickerForLabel && (
            <input
              onClick={handleClickForLabel}
              style={{
                backgroundColor: textColor,
                border: 'solid 0.5px #41678b',
                borderRadius: '4px',
                color: '#fff0',
                cursor: 'pointer',
                height: '30px',
                width: '46px',
                textAlign: 'left',
              }}
            />
          )}
          {showColorPickerForLabel && (
            <div
              style={{ position: 'fixed', top: 120, left: 100, zIndex: 1000 }}
            >
              <div className={colorPickerScss.colorPickerPopover}>
                <div
                  role='button'
                  className={colorPickerScss.colorPickerCover}
                  style={{ backgroundColor: '#00000050', borderRadius: '9px' }}
                  onClick={handleClickForLabel}
                />
                <BlockPicker
                  colors={myAssetMapColors}
                  triangle='hide'
                  type='color'
                  color={textColor}
                  onChangeComplete={color => {
                    handleFontColorChange(color)
                    handleClickForLabel()
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginLeft: '50px',
          fontSize: 'smaller',
          flexDirection: 'column',
        }}
      >
        <div style={{ fontSize: '14px', width: '100%' }}>Halo</div>
        <div style={{ display: 'flex' }}>
          <div>
            <div>Color</div>
            {!showColorPickerForBorderLabel && (
              <input
                onClick={handleClickForBorderLabel}
                style={{
                  backgroundColor: textBorder,
                  border: 'solid 0.5px #41678b',
                  borderRadius: '4px',
                  color: '#fff0',
                  cursor: 'pointer',
                  height: '30px',
                  width: '46px',
                  textAlign: 'left',
                }}
              />
            )}
            {showColorPickerForBorderLabel && (
              <div
                style={{
                  position: 'fixed',
                  top: 120,
                  left: 100,
                  zIndex: 1000,
                }}
              >
                <div className={colorPickerScss.colorPickerPopover}>
                  <div
                    role='button'
                    className={colorPickerScss.colorPickerCover}
                    style={{
                      backgroundColor: '#00000050',
                      borderRadius: '9px',
                    }}
                    onClick={handleClickForBorderLabel}
                  />
                  <BlockPicker
                    colors={myAssetMapColors}
                    triangle='hide'
                    type='color'
                    color={textBorder}
                    onChangeComplete={color => {
                      handleBorderColorChange(color)
                      handleClickForBorderLabel()
                    }}
                    style={{
                      margin: 'auto',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              alignItems: 'flex-start',
              display: 'flex',
              padding: '0px 15px',
              flexDirection: 'column',
            }}
          >
            <div>Thickness</div>
            <input
              value={borderWidth || 1}
              min={0}
              max={6}
              type='number'
              style={{
                marginLeft: '50px',
                border: 'solid 0.5px #41678b',
                borderRadius: '4px',
                color: 'white',
                cursor: 'pointer',
                height: '30px',
                width: '46px',
                margin: 'inherit',
              }}
              onChange={e => {
                setBorderWidth(e.target.value)
                handleBorderWidthChange(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LabelColor
