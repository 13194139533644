/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import { DialogTitle } from '@material-ui/core'
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { ThemeProvider } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import mainModalTheme from '../../../../../../utilities/componentConstants/mainModalTheme'
import scss from './DeleteAttributePopup.scss'
import modalScss from '../../../../../CSSModules/Modal.scss'
import { apis } from '../../../../../../config/apiConfig'
import { apiFetch } from '../../../../../../utilities/api'

const DeleteAttributePopup = ({
  config,
  targetProperty,
  setDisplayDeleteAttributePopup,
  getProperties,
}) => {
  const deleteAttribute = () => {
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/delete'
    const bodyParams = {
      layerID: layerId,
      key: targetProperty.attrKey,
    }

    apiFetch(url, method, bodyParams, result => {
      // getAttributes()
      getProperties()
      setDisplayDeleteAttributePopup(false)
    })
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={() => {
          setDisplayDeleteAttributePopup(false)
        }}
        open
        aria-labelledby='document-browser-dialog'
        maxWidth='lg'
      >
        <DialogTitle
          id='document-browser-title'
          style={{
            paddingTop: '20px',
            width: 'auto',
            borderStyle: 'none',
            background: '#213445',
          }}
          className={scss.NewFolderPopup}
        >
          Delete
        </DialogTitle>
        <div
          style={{
            height: '75px',
            background: '#213445',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'center',
            color: '#314d68',
          }}
        >
          <span style={{ color: 'transparent' }}>x</span>
          <div style={{ width: '80%' }}>
            <span
              style={{ background: 'white !important', color: 'white' }}
              className={scss.InputBox}
              type='text'
              autoFocus
            >
              Are you sure you want to delete this Feature Attribute?
            </span>
          </div>
        </div>

        <DialogActions
          style={{
            borderStyle: 'none',
            paddingTop: '0',
            background: '#213445',
          }}
        >
          <button
            className={scss.CancelButton}
            onClick={() => {
              setDisplayDeleteAttributePopup(false)
            }}
          >
            CANCEL
          </button>
          <button
            className={scss.DeleteButton}
            onClick={() => {
              deleteAttribute()
              setDisplayDeleteAttributePopup(false)
            }}
          >
            DELETE
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default DeleteAttributePopup
