import { Storage } from 'aws-amplify'

export const makeUniqueID = length => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const makeUniqueIDNumbersOnly = length => {
  let result = ''
  const characters = '0123456789'
  const charactersLength = characters.length
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/* eslint-disable guard-for-in */
/* eslint-disable no-prototype-builtins */
/**
 * Takes an array of filepaths obtained from S3
 * @param {array} s3Contents
 * @Example of s3Contents
 *[
    {
        "key": "client_data/1/documents/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:04.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Delete Files/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T19:13:20.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Empty Folder on Sidebar/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:34.000Z",
        "size": 0
    },...]
]
 * @returns an array with a file tree structure
 */
export const generateFileTreeFolderNames = (accountID, s3Contents) => {
  if (!s3Contents) return []

  // Obtain only the keu
  s3Contents = s3Contents.map(item => {
    const element = {}
    element.key = item.key
    return element
  })

  // Remove repetitive beginning path
  s3Contents = s3Contents
    .map(item => {
      item.key = item.key.replace('client_data/' + accountID + '/', '')
      return item
    })
    .map(item => {
      item.key = item.key.replace('//', '/')
      return item
    })

  // Return only folders since files are not shown on the file tree of the file manager
  s3Contents = s3Contents
    .map(item => {
      if (item.key.includes('/')) {
        return item
      }
      return null
    })
    .filter(item => item !== null)

  s3Contents = s3Contents
    .map(item => {
      if (item.key.includes('.')) {
        return null
      }
      return item
    })
    .filter(item => item !== null)

  const paths = s3Contents

  const fileTree = []
  const level = { fileTree }

  // Each folder gets a "unique" id for the three view,
  // for now a 1 digit integer does the trick
  let simpleId = 0
  paths.forEach(path => {
    path.key.split('/').reduce((r, value, i, a) => {
      if (!r[value]) {
        r[value] = { fileTree: [] }
        if (value) {
          r.fileTree.push({
            label: value,
            value: (simpleId += 1),
            children: r[value].fileTree,
          })
        }
      }
      return r[value]
    }, level)
  })

  return fileTree
}

/**
 * Returns the string path to the target nodeClicked
 * @param fileTree - nested object
 * @param nodeClicked - UNIQUE value of the node
 * @Example
 * const fileTree = [
  {
      "value": "pnLQmoSg6DpWSfE",
      "label": "documents",
      "children": [
          {
              "value": "MZ0K7elisBfVS3O",
              "label": "My House",
              "children": [
                  {
                      "value": "cn0PVdcGR4ee9cv",
                      "label": "Really LOOOOOOOOOOONNNNNNNNG TTTTTTIIIIIITTLE",
                      "children": [... ]
                  }
          ]
      }
    ]
 * @returns path string
 */
export const findPathToClickedNode = (fileTree, nodeClicked) => {
  const key = 'value'
  const path = []
  let pathText = ''
  const keyExists = obj => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false
    }
    if (obj.hasOwnProperty(key) && obj[key] === nodeClicked) {
      return true
    }
    if (Array.isArray(obj)) {
      const parentKey = path.length ? path.pop() : ''
      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`)
        const result = keyExists(obj[i], key)
        if (result) {
          pathText = obj[i].label + '/' + pathText
          return result
        }
        path.pop()
      }
    } else {
      for (const k in obj) {
        path.push(k)
        const result = keyExists(obj[k], key)
        if (result) {
          return result
        }
        path.pop()
      }
    }

    return false
  }

  keyExists(fileTree)
  return pathText
}

export const formatDateObjectIntoTimeStamp = dateObject => {
  const addZero = itemToAddZero => {
    return ('0' + itemToAddZero).slice(-2)
  }

  const timeStamp = `${[
    dateObject.getFullYear(),
    addZero(dateObject.getMonth() + 1),
    addZero(dateObject.getDate()),
  ].join('-')} ${[
    addZero(dateObject.getHours()),
    addZero(dateObject.getMinutes()),
    addZero(dateObject.getSeconds()),
  ].join(':')}`

  return timeStamp
}

export const formatBytes = (a, b = 2, k = 1024) => {
  const d = Math.floor(Math.log(a) / Math.log(k))
  return a === 0
    ? '-'
    : parseFloat((a / k ** d).toFixed(Math.max(0, b))) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
}
/**
 * Takes an array of filepaths obtained from S3 and returns the data for the table
 * @param {array} s3Contents
 * @Example of s3Contents
 *[
    {
        "key": "client_data/1/documents/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:04.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Delete Files/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T19:13:20.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Empty Folder on Sidebar/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:34.000Z",
        "size": 0
    },...]
]
 * @returns an array with a table structure
 */
export const generateTableData = s3Contents => {
  if (!s3Contents) return []

  s3Contents = s3Contents.map(item => {
    const nameArray = item.keyVal.split('/')

    const myDate = new Date(item.lastModified)

    item.dateUploaded = formatDateObjectIntoTimeStamp(myDate)
    item.dateUpdated = '-'
    if (
      nameArray[nameArray.length - 1] &&
      nameArray[nameArray.length - 1].includes('.')
    ) {
      // it is a file
      item.name = nameArray[nameArray.length - 1]
      item.size = formatBytes(item.size)
    } else {
      // it is a folder
      item.name = nameArray[nameArray.length - 1]
      item.size = '-'
    }

    delete item.keyVal
    delete item.eTag
    return item
  })

  return s3Contents
}

/**
 * Returns the files that are on the current cwd from the S3
 * @param {JSON} s3Contents - S3 content JS object similar to the one below.
 * @param {string} cwd - current working directory.
 * @Example of s3Contents
 *[
    {
        "key": "client_data/1/documents/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:04.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Delete Files/",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T19:13:20.000Z",
        "size": 0
    },
    {
        "key": "client_data/1/documents/Delete Files/somefile",
        "eTag": "\"d41d8cd98f00b204e9800998ecf8427e\"",
        "lastModified": "2021-01-28T18:33:34.000Z",
        "size": 0
    },...]
]
 * @returns JSON Object with the files on that cwd
 */
export const getDirectFolderChildren = (s3Contents, cwd) => {
  if (!s3Contents) return []

  s3Contents = s3Contents.map(item => {
    if (item.key.includes(cwd)) {
      const awsPath = item.key
      let decider = item.key.replace(cwd, '')
      decider = decider.split('/').filter(deciderItem => deciderItem !== '')
      if (decider.length > 0) {
        return {
          keyVal: decider[0],
          size: item.size,
          lastModified: item.lastModified,
          awsPath,
        }
      }
      return null
    }
    return null
  })

  s3Contents = s3Contents.filter(item => item !== null)

  s3Contents = Array.from(new Set(s3Contents.map(s => s.keyVal))).map(
    keyVal => {
      return {
        keyVal,
        size: s3Contents.find(s => s.keyVal === keyVal).size,
        lastModified: s3Contents.find(s => s.keyVal === keyVal).lastModified,
        awsPath: s3Contents.find(s => s.keyVal === keyVal).awsPath,
      }
    }
  )

  return s3Contents
}

export const openFileOnAnotherWindow = async awsPath => {
  const signedURL = await Storage.get(awsPath)
  window.open(signedURL)
}

export const downloadFile = async awsPath => {
  const result = await Storage.get(awsPath)
  return result
}

export const eraseFile = async folderPath => {
  const result = await Storage.remove(folderPath)
}

export const checkIfDocumentsFolderExist = async basePath => {
  let files = await Storage.list(basePath) // for listing ALL files without prefix, pass '' instead

  files = files
    .map(item => {
      // Check for the number of /
      if (!item.key || !item.key.match(/\//g).length) return null
      // the root folder contains three /
      if (item.key.match(/\//g).length !== 3) return null
      // root folder has / as a last element
      if (
        !item.key ||
        !item.key.length ||
        item.key.charAt(item.key.length - 1) !== '/'
      )
        return null

      // last folder should be documents/
      if (!item.key.includes('documents/')) return null

      return item
    })
    .filter(item => item !== null).length

  if (files === 1) return true

  return false
}
