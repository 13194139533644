import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../utilities/geospatial'
import * as mapUtils from '../../../utilities/mapStyle'
import { updateMapStyle } from '../../../actions'
import { buildMeasureId, checkForFeature } from './Utils'

export default function Point({
  layerId,
  targetFeature,
  measureType,
  precision,
}) {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const style = geoUtils.clone(mapStyle)
  const labelSource = mapUtils.getSource(style, 'measure_label_cold')

  const addFeature = type => {
    const measureId = buildMeasureId(
      targetFeature.properties.mamid,
      layerId,
      type
    )
    const measurement = geoUtils.getCoordinates(targetFeature, precision)
    const point = geoUtils.clone(targetFeature)
    point.properties.measureType = 'coordinates'
    point.properties.measurement = measurement.lat
    point.properties.measurementSecondLine = measurement.lng
    point.properties.measureId = measureId
    point.properties.mamid = targetFeature.properties.mamid
    point.properties.layerId = layerId
    labelSource.data.features.push(point)
    style.sources.measure_label_cold = labelSource
    dispatch(updateMapStyle(fromJS(style)))
  }

  const removeFeature = type => {
    const measureId = buildMeasureId(
      targetFeature.properties.mamid,
      layerId,
      type
    )
    const newFeatures = labelSource.data.features.filter(
      feature => feature.properties.measureId !== measureId
    )
    labelSource.data.features = newFeatures
    style.sources.measure_label_cold = labelSource
    dispatch(updateMapStyle(fromJS(style)))
  }

  useEffect(() => {
    measureType.forEach(type => {
      const featureInSource = checkForFeature(
        mapStyle,
        buildMeasureId(targetFeature.properties.mamid, layerId, type)
      )
      if (featureInSource.length) {
        removeFeature(type)
      } else {
        addFeature(type)
      }
    })
  }, [targetFeature])

  return null
}
