/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Storage, API } from 'aws-amplify'
import scss from './DocumentBrowserV2.scss'
import 'react-contexify/dist/ReactContexify.min.css'
import mainModalTheme from '../../utilities/componentConstants/mainModalTheme'
import documentMAMlogo from '../../assets/logo/mam_logo_documents.png'
import * as DocumentUtil from './DocumentBrowserV2Util'
import DropZoneV2 from './DropZoneV2/DropZoneV2'

import SearchBar from './SearchBar/SearchBar.js'
import FileTree from './FileTree/FileTree.js'
import Table from './Table/Table.js'
import NewCreateMenu from './NewCreateMenu/NewCreateMenu'
import Cwd from './Cwd/Cwd'

import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'

const DocumentBrowserV2 = ({
  onClose,
  linkMode,
  getAWSLink,
  typeDocumentKey,
  handleDocumentChange,
}) => {
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState([])
  const [fileTreeFolderNames, setFileTreeFolderNames] = useState([])

  const user = useSelector(state => state.user)
  const [cwd, setCwd] = useState(
    'client_data/' + user.accountID + '/documents/'
  )
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [tableData, setTableData] = useState('')
  const [s3Contents, setS3Contents] = useState([])
  const [generateNewFileTree, setGenerateNewFileTree] = useState(true)
  const [isNewCreateMenuVisible, setIsNewCreateMenuVisible] = useState(false)
  const [rawData, setRawData] = useState([])
  const [triggerRefresh, setTriggerRefresh] = useState(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Size',
        accessor: 'size',
      },
      {
        Header: 'Date Uploaded',
        accessor: 'dateUploaded',
      },
    ],
    []
  )

  async function fetchData(path) {
    let rawContent

    if (generateNewFileTree) {
      rawContent = await Storage.list(
        'client_data/' + user.accountID + '/documents/'
      )
      setGenerateNewFileTree(false)

      const folderList = DocumentUtil.generateFileTreeFolderNames(
        user.accountID,
        rawContent
      )

      setFileTreeFolderNames(folderList)
    }

    rawContent = await Storage.list(`${path}`)
    setRawData(rawContent)
    rawContent = DocumentUtil.getDirectFolderChildren(rawContent, path)
    setS3Contents(rawContent)
  }

  useEffect(() => {
    fetchData(cwd)
    setTriggerRefresh(false)
  }, [cwd, triggerRefresh])

  useEffect(() => {
    setTableData(DocumentUtil.generateTableData(s3Contents))
  }, [s3Contents])

  const toggleIsNewCreateMenuVisible = () => {
    if (isNewCreateMenuVisible) setIsNewCreateMenuVisible(false)
    else setIsNewCreateMenuVisible(true)
  }

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          onClose={() => {
            onClose()
          }}
          open
          aria-labelledby='document-browser-dialog'
          maxWidth='lg'
        >
          <DialogTitle
            id='document-browser-title'
            style={{ paddingTop: '20px', paddingBottom: '20px' }}
          >
            <span
              style={{ display: 'flex', alignItems: 'center', height: '30px' }}
            >
              <img
                alt='document-logo'
                src={documentMAMlogo}
                className={scss.logo}
              ></img>
              <SearchBar
                accountID={user.accountID}
                rawData={rawData}
                setS3Contents={setS3Contents}
                setShowSearchResults={setShowSearchResults}
              />
            </span>
          </DialogTitle>
          <div className={scss.mainContainer}>
            <div style={{ display: 'flex' }}>
              <ResizableBox 
                className={scss.documentTreeContainer}
                resizeHandles={['e']}
                width={250}
                minConstraints={[150]}
                maxConstraints={[1000]}
                handle={(handleProps) => <div className={scss['custom-handle-e']} {...handleProps} />}
              >
                <button
                  className={scss.newButton}
                  type='button'
                  onClick={toggleIsNewCreateMenuVisible}
                >
                  <FontAwesomeIcon className={scss['plus-icon']} icon='plus' />
                  <span>New</span>
                </button>
                <NewCreateMenu
                  cwd={cwd}
                  isNewCreateMenuVisible={isNewCreateMenuVisible}
                  setIsNewCreateMenuVisible={setIsNewCreateMenuVisible}
                  setTriggerRefresh={setTriggerRefresh}
                  setGenerateNewFileTree={setGenerateNewFileTree}
                />
                <FileTree
                  nodes={fileTreeFolderNames}
                  checked={checked}
                  setChecked={setChecked}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  setCwd={setCwd}
                  accountID={user.accountID}
                  setShowSearchResults={setShowSearchResults}
                />
              </ResizableBox>

              <div className={scss.mainFilesContainer}>
                <div className={scss.cwd}>
                  <Cwd
                    cwd={cwd}
                    accountID={user.accountID}
                    setCwd={setCwd}
                    showSearchResults={showSearchResults}
                    setShowSearchResults={setShowSearchResults}
                  />
                </div>
                {tableData ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    setCwd={setCwd}
                    cwd={cwd}
                    setShowSearchResults={setShowSearchResults}
                    setTriggerRefresh={setTriggerRefresh}
                    setGenerateNewFileTree={setGenerateNewFileTree}
                    linkMode={linkMode}
                    typeDocumentKey={typeDocumentKey}
                    handleDocumentChange={handleDocumentChange}
                    getAWSLink={getAWSLink}
                    onClose={onClose}
                  />
                ) : null}
                {s3Contents.length === 0 ? (
                  <DropZoneV2
                    height='83%'
                    cwd={cwd}
                    setTriggerRefresh={setTriggerRefresh}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Dialog>
      </ThemeProvider>
    </>
  )
}
export default DocumentBrowserV2
