import { useEffect } from 'react'

const EditHotKeys = ({ cancelEdit, setDeleteVertex }) => {
  const handleKeyPress = e => {
    if (e.key == 'Escape') {
      cancelEdit()
    }
    if (e.key == 'Backspace') {
      setDeleteVertex(true)
    }
    if (e.keyCode == 46) {
      setDeleteVertex(true)
    }
  }

  const enableKeyEvents = () => {
    const main = document.getElementById('main')
    main.addEventListener('keydown', handleKeyPress, true)
  }

  const disableKeyEvents = () => {
    const main = document.getElementById('main')
    main.removeEventListener('keyup', handleKeyPress, true)
  }

  useEffect(() => {
    enableKeyEvents()
    return () => {
      disableKeyEvents()
    }
  }, [])

  return null
}

export default EditHotKeys
