import React from 'react'
import printScss from '../../Print.scss'

const imgContainerStyle = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  backgroundSize: 'cover',
  overflow: 'hidden',
  cursor: 'not-allowed',
}
const fullWidthHeight = {
  width: '100%',
  height: '100%',
}
function ImageElement(props) {
  const object = props.object
  const imageElements = props.imageElements
  const handleElementClick = props.handleElementClick

  return (
    <>
      <div
        style={imgContainerStyle}
        className={[
          'image',
          printScss[imageElements == object.id ? 'activeEditWindow' : ''],
        ].join(' ')}
        onClick={handleElementClick}
      >
        <img
          src={object.imagePreviewUrl}
          style={fullWidthHeight}
          draggable='false'
        />
      </div>
    </>
  )
}
export default ImageElement
