import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fromJS } from 'immutable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import appScss from '../App/App.scss'
import scss from './DocumentBrowser.scss'
import { apis } from '../../config/apiConfig'
import { setMapStyle, updateMapStyle } from '../../actions/index'
import * as utils from '../../utilities/util'
import ConfirmModal from '../../components/Modal/Confirm'

import AsyncFetch from '../../utilities/AsyncFetch'

const DocumentKey = ({
  layerId,
  layerProperties,
  documentKeyValue,
  modal = false,

  resetDocumentKey,
  onClose,
  calculatePathFromDocumentKey,

  mapStyle,
  updateMapStyle,
}) => {
  let style = mapStyle.toJS()
  // STATE
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [initialOpen, setInitialOpen] = useState(modal)
  const [documentKeys, setDocumentKeys] = useState([])
  const [layerDocumentKey, setLayerDocumentKey] = useState(null)
  const [layerDocumentKeyInitial, setLayerDocumentKeyInitial] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  const buildFetchParams = () => {
    const indexOfLayer = style.layers.findIndex(ele => ele.id == layerId)

    let newStyle = { ...style }
    newStyle.layers[indexOfLayer].metadata.documentKey = layerDocumentKey.value
    updateMapStyle(fromJS(newStyle))

    const method = 'POST'
    const body = {
      layerID: layerId,
      metadata: newStyle.layers[indexOfLayer].metadata,
    }

    let url = apis['apiDatabase'].uri + 'layer/update/layer'
    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const getDocumentKey = () => {
    if (!layerId) return null
    const layer = style.layers.filter(layer => layer.id === layerId)
    return layer[0].metadata.documentKey || null
  }

  const fetchFinished = () => {
    // do something here
    if (typeof resetDocumentKey === 'function')
      resetDocumentKey(layerDocumentKey)

    setLayerDocumentKeyInitial(layerDocumentKey) //Reset initial to be new value
    setFetchObjects(null)
    setFetching(null)
    setInitialOpen(false)
    setConfirmOpen(false)
    if (typeof onClose === 'function') onClose()
  }

  const handleUpdateDocumentKey = () => {
    buildFetchParams()
  }
  const handleUpdateCancel = () => {
    setLayerDocumentKey(layerDocumentKeyInitial)
    setInitialOpen(false)
    setConfirmOpen(false)
    if (typeof onClose === 'function') onClose()
  }

  const onSelectChange = e => {
    const selected = documentKeys.filter(type => type.value === e.target.value)
    setLayerDocumentKey(selected[0])

    if (!initialOpen) {
      setConfirmOpen(true)
    }
  }

  useEffect(() => {
    let docKeyArray = [
      {
        label: 'No Document Attribute',
        value: 'No Document Attribute',
      },
    ]
    layerProperties.map(item => {
      if (!['document'].includes(item.type))
        docKeyArray.push({
          label: item.name,
          value: item.key,
        })
    })
    setDocumentKeys(docKeyArray)

    const currentDocumentKeyItem = docKeyArray.find(
      item => item.value == documentKeyValue
    )
    let docKeyItem = currentDocumentKeyItem
      ? currentDocumentKeyItem
      : docKeyArray[0]

    setLayerDocumentKey(docKeyItem)
    setLayerDocumentKeyInitial(docKeyItem)

    // console.log(3,documentKeyValue)
    if (!modal && documentKeyValue === null) setInitialOpen(true)
  }, [])

  var documentKeyOptions = utils.buildOptions(
    layerDocumentKey && layerDocumentKey.value ? layerDocumentKey.value : null,
    documentKeys
  )

  if (!documentKeyValue) documentKeyValue = getDocumentKey()

  const documentKeySelect = () => {
    return (
      <div className={scss['select-prefix']} style={{ width: '256px' }}>
        <FontAwesomeIcon className={scss['select-prefix-file']} icon='file' />
        <FontAwesomeIcon className={scss['select-prefix-key']} icon='key' />
        <select value={layerDocumentKey.value} onChange={onSelectChange}>
          {documentKeyOptions}
        </select>
        <FontAwesomeIcon
          className={scss['select-prefix-arrow']}
          icon={('fal', 'chevron-down')}
        />
      </div>
    )
  }

  const buildPaths = () => {
    let currentPath, newPath
    if (typeof calculatePathFromDocumentKey === 'function') {
      if (
        layerDocumentKeyInitial &&
        layerDocumentKeyInitial.value !== 'No Document Attribute'
      )
        currentPath = calculatePathFromDocumentKey(
          null,
          layerDocumentKeyInitial.value
        )
      if (layerDocumentKey)
        newPath = calculatePathFromDocumentKey(null, layerDocumentKey.value)
    }

    return (
      <>
        {currentPath && (
          <>
            <p
              className={[
                scss['confirm-modal-text-normal-subheading'],
                scss['confirm-modal-margin-bottom-8'],
              ].join(' ')}
            >
              Current file path:
            </p>
            <p
              className={[
                scss['confirm-modal-text-normal'],
                scss['confirm-modal-margin-bottom-8'],
              ].join(' ')}
            >{`${currentPath.folder}`}</p>
          </>
        )}

        {newPath && (
          <>
            <p
              className={[
                scss['confirm-modal-text-normal-subheading'],
                scss['confirm-modal-margin-bottom-8'],
              ].join(' ')}
            >
              New file path:
            </p>
            <p
              className={[scss['confirm-modal-text-normal']].join(' ')}
            >{`${newPath.folder}`}</p>
          </>
        )}
      </>
    )
  }

  const initialModal = () => {
    return (
      <ConfirmModal
        title='Set document key'
        onConfirm={handleUpdateDocumentKey}
        onClose={handleUpdateCancel}
        maxWidth='sm'
      >
        <p
          className={[scss['confirm-modal-text-normal']].join(' ')}
          style={{ marginBottom: '24px' }}
        >
          By selecting an attribute, a new folder structure will be started
          based on the attribute selected. This attribute should be unique to
          each feature.
        </p>
        <p
          className={[scss['confirm-modal-text-normal']].join(' ')}
          style={{ marginBottom: '24px' }}
        >
          What attribute would you like to link your documents to?
        </p>
        {documentKeySelect()}
        <br />
        {buildPaths()}
      </ConfirmModal>
    )
  }

  const editModal = () => {
    return (
      <ConfirmModal
        title='Edit document key'
        onConfirm={handleUpdateDocumentKey}
        onClose={handleUpdateCancel}
        maxWidth='sm'
        submitButtonText='Accept'
      >
        <p
          className={[scss['confirm-modal-text-normal']].join(' ')}
          style={{ marginBottom: '24px' }}
        >
          By changing the document key attribute, a new folder structure will be
          started based on the attribute selected. If there was previously a
          document key attribute set up, that folder structure will remain, but
          no longer be linked.
        </p>
        {documentKeySelect()}
        <br />
        {buildPaths()}
      </ConfirmModal>
    )
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {confirmOpen && editModal()}
      {initialOpen
        ? layerDocumentKey && initialModal()
        : layerDocumentKey && documentKeySelect()}
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMapStyle: setMapStyle,
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentKey)
