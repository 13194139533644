import React, { useState, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import modalScss from '../../containers/CSSModules/Modal.scss'
import appScss from '../../containers/App/App.scss'

import mainModalTheme from '../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  title = '',
  onConfirm,
  onClose,
  maxWidth = 'sm',
  cancelButtonText = 'Cancel',
  submitButtonText = 'Save',

  children,
}) {
  // * State
  const [fetching, setFetching] = useState(false)
  const [open, setOpen] = useState(true)

  const handleOnConfirm = () => {
    setFetching(true)
    if (typeof onConfirm === 'function') onConfirm()
  }

  const handleOnClose = () => {
    // cleanup
    setOpen(false)
    setFetching(false)
    if (typeof onConfirm === 'function') onClose()
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby={title + '-dialog'}
        open={open}
        maxWidth={maxWidth}
      >
        <DialogTitle id={title + '-dialog'}>
          {title}
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          {children}
        </DialogContent>

        <DialogActions>
          <button
            className={appScss.blueButton}
            onClick={fetching ? null : handleOnConfirm}
          >
            {fetching ? (
              <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              submitButtonText
            )}
          </button>

          <button className={appScss.altBlueButton} onClick={handleOnClose}>
            {cancelButtonText}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ConfirmModal(props) {
  return <SimpleDialog {...props} />
}
