/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import scss from '../../TabTemplate.scss'
import inputScss from './InputStyle.scss'
import { makeUniqueIDNumbersOnly } from '../../../../../utilities/util'

const CustomDropdown = ({
  CurrentSelectedValue,
  featureProperties,
  setFeatureProperties,
  CustomDropdownValues,
  handleAllChanges,
  propertyKey,
}) => {
  const [selectedValue, setSelectedValue] = useState(CurrentSelectedValue || '')
  const [originalValue] = useState(CurrentSelectedValue || '')
  const [optionList, setOptionList] = useState(CustomDropdownValues || [])
  const [displaySelectedOption, setDisplaySelectedOption] = useState(true)

  //  We remove the selected option from the dropdown menu so it is not display twice
  useEffect(() => {
    let optionListValues = optionList
    if (optionListValues.includes(selectedValue)) {
      optionListValues = optionListValues.filter(item => item !== selectedValue)
      setOptionList(optionListValues)
    }
  }, [])

  return (
    <select
      value={selectedValue || ''}
      onChange={e => setSelectedValue(e.target.value)}
      className={[scss.CustomSelect, inputScss.defaultInput].join(' ')}
      onBlur={() => {
        if (originalValue !== selectedValue) {
          featureProperties[propertyKey] = selectedValue
          setFeatureProperties(featureProperties)
          handleAllChanges()
        }
      }}
    >
      <option
        disabled='disabled'
        style={{ display: 'none' }}
        value={selectedValue || ''}
        className={scss.CustomOption}
      >
        {selectedValue || ' '}
      </option>
      {optionList &&
        optionList.length &&
        optionList.length > 0 &&
        optionList.map(dropDownValue => {
          return (
            <option
              key={makeUniqueIDNumbersOnly(9999)}
              value={dropDownValue}
              className={scss.CustomOption}
            >
              {dropDownValue}
            </option>
          )
        })}
    </select>
  )
}

export default CustomDropdown
