import {
  SET_MAP_STYLE,
  TOGGLE_MAP_STYLE,
  UPDATE_MAP_STYLE,
  UPDATE_LAYOUT_PROPERTY,
} from '../actions/types'

import { styleToggleObject } from '../mapStyles/style_config'
import { fromJS } from 'immutable'
import { filter } from 'lodash'

const getOutlinesAndSymbolLayers = payload => {
  let ids = payload.map(id => {
    let newIds = [id, id + '_outline', id + 'symbol']
    return [...newIds]
  })
  const merged = [].concat.apply([], ids)
  return merged
}
export default function MapStyleReducer(state = null, action) {
  switch (action.type) {
    case SET_MAP_STYLE: {
      return action.payload
    }

    case 'TOGGLE_MAP_LAYER': {
      let newMapStyle = state.toJS()
      newMapStyle.layers
        .filter(layer => action.payload.includes(layer.id))
        .forEach(layer => {
          if (layer.layout.visibility === 'none') {
            layer.layout.visibility = 'visible'
          } else {
            layer.layout.visibility = 'none'
          }
        })
      return fromJS(newMapStyle)
    }

    case 'TOGGLE_MAP_LAYERS_ON': {
      const layerIds = getOutlinesAndSymbolLayers(action.payload)
      let newMapStyle = state.toJS()
      newMapStyle.layers
        .filter(layer => layerIds.includes(layer.id))
        .forEach(layer => {
          layer.layout.visibility = 'visible'
        })
      return fromJS(newMapStyle)
    }

    case 'TOGGLE_MAP_LAYERS_OFF': {
      const layerIds = getOutlinesAndSymbolLayers(action.payload)
      let newMapStyle = state.toJS()
      newMapStyle.layers
        .filter(layer => layerIds.includes(layer.id))
        .forEach(layer => {
          layer.layout.visibility = 'none'
        })
      return fromJS(newMapStyle)
    }

    case TOGGLE_MAP_STYLE: {
      // get new map style from styleSwitcher
      let newStyle = styleToggleObject[action.payload].toJS()
      let layerArray = []
      // iterate layers in current state
      state.toJS().layers.forEach(currentLayer => {
        let currentLayerId = currentLayer.id
        let swapLayer = currentLayer
        // iterate the newStyle layers
        // if the layer id === the current layer id, set it as the swap layer
        newStyle.layers.forEach(newLayer => {
          if (currentLayerId === newLayer.id) {
            swapLayer = newLayer
          }
        })
        // add swap layer to the layers array
        layerArray.push(swapLayer)
      })

      // set the new state layers to the new layersArray
      let newState = state.toJS()
      newState.layers = layerArray
      newState.metadata.id = action.payload

      return fromJS(newState)
    }

    case UPDATE_MAP_STYLE: {
      return action.payload
    }

    default:
      return state
  }
}

export function MapRefReducer(state = null, action) {
  switch (action.type) {
    case 'SET_MAP_REF': {
      return action.payload
    }
    default:
      return state
  }
}
