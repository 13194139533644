/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'

import scss from '../TabTemplate.scss'
import TabNav from '../TabNav/TabNav'
import { getAttributesThatBelongToTab } from '../TabTemplateUtil'

import Input from './Input/Input'
import Currency from './Input/Currency'
import CustomDatePicker from './Input/CustomDatePicker'
import CustomDropdown from './Input/CustomDropdown'
import CustomTextArea from './Input/CustomTextArea'

// utilities
import * as utils from '../../../../utilities/util'

const AttributeTab = ({
  tabs,
  tabAttribute,
  tabsPerPage,
  currentPage,
  activeTabIndex,
  setActiveTabIndex,
  handleAllChanges,
  featureProperties,
  setFeatureProperties,
  enlargeWindow,
  setTotalAttributeTabPageNumber,
  isCommonLayer,
}) => {
  const [newTabs, setNewTabs] = useState([])
  const [attributeForThisTab, setAttributeForThisTab] = useState(
    getAttributesThatBelongToTab(newTabs[activeTabIndex], tabAttribute) || []
  )

  //  When a new layer has been created, we create a default tab to throw all the attributes in there
  const generateValues = () => {
    const newTabsValues = newTabs
      .filter(tab => tab.id !== 'Attributes')
      .filter(tab => tab.id !== 'PropertyHolder')

    if (newTabsValues && newTabsValues.length === 0) {
      setAttributeForThisTab([])
      setNewTabs([
        {
          id: 'Attributes',
          title: 'Attributes',
          itemsIds: [],
        },
      ])

      const allAttributesIds = tabAttribute.filter(
        item => item.type !== 'document (Feature)'
      )

      setAttributeForThisTab([...allAttributesIds])
    }
  }

  useEffect(() => {
    generateValues()
  }, [])

  //  When a new layer has been created, we create a default tab to throw all the attributes in there
  useEffect(() => {
    const newTabsValues = tabs
      .filter(tab => tab.id !== 'Attributes')
      .filter(tab => tab.id !== 'propertiesHolder')

    setNewTabs(newTabsValues)
    if (newTabsValues.length > 0) {
      setAttributeForThisTab(
        getAttributesThatBelongToTab(
          newTabsValues[activeTabIndex],
          tabAttribute
        )
      )
    } else {
      setNewTabs([
        {
          id: 'Attributes',
          title: 'Attributes (Unassigned)',
          itemsIds: [],
        },
      ])

      const allAttributesIds = tabAttribute.filter(
        item => item.type !== 'document (Feature)'
      )

      setAttributeForThisTab([...allAttributesIds])
    }
  }, [tabs, tabAttribute, activeTabIndex])

  const getEditableElement = (
    key,
    type,
    value,
    currentValue,
    currentValueDisplay,
    defaultValue
  ) => {
    if (!key) return null
    if (type === 'text')
      return (
        <Input
          inputType='text'
          handleAllChanges={handleAllChanges}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          propertyKey={key}
          CustomInputValue={featureProperties[key] || defaultValue || value}
          readOnly={isCommonLayer}
        />
      )
    if (type === 'longtext') {
      return (
        <CustomTextArea
          handleAllChanges={handleAllChanges}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          propertyKey={key}
          CustomTextAreaValue={featureProperties[key] || defaultValue || value}
          readOnly={isCommonLayer}
        />
      )
    }
    if (type === 'number')
      return (
        <Input
          inputType='number'
          handleAllChanges={handleAllChanges}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          propertyKey={key}
          CustomInputValue={featureProperties[key] || defaultValue || value}
          readOnly={isCommonLayer}
        />
      )
    if (type === 'dropdown') {
      return (
        <CustomDropdown
          propertyKey={key}
          value={value}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          CustomDropdownValues={currentValue}
          CurrentSelectedValue={featureProperties[key] || defaultValue || value}
          handleAllChanges={handleAllChanges}
        />
      )
    }
    if (type === 'date') {
      return (
        <CustomDatePicker
          propertyKey={key}
          currentValueDisplay={currentValueDisplay || ''}
          CustomDateValue={featureProperties[key] || defaultValue || value}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          handleAllChanges={handleAllChanges}
          className={scss.CustomDatePicker}
        />
      )
    }
    if (type === 'currency') {
      return (
        <Currency
          handleAllChanges={handleAllChanges}
          featureProperties={featureProperties}
          setFeatureProperties={setFeatureProperties}
          propertyKey={key}
          CustomCurrencyValue={featureProperties[key] || defaultValue || value}
          className={scss.CustomCurrency}
          readOnly={isCommonLayer}
        />
      )
    }
    return null
  }

  useEffect(() => {
    if (tabsPerPage && tabsPerPage > 0) {
      if (newTabs && newTabs.length) {
        setTotalAttributeTabPageNumber(Math.ceil(newTabs.length / tabsPerPage))
      }
    }
  }, [newTabs])

  return (
    <>
      <TabNav
        tabs={newTabs}
        tabsPerPage={tabsPerPage}
        currentPage={currentPage}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <div
        style={{
          border: 'solid 1px #213445',
          borderRadius: '6px',
          boxShadow: 'inset 1px 1px 3px 1px rgba(0,0,0,0.5)',
          overflow: 'overlay',
          padding: '10px 11px',
          height: enlargeWindow ? '300px' : '200px',
          minWidth: 'fit-content',
        }}
      >
        {attributeForThisTab &&
        Array.isArray(attributeForThisTab) &&
        attributeForThisTab.length > 0 ? (
          attributeForThisTab.map(property => (
            <div
              key={utils.makeUniqueIDNumbersOnly(30)}
              className={[
                scss['attribute-grid-row'],
                scss[
                  property.type === 'longtext' ? 'AttributeGridRowTextArea' : ''
                ],
                scss[property.type === 'dropdown' ? 'dropdownInput' : ''],
              ].join(' ')}
            >
              <div
                title={property.name}
                style={{
                  maxWidth: '145px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: 'text',
                  userSelect: 'text',
                }}
              >
                {property.name}
              </div>
              {getEditableElement(
                property.key,
                property.type,
                null,
                property.value,
                null,
                property.default
              )}
            </div>
          ))
        ) : (
          <div style={{ lineHeight: '25px' }}>
            There are no Feature Attributes in your tab.
          </div>
        )}
      </div>
    </>
  )
}

export default AttributeTab
