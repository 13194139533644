import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as utils from '../../utilities/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { connect } from 'react-redux'
import { apis } from '../../config/apiConfig'
import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Skeleton from 'react-loading-skeleton'
import Checkbox from 'react-three-state-checkbox'

import './mui-switch.css'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import ReactTooltip from 'react-tooltip'

import logoDark from '../../assets/logo/logo-dark.svg'
import organizationLayerGroup from '../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../assets/icons/userLayerGroup.svg'

import ShareModal from '../Tools/ShareModal/ShareModal'
import LayerDelete from './StoreModals/LayerDelete'
import dropdownScss from '../CSSModules/Dropdown.scss'
import checkboxScss from '../CSSModules/Checkbox.scss'
import appScss from '../App/App.scss'
import layerScss from './LayerList.scss'
import scss from './Accounts.scss'

//This is the Layer Store or Data Store component
//layerStore dataStore (so we can find this component easier)
export default function LayerList({
  layerList,
  activeLayerList,
  setLayerList,
  toggleLayerChecks,
  checkLayerFilter,
  getCountChecked,
  getCheckbox,
  clickCheckbox,
  handleLayerStatus,
  isActiveGroup,
  isActiveLayer,
  isDefaultLayer,
  handleLayerDelete,
  listArea,
  addList,
  removeList,
  collapsed,
  handleCollapse,
  handleDefaultUpdate,
  resetting,
  defaultLayerFetching,
  defaultsUpdating,
}) {
  // const dispatch = useDispatch();

  // const [resultsFound, setResultsFound] = useState(false);
  const [showLayerDropdown, setLayerDropdown] = useState(false)
  const [layerListType, setLayerListType] = useState(null)

  /* ------------------------------ MODAL STATES ------------------------------ */
  const [shareVisible, setShareVisible] = useState(false)
  const [layerDeleteVisible, setLayerDelete] = useState(false)
  const [shareLayerVisible, setShareLayer] = useState(false)
  /* -------------------------------------------------------------------------- */
  const user = useSelector(state => state.user)

  const toggleLayerShare = layerKey => {
    if (shareLayerVisible == layerKey) {
      setShareLayer(false)
    } else setShareLayer(layerKey)
  }
  const openShareLayer = shareLayerVisible => setShareLayer(shareLayerVisible)

  const toggleLayerDropdown = layerKey => {
    if (showLayerDropdown == layerKey) {
      setLayerDropdown(false)
    } else setLayerDropdown(layerKey)
  }

  const toggleLayerDelete = layerKey => {
    if (layerDeleteVisible == layerKey) {
      setLayerDelete(false)
    } else setLayerDelete(layerKey)
  }
  const openLayerDelete = layerDeleteVisible =>
    setLayerDelete(layerDeleteVisible)

  const getDate = date => {
    if (!date) return '-'
    const convertedDate = new Date(date)
    return new Intl.DateTimeFormat('en-US').format(convertedDate)
  }

  const displayLayer = layer => {
    if (!checkLayerFilter(layer, layer.children)) return

    resultsFound = true

    var checkedValue
    var indeterminateValue
    var curLayerCount = getCountChecked(layer.id)
    if (curLayerCount == 0) {
      checkedValue = false
      indeterminateValue = false
    } else if (curLayerCount == layer.length) {
      checkedValue = true
      indeterminateValue = false
    } else {
      checkedValue = false
      indeterminateValue = true
    }

    const makeLayerButton = (layer, isChild = false) => {
      if (!checkLayerFilter(layer, layer.children)) return

      if (layer && layer.children) {
        //IS A GROUP
        return getTocGroup(layer, isChild)
      } else {
        //IS A LAYER
        return getTocLayer(layer)
      }
    }

    const canEdit = layer.canEdit
    const canDelete = layer.canDelete

    const getTocGroup = (group, isChild) => {
      if (!group) return null

      if (!group.children) return null

      var groupShare, groupClass
      groupShare = ''
      groupClass = scss.filterShare

      if (group.type === 'user') {
        groupShare = userLayerGroup
        groupClass = layerScss.layerBoxTypeImage
      } else if (group.type === 'shared') {
        groupShare = sharedLayerGroup
      } else if (group.type === 'org') {
        groupShare = organizationLayerGroup
      } else {
        groupShare = logoDark
        groupClass = layerScss.layerBoxTypeOrgImage
      }

      return (
        <div
          key={'group_' + group.id}
          className={
            group.children.length !== 0 && isChild == false
              ? layerScss.groupBox
              : ''
          }
        >
          <div
            className={[
              layerScss.layerGroupBoxAdmin,
              scss.layerBoxRowHeading,
              layerScss.layerRowHeadings,
              layerScss.groupRow,
            ].join(' ')}
          >
            {/* ALT CUSTOM CHECKBOX BELOW */}
            <label
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
            ></label>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span
                className={scss.adminBoxText}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={groupShare} className={groupClass}></img>
                <div className={layerScss.layerNameBox}>
                  <span className={layerScss.groupLabel}>
                    Group: {group.label}
                  </span>
                  <p className={layerScss.layerID}>
                    ID: <span>{group.id}</span>
                  </p>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {/* {defaultLayerFetching || resetting ? (
                  <FontAwesomeIcon icon='spinner' size='sm' spin />
                ) : (
                  <Switch
                    checked={isDefaultLayer(group)}
                    onChange={() => handleDefaultUpdate(group)}
                    name='groupDefault'
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                )} */}
              </span>
            </div>
            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {group.isFetching ? (
                  <FontAwesomeIcon icon='spinner' size='sm' spin />
                ) : (
                  <Switch
                    checked={isActiveGroup(group)}
                    onChange={() => handleLayerStatus(group)}
                    name='groupStatus'
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                  />
                )}
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            {canEdit || canDelete ? (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
                onClick={() =>
                  toggleLayerDropdown('group_' + user.id + '_' + layer.id)
                }
              >
                {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
                <div
                  className={[
                    dropdownScss.dropdown,
                    scss.adminUserOptions,
                  ].join(' ')}
                >
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ fontSize: '16px' }}
                  />
                  {showLayerDropdown == 'group_' + user.id + '_' + layer.id && (
                    <>
                      <div
                        className={dropdownScss.dropdownCover}
                        onClick={() =>
                          toggleLayerDropdown(
                            'group_' + user.id + '_' + layer.id
                          )
                        }
                      />
                      <div
                        className={[
                          dropdownScss.dropdownContent,
                          dropdownScss.assignDropdownContent,
                        ].join(' ')}
                        style={{ position: 'absolute', right: '0' }}
                      >
                        {canDelete && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div>
                              <span>Delete</span>
                            </div>
                          </div>
                        )}
                        {/* {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={
                                canEdit
                                  ? () => toggleLayerShare(group.id)
                                  : null
                              }
                            >
                              <span>Share</span>
                            </div>
                          </div>
                        )} */}
                        {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div>
                              <span>Edit</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* -------------------------------------------------------------------------- */}
              </div>
            ) : (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
              >
                <div className={[scss.adminUserOptions].join(' ')}>
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ color: '#a0a5ac', fontSize: '16px' }}
                  />
                </div>
              </div>
            )}

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
              onClick={() => handleCollapse(group.id)}
            >
              {group.children.length != 0 && (
                <span className={scss.adminBoxText}>
                  <FontAwesomeIcon
                    icon={
                      collapsed.includes(group.id)
                        ? 'chevron-down'
                        : 'chevron-up'
                    }
                    style={{ fontSize: '16px' }}
                  />
                </span>
              )}
            </div>
          </div>

          <div className={layerScss.emptyGrid}></div>

          <Collapse
            className={layerScss.muiPaperCollapse}
            in={!collapsed.includes(group.id)}
          >
            <Paper
              className={[scss.muiPaperShadow, layerScss.muiPaperShadow].join(
                ' '
              )}
              elevation={4}
            >
              {
                (listArea = group.children.map(layer =>
                  makeLayerButton(layer, true)
                ))
              }
            </Paper>
          </Collapse>

          {layerDeleteVisible == layer.id && (
            <LayerDelete
              onClose={() => openLayerDelete(false)}
              layerLabel={layer.label}
              layerId={layer.id}
              visible={null}
              layerList={layerList}
              setLayerList={setLayerList}
            />
          )}
          {shareLayerVisible == layer.id && (
            <ShareModal
              onClose={() => openShareLayer(false)}
              itemId={layer.id}
              dataType={'group'}
              dataConfig={layerList}
            />
          )}
        </div>
      )
    }

    const getTocLayer = layer => {
      if (!layer || !layer.label) return null
      let layerShare, layerClass
      layerShare = ''
      layerClass = scss.filterShare

      if (layer.type) {
        if (layer.type === 'user') {
          layerShare = userLayerGroup
          layerClass = layerScss.layerBoxTypeImage
        } else if (layer.type === 'shared') {
          layerShare = sharedLayerGroup
        } else if (layer.type === 'org') {
          layerShare = organizationLayerGroup
        } else if (layer.type === 'mam') {
          layerShare = logoDark
          layerClass = layerScss.layerBoxTypeOrgImage
        }
      } else {
        if (layer.owner === user.profile.displayName) {
          layerShare = userLayerGroup
          layerClass = layerScss.layerBoxTypeImage
        }

        if (layer.owner !== user.profile.displayName) {
          layerShare = sharedLayerGroup
        }
      }

      return (
        <div key={'tocLayer_' + layer.id}>
          <div
            className={[
              layerScss.layerBoxAdmin,
              scss.layerBoxRowHeading,
              layerScss.layerRowHeadings,
            ].join(' ')}
          >
            {/* ALT CUSTOM CHECKBOX BELOW */}
            <label className={checkboxScss.checkboxContainer}>
              <input
                className={checkboxScss.checkbox}
                type='checkbox'
                checked={getCheckbox(layer)}
                onChange={() => clickCheckbox(layer)}
              />
              <span className={checkboxScss.checkboxMark}></span>
            </label>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span
                className={scss.adminBoxText}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={layerShare} className={layerClass}></img>
                <div className={layerScss.layerNameBox}>
                  {layer.label}
                  <p className={layerScss.layerID}>
                    ID: <span>{layer.id}</span>
                  </p>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <span className={scss.adminBoxText}>
                <div className={layerScss.layerNameBox}>
                  <p className={layerScss.layerDescription}>
                    {layer.description !== '' ? layer.description : '–'}
                  </p>
                  <span className={layerScss.layerAuthor}>
                    Owned by: {layer.owner} &nbsp;|&nbsp; Last Edited by:{' '}
                    {layer.owner}
                  </span>
                </div>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div className={scss.adminBoxRowItems}>
              <span className={scss.adminBoxText}>
                <p className={layerScss.layerDate}>
                  {getDate(layer.dateUpdated)}
                </p>
                <p className={layerScss.layerDate}>
                  {getDate(layer.dateCreated)}
                </p>
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {user.isAdmin ? (
                  defaultLayerFetching || resetting ? (
                    <FontAwesomeIcon icon='spinner' size='sm' spin />
                  ) : (
                    <Switch
                      checked={isDefaultLayer(layer)}
                      onChange={() => handleDefaultUpdate(layer)}
                      name='layerDefault'
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  )
                ) : isDefaultLayer(layer) ? (
                  <FontAwesomeIcon icon='check' size='sm' />
                ) : null}
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {layer.isFetching ? (
                  <FontAwesomeIcon icon='spinner' size='sm' spin />
                ) : (
                  <Switch
                    checked={isActiveLayer(layer)}
                    onChange={() => handleLayerStatus(layer)}
                    name='layerStatus'
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                  />
                )}
              </span>
            </div>

            <div className={layerScss.emptyGrid}></div>

            {canEdit || canDelete ? (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
                onClick={() => toggleLayerDropdown(user.id + '_' + layer.id)}
              >
                {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
                <div
                  className={[
                    dropdownScss.dropdown,
                    scss.adminUserOptions,
                  ].join(' ')}
                >
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ fontSize: '16px' }}
                  />
                  {showLayerDropdown == user.id + '_' + layer.id && (
                    <>
                      <div
                        className={dropdownScss.dropdownCover}
                        onClick={() =>
                          toggleLayerDropdown(user.id + '_' + layer.id)
                        }
                      />
                      <div
                        className={[
                          dropdownScss.dropdownContent,
                          dropdownScss.assignDropdownContent,
                        ].join(' ')}
                        style={{ position: 'absolute', right: '0' }}
                      >
                        {canDelete && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div onClick={() => toggleLayerDelete(layer.id)}>
                              <span>Delete</span>
                            </div>
                          </div>
                        )}
                        {canEdit && (
                          <div
                            className={[dropdownScss.assignDropdownItem].join(
                              ' '
                            )}
                          >
                            <div
                              onClick={
                                canEdit
                                  ? () => toggleLayerShare(layer.id)
                                  : null
                              }
                            >
                              <span>Share</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* -------------------------------------------------------------------------- */}
              </div>
            ) : (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center', overflow: 'visible' }}
              >
                <div className={[scss.adminUserOptions].join(' ')}>
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-h']}
                    style={{ color: '#a0a5ac', fontSize: '16px' }}
                  />
                </div>
              </div>
            )}

            <div className={layerScss.emptyGrid}></div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span
                className={[scss.adminBoxText, layerScss.layerBoxType].join(
                  ' '
                )}
              >
                &nbsp;
              </span>
            </div>
          </div>

          {layerDeleteVisible == layer.id && (
            <LayerDelete
              onClose={() => openLayerDelete(false)}
              layerLabel={layer.label}
              layerId={layer.id}
              visible={null}
              layerList={layerList}
              setLayerList={setLayerList}
            />
          )}
          {shareLayerVisible == layer.id && (
            <ShareModal
              onClose={() => openShareLayer(false)}
              itemId={layer.id}
              dataType='layer'
              dataConfig={layerList}
            />
          )}
        </div>
      )
    }

    return makeLayerButton(layer)
  }

  var resultsFound = false

  if (!layerList.length) {
    listArea = (
      <div className={layerScss.noResults}>
        <FontAwesomeIcon icon={['fal', 'search']} />
        <FontAwesomeIcon icon={'question'} />
        <span className={layerScss.noResultsHeading}>No Results Found</span>
        <span className={layerScss.noResultsParagraph}>
          Try adjusting your search to find what you’re looking for
        </span>
      </div>
    )
  } else {
    listArea = layerList.map(layer => displayLayer(layer))
    if (!resultsFound) {
      listArea = (
        <div className={layerScss.noResults}>
          <FontAwesomeIcon icon={['fal', 'search']} />
          <FontAwesomeIcon icon={'question'} />
          <span className={layerScss.noResultsHeading}>No Results Found</span>
          <span className={layerScss.noResultsParagraph}>
            Try adjusting your search to find what you’re looking for
          </span>
        </div>
      )
    }
  }

  useEffect(() => {
    const removeFetching = arr => {
      arr.forEach(layer => {
        if (layer.isFetching) {
          layer.isFetching = false
        }

        if (layer.children && layer.children.length) {
          removeFetching(layer.children)
        }
      })
    }

    const checkIds = (arr, targetId, val) => {
      arr.forEach(layer => {
        if (layer.id === targetId) {
          layer.isFetching = val
        } else if (layer.children && layer.children.length) {
          checkIds(layer.children, targetId, val)
        }
      })
    }

    if (addList.length) {
      addList.forEach(id => checkIds(layerList, id, true))
    }

    if (removeList.length) {
      removeList.forEach(id => checkIds(layerList, id, true))
    }

    if (addList.length === 0 && removeList.length === 0) {
      removeFetching(layerList, null, null)
    }
  }, [addList, removeList, layerList])

  return (
    <div className={[scss.layerBox, layerScss.layerContainer].join(' ')}>
      {listArea}
    </div>
  )
}
