import React, { useState, Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setToolConfig, setToc, setMapMode } from '../../../actions/index'
import scss from './Print.scss'
import dropdownScss from '../../CSSModules/Dropdown.scss'
import { Rnd } from 'react-rnd'
import { Font } from '@react-pdf/renderer'
import Viewer from './Viewer'
import ImageElement from './LayoutElements/ImageElement/ImageElement'
import TextElement from './LayoutElements/TextElement/TextElement'
import DateElement from './LayoutElements/DateElement/DateElement'
import ElementButtons from './LayoutElements/ElementButtons/ElementButtons'
import LegendElement from './LayoutElements/LegendElement/LegendElement'
import NorthArrowElement from './LayoutElements/NorthArrowElement/NorthArrowElement'
import Scale from './LayoutElements/ScaleElement/ScaleElement'

//TODO: move fonts to assets - currently Print cant import from assets on build.  Webpack setting??
import GilroyRegular from '../../../assets/fonts/Gilroy-Light.ttf'
import GilroyBold from '../../../assets/fonts/Gilroy-Medium.ttf'
import GilroyBoldItalic from '../../../assets/fonts/Gilroy-MediumItalic.ttf'

import MontserratRegular from '../../../assets/fonts/Montserrat-Light.ttf'
import MontserratBold from '../../../assets/fonts/Montserrat-Medium.ttf'
import MontserratBoldItalic from '../../../assets/fonts/Montserrat-MediumItalic.ttf'

import OpenSansRegular from '../../../assets/fonts/OpenSans-Light.ttf'
import OpenSansBold from '../../../assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansBoldItalic from '../../../assets/fonts/OpenSans-SemiBoldItalic.ttf'

import MapGL from 'react-map-gl'
import { ElementStyle } from './ElementStyle/ElementStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getVisibleLayersFromDataConfig } from '../../../utilities/dataConfig'
import { size } from 'lodash'

import PrintLayouts from './PrintLayouts'
import PrintSettings from './Modals/PrintSettings/PrintSettings'

import textIcon from '../../../assets/icons/print/text.svg'
import plusIcon from '../../../assets/icons/accordian.svg'
import dateIcon from '../../../assets/icons/print/dateStamp.svg'
import northIcon from '../../../assets/icons/orientNorth.svg'
import legendIcon from '../../../assets/icons/print/legend.svg'
import imageIcon from '../../../assets/icons/print/image.svg'
import { ReactComponent as LockIcon } from '../../../assets/icons/print/Lock.svg'
import { ReactComponent as UnlockIcon } from '../../../assets/icons/print/Unlock.svg'
import { ReactComponent as ExportIcon } from '../../../assets/icons/print/Export.svg'
import { ReactComponent as PrintIcon } from '../../../assets/icons/print/Print.svg'
import { clone } from '../../../utilities/geospatial'

import {
  PAGE_SIZE_LOOKUP,
  MAP_ELEMENT,
  TEXT_ELEMENT,
  NORTH_ARROW,
  SCALE_BAR,
  LEGEND_ELEMENT,
  DEFAULT_SELECT_OPTION,
} from './PrintConstants'

class Print extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInsertDropdown: false,
      showFileDropdown: false,
      printSettingsVisible: false,
      openSaveModal: false,
      openDeleteModal: false,
      mode: 'LAYOUT',
      orientPortrait: false,
      elements: [],
      dpi: 240,
      editMode: false,
      activeElement: null,
      activeElementWindow: null,
      mapElement: false,
      imageElements: false,
      textAreaElements: false,
      textAreaActive: false,
      dateStampElements: false,
      legendElement: false,
      northArrowElements: false,
      scaleBarElement: true,
      layoutName: '',
      layoutNameError: '',
      selectedLayout: DEFAULT_SELECT_OPTION,
      savedLayouts: [],
      layoutOption: [],
      editToolPosition: {
        x: 0,
        y: 0,
      },
      lockMap: false,
      excludedLayers: [],
      pageSize: 'LETTER',
    }
    this.inputRef = React.createRef()
  }

  componentDidMount = () => {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
    this.setInitialMap()
  }

  setInitialMap = async () => {
    let { dataConfig, mapStyle } = this.props
    const style = mapStyle.toJS()
    const tocLayers = dataConfig.tocLayers
    let visibleLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    let initPrintElements = []
    if (visibleLayers.length) {
      initPrintElements = [
        MAP_ELEMENT,
        TEXT_ELEMENT,
        NORTH_ARROW,
        SCALE_BAR,
        LEGEND_ELEMENT,
      ]
    } else {
      initPrintElements = [MAP_ELEMENT, TEXT_ELEMENT, NORTH_ARROW, SCALE_BAR]
    }
    initPrintElements = initPrintElements.map((ele, index) => {
      ele.id = index + 1
      return ele
    })
    this.setState({
      mode: 'LAYOUT',
      orientPortrait: false,
      elements: initPrintElements,
    })
  }
  /*
  @param{obj} - obj containing key pairs for each element in the layout
  */
  setPrintState = layoutElements => this.setState({ ...layoutElements })

  showPrintSettings = visible => {
    this.setState({
      printSettingsVisible: visible,
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions)
  }

  /**
   * @description Сalculating height and width for a PDFViewer component
   */
  updateDimensions = () => {
    let update_width = window.innerWidth - 363
    let update_height = window.innerHeight - 45
    this.setState({ width: update_width, height: update_height })
  }

  /**
   * @description Toggle the orientation of the sheet
   *
   * Shifts the element if the previous position of the
   * element does not correspond to the current orientation
   * and the actual values of the width, height and proportions
   * of the layout
   */
  toggleOrient = () => {
    // TODO: this function should reset the map width to be smaller than the page width when
    // switching page orientation
    // this currently does not work.  Fix this!
    const { elements, pageSize, orientPortrait } = this.state
    let newElements = [...elements]

    this.setState({ orientPortrait: !this.state.orientPortrait }, () => {
      const height = orientPortrait
        ? PAGE_SIZE_LOOKUP[pageSize].height
        : PAGE_SIZE_LOOKUP[pageSize].width
      const width = orientPortrait
        ? PAGE_SIZE_LOOKUP[pageSize].width
        : PAGE_SIZE_LOOKUP[pageSize].height
      newElements = newElements.map(elem => {
        if (elem.width > width) {
          elem.width = width - 10
        }
        if (elem.height > height) {
          elem.height = height - 10
        }
        return elem
      })

      this.setState({ elements: newElements })
    })
  }

  toggleMapLock = () => this.setState({ lockMap: !this.state.lockMap })

  /**
   * @description Save map object as image.png on PC/device
   */
  handleSaveToImg = () => {
    let mapboxobj = this.mapRef.getMap()
    let mapCanvas = mapboxobj.getCanvas()
    let mapImage = mapCanvas.toDataURL('image/png') // mapImage - base64 string
    // Create and execute element "a" for download image
    var link = document.createElement('a')
    link.download = 'image.png'
    link.href = mapImage
    link.click()
  }

  /**
   * @description Delete element from layout
   * @param {number} id - id of the element to be deleted in the array of elements
   */
  handleDeleteElement = id => {
    const { elements } = this.state
    let newElements = elements.filter(elem => elem.id !== id)
    this.setState({
      elements: newElements,
      activeElement: null,
    })
  }

  handleUpdateElements = elements => {
    this.setState({
      elements,
    })
  }

  /**
   * @description Get needed Layers for add to legend
   * @return neededLayers - The list of layers to represent in the legend
   */
  getVisibleLayers = () => {
    let { dataConfig, mapStyle } = this.props
    const style = mapStyle.toJS()
    const tocLayers = dataConfig.tocLayers
    let visibleLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    let legendLayers = []
    visibleLayers.forEach(layerObj => {
      layerObj.layersArray.forEach(layerItem => {
        const styleLayer = style.layers.filter(
          layer => layer.id === layerItem.layer.id
        )
        const legendObject = {
          layer: {},
          outline: null,
        }
        if (styleLayer.length) {
          legendObject.layer.label =
            layerItem.layer.metadata.label || layerItem.layer.id
          legendObject.layer.id = styleLayer[0].id
          legendObject.layer.type = styleLayer[0].type
          legendObject.layer.paint = styleLayer[0].paint
          if (layerItem.outline) {
            const outlineLayer = style.layers.filter(
              layer => layer.id === layerItem.outline.id
            )
            if (outlineLayer.length) {
              legendObject.outline = {}
              legendObject.outline.paint = outlineLayer[0].paint
            }
          }
          legendLayers.push(legendObject)
        }
      })
    })
    return legendLayers
  }

  /**
   * @description Add legend element and set default values
   * @return nothing if legend already added
   */
  handleAddLegend = () => {
    const { elements } = this.state
    // check if legend element already added
    if (elements.find(obj => obj.type == 'legend')) return
    // get needed Layers for add to legend
    const neededLayers = this.getVisibleLayers()
    if (size(neededLayers) > 0) {
      // set defaul value for Legend element
      const newLegend = clone(LEGEND_ELEMENT)
      ;(newLegend.id = elements.length
        ? elements[elements.length - 1].id + 1
        : 1),
        (newLegend.layers = neededLayers)
      this.setState({
        elements: [...elements, newLegend],
      })
    }
  }

  /**
   * @description Delete layer from the legend element
   * @param {number} id - id of the layer to be deleted in the array of layers in legend element
   */
  handleDeleteItemLegend = id => {
    const { elements } = this.state
    const newElements = [...elements]
    let newLegend = newElements.find(obj => obj.type == 'legend')
    const neededLegendItem = newLegend.layers.findIndex(
      obj => obj.layer.id == id
    )
    newLegend.layers.splice(neededLegendItem, 1)
    this.setState({
      elements: newElements,
    })
  }

  /**
   * @description Refresh list of layers in the legend
   */
  handleRefreshItemLegend = () => this.setState({ excludedLayers: [] })

  /**
   * @description Add image element and set default values for image element
   * @param e - onChange event for input with type="file"
   */
  handleImageChange = e => {
    const { elements } = this.state
    e.preventDefault()

    // Upload image
    let reader = new FileReader()
    let file = e.target.files[0]
    e.target.value = '' // clearing target.value information for next upload
    // set defaul value for image element after imag upload end
    reader.onloadend = () => {
      const img = {
        file,
        id: elements.length ? elements[elements.length - 1].id + 1 : 1,
        type: 'img',
        imagePreviewUrl: reader.result,
        x: 0,
        y: 0,
        width: 150,
        height: 150,
        borderWidth: 1,
        borderColor: '#000000',
      }
      this.setState({
        elements: [...elements, img],
      })
    }
    // The result is a string with a data: URL representing the file's data.
    reader.readAsDataURL(file)
  }

  /**
   * @description  Add text and set default values for text element
   */
  handleAddText = positionY => {
    const { elements } = this.state
    const newInput = clone(TEXT_ELEMENT)
    newInput.id = elements.length ? elements[elements.length - 1].id + 1 : 1
    newInput.y = positionY
    this.setState({
      elements: [...elements, newInput],
    })
  }

  getDate = () => {
    let d = new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
  }

  /**
   * @description  Add date stamp and set default values for date stamp
   */
  handleAddDateStamp = () => {
    const { elements } = this.state
    var newInput = {
      id: elements.length ? elements[elements.length - 1].id + 1 : 1,
      type: 'date',
      text: this.getDate(),
      x: 200,
      y: 420,
      width: 90.70933333333333, //Set the width 80px
      height: 28.346666666666668, //Set the height 25px
      borderWidth: 1,
      borderColor: '#000000',
      borderTransparent: false,
      textColor: '#000000',
      fontSize: 12,
      fontFamily: 'Montserrat',
      bgColor: '#ffffff',
      bgTransparent: false,
      opacity: 100,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      textAlign: 'left',
      padding: 5,
    }
    this.setState({
      elements: [...elements, newInput],
    })
  }

  /**
   * @description  Change text element
   * @param event onChange event for textarea input
   */
  handleChangeText = event => {
    const { elements } = this.state
    let newInputs = [...elements]
    let neededInput = newInputs.find(obj => obj.id == event.target.id)

    neededInput.text = event.target.value
    this.setState({ elements: newInputs })
  }

  handleChangeScale = (distance, ratio, units, maxWidth) => {
    const { elements } = this.state
    let newInputs = [...elements]

    let neededInput = newInputs.find(obj => obj.type === 'scaleBar')
    neededInput.distance = distance + ' ' + units
    neededInput.ratio = ratio
    neededInput.maxWidth = maxWidth
    this.setState({ elements: newInputs })
  }

  /**
   * @description  Change date element
   * @param event onChange event for textarea input
   */
  handleChangeDate = event => {
    const { elements } = this.state
    let newInputs = [...elements]
    let neededInput = newInputs.find(obj => obj.id == event.target.id)

    neededInput.text = event.target.value
    this.setState({ elements: newInputs })
  }

  /**
   * @description Check if textarea text is empty - delete this text element
   * @param event onBlur event for textarea input
   */
  handleTextBlur = event => {
    if (!event.target.value) {
      const { elements } = this.state
      let newInputs = [...elements]
      let neededInput = newInputs.findIndex(obj => obj.id == event.target.id)

      newInputs.splice(neededInput, 1)
      this.setState({ elements: newInputs })
    }
  }

  /**
   * @description Copy text element
   * @param id - id of the text element to copy
   */
  copyText = id => {
    const { elements } = this.state
    let newElements = [...elements]
    let neededText = newElements.findIndex(obj => obj.id == id)
    const newText = {
      ...newElements[neededText],
      id: newElements.length ? newElements[newElements.length - 1].id + 1 : 1,
      x: newElements[neededText].x + 5,
      y: newElements[neededText].y + 5,
    }

    newElements.push(newText)
    this.setState({ elements: newElements })
  }

  /**
   * @description  Add North arrow element and set default values
   * @return nothing if North arrow element already added
   */
  handleAddNorthArrow = () => {
    const { elements } = this.state
    // check if North arrow element already added
    if (elements.find(obj => obj.type === 'northArrow')) return
    const newNorthArrow = clone(NORTH_ARROW)
    newNorthArrow.id = elements.length
      ? elements[elements.length - 1].id + 1
      : 1
    this.setState({
      elements: [...elements, newNorthArrow],
    })
  }

  handleAddScaleBar = () => {
    const { elements } = this.state
    // check if Scale Bar element already added
    if (elements.find(obj => obj.type === 'scaleBar')) return
    const newScaleBar = clone(SCALE_BAR)
    newScaleBar.id = elements.length ? elements[elements.length - 1].id + 1 : 1
    this.setState({
      elements: [...elements, newScaleBar],
    })
  }

  /**
   * @description Update element on resize
   * @param e: MouseEvent | TouchEvent
   * @param direction: ResizeDirection
   * @param ref: HTMLDivElement
   * @param delta: ResizableDelta
   * @param position: Position
   */
  handleResizeStop = (e, direction, ref, delta, position) => {
    const { elements } = this.state
    // Get new width and height of element
    const width = parseInt(ref.style.width.replace('px', ''))
    const height = parseInt(ref.style.height.replace('px', ''))
    // Get the proportionality coefficient to save
    const heightPropotional = this.getLayoutSize().heightPropotionalPage

    const newElements = [...elements]
    // find updated element
    let neededObj = newElements.findIndex(obj => obj.id == ref.id)
    // update element in accordance with the proportions
    newElements[neededObj] = {
      ...newElements[neededObj],
      width: width,
      height: height,
      x: position.x,
      y: position.y,
    }
    this.setState({ elements: newElements })
  }

  /**
   * @description Update element on move
   * @param e: DraggableEvent
   * @param d: DraggableData
   */
  handleDragStop = (e, d) => {
    const { elements } = this.state
    const newElements = [...elements]
    const heightPropotional = this.getLayoutSize().heightPropotionalPage
    // find updated element
    let neededObj = newElements.findIndex(obj => obj.id == d.node.id)
    // update element in accordance with new position
    newElements[neededObj] = {
      ...newElements[neededObj],
      x: d.lastX, // / heightPropotional,
      y: d.lastY, // / heightPropotional,
    }
    this.setState({ elements: newElements })
  }

  /**
   * @description Set Edit tool element position
   * @param pos - position object { x, y }
   */
  setEditToolPosition = pos => {
    this.setState({ editToolPosition: pos })
  }

  /**
   * @description Toggle Edit Tool element
   * @param id - id of edited element, if id is missing then it closes
   */
  toggleEditWindow = id => {
    this.setState(state => ({
      editMode: true,
      activeElement: id ? id : null,
      editToolPosition: !state.editMode && {
        x: 0,
        y: 0,
      },
    }))
  }

  handleElementWindow = object => {
    this.toggleEditWindow(object.id)

    this.setState(state => ({
      activeElementWindow: object.id,
    }))
  }

  /**
   * @description Get index of Active for edit object
   * @returns index of of Active for edit object
   */
  getActiveObject = () => {
    const { activeElement, elements } = this.state
    const newElements = [...elements]

    let neededObj = newElements.findIndex(obj => obj.id == activeElement)
    return neededObj
  }

  /**
   * @description Update Active object data style
   * @param object - object containing properties for editing element styles
   */
  changeStyle = object => {
    const { elements } = this.state
    const newElements = [...elements]
    newElements[this.getActiveObject()] = object
    this.setState({ elements: newElements })
  }

  resetDropdowns = () => {
    this.setState({
      showFileDropdown: false,
      showInsertDropdown: false,
    })
  }

  toggleInsertDropdown = e => {
    e.stopPropagation()
    this.resetDropdowns()

    const { showInsertDropdown } = this.state

    if (showInsertDropdown) {
      this.setState({ showInsertDropdown: false })
    } else this.setState({ showInsertDropdown: true })
  }

  toggleFileDropdown = e => {
    e.stopPropagation()
    this.resetDropdowns()

    const { showFileDropdown } = this.state

    if (showFileDropdown) {
      this.setState({ showFileDropdown: false })
    } else this.setState({ showFileDropdown: true })
  }

  /**
   * @description Toggle to PDF or Edit mode
   */
  toggleMode = () => {
    let { mode, activeElement } = this.state
    // close Edit tool element if it is open
    if (activeElement) this.toggleEditWindow()
    // change mode
    mode = mode === 'LAYOUT' ? 'PDF' : 'LAYOUT'
    if (mode === 'PDF') {
      Font.register({
        family: 'Gilroy-Light',
        fonts: [
          {
            src: GilroyRegular,
          },
          {
            src: GilroyBold,
            fontWeight: 'bold',
          },
          {
            src: GilroyBoldItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
          },
        ],
      })

      Font.register({
        family: 'Montserrat',
        fonts: [
          {
            src: MontserratRegular,
          },
          {
            src: MontserratBold,
            fontWeight: 'bold',
          },
          {
            src: MontserratBoldItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
          },
        ],
      })

      Font.register({
        family: 'open sans',
        fonts: [
          {
            src: OpenSansRegular,
          },
          {
            src: OpenSansBold,
            fontWeight: 'bold',
          },
          {
            src: OpenSansBoldItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
          },
        ],
      })

      this.updateDimensions()
    }
    this.setState({ mode })
  }

  /**
   * @description  Formation of pdf document
   */
  getPDF = () => {
    const { elements, orientPortrait, width, height } = this.state
    // fix crash app after download PDF
    if (!this.mapRef.getMap()) {
      return this.toggleMode()
    }
    // get base64 string of map image
    const mapboxobj = this.mapRef.getMap()
    const mapCanvas = mapboxobj.getCanvas()
    const mapImage = mapCanvas.toDataURL('image/png')
    const ui = (
      <Viewer
        mapStyle={this.props.mapStyle}
        dataConfig={this.props.dataConfig}
        viewport={this.props.viewport}
        orientPortrait={orientPortrait}
        pageWidth={width}
        pageHeight={height}
        pageSize={this.state.pageSize}
        elements={elements}
        mapImage={mapImage}
        mapboxobj={mapboxobj}
        excludedLayers={this.state.excludedLayers}
      />
    )
    return ui
  }

  exportMap = () => {
    // get base64 string of map image
    const mapboxobj = this.mapRef.getMap()
    const mapCanvas = mapboxobj.getCanvas()
    const mapImage = mapCanvas.toDataURL('image/png')
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.download = 'MyAssetMap.png'
    a.href = mapImage
    a.target = '_blank'
    a.click()
    document.body.removeChild(a)
  }

  /**
   * @description  Calculation of the actual values of the width, height and proportions of the layout
   * @returns { layoutWidth, layoutHeight, heightPropotionalPage }
   */
  getLayoutSize = () => {
    let { orientPortrait, pageSize } = this.state
    const height = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].height
      : PAGE_SIZE_LOOKUP[pageSize].width
    const width = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].width
      : PAGE_SIZE_LOOKUP[pageSize].height

    return {
      layoutWidth: width,
      layoutHeight: height,
    }
  }

  pageClicked = () => this.resetDropdowns()

  onViewportChange = viewport => {
    // Note: viewprotSet is being used to prevent infinte loops
    // TODO: Refactor this component!!!!!!!
    if (this.state.viewportSet) {
      this.props.onViewportChange(viewport)
      this.setState({ viewportSet: false })
    } else {
      this.setState({ viewportSet: true })
    }
  }

  render() {
    let {
      mode,
      orientPortrait,
      elements,
      activeElement,
      activeElementWindow,
      editToolPosition,
      textAreaElements,
      dateStampElements,
      legendElement,
      northArrowElements,
      scaleBarElement,
      showInsertDropdown,
      showFileDropdown,
      printSettingsVisible,
      dpi,
      pageSize,
    } = this.state

    let { viewport } = this.props
    if (!viewport) return null
    const layoutSize = this.getLayoutSize()
    const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'solid 1px #ddd',
      background: '#f0f0f0',
      border: 'solid 1px',
    }
    const layoutStyle = {
      width: `${layoutSize.layoutWidth}px`,
      height: `${layoutSize.layoutHeight}px`,
      backgroundColor: '#fff',
      alignSelf: 'center',
      position: 'relative',
      margin: 'auto',
      marginTop: '54px',
    }
    const hiddenInputStyle = {
      position: 'absolute',
      top: '-9999px',
    }
    const triggerInput = e => {
      e.persist()
      this.inputRef.current.click()
    }

    const backToMap = () => {
      this.props.setToc(false)
      this.props.setMapMode('MAP')
    }

    let dragPan = this.state.lockMap ? false : true
    let doubleClickZoom = this.state.lockMap ? false : true
    let scrollZoom = this.state.lockMap ? false : true

    let ui

    const rndTopLeftCornerHandle = scss.rndTopLeftCornerHandle
    const rndTopRightCornerHandle = scss.rndTopRightCornerHandle
    const rndBottomRightCornerHandle = scss.rndBottomRightCornerHandle
    const rndBottomLeftCornerHandle = scss.rndBottomLeftCornerHandle

    const rndTopHandle = scss.rndTopHandle
    const rndRightHandle = scss.rndRightHandle
    const rndBottomHandle = scss.rndBottomHandle
    const rndLeftHandle = scss.rndLeftHandle

    if (mode !== 'PDF') {
      ui = (
        <div className={scss.printMapLayout}>
          <div style={layoutStyle} className={'Layout'}>
            {!!elements.length &&
              elements.map(object => {
                const { type } = object
                if (type === 'map') {
                  return (
                    <Rnd
                      resizeHandleWrapperClass={scss.rndWrapperHandles}
                      resizeHandleClasses={{
                        topLeft: rndTopLeftCornerHandle,
                        topRight: rndTopRightCornerHandle,
                        bottomRight: rndBottomRightCornerHandle,
                        bottomLeft: rndBottomLeftCornerHandle,

                        top: rndTopHandle,
                        right: rndRightHandle,
                        bottom: rndBottomHandle,
                        left: rndLeftHandle,
                      }}
                      id={object.id}
                      className={[
                        scss.rndWrapperHover,
                        scss[
                          activeElementWindow == object.id
                            ? 'activeEditWindowMap'
                            : ''
                        ],
                      ].join(' ')}
                      onDragStop={(e, d) => this.handleDragStop(e, d)}
                      onResize={(e, direction, ref, delta, position) =>
                        this.handleResizeStop(
                          e,
                          direction,
                          ref,
                          delta,
                          position
                        )
                      }
                      onClick={() => this.handleElementWindow(object)}
                      size={{
                        width: object.width,
                        height: object.height,
                      }}
                      maxWidth={`${layoutSize.layoutWidth}px`}
                      maxHeight={`${layoutSize.layoutHeight}px`}
                      dragGrid={[5, 5]}
                      resizeGrid={[5, 5]}
                      style={
                        (style,
                        {
                          borderColor: !object.borderTransparent
                            ? object.borderColor
                            : 'transparent',
                          borderWidth: `${object.borderWidth}px`,
                          borderStyle: 'solid',
                        })
                      }
                      bounds='parent'
                      default={{
                        x: object.x,
                        y: object.y,
                        width: object.width,
                        height: object.height,
                      }}
                      position={{
                        x: object.x,
                        y: object.y,
                      }}
                      cancel='.map'
                    >
                      <div className={'map'}>
                        <MapGL
                          {...viewport}
                          width={object.width - object.borderWidth * 2}
                          height={object.height - object.borderWidth * 2}
                          mapStyle={this.props.mapStyle}
                          onViewportChange={this.onViewportChange}
                          onLoad={this.onMapLoad}
                          onNativeClick={this.props.onMapClickNative}
                          dragPan={dragPan}
                          doubleClickZoom={doubleClickZoom}
                          scrollZoom={scrollZoom}
                          transformRequest={this.props.transformRequest}
                          onTransitionEnd={this.props.onTransitionEnd}
                          interactiveLayerIds={this.props.interactiveLayerIds}
                          attributionControl={false}
                          getCursor={this.props.getCursor}
                          ref={map => map && (this.mapRef = map)}
                          preserveDrawingBuffer={true}
                        />
                      </div>
                      <div className={scss.mapButtons}>
                        <button className={scss.mapButton}>
                          <FontAwesomeIcon
                            icon={['fal', 'arrows-alt']}
                            size='1x'
                          />
                        </button>
                        <button
                          onClick={this.handleSaveToImg}
                          className={scss.mapButton}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'download']}
                            size='1x'
                          />
                        </button>
                      </div>
                    </Rnd>
                  )
                }
                if (type !== 'map') {
                  return (
                    <Rnd
                      resizeHandleClasses={{
                        topLeft: rndTopLeftCornerHandle,
                        topRight: rndTopRightCornerHandle,
                        bottomRight: rndBottomRightCornerHandle,
                        bottomLeft: rndBottomLeftCornerHandle,

                        top: rndTopHandle,
                        right: rndRightHandle,
                        bottom: rndBottomHandle,
                        left: rndLeftHandle,
                      }}
                      onClick={() => this.handleElementWindow(object)}
                      id={object.id}
                      key={object.id}
                      className={scss.rndWrapperHover}
                      onDragStop={(e, d) => this.handleDragStop(e, d)}
                      onResizeStop={(e, direction, ref, delta, position) =>
                        this.handleResizeStop(
                          e,
                          direction,
                          ref,
                          delta,
                          position
                        )
                      }
                      lockAspectRatio={type === 'northArrow'}
                      style={
                        (style,
                        {
                          overflow: 'visible',
                        })
                      }
                      bounds='parent'
                      minHeight={type === 'date' ? 25 : 50}
                      minWidth={type === 'date' ? 80 : 50}
                      maxWidth={`${layoutSize.layoutWidth}px`}
                      maxHeight={`${layoutSize.layoutHeight}px`}
                      dragGrid={[5, 5]}
                      resizeGrid={[5, 5]}
                      default={{
                        x: object.x, // * layoutSize.heightPropotionalPage,
                        y: object.y, // * layoutSize.heightPropotionalPage,
                        width: object.width, // * layoutSize.heightPropotionalPage,
                        height: object.height, // * layoutSize.heightPropotionalPage,
                      }}
                      size={{
                        width: object.width, // * layoutSize.heightPropotionalPage,
                        height: type === 'img' ? 'auto' : object.height, // * layoutSize.heightPropotionalPage),
                      }}
                      position={{
                        x: object.x, // * layoutSize.heightPropotionalPage,
                        y: object.y, // * layoutSize.heightPropotionalPage,
                      }}
                      cancel={
                        type === 'img'
                          ? '.image'
                          : type === 'text'
                          ? '.text'
                          : type === 'legend'
                          ? '.legend'
                          : '.northArrow'
                      }
                    >
                      {(type === 'img' && (
                        <>
                          <ImageElement
                            ref={object => (this.imgRef = object)}
                            object={object}
                            imageElements={this.state.activeElementWindow}
                          />
                          <ElementButtons
                            object={object}
                            toggleEditWindow={this.toggleEditWindow}
                            handleDeleteElement={this.handleDeleteElement}
                          />
                        </>
                      )) ||
                        (type === 'text' && (
                          <>
                            <TextElement
                              object={object}
                              handleElementClick={() => {
                                this.setState({
                                  textAreaElements: !textAreaElements,
                                })
                              }}
                              handleChangeText={this.handleChangeText}
                              handleTextBlur={this.handleTextBlur}
                              textAreaElements={this.state.activeElementWindow}
                            />
                            {textAreaElements && (
                              <ElementButtons
                                object={object}
                                toggleEditWindow={this.toggleEditWindow}
                                handleDeleteElement={this.handleDeleteElement}
                                copyText={this.copyText}
                              />
                            )}
                          </>
                        )) ||
                        (type === 'date' && (
                          <>
                            <DateElement
                              object={object}
                              handleElementClick={() => {
                                this.setState({
                                  dateStampElements: !dateStampElements,
                                })
                              }}
                              handleChangeText={this.handleChangeText}
                              handleTextBlur={this.handleTextBlur}
                              dateStampElements={this.state.activeElementWindow}
                            />
                            {dateStampElements && (
                              <ElementButtons
                                object={object}
                                toggleEditWindow={this.toggleEditWindow}
                                handleDeleteElement={this.handleDeleteElement}
                              />
                            )}
                          </>
                        )) ||
                        (type === 'legend' && (
                          <>
                            <LegendElement
                              object={object}
                              handleElementClick={() => {
                                this.setState({
                                  legendElement: !legendElement,
                                })
                              }}
                              excludedLayers={this.state.excludedLayers}
                              legendElement={this.state.activeElementWindow}
                            />
                            <ElementButtons
                              object={object}
                              toggleEditWindow={this.toggleEditWindow}
                              handleDeleteElement={this.handleDeleteElement}
                              handleRefreshItemLegend={
                                this.handleRefreshItemLegend
                              }
                            />
                          </>
                        )) ||
                        (type === 'northArrow' && (
                          <>
                            <NorthArrowElement
                              object={object}
                              viewport={viewport}
                              handleElementClick={() => {
                                this.setState({
                                  northArrowElements: !northArrowElements,
                                })
                              }}
                              northArrowElements={
                                this.state.activeElementWindow
                              }
                            />

                            {northArrowElements && (
                              <ElementButtons
                                object={object}
                                toggleEditWindow={this.toggleEditWindow}
                                handleDeleteElement={this.handleDeleteElement}
                              />
                            )}
                          </>
                        )) ||
                        (type === 'scaleBar' && (
                          <>
                            <Scale
                              object={object}
                              viewport={viewport}
                              mapRef={this.mapRef}
                              handleElementClick={() => {
                                this.setState({
                                  scaleBarElement: !scaleBarElement,
                                })
                              }}
                              scaleBarElement={this.state.activeElementWindow}
                              handleChangeScale={this.handleChangeScale}
                            />

                            {scaleBarElement && (
                              <ElementButtons
                                object={object}
                                toggleEditWindow={this.toggleEditWindow}
                                handleDeleteElement={this.handleDeleteElement}
                              />
                            )}
                          </>
                        ))}
                    </Rnd>
                  )
                }
              })}
          </div>
          {activeElement && (
            <ElementStyle
              style={style}
              object={elements[this.getActiveObject()]}
              changeStyle={this.changeStyle}
              editToolPosition={editToolPosition}
              setEditToolPosition={this.setEditToolPosition}
              elements={elements}
              handleUpdateElements={this.handleUpdateElements}
            />
          )}
        </div>
      )
    } else {
      ui = this.getPDF()
    }

    return (
      <div onClick={this.pageClicked} className={scss.layoutCenter}>
        <div className={scss.printControlsWrapper}>
          {mode === 'PDF' && (
            <div className={scss.backLayoutButton}>
              <button onClick={this.toggleMode}>
                <FontAwesomeIcon
                  icon='chevron-left'
                  size='xs'
                  pull='left'
                  color='white'
                  className={scss.printArrowIcon}
                />
                <span>BACK TO LAYOUT</span>
              </button>
            </div>
          )}
          {mode !== 'PDF' && (
            <div className={scss.printControlsGroup}>
              <div className={scss.btnOptions}>
                <button className={scss.backButton} onClick={backToMap}>
                  <FontAwesomeIcon
                    icon='chevron-left'
                    size='xs'
                    pull='left'
                    color='white'
                    className={scss.printArrowIcon}
                  />
                  <span>Back</span>
                </button>
                <div className={scss.mapOptions}>
                  <button
                    className={scss.textBtn}
                    onClick={this.handleAddText.bind(this, 10)}
                  >
                    <img src={textIcon} className={scss.printTextIcon}></img>
                  </button>
                  <button className={scss.textBtn} onClick={triggerInput}>
                    <input
                      style={hiddenInputStyle}
                      ref={this.inputRef}
                      type='file'
                      accept='image/*'
                      onChange={this.handleImageChange}
                    />
                    <img src={imageIcon} className={scss.printIcon}></img>
                  </button>
                  {/* -------------------------- PRINT INSERT DROPDOWN ------------------------- */}

                  <div
                    className={[
                      scss.insertBtn,
                      scss.printDropdown,
                      dropdownScss.dropdown,
                    ].join(' ')}
                    onClick={this.toggleInsertDropdown}
                  >
                    <img src={plusIcon} className={scss.printPlusIcon}></img>
                    <FontAwesomeIcon
                      icon='chevron-down'
                      size='xs'
                      pull='right'
                      color='white'
                      className={scss.printArrowIcon}
                    />

                    {showInsertDropdown && (
                      <div
                        className={[
                          scss.printDropdownContent,
                          dropdownScss.dropdownContent,
                        ].join(' ')}
                      >
                        <div
                          className={[scss.printDropdownItem].join(' ')}
                          onClick={this.handleAddDateStamp}
                        >
                          <img src={dateIcon} className={scss.printIcon}></img>
                          Date Stamp
                        </div>
                        <div
                          className={[scss.printDropdownItem].join(' ')}
                          onClick={this.handleAddNorthArrow}
                        >
                          <img
                            src={northIcon}
                            className={scss.printNorthIcon}
                          ></img>
                          North Arrow
                        </div>
                        <div
                          className={[scss.printDropdownItem].join(' ')}
                          onClick={this.handleAddLegend}
                        >
                          <img
                            src={legendIcon}
                            className={scss.printIcon}
                          ></img>
                          Legend
                        </div>
                        <div
                          className={[scss.printDropdownItem].join(' ')}
                          onClick={this.handleAddScaleBar}
                        >
                          Scale Bar
                        </div>
                      </div>
                    )}
                  </div>
                  {/* -------------------------------------------------------------------------- */}

                  {printSettingsVisible && (
                    <PrintSettings
                      showPrintSettings={visible =>
                        this.showPrintSettings(visible)
                      }
                      toggleOrient={this.toggleOrient}
                      orientPortrait={orientPortrait}
                      viewport={this.props.viewport}
                      onViewportChange={this.props.onViewportChange}
                      dpi={dpi}
                      setDPI={newDPI => this.setState({ dpi: newDPI })}
                      pageSize={pageSize}
                      setPageSize={newPageSize =>
                        this.setState({ pageSize: newPageSize })
                      }
                    />
                  )}
                  <div
                    className={[
                      scss.settingsBtn,
                      scss.printDropdown,
                      dropdownScss.dropdown,
                    ].join(' ')}
                    onClick={() => this.showPrintSettings(true)}
                  >
                    Settings
                    <FontAwesomeIcon
                      icon='cog'
                      size='xs'
                      pull='right'
                      color='white'
                      className={scss.printArrowIcon}
                    />
                  </div>

                  <button
                    className={[scss.btn, scss.lockBtn].join(' ')}
                    onClick={this.toggleMapLock}
                  >
                    <div>
                      <span>
                        {this.state.lockMap ? 'Unlock Map' : 'Lock Map'}
                        {this.state.lockMap ? (
                          <LockIcon className={scss.lockIcon} />
                        ) : (
                          <UnlockIcon className={scss.lockIcon} />
                        )}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className={scss.pdfOptions}>
                <PrintLayouts
                  toggleFileDropdown={this.toggleFileDropdown}
                  showFileDropdown={showFileDropdown}
                  toggleMode={this.toggleMode}
                  setInitialMap={this.setInitialMap}
                  setPrintState={this.setPrintState}
                  dataToSave={JSON.parse(JSON.stringify(this.state))}
                />
                <button // EXPORT BUTTON
                  className={[scss.btn, scss.exportBtn].join(' ')}
                  onClick={this.exportMap}
                >
                  Export
                  <ExportIcon className={scss.exportIcon} />
                </button>
                <button // PRINT BUTTON
                  className={[scss.btn, scss.printBtn].join(' ')}
                  onClick={this.toggleMode}
                >
                  Print
                  <PrintIcon className={scss.printIcon} />
                </button>
              </div>
            </div>
          )}
        </div>
        {ui}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToolConfig: setToolConfig,
      setMapMode: setMapMode,
      setToc: setToc,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    toolConfig: state.toolConfig,
    user: state.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Print)
