import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { setSnapSettings } from '../../../../../../../actions'
import scss from '../Snapping.scss'

const Vertex = () => {
  const dispatch = useDispatch()
  const snapVertex = useSelector(state => state.snapSettings.snapVertex)
  const [checked, setChecked] = useState(snapVertex)
  const handleChange = () => setChecked(!checked)

  useEffect(() => {
    dispatch(setSnapSettings({ snapVertex: checked }))
  }, [checked])

  return (
    <div className={scss.wrapper}>
      <div className={scss.label}>Snap To Vertex:</div>
      <Switch
        checked={checked}
        onChange={handleChange}
        style={{ marginTop: '2px' }}
      />
    </div>
  )
}

export default Vertex
