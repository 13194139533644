import * as geoUtils from '../../../utilities/geospatial'
import * as mapUtils from '../../../utilities/mapStyle'

export const getMidPointLengths = feature => {
  const midPoint = geoUtils.getLineMidPoint(feature)
  return midPoint
}

export const buildMeasureId = (mamId, layerId, mType) => {
  return `${layerId}_${mamId}_${mType}`
}

export const checkForFeature = (mapStyle, measureId) => {
  const style = geoUtils.clone(mapStyle)
  const labelSource = mapUtils.getSource(style, 'measure_label_cold')

  const inSource = labelSource.data.features.filter(
    feat => feat.properties.measureId === measureId
  )
  return inSource
}
