export const GOOGLE = {
  toc: {
    id: 'aerial_google_tiles',
    group: 'imageryLayer',
    label: 'Google',
    description: 'Satellite imagery provided by Google',
    icon: ['fal', 'globe'],
    logo: 'google',
  },
  sourcesArray: [
    {
      id: 'aerial_google_tiles',
      type: 'raster',
      tiles: [
        'https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}',
      ],
      tileSize: 256,
    },
  ],
  layersArray: [
    {
      beforeLayer: 'landuse',
      layer: {
        id: 'aerial_google_tiles',
        type: 'raster',
        source: 'aerial_google_tiles',
        interactive: true,
        layout: {
          visibility: 'none',
        },
        paint: {
          'raster-opacity': 1,
        },
        metadata: {},
      },
    },
  ],
}

export const NEARMAP = {
  toc: {
    id: 'aerial_nearmap',
    group: 'imageryLayer',
    label: 'NearMap',
    description: 'Aerai imagery provided by NearMap',
    icon: ['fa', 'plane'],
    logo: 'nearmap',
  },
  sourcesArray: [
    {
      id: 'aerial_nearmap',
      type: 'raster',
      tiles: ['https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.jpg'],
      tileSize: 256,
    },
  ],
  layersArray: [
    {
      beforeLayer: 'landuse',
      layer: {
        id: 'aerial_nearmap',
        type: 'raster',
        source: 'aerial_nearmap',
        interactive: true,
        layout: {
          visibility: 'none',
        },
        paint: {
          'raster-opacity': 1,
        },
        metadata: {
          filters: {
            timeSlider: {
              range: {},
            },
          },
        },
      },
    },
  ],
}
