import React from 'react'
import scss from './DateElement.scss'
import printScss from '../../Print.scss'

const fullWidthHeight = {
  width: '100%',
  height: '100%',
}

function DateElement(props) {
  const object = props.object

  const wrapperStyle = {
    borderColor: !object.borderTransparent
      ? `${object.borderColor}`
      : 'transparent',
    borderWidth: object.borderWidth + 'px',
    borderStyle: 'solid',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  const textStyle = {
    resize: 'none',
    // border: "none",
    background: 'transparent',
    width: '100%',
    height: '100%',
  }

  const handleElementClick = props.handleElementClick
  const dateStampElements = props.dateStampElements

  return (
    <>
      <div
        style={wrapperStyle}
        className={
          printScss[dateStampElements == object.id ? 'activeEditWindow' : '']
        }
      >
        <div onClick={handleElementClick} style={fullWidthHeight}>
          <div
            className={'text'}
            id={object.id}
            style={
              (textStyle,
              {
                width: '100%',
                height: '100%',
                opacity: `${object.opacity}%`,
                color: `${object.textColor}`,
                fontSize: `${object.fontSize}px`,
                fontFamily: `${object.fontFamily}`,
                padding: `${object.padding}px`,
                backgroundColor: object.bgTransparent
                  ? 'transparent'
                  : `${object.bgColor}`,
                borderColor: object.borderTransparent
                  ? 'transparent'
                  : `${object.borderColor}`,
                fontWeight: `${object.fontWeight}`,
                fontStyle: `${object.fontStyle}`,
                textDecoration: `${object.textDecoration}`,
                textAlign: `${object.textAlign}`,
              })
            }
          >
            {object.text}
          </div>
        </div>
      </div>
    </>
  )
}
export default DateElement
