/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { makeUniqueIDNumbersOnly } from '../../../../utilities/util'
import scss from '../TabTemplate.scss'

const TabNav = ({
  tabs,
  tabsPerPage,
  currentPage,
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const [tabNavUi, setTabNavUi] = useState([])

  /*
   * TABS
   */
  const [tabTitle, setTabTitle] = useState([])

  const setTabToActive = e => {
    let newActiveTabIndex = e.target.getAttribute('data-tab-index')
    newActiveTabIndex = parseInt(newActiveTabIndex)
    setActiveTabIndex(newActiveTabIndex)
  }

  useEffect(() => {
    if (tabTitle && tabTitle.length > 0) {
      //   Set up the first tab as active
      let buttonStyle = ''
      let tabButtonUI = tabTitle.map((tabTitleVal, index) => {
        if (activeTabIndex === index) {
          buttonStyle = scss.ActiveTab
        } else buttonStyle = ''

        return (
          <>
            <button
              data-tab-index={index}
              type='button'
              title={tabTitleVal}
              className={[scss.TabButton, buttonStyle].join(' ')}
              onClick={e => setTabToActive(e)}
            >
              {tabTitleVal}
            </button>
          </>
        )
      })

      if (currentPage > 0) currentPage -= 1

      tabButtonUI = tabButtonUI.slice(
        currentPage * tabsPerPage,
        currentPage * tabsPerPage + tabsPerPage
      )
      setTabNavUi(tabButtonUI)
    } else {
      setTabNavUi(null)
    }
  }, [tabTitle, activeTabIndex, currentPage])

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      const titleValues = tabs.map(tab => tab.title)
      setTabTitle(titleValues)
    }
  }, [tabs])

  return (
    <>
      <span>
        {tabNavUi &&
          tabNavUi.map(item => {
            return <span key={makeUniqueIDNumbersOnly(8)}>{item}</span>
          })}
      </span>
    </>
  )
}

export default TabNav
