import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './Button.scss'
export default class Button extends PureComponent {
  render() {
    const {
      value,
      onClick,
      active,
      leadingIcon,
      leadingIconSize,
      leadingPull,
      trailingIcon,
      trailingIconSize,
      trailingPull,
      buttonText,
      activateSpin,
      buttonType,
      title,
      stacked,
    } = this.props

    let faLeading = null
    let faTrailing = null
    let faLeadingPull = leadingPull || null
    let faTrailingPull = trailingPull || null
    let faSize = leadingIconSize || 'xs'
    let faSize2 = trailingIconSize || 'xs'
    if (leadingIcon)
      if (stacked) {
        faLeading = (
          <span className='fa-layers fa-fw'>
            <FontAwesomeIcon
              icon={leadingIcon[0]}
              size={faSize}
              pull={faLeadingPull}
              className={scss.faIcon}
              fixedWidth
            />
            <FontAwesomeIcon
              icon={leadingIcon[1]}
              size={faSize}
              pull={faLeadingPull}
              className={scss.faIcon}
              fixedWidth
            />
          </span>
        )
      } else {
        faLeading = (
          <FontAwesomeIcon
            icon={leadingIcon}
            size={faSize}
            pull={faLeadingPull}
            className={scss.faIcon}
            fixedWidth
          />
        )
      }

    if (trailingIcon)
      faTrailing = (
        <FontAwesomeIcon
          icon={trailingIcon}
          size={faSize2}
          pull={faTrailingPull}
          className={scss.faIcon}
          fixedWidth
        />
      )
    if (activateSpin === 'true')
      faLeading = (
        <FontAwesomeIcon
          icon={leadingIcon}
          size={faSize}
          pull={faLeadingPull}
          className={scss.faIcon}
          fixedWidth
          spin
        />
      )
    return (
      <button
        value={value}
        onClick={onClick}
        className={[scss.button, scss[buttonType], scss[active]].join(' ')}
        title={title}
      >
        {faLeading && (
          <div className={scss['LeadingIcon']} value={value}>
            {' '}
            {faLeading}{' '}
          </div>
        )}
        {buttonText && <div className={scss['btnText']}> {buttonText} </div>}
        {faTrailing && (
          <div className={scss[buttonType + 'TrailingIcon']} value={value}>
            {' '}
            {faTrailing}{' '}
          </div>
        )}
      </button>
    )
  }
}
