import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import scss from './LegendElement.scss'
import printScss from '../../Print.scss'
import { getVisibleLayersFromDataConfig } from '../../../../../utilities/dataConfig'
import LineSwatch from '../../../Legend/LegendSwatch/LineSwatch'
import FillSwatch from '../../../Legend/LegendSwatch/FillSwatch'
import CircleSwatch from '../../../Legend/LegendSwatch/CircleSwatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LegendElement = ({
  object,
  handleElementClick,
  excludedLayers,
  legendElement,
}) => {
  const mapStyle = useSelector(state => state.mapStyle)
  const dataConfig = useSelector(state => state.updateDataConfig)
  const [hoverItem, setHoverItem] = useState('')

  const excludeLayer = id => excludedLayers.push(id)

  const getLegendLayers = () => {
    const style = mapStyle.toJS()
    const tocLayers = dataConfig['tocLayers']
    const legendLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    const uniqueLegendLayers = Array.from(
      new Set(legendLayers.map(JSON.stringify)),
      JSON.parse
    )

    const legendUi = []
    uniqueLegendLayers.reverse().forEach(legendLayer => {
      if (legendLayer.layersArray[0].layer.type === 'raster') return
      if (excludedLayers.includes(legendLayer.layersArray[0].layer.id)) return
      const key = legendLayer.toc.id
      let title = null
      let zoomLabel = legendLayer.toc.label
      // if simple style we want to align the title with the swatch by using display:flex
      let paintPorps = legendLayer.layersArray[0].layer.paint
      let outlinePaintPorps
      let flexAlignTitle = true

      for (let [key, value] of Object.entries(paintPorps)) {
        if (Array.isArray(value)) {
          const flat = value.flat().join(',')
          if (flat.indexOf('case,==,get') !== -1) {
            // data expression
            flexAlignTitle = false
            title = <div className={scss.title}>{legendLayer.toc.label}</div>
          } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
            // zoom expression
            flexAlignTitle = true
            title = null
            zoomLabel = legendLayer.toc.label
          }
        }
      }

      if (legendLayer.layersArray[0].outline)
        outlinePaintPorps = legendLayer.layersArray[0].outline.paint

      if (outlinePaintPorps) {
        for (let [key, value] of Object.entries(outlinePaintPorps)) {
          if (Array.isArray(value)) {
            const flat = value.flat().join(',')
            if (flat.indexOf('case,==,get') !== -1) {
              // data expression
              flexAlignTitle = false
              title = <div className={scss.title}>{legendLayer.toc.label}</div>
            } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
              // zoom expression
              flexAlignTitle = true
              title = null
              zoomLabel = legendLayer.toc.label
            }
          }
        }
      }
      // alsway show title on layerPackages
      if (legendLayer.toc.layerPackage) flexAlignTitle = false

      let hideLayerHeader = null
      if (legendLayer.layersArray.length > 1) {
        hideLayerHeader = true
        // this title is for layers holding layers, like TX Wetlands
        title = <div className={scss.title}>{legendLayer.toc.label}</div>
      }
      if (legendLayer.toc.hideLegnedHeader) {
        hideLayerHeader = true
      }

      const items = legendLayer.layersArray
        .filter(layerObj => !excludedLayers.includes(layerObj.layer.id))
        .filter(layerObj => !layerObj.layer.id.includes('_outline'))
        .filter(layerObj => !layerObj.layer.metadata.excludeFromLegend)
        .map(layerObj => {
          // get layer from mapStyle to build legend swatch
          const layer = style.layers.filter(
            layer => layer.id === layerObj.layer.id
          )
          const type = layer[0].type
          let outline = []
          if (type === 'fill') {
            outline = style.layers.filter(
              layer => layer.id === layerObj.layer.id + '_outline'
            )
          }

          const swatch = (
            <>
              {type === 'line' ? (
                <LineSwatch
                  key={layer[0].id}
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                />
              ) : null}
              {type === 'fill' ? (
                <FillSwatch
                  key={layer[0].id}
                  layer={layer[0]}
                  outline={outline[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                />
              ) : null}
              {type === 'circle' ? (
                <CircleSwatch
                  key={layer[0].id}
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                  zoomLabel={zoomLabel}
                />
              ) : null}
            </>
          )

          return swatch
        })

      if (items.length) {
        const btnStyle = {
          display:
            hoverItem === legendLayer.layersArray[0].layer.id ? 'grid' : 'none',
          color: 'black',
          height: '29px',
          width: '29px',
          // marginRight: "5px"
        }
        const deleteButton = (
          <div
            style={btnStyle}
            className={[scss.collapseBtn].join(' ')}
            onClick={() => excludeLayer(legendLayer.layersArray[0].layer.id)}
          >
            <FontAwesomeIcon icon='times' size='1x' />
          </div>
        )
        const style = {
          display: flexAlignTitle ? 'flex' : 'block',
          flexDirection: 'row',
          // alignItems: flexAlignTitle ? "center" : "none",
          // justifyContent: flexAlignTitle ? "space-between" : "none",
          marginTop:
            //   hoverItem === legendLayer.layersArray[0].layer.id && !title
            //     ? "-22px"
            //     : "8px",
            '4px',
        }
        const innerStyle = {
          // display: "flex",
          // flexDirection: "row",
          //justifyContent: "space-between"
        }
        legendUi.push(
          <div
            key={key}
            style={style}
            className={[
              scss.titleButtonRow,
              title ? '' : scss.deleteButtonRow,
              hoverItem === legendLayer.layersArray[0].layer.id && !title
                ? scss.collapseBtnVisible
                : '',
            ].join(' ')}
            onMouseEnter={() =>
              setHoverItem(legendLayer.layersArray[0].layer.id)
            }
            onMouseLeave={() =>
              setHoverItem(legendLayer.layersArray[0].layer.id)
            }
          >
            {title ? (
              <div style={innerStyle} className={scss.titleWrapper}>
                {deleteButton}
                {title}
              </div>
            ) : null}

            {title ? null : deleteButton}
            {items}
          </div>
        )
      }
    })
    return <div className={scss.legendPanel}>{legendUi}</div>
  }

  const legendStyle = {
    borderColor: !object.borderTransparent
      ? `${object.borderColor}`
      : 'transparent',
    borderWidth: object.borderWidth + 'px',
    borderStyle: 'solid',

    backgroundColor: !object.bgTransparent && `${object.bgColor}`,
    opacity: object.opacity + '%',
    color: `${object.textColor}`,

    height: '100%',
    padding: object.padding + 'px',
  }
  const legendLayers = getLegendLayers()

  return (
    <div
      style={legendStyle}
      className={
        printScss[legendElement == object.id ? 'activeEditWindow' : '']
      }
      onClick={handleElementClick}
    >
      <div key={'legend_ui'} className={scss.toolBody}>
        <div className={scss.targetLayerPanel}>
          <div key={excludedLayers.length} className={scss.toolGroup}>
            {legendLayers}
          </div>
        </div>
      </div>
    </div>
  )
}
export default LegendElement
