/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMapStyle } from '../../../../../actions'
import { fromJS } from 'immutable'
import scss from './Style.scss'

// utilities
import * as utils from '../../../../../utilities/util'
/*
    NOTE:
    Zoom is set as a mapbox expression
    the Min and Max zooms will adjust the values as follows
    Min Zoom will adjust value in expression at Array position 4
    Max Zoom will adjust value in expression at Array position 6

    Example Expression Looks Like this
    [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        12,
        24,
        14,
    ]
*/
const LabelSize = ({
  layerId,
  textSize,
  selectedZoom,
  setDisplaySaveButtonOnMenu,
}) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const style = mapStyle.toJS()
  const [selectedMin, setSelectedMin] = useState(12)
  const [selectedMax, setSelectedMax] = useState(14)
  const sizeList = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  ]

  const handleMinChange = e => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.layout['text-size'][4] = parseInt(e.target.value)
      })

    dispatch(setMapStyle(fromJS(style)))
    setDisplaySaveButtonOnMenu(true)
  }

  const handleMaxChange = e => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.layout['text-size'][6] = parseInt(e.target.value)
      })

    dispatch(setMapStyle(fromJS(style)))
    setDisplaySaveButtonOnMenu(true)
  }

  useEffect(() => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        if (
          layer &&
          layer.layout &&
          typeof layer.layout['text-size'] === 'undefined'
        ) {
          layer.layout['text-size'] = [
            'interpolate',
            ['linear'],
            ['zoom'],
            selectedZoom || 12,
            12,
            24,
            14,
          ]
        }
      })

    dispatch(setMapStyle(fromJS(style)))
  }, [])

  useEffect(() => {
    setSelectedMin(textSize[4] || 12)
    setSelectedMax(textSize[6] || 14)
  }, [textSize])

  return (
    <>
      <div>Label Size</div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'inline-grid', width: '35%' }}>
          <label>Size at Min Zoom (zoom {selectedZoom})</label>
          <select
            className={scss.zoomSelect}
            value={selectedMin}
            onChange={handleMinChange}
          >
            {sizeList
              .filter(size => size < selectedMax)
              .map(size => (
                <option key={utils.makeUniqueIDNumbersOnly(90)} value={size}>
                  {size}
                </option>
              ))}
          </select>
        </div>
        <div
          style={{ display: 'inline-grid', width: '35%', marginLeft: '20px' }}
        >
          <label>Size at Max Zoom (zoom 24)</label>
          <select
            className={scss.zoomSelect}
            value={selectedMax}
            onChange={handleMaxChange}
          >
            {sizeList
              .filter(size => size > selectedMin)
              .map(size => (
                <option key={utils.makeUniqueIDNumbersOnly(90)} value={size}>
                  {size}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  )
}

export default LabelSize
