/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import { fromJS } from 'immutable'
import {
  setVisibleLayers,
  updateDataConfig,
  updateMapStyle,
} from '../../../actions/index'
import scss from '../Toc.scss'
import TocLayer from './TocLayer/TocLayer'
import Group from '../Group/Group'
import { clone } from '../../../utilities/geospatial'
import {
  getOrderFromDataConfig,
  getVisibleLayersFromDataConfig,
} from '../../../utilities/dataConfig'
import { apis } from '../../../config/apiConfig'
import { apiFetch } from '../../../utilities/api'
import { BASIC_STYLE } from '../../../mapStyles/basic/style.js'
import { addLayerToStyle, addSourceToStyle } from '../../../utilities/mapStyle'
import ErrorMessage from './ErrorMessage'

const LayerList = React.memo(() => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const dataConfig = useSelector(state => state.updateDataConfig)
  const mapStyle = useSelector(state => state.mapStyle)
  const [dataLayers, setDataLayers] = useState([])

  const groupClicked = useSelector(state => state.groupClicked)
  const layerClicked = useSelector(state => state.layerClicked)
  const filterChanged = useSelector(state => state.filterChanged)

  const tocLayerFilter = ''

  const makeDraggableDataLayerButton = layer => {
    if (layer && layer.children) {
      const tocGroup = getTocGroup(layer)
      return tocGroup
    }
    const tocLayer = getTocLayer(layer)
    return tocLayer
  }

  const getTocGroup = (layer, parent = null) => {
    if (!layer) return null
    if (tocLayerFilter !== '') {
      if (layer.children.items.length === 0) {
        return null
      }
    }

    const groupVisibleLayers = []
    let groupLayerIds = []
    const layerIds = []
    const groupIds = []

    const getLayerIds = layer => {
      layer.layersArray.forEach(layer => {
        layerIds.push(layer.layer.id)
      })
    }

    const processItem = item => {
      if (item.children && item.children.items) {
        item.children.items.forEach(childItem => {
          processItem(childItem)
        })
      } else {
        getLayerIds(item)
      }
    }

    processItem(layer)

    const groupLayers = []
    layer.children.items.map(groupItem => {
      if (groupItem.children) {
        groupIds.push(layer.groupId)
        if (tocLayerFilter !== '') {
          if (groupItem.children.items.length) {
            const tocGroup = getTocGroup(groupItem, layer.groupId)
            groupLayers.push(tocGroup)
          }
        } else {
          const tocGroup = getTocGroup(groupItem, layer.groupId)
          groupLayers.push(tocGroup)
        }
      } else if (tocLayerFilter !== '') {
        const re = new RegExp(tocLayerFilter, 'gi')
        const res = groupItem.toc.label.match(re)
        if (!res) {
          // Layer Name does not contain, do not render in TOC
        } else {
          const gLayer = getTocLayer(groupItem, layer)
          groupLayers.push(gLayer)
        }
      } else {
        const gLayer = getTocLayer(groupItem, layer)
        groupLayers.push(gLayer)
      }
    })

    groupLayerIds = [...groupLayerIds, ...layerIds]

    const draggableID = layer.groupId || layer.id
    const index = layer.draggableIndex

    if (tocLayerFilter !== '') {
      if (!groupLayers.length) {
        return null
      }
    }
    return (
      <Draggable
        draggableId={draggableID}
        index={index}
        type='droppableTOCroot'
        key={draggableID}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Group
              key={layer.groupId}
              group={layer}
              groupLayers={groupLayers}
              groupLayerIds={groupLayerIds}
              groupIds={groupIds}
              groupVisibleLayers={groupVisibleLayers}
              parent={parent}
            />
          </div>
        )}
      </Draggable>
    )
  }

  const getTocLayer = (layer, parentGroup = null) => {
    let filtered = false
    let labeled = false
    const style = mapStyle.toJS()

    // If layer is a valid layer
    if (!layer.layersArray[0]) {
      console.warn('Invalid Layer (Layer) Identified :', layer)
      return null
    }

    if (layer.toc.userLayer) {
      if (style) {
        const filterLayer = style.layers.filter(
          styleLayer => styleLayer.id === layer.layersArray[0].layer.id
        )

        if (filterLayer[0] && filterLayer[0].filter) {
          filtered = true
        }
        const symbolLayer = style.layers.filter(
          styleLayer => styleLayer.id === layer.layersArray[0].symbol.id
        )
        if (symbolLayer[0]) {
          const symbolSource = style.sources[symbolLayer[0].source]
          if (
            symbolSource &&
            symbolSource.type === 'geojson' &&
            symbolSource.data.features.length
          ) {
            labeled = true
          }
        }
      }
    }
    const tocLayer = (
      <TocLayer layer={layer} filtered={filtered} labeled={labeled} />
    )
    const draggableID = layer.toc.id
    const index = layer.toc.draggableIndex
    let dragLock = false

    if (parentGroup) {
      if (parentGroup.canEdit === false) dragLock = true
    }

    return (
      <Draggable
        draggableId={draggableID}
        index={index}
        type='droppableTOCroot'
        isDragDisabled={dragLock}
        key={draggableID}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div key={layer.toc.id} className={scss.btnContainer}>
              {tocLayer}
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  const onDragEnd = result => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    const clonedConfig = clone(dataConfig)
    let currentOrder = getOrderFromDataConfig(clonedConfig.tocLayers)
    const { droppableId } = result.destination
    // NOTE: draggable indexes start at 1, to get correct position in array subract 1 from the index
    const startIndex = result.source.index - 1
    const finishIndex = result.destination.index - 1
    const targetGroupFromConfig = []

    // find the droppable group in the current order
    const droppableGroupFromOrder = currentOrder.filter(
      orderObj => orderObj.group && orderObj.group === droppableId
    )

    const array_move = (arr, old_index, new_index) => {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    }

    const processConfigObj = configObj => {
      if (configObj.groupId && configObj.groupId === droppableId) {
        targetGroupFromConfig.push(configObj)
      }
      if (configObj.children && configObj.children.items) {
        configObj.children.items.forEach(
          configObj => configObj.children && processConfigObj(configObj)
        )
      }
    }

    if (droppableGroupFromOrder.length) {
      const newGroupIds = []
      const newLayerIds = []
      const newItems = []
      let draggableIndex = 1

      array_move(droppableGroupFromOrder[0].children, startIndex, finishIndex)
      clonedConfig.tocLayers.forEach(configObj => {
        return processConfigObj(configObj)
      })

      droppableGroupFromOrder[0].children.forEach(orderObj => {
        if (orderObj.layer) {
          const layer = targetGroupFromConfig[0].children.items.filter(
            layer => layer.toc && layer.toc.id === orderObj.layer
          )
          // this if statement was added because there is an orphan layer in user profile
          if (layer[0]) {
            layer[0].toc.draggableIndex = draggableIndex
            newItems.push(layer[0])
            newLayerIds.push(layer[0].toc.id)
            draggableIndex++
          }
        }
        if (orderObj.group) {
          const group = targetGroupFromConfig[0].children.items.filter(
            group => group.groupId && group.groupId === orderObj.group
          )
          group[0].draggableIndex = draggableIndex
          newItems.push(group[0])
          newGroupIds.push(group[0].groupId)
          draggableIndex++
        }
      })

      targetGroupFromConfig[0].children.items = newItems
      targetGroupFromConfig[0].children.groupIds = newGroupIds
      targetGroupFromConfig[0].children.layerIds = newLayerIds
    }

    if (
      !droppableGroupFromOrder.length &&
      droppableId === 'toc_root_droppable'
    ) {
      array_move(clonedConfig.tocLayers, startIndex, finishIndex)

      clonedConfig.tocLayers.forEach((configObj, index) => {
        if (configObj.children) configObj.draggableIndex = index + 1
        if (configObj.toc) configObj.toc.draggableIndex = index + 1
      })

      currentOrder = getOrderFromDataConfig(clonedConfig.tocLayers)
    }

    dispatch(updateDataConfig(clonedConfig))

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layers/order'
    const bodyParams = {
      order: currentOrder,
      mapID: user.mapID,
    }

    apiFetch(url, method, bodyParams, result => {
      updateLayerOrder(clonedConfig)
    })
  }

  const updateLayerOrder = dataConfig => {
    const oldStyle = mapStyle.toJS()

    const addLayers = (layersArray, style) => {
      layersArray.forEach(layerObj => {
        // get old style layer and use it to define props for layer in new style
        const oldStyleLayer = oldStyle.layers.filter(
          oldLayer => oldLayer.id === layerObj.layer.id
        )
        const { layout } = oldStyleLayer[0]
        const { paint } = oldStyleLayer[0]
        const filter = oldStyleLayer[0].filter || []
        const { metadata } = oldStyleLayer[0]

        layerObj.layer.layout = layout
        layerObj.layer.paint = paint
        layerObj.layer.filter = filter
        layerObj.layer.metadata = metadata

        if (layerObj.layer.filter && !layerObj.layer.filter.length) {
          delete layerObj.layer.filter
        }

        style = addLayerToStyle(style, layerObj, layout.visibility)
      })

      return style
    }

    const addSources = (sourcesArray, style) => {
      sourcesArray.forEach(source => {
        if (source.tiles) {
          // if source is a tile url and comming from https://tiles.myassetmap.com
          // bust the browser cache for each tile url by add dt=${Date.now() as query param
          source.tiles = source.tiles.map(tileUrl => {
            if (tileUrl.includes(`https://tiles.myassetmap.com`)) {
              if (tileUrl.indexOf('?') === -1) {
                return tileUrl + `?dt=${Date.now()}`
              }
              const url = tileUrl.replace(/&dt.*$/, '')
              return url + `&dt=${Date.now()}`
            }
            return tileUrl
          })
        }
        style = addSourceToStyle(style, source)
      })
      return style
    }

    const processConfigObj = config => {
      // add groups
      if (config.children) {
        config.children.items.reverse().forEach(item => {
          if (item.children) {
            processConfigObj(item)
          } else {
            if (item.layersArray) {
              newMapStyle = addLayers(item.layersArray, newMapStyle)
            }
            if (item.sourcesArray) {
              newMapStyle = addSources(item.sourcesArray, newMapStyle)
            }
          }
        })
      }
      // add layers
      if (config.layersArray) {
        newMapStyle = addLayers(config.layersArray, newMapStyle)
      }
      // add sources
      if (config.sourcesArray) {
        newMapStyle = addSources(config.sourcesArray, newMapStyle)
      }
    }

    // add layers and sources from data config to map style
    const dConfig = clone(dataConfig)
    let newMapStyle = clone(BASIC_STYLE) // TODO - check and use current base style instead of BASIC

    dConfig.tocLayers.reverse().forEach(config => {
      processConfigObj(config)
    })

    dConfig.auxiliaryLayers.forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          const visible = layerObj.layer.layout.visibility
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible)
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })

    dConfig.imageryLayers.forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          const visible = oldStyle.layers
            .filter(layer => layer.id === layerObj.layer.id)
            .map(layer => layer.layout.visibility)
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible[0])
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })
    // update mapStyle
    dispatch(updateMapStyle(fromJS(newMapStyle)))
  }

  useEffect(() => {
    if (dataConfig) {
      const layers = dataConfig.tocLayers.map((layer, index) => {
        return makeDraggableDataLayerButton(layer, index)
      })
      setDataLayers(layers)
    }
  }, [dataConfig, filterChanged])

  useEffect(() => {
    if (dataConfig && mapStyle) {
      const visible = getVisibleLayersFromDataConfig(
        dataConfig.tocLayers,
        mapStyle.toJS()
      )
      apiFetch(
        apis['userPreferences'].uri + 'upsert',
        'POST',
        {
          key: 'layer.visibility.history',
          name: 'visibility',
          value: visible
            .flatMap(layer => layer.layersArray)
            .flatMap(layer => Object.values(layer))
            .filter(layer => typeof layer !== 'undefined' && layer !== null)
            .map(layer => layer.id)
        },
        () => {}
      )
      dispatch(setVisibleLayers(visible))
    }
  }, [layerClicked, groupClicked])

  return (
    <div className={[scss['toc-list'], scss['data-layer-list']].join(' ')}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='toc_root_droppable' type='droppableTOCroot'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {provided.placeholder}
              <ErrorMessage />
              {dataLayers && dataLayers.length > 0 ? (
                dataLayers
              ) : (
                <div>
                  {' '}
                  No active layers were found. Go to the{' '}
                  <Link to='/layers' style={{ color: '#337ab7' }}>
                    Data Store
                  </Link>{' '}
                  tab to activate your layers.
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
})

export default LayerList
