import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiFetch } from '../../../utilities/api'
import { extractLayerInt } from '../../../utilities/util'
import { apis } from '../../../config/apiConfig'
import { updateDataConfig, updateMapStyle } from '../../../actions/index'
import MakeDataConfigComponent, {
  getOrderFromDataConfig,
} from '../../../utilities/dataConfig'
import {
  addLayerToStyle,
  addSourceToStyle,
  addTocLayersToMapStyle2,
} from '../../../utilities/mapStyle'
import { clone } from '../../../utilities/geospatial'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'
import { fromJS } from 'immutable'
import { BASIC_STYLE } from '../../../mapStyles/basic/style.js'

// scss files
import scss from './ParentGroupSelect.scss'
import appScss from '../../App/App.scss'

const tocRootOption = { label: 'None', value: 'toc_root' }
class ParentGroupSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDelete: false,
      modalOpen: false,
      selectedOption: { value: '', label: 'loading...' },
      selectOptions: [],
      doingMove: false,
      mountMakeDataConfig: false,
    }
  }

  componentDidMount = () => {
    const { config } = this.props
    if (config.group) {
      this.buildOptionsForGroup()
    } else {
      this.buildOptionsForLayer()
    }
  }

  buildOptionsForGroup = () => {
    const { config } = this.props

    const groups = this.getGroups()
    const selectOptions = this.getGroupOptions(groups, config.group.groupId)
    const targetGroupParent = this.getTargetGroupParent(
      groups,
      config.group.groupId
    )
    const selectedOption = targetGroupParent
      ? { label: targetGroupParent.label, value: targetGroupParent.groupId }
      : tocRootOption

    selectOptions.unshift(tocRootOption)
    this.setState({ selectedOption, selectOptions })
  }

  buildOptionsForLayer = () => {
    const groups = this.getGroups()
    const layerGroup = this.getLayerGroup(groups)
    const selectOptions = this.getGroupOptions(groups)
    const selectedOption = layerGroup
      ? { label: layerGroup.label, value: layerGroup.groupId }
      : tocRootOption

    selectOptions.unshift(tocRootOption)
    this.setState({ selectedOption, selectOptions })
  }

  getGroups = () => {
    const { dataConfig } = this.props
    const clonedConfig = clone(dataConfig)
    const groups = clonedConfig['tocLayers'].filter(configObj => !configObj.toc)
    return groups
  }

  getGroupOptions = (groups, targetGroupId) => {
    const { config } = this.props
    let groupOptions = []

    const buildGroupOptions = (group, groupOptions) => {
      //temp if for dev
      if (!group) return
      const option = { label: group.label, value: group.groupId }
      groupOptions.push(option)
      group.children.items.forEach(group => {
        if (group.groupId) buildGroupOptions(group, groupOptions)
      })
    }
    groups.forEach(group => {
      buildGroupOptions(group, groupOptions)
    })
    // remove the target group from the options
    groupOptions = groupOptions.filter(
      option => option.value !== targetGroupId //config.group.groupId
    )

    // remove the children already in hierarchy from the group to avoid infinite grouping
    groupOptions = this.removeChildrenFromParentGroup(
      groupOptions,
      targetGroupId
    )

    return groupOptions
  }

  removeChildrenFromParentGroup = (groupOptions, parentID) => {
    const { dataConfig } = this.props
    const { layerHierarchy } = dataConfig

    // We obtain all values that are connected to the parent
    let relatedGroupOrLayers = []

    if (layerHierarchy) {
      layerHierarchy.forEach(tocObject => {
        if (tocObject.groupPath.includes(extractLayerInt(parentID))) {
          relatedGroupOrLayers.push(...tocObject.groupPath)
        }
      })

      // Clean the values to remove duplicates
      relatedGroupOrLayers = [...new Set(relatedGroupOrLayers)]

      // remove the children from the options
      relatedGroupOrLayers.forEach(targetGroupId => {
        groupOptions = groupOptions.filter(
          option => extractLayerInt(option.value) !== targetGroupId
        )
      })
    }
    return groupOptions
  }

  getLayerGroup = groups => {
    const { config } = this.props
    let layerGroup = null
    const checkGroupChildren = groupObj => {
      groupObj.children.layerIds.forEach(layerId => {
        if (layerId === config.layer.toc.id) {
          layerGroup = groupObj
        }
      })
      groupObj.children.items.forEach(item => {
        if (item.children) checkGroupChildren(item)
      })
    }
    groups.forEach(groupObj => {
      checkGroupChildren(groupObj)
    })
    return layerGroup
  }

  getTargetGroupParent = (groups, targetGroupId) => {
    let targetGroupParent = null
    const checkGroupChildren = groupObj => {
      groupObj.children.groupIds.forEach(groupId => {
        if (groupId === targetGroupId) {
          targetGroupParent = groupObj
        }
      })
      groupObj.children.items.forEach(groupObj => {
        if (groupObj.groupId) checkGroupChildren(groupObj)
      })
    }
    groups.forEach(groupObj => {
      checkGroupChildren(groupObj)
    })
    return targetGroupParent
  }

  getParentGroupSelectUI = () => {
    const { config } = this.props
    const { selectedOption, selectOptions, doingMove } = this.state
    var disabled = false
    var placeholder = 'Select Parent Group'
    var options = selectOptions
    var currentOption = selectedOption

    if (selectOptions.length == 1) {
      placeholder = 'No Groups Found'
      disabled = true
      currentOption = null
      options = []
    } else if (selectOptions.length > 1 && selectedOption == tocRootOption) {
      delete options[0]
      currentOption = null
    }

    if (doingMove) {
      return (
        <div className={scss['parent-group-saving']}>
          <FontAwesomeIcon icon='spinner' size='1x' spin /> Selecting
        </div>
      )
    }
    const onChange = config.group
      ? this.handleGroupChange
      : this.handleLayerChange
    return (
      <div className={scss['parent-group']}>
        <ReactSelect
          options={options}
          onChange={onChange}
          defaultValue={currentOption}
          disabled={disabled}
          isFixed
        />
      </div>
    )
  }

  handleGroupChange = (values, selected) => {
    this.setState({ selectedOption: selected }, () => {
      this.updateDataConfigGroups()
    })
  }

  handleLayerChange = (values, selected) => {
    this.setState({ selectedOption: selected }, () => {
      this.updateDataConfigLayers()
    })
  }

  updateDataConfigGroups = () => {
    const { selectedOption } = this.state
    const { config, dataConfig } = this.props

    const clonedConfig = clone(dataConfig)
    const selectedGroupId = selectedOption.value
    const groups = this.getGroups()
    const targetGroupParent = this.getTargetGroupParent(
      groups,
      config.group.groupId
    )

    // get config order
    const newOrder = getOrderFromDataConfig(clonedConfig['tocLayers'])

    if (selectedOption.value === 'toc_root') {
      // move target group to root by removing it from its parent children array
      newOrder
        .filter(orderObj => orderObj.children)
        .forEach(orderGroup => {
          const index = orderGroup.children.findIndex(
            childObj => childObj.group === config.group.groupId
          )
          if (index >= 0) {
            orderGroup.children.splice(index, 1)
          }
        })
    } else {
      // move group to parent by adding it to the parents children array
      const parentGroupIndex = newOrder.findIndex(orderObj => {
        return orderObj.group && orderObj.group === selectedGroupId
      })
      //const parentGroupIndex = getParentIndex(newOrder)
      if (!newOrder[parentGroupIndex].children)
        newOrder[parentGroupIndex].children = []
      newOrder[parentGroupIndex].children.push({ group: config.group.groupId })
      // if there is a taretGroupParent we need to remove the group from its children array
      if (targetGroupParent) {
        newOrder.forEach(orderObj => {
          if (
            orderObj.children &&
            orderObj.group === targetGroupParent.groupId
          ) {
            const index = orderObj.children.findIndex(
              childObj => childObj.group === config.group.groupId
            )
            if (index >= 0) {
              orderObj.children.splice(index, 1)
            }
          }
        })
      }
    }

    this.doFetch(newOrder)
  }

  updateDataConfigLayers = () => {
    const { selectedOption } = this.state
    const { config, dataConfig } = this.props

    const clonedConfig = clone(dataConfig)
    const selectedGroupId = selectedOption.value
    const groups = this.getGroups()
    const targetParentGroup = this.getLayerGroup(groups)
    // get config order
    const newOrder = getOrderFromDataConfig(clonedConfig['tocLayers'])

    if (selectedOption.value === 'toc_root') {
      // move target group to root by removing it from its parent children array
      newOrder
        .filter(orderObj => orderObj.children)
        .forEach(orderGroup => {
          const index = orderGroup.children.findIndex(
            childObj => childObj.layer === config.layer.toc.id
          )
          if (index >= 0) {
            orderGroup.children.splice(index, 1)
          }
        })
    } else {
      // move layer to parent by adding it to the parents children array
      const parentGroupIndex = newOrder.findIndex(orderObj => {
        return orderObj.group && orderObj.group === selectedGroupId
      })
      //const parentGroupIndex = getParentIndex(newOrder)
      if (!newOrder[parentGroupIndex].children)
        newOrder[parentGroupIndex].children = []
      newOrder[parentGroupIndex].children.push({ layer: config.layer.toc.id })
      // if there is a taretGroupParent we need to remove the group from its children array
      if (targetParentGroup) {
        newOrder.forEach(orderObj => {
          if (
            orderObj.children &&
            orderObj.group === targetParentGroup.groupId
          ) {
            const index = orderObj.children.findIndex(
              childObj => childObj.layer === config.layer.toc.id
            )
            if (index >= 0) {
              orderObj.children.splice(index, 1)
            }
          }
        })
      }
    }
    this.doFetch(newOrder)
  }

  doFetch = payload => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layers/order'
    const bodyParams = {
      order: payload,
      mapID: this.props.user.mapID,
    }

    this.setState({ doingMove: true }, () => {
      apiFetch(url, method, bodyParams, () => {
        this.setState({ mountMakeDataConfig: true }, () => {
          this.setState({ mountMakeDataConfig: false })
        })
      })
    })
  }

  handleOnFinish = dConfig => {
    // Note: we need to clone the config,
    // otherwise there will be side effects resulting in the wrong layer order in the TOC
    let configClone = clone(dConfig)
    let styleClone = clone(BASIC_STYLE) //TODO - check and use current base style instead of BASIC
    let oldStyle = this.props.mapStyle.toJS()
    let newMapStyle = addTocLayersToMapStyle2(styleClone, oldStyle, configClone)
    configClone['auxiliaryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = layerObj.layer.layout.visibility
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible)
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })

    configClone['imageryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = oldStyle.layers
            .filter(layer => layer.id === layerObj.layer.id)
            .map(layer => layer.layout.visibility)
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible[0])
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })
    this.props.updateMapStyle(fromJS(newMapStyle))
    this.setState({ doingMove: false })
  }

  render() {
    const { mountMakeDataConfig } = this.state
    let ui = this.getParentGroupSelectUI()
    return (
      <div>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={this.handleOnFinish} />
        )}
        {ui}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDataConfig: updateDataConfig,
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    dataConfig: state.updateDataConfig,
    mapStyle: state.mapStyle,
    user: state.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentGroupSelect)
