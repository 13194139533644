import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  setOverPanel,
} from '../../../actions/index'
import NearMapCoverage from '../../MapControls/NearMap/NearMapCoverage'
import ImageryPickerBtn from '../../MapControls/ImageryPicker/ImageryPickerBtn'
import ImageryPicker from '../../MapControls/ImageryPicker/ImageryPicker'
import LegendBtn from '../../MapControls/Legend/LegendBtn'
import BaseStylePickerBtn from '../../MapControls/BaseStylePicker/BaseStylePickerBtn'
import BaseStylePicker from '../../MapControls/BaseStylePicker/BaseStylePicker'
import scss from './MapControls.scss'

const BottomControls = React.memo(() => {
  const dispatch = useDispatch()

  const mapRef = useSelector(state => state.mapRef)
  const dataConfig = useSelector(state => state.updateDataConfig)

  const [baseStylePickerVisible, setBaseStylePickerVisible] = useState(false)
  const [imageryPickerVisible, setImageryPickerVisible] = useState(false)
  const [nearMapCoverageVisible, setNearMapCoverageVisible] = useState(false)
  const [currentStyle, setCurrentStyle] = useState('basic')

  const disableMap = () => {
    dispatch(setOverPanel(true))
    dispatch(setScrollZoom(false))
    dispatch(setDragPan(false))
    dispatch(setDoubleClickZoom(false))
  }

  const enableMap = () => {
    dispatch(setOverPanel(false))
    dispatch(setScrollZoom(true))
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
  }

  const toggleMapStyle = () => {
    if (baseStylePickerVisible) {
      enableMap()
    } else {
      disableMap()
    }
    setBaseStylePickerVisible(!baseStylePickerVisible)
  }

  const toggleImageryPicker = () => {
    if (imageryPickerVisible) {
      enableMap()
    } else {
      disableMap()
    }
    setImageryPickerVisible(!imageryPickerVisible)
  }

  const toggleNearCoverage = () => {
    if (nearMapCoverageVisible) {
      enableMap()
    } else {
      disableMap()
    }

    setNearMapCoverageVisible(!nearMapCoverageVisible)
    setImageryPickerVisible(!imageryPickerVisible)
  }

  return (
    <>
      <div
        className={scss.bottomLeftControl}
        onMouseEnter={disableMap}
        onMouseLeave={enableMap}
      >
        <BaseStylePickerBtn
          toggleMapStyle={toggleMapStyle}
          BaseStylePickerVisible={baseStylePickerVisible}
          currentStyle={currentStyle}
        />
        <ImageryPickerBtn
          toggleImagery={toggleImageryPicker}
          imageryPickerVisible={imageryPickerVisible}
        />
        <LegendBtn />
      </div>
      {imageryPickerVisible && (
        <div
          className={[scss['mapBtnControlGroup'], scss['mapBtnDropdown']].join(
            ' '
          )}
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
          onClick={toggleImageryPicker}
        >
          <ImageryPicker
            dataConfig={dataConfig}
            enableMap={enableMap}
            toggleNearCoverage={toggleNearCoverage}
            toggleImageryPicker={toggleImageryPicker}
          />
        </div>
      )}
      {baseStylePickerVisible && (
        <div
          className={scss['mapBtnControlGroup']}
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
          onClick={toggleMapStyle}
        >
          <BaseStylePicker
            currentStyle={currentStyle}
            updateStyle={setCurrentStyle}
          />
        </div>
      )}
      {nearMapCoverageVisible && (
        <NearMapCoverage
          mapRef={mapRef}
          enableMap={enableMap}
          disableMap={disableMap}
          imageryPickerVisible={imageryPickerVisible}
          BaseStylePickerVisible={baseStylePickerVisible}
          toggleNearCoverage={toggleNearCoverage}
        />
      )}
    </>
  )
})

export default BottomControls
