import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle, layerClicked } from '../../../../actions'
import * as mapUtils from '../../../../utilities/mapStyle'
import { fromJS } from 'immutable'
import { DRAW_SOURCES } from '../Constants'

const UpdateMapStyle = ({ targetLayer }) => {
  // NOTE:
  // The purpose of this component is to handel changes to MapStyle in a component that
  // Does not render anything.
  // This will prevenet over rendering.
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const handleChangedTargetLayer = (style, targetLayer) => {
    style = makeLayerVisible(style, targetLayer)
    style = emptySources(style)
    let radius = 5
    let opacity = 1
    let strokeWidth = 0.5
    let lineWidth = 2
    if (targetLayer.type === 'circle') {
      // NOTE:
      // we need to update certain paint properties of draw and delete layers based on the target layer
      if(Array.isArray(targetLayer.paint['circle-radius'])){
        radius = targetLayer.paint['circle-radius']
      } else {
        radius = targetLayer.paint['circle-radius'] + 3
      }
      opacity = 0.02
      targetLayer = 2
      strokeWidth = 2
    }
    if (targetLayer.type === 'line') {
      if(Array.isArray(targetLayer.paint['line-width'])){
        lineWidth = targetLayer.paint['line-width']
      } else {
        lineWidth = targetLayer.paint['line-width'] + 3
      }
    }
    style.layers
      .filter(
        layer =>
          layer.id === 'delete_circle' || layer.id === 'draw_verticies_hot'
      )
      .forEach(layer => {
        layer.paint['circle-radius'] = radius
        layer.paint['circle-opacity'] = opacity
        layer.paint['circle-stroke-width'] = strokeWidth
      })
    style.layers
      .filter(layer => layer.id === 'delete_line')
      .forEach(layer => {
        layer.paint['line-width'] = lineWidth
      })
    dispatch(updateMapStyle(fromJS(style)))
    dispatch(layerClicked())
  }

  const makeLayerVisible = (style, targetLayer) => {
    style.layers.forEach(layer => {
      if (layer.id === targetLayer.value) {
        layer.layout.visibility = 'visible'
      }
      if (layer.id === targetLayer.value + '_outline') {
        layer.layout.visibility = 'visible'
      }
      if (layer.id === targetLayer.value + '_symbol') {
        layer.layout.visibility = 'visible'
      }
    })
    return style
  }

  const emptySources = style => {
    DRAW_SOURCES['line'].forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    DRAW_SOURCES['fill'].forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    DRAW_SOURCES['circle'].forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    return style
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  useEffect(() => {
    let style = mapStyle.toJS()

    if (targetLayer) {
      // NOTE:
      // when target layer changes we want
      // make the target layer visible and
      // to clear all pending edits
      handleChangedTargetLayer(style, targetLayer)
    }
    return () => {
      // NOTE:
      // run cleanup hear to empty all draw sources
      // this effect will run when the tool is turned off
      // and ensure no draw layers remain visible on screen

      let style = mapStyle.toJS()
      style = emptySources(style)
      if (targetLayer && targetLayer.sources) {
        targetLayer.sources.forEach(sourceObj => {
          if (sourceObj.id) {
            style.sources[sourceObj.id].tiles = style.sources[
              sourceObj.id
            ].tiles.map(tileUrl => {
              if (tileUrl.indexOf('?') === -1) {
                return tileUrl + `?dt=${Date.now()}`
              } else {
                let url = tileUrl.replace(/&dt.*$/, '')
                return url + `&dt=${Date.now()}`
              }
            })
          }
        })
      }

      dispatch(updateMapStyle(fromJS(style)))
    }
  }, [targetLayer])

  return null
}

export default UpdateMapStyle
