import * as Sentry from '@sentry/react'
import Amplify from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'
import AWS from 'aws-sdk'
import environment from '_environments'
import authConfig from '../config/authConfig'
import pkg from '../../package.json'

export * from './user.service'

export function configureSentry() {
  // Sentry
  Sentry.init({
    dsn: 'https://4a62e2d5906a64431df49ba72b56a9aa@o4506713764986880.ingest.sentry.io/4506713766428672',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    environment: environment.env,
    release: `myassetmap-frontend@${pkg.version}-${environment.env}`,

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/*myassetmap\.com\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: environment.build !== 'development',
  })
}

export function configureS3() {
  AWS.config.update(authConfig.s3Auth)
}

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: authConfig.cognito.REGION,
      userPoolId: authConfig.cognito.USER_POOL_ID,
      identityPoolId: authConfig.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: authConfig.cognito.APP_CLIENT_ID,
    },
    Storage: {
      region: authConfig.s3.REGION,
      bucket: authConfig.s3.BUCKET,
      identityPoolId: authConfig.cognito.IDENTITY_POOL_ID,
    },
    API: {
      endpoints: [
        {
          name: 'login',
          endpoint: authConfig.apiGateway.URL,
          region: authConfig.apiGateway.REGION,
        },
        {
          name: 'my-asset-map-api',
          endpoint: authConfig.apiGateway.URL2,
          region: authConfig.apiGateway.REGION,
        },
      ],
    },
  })
}

export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket,
    level,
    region: 'us-east-1',
    identityPoolId: authConfig.cognito.IDENTITY_POOL_ID,
  })
}
