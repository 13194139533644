import { useEffect } from 'react'
/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  ref,
  setIsNewCreateMenuVisible,
  setIsNewFolderPopupVisible
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // ignores the actions of the buttons that the pop up opened on top of it
        if (event && event.path && event.path[0] && event.path[0].value) {
          let buttonText = event.path[0].value

          if (buttonText && buttonText.toLowerCase() === 'untitled folder')
            return

          if (event.path[0].childNodes[0])
            buttonText = event.path[0].childNodes[0].data

          if (buttonText && buttonText.toLowerCase() === 'cancel') return
          if (buttonText && buttonText.toLowerCase() === 'create') return
          if (buttonText && buttonText.toLowerCase() === 'new folder') return
          if (buttonText && buttonText.toLowerCase() === 'x') return
          setIsNewCreateMenuVisible(false)
          setIsNewFolderPopupVisible(false)
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
