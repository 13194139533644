import React from 'react'
import northArrow from './NorthArrowIcons/simple.png'
import scss from './NorthArrowElement.scss'
import printScss from '../../Print.scss'

const fullWidthHeight = {
  width: '100%',
  height: '100%',
}
function NorthArrowElement(props) {
  const object = props.object
  const northArrowElements = props.northArrowElements

  const wrapperStyle = {
    borderColor: !object.borderTransparent
      ? `${object.borderColor}`
      : 'transparent',
    borderWidth: object.borderWidth + 'px',
    borderStyle: 'solid',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  const viewport = props.viewport
  const handleElementClick = props.handleElementClick
  return (
    <>
      <div
        style={wrapperStyle}
        className={
          printScss[northArrowElements == object.id ? 'activeEditWindow' : '']
        }
      >
        <div
          className={'northArrow'}
          onClick={handleElementClick}
          style={{
            ...fullWidthHeight,
            border: 'none',
            transform: `rotate(${-viewport.bearing}deg)`,
            background: `url(${northArrow}) 100% 100% no-repeat`,
            backgroundSize: 'contain',
            border: `none`,
          }}
        />
      </div>
    </>
  )
}
export default NorthArrowElement
