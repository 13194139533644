import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from '../../Map/Map.scss'

const MeasureBtn = React.memo(({ toggleMeasure }) => {
  return (
    <button
      className={scss['map-button']}
      onClick={toggleMeasure}
      data-tip='Measure'
      data-for='map-control'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <FontAwesomeIcon icon='ruler' />
    </button>
  )
})

export default MeasureBtn
