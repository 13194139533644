import {
  DOUBLE_CLICK_ZOOM,
  SET_DRAG_PAN,
  SET_MAP_ZOOM,
  SET_SCROLL_ZOOM,
  SET_OVER_PANEL,
  DISABLE_POPUP,
} from '../actions/types'

export function DoubleClickZoom(state = true, action) {
  switch (action.type) {
    case DOUBLE_CLICK_ZOOM: {
      state = action.payload
      return state
    }

    default:
      return state
  }
}

export function SetDragPan(state = true, action) {
  switch (action.type) {
    case SET_DRAG_PAN: {
      state = action.payload
      return state
    }
    default:
      return state
  }
}

export function SetScrollZoom(state = true, action) {
  switch (action.type) {
    case SET_SCROLL_ZOOM: {
      state = action.payload
      return state
    }
    default:
      return state
  }
}

export function SetOverPanel(state = false, action) {
  switch (action.type) {
    case SET_OVER_PANEL: {
      state = action.payload
      return state
    }
    default:
      return state
  }
}

export function SetMapZoom(state = 16, action) {
  switch (action.type) {
    case SET_MAP_ZOOM: {
      state = action.payload
      return state
    }
    default:
      return state
  }
}

export function DisablePopup(state = false, action) {
  switch (action.type) {
    case DISABLE_POPUP: {
      state = action.payload
      return state
    }
    default:
      return state
  }
}
