import { LOGIN, REFRESH, LOGOUT, GET_INFO } from './types'

//Import Helper Functions
import * as utils from '../utilities/util'

export function defaultUser() {
  return {
    UUID: null,
    isLoggedIn: false,

    profile: {
      firstName: '',
      lastName: '',
      displayName: '',
      emailAddress: '',
    },
  }
}

export function Login(user) {
  return {
    type: LOGIN,
    payload: user,
  }
}

export function Refresh(user) {
  return {
    type: REFRESH,
    payload: user,
  }
}

export function Logout() {
  return {
    type: LOGOUT,
    payload: defaultUser(),
  }
}

// export function update(userID){
//   return {
//     type: GET_INFO,
//     payload: userInfo
//   }
// }
