/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import scss from '../../TabTemplate.scss'
import inputScss from './InputStyle.scss'

const CustomTextArea = ({
  CustomTextAreaValue,
  handleAllChanges,
  featureProperties,
  setFeatureProperties,
  propertyKey,
  readOnly,
}) => {
  const [originalValue] = useState(CustomTextAreaValue)
  const [value, setValue] = useState(CustomTextAreaValue)

  return (
    <textarea
      readOnly={readOnly}
      key={propertyKey}
      name={propertyKey}
      className={[scss['attribute-textarea'], inputScss.defaultInput].join(' ')}
      onChange={e => {
        e.preventDefault()
        setValue(e.target.value)
      }}
      placeholder=''
      rows={5}
      value={value || ''}
      onBlur={() => {
        if (originalValue !== value) {
          featureProperties[propertyKey] = value
          setFeatureProperties(featureProperties)
          handleAllChanges()
        }
      }}
    />
  )
}

export default CustomTextArea
