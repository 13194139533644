import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from '../../Map/Map.scss'
import {
  setToolConfig
} from '../../../actions/index'
import { clone } from '../../../utilities/geospatial'
import { useDispatch, useSelector } from 'react-redux'

const MeasureBtn = React.memo(() => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state=>state.toolConfig)
  const handleDraw = () => {
    toggleTool('Draw')
  }

  const toggleTool = name => {
    let config = clone(toolConfig)
    
    config.forEach(tool => {
      if (tool.name === name) {
        tool.visible = !tool.visible
      }
    })
    dispatch(setToolConfig(config))
  }

  return (
    <button
      className={[scss['map-button']].join(' ')}
      onClick={() => handleDraw()}
      data-tip='Draw'
      data-for='map-control'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <FontAwesomeIcon icon='pen-nib' />
    </button>
  )
})

export default MeasureBtn
