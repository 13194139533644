import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
  MuiButton,
} from '../../../../../components/MaterialUi/Tool'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'

import scss from './SaveLayout.scss'
import modalScss from '../../../../CSSModules/Modal.scss'
import appScss from '../../../../App/App.scss'

class SaveLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      layoutName: '',
      validLayoutName: '',
      doingSave: false,
    }
  }

  handleInputChange = e => {
    this.setState({ layoutName: e.target.value })
  }

  handleOnClose = () => {
    this.setState({ open: false })
  }

  onClose = () => this.setState({ doingSave: false })

  validateLayoutName = layoutName => layoutName !== ''

  handleSave = () => {
    const { layoutName } = this.state
    const { doSaveLayout } = this.props
    this.setState({ doingSave: true }, () => {
      doSaveLayout(layoutName)
    })
  }

  render() {
    const { layoutName, doingSave } = this.state
    const { modalOpen, closeModal, message } = this.props

    const validLayoutName = this.validateLayoutName(layoutName)

    const modalStyle = {
      modal: {
        backgroundClip: 'unset',
      },
    }

    return (
      <ThemeProvider theme={mainModalTheme}>
        <Dialog onClose={closeModal} open={modalOpen}>
          <DialogTitle id='save-layout-dialog'>
            Save Layout
            <FontAwesomeIcon
              onClick={closeModal}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent
            className={[
              modalScss.MuiDialogBoxes,
              appScss.flexColumn,
              modalScss.fieldWidths,
              modalScss.addUserModal,
            ].join(' ')}
          >
            <label htmlFor='layoutName' className={modalScss.label}></label>

            <div>
              <MuiFormControlText
                disabled={false}
                label='Layout Name:'
                placeholder='Layout Name'
                onChange={this.handleInputChange}
                onSubmit={doingSave ? null : this.handleSaveClick}
                value={layoutName}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <button
              className={modalScss.modalMainButton}
              onClick={doingSave ? null : this.handleSave}
              style={{ width: '100%', placeContent: 'center' }}
              disabled={!validLayoutName}
            >
              {doingSave ? (
                <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Save'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}

export default SaveLayout
