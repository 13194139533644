import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import scss from './MapPopup.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAttribute } from './Utilities'

import Tabs from '../../components/Tabs/Tabs'

const AttributeTab = ({
  config,
  layerProperties,
  edit,
  featureProperties,
  layerId,
  popupInfo,
  index,
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  handleCurrencyInputChange,
}) => {
  const user = useSelector(state => state.user)
  let tabUi = []

  if (config && layerProperties[layerId]) {
    for (const tab in config.tabs) {
      const rows = []
      // create a new object with all lowercase keys from FeatureProperties
      // we will convert all layer property keys to lowercase to ensure case insensitivity
      let keys = Object.keys(featureProperties)
      let n = keys.length
      let lowerCaseObj = {}
      while (n--) {
        let key = keys[n]
        lowerCaseObj[key.toLowerCase()] = featureProperties[key]
      }
      const label = config.tabs[tab].title
      const tabKey = config.tabs[tab].id
      if (config.tabs[tab].id !== 'propertiesHolder') {
        config.tabs[tab].itemsIds.forEach(id => {
          let property = layerProperties[layerId].filter(
            layerProperty => layerProperty.id === parseInt(id)
          )
          //Note:  if there is no property[0] than the property has been deleted
          // but the popupConfig has not been saved to reflect this change
          if (property[0]) {
            const attr = getAttribute({
              lowerCaseObj,
              property: property[0],
              propertyConfigName: null,
              featureProperties,
              edit,
              popupInfo,
              index,
              layerProperties,
              handleInputChange,
              handleSelectChange,
              handleDateChange,
              handleCurrencyInputChange,
              user,
            })

            if (attr) rows.push(attr)
          }
        })

        tabUi.push(
          <div key={tabKey} label={label}>
            <div className={scss['feature-attribute-grid']}>{rows}</div>
          </div>
        )
      }
    }
  }

  const checkForAttributes = () => {
    if (!layerProperties[layerId]) return false

    let attributeList = layerProperties[layerId].filter(
      attr => attr && attr.type !== 'document'
    )
    return attributeList.length > 0
  }

  return (
    <>
      {config && checkForAttributes() ? (
        <div className={scss['feature-attribute-tab']}>
          <Tabs key={layerId} column={false} customClass='primaryTabs' scroll>
            {tabUi}
          </Tabs>
        </div>
      ) : (
        <div className={scss['feature-empty-grid']}>
          <div className={scss['feature-empty-square']}>
            <FontAwesomeIcon icon='question' />
          </div>
          <span className={scss['feature-empty-title']}>
            No attributes found
          </span>
          <span className={scss['feature-empty-description']}>
            Use the layer settings panel to add attributes, then come back here.
          </span>
          {/* <a>Help?</a> */}
        </div>
      )}
    </>
  )
}

export default AttributeTab
