import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validLatitude, validLongitude } from '../../../../utilities/validation'
import ZoomToPoint from '../../../../components/ZoomToPoint/ZoomToPoint'
import {
  createPointFeature,
  addFeatureToSource,
  getSource,
  emptySource,
} from '../../../../utilities/mapStyle'
import { updateMapStyle } from '../../../../actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './Coordinate.scss'
import { fromJS } from 'immutable'

class Coordinate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      latitude: '',
      longitude: '',
      searching: false,
      zoomToPointKey: null,
      newZoom: null,
    }
  }

  componentDidMount = () => {
    const { mapStyle } = this.props
    let style = mapStyle.toJS()
    let source = getSource(style, 'coordiantes_results')
    const results = source.data.features.map(
      feature => feature.geometry.coordinates
    )
    this.setState({ results })
  }

  handleChange = e => {
    let value = e.target.value
    let valid = true
    if (e.target.name === 'latitude') {
      valid = validLatitude(e.target.value)
    } else {
      valid = validLongitude(e.target.value)
      if (e.target.value === '-') {
        valid = true
      }
    }

    if (e.target.name === 'longitude' && valid && value > 0) value = value * -1

    this.setState({ [e.target.name]: valid ? value : '' })
  }

  doSearch = () => {
    const { latitude, longitude } = this.state
    let { results } = this.state
    if (latitude === '') return
    if (longitude === '') return
    const lat = parseFloat(latitude)
    const lng = parseFloat(longitude)
    const coordinates = [lng, lat]
    this.setState({ searching: true })
    this.doZoomToPoint(coordinates)
    results.push(coordinates)
    this.setState(
      { results, searching: false, latitude: '', longitude: '' },
      () => {
        this.updateCoordinateLayer()
      }
    )
  }

  doZoomToPoint = zoomToCoordinates => {
    let { zoomToPointKey } = this.state
    if (!zoomToPointKey) zoomToPointKey = 0
    zoomToPointKey++
    this.setState({ zoomToPointKey, zoomToCoordinates, newZoom: 14 })
  }

  updateCoordinateLayer = () => {
    const { mapStyle } = this.props
    const { results } = this.state
    let style = mapStyle.toJS()
    let source = getSource(style, 'coordiantes_results')
    source = emptySource(source)
    results.forEach(coordinates => {
      const properties = {
        id: coordinates[0] + coordinates[1],
        latitude: coordinates[0],
        longitude: coordinates[1],
      }
      let feature = createPointFeature(coordinates, properties)
      source = addFeatureToSource(source, feature)
    })
    style.sources['coordiantes_results'] = source
    this.props.updateMapStyle(fromJS(style))
  }

  deleteCoordinate = i => {
    let { results } = this.state
    results.splice(i, 1)
    this.setState({ results }, () => {
      this.updateCoordinateLayer()
    })
  }

  render() {
    const {
      results,
      zoomToPointKey,
      zoomToCoordinates,
      newZoom,
      searching,
      latitude,
      longitude,
    } = this.state
    const { viewport } = this.props
    const latStyle = {
      border: 'none',
      borderRight: 'solid 1px',
      outline: 'none',
      borderRadius: '4px 0px 0px 4px',
      width: '100%',
      height: '40px',
      padding: '0 12px',
      fontSize: '16px',
      letterSpacing: '-0.43px',
      color: '#fff',
    }
    const lngStyle = {
      border: 'none',
      borderRight: 'solid 1px',
      outline: 'none',
      borderRadius: '0px',
      width: '100%',
      height: '40px',
      padding: '0 12px',
      fontSize: '16px',
      letterSpacing: '-0.43px',
      color: '#fff',
    }

    const resultList = results.map((result, index) => {
      const item = `Latitude: ${result[1]} Longitude: ${result[0]}`
      return (
        <div key={index}>
          <span
            className={scss.coordinateSpan}
            onClick={() => this.doZoomToPoint(result)}
          >
            <FontAwesomeIcon icon={['fal', 'map-pin']} size='1x' />
            {item}
          </span>
          <div>
            <button
              className={scss.btn}
              onClick={() => this.deleteCoordinate(index)}
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} size='1x' />
            </button>
          </div>
        </div>
      )
    })

    const searchIcon = searching ? (
      <FontAwesomeIcon icon={'spinner'} size='1x' spin />
    ) : (
      <FontAwesomeIcon icon={['fal', 'map-marker']} size='1x' />
    )
    return (
      <div>
        <ZoomToPoint
          key={zoomToPointKey}
          viewport={viewport}
          coordinates={zoomToCoordinates}
          zoom={newZoom}
        />

        <div className={scss.coordinateWrapper}>
          <div className={scss.addOnGroup}>
            <input
              autoComplete='off'
              name='latitude'
              value={latitude ? latitude : ''}
              onChange={this.handleChange}
              style={latStyle}
              placeholder='Enter Latitude'
            />
            <input
              autoComplete='off'
              name='longitude'
              value={longitude}
              onChange={this.handleChange}
              style={lngStyle}
              placeholder='Enter Longitude'
            />
            <button
              onClick={this.doSearch}
              className={scss.coordinateSearchBtn}
            >
              {' '}
              {searchIcon}
            </button>
          </div>
        </div>
        {resultList.length > 0 ? (
          <div className={scss.resultContainer}>
            <div className={scss.resultList}>{resultList}</div>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(Coordinate)
