export const selectStyle = {
  control: provided => ({
    ...provided,
    maxHeight: '32px',
    marginRight: '10px',
    backgroundColor: '#213445',
    border: 'solid 1px #314d68',
    borderRadius: '4px',
    color: '#fff',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
    top: '40%',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    color: '#fff0',
    marginBottom: '4px',
    marginTop: '0px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    color: '#fff',
    marginBottom: '8px',
  }),
  indicatorsSeparator: provided => ({
    ...provided,
    color: '#fff0',
  }),
}

export const selectPrintStyle = {
  control: provided => ({
    ...provided,
    minHeight: '35px',
    maxHeight: '35px',
    marginRight: '0px',
    border: '1px solid #aaaaaa',
    borderRadius: '4px 0px 0px 4px',
    backgroundColor: '#213445',
  }),
  singleValue: provided => ({
    ...provided,
    top: '35%',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    color: '#fff0',
    marginBottom: '5px',
    marginTop: '0px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    marginBottom: '8px',
  }),
  indicatorsSeparator: provided => ({
    ...provided,
    color: '#fff0',
  }),
}

export const selectStylePopup = {
  control: provided => ({
    ...provided,
    maxHeight: '30px',
    minHeight: '30px',
    marginRight: '0px',
    backgroundColor: '#213445',
    border: 'solid 1px #314d68',
  }),
  singleValue: provided => ({
    ...provided,
    top: '40%',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    color: '#fff0',
    marginBottom: '10px',
    marginTop: '0px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    marginBottom: '0px',
  }),
  indicatorsSeparator: provided => ({
    ...provided,
    color: '#fff0',
  }),
}
