import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Modal from "react-responsive-modal";

import scss from './ConfirmDelete.scss'
import modalScss from '../../../../CSSModules/Modal.scss'
import appScss from '../../../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../../../components/MaterialUi/Tool'
import { MuiButton } from '../../../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

class ConfirmDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      doingDelete: false,
    }
  }

  deleteConfirmed = () => {
    const { doDeleteLayout } = this.props
    this.setState({ doingDelete: true }, () => {
      doDeleteLayout()
    })
  }

  onClose = () => this.setState({ doingDelete: false })

  render() {
    const { doingDelete } = this.state
    const { modalOpen, closeModal, message } = this.props
    const modalStyle = {
      modal: {
        backgroundClip: 'unset',
      },
    }
    return (
      <ThemeProvider theme={mainModalTheme}>
        <Dialog onClose={closeModal} open={modalOpen}>
          <DialogTitle id='delete-layout-dialog'>
            Delete Layout
            <FontAwesomeIcon
              onClick={closeModal}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent className={modalScss.MuiDialogBoxes}>
            <div>{message}</div>
          </DialogContent>

          <DialogActions>
            <div className={scss.modalSaveButton}>
              <button
                className={appScss.blueButton}
                onClick={doingDelete ? null : this.deleteConfirmed}
              >
                {doingDelete ? (
                  <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
                ) : (
                  'Confirm'
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}
export default ConfirmDelete
