export const STYLE_EXPRESSION_SEARCH_COORDINATE = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  16,
  8,
  18,
  10,
  20,
  13,
  22,
  15,
  24,
]

export const STYLE_EXPRESSION_SEARCH_GEOCODE = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  16,
  8,
  18,
  10,
  20,
  13,
  22,
  15,
  24,
]
