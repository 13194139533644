import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'

import { PDF_STYLE_SHEET } from './PrintConstants.js'
import northArrowImage from './LayoutElements/NorthArrowElement/NorthArrowIcons/simple.png'
import ViewerLegend from './ViewerElements/ViewerLegend'
const Viewer = ({
  mapStyle,
  dataConfig,
  viewport,
  orientPortrait,
  pageWidth,
  pageSize,
  elements,
  mapImage,
  mapboxobj,
  excludedLayers,
}) => {
  const pdfViewStyle = {
    display: 'flex',
    justifyContent: 'center',
  }

  const viewerHeight = window.innerHeight - 125

  return (
    <div style={pdfViewStyle}>
      <PDFViewer width={pageWidth} height={viewerHeight}>
        <Document>
          <Page
            style={PDF_STYLE_SHEET.body}
            orientation={orientPortrait ? 'portrait' : 'landscape'}
            size={pageSize}
          >
            {!!elements.length &&
              elements.map(object => {
                let textAlign = 'left'

                if (object.horizontalAligment === 'center') {
                  textAlign = 'center'
                }
                if (object.horizontalAligment === 'flex-end') {
                  textAlign = 'right'
                }

                return (
                  (object.type === 'map' && (
                    <Image
                      key={object.id}
                      style={[
                        PDF_STYLE_SHEET.map,
                        {
                          top: object.y,
                          left: object.x,
                          width: object.width, // - object.borderWidth * 2,
                          height: object.height, // - object.borderWidth * 2,
                          border:
                            !object.borderTransparent &&
                            `${object.borderWidth}pt solid ${object.borderColor})`,
                        },
                      ]}
                      src={`${mapImage}`}
                    />
                  )) ||
                  (object.type === 'northArrow' && (
                    <Image
                      key={object.id}
                      style={{
                        ...PDF_STYLE_SHEET.image,
                        top: object.y,
                        left: object.x,
                        width: object.width,
                        height: object.height,
                        transform: `rotate(${-mapboxobj.transform.bearing}deg)`,
                        border:
                          !object.borderTransparent &&
                          `${object.borderWidth}pt solid ${object.borderColor})`,
                      }}
                      src={northArrowImage}
                    />
                  )) ||
                  (object.type === 'img' && (
                    <Image
                      key={object.id}
                      style={[
                        PDF_STYLE_SHEET.image,
                        {
                          top: object.y,
                          left: object.x,
                          width: object.width - object.borderWidth * 2,
                          height: object.height - object.borderWidth * 2,
                          border:
                            !object.borderTransparent &&
                            `${object.borderWidth}pt solid ${object.borderColor})`,
                        },
                      ]}
                      src={object.imagePreviewUrl}
                    />
                  )) ||
                  (object.type === 'text' && (
                    <View
                      key={object.id + '_view'}
                      style={[
                        {
                          display: 'flex',
                          position: 'absolute',
                          top: object.y,
                          left: object.x,
                          width: object.width,
                          height: object.height,
                          border:
                            !object.borderTransparent &&
                            `${object.borderWidth}pt solid ${object.borderColor})`,
                          backgroundColor:
                            !object.bgTransparent && `${object.bgColor}`,

                          justifyContent: object.verticalAligment,
                          textAlign: `${textAlign}`,
                        },
                      ]}
                    >
                      <Text
                        key={object.id}
                        style={[
                          {
                            fontFamily: object.fontFamily,
                            position: 'absolute',
                            fontWeight: object.fontWeight,
                            fontStyle: object.fontStyle,
                            textDecoration: object.textDecoration,
                            color: object.textColor,
                            fontSize: object.fontSize * 0.75, // multiplying the pixels into points with 0.75
                          },
                        ]}
                      >
                        {object.text}
                      </Text>
                    </View>
                  )) ||
                  (object.type === 'date' && (
                    <Text
                      key={object.id}
                      style={[
                        PDF_STYLE_SHEET.text,
                        {
                          top: object.y,
                          left: object.x,
                          fontWeight: object.fontWeight,
                          fontStyle: object.fontStyle,
                          textDecoration: object.textDecoration,
                          textAlign: object.textAlign,
                          color: object.textColor,
                          backgroundColor:
                            !object.bgTransparent && `${object.bgColor}`,
                          fontSize: object.fontSize * 0.75, // multiplying the pixels into points with 0.75
                          padding: object.padding,
                          width: object.width,
                          height: object.height,
                          margin: 0,
                          border:
                            !object.borderTransparent &&
                            `${object.borderWidth}pt solid ${object.borderColor})`,
                        },
                      ]}
                    >
                      {object.text}
                    </Text>
                  )) ||
                  (object.type === 'scaleBar' && (
                    <View
                      key={object.id + '_view'}
                      style={[
                        {
                          position: 'absolute',
                          top: object.y,
                          left: object.x,
                          height: `50px`,
                        },
                      ]}
                    >
                      <View>
                        <Text
                          key={object.id}
                          style={[
                            {
                              fontFamily: object.fontFamily,
                              position: 'absolute',
                              fontWeight: object.fontWeight,
                              fontStyle: object.fontStyle,
                              textDecoration: object.textDecoration,
                              color: object.textColor,
                              fontSize: object.fontSize * 0.75, // multiplying the pixels into points with 0.75
                            },
                          ]}
                        >
                          {object.distance}
                        </Text>
                      </View>
                      <View>
                        <View
                          style={{
                            position: `absolute`,
                            top: `12px`,
                            backgroundColor: `white`,
                            height: `10px`,
                            width: `${object.maxWidth * object.ratio}px`,
                            border: `1pt solid black`,
                          }}
                        ></View>
                      </View>
                    </View>
                  )) ||
                  (object.type === 'legend' && (
                    <ViewerLegend
                      mapStyle={mapStyle}
                      dataConfig={dataConfig}
                      viewport={viewport}
                      excludedLayers={excludedLayers}
                      object={object}
                      style={[
                        PDF_STYLE_SHEET.text,
                        {
                          fontFamily: object.fontFamily,
                          color: object.textColor,

                          border:
                            !object.borderTransparent &&
                            `${object.borderWidth}pt solid ${object.borderColor})`,
                          backgroundColor:
                            !object.bgTransparent && `${object.bgColor}`,

                          padding: object.padding,
                          width: object.width,
                          height: object.height,
                        },
                      ]}
                    />
                  ))
                )
              })}
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default Viewer
