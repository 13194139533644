import {
  SET_VIEWPORT,
  SET_TOOL_CONFIG,
  DOUBLE_CLICK_ZOOM,
  SET_DRAG_PAN,
  CREATE_POPUP,
  SET_MAP_CURSOR,
  SET_MAP_MODE,
  UPDATE_DATA_CONFIG,
  SWITCH_PAGE,
  MOUNT_LAYER_SETTINGS,
  SET_MAP_ZOOM,
  MOUNT_MAP_VIEWS,
  OPEN_NOTES,
  TOC_COLLAPSED,
  SET_SCROLL_ZOOM,
  SET_OVER_PANEL,
  DISABLE_POPUP,
  SET_DRAW_MODE,
  SET_MEASURE_MODE,
} from './types'

export function setAccountKey(viewport) {
  return {
    type: 'SET_ACCOUNT_KEY',
    payload: { viewport: viewport },
  }
}

export function setViewPort(viewport) {
  return {
    type: SET_VIEWPORT,
    payload: { viewport: viewport },
  }
}

export function setToolConfig(toolConfig) {
  return {
    type: SET_TOOL_CONFIG,
    payload: toolConfig,
  }
}

export function emptyDrawSources(drawSources) {
  return null
}

export function setAppMeasureMode(mode) {
  return {
    type: SET_MEASURE_MODE,
    payload: mode,
  }
}

export function setDoubleClickZoom(value) {
  return {
    type: DOUBLE_CLICK_ZOOM,
    payload: value,
  }
}

export function setDragPan(value) {
  return {
    type: SET_DRAG_PAN,
    payload: value,
  }
}

export function setScrollZoom(value) {
  return {
    type: SET_SCROLL_ZOOM,
    payload: value,
  }
}

export function setOverPanel(value) {
  return {
    type: SET_OVER_PANEL,
    payload: value,
  }
}

export function createPopup(popupInfo) {
  return {
    type: CREATE_POPUP,
    payload: popupInfo,
  }
}

export function setMapCursor(cursor) {
  return {
    type: SET_MAP_CURSOR,
    payload: cursor,
  }
}

export function setMapMode(mode) {
  return {
    type: SET_MAP_MODE,
    payload: mode,
  }
}

export function updateDataConfig(dataConfig) {
  return {
    type: UPDATE_DATA_CONFIG,
    payload: dataConfig,
  }
}

export function switchPage(page) {
  return {
    type: SWITCH_PAGE,
    payload: page,
  }
}

export function mountLayerSettings(key) {
  return {
    type: MOUNT_LAYER_SETTINGS,
    payload: key,
  }
}

export function mountMapViews(key) {
  return {
    type: MOUNT_MAP_VIEWS,
    payload: key,
  }
}

export function setMapZoom(zoom) {
  return {
    type: SET_MAP_ZOOM,
    payload: zoom,
  }
}

export function openNotes(notes) {
  return {
    type: OPEN_NOTES,
    payload: notes,
  }
}

export function setToc(collapsed) {
  return {
    type: TOC_COLLAPSED,
    payload: collapsed,
  }
}

export function disablePopup(value) {
  return {
    type: DISABLE_POPUP,
    payload: value,
  }
}

export function setMapRef(mapRef) {
  return {
    type: 'SET_MAP_REF',
    payload: mapRef,
  }
}

export function setFooterHeight(height) {
  return {
    type: 'SET_FOOTER_HEIGHT',
    payload: height,
  }
}

export function setAsideWidth(width) {
  return {
    type: 'SET_ASIDE_WIDTH',
    payload: width,
  }
}

export function setDataTable(config) {
  return {
    type: 'SET_DATA_TABLE',
    payload: config,
  }
}

export function setSelectFeatures(select) {
  return {
    type: 'SET_SELECT_FEATURES',
    payload: select,
  }
}

export function setPopupDocked(docked) {
  return {
    type: 'SET_POPUP_DOCKED',
    payload: docked,
  }
}

export function setLayerLabels(labelArray) {
  return {
    type: 'SET_LAYER_LABELS',
    payload: labelArray,
  }
}

export function setMapToolTip(showTip) {
  return {
    type: 'SET_MAP_TOOL_TIP',
    payload: showTip,
  }
}

export function setVisibleLayers(layerIds) {
  return {
    type: 'SET_VISIBLE_LAYERS',
    payload: layerIds,
  }
}

export function setTocLayersFilter(searchTerm) {
  return {
    type: 'SET_TOC_LAYERS_FILTER',
    payload: searchTerm,
  }
}

export function groupClicked() {
  return {
    type: 'GROUP_CLICKED',
  }
}

export function layerClicked() {
  return {
    type: 'LAYER_CLICKED',
  }
}

export function filterChanged() {
  return {
    type: 'FILTER_CHANGED',
  }
}

export * from './actions_user'
export * from './actions_alert'
export * from './actions_map_style'
export * from './actions_draw'
export * from './actions_export'
export * from './actions_geolocate'
