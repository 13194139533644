/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CurrencyInput from 'react-currency-input-field'
import isEqual from 'react-fast-compare'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ThemeProvider } from '@material-ui/core/styles'
import { apiFetch } from '../../../../utilities/api'
import { apis } from '../../../../config/apiConfig'

import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
// utilities
import { processDateFormat } from '../../../Profile/ProfileDate'

// scss files
import scss from './Attributes.scss'
import './LayerAttributes.css'
import appScss from '../../../App/App.scss'
import 'react-datepicker/dist/react-datepicker.css'

import { fieldTypes } from './Attributes'

const EditAttributeV2 = ({
  config,
  targetProperty,
  getDropDownOptionList,
  cancelEdit,
  getProperties,
  finishUpdate,
}) => {
  const user = useSelector(state => state.user)

  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [propertyDefault, setPropertyDefault] = useState(targetProperty.default)
  const [documentDropdown, setDocumentDropdown] = useState('feature')
  const [defaultTitleForEdit, setDefaultTitleForEdit] = useState('Default')
  const [displayDeleteTabPopup, setDisplayDeleteTabPopup] = useState(false)
  const [propertyName, setPropertyName] = useState(targetProperty.name)
  const [originalPropertyName] = useState(targetProperty.name)

  const [originalPropertyType] = useState(targetProperty.type)
  const [propertyType, setPropertyType] = useState(targetProperty.type)

  const [displaySave, setDisplaySave] = useState(false)

  useEffect(() => {
    if (
      propertyName &&
      originalPropertyName &&
      propertyType &&
      originalPropertyType
    ) {
      if (
        !isEqual(propertyName, originalPropertyName) ||
        !isEqual(propertyType, originalPropertyType)
      ) {
        setDisplaySave(true)
      } else {
        setDisplaySave(false)
      }
    }
  }, [propertyName, propertyType])

  useEffect(() => {
    if (
      targetProperty &&
      targetProperty.type &&
      targetProperty.type.includes('document')
    ) {
      if (targetProperty.type.includes('(Layer)')) {
        setDocumentDropdown('Layer')
      } else {
        setDocumentDropdown('Feature')
      }
    } else {
      setPropertyName(targetProperty.type)
    }
  }, [])

  const handleCurrencyChange = (e, maskedValue, floatValue) => {
    if (maskedValue === '') {
      setPropertyDefault(null)
    } else setPropertyDefault(floatValue)
  }

  const updateProperty = () => {
    if (propertyName === '') {
      setError('name')
      return
    }
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'layer/properties/add'
    if (targetProperty && targetProperty.key)
      url = apis['apiDatabase'].uri + 'layer/properties/update'

    let value = targetProperty.value

    if (value) {
      if (propertyType === 'dropdown') {
        if (!Array.isArray(targetProperty.value)) {
          if (value === '') {
            value === []
          } else {
            // Split text area into new line and remove invalid attributes
            value = targetProperty.value.split('\n').filter(val => val !== '')
          }
        }
      } else {
        if (Array.isArray(targetProperty.value)) {
          //value = value[0]
        }
      }
    }

    const bodyParams = {
      layerID: layerId,
      name: propertyName,
      type: propertyType,
      default: propertyDefault,
      value,
    }

    if (
      (documentDropdown === 'Feature' || documentDropdown === 'feature') &&
      propertyType === 'document'
    )
      bodyParams.type = 'document (Feature)'
    else if (
      (documentDropdown === 'Layer' || documentDropdown === 'layer') &&
      propertyType === 'document'
    )
      bodyParams.type = 'document (Layer)'

    if (targetProperty && targetProperty.key)
      bodyParams['key'] = targetProperty.key

    setSaving(true)
    apiFetch(url, method, bodyParams, result => {
      getProperties()
      finishUpdate()
    })
  }

  useEffect(() => {
    if (
      propertyType === 'document (Layer)' ||
      propertyType === 'document (Feature)'
    ) {
      setDefaultTitleForEdit('Level')
    } else {
      setDefaultTitleForEdit('Default')
    }
  }, [propertyType])

  const getDefaultEditor = () => {
    if (['document (Layer)', 'document (Feature)'].includes(propertyType)) {
      return (
        <div className={scss.globalCustomSelect}>
          <select
            id='property-default'
            value={documentDropdown}
            onChange={e => {
              setPropertyType('document (' + e.target.value + ')')
              setDocumentDropdown(e.target.value)
            }}
          >
            <option key='1' value='Feature'>
              Feature
            </option>
            <option key='2' value='Layer'>
              Layer
            </option>
          </select>
          <FontAwesomeIcon
            icon='chevron-down'
            size='1x'
            pull='right'
            className={scss.globalCustomSelectIcon}
          />
        </div>
      )
    }
    if (propertyType === 'date') {
      if (propertyDefault === null || propertyDefault instanceof Date) {
        const userDateFormat = processDateFormat(user.profile.dateFormat)

        return (
          <DatePicker
            name={targetProperty.key}
            selected={propertyDefault}
            onChange={date => setPropertyDefault(date)}
            dateFormat={userDateFormat}
            isClearable
          />
        )
      }
      return null
    }
    if (propertyType === 'currency') {
      // $ has to be removed because it breaks the defaultValue property
      if (propertyDefault.includes('$'))
        setPropertyDefault(propertyDefault.replace('$', ''))
      return (
        <CurrencyInput
          placeholder='$0.00'
          name='default'
          defaultValue={propertyDefault || ''}
          prefix='$'
          onValueChange={value => {
            setPropertyDefault(value ? '$' + value : '')
          }}
          style={{ width: '100%', textAlign: 'left' }}
          decimalsLimit={2}
          allowDecimals
        />
      )
    }
    if (propertyType === 'longtext') {
      return (
        <textarea
          id='property-default'
          name='property-default'
          value={propertyDefault ? propertyDefault : ''}
          onChange={e => setPropertyDefault(e.target.value)}
          autoComplete='off'
          rows={5}
          style={{ maxHeight: '300px' }}
        />
      )
    }
    return (
      <input
        id='property-default'
        name='property-default'
        value={propertyDefault ? propertyDefault : ''}
        onChange={e => setPropertyDefault(e.target.value)}
        autoComplete='off'
      />
    )
  }

  useEffect(() => {
    if (propertyType === 'date' && propertyDefault && propertyDefault !== '') {
      let newDate = new Date(propertyDefault)
      setPropertyDefault(newDate)
    }
  }, [])

  const nameError = error === 'name' ? 'error' : ''
  const dropDownOptions =
    propertyType === 'dropdown' ? getDropDownOptionList() : null
  const defaultEditor = getDefaultEditor()

  return (
    <>
      {displayDeleteTabPopup && (
        <ThemeProvider theme={mainModalTheme}>
          <Dialog
            onClose={() => {
              setDisplayDeleteTabPopup(false)
            }}
            open={displayDeleteTabPopup}
          >
            <DialogTitle>Delete attribute</DialogTitle>

            <DialogContent className={mainModalTheme.MuiDialogBoxes}>
              <div>
                <p>
                  Are you sure you want to delete this attribute? (this process
                  cannot be undone)
                </p>
              </div>
            </DialogContent>

            <DialogActions>
              <button
                className={appScss.altBlueButton}
                type='button'
                onClick={() => {
                  setDisplayDeleteTabPopup(false)
                }}
              >
                Cancel
              </button>
              <button
                className={appScss.blueButton}
                type='button'
                onClick={() => {
                  setDisplayDeleteTabPopup(false)
                }}
              >
                Delete
              </button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}
      <div className={[scss.flexColumn, scss.layerPropertiesEdit].join(' ')}>
        <span
          className={scss.layerPropertiesEditText}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!targetProperty.key ? 'Create New Attribute' : 'Edit Attribute'}
          <button
            type='button'
            className={scss.EraseAttribute}
            onClick={() => {
              setDisplayDeleteTabPopup(true)
            }}
          >
            <FontAwesomeIcon icon='trash-alt' />
          </button>
        </span>
        <div
          className={[scss.flexColumn, scss.layerPropertiesEditInputs].join(
            ' '
          )}
        >
          <label htmlFor='property-name' className={scss.globalLabel}>
            Name
            <input
              id='property-name'
              name='name'
              value={propertyName}
              onChange={e => {
                let propertyNameInput = e.target.value
                // The back-end creates scripts with this name so we restrict it to normal characters
                propertyNameInput = propertyNameInput.replace(/[^\w\s]/gi, '')

                setPropertyName(propertyNameInput)
              }}
              className={scss[nameError]}
            />
          </label>

          {!['document (Layer)', 'document (Feature)'].includes(
            propertyType
          ) ? (
            <label
              htmlFor='property-type'
              className={scss.globalLabel}
              style={{ marginBottom: 0 }}
            >
              Type
              <div className={scss.globalCustomSelect}>
                <select
                  id='property-type'
                  value={propertyType}
                  onChange={e => setPropertyType(e.target.value)}
                >
                  {fieldTypes.map(property => (
                    <option key={property.value} value={property.value}>
                      {property.label}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon
                  icon='chevron-down'
                  size='1x'
                  pull='right'
                  className={scss.globalCustomSelectIcon}
                />
              </div>
              {dropDownOptions}
            </label>
          ) : null}

          {/* Specific Inputs for Dates and Currency */}
          {
            <label htmlFor='property-default' className={scss.globalLabel}>
              {defaultTitleForEdit}
              {defaultEditor}
            </label>
          }

          {/* Cancel and Save Buttons */}
          <div
            className={[scss.flexRow, scss.flexCenter, scss.flexJustify].join(
              ' '
            )}
          >
            <button
              type='button'
              className={appScss.altBlueButton}
              onClick={cancelEdit}
            >
              Cancel
            </button>

            {displaySave ? (
              <button
                type='button'
                className={appScss.blueButton}
                onClick={saving ? null : updateProperty}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default EditAttributeV2
