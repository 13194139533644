import React, { useState, useEffect } from 'react'
import appScss from '../../../../App/App.scss'
import dropdownScss from '../../../../CSSModules/Dropdown.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import { ReactComponent as PortraitIcon } from '../../../../../assets/icons/print/OrientationPortrait.svg'
import { ReactComponent as LandscapeIcon } from '../../../../../assets/icons/print/OrientationLandscape.svg'
import ReactSelect from '../../../../../components/ReactSelect/ReactSelect'
import * as util from '../../../../../utilities/util'

import settingsScss from './PrintSettings.scss'
import scss from '../../Print.scss'
import modalScss from '../../../../CSSModules/Modal.scss'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  toggleOrient,
  orientPortrait,
  showPrintSettings,
  dpi,
  setDPI,
  setPageSize,
  pageSize,
  onViewportChange,
  viewport,
}) {
  const [open, setOpen] = useState(true)
  const [urlObjects, setUrlObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [pageSizeOptions, setPageSizeOptions] = useState([
    { label: 'Letter - 8.5x11', value: 'LETTER' },
    { label: 'Legal - 8.5x14', value: 'LEGAL' },
    { label: 'Tabloid - 11x17', value: 'TABLOID' },
  ])
  const [initialPageSize, setInitialPageSize] = useState(
    util.objectKeyVal(pageSize, pageSizeOptions, 'value')
  )
  const [dpiOptions, setDPIOptions] = useState([
    { label: '300ppi - Print', value: 300 },
    { label: '240ppi - Print', value: 240 },
    { label: '72ppi - Web', value: 72 },
  ])
  const [initialDPI, setInitialDPI] = useState(
    util.objectKeyVal(dpi, dpiOptions, 'value')
  )

  const handleCancel = () => {
    setOpen(false)
    showPrintSettings(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    showPrintSettings(false)
  }

  const handleDPIChange = newDPI => {
    Object.defineProperty(window, 'devicePixelRatio', {
      get: function () {
        return newDPI / 96
      },
    })
    // Add a 0.001 zoom for update map
    onViewportChange({ ...viewport, zoom: viewport.zoom + 0.001 })
    setDPI(newDPI)
  }

  useEffect(
    function () {
      setUrlObjects(null)
    },
    [urlObjects]
  )
  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='print-settings-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='print-settings-dialog'>
          Settings
          <FontAwesomeIcon icon='times' onClick={handleOnClose} />
        </DialogTitle>

        <DialogContent>
          <div className={settingsScss['print-settings-grid']}>
            {/* Page Size and DPI */}
            <div className={settingsScss['print-settings-grid-left']}>
              <label className={modalScss['modal-primary-label']}>
                Output Size
                <ReactSelect
                  options={pageSizeOptions}
                  onChange={setPageSize}
                  defaultValue={initialPageSize}
                  isFixed
                />
              </label>
              <label
                className={modalScss['modal-primary-label']}
                style={{ marginTop: '16px' }}
              >
                Resolution
                <ReactSelect
                  options={dpiOptions}
                  onChange={handleDPIChange}
                  defaultValue={initialDPI}
                  isFixed
                />
              </label>
            </div>
            {/* Orientation */}
            <div className={settingsScss['print-settings-grid-right']}>
              <label className={modalScss['modal-primary-label']}>
                Orientation
                <ButtonGroup
                  className={scss['mui-double-toggle-button']}
                  variant='contained'
                  color='primary'
                  aria-label='text primary button group'
                >
                  <Button
                    onClick={orientPortrait == true ? toggleOrient : null}
                  >
                    <LandscapeIcon className={scss.landscapeIcon} />
                  </Button>
                  <Button
                    onClick={orientPortrait == false ? toggleOrient : null}
                  >
                    <PortraitIcon className={scss.portraitIcon} />
                  </Button>
                </ButtonGroup>
              </label>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <button className={modalScss.modalAltButton} onClick={handleCancel}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default props => {
  return <SimpleDialog {...props} />
}
