import { useEffect } from 'react'
import { Storage, API } from 'aws-amplify'
import authConfig from '../../../../config/authConfig'
/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref, setIsNewFolderPopupVisible) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // ignores the actions of the buttons that the pop up opened on top of it

        setIsNewFolderPopupVisible(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const renameFile = async (cwd, oldName, newName) => {
  const srcKey = 'public/' + cwd + '/' + oldName
  const destKey = 'public/' + cwd + '/' + newName

  const token = localStorage.getItem('token')
  const init = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      bucketName: authConfig.s3.BUCKET,
      sourcePath: srcKey,
      destinationPath: destKey,
    },
  }

  const res = await API.post('my-asset-map-api', '/renameobject', init)

  return res
}
