import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSnapSettings } from '../../../../../../../actions'
import ReactSelect from '../../../../../../../components/ReactSelect/ReactSelect'

const LayerSelect = () => {
  const dispatch = useDispatch()
  const dataConfig = useSelector(state => state.updateDataConfig)
  const snappingLayer = useSelector(state => state.snapSettings.snapLayer)
  const snappingLayers = []

  const processTocObject = tocObject => {
    if (tocObject.toc) {
      if (tocObject.toc.userLayer) {
        const snappingIds = []
        tocObject.layersArray.forEach(layerObj => {
          // we need to get layer ids (not the toc object layer id) to be used from snapping
          // we only want to snap to lines and points
          if (layerObj.layer && layerObj.layer.type === 'line') {
            snappingIds.push(layerObj.layer.id)
          }
          if (layerObj.layer && layerObj.layer.type === 'circle') {
            snappingIds.push(layerObj.layer.id)
          }
          if (layerObj.symbol && layerObj.symbol.type === 'symbol') {
            snappingIds.push(layerObj.symbol.id)
          }
          // for fill, we only want to snap to outline
          if (layerObj.outline && layerObj.outline.type === 'line') {
            snappingIds.push(layerObj.outline.id)
          }
        })
        snappingLayers.push({
          value: tocObject.toc.id,
          label: tocObject.toc.label,
          type: tocObject.layersArray[0].layer.type,
          snappingIds,
        })
      }
    } else {
      tocObject.children.items.forEach(tocEachObject => {
        processTocObject(tocEachObject)
      })
    }
  }

  dataConfig.tocLayers.forEach(tocObject => {
    processTocObject(tocObject)
  })

  snappingLayers.unshift({
    value: 'none',
    label: 'Select Snapping Layer',
    type: 'none',
  })

  const handleChange = e => {
    const selected = snappingLayers.filter(layer => layer.value === e.value)
    if (selected[0].value === 'none') {
      dispatch(setSnapSettings({ snapLayer: false }))
    } else {
      dispatch(setSnapSettings({ snapLayer: selected[0] }))
    }
  }

  return (
    <div>
      <ReactSelect
        style={{ borderRadius: snappingLayer ? '4px' : '0px' }}
        options={snappingLayers}
        onChange={(values, obj) => handleChange(obj)}
        defaultValue={snappingLayer || ''}
        isFixed
        placeholder='Select Snap Layer'
      />
    </div>
  )
}

export default LayerSelect
