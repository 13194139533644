import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../../../utilities/geospatial'
import * as mapUtils from '../../../../../utilities/mapStyle'
import {
  updateMapStyle,
  setDoubleClickZoom,
  setMapToolTip,
} from '../../../../../actions'
import { getHotLayers, getSnappingGeom, getSnapPoint } from '../../Utils'

import { HOT_SOURCES } from '../../Constants'

const New = ({ geometry, mapRef }) => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const mapToolTip = useSelector(state => state.mapToolTip)
  const overPanel = useSelector(state => state.setOverPanel)
  const snapLayer = useSelector(state => state.snapSettings.snapLayer)
  const snapLine = useSelector(state => state.snapSettings.snapLine)
  const snapVertex = useSelector(state => state.snapSettings.snapVertex)
  const measureLine = useSelector(state => state.drawMeasureSettings.measureLine)
  const [mouseDownCenter, setMouseDownCenter] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)
  const hotLayers = getHotLayers(geometry)

  const addPointToFeature = e => {
    const style = mapStyle.toJS()
    // this function is called after mouse up
    // a point on the map has been clicked, add it to the relevant draw data sources
    let newPoint = geoUtils.getPointAtClick(e, viewport)
    if (snapLayer) {
      if (snapLine || snapVertex) {
        const snappingGeom = getSnappingGeom(e, mapRef, snapLayer)
        if (snappingGeom) {
          newPoint = getSnapPoint(snappingGeom, newPoint, snapLine, snapVertex)
        }
      }
    }
 
    let verticies = mapUtils.getSource(style, 'draw_verticies_hot')

    // add new point to verticies
    verticies.data.features.push(newPoint)
    // get all coords from verticies, these will be used to create all new geometries
    let allCoords = geoUtils.getAllCoords(verticies.data)
    // update verticies source
    style.sources['draw_verticies_hot'] = verticies
    if (geometry === 'line' && allCoords.length > 1) {
      let line = geoUtils.getLineString(allCoords)
      style.sources['draw_line_hot'].data = {
        type: 'FeatureCollection',
        features: [line],
      }
      if(measureLine){
        //TODO - Draw Measurements are is WIP
        let length = geoUtils.getLength(line, 'miles')
        let linearLabel = "miles"
        let midPoint = geoUtils.getLineMidPoint(line)
        midPoint.properties.measureType = "lineSegments"
        midPoint.properties.measurement =
          length.toFixed(2) + ` ${linearLabel}`
          style.sources['draw_text_cold'].data.features.push(midPoint)
      }
    }
    if (geometry === 'fill' && allCoords.length > 2) {
      allCoords.push(allCoords[0])
      let polygon = geoUtils.getPolygon([allCoords])
      let line = geoUtils.getLineString(allCoords)
      style.sources['draw_polygon_hot'].data = {
        type: 'FeatureCollection',
        features: [polygon],
      }
      style.sources['draw_polygon_outline_hot'].data = {
        type: 'FeatureCollection',
        features: [line],
      }
    }
    if (geometry === 'circle' && allCoords.length > 0) {
      let coldSourceId = 'draw_verticies_cold'
      let coldSource = mapUtils.getSource(style, coldSourceId)
      let hotSource = mapUtils.getSource(style, 'draw_verticies_hot')
      let coldDataIndex = null
      mapUtils.moveHotToCold(hotSource, coldSource, coldDataIndex)
      style.sources['draw_verticies_hot'] = hotSource
      style.sources['draw_verticies_cold'] = coldSource
    }
    if (geometry === 'line' && allCoords.length > 0) {
      showDrawToolTip()
    }
    if (geometry === 'fill' && allCoords.length > 1) {
      showDrawToolTip()
    }

    // if there is data in verticies we want to disable doubleClick zoom
    if (verticies.data.features.length === 1) {
      dispatch(setDoubleClickZoom(false))
    }
    dispatch(updateMapStyle(fromJS(style)))
  }

  const updateFeature = e => {
    let style = mapStyle.toJS()
    let verticies = mapUtils.getSource(style, 'draw_verticies_hot')
    let allCoords = geoUtils.getAllCoords(verticies.data)
    let newPoint = geoUtils.getPointAtClick(e, viewport)
    // check for snapping
    if (snapLayer) {
      if (snapLine || snapVertex) {
        const snappingGeom = getSnappingGeom(e, mapRef, snapLayer)
        if (snappingGeom) {
          newPoint = getSnapPoint(snappingGeom, newPoint, snapLine, snapVertex)
        }
      }
    }

    allCoords.push(newPoint.geometry.coordinates)
    style.sources['draw_clicked_verticie'].data = {
      type: 'FeatureCollection',
      features: [newPoint],
    }

    if (geometry === 'line' && verticies.data.features.length > 0) {
      let line = geoUtils.getLineString(allCoords)
      style.sources['draw_line_hot'].data = {
        type: 'FeatureCollection',
        features: [line],
      }
    }

    if (geometry === 'fill' && verticies.data.features.length >= 2) {
      // close the polygon
      allCoords.push(allCoords[0])
      let polygon = geoUtils.getPolygon([allCoords])
      let line = geoUtils.getLineString(allCoords)
      style.sources['draw_polygon_hot'].data = {
        type: 'FeatureCollection',
        features: [polygon],
      }
      style.sources['draw_polygon_outline_hot'].data = {
        type: 'FeatureCollection',
        features: [line],
      }
    }
    dispatch(updateMapStyle(fromJS(style)))
  }

  const showDrawToolTip = () => {
    if (!mapToolTip.show) {
      dispatch(
        setMapToolTip({
          show: true,
          tipHtml: (
            <>
              <div>Double Click</div>
              <div>to Finish</div>
            </>
          ),
        })
      )
    }
  }

  const hideDrawToolTip = () => {
    if (mapToolTip.show) {
      dispatch(
        setMapToolTip({
          show: false,
          tipHtml: null,
        })
      )
    }
  }

  const finishFeature = () => {
    let style = mapStyle.toJS()
    const layers = mapUtils.getLayerObjects(hotLayers, style)
    layers.forEach(layer => {
      let hotSourceId = layer.hot.source
      let hotSource = mapUtils.getSource(style, hotSourceId)
      // remove duplicate coordinates
      // NOTE: duplicates are usually inserted on doubleclick (when finishing a feature)
      // if geometry is line or fill we can run getCleanCoords to remove one of the duplicates

      if (hotSource.data.features[0]) {
        hotSource.data.features[0] = geoUtils.getCleanCoords(
          hotSource.data.features[0]
        )
      }
      if (hotSourceId === 'draw_verticies_hot') {
        // for geometry circle its a little different
        // we dont want to keep either duplicate
        // remove both is pop
        // TODO:  this is kinda hacky, find a better way
        hotSource.data.features.pop()
        hotSource.data.features.pop()
      }
      if (
        hotSource.data.features.length &&
        hotSource.data.features[0].geometry.geometry === 'Polygon'
      ) {
        // check the polygon is closed
        let firstCoord = hotSource.data.features[0].geometry.coordinates[0][0]
        let lastCoord =
          hotSource.data.features[0].geometry.coordinates[0][
            hotSource.data.features[0].geometry.coordinates[0].length - 1
          ]
        if (JSON.stringify(firstCoord) !== JSON.stringify(lastCoord)) {
          // polygon is not closed, push firstCoord into coordinates array
          hotSource.data.features[0].geometry.coordinates[0].push(firstCoord)
        }
      }
      let coldSourceId = null
      let coldSource = null
      if (layer.cold) {
        coldSourceId = layer.cold.source
        coldSource = mapUtils.getSource(style, coldSourceId)
      }
      let coldDataIndex = null
      mapUtils.moveHotToCold(hotSource, coldSource, coldDataIndex)
      style.sources[hotSourceId] = hotSource
      if (coldSourceId) {
        style.sources[coldSourceId] = coldSource
      }
    })
    hideDrawToolTip()
    style = emptyHotLayerSources(style)
    dispatch(updateMapStyle(fromJS(style)))
  }

  const emptyHotLayerSources = style => {
    HOT_SOURCES.forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    return style
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  // debounced callback for handling mousemove
  const debouncedCallback = useCallback(
    _.debounce(e => {
      handleMouseMove(e), 500
    }, [])
  )

  const handleMouseDown = e => {
    const center = geoUtils.getMapCenter(viewport)
    setMouseDown(true)
    if (overPanel) return
    setMouseDownCenter(center)
  }

  const handleMouseUp = e => {
    if (e.detail > 1) return
    setMouseDown(false)
    if (overPanel) return
    const center = geoUtils.getMapCenter(viewport)
    // if the mouseDownCenter (set in mouse down event) does not equal center
    // the map has been dragged, do not add the point to the map
    if (mouseDownCenter !== center) return
    addPointToFeature(e)
  }

  const handleMouseMove = e => {
    if (overPanel) return
    if (mouseDown) return
    updateFeature(e)
  }

  const handleDoubleClick = e => {
    finishFeature()
  }

  const enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousedown', handleMouseDown, true)
    main.addEventListener('mouseup', handleMouseUp, true)
    main.addEventListener('mousemove', debouncedCallback, true)
    main.addEventListener('dblclick', handleDoubleClick, true)
  }

  const disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousedown', handleMouseDown, true)
    main.removeEventListener('mouseup', handleMouseUp, true)
    main.removeEventListener('mousemove', debouncedCallback, true)
    main.removeEventListener('dblclick', handleDoubleClick, true)
  }

  useEffect(() => {
    return () => {
      dispatch(setDoubleClickZoom(true))
      dispatch(setMapToolTip({ show: false, tipHtml: null }))
    }
  }, [])

  useEffect(() => {
    enableEventListeners()
    return () => {
      disableEventListeners()
    }
  }, [handleMouseDown])

  return null
}

export default New
