import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setDragPan, setDoubleClickZoom } from "../../../../../actions"
import EditHotKeys from "./EditHotKeys"
import SelectFeatureForEdit from "./SelectFeatureForEdit"
import EditFeature from "./EditFeature"
import DeleteVertex from "./DeleteVertex"

const Edit = ({targetLayer, geometry, mapRef, clearEdits}) => {
    const dispatch = useDispatch()

    const [editMode, setEditMode] = useState("SELECT_FEATURE_FOR_EDIT")
    const [editFeature, setEditFeature] = useState(null)
    const [deleteVertex,setDeleteVertex] = useState(null)
    const setFeatureSelected = feature => {
        setEditFeature(feature)
        setEditMode('EDIT_FEATURE')
      }

    const finishEdit = useCallback(
        () => {
            setEditFeature(null)
            setEditMode('SELECT_FEATURE_FOR_EDIT') 
        }
    ) 

    const cancelEdit = useCallback( 
        () => {
            setEditFeature(null)
            setEditMode('SELECT_FEATURE_FOR_EDIT')
        }
    )

    useEffect(()=>{
        // NOTE
        // map dragPan is set to false when editing / moving a feature verticie
        // on editMode change we always set dragPan back to true to prevent map locking
        dispatch(setDragPan(true)) 
        if(editMode === "EDIT_FEATURE"){
            dispatch(setDoubleClickZoom(false))
        } else {
            dispatch(setDoubleClickZoom(true))
        }
        
    },[editMode])

    useEffect(()=>{
        if(clearEdits){
            setEditFeature(null)
            setEditMode('SELECT_FEATURE_FOR_EDIT') 
        }
    },[clearEdits])

    return (
        <>
        <EditHotKeys 
            cancelEdit={cancelEdit} 
            setDeleteVertex={setDeleteVertex}
        />
        {editMode === "SELECT_FEATURE_FOR_EDIT" ?
            <SelectFeatureForEdit 
                id={targetLayer.value}
                geometry={geometry}
                mapRef={mapRef}  
                setFeatureSelected={setFeatureSelected}  
            /> :
            null
        }
        {editMode === "EDIT_FEATURE" ? 
           <>
           <EditFeature 
                type={geometry}
                mapRef={mapRef}
                clickedFeatures={editFeature}
                finishEdit={finishEdit}
            /> 
            {deleteVertex && (
                <DeleteVertex geometry={geometry} setDeleteVertex={setDeleteVertex}/>
              )}
            </>
            :
            null
        }

        </>
    )
}


export default Edit