import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLayerLabels, updateMapStyle } from '../../../../../actions/index'
import { clone } from '../../../../../utilities/geospatial'
import { fromJS } from 'immutable'
import scss from '../LayerLabel.scss'

const PropertySelector = ({
  config,
  propertyList,
  selectedZoom,
  setSelectedZoom,
  selectedProperty,
  setSelectedProperty,
  setDisplaySaveButtonOnMenu,
}) => {
  const dispatch = useDispatch()
  const commonLayerLabels = useSelector(state => state.commonLayerLabels)
  const mapStyle = useSelector(state => state.mapStyle)

  const [mounted, setMounted] = useState(false)
  const zoomList = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ]

  const handleChange = e => {
    setDisplaySaveButtonOnMenu(true)
    const prop = propertyList.filter(
      property => property.key === e.target.value
    )
    setSelectedProperty(prop[0])
  }

  const handleZoomChange = e => {
    setSelectedZoom(parseInt(e.target.value))
  }

  const setActiveCommonLayerLabels = () => {
    let newLabels = clone(commonLayerLabels)
    const idxs = []

    config.layer.layersArray
      .filter(layerObj => layerObj.metadata && layerObj.metadata.commonLayer)
      .forEach(layerObj => {
        const index = newLabels.findIndex(
          labelObj => labelObj.layerId === layerObj.layer.id
        )
        if (index !== -1) idxs.push(index)
      })
    if (idxs.length) {
      idxs.forEach(idx => {
        newLabels[idx].attribute =
          selectedProperty.key === 'none' ? null : selectedProperty.key
        newLabels[idx].attributeType = selectedProperty.type
        newLabels[idx].zoom = selectedZoom
      })
    } else {
      const labelObjs = config.layer.layersArray.map(layerObj => {
        return {
          layerId: layerObj.layer.id,
          attribute:
            selectedProperty.key === 'none' ? null : selectedProperty.key,
          attributeType: selectedProperty.type,
          zoom: selectedZoom,
        }
      })

      newLabels = [...newLabels, ...labelObjs]
    }
    dispatch(setLayerLabels(newLabels))
  }

  const setUserLayerLabels = () => {
    const style = mapStyle.toJS()
    const symbolLayer = config.layer.layersArray[0].symbol
    const styleLayer = style.layers.filter(layer => layer.id === symbolLayer.id)
    if (selectedProperty.key === 'none') {
      delete styleLayer[0].layout['text-field']
      delete styleLayer[0].layout['text-size']
      delete styleLayer[0].layout['text-font']
      delete styleLayer[0].layout['text-allow-overlap']
      delete styleLayer[0].layout['text-halo-color']
      delete styleLayer[0].layout['text-halo-width']
      delete styleLayer[0].layout['text-color']
      delete styleLayer[0].layout['text-allow-overlap']
    } else {
      styleLayer[0].layout['text-field'] = [
        'concat',
        ['get', selectedProperty.key],
      ]

      if (!styleLayer[0].layout['text-size']) {
        styleLayer[0].layout['text-size'] = [
          'interpolate',
          ['linear'],
          ['zoom'],
          selectedZoom,
          12,
          24,
          14,
        ]
      }
      styleLayer[0].layout['text-font'] = ['Noto Sans Regular']

      if (typeof styleLayer[0].layout['text-allow-overlap'] === 'undefined')
        styleLayer[0].layout['text-allow-overlap'] = false

      styleLayer[0].minzoom = selectedZoom || 10

      // If text color is not set we set it to black
      if (!styleLayer[0].paint['text-color']) {
        styleLayer[0].paint['text-color'] = '#000000'
      }
      // If halo color is not set we set it to white
      if (!styleLayer[0].paint['text-halo-color']) {
        styleLayer[0].paint['text-halo-color'] = '#ffffff'
      }

      if (!styleLayer[0].layout['text-offset']) {
        styleLayer[0].layout['text-offset'] = [0, 0]
      }

      if (typeof styleLayer[0].paint['text-halo-width'] === 'undefined') {
        styleLayer[0].paint['text-halo-width'] = 2
      }
    }

    dispatch(updateMapStyle(fromJS(style)))
  }

  const checkForSelectedCommonLayerProperty = () => {
    const existing = commonLayerLabels.filter(
      labelObj => labelObj.layerId === config.layer.layersArray[0].layer.id
    )

    if (existing[0]) {
      let previousSelectedProp
      existing.forEach(x => {
        if (x.attribute) {
          previousSelectedProp = x.attribute
        }
      })

      if (previousSelectedProp) {
        const prop = propertyList.filter(
          property => property.key === previousSelectedProp
        )

        setSelectedProperty(prop[0])
      }
    }
    setMounted(true)
  }

  const checkForSelectedUserLayerProperty = () => {
    const textField = config.layer.layersArray[0].symbol.layout['text-field']

    if (textField && Array.isArray(textField)) {
      const selectedProperty = textField[1][1]
      const prop = propertyList.filter(
        property => property.key === selectedProperty
      )

      setSelectedProperty(prop[0])
    }
    setMounted(true)
  }

  useEffect(() => {
    if (propertyList) {
      if (config.layer.layersArray[0].layer.metadata.commonLayer) {
        checkForSelectedCommonLayerProperty()
      } else {
        checkForSelectedUserLayerProperty()
      }
    }
  }, [propertyList])

  useEffect(() => {
    if (mounted && propertyList) {
      if (config.layer.layersArray[0].layer.metadata.commonLayer) {
        setActiveCommonLayerLabels()
      } else {
        setUserLayerLabels()
      }
    }
  }, [selectedProperty, selectedZoom])

  return (
    <div className={scss.selectWrapper}>
      <div>
        <label>Properties</label>
        <select value={selectedProperty.key} onChange={handleChange}>
          {propertyList.map(obj => (
            <option key={obj.key} value={obj.key}>
              {obj.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Zoom</label>
        <select
          className={scss.zoomSelect}
          value={selectedZoom}
          onChange={handleZoomChange}
        >
          {zoomList.map(zoom => (
            <option key={zoom} value={zoom}>
              {zoom}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default PropertySelector
