import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import 'react-tabs/style/react-tabs.css'
import Checkbox from 'react-three-state-checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs } from 'react-tabs'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import * as utils from '../../utilities/util'

import AsyncFetch from '../../utilities/AsyncFetch'
import { apis } from '../../config/apiConfig'

import checkboxScss from '../CSSModules/Checkbox.scss'
import appScss from '../App/App.scss'
import profileScss from './Profile.scss'
import scss from './Accounts.scss'

import AccountList from './AccountList'

import AddNewUserModal from './AccountModals/addNewUser'
import AddNewAccountModal from './AccountModals/addNewAccount'
import AssignAccountModal from './AccountModals/assignAccount'
import ActivateUserModal from './AccountModals/activateUser'
import DeactivateUserModal from './AccountModals/deactivateUser'
import UnassignAccountModal from './AccountModals/unassignAccount'
import AssignMapModal from './AccountModals/assignMap'

export default function Accounts() {
  // const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  // let account = utils.objectKeyVal(user.accountID, user.accounts, 'id');

  const [loading, setLoading] = useState(false)
  const [showDropdown, setDropdown] = useState(false)
  const [users, setUsers] = useState([])
  const [accounts, setAccounts] = useState([])
  const [update, doUpdate] = useState([true])
  const [fetchObjects, setFetchObjects] = useState(null)

  const [checkboxList, setCheckboxList] = useState({})
  const [checkboxCount, setCheckboxCount] = useState(0)

  /* ------------------------------ FILTER STATES ------------------------------ */
  const [filterSearch, setFilterSearch] = useState('')
  const [filterOrg, setFilterOrg] = useState('')
  /* --------------------------------------------------------------------------- */

  /* ----------------------------- DROPDOWN STATES ---------------------------- */
  const [showAssignDropdown, setAssignDropdown] = useState(false)
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ MODAL STATES ------------------------------ */
  const [addUserVisible, setAddUserVisible] = useState(false)
  const [addNewUserVisible, setAddNewUserVisible] = useState(false)
  const [addNewAccountVisible, setAddNewAccountVisible] = useState(false)
  const [editUserVisible, setEditUserVisible] = useState(false)
  const [deleteUserVisible, setDeleteUserVisible] = useState(false)
  const [assignAccountVisible, setAssignAccountVisible] = useState(false)
  const [activateUserVisible, setActivateUserVisible] = useState(false)
  const [deactivateUserVisible, setDeactivateUserVisible] = useState(false)
  const [unassignAccountVisible, setUnassignAccountVisible] = useState(false)
  const [assignMapVisible, setAssignMapVisible] = useState(false)
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- DATA STATES ------------------------------ */
  const [sortBy, setSortBy] = useState({
    type: 'createdDate',
    direction: 'ascending',
  })
  /* -------------------------------------------------------------------------- */

  const [collapsed, setCollapsed] = useState([])

  const adminBoxStatusIconRed = { color: '#ff0000' } // RED COLOR
  const adminBoxStatusIcon = { color: '#44df60' } // GREEN COLOR

  // ====================================
  // = Fetch Account List from Database =
  // ====================================
  const buildFetchParams = () => {
    const method = 'GET'
    const url = apis.apiDatabase.uri + 'accounts'
    const body = {}

    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        const accountList = utils.resultReturn(result)
        setAccounts(accountList)

        // Collapsed Logic
        if (accountList.length > 2 && !collapsed.length) {
          setCollapsed(accountList.map(account => account.id))
        }

        setLoading(false)
      }
      setFetchObjects(null)
    })
  }

  const updateAccounts = () => {
    // Reset
    setCheckboxList({})
    setCheckboxCount(0)

    // Continue
    buildFetchParams()
    setLoading(true)
  }

  const forceUpdate = () => {
    doUpdate(utils.generateRandomString(5))
  }

  // =================
  // = SORT HANDLING =
  // =================
  const handleSortList = sortByObj => {
    const copy = accounts.slice()

    const { type, direction } = sortByObj
    let valA
    let valB

    // helpers
    const compare = (a, b) => {
      if (type === 'name') {
        valA = a.label.toUpperCase()
        valB = b.label.toUpperCase()
      } else if (type === 'createdDate') {
        valA = a.dateCreated
        valB = b.dateCreated
      } else if (type === 'updatedDate') {
        valA = a.dateUpdated
        valB = b.dateUpdated
      }

      let comparison = 0
      if (valA > valB) {
        comparison = 1
      } else if (valB > valA) {
        comparison = -1
      }

      return comparison
    }

    // BELOW IS THE LAYER'S STATUS SORT

    // if (type === 'status') {
    //   let activeList = copy.filter(obj => {
    //     if (isActiveGroup(obj) || isActiveLayer(obj)) return true
    //   })

    //   const inactiveList = copy.filter(obj => {
    //     if (!isActiveGroup(obj) && !isActiveLayer(obj)) return true
    //   })

    //   const newLayerList = [...activeList, ...inactiveList]

    //   if (direction === 'descending') newLayerList.reverse()
    //   setLayerList(newLayerList)
    // } else {
    //   copy.sort(compare)
    //   if (direction === 'descending') copy.reverse()
    //   setLayerList(copy)
    // }
  }

  const toggleDirection = () => {
    if (sortBy.direction === 'ascending')
      setSortBy({ ...sortBy, direction: 'descending' })
    if (sortBy.direction === 'descending')
      setSortBy({ ...sortBy, direction: 'ascending' })
  }

  const handleSortByClick = type => {
    if (type == sortBy.type) {
      toggleDirection()
    } else {
      setSortBy({ ...sortBy, type: type })
    }
  }

  // =====================
  // = DROPDOWN HANDLING =
  // =====================
  const resetDropdowns = () => {
    setAssignDropdown(false)
  }

  const pageClicked = () => {
    resetDropdowns()
  }

  const toggleAssignSwitcher = () => {
    resetDropdowns()
    if (showAssignDropdown == true) {
      setAssignDropdown(false)
    } else setAssignDropdown(true)
  }

  // =====================
  // = CHECKBOX HANDLING =
  // =====================
  const getCheckbox = (acc, usr) => {
    return acc.id + '_' + usr.id in checkboxList
  }

  const clickCheckbox = (acc, usr) => {
    const newVal = utils.toggleVal(checkboxList, acc.id + '_' + usr.id, {
      account: acc,
      user: usr,
    })
    // console.log('Checkbox Clicked',newVal,Object.keys(newVal).length)
    setCheckboxList(newVal)
    setCheckboxCount(Object.keys(newVal).length)
  }

  const setCheckbox = (acc, usr) => {
    const newVal = {
      [acc.id + '_' + usr.id]: { account: acc, user: usr },
    }
    // console.log('Checkbox Clicked',newVal,Object.keys(newVal).length)
    setCheckboxList(newVal)
    setCheckboxCount(Object.keys(newVal).length)
  }

  const getCountChecked = accID => {
    if (accID == null) return checkboxCount

    let count = 0
    for (const checkKey in checkboxList) {
      var checkArr = checkKey.split('_')
      if (checkArr.length >= 2) {
        var thisAccountID = checkArr[0]
        // var thisUserID = checkArr[1];

        if (thisAccountID == accID) count++
      }
    }
    return count
  }

  const getTotalCount = () => {
    var count = 0
    accounts.forEach(function (acc) {
      if (acc.users && Array.isArray(acc.users)) {
        count += acc.users.length
      }
    })
    return count
  }

  const toggleAccountChecks = (e, acc) => {
    acc.users.forEach(function (usr) {
      if (e.target.checked) {
        var newVal = utils.addVal(checkboxList, acc.id + '_' + usr.id, {
          account: acc,
          user: usr,
        })
      } else {
        var newVal = utils.unVal(checkboxList, acc.id + '_' + usr.id)
      }
      // console.log('Account Checkbox Clicked',newVal,e.target.checked)
      setCheckboxList(newVal)
      setCheckboxCount(Object.keys(newVal).length)
    })
  }

  const togglePageChecks = e => {
    accounts.forEach(function (acc) {
      acc.users.forEach(function (usr) {
        if (e.target.checked) {
          var newVal = utils.addVal(checkboxList, acc.id + '_' + usr.id, {
            account: acc,
            user: usr,
          })
        } else {
          var newVal = utils.unVal(checkboxList, acc.id + '_' + usr.id)
        }
        // console.log('Account Checkbox Clicked',newVal,e.target.checked)
        setCheckboxList(newVal)
        setCheckboxCount(Object.keys(newVal).length)
      })
    })
  }

  // ================================== //
  // ======== Handle Collapsed ======== //
  // ================================== //
  const handleCollapse = accountID => {
    setCollapsed(utils.toggleVal(collapsed, accountID))
  }

  const collapseAll = isCollapsed => {
    if (isCollapsed) {
      setCollapsed(accounts.map(account => account.id))
    } else setCollapsed([])
  }

  const allCollapsed = () => {
    return collapsed.length >= accounts.length
  }

  const handleToggleAllBtnClick = () => {
    if (allCollapsed()) {
      // Expand All
      collapseAll(false)
    } else {
      // Collapse All
      collapseAll(true)
    }
  }

  // =================================
  // =================================
  // =================================
  // ============ FILTERS ============
  // =================================
  // =================================
  // =================================
  const checkUserFilter = userObj => {
    let passFilter = true
    // Check Search
    if (filterSearch !== '') {
      const search = filterSearch.toLowerCase()

      const userName = userObj.displayName.toLowerCase()
      if (userName.indexOf(search) === -1) passFilter = false
    }

    return passFilter
  }

  const checkAccountFilter = account => {
    let passFilter = true

    // Check Search
    if (filterSearch !== '') {
      const search = filterSearch.toLowerCase()

      const accountName = account.name.toLowerCase()
      if (accountName.indexOf(search) === -1) passFilter = false

      // CHECK USERS
      account.users.map(userObj => {
        if (checkUserFilter(userObj)) passFilter = true
      })
    }

    // Check Org Filter
    if (filterOrg !== '') {
      const accountID = account.id
      if (accountID != filterOrg) passFilter = false
    }

    return passFilter
  }

  useEffect(() => {
    updateAccounts()
  }, [update])

  useEffect(() => {
    handleSortList(sortBy)
  }, [sortBy])

  // ================================
  // = HANDLING THE CHECKBOX STATUS =
  // ================================
  let checkedValue
  let indeterminateValue
  const curAccountCount = getCountChecked()
  if (curAccountCount == 0) {
    checkedValue = false
    indeterminateValue = false
  } else if (curAccountCount == getTotalCount()) {
    checkedValue = true
    indeterminateValue = false
  } else {
    checkedValue = false
    indeterminateValue = true
  }

  // ==============
  // = SORT ICONS =
  // ===============
  const sortUpIcon = ['fa', 'sort-up']
  const sortDownIcon = ['fa', 'sort-down']

  // ====================================
  // = ACTIVE/DEACTIVATE/DELETE STYLING =
  // ====================================

  // Show the add Account BTN if a super admin.
  let showAddAccountBtn = false
  let showUsage = false
  if (utils.isSuperAdmin(user)) {
    showAddAccountBtn = true
    showUsage = true
  }

  return (
    <>
      {fetchObjects && (
        // <p>Here's fetching</p>
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}

      <Tabs
        className={profileScss.userProfile}
        style={{ backgroundColor: '#f1f2f4', overflowY: 'overlay' }}
      >
        <div
          //  onClick={pageClicked}
          className={scss.usersContainer}
        >
          <div className={scss.userHeader}>
            <h1>Manage Accounts</h1>
            <div className={scss.userButtons}>
              {showAddAccountBtn && addNewAccountVisible && (
                <AddNewAccountModal
                  doUpdate={forceUpdate}
                  visible={setAddNewAccountVisible}
                  accountID={null}
                />
              )}
              {showAddAccountBtn && (
                <a
                  className={scss.accountHeaderButtons}
                  onClick={() => setAddNewAccountVisible(true)}
                >
                  <span>Add Account</span>
                  <FontAwesomeIcon icon='plus' size='1x' />
                </a>
              )}

              {addNewUserVisible && (
                <AddNewUserModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  visible={setAddNewUserVisible}
                />
              )}
              <a
                className={scss.accountHeaderButtons}
                onClick={() => setAddNewUserVisible(true)}
              >
                <span>Add User</span>
                <FontAwesomeIcon icon='plus' size='1x' />
              </a>

              {deleteUserVisible && (
                <DeleteUserModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setDeleteUserVisible}
                />
              )}
              {/* <a className={scss.accountHeaderButtons} onClick={() => setDeleteUserVisible(true)}><span>Delete User</span><FontAwesomeIcon icon="minus" size="1x" /></a> */}
            </div>
          </div>

          <div className={scss.adminHeaderLabel}>
            <FontAwesomeIcon
              style={{ marginRight: '7px' }}
              icon={['far', 'filter']}
              size='1x'
            />
            <span style={{ color: '#000' }}>Filters:</span>
          </div>

          {/* ADMIN HEADER WITH FILTERS AND BUTTONS */}
          <div className={scss.adminHeader}>
            <div className={scss.adminFilters}>
              <input
                style={{ maxWidth: '236px', height: '36px', textAlign: 'left' }}
                className={appScss.mainInput}
                type='search'
                placeholder='Filter Search'
                onChange={e => setFilterSearch(e.target.value)}
              />

              <select
                style={{ minWidth: '204px', height: '36px' }}
                className={appScss.altSelect}
                onChange={e => setFilterOrg(e.target.value)}
              >
                <option value={''}>Showing all accounts</option>
                <option disabled>-</option>
                {accounts.map(account => (
                  <option key={'accID' + account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className={[
                scss.adminButtons,
                Object.keys(checkboxList).length
                  ? ''
                  : scss.adminButtons_disabled,
              ].join(' ')}
            >
              {/* -------------------------------------------------------------------------- */}

              {assignAccountVisible && (
                <AssignAccountModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setAssignAccountVisible}
                />
              )}

              {assignMapVisible && (
                <AssignMapModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setAssignMapVisible}
                />
              )}

              {unassignAccountVisible && (
                <UnassignAccountModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setUnassignAccountVisible}
                />
              )}

              {activateUserVisible && (
                <ActivateUserModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setActivateUserVisible}
                />
              )}

              {deactivateUserVisible && (
                <DeactivateUserModal
                  doUpdate={forceUpdate}
                  accounts={accounts}
                  checkboxList={checkboxList}
                  visible={setDeactivateUserVisible}
                />
              )}

              <a
                className={scss.accountHeaderButtons}
                onClick={() =>
                  checkboxCount > 0 && setAssignAccountVisible(true)
                }
              >
                <span>Assign</span>
                <FontAwesomeIcon icon='plus' size='1x' />
              </a>
              <a
                className={scss.accountHeaderButtons}
                onClick={() =>
                  checkboxCount > 0 && setUnassignAccountVisible(true)
                }
              >
                <span>Unassign</span>
                <FontAwesomeIcon icon='minus' size='1x' />
              </a>

              <a
                className={scss.accountHeaderButtons}
                onClick={() =>
                  checkboxCount > 0 && setActivateUserVisible(true)
                }
              >
                <span>Activate</span>
                <FontAwesomeIcon
                  style={adminBoxStatusIcon}
                  icon='circle'
                  size='1x'
                />
              </a>
              <a
                className={scss.accountHeaderButtons}
                onClick={() =>
                  checkboxCount > 0 && setDeactivateUserVisible(true)
                }
              >
                <span>Deactivate</span>
                <FontAwesomeIcon
                  style={adminBoxStatusIconRed}
                  icon='circle'
                  size='1x'
                />
              </a>

              <a
                className={scss.accountHeaderButtons}
                onClick={handleToggleAllBtnClick}
              >
                <FontAwesomeIcon
                  className={scss.collapseIcon}
                  icon={[
                    'fa',
                    'chevron-double-' + (allCollapsed() ? 'down' : 'up'),
                  ]}
                  size='1x'
                />
              </a>
            </div>
          </div>

          {/* ADMIN HEADER WITH TABLE HEADINGS */}
          <div
            className={[
              scss.adminHeaderBox,
              showUsage ? scss.withUsageColumn : '',
            ].join(' ')}
          >
            {/* ALT CUSTOM CHECKBOX BELOW */}
            <label
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
              style={{ marginBottom: '8px' }}
            >
              <Checkbox
                className={checkboxScss.inputIndeterminate}
                checked={checkedValue}
                indeterminate={indeterminateValue}
                onChange={e => togglePageChecks(e)}
              />
              <span
                className={[
                  checkboxScss.checkboxMark,
                  checkboxScss.altCheckboxMark,
                ].join(' ')}
              ></span>
            </label>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'unset' }}
            >
              <h4
                className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                onClick={() => handleSortByClick('org')}
              >
                Account Name
              </h4>
              {sortBy.type === 'org' ? (
                <FontAwesomeIcon
                  icon={
                    sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                  }
                  size='1x'
                  className={scss.adminCursor}
                  onClick={() => handleSortByClick('org')}
                />
              ) : null}
            </div>

            <div className={scss.adminBoxRowItems}>
              <h4 className={scss.adminHeadings}>Number of Users</h4>
            </div>

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <h4
                className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                onClick={() => handleSortByClick('maps')}
              >
                Maps
              </h4>
              {sortBy.type === 'maps' ? (
                <FontAwesomeIcon
                  icon={
                    sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                  }
                  size='1x'
                  className={scss.adminCursor}
                  onClick={() => handleSortByClick('maps')}
                />
              ) : null}
            </div>

            {showUsage && (
              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center' }}
              >
                <h4 className={scss.adminHeadings}>Usage</h4>
              </div>
            )}

            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <h4
                className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                onClick={() => handleSortByClick('status')}
              >
                Status
              </h4>
              {sortBy.type === 'status' ? (
                <FontAwesomeIcon
                  icon={
                    sortBy.direction === 'ascending' ? sortDownIcon : sortUpIcon
                  }
                  size='1x'
                  className={scss.adminCursor}
                  onClick={() => handleSortByClick('status')}
                />
              ) : null}
            </div>

            <div className={scss.adminBoxRowItems}></div>
          </div>

          {loading && (
            <SkeletonTheme color='#e4e5e7' highlightColor='#e8ebf2'>
              <Skeleton height={54} count={12} />
            </SkeletonTheme>
          )}
          {!loading && (
            <AccountList
              setAssignAccountVisible={setAssignAccountVisible}
              setUnassignAccountVisible={setUnassignAccountVisible}
              accounts={accounts}
              toggleAccountChecks={toggleAccountChecks}
              checkAccountFilter={checkAccountFilter}
              checkUserFilter={checkUserFilter}
              getCountChecked={getCountChecked}
              getCheckbox={getCheckbox}
              setCheckbox={setCheckbox}
              clickCheckbox={clickCheckbox}
              collapsed={collapsed}
              handleCollapse={handleCollapse}
              forceUpdate={forceUpdate}
            />
          )}
        </div>
      </Tabs>
    </>
  )
}
