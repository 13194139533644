import React, { useState, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import scss from '../../Profile/Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import mapViewScss from './MapViews.scss'
import MakeDataConfigComponent from '../../../utilities/dataConfig'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

function SimpleDialog({
  visible,
  needActivationLayers,
  mapID,
  onFinish,
  onHandleClose,
}) {
  const [open, setOpen] = useState(true)

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(null)

  const handleOnClose = () => {
    setOpen(false)
    if (visible) visible(false)
    onHandleClose()
  }

  const fetchFinished = () => {
    setFetchObjects(null)
    setFetching(null)
    setMountMakeDataConfig(true)
    handleOnClose()
  }

  const handleLayerActivation = () => {
    const justIDs = []
    needActivationLayers.forEach(layer => {
      justIDs.push(layer.layerID)
    })

    const method = 'POST'
    const url = apis.apiDatabase.uri + '/layers/user/add'
    const body = {
      layerID: justIDs,
      mapID,
    }

    setFetchObjects([{ url, method, body }])
    onFinish()
  }

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}

      {mountMakeDataConfig && (
        <MakeDataConfigComponent updateStyle onFinish={() => {}} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-new-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-new-account-dialog'>
          Layer permissions
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>

        <DialogContent
          className={modalScss.MuiDialogBoxes}
          style={{ width: '500px' }}
        >
          {needActivationLayers ? (
            <div>
              <div>
                The following layers need to be activated to display the Map
                View correctly:{' '}
                <div
                  className={[scss.handleContainer, 'handle'].join(' ')}
                ></div>
                <br />
              </div>

              <div>
                {needActivationLayers.map(layer => {
                  return (
                    <div key={layer.layerID}>
                      {layer.labelName ? (
                        <>
                          <span className={mapViewScss.label}>Name: </span>
                          <span>{layer.labelName}</span>
                        </>
                      ) : null}{' '}
                      {mapViewScss.label ? (
                        <>
                          <span className={mapViewScss.label}>ID: </span>{' '}
                          <span>{layer.layerID}</span>{' '}
                        </>
                      ) : null}
                      {layer.layerOwnerName ? (
                        <>
                          <span className={mapViewScss.label}>Owner:</span>{' '}
                          <span>{layer.layerOwnerName}</span>
                        </>
                      ) : null}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#1a2937' }}>
          <button
            className={modalScss.modalMainButton}
            style={{ width: '100%', placeContent: 'center' }}
            onClick={fetching ? null : handleLayerActivation}
          >
            Activate layers
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function mapViewActivateLayers(props) {
  return <SimpleDialog {...props} />
}
