import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refreshDetails } from '../../utilities/user'

import * as utils from '../../utilities/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import AsyncFetch from '../../utilities/AsyncFetch'
import { apis } from '../../config/apiConfig'
import {
  switchPage,
  Login as _Login,
  Logout as _Logout,
  setToc,
} from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Skeleton from 'react-loading-skeleton'

import { updateDataConfig } from '../../actions'
import MakeDataConfigComponent from '../../utilities/dataConfig'

import dropdownScss from '../CSSModules/Dropdown.scss'
import checkboxScss from '../CSSModules/Checkbox.scss'
import appScss from '../App/App.scss'
import profileScss from './Profile.scss'
import scss from './Accounts.scss'
import layerScss from './LayerList.scss'
import buttonsScss from './LayerButtons.scss'

import LayerList from './LayerList'
import { layer } from '@fortawesome/fontawesome-svg-core'
import MapViews from './MapViews'
import logoDark from '../../assets/logo/logo-dark.svg'
import organizationLayerGroup from '../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../assets/icons/userLayerGroup.svg'

export default function Layers() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  let account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
  const mapList = account.maps
  const typeList = [
    ['My Layers', 'user', userLayerGroup, layerScss.layerBoxTypeImage],
    ['Shared with User', 'Shared', sharedLayerGroup, layerScss.layerTypeIcon],
    [
      'Shared with Organization',
      'Org',
      organizationLayerGroup,
      layerScss.layerTypeIcon,
    ],
    ['Common Layers', 'mam', logoDark, layerScss.layerBoxTypeOrgImage],
  ]

  const map = utils.objectKeyVal(user.mapID, mapList, 'id')
  const [layerList, setLayerList] = useState([])
  const [layerListCopy, setLayerListCopy] = useState([])
  const [activeLayerList, setActiveLayerList] = useState([])
  const [update, doUpdate] = useState([true])
  const [checkboxList, setCheckboxList] = useState({})
  const [checkboxCount, setCheckboxCount] = useState(0)

  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(null)

  /* ------------------------------ FILTER STATES ------------------------------ */
  const [filterSearch, setFilterSearch] = useState('')
  const [filterType, setFilterType] = useState('')
  const [filterMap, setFilterMap] = useState('')
  const [lastUpdatedFilter, setLastUpdatedFilter] = useState(null)
  /* --------------------------------------------------------------------------- */

  /* ----------------------------- DROPDOWN STATES ---------------------------- */
  const [showAssignDropdown, setAssignDropdown] = useState(false)
  const [showLayerTypeDropdown, setShowLayerTypeDropdown] = useState(false)
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ DATA STATES ------------------------------ */
  const [availableLoading, setAvailableLoading] = useState(false)
  const [activeLoading, setActiveLoading] = useState(false)
  const [availableFetchObjects, setAvailableFetchObjects] = useState(null)
  const [activeFetchObjects, setActiveFetchObjects] = useState(null)
  const [activateDeactivateObjects, setActivateDeactivateObjects] =
    useState(null)
  const [mapUpdateObjects, setMapUpdateObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [resetting, setResetting] = useState(false)
  const [resetObjects, setResetObjects] = useState(null)
  const [defaultLayerFetching, setDefaultLayerFetching] = useState(false)
  const [defaultObjects, setDefaultObjects] = useState(null)
  const [defaultLayers, setDefaultLayers] = useState(null)
  const [defaultsUpdating, setDefaultsUpdating] = useState(false)
  const [defaultUpdateObjects, setDefaultUpdateObjects] = useState(null)
  const [initialDefaults, setInitialDefaults] = useState(null)

  const [removeList, setRemoveList] = useState([])
  const [addList, setAddList] = useState([])

  const [sortBy, setSortBy] = useState({
    type: 'createdDate',
    direction: 'ascending',
  })

  const [prevDefaultLayer, setPrevDefaultLayer] = useState()

  /* -------------------------------------------------------------------------- */

  const [collapsed, setCollapsed] = useState([])

  var listArea

  useEffect(() => {
    updateLayerList()
  }, [update])

  // ====================================
  // =  Fetch Layer List from Database  =
  // ====================================

  const dataConfigUpdated = dataConfig => dispatch(updateDataConfig(dataConfig))

  const buildFetchParams = (id, e) => {
    if (id === 'available') {
      const method = 'GET'
      const url = apis['apiDatabase'].uri + 'layers/available'
      const body = {
        mapID: user.mapID,
      }

      setAvailableLoading(true)
      setAvailableFetchObjects([{ url, method, body }])
    } else if (id === 'active') {
      const method = 'GET'
      const url = apis['apiDatabase'].uri + 'layers/active'
      const body = {
        mapID: user.mapID,
      }

      setActiveLoading(true)
      setActiveFetchObjects([{ url, method, body }])
    } else if (id === 'update') {
      var newMapID = e.target.value
      if (newMapID == '') resetLayers()

      resetLayers()
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'user/update'
      const body = {
        mapID: newMapID,
      }

      setMapUpdateObjects([{ url, method, body }])
    }
  }

  const updateLayerList = () => {
    //Reset
    setCheckboxList({})
    setCheckboxCount(0)

    //Continue
    setAvailableLoading(true)
    setActiveLoading(true)
    buildFetchParams('active')
    buildFetchParams('available')
  }

  // =====================
  // = DROPDOWN HANDLING =
  // =====================
  const resetDropdowns = () => {
    setAssignDropdown(false)
  }

  const pageClicked = () => {
    resetDropdowns()
  }

  const toggleAssignSwitcher = () => {
    resetDropdowns()
    if (showAssignDropdown == true) {
      setAssignDropdown(false)
    } else setAssignDropdown(true)
  }

  const toggleLayerTypeDropdown = () => {
    setShowLayerTypeDropdown(showLayerTypeDropdown ? false : true)
  }

  const isActiveLayer = layer => {
    return activeLayerList.includes(layer.id)
  }

  const isActiveGroup = layer => {
    return isActiveLayer(layer)

    if (!layer.children || !Array.isArray(layer.children))
      return isActiveLayer(layer)
    var active = true
    if (layer.children.length == 0) return isActiveLayer('group_' + layer)
    layer.children.every(groupLayer => {
      if (!isActiveGroup(groupLayer)) {
        active = false
        return false
      }
    })
    return active
  }

  const isDefaultLayer = layer => {
    return defaultLayers && defaultLayers.includes(layer.id)
  }

  // =====================
  // = CHECKBOX HANDLING =
  // =====================
  const getCheckbox = layer => {
    return layer.id in checkboxList
  }

  const clickCheckbox = layer => {
    var newVal = utils.toggleVal(checkboxList, layer.id, { layer })
    setCheckboxList(newVal)
    setCheckboxCount(Object.keys(newVal).length)
  }

  const getCountChecked = accID => {
    if (accID == null) return checkboxCount

    var count = 0
    for (const checkKey in checkboxList) {
      var checkArr = checkKey.split('_')
      if (checkArr.length >= 2) {
        var thisAccountID = checkArr[0]
        // var thisUserID = checkArr[1];

        if (thisAccountID == accID) count++
      }
    }
    return count
  }

  const getTotalCount = () => {
    var count = 0
    layerList.forEach(function (acc) {
      if (acc.users && Array.isArray(acc.users)) {
        count += acc.users.length
      }
    })
    return count
  }

  const toggleLayerChecks = (e, layr) => {
    layerList.forEach(function (usr) {
      if (e.target.checked) {
        var newVal = utils.addVal(checkboxList, layr.id + '_' + usr.id, {
          layer: layr,
          user: usr,
        })
      } else {
        var newVal = utils.unVal(checkboxList, layr.id + '_' + usr.id)
      }
      setCheckboxList(newVal)
      setCheckboxCount(Object.keys(newVal).length)
    })
  }

  const togglePageChecks = e => {
    layerList.forEach(function (layer) {
      layer.children.forEach(function (usr) {
        if (e.target.checked) {
          var newVal = utils.addVal(checkboxList, layer.id + '_' + usr.id, {
            layer: layer,
            user: usr,
          })
        } else {
          var newVal = utils.unVal(checkboxList, layer.id + '_' + usr.id)
        }
        setCheckboxList(newVal)
        setCheckboxCount(Object.keys(newVal).length)
      })
    })
  }

  // =================================
  // =================================
  // =================================
  // ============ FILTERS ============
  // =================================
  // =================================
  // =================================
  const checkLayerFilter = (layer, layerChildren) => {
    //Check Search
    if (filterType !== '') {
      var passedChecks_type = false
      var type = filterType[1].toLowerCase()

      //Check Type
      if (layer && layer.type == type) passedChecks_type = true

      if (!passedChecks_type) return false
    }

    if (filterSearch !== '') {
      var passedChecks_search = false
      var search = filterSearch.toLowerCase()

      //Active Check
      if (search === 'active') {
        if (layer && isActiveLayer(layer)) passedChecks_search = true
      } else if (search === 'inactive') {
        if (layer && !isActiveLayer(layer)) passedChecks_search = true
      } else {
        //Check Label
        if (layer && layer.id && layer.id.toString().indexOf(search) !== -1)
          passedChecks_search = true
        if (
          layer &&
          layer.label &&
          layer.label.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
        if (
          layer &&
          layer.description &&
          layer.description.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
        if (
          layer &&
          layer.owner &&
          layer.owner.toLowerCase().indexOf(search) !== -1
        )
          passedChecks_search = true
      }

      //Check Children
      if (layerChildren && Array.isArray(layerChildren)) {
        layerChildren.every(groupLayer => {
          if (checkLayerFilter(groupLayer, groupLayer.children)) {
            passedChecks_search = true
            return false
          }
        })
      }

      if (!passedChecks_search) {
        listArea = (
          <div className={layerScss.noResults}>
            <FontAwesomeIcon icon={['fal', 'search']} />
            <FontAwesomeIcon icon={'question'} />
            <span className={layerScss.noResultsHeading}>No Results Found</span>
            <span className={layerScss.noResultsParagraph}>
              Try adjusting your search to find what you’re looking for
            </span>
          </div>
        )

        return false
      }
    }

    return true
  }

  const resetLayers = () => {
    setLayerList([])
    setActiveLayerList([])

    setAddList([])
    setRemoveList([])
    setFetching(false)
  }

  const updateMap = e => {
    buildFetchParams('update', e)
  }

  // ================================== //
  // ======== Handle Collapsed ======== //
  // ================================== //
  const handleCollapse = layerID => {
    setCollapsed(utils.toggleVal(collapsed, layerID))
  }

  const collapseAll = isCollapsed => {
    if (isCollapsed) {
      setCollapsed(layerList.map(layer => layer.id))
    } else setCollapsed([])
  }

  const allCollapsed = () => {
    return collapsed.length >= layerList.length
  }

  const handleToggleAllBtnClick = () => {
    if (allCollapsed()) {
      //Expand All
      collapseAll(false)
    } else {
      //Collapse All
      collapseAll(true)
    }
  }

  // ================================
  // = HANDLING THE CHECKBOX STATUS =
  // ================================
  var checkedValue
  var indeterminateValue
  var curAccountCount = getCountChecked()
  if (curAccountCount == 0) {
    checkedValue = false
    indeterminateValue = false
  } else if (curAccountCount == getTotalCount()) {
    checkedValue = true
    indeterminateValue = false
  } else {
    checkedValue = false
    indeterminateValue = true
  }

  // ====================================
  // = ACTIVE/DEACTIVATE/DELETE STYLING =
  // ====================================

  //Show the add Account BTN if a super admin.
  var showAddAccountBtn = false
  if (utils.isSuperAdmin(user)) showAddAccountBtn = true

  const handleLayerStatus = (layer, action = true) => {
    const getGroupChildrenIds = layer => {
      let ids = []

      const processLayer = childLayer => {
        childLayer.children.forEach(child => {
          ids.push(child.id)

          if (child.children && child.children.length) {
            processLayer(child)
          } else return
        })
      }

      processLayer(layer)
      return ids
    }

    if (layer == null) {
      // Triggered by checkbox usecase

      var activateLayerKeyList = Object.keys(checkboxList)
        .map(Number)
        .filter(layer => activeLayerList.indexOf(layer) === -1)
      var deactivateLayerKeyList = Object.keys(checkboxList)
        .map(Number)
        .filter(layer => activeLayerList.indexOf(layer) !== -1)

      if (action && activateLayerKeyList.length > 0) {
        let newActiveLayerList = activeLayerList
        activateLayerKeyList.forEach(layerID => {
          newActiveLayerList = utils.addVal(newActiveLayerList, layerID)
        })
        setAddList(activateLayerKeyList)
      } else if (!action && deactivateLayerKeyList.length > 0) {
        let newActiveLayerList = activeLayerList

        deactivateLayerKeyList.forEach(layerID => {
          newActiveLayerList = utils.unVal(newActiveLayerList, layerID)
        })
        setRemoveList(deactivateLayerKeyList)
      }
      return
    } else {
      // Triggered by switch usecase

      if (activeLayerList.indexOf(layer.id) !== -1) {
        // layer is active, deactivate
        if (layer.children && layer.children.length) {
          // group
          const childrenIds = getGroupChildrenIds(layer)
          childrenIds.push(layer.id)

          let finalArray = activeLayerList
          childrenIds.forEach(id => {
            finalArray = utils.unVal(finalArray, id)
          })

          setRemoveList(childrenIds)
        } else {
          // indiv layer
          setRemoveList([layer.id])
        }
      } else {
        // layer is inactive, activate
        if (layer.children && layer.children.length) {
          // group
          const childrenIds = getGroupChildrenIds(layer)
          childrenIds.push(layer.id)

          let finalArray = activeLayerList
          childrenIds.forEach(id => {
            finalArray = utils.addVal(finalArray, id)
          })

          let aList = childrenIds.filter(childId => {
            if (activeLayerList.indexOf(childId) === -1) return true
          })

          setAddList(aList)
        } else {
          // indiv layer
          setAddList([layer.id])
        }
      }
    }
  }

  const buildActivateDeactivateParams = id => {
    let method = 'POST'
    let url
    let body

    if (id === 'delete') {
      url = apis['apiDatabase'].uri + 'layers/user/delete'
      body = {
        layerID: removeList,
      }
    } else if (id === 'add') {
      url = apis['apiDatabase'].uri + 'layers/user/add'
      body = {
        layerID: addList,
      }
    }

    setActivateDeactivateObjects([{ url, method, body }])
  }

  // Fetch Cleanups

  const availableFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        var availableItems = utils.resultReturn(result)
        setLayerList(availableItems)
        setLayerListCopy(availableItems)

        //Collapsed Logic
        if (availableItems.length > 2 && !collapsed.length) {
          setCollapsed(availableItems.map(layer => layer.id))
        }
      }

      setAvailableLoading(false)
      setAvailableFetchObjects(null)
    })
  }

  const activeFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        var activeItems = utils.resultReturn(result)
        setActiveLayerList(utils.resultReturn(result))

        //Collapsed Logic
        if (activeItems.length > 2 && !collapsed.length) {
          setCollapsed(activeItems.map(layer => layer.id))
        }
      }

      setActiveLoading(false)
      setActiveFetchObjects(null)
    })
  }

  const activateDeactivateFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        const newLayerList = results[0].data.activeLayers
        setActiveLayerList(newLayerList)
      }
    })

    setAddList([])
    setRemoveList([])
    if (activateDeactivateObjects) setActivateDeactivateObjects(null)
    setFetching(false)
    setMountMakeDataConfig(true)
  }

  const mapUpdateFinished = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
    })

    setMapUpdateObjects(null)
  }

  // handler functions
  const handleSortByChange = e => {
    setSortBy({ ...sortBy, type: e.target.value })
  }

  const handleSortList = sortBy => {
    let copy = layerList.slice()

    const { type, direction } = sortBy
    let valA, valB

    // helpers
    const compare = (a, b) => {
      if (type === 'name') {
        valA = a.label.toUpperCase()
        valB = b.label.toUpperCase()
      } else if (type === 'createdDate') {
        valA = a.dateCreated
        valB = b.dateCreated
      } else if (type === 'updatedDate') {
        valA = a.dateUpdated
        valB = b.dateUpdated
      }

      let comparison = 0
      if (valA > valB) {
        comparison = 1
      } else if (valB > valA) {
        comparison = -1
      }

      return comparison
    }

    // main functionality

    if (type === 'status') {
      let activeList = copy.filter(obj => {
        if (isActiveGroup(obj) || isActiveLayer(obj)) return true
      })

      const inactiveList = copy.filter(obj => {
        if (!isActiveGroup(obj) && !isActiveLayer(obj)) return true
      })

      const newLayerList = [...activeList, ...inactiveList]

      if (direction === 'descending') newLayerList.reverse()
      setLayerList(newLayerList)
    } else if (type === 'default') {
      let activeList = copy.filter(obj => {
        if (isDefaultLayer(obj)) return true
      })

      const inactiveList = copy.filter(obj => {
        if (!isDefaultLayer(obj)) return true
      })

      const newLayerList = [...activeList, ...inactiveList]

      if (direction === 'descending') newLayerList.reverse()
      setLayerList(newLayerList)
    } else {
      copy.sort(compare)
      if (direction === 'descending') copy.reverse()
      setLayerList(copy)
    }
  }

  const handleAddDefaults = () => {
    const addList = Object.keys(checkboxList)

    if (addList.length) {
      // compare defaultLayers and addlist
      // add anything that isn't in the defaultLayers
      let newArr = defaultLayers || []
      addList.forEach(item => {
        newArr = utils.addVal(newArr, parseInt(item))
      })
      let newList = layerList
      addList.forEach(item => {
        newList.forEach(l => {
          if (l.id === parseInt(item)) {
            l.default = true
          }
        })
      })
      setLayerList(newList)
      updateDefaultLayers(newArr)
    }
  }

  const handleRemoveDefaults = () => {
    const removeList = Object.keys(checkboxList)

    if (removeList.length && defaultLayers) {
      let newArr = defaultLayers
      removeList.forEach(item => {
        newArr = utils.unVal(newArr, parseInt(item))
      })
      // reset layerlist.default to false for new comparison
      let newList = layerList
      removeList.forEach(item => {
        newList.forEach(l => {
          if (l.id === parseInt(item)) {
            l.default = false
          }
        })
      })

      setLayerList(newList)
      updateDefaultLayers(newArr)
    }
  }

  const handleFilterUpdate = filteredList => {
    setLayerList(filteredList)
  }

  const toggleDirection = () => {
    if (sortBy.direction === 'ascending')
      setSortBy({ ...sortBy, direction: 'descending' })
    if (sortBy.direction === 'descending')
      setSortBy({ ...sortBy, direction: 'ascending' })
  }

  const handleSortByClick = type => {
    if (type == sortBy.type) {
      toggleDirection()
    } else {
      setSortBy({ ...sortBy, type: type })
    }
  }

  // fetch calls

  const updateAvailableLayerPanel = () => {
    buildFetchParams('available')
  }

  const updateActiveLayerPanel = () => {
    buildFetchParams('active')
  }

  const handleDefaultUpdate = layer => {
    const newArr = utils.toggleVal(defaultLayers, layer.id)
    setDefaultLayers(newArr)
  }

  const handleResetLayers = () => {
    const url = apis.apiDatabase.uri + 'layers/default/reset'
    const method = 'POST'
    const body = {}

    setResetting(true)
    setResetObjects([{ url, method, body }])
  }

  const getDefaultLayers = () => {
    const url = apis.apiDatabase.uri + 'layers/default'
    const method = 'GET'
    const body = {}

    setDefaultLayerFetching(true)
    setDefaultObjects([{ url, method, body }])
  }

  const updateDefaultLayers = layersArray => {
    const url = apis.apiDatabase.uri + 'layers/default/update'
    const method = 'POST'

    const body = { layersArray }

    setDefaultsUpdating(true)

    if (defaultLayers && prevDefaultLayer) {
      if (defaultLayers > prevDefaultLayer) {
        const added = defaultLayers.filter(
          layerDif => !prevDefaultLayer.includes(layerDif)
        )

        body.added = added
      } else {
        const removed = prevDefaultLayer.filter(
          layerDif => !defaultLayers.includes(layerDif)
        )

        body.removed = removed
      }
    }
    body.accountID = user.accountID
    setDefaultUpdateObjects([{ url, method, body }])
  }

  const handleDefaultsFetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        const defaultLayers = utils.resultReturn(result)

        setInitialDefaults(defaultLayers)
        setDefaultLayers(defaultLayers)
      }
      setDefaultLayerFetching(false)
    })
  }

  const layerResetFinished = results => {
    setActiveLayerList(defaultLayers || [])
    setResetObjects(null)
    setResetting(false)
  }

  const handleDefaultUpdateFinished = results => {
    setCheckboxList({})

    setDefaultUpdateObjects(null)
    setDefaultsUpdating(false)
  }

  // * ------------------------------------------
  // * Effects
  // * ------------------------------------------

  useEffect(() => {
    if (layerList && defaultLayers) {
      if (defaultLayers.length) {
        defaultLayers.forEach(defLayer => {
          layerList.forEach(layer => {
            if (layer.id === defLayer) {
              layer.default = true
            }
          })
        })
      }
    }
  }, [defaultLayers, layerList])

  useEffect(() => {
    if (defaultLayers && initialDefaults && defaultLayers !== initialDefaults) {
      updateDefaultLayers(defaultLayers)
    }
  }, [defaultLayers])

  useEffect(() => {
    setPrevDefaultLayer(defaultLayers)
  }, [defaultLayers])

  useEffect(() => {
    if (!removeList || (removeList && !removeList.length) || removeList === [])
      return

    buildActivateDeactivateParams('delete')
  }, [removeList])

  useEffect(() => {
    if (lastUpdatedFilter === 'Last Updated') {
      handleFilterUpdate(layerListCopy)
    } else if (lastUpdatedFilter) {
      let newList = layerListCopy.slice()
      let now = moment()
      let filterStop

      if (lastUpdatedFilter === 'week') filterStop = now.subtract(7, 'days')
      else if (lastUpdatedFilter === 'two_weeks')
        filterStop = now.subtract(14, 'days')
      else if (lastUpdatedFilter === 'month')
        filterStop = now.subtract(30, 'days')
      else if (lastUpdatedFilter === 'two_months')
        filterStop = now.subtract(60, 'days')
      else if (lastUpdatedFilter === 'three_months')
        filterStop = now.subtract(90, 'days')

      newList = newList.filter(layer => {
        if (moment(layer.dateUpdated).isAfter(filterStop)) return true
      })
      handleFilterUpdate(newList)
    }
  }, [lastUpdatedFilter])

  useEffect(() => {
    if (!addList || (addList && !addList.length) || addList === []) return

    buildActivateDeactivateParams('add')
  }, [addList])

  useEffect(() => {
    updateAvailableLayerPanel()
    updateActiveLayerPanel()
  }, [user])

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(() => {
    handleSortList(sortBy)
  }, [sortBy])

  useEffect(() => {
    getDefaultLayers()
  }, [])

  let sortUpIcon = ['fa', 'sort-up']
  let sortDownIcon = ['fa', 'sort-down']
  const lastUpdatedOptions = [
    { label: 'Last 7 Days', value: 'week' },
    { label: 'Last 14 Days', value: 'two_weeks' },
    { label: 'Last 30 Days', value: 'month' },
    { label: 'Last 60 Days', value: 'two_months' },
    { label: 'Last 90 Days', value: 'three_months' },
  ]

  var layerShare, layerClass
  layerShare = ''
  layerClass = layerScss.layerTypeIcon

  if (layer.type === 'user') {
    layerShare = userLayerGroup
    layerClass = layerScss.layerBoxTypeImage
  } else if (layer.type === 'shared') {
    layerShare = sharedLayerGroup
  } else if (layer.type === 'org') {
    layerShare = organizationLayerGroup
  } else {
    layerShare = logoDark
    layerClass = layerScss.layerBoxTypeOrgImage
  }

  return (
    <>
      {mountMakeDataConfig && (
        <MakeDataConfigComponent
          onFinish={dataConfigUpdated}
          updateStyle={false}
        />
      )}
      {defaultObjects && (
        <AsyncFetch
          fetchObjects={defaultObjects}
          fetchFinished={handleDefaultsFetchFinished}
        />
      )}
      {defaultUpdateObjects && (
        <AsyncFetch
          fetchObjects={defaultUpdateObjects}
          fetchFinished={handleDefaultUpdateFinished}
        />
      )}
      {
        // Available Layers Fetch on state change
        availableFetchObjects && (
          <AsyncFetch
            fetchObjects={availableFetchObjects}
            fetchFinished={availableFinished}
          />
        )
      }
      {
        // Active Layers Fetch on state change
        activeFetchObjects && (
          <AsyncFetch
            fetchObjects={activeFetchObjects}
            fetchFinished={activeFinished}
          />
        )
      }
      {
        // Api call for updating map
        mapUpdateObjects && (
          <AsyncFetch
            fetchObjects={mapUpdateObjects}
            fetchFinished={mapUpdateFinished}
          />
        )
      }
      {
        // Api call for removals
        activateDeactivateObjects && (
          <AsyncFetch
            fetchObjects={activateDeactivateObjects}
            fetchFinished={activateDeactivateFinished}
          />
        )
      }
      {
        // Resetting Layers to Defaults
        resetObjects && (
          <AsyncFetch
            fetchObjects={resetObjects}
            fetchFinished={layerResetFinished}
          />
        )
      }

      <Tabs
        className={profileScss.userProfile}
        style={{ backgroundColor: '#f1f2f4', overflowY: 'overlay' }}
      >
        <h1>Data</h1>
        <TabList>
          <Tab selectedClassName={profileScss.activeTab}>Layers and Groups</Tab>
          <Tab selectedClassName={profileScss.activeTab}>Map Views</Tab>
          <div className={layerScss.mapSelect}>
            <label>Map Selector</label>
            <select
              defaultValue={user.mapID}
              className={[appScss.altSelect].join(' ')}
              onChange={updateMap}
            >
              {/* <select style={{minWidth: "204px", height: "36px", fontStyle: "italic"}} className={appScss.altSelect} onChange={e => setFilterMap(e.target.value)}> */}
              {mapList.map(map => (
                <option value={map.id} key={'mapid_' + map.id}>
                  {map.name}
                </option>
              ))}
            </select>
          </div>
        </TabList>

        <TabPanel>
          <div
            //  onClick={pageClicked}
            className={scss.usersContainer}
          >
            <div className={scss.adminHeaderLabel}>
              <FontAwesomeIcon
                style={{ marginRight: '7px' }}
                icon={['far', 'filter']}
                size='1x'
              />
              <span style={{ color: '#000' }}>Filters:</span>
            </div>

            {/* ADMIN HEADER WITH FILTERS AND BUTTONS */}
            <div className={scss.adminHeader}>
              <div className={scss.adminFilters}>
                <input
                  style={{
                    maxWidth: '236px',
                    height: '36px',
                    fontStyle: 'italic',
                    textAlign: 'left',
                  }}
                  className={appScss.mainInput}
                  type='search'
                  placeholder='Filter Search'
                  onChange={e => setFilterSearch(e.target.value)}
                />

                {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
                <div
                  className={[
                    layerScss.layerDropdownSelect,
                    dropdownScss.dropdown,
                    scss.adminUserOptions,
                  ].join(' ')}
                  onClick={() => toggleLayerTypeDropdown()}
                >
                  <div>
                    <img
                      src={filterType === '' ? '' : filterType[2]}
                      className={filterType === '' ? '' : filterType[3]}
                    ></img>
                    {filterType === '' ? 'All Layers' : filterType[0]}
                  </div>
                  <FontAwesomeIcon
                    icon={[
                      'fas',
                      showLayerTypeDropdown ? 'chevron-up' : 'chevron-down',
                    ]}
                    size='1x'
                  />
                  {showLayerTypeDropdown ? (
                    <>
                      <div
                        className={dropdownScss.dropdownCover}
                        onClick={() => toggleLayerTypeDropdown()}
                      />
                      <div
                        className={[
                          dropdownScss.dropdownContent,
                          dropdownScss.assignDropdownContent,
                        ].join(' ')}
                        style={{ position: 'absolute', left: '0', top: '36px' }}
                      >
                        <div
                          className={[dropdownScss.assignDropdownItem].join(
                            ' '
                          )}
                          onClick={() => setFilterType('')}
                        >
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '11% 89%',
                            }}
                          >
                            <div></div>
                            <span>All Layers</span>
                          </div>
                        </div>
                        {typeList &&
                          typeList.map(type => (
                            <div
                              /* value={type[1]} */ className={[
                                dropdownScss.assignDropdownItem,
                              ].join(' ')}
                              onClick={() => setFilterType(type)}
                            >
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '11% 89%',
                                }}
                              >
                                <img src={type[2]} className={type[3]}></img>
                                <span>{type[0]}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : null}
                </div>
                {/* -------------------------------------------------------------------------- */}
                <select
                  style={{
                    minWidth: '204px',
                    height: '36px',
                    fontStyle: 'italic',
                  }}
                  className={appScss.altSelect}
                  onChange={e => setLastUpdatedFilter(e.target.value)}
                >
                  <option>Last Updated</option>
                  {lastUpdatedOptions.map(option => (
                    <option
                      value={option.value}
                      key={'lastUpdated_' + option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className={scss.adminButtons}>
                <a
                  className={scss.accountHeaderButtons}
                  onClick={resetting ? null : handleResetLayers}
                >
                  {resetting ? (
                    <FontAwesomeIcon icon='spinner' spin />
                  ) : (
                    <span>Reset Layers</span>
                  )}
                </a>

                <div
                  className={[
                    scss.adminButtons,
                    Object.keys(checkboxList).length
                      ? ''
                      : scss.adminButtons_disabled,
                  ].join(' ')}
                >
                  <a
                    className={scss.accountHeaderButtons}
                    onClick={handleToggleAllBtnClick}
                  >
                    <FontAwesomeIcon
                      className={scss.collapseIcon}
                      icon={[
                        'fa',
                        'chevron-double-' + (allCollapsed() ? 'down' : 'up'),
                      ]}
                      size='1x'
                    />
                  </a>
                </div>
              </div>
            </div>

            {/* ADMIN HEADER WITH TABLE HEADINGS */}
            <div
              className={[
                layerScss.layerHeaderBoxAdmin,
                scss.layerHeaderBox,
                layerScss.layerHeaderGrid,
              ].join(' ')}
            >
              {/* ALT CUSTOM CHECKBOX BELOW */}
              <label
                className={[
                  checkboxScss.checkboxContainer,
                  checkboxScss.altCheckboxContainer,
                ].join(' ')}
                style={{ marginBottom: '8px' }}
              ></label>

              <div className={layerScss.emptyGrid}></div>

              <div className={scss.adminBoxRowItems}>
                <h4
                  className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                  onClick={() => handleSortByClick('name')}
                >
                  Name
                </h4>
                {sortBy.type === 'name' ? (
                  <FontAwesomeIcon
                    icon={
                      sortBy.direction === 'ascending'
                        ? sortDownIcon
                        : sortUpIcon
                    }
                    size='1x'
                    className={scss.adminCursor}
                    onClick={() => handleSortByClick('name')}
                  />
                ) : null}
              </div>

              <div className={layerScss.emptyGrid}></div>

              <div className={scss.adminBoxRowItems}>
                <h4 className={scss.adminHeadings}>Description</h4>
              </div>

              <div className={layerScss.emptyGrid}></div>

              <div
                className={scss.adminBoxRowItems}
                style={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div className={layerScss.layerSortContainer}>
                  <h4
                    className={[
                      layerScss.adminEditedHeadings,
                      scss.adminHeadings,
                      scss.adminCursor,
                    ].join(' ')}
                    onClick={() => handleSortByClick('dateUpdated')}
                  >
                    Last Edited
                  </h4>
                  {sortBy.type === 'dateUpdated' ? (
                    <FontAwesomeIcon
                      icon={
                        sortBy.direction === 'ascending'
                          ? sortDownIcon
                          : sortUpIcon
                      }
                      size='1x'
                      className={scss.adminCursor}
                      onClick={() => handleSortByClick('dateUpdated')}
                    />
                  ) : null}
                </div>
                <div className={layerScss.layerSortContainer}>
                  <h4
                    className={[
                      layerScss.adminEditedHeadings,
                      scss.adminHeadings,
                      scss.adminCursor,
                    ].join(' ')}
                    onClick={() => handleSortByClick('dateCreated')}
                  >
                    Created
                  </h4>
                  {sortBy.type === 'dateCreated' ? (
                    <FontAwesomeIcon
                      icon={
                        sortBy.direction === 'ascending'
                          ? sortDownIcon
                          : sortUpIcon
                      }
                      size='1x'
                      className={scss.adminCursor}
                      onClick={() => handleSortByClick('dateCreated')}
                    />
                  ) : null}
                </div>
              </div>

              <div className={layerScss.emptyGrid}></div>

              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center' }}
              >
                <h4
                  className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                  onClick={() => handleSortByClick('default')}
                >
                  Default
                </h4>
                {sortBy.type === 'default' ? (
                  <FontAwesomeIcon
                    icon={
                      sortBy.direction === 'ascending'
                        ? sortDownIcon
                        : sortUpIcon
                    }
                    size='1x'
                    className={scss.adminCursor}
                    onClick={() => handleSortByClick('status')}
                  />
                ) : null}
              </div>

              <div className={layerScss.emptyGrid}></div>

              <div
                className={scss.adminBoxRowItems}
                style={{ justifyContent: 'center' }}
              >
                <h4
                  className={[scss.adminHeadings, scss.adminCursor].join(' ')}
                  onClick={() => handleSortByClick('status')}
                >
                  Status
                </h4>
                {sortBy.type === 'status' ? (
                  <FontAwesomeIcon
                    icon={
                      sortBy.direction === 'ascending'
                        ? sortDownIcon
                        : sortUpIcon
                    }
                    size='1x'
                    className={scss.adminCursor}
                    onClick={() => handleSortByClick('status')}
                  />
                ) : null}
              </div>

              <div className={layerScss.emptyGrid}></div>
              <div className={layerScss.emptyGrid}></div>
              <div className={layerScss.emptyGrid}></div>
              <div className={layerScss.emptyGrid}></div>
            </div>

            {(availableLoading || activeLoading) && (
              <>
                <Skeleton height={88} count={7} />
              </>
            )}
            {!availableLoading && !activeLoading && (
              <LayerList
                layerList={layerList}
                setLayerList={setLayerList}
                activeLayerList={activeLayerList}
                toggleLayerChecks={toggleLayerChecks}
                checkLayerFilter={checkLayerFilter}
                getCountChecked={getCountChecked}
                getCheckbox={getCheckbox}
                clickCheckbox={clickCheckbox}
                handleLayerStatus={handleLayerStatus}
                isActiveGroup={isActiveGroup}
                isActiveLayer={isActiveLayer}
                isDefaultLayer={isDefaultLayer}
                listArea={listArea}
                addList={addList}
                removeList={removeList}
                collapsed={collapsed}
                handleCollapse={handleCollapse}
                handleDefaultUpdate={handleDefaultUpdate}
                resetting={resetting}
                defaultLayerFetching={defaultLayerFetching}
                defaultsUpdating={defaultsUpdating}
              />
            )}
          </div>

          <div className={buttonsScss.layerButtons}>
            {/* <button
              style={{ marginRight: '12px' }}
              className={appScss.blueButton}
              onClick={() =>
                checkboxCount > 0 && handleLayerStatus(null, false)
              }
              disabled={Object.keys(checkboxList).length ? false : true}
            >
              Delete
            </button> */}
            {!defaultsUpdating ? (
              <>
                <button
                  className={buttonsScss.layerButton}
                  onClick={() => checkboxCount > 0 && handleAddDefaults()}
                  disabled={Object.keys(checkboxList).length ? false : true}
                >
                  {!defaultsUpdating ? (
                    'Add Default'
                  ) : (
                    <FontAwesomeIcon icon='spinner' spin size='1x' />
                  )}
                </button>
                <button
                  className={buttonsScss.layerButton}
                  onClick={() => checkboxCount > 0 && handleRemoveDefaults()}
                  disabled={Object.keys(checkboxList).length ? false : true}
                >
                  Remove Default
                </button>
              </>
            ) : (
              <button className={buttonsScss.layerButton}>
                <FontAwesomeIcon icon='spinner' spin size='1x' />
              </button>
            )}
            <button
              className={[buttonsScss.layerButton].join(' ')}
              onClick={() => checkboxCount > 0 && handleLayerStatus(null, true)}
              disabled={Object.keys(checkboxList).length ? false : true}
            >
              Activate
            </button>
            <button
              className={buttonsScss.layerButton}
              onClick={() =>
                checkboxCount > 0 && handleLayerStatus(null, false)
              }
              disabled={Object.keys(checkboxList).length ? false : true}
            >
              Deactivate
            </button>
          </div>
        </TabPanel>

        <TabPanel>
          <MapViews />
        </TabPanel>
      </Tabs>
    </>
  )
}
