export default function MapToolTip(
  state = { show: false, tipHtml: null },
  action
) {
  switch (action.type) {
    case 'SET_MAP_TOOL_TIP': {
      return action.payload
    }
    default:
      return state
  }
}
