import React from 'react'
import scss from './TextElement.scss'
import printScss from '../../Print.scss'

function TextElement({
  object,
  handleElementClick,
  handleChangeText,
  handleTextBlur,
  textAreaElements,
}) {
  const wrapperStyle = {
    display: 'flex',
    alignItems: object.verticalAligment,
    opacity: object.opacity + '%',
    backgroundColor: !object.bgTransparent && `${object.bgColor}`,
    borderColor: !object.borderTransparent
      ? `${object.borderColor}`
      : 'transparent',
    borderWidth: object.borderWidth + 'px',
    borderStyle: 'solid',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }
  let paddingTop = 0
  if (object.verticalAligment === 'center') {
    paddingTop = 12
  }
  if (object.verticalAligment === 'flex-end') {
    paddingTop = 24
  }
  // we need to calculate the height of the text area
  // height is based on the number of lines, line height, and fontsize
  // Note: not exactly sure about line height, but this does work so leaving for now
  const lines = object.text.split(/\r*\n/)
  const lineHeight = 1.5
  const lineCount = lines.length + 1
  const height = lineCount * object.fontSize * lineHeight
  let textAlign = 'left'
  if (object.horizontalAligment === 'center') {
    textAlign = 'center'
  }
  if (object.horizontalAligment === 'flex-end') {
    textAlign = 'right'
  }

  return (
    <>
      <div
        onClick={handleElementClick}
        style={wrapperStyle}
        className={
          printScss[textAreaElements == object.id ? 'activeEditWindow' : '']
        }
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <textarea
            className={['text', scss.printTextArea].join(' ')}
            id={object.id}
            style={{
              resize: 'none',
              border: 'none',
              background: 'transparent',
              width: '100%',
              height: `${height}px`,
              color: `${object.textColor}`,
              fontSize: `${object.fontSize}px`,
              fontFamily: `${object.fontFamily}`,
              opacity: `${object.opacity}%`,
              paddingTop: `${paddingTop}px`,
              fontWeight: `${object.fontWeight}`,
              fontStyle: `${object.fontStyle}`,
              textDecoration: `${object.textDecoration}`,
              textAlign: textAlign,
              background: `transparent`,
              border: `none`,
              overflow: `hidden`,
            }}
            onChange={handleChangeText}
            onBlur={handleTextBlur}
            value={object.text}
          />
        </div>
      </div>
    </>
  )
}
export default TextElement
