import authConfig from '../config/authConfig'

import { defaultUser } from '../actions/'

function hydrate(usePrevious = null) {
  if (!usePrevious) {
    let init = {
      mapStyle: null,
      viewport: {
        latitude: null,
        longitude: null,
        zoom: null,
        bearing: null,
        pitch: null,
        width: 500,
        height: 500,
      },
    }
    init['user'] = defaultUser()

    return init
  }
}

function constructRelayStateParam(state) {
  let relayStateParam = 'RPID=' + authConfig.relayingParty + '&'
  Object.keys(state).forEach(function (key) {
    state[key].forEach(function (value) {
      relayStateParam += `${key}=${value}&`
    }, this)
  }, this)
  relayStateParam = relayStateParam.replace(/&$/, '')

  return encodeURIComponent(relayStateParam)
}

function getRelayedState(windowQueryParams) {
  return {
    lat: windowQueryParams.getAll('lat'),
    lng: windowQueryParams.getAll('lng'),
    zoom: windowQueryParams.getAll('zoom'),
    'visibleLayers[]': windowQueryParams.getAll('visibleLayers[]'),
  }
}

const initialState = hydrate()

export { constructRelayStateParam, getRelayedState, initialState }
