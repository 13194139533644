import React from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import { TabItem } from '../TabItem'
import scss from './PropertyList.scss'

export const PropertyTabContent = styled.div`
  min-height: 20px;
  background-color: ${props => (props.isDraggingOver ? '#fff0' : null)};
  border-radius: 4px;
  width: 200px;
`

export const PropertyList = props => {
  const { listId = 'LIST', listType, items } = props

  return (
    <Droppable droppableId={listId} type={listType}>
      {dropProvided => (
        <PropertyTabContent
          className={scss.tabContent}
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          {/* All items belong into specific column. */}
          {items.map((item, index) => (
            <TabItem
              key={item.id}
              item={item}
              index={index}
              provided={dropProvided}
            />
          ))}
          {dropProvided.placeholder}
        </PropertyTabContent>
      )}
    </Droppable>
  )
}
