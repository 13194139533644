import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setToc } from '../../../actions'
import scss from './TocToggle.scss'

export default function TocToggle(props) {
  const dispatch = useDispatch()
  let tocCollapsed = useSelector(state => state.tocCollapsed)

  const handleClick = () => {
    // action triggered from data panel collapse icon, alwasy collpase TOC
    if (!props.childPanel) dispatch(setToc(true))
    // action triggered from Sidebar button
    // if the childPannel === currentPanel the icon data pannel is current, toggle TOC
    if (props.childPanel === props.currentPanel) dispatch(setToc(!tocCollapsed))
  }

  return (
    <div className={scss.tocIcon} onClick={handleClick}>
      <div className={scss.sidebarIconBox}>{props.children}</div>
    </div>
  )
}
