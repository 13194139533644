import { useEffect } from 'react'

const DeleteHotKeys = ({ cancelDelete, handleDeleteClick }) => {
  const handleKeyPress = e => {
    if (e.key === 'Escape') {
      cancelDelete()
    }
    if (e.key === 'Backspace') {
      handleDeleteClick()
    }
    if (e.keyCode === 46) {
      handleDeleteClick()
    }
  }

  const enableKeyEvents = () => {
    const main = document.getElementById('main')
    main.addEventListener('keydown', handleKeyPress, true)
  }

  const disableKeyEvents = () => {
    const main = document.getElementById('main')
    main.removeEventListener('keyup', handleKeyPress, true)
  }

  useEffect(() => {
    enableKeyEvents()
    return () => {
      disableKeyEvents()
    }
  }, [])

  return null
}

export default DeleteHotKeys
