import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../../../utilities/geospatial'
import * as mapUtils from '../../../../../utilities/mapStyle'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import {
  updateMapStyle,
  setDoubleClickZoom,
  setMapCursor,
} from '../../../../../actions'

export default function SelectFeatureForEdit({
  id,
  geometry,
  mapRef,
  setFeatureSelected,
}) {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const overPanel = useSelector(state => state.setOverPanel)
  const [fetchObjects, setFetchObjects] = useState(null)

  const style = mapStyle.toJS()
  const targetLayers = [
    'draw_line_cold',
    'draw_polygon_cold',
    'draw_verticies_cold',
  ]
  if (
    id !== 'draw_line_cold' &&
    id !== 'draw_polygon_cold' &&
    id !== 'draw_verticies_cold'
  ) {
    targetLayers.push(id)
  }

  const [mouseDownCenter, setMouseDownCenter] = useState(false)
  const [clickedFeatureIndex, setClickedFeatureIndex] = useState(0)

  const getMidPoints = feature => {
    // convert feature to line segments
    // get midpoint for each line segment
    const lineSegments = geoUtils.getLineSegments(feature)
    return lineSegments.features.map(feature =>
      geoUtils.getLineMidPoint(feature)
    )
  }

  const findFeaturesUnderClick = event => {
    const mapObj = mapRef.getMap()
    const bbox = [
      [event.offsetX - 5, event.offsetY - 5],
      [event.offsetX + 5, event.offsetY + 5],
    ]
    const clickedFeatures = mapObj.queryRenderedFeatures(bbox, {
      layers: targetLayers,
    })
    Promise.all(clickedFeatures).then(() => {
      getFeatureGeoJson(clickedFeatures)
    })
  }

  const getFeatureGeoJson = clickedFeatures => {
    const targetFeature = clickedFeatures[clickedFeatureIndex]
    if (!targetFeature) return
    const featureId = targetFeature.properties.mamid
    const targetSourceId = targetFeature.source
    const targetSource = mapUtils.getSource(style, targetSourceId)

    if (targetSource.type !== 'geojson') {
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'layer/geojson/get'
      const body = {
        layerID: targetFeature.layer.id,
        featureID: parseInt(featureId),
      }
      dispatch(setMapCursor('wait'))
      setFetchObjects([{ url, body, method }])
    } else {
      const hotFeature = targetSource.data.features.filter(
        feature => feature.id === targetFeature.id
      )
      makeFeatureEditable(hotFeature[0])
    }
  }

  const fetchFinished = results => {
    dispatch(setMapCursor('crosshair'))
    makeFeatureEditable(results[0].data.features[0])
  }

  const makeFeatureEditable = featureGeojson => {
    let hotLayer
    let midpoints
    if (geometry === 'fill') {
      hotLayer = mapUtils.getLayerObject(style, 'draw_polygon_hot')
      midpoints = getMidPoints(featureGeojson)
    }
    if (geometry === 'line') {
      hotLayer = mapUtils.getLayerObject(style, 'draw_line_hot')
      midpoints = getMidPoints(featureGeojson)
    }
    if (geometry === 'circle') {
      hotLayer = mapUtils.getLayerObject(style, 'draw_verticies_hot')
    }

    const hotSourceId = hotLayer.source
    const verticiesId = 'draw_verticies_hot'
    const midPointsId = 'draw_midpoints_hot'

    // empty sources
    let hotSource = emptyLayerSource(style, hotSourceId)
    let verticiesSource = emptyLayerSource(style, verticiesId)
    let midPointSource = emptyLayerSource(style, midPointsId)

    // add hot feature to sources
    hotSource.data.features.push(featureGeojson)

    const pointFeatures = geoUtils.explodeFeature(featureGeojson)
    verticiesSource.data.features = pointFeatures.features

    // update style with new source
    style.sources[hotSourceId] = hotSource
    style.sources[verticiesId] = verticiesSource
    // if midpoints (only for polygons || lines)
    if (midpoints) {
      midPointSource.data.features = midpoints
      style.sources[midPointsId] = midPointSource
    }
    setFeatureSelected(featureGeojson)
    dispatch(updateMapStyle(fromJS(style)))
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const handleMouseDown = e => {
    const center = geoUtils.getMapCenter(viewport)
    if (overPanel) return
    setMouseDownCenter(center)
  }

  const handleMouseUp = e => {

    const center = geoUtils.getMapCenter(viewport)
    
    if (JSON.stringify(center) !== JSON.stringify(mouseDownCenter)) return
    
    if (overPanel) return
    
    findFeaturesUnderClick(e)
  }

  const enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousedown', handleMouseDown, true)
    main.addEventListener('mouseup', handleMouseUp, true)
  }

  const disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousedown', handleMouseDown, true)
    main.removeEventListener('mouseup', handleMouseUp, true)
  }

  useEffect(() => {
    dispatch(setDoubleClickZoom(false))
  }, [])

  useEffect(
    function () {
      enableEventListeners()

      return function cleanup() {
        disableEventListeners()
      }
    },
    [handleMouseDown]
  )

  useEffect(
    function () {
      setFetchObjects(null)
    },
    [fetchObjects]
  )

  return (
    fetchObjects && (
      <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
    )
  )
}
