import React from 'react'
import Line from './Line/Line'
import Vertex from './Vertex/Vertex'
import LayerSelect from './LayerSelect/LayerSelect'

const Snapping = () => {
  return (
    <div>
      <h5>Snapping:</h5>
      <Line />
      <Vertex />
      <LayerSelect />
    </div>
  )
}

export default Snapping
