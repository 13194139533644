import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Login as _Login } from '../../actions'
import LogoIcon from '../../assets/logo/drk-trim-main.svg'

// scss files
import appScss from '../App/App.scss'
import scss from './Login.scss'

import { Alert } from '../../actions'

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = scss.boxedFormPage
    return () => {
      document.getElementsByTagName('body')[0].className = ''
    }
  }, [])

  const validateForm = () => {
    return email.length > 0 && password.length > 0
  }

  const handleError = e => {
    switch (e.code) {
      case 'UserNotConfirmedException':
        Auth.resendSignUp(email)
          .then(e => {
            if (e && e.CodeDeliveryDetails) {
              dispatch(
                Alert({
                  error:
                    'Email Verification Needed: Please check your email to verify your account prior to logging in. We have just sent you another email verification link.',
                })
              )
            }
          })
          .catch(e => {
            console.log('ResendEmail', e)
            dispatch(
              Alert({
                error:
                  'Email Verification Needed: \nThis account needs to be verified by email, but an error occurred: ' +
                  e.message,
              })
            )
          })
        break
      case 'UserNotFoundException':
        e.message = 'Incorrect username or password.'
      case 'PasswordResetRequiredException':
      case 'NotAuthorizedException':
        dispatch(Alert({ error: e.message }))
        break
      default:
        dispatch(Alert({ error: e.message }))
        console.error(e)
        break
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    Auth.signIn(email.toLowerCase(), password)
      .then(loginInfo => {
        // console.warn('Challenge',loginInfo.challengeName)
        if (loginInfo.signInUserSession != null) {
          setIsLoading(false)

          // dispatch(Alert({success: 'Thank you for signing in. Please wait...'}))

          //If on login page and login page only, redirect to home page.
          if (props.location.pathname === '/login') props.history.push('/')

          const userPayload = loginInfo.signInUserSession.idToken.payload
          props.handleLoginAttempt(true, userPayload)
        } else {
          if (loginInfo.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // Get these details and call
            Auth.completeNewPassword(loginInfo, password, {}).then(
              loginInfo => {
                //Now that the password is set, let's re-login.
                handleSubmit(e)
              }
            )
          } else if (loginInfo.challengeName === 'INVALIDEMAIL') {
            // Get these details and call
            Auth.resendSignUp(username)
          }
        }
      })
      .catch(err => {
        handleError(err)
        setIsLoading(false)
      })
  }

  const handleSubmitEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSubmit(e)
    }
  }

  return (
    <div className={scss['login-container']}>
      <img
        className={scss['login-logo']}
        style={{ marginBottom: '54px' }}
        src={LogoIcon}
        alt='My Asset Map Logo'
      />
      <label htmlFor='login-email' className={appScss['input-notched']}>
        <span>Email</span>
        <input
          autoFocus
          id='login-email'
          onChange={e => setEmail(e.target.value)}
          onKeyDown={handleSubmitEnter}
          value={email}
          type='text'
          style={{ textAlign: 'left' }}
        />
      </label>
      <label
        htmlFor='login-password'
        className={appScss['input-notched']}
        style={{ marginTop: '32px' }}
      >
        <span>Password</span>
        <input
          id='login-password'
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleSubmitEnter}
          value={password}
          type='password'
        />
      </label>
      <label
        htmlFor='login-remember-me'
        className={scss['input-checkbox-remember']}
        style={{ margin: '16px 0 24px 0' }}
      >
        <input id='login-remember-me' type='checkbox' defaultChecked />
        Remember Me
      </label>
      <button
        type='button'
        onClick={handleSubmit}
        disabled={!validateForm()}
        className={[
          scss['login-submit-button'],
          scss[isLoading ? 'login-submit-button-loading' : ''],
        ].join(' ')}
      >
        {isLoading ? 'Logging in...' : 'Login'}
      </button>
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/reset'
        >
          Forgot your password?
        </Link>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/verify'
        >
          Verify with code
        </Link>
      </span>
    </div>
  )
}
