export const MEASURE_CONTROL = {
  sourcesArray: [
    {
      id: 'measure_control_label_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_label_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_active_point',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_point_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_line_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },

    {
      id: 'measure_control_line_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_area_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_control_area_hot_outline',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },

    {
      id: 'measure_control_area_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      layer: {
        id: 'measure_control_area_hot',
        type: 'fill',
        source: 'measure_control_area_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },

        paint: {
          'fill-color': '#E2EAFF',
          'fill-opacity': 0.2,
        },
        metadata: {},
      },
    },

    {
      layer: {
        id: 'measure_control_area_cold',
        type: 'fill',
        source: 'measure_control_area_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },

        paint: {
          'fill-color': '#E2EAFF',
          'fill-opacity': 0.2,
        },
        metadata: {},
      },
      outline: {
        id: 'measure_control_area_cold_outline',
        type: 'line',
        source: 'measure_control_area_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },

        paint: {
          'line-color': '#1300FF',
          'line-width': 1.5,
        },
        metadata: {},
      },
    },

    {
      layer: {
        id: 'measure_control_area_hot_outline',
        type: 'line',
        source: 'measure_control_area_hot_outline',
        interactive: false,
        layout: {
          visibility: 'visible',
        },

        paint: {
          'line-color': '#1300FF',
          'line-opacity': 1,
          'line-width': 2,
          'line-dasharray': [1, 2],
        },
        metadata: {},
      },
    },

    {
      layer: {
        id: 'measure_control_line_hot',
        type: 'line',
        source: 'measure_control_line_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },

        paint: {
          'line-color': '#1300FF',
          'line-opacity': 1,
          'line-width': 2,
          'line-dasharray': [1, 2],
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_control_line_cold',
        type: 'line',
        source: 'measure_control_line_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#1300FF',
          'line-width': 1.5,
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_control_point_cold',
        type: 'circle',
        source: 'measure_control_point_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': '#1300FF',
          'circle-radius': 6,
          'circle-opacity': 0.5,
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 1,
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_control_active_point',
        type: 'circle',
        source: 'measure_control_active_point',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': '#E2EAFF',
          'circle-radius': 6,
          'circle-opacity': 0.5,
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 1,
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_control_label_hot',
        type: 'symbol',
        source: 'measure_control_label_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': [
            'format',
            ['get', 'measurement'],
            {},
            '\n',
            {},
            ['get', 'measurementSecondLine'],
            {},
          ],
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            14,
            10,
            15,
            13,
            16,
          ],
        },
        paint: {
          'text-color': '#FFFFFF',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': 'hsl(224, 2%, 18%)',
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_control_label_cold',
        type: 'symbol',
        source: 'measure_control_label_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': [
            'format',
            ['get', 'measurement'],
            {},
            '\n',
            {},
            ['get', 'measurementSecondLine'],
            {},
          ],
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            10,
            10,
            11,
            13,
            12,
            14,
            12,
            15,
            13,
          ],
        },
        paint: {
          'text-color': '#000000',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': '#FFFFFF', //"hsl(224, 2%, 18%)",
        },
        metadata: {},
      },
    },
  ],
}
