import React, { useState, useEffect } from 'react'
import { ReactComponent as LineIcon } from '../../../../assets/icons/draw/DrawLine.svg'
import { ReactComponent as PointIcon } from '../../../../assets/icons/draw/DrawPoint.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import appScss from '../../../App/App.scss'
import scss from './GeometryIndicator.scss'

const GeometryIndicator = React.memo(({ geometry }) => {
  const [tip, setTip] = useState('')
  const icons = {
    fill: <FontAwesomeIcon icon={['fa', 'draw-polygon']} size='1x' />,
    line: <LineIcon className={scss.svg} />,
    circle: <PointIcon className={scss.svg} />,
  }

  useEffect(() => {
    if (geometry) {
      setTip(`Layer Geometry is ${geometry}`)
    }
  }, [geometry])

  return (
    <>
      <div className={scss.wrapper}>
        {geometry ? (
          <div data-tip={tip} className={scss.icon}>
            {icons[geometry]}
          </div>
        ) : null}
      </div>
      <div className={appScss.reactToolTip}>
        <ReactTooltip place='bottom' effect='solid' offset={{ bottom: 10 }} />
      </div>
    </>
  )
})

export default GeometryIndicator
