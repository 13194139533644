import { Storage, API } from 'aws-amplify'
import React, { useEffect } from 'react'
import authConfig from '../../../config/authConfig'

export const eraseFolder = async folderPath => {
  const result = await Storage.remove(folderPath)
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  ref,
  setRenamePopupVisible,
  setIsContextMenuVisible
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // ignores the actions of the buttons that the pop up opened on top of it
        setIsContextMenuVisible(false)
        setRenamePopupVisible(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
