'use strict'

function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex['default'] : ex
}

var React = _interopDefault(require('react'))

var BaseIcon = function BaseIcon(_ref) {
  var color = _ref.color,
    _ref$pushRight = _ref.pushRight,
    pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
    children = _ref.children
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      stroke: color,
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      style: { marginRight: pushRight ? '20px' : '0', minWidth: 24 },
    },
    children
  )
}

var SuccessIcon = function SuccessIcon() {
  return React.createElement(
    'svg',
    {
      fill: '#1b9e42',
      style: { marginRight: '12px', height: '17px' },
      'aria-hidden': 'true',
      focusable: 'false',
      'data-prefix': 'fas',
      'data-icon': 'check-circle',
      className: 'svg-inline--fa fa-check-circle fa-w-16 fa-1x',
      role: 'img',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 512 512',
      color: '#2E9AFE',
    },
    React.createElement('path', {
      d: 'M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z',
    })
  )
}

var InfoIcon = function InfoIcon() {
  return React.createElement(
    'svg',
    {
      fill: '#0044ce',
      style: { marginRight: '12px', height: '17px' },
      'aria-hidden': 'true',
      focusable: 'false',
      'data-prefix': 'fas',
      'data-icon': 'info-circle',
      className: 'svg-inline--fa fa-info-circle fa-w-16 fa-1x',
      role: 'img',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 512 512',
      color: '#2E9AFE',
    },
    React.createElement('path', {
      d: 'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z',
    })
  )
}

var WarningIcon = function WarningIcon() {
  return React.createElement(
    'svg',
    {
      fill: '#f1c21b',
      style: { marginRight: '12px', height: '17px' },
      'aria-hidden': 'true',
      focusable: 'false',
      'data-prefix': 'fas',
      'data-icon': 'exclamation-circle',
      className: 'svg-inline--fa fa-exclamation-circle fa-w-16 fa-1x',
      role: 'img',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 512 512',
      color: '#2E9AFE',
    },
    React.createElement('path', {
      d: 'M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z',
    })
  )
}

var ErrorIcon = function ErrorIcon() {
  return React.createElement(
    'svg',
    {
      fill: '#d91b24',
      style: { marginRight: '12px', height: '17px' },
      'aria-hidden': 'true',
      focusable: 'false',
      'data-prefix': 'fas',
      'data-icon': 'times-octagon',
      className: 'svg-inline--fa fa-times-octagon fa-w-16 fa-1x',
      role: 'img',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 512 512',
      color: '#2E9AFE',
    },
    React.createElement('path', {
      d: 'M497.9 150.5c9 9 14.1 21.2 14.1 33.9v143.1c0 12.7-5.1 24.9-14.1 33.9L361.5 497.9c-9 9-21.2 14.1-33.9 14.1H184.5c-12.7 0-24.9-5.1-33.9-14.1L14.1 361.5c-9-9-14.1-21.2-14.1-33.9V184.5c0-12.7 5.1-24.9 14.1-33.9L150.5 14.1c9-9 21.2-14.1 33.9-14.1h143.1c12.7 0 24.9 5.1 33.9 14.1l136.5 136.4zM377.6 338c4.7-4.7 4.7-12.3 0-17l-65-65 65.1-65.1c4.7-4.7 4.7-12.3 0-17L338 134.4c-4.7-4.7-12.3-4.7-17 0l-65 65-65.1-65.1c-4.7-4.7-12.3-4.7-17 0L134.4 174c-4.7 4.7-4.7 12.3 0 17l65.1 65.1-65.1 65.1c-4.7 4.7-4.7 12.3 0 17l39.6 39.6c4.7 4.7 12.3 4.7 17 0l65.1-65.1 65.1 65.1c4.7 4.7 12.3 4.7 17 0l39.4-39.8z',
    })
  )
}

var CloseIcon = function CloseIcon() {
  return React.createElement(
    'svg',
    {
      fill: '#1a2937',
      'aria-hidden': 'true',
      focusable: 'false',
      'data-prefix': 'fas',
      'data-icon': 'times',
      className: 'svg-inline--fa fa-times fa-w-16 fa-1x',
      role: 'img',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 512 512',
      color: '#2E9AFE',
    },
    React.createElement('path', {
      d: 'M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z',
    })
  )
}

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i]

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }

    return target
  }

var alertStyle = {
  // backgroundColor: '#151515',
  // color: 'white',
  // padding: '10px',
  // textTransform: 'uppercase',
  // borderRadius: '3px',
  // display: 'flex',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  // boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  // fontFamily: 'Arial',
  // width: '300px',
  // boxSizing: 'border-box'
}

var InfoAlertStyle = {
  padding: '10px',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '344px',
  boxSizing: 'border-box',

  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '-0.09px',
  color: '#1a2937',
  backgroundColor: 'rgba(237, 245, 255, 0.85)',
  borderLeft: 'solid 2px #0044ce',
}

var SuccessAlertStyle = {
  padding: '10px',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '344px',
  boxSizing: 'border-box',

  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '-0.09px',
  color: '#1a2937',
  backgroundColor: '#defbe6',
  borderLeft: 'solid 2px #1b9e42',
}

var ErrorAlertStyle = {
  padding: '10px',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '344px',
  boxSizing: 'border-box',

  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '-0.09px',
  color: '#1a2937',
  backgroundColor: '#fff1f1',
  borderLeft: 'solid 2px #d91b24',
}

var WarningAlertStyle = {
  padding: '10px',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '344px',
  boxSizing: 'border-box',

  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '-0.09px',
  color: '#1a2937',
  backgroundColor: '#fff8e1',
  borderLeft: 'solid 2px #f1c21b',
}

var buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#1a2937',
}

var AlertTemplate = function AlertTemplate(_ref) {
  var message = _ref.message,
    options = _ref.options,
    style = _ref.style,
    close = _ref.close

  if (typeof message !== 'string') {
    try {
      message = JSON.stringify(message)
    } catch (e) {
      message = 'Unknown error'
    }
  }
  return React.createElement(
    'div',
    { style: _extends({}, alertStyle, style) },

    options.type === 'info' &&
      React.createElement(
        // Info Alert Template
        'div',
        { style: InfoAlertStyle },

        React.createElement(InfoIcon, null),

        React.createElement('span', { style: { flex: 2 } }, message),

        React.createElement(
          'button',
          { onClick: close, style: buttonStyle },
          React.createElement(CloseIcon, null)
        )
      ),

    options.type === 'success' &&
      React.createElement(
        // Success Alert Template
        'div',
        { style: SuccessAlertStyle },

        React.createElement(SuccessIcon, null),

        React.createElement('span', { style: { flex: 2 } }, message),

        React.createElement(
          'button',
          { onClick: close, style: buttonStyle },
          React.createElement(CloseIcon, null)
        )
      ),

    options.type === 'error' &&
      React.createElement(
        // Error Alert Template
        'div',
        { style: ErrorAlertStyle },

        React.createElement(ErrorIcon, null),

        React.createElement('span', { style: { flex: 2 } }, message),

        React.createElement(
          'button',
          { onClick: close, style: buttonStyle },
          React.createElement(CloseIcon, null)
        )
      ),

    options.type === 'warning' &&
      React.createElement(
        // Error Alert Template
        'div',
        { style: WarningAlertStyle },

        React.createElement(WarningIcon, null),

        React.createElement('span', { style: { flex: 2 } }, message),

        React.createElement(
          'button',
          { onClick: close, style: buttonStyle },
          React.createElement(CloseIcon, null)
        )
      )
  )
}

module.exports = AlertTemplate
