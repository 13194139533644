import { clone } from '../../../../utilities/geospatial'
import { getLayerConfigUsingLayerId } from '../../../../utilities/dataConfig'
export const getExpressionType = expression => {
  const expressionFlat = expression.flat().join(',')
  let expressionType = null
  if (expressionFlat.indexOf('interpolate,linear,zoom') !== -1) {
    // zoom expression
    expressionType = 'zoomBased'
  }
  if (expressionFlat.indexOf('case,==,get') !== -1) {
    // data expression
    expressionType = 'dataBased'
  }
  return expressionType
}

export const getZoomBasedValue = (expression, viewport) => {
  const expressionClone = clone(expression)
  // remove the text elements ("interpolate,linear,zoom") from expressionClone
  expressionClone.splice(0, 3)
  let expressionValue = null
  let expressionZoom = null
  let returnValue = null
  expressionClone.forEach((item, index) => {
    // items in expression are zoom, value, zoom , value , n....
    // so we want to check compare zoom item against the viewport zoom
    // when the expression zoom is greater than the viewport zoom, set the return value to the current expressionValue
    if (returnValue) return
    if (index === 0 || index % 2 === 0) {
      // the item is a zoom value
      expressionZoom = item
    } else {
      // the item is an expression value
      expressionValue = item
    }
    if (expressionZoom && expressionZoom > viewport.zoom) {
      returnValue = expressionValue
    }
  })
  // if the forEach finishes and there is no return value, set return value to last item in expressionClone
  if (!returnValue) returnValue = expressionClone[expressionClone.length - 1]

  return returnValue
}

export const getDataBasedValue = expression => {
  const expressionClone = clone(expression)
  const values = []
  let reduce = expressionClone.reduce(
    (accumulator, currentValue, currentIndex, array) => {
      if (currentIndex % 2 !== 0) {
        accumulator.push(array.slice(currentIndex, currentIndex + 2))
      }
      return accumulator
    },
    []
  )
  // remove last value, this is the default color
  let defaultValue = reduce.pop()

  reduce.forEach(item => {
    const value = item[0][2]
    const color = item[1]
    values.push([value, color])
  })

  values.push(['default', defaultValue[0]])

  return values
}

export const getSwatchLabel = (layer, dataConfig) => {
  if (!dataConfig) {
    return layer.metadata.label || layer.id
  }
  const configLayer = getLayerConfigUsingLayerId(dataConfig, layer.id)

  if (configLayer && configLayer.toc) {
    return configLayer.toc.label
  } else {
    return layer.metadata.label || layer.id
  }
}
