import React, { Component } from 'react'
import { Popup } from 'react-map-gl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Rnd } from 'react-rnd'

// components
import Tabs from '../../components/Tabs/Tabs'
import PopupSettings from './PopupSettings/PopupSettings'
import AttributeTab from './AttributeTab'
import AttributeNoTab from './AttributeNoTab'
import DocumentNoTab from './DocumentNoTab'
import PopupHeader from './PopupHeader'
import AttributeMeasureButtons from './AttributeMeasureButtons'
import AttributeDocumentButtons from './AttributeDocumentButtons'
import DocumentTab from './DocumentTab'
import PopupFooter from './PopupFooter'

// utilities
import { apis } from '../../config/apiConfig'
import { apiFetch } from '../../utilities/api'
import MakeDataConfigComponent, {
  getCommonLayerConfigUsingLayerId,
  getLayerConfigUsingLayerId,
} from '../../utilities/dataConfig'
import { updateMapStyle, createPopup, setOverPanel } from '../../actions/index'

// scss files
import scss from './MapPopup.scss'
import toolScss from '../Tools/Tools.scss'
import './MapPopupTip.css'

class MapPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      layerId: null,
      config: null,
      index: 0,
      layerProperties: {},
      dataConfigLayer: null,
      edit: false,
      featureProperties: null,
      openSettings: false,
      fetchingSettings: false,
      urlObjects: null,
      mountMakeDataConfig: null,
      active: false,
      dockPopup: false,
      forceSave: false,
      addDocumentRowVisible: false,
      forceUpdatePanel: false,
      isOnTool: false,
      isDragging: false,
    }

    this.reRender = this.reRender.bind(this)
  }

  componentDidMount() {
    this.initialRender()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.forceUpdatePanel !== this.state.forceUpdatePanel) {
      if (this.state.forceUpdatePanel) {
        this.setState(
          { layerProperties: {}, mountMakeDataConfig: true },
          () => {
            this.initialRender()
            this.setState({
              mountMakeDataConfig: false,
              forceUpdatePanel: false,
            })
          }
        )
      }
    }
  }

  componentWillUnmount() {
    this.props.setOverPanel(false)
  }

  initialRender() {
    const layerId = this.getLayerId()
    this.getProperties()
    const dataConfigLayer = this.getDataConfigLayer()
    const featureProperties = this.getFeatureProperties()
    const popupConfig = this.getPopupConfig()
    this.setToState(layerId, dataConfigLayer, featureProperties, popupConfig)
  }

  reRender() {
    this.setState({ forceUpdatePanel: true })
  }

  setToState = (layerId, dataConfigLayer, featureProperties, popupConfig) => {
    this.setState({ layerId, dataConfigLayer, featureProperties, popupConfig })
  }

  getLayerId = () => {
    const { popupInfo } = this.props
    const { index } = this.state
    const layerId = popupInfo.features[index].layer.id
    return layerId
  }

  getProperties = async () => {
    const { popupInfo } = this.props
    let { index, layerProperties } = this.state
    const layerId = popupInfo.features[index].layer.id
    if (layerId === 'geocode_results') {
      layerProperties[layerId] = [
        {
          key: 'location',
          name: 'location',
        },
      ]
      this.setState({ layerProperties })
    } else if (layerId === 'coordiantes_results') {
      layerProperties[layerId] = [
        {
          key: 'latitude',
          name: 'latitude',
          type: 'text',
        },
        {
          key: 'longitude',
          name: 'longitude',
          type: 'text',
        },
      ]
      this.setState({ layerProperties })
    } else if (layerId === 'geospatial_results_fill') {
      layerProperties[layerId] = [
        {
          key: 'area',
          name: 'area',
          type: 'text',
        },
      ]
      this.setState({ layerProperties })
    } else {
      if (layerProperties[layerId]) return
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'layer/properties/get'
      const bodyParams = {
        layerID: layerId,
        unique: false,
      }
      try {
        await apiFetch(url, method, bodyParams, result => {
          layerProperties[layerId] = result.data
          this.setState({ layerProperties })
          /*
          this.setState({ layerProperties, mountMakeDataConfig: true }, () => {
            this.setState({ mountMakeDataConfig: false });
          });
          */
        })
      } catch (error) {
        console.log('MapPopup API CALL ERROR:', error)
      }
    }
  }

  dataConfigUpdated = () => {}

  getDataConfigLayer = () => {
    const { popupInfo, dataConfig } = this.props
    const { index } = this.state
    const layerId = popupInfo.features[index].layer.id
    let dataConfigLayer = null
    if (
      popupInfo.features[index].layer.metadata &&
      popupInfo.features[index].layer.metadata.commonLayer
    ) {
      dataConfigLayer = getCommonLayerConfigUsingLayerId(dataConfig, layerId)
    } else {
      dataConfigLayer = getLayerConfigUsingLayerId(dataConfig, layerId)
    }

    return dataConfigLayer
  }

  getFeatureProperties = () => {
    const { popupInfo } = this.props
    const { index } = this.state
    const featureProperties = popupInfo.features[index].properties
    return featureProperties
  }

  getPopupConfig = () => {
    const { popupInfo, dataConfig } = this.props

    const { index } = this.state
    let tabs
    let properties
    let configLayer = null
    if (
      popupInfo.features[index].layer.metadata &&
      popupInfo.features[index].layer.metadata.commonLayer
    ) {
      configLayer = getCommonLayerConfigUsingLayerId(
        dataConfig,
        popupInfo.features[index].layer.id
      )
    } else {
      configLayer = getLayerConfigUsingLayerId(
        dataConfig,
        popupInfo.features[index].layer.id
      )
    }

    let defaultConfig = {}

    const { popup } = configLayer.layersArray[0].layer.metadata
    if (popup && popup.tabs) {
      tabs = popup.tabs
    }
    if (popup && popup.properties) {
      properties = popup.properties // properties config are for common layers only
    }

    if (configLayer && configLayer.toc && configLayer.toc.type === 'user') {
      // if user layer use toc label

      defaultConfig = { label: configLayer.toc.label, tabs }
    } else {
      if (popupInfo.features[index].layer.metadata.label) {
        defaultConfig = {
          label: popupInfo.features[index].layer.metadata.label,
          tabs,
          properties,
        }
      } else {
        defaultConfig = {
          label: popupInfo.features[index].layer.id,
          tabs,
          properties,
        }
      }
    }

    let popupConfig = defaultConfig
    return popupConfig
  }

  openSettings = () => {
    this.setState({ openSettings: true, fetchingSettings: true }, () => {
      this.setState({ fetchingSettings: false })
    })
  }

  closeSettings = () => this.setState({ openSettings: false })

  makePopupEditable = () => {
    this.props.setOverPanel(true)
    this.setState({ edit: true })
  }

  cancelEdits = () => {
    this.props.setOverPanel(false)
    this.setState({ edit: false })
  }

  handleAddDocumentRowVisible = () => {
    this.setState({ addDocumentRowVisible: !this.state.addDocumentRowVisible })
  }

  handleInputChange = e => {
    let { featureProperties } = this.state
    featureProperties[e.target.name] = e.target.value
    this.setState({ featureProperties })
  }

  handleCurrencyInputChange = (e, maskedValue, floatValue) => {
    let { featureProperties } = this.state
    featureProperties[e.target.name] = floatValue !== 0 ? floatValue : null
    this.setState({ featureProperties })
  }

  handleDateChange = (date, name) => {
    // date is passed in as full string
    // convert to ISO and save to state
    // ISO is format saved in database
    let isoDate = null
    if (date instanceof Date) isoDate = date.toISOString()

    let { featureProperties } = this.state
    featureProperties[name] = isoDate
    this.setState({ featureProperties })
  }

  handleSelectChange = (e, key) => {
    let { featureProperties } = this.state
    featureProperties[key] = e.target.value
    this.setState({ featureProperties })
  }

  handleDocumentChange = (documentKey, filePath, fileName) => {
    if (!documentKey) return

    let newValue
    if (filePath && fileName) {
      filePath = filePath.substr(0, filePath.length - fileName.length)

      newValue = `${filePath}|${fileName}`
    } else newValue = ''

    let { featureProperties } = this.state
    featureProperties[documentKey] = newValue

    this.setState({
      featureProperties,
      forceSave: true,
    })
  }

  forceSaveFinish = () => {
    this.setState({
      forceSave: false,
    })
  }

  popupForward = () => {
    const { popupInfo } = this.props
    let { index } = this.state
    const clickedFeatureCount = popupInfo.features.length
    if (index + 1 === clickedFeatureCount) {
      index = 0
    } else {
      index++
    }
    this.setState({ index }, () => {
      this.props.setClickedFeature(popupInfo.features[index])
      const layerId = this.getLayerId()
      this.getProperties()
      const dataConfigLayer = this.getDataConfigLayer()
      const featureProperties = this.getFeatureProperties()
      const popupConfig = this.getPopupConfig()
      this.setToState(layerId, dataConfigLayer, featureProperties, popupConfig)
    })
  }

  popupBackward = () => {
    const { popupInfo } = this.props
    let { index } = this.state
    const clickedFeatureCount = popupInfo.features.length
    if (index === 0) {
      index = clickedFeatureCount - 1
    } else {
      index--
    }
    this.setState({ index }, () => {
      this.props.setClickedFeature(popupInfo.features[index])
      const layerId = this.getLayerId()
      this.getProperties()
      const dataConfigLayer = this.getDataConfigLayer()
      const featureProperties = this.getFeatureProperties()
      const popupConfig = this.getPopupConfig()
      this.setToState(layerId, dataConfigLayer, featureProperties, popupConfig)
    })
  }

  getPopupSettingstUi = () => {
    const { openSettings, layerProperties, layerId, popupConfig } = this.state
    return (
      openSettings && (
        <PopupSettings
          modalOpen={openSettings}
          onClose={this.closeSettings}
          layerProperties={layerProperties[layerId]}
          layerId={layerId}
          mapStyle={this.props.mapStyle}
          config={popupConfig}
        />
      )
    )
  }

  handleDockPopup = () => this.setState({ dockPopup: !this.state.dockPopup })

  render() {
    const { popupConfig, mountMakeDataConfig } = this.state
    const { popupInfo } = this.props

    if (!popupInfo) return null
    if (!popupConfig) return null

    const popupSettingsUi = this.getPopupSettingstUi()

    const AttributeSystem =
      popupConfig &&
      popupConfig.tabs &&
      Object.keys(popupConfig.tabs).length > 1
        ? AttributeTab
        : AttributeNoTab
        
    const DocumentSystem =
      popupConfig &&
      popupConfig.tabs &&
      Object.keys(popupConfig.tabs).length > 1
        ? DocumentTab
        : DocumentNoTab

    const ui = (
      <div
        className={
          this.props.popupDocked
            ? scss['feature-docked'] + ' feature-docked'
            : ''
        }
      >
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={this.dataConfigUpdated} />
        )}
        {popupSettingsUi}
        <Popup
          tipSize={5}
          anchor='top'
          longitude={popupInfo.lngLat[0]}
          latitude={popupInfo.lngLat[1]}
          captureScroll={true}
          captureClick={true}
          closeOnClick={false}
          closeButton={false}
          className={[scss['feature-container']].join(' ')}
          onMouseEnter={handleEnterTool}
          onMouseLeave={handleLeaveTool}
        >
          <PopupHeader
            closePopup={this.props.closePopup}
            mamId={this.state.featureProperties.mamid}
            featureProperties={this.state.featureProperties}
            config={popupConfig}
            active={this.state.active}
            layerId={this.state.layerId}
            layerProperties={this.state.layerProperties}
            popupInfo={this.props.popupInfo}
            index={this.state.index}
            dataConfigLayer={this.state.dataConfigLayer}
            fetchingSettings={this.state.fetchingSettings}
            edit={this.state.edit}
            popupBackward={this.popupBackward}
            popupForward={this.popupForward}
            makePopupEditable={this.makePopupEditable}
            cancelEdits={this.cancelEdits}
            openSettings={this.openSettings}
          />

          {/* LEFT TAB CONTROLLER */}
          <div className={[scss['feature-tabs']].join(' ')}>
            <Tabs customClass='leftTabs'>
              <div icon='poll-h' label='Attributes'>
                <div className={scss['feature-tabs-header']}>
                  <span>Attributes</span>
                  {/* Attribute measurement buttons */}
                  <AttributeMeasureButtons
                    closePopup={this.props.closePopup}
                    mamId={this.state.featureProperties.mamid}
                    featureProperties={this.state.featureProperties}
                    config={popupConfig}
                    active={this.state.active}
                    layerId={this.state.layerId}
                    layerProperties={this.state.layerProperties}
                    popupInfo={this.props.popupInfo}
                    index={this.state.index}
                    dataConfigLayer={this.state.dataConfigLayer}
                    fetchingSettings={this.state.fetchingSettings}
                    edit={this.state.edit}
                    popupBackward={this.popupBackward}
                    popupForward={this.popupForward}
                    makePopupEditable={this.makePopupEditable}
                    cancelEdits={this.cancelEdits}
                    openSettings={this.openSettings}
                  />
                </div>
                <AttributeSystem
                  config={popupConfig}
                  layerProperties={this.state.layerProperties}
                  layerId={this.state.layerId}
                  edit={this.state.edit}
                  featureProperties={this.state.featureProperties}
                  popupInfo={this.props.popupInfo}
                  index={this.state.index}
                  handleInputChange={this.handleInputChange}
                  handleSelectChange={this.handleSelectChange}
                  handleDateChange={this.handleDateChange}
                  handleCurrencyInputChange={this.handleCurrencyInputChange}
                  handleDocumentChange={this.handleDocumentChange}
                />
                <PopupFooter
                  layerId={this.state.layerId}
                  featureProperties={this.state.featureProperties}
                  layerProperties={this.state.layerProperties}
                  popupInfo={this.props.popupInfo}
                  index={this.state.index}
                  dataConfigLayer={this.state.dataConfigLayer}
                  fetchingSettings={this.state.fetchingSettings}
                  edit={this.state.edit}
                  popupBackward={this.popupBackward}
                  popupForward={this.popupForward}
                  makePopupEditable={this.makePopupEditable}
                  cancelEdits={this.cancelEdits}
                  openSettings={this.openSettings}
                  closePopup={this.props.closePopup}
                  handleDockPopup={this.handleDockPopup}
                />
              </div>
              <div icon='file-alt' label='Documents'>
                <div className={scss['feature-tabs-header']}>
                  <span>Documents</span>
                  {/* Add Document and Measurement buttons */}
                  <AttributeDocumentButtons
                    closePopup={this.props.closePopup}
                    mamId={this.state.featureProperties.mamid}
                    featureProperties={this.state.featureProperties}
                    config={popupConfig}
                    active={this.state.active}
                    layerId={this.state.layerId}
                    layerProperties={this.state.layerProperties}
                    popupInfo={this.props.popupInfo}
                    index={this.state.index}
                    dataConfigLayer={this.state.dataConfigLayer}
                    fetchingSettings={this.state.fetchingSettings}
                    edit={this.state.edit}
                    popupBackward={this.popupBackward}
                    popupForward={this.popupForward}
                    makePopupEditable={this.makePopupEditable}
                    cancelEdits={this.cancelEdits}
                    openSettings={this.openSettings}
                  />
                </div>
                <DocumentSystem
                  config={popupConfig}
                  layerProperties={this.state.layerProperties}
                  layerId={this.state.layerId}
                  featureProperties={this.state.featureProperties}
                  popupInfo={this.props.popupInfo}
                  index={this.state.index}
                  dataConfigLayer={this.state.dataConfigLayer}
                  handleDocumentChange={this.handleDocumentChange}
                  getProperties={this.getProperties}
                  addDocumentRowVisible={this.state.addDocumentRowVisible}
                  handleAddDocumentRowVisible={this.handleAddDocumentRowVisible}
                  reRender={this.reRender}
                />
                <PopupFooter
                  layerId={this.state.layerId}
                  featureProperties={this.state.featureProperties}
                  layerProperties={this.state.layerProperties}
                  popupInfo={this.props.popupInfo}
                  index={this.state.index}
                  dataConfigLayer={this.state.dataConfigLayer}
                  fetchingSettings={this.state.fetchingSettings}
                  edit={this.state.edit}
                  popupBackward={this.popupBackward}
                  popupForward={this.popupForward}
                  makePopupEditable={this.makePopupEditable}
                  cancelEdits={this.cancelEdits}
                  openSettings={this.openSettings}
                  closePopup={this.props.closePopup}
                  handleDockPopup={this.handleDockPopup}
                  forceSave={this.state.forceSave}
                  forceSaveFinish={this.forceSaveFinish}
                  handleAddDocumentRowVisible={this.handleAddDocumentRowVisible}
                  documentTab={true}
                />
              </div>
            </Tabs>
          </div>
        </Popup>
      </div>
    )

    const handleDrag = event => {}

    const handleToolDragStart = () => {
      this.setState({ isDragging: true })
      if (!this.state.isOnTool) {
        this.props.disableMap()
      }
    }

    const handleToolDragStop = () => {
      this.setState({ isDragging: false })
      if (!this.state.isOnTool) {
        this.props.enableMap()
      }
    }

    const handleEnterTool = () => {
      this.setState({ isOnTool: true })
      if (!this.state.isDragging) {
        this.props.disableMap()
      }
    }

    const handleLeaveTool = () => {
      this.setState({ isOnTool: false })
      if (!this.state.isDragging) {
        this.props.enableMap()
      }
    }

    return this.props.popupDocked ? (
      <Rnd
        className={[
          toolScss.panelDraggableContainer,
          scss['feature-draggable'],
        ].join(' ')}
        key={this.state.featureProperties.mamid + '-feature-draggable'}
        style={{
          zIndex: this.props.componentOnTop === 'feature-popup' ? 1000 : 2,
        }}
        dragHandleClassName='handle'
        bounds='#mapContainer'
        scale={1}
        onClick={() => this.props.setComponentOnTop('feature-popup')}
        onDragStart={handleToolDragStart}
        onResizeStart={handleToolDragStart}
        onDrag={handleDrag}
        onDragStop={handleToolDragStop}
        onResizeStop={handleToolDragStop}
        default={{
          x: 10,
          y: 10,
          height: 375,
          width: 400,
        }}
        minHeight={375}
        minWidth={400}
      >
        {ui}
      </Rnd>
    ) : (
      ui
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
      setOverPanel: setOverPanel,
      createPopup: createPopup,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    viewport: state.viewport,
    mapStyle: state.mapStyle,
    toolConfig: state.toolConfig,
    doubleClickZoom: state.doubleClickZoom,
    popupDocked: state.popupDocked,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapPopup)
