import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getMouseCoords } from '../../../utilities/geospatial'
import { numberFormat } from '../../../utilities/util'
import scss from './MeasureMode.scss'
const Coordinates = () => {
  const mapZoom = useSelector(state => state.setMapZoom)
  const viewport = useSelector(state => state.viewport)
  const [latLoc, setLatLoc] = useState(null)
  const [lonLoc, setLonLoc] = useState(null)

  const handleMouseMove = e => {
    const coords = getMouseCoords(
      e.layerX || e.offsetX,
      e.layerY || e.offsetY,
      viewport
    )

    setLonLoc(numberFormat(coords[0], 5))
    setLatLoc(numberFormat(coords[1], 5))
  }

  const enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousemove', handleMouseMove, true)
  }

  const disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousemove', handleMouseMove, true)
  }

  useEffect(function () {
    enableEventListeners()
    return function cleanup() {
      disableEventListeners()
    }
  }, [])

  return (
    <div className={scss['measure-coordinates']}>
      {latLoc || lonLoc || mapZoom ? (
        <>
          <span className={scss['measure-header']}>Pointer Coordinates:</span>
          <div className={scss['measure-coordinates-row']}>
            <span>Lat:</span>
            <span>{latLoc ? latLoc : '-'}</span>
          </div>
          <div className={scss['measure-coordinates-row']}>
            <span>Lon:</span>
            <span>{lonLoc ? lonLoc : '-'}</span>
          </div>
          <div className={scss['measure-coordinates-row']}>
            <span>Zoom:</span>
            <span>{mapZoom ? numberFormat(mapZoom, 0) : '-'}</span>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Coordinates
