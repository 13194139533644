/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import { DialogTitle } from '@material-ui/core'
import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import { useSelector } from 'react-redux'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import scss from './RenamePopup.scss'
import modalScss from '../../../CSSModules/Modal.scss'
import * as RenamePopupUtil from './RenamePopupUtil.js'

const RenamePopup = ({
  cwd,
  setRenamePopupVisible,
  setTriggerRefresh,
  setGenerateNewFileTree,
  row,
}) => {
  const [oldFileName, setOldFileName] = useState('old file name')
  const [fileName, setFileName] = useState()
  const [extension, setExtension] = useState('NA')

  const handleRename = () => {
    setRenamePopupVisible(false)

    const renameResult = RenamePopupUtil.renameFile(
      cwd,
      oldFileName + extension,
      fileName + extension
    )

    renameResult.then(res => {
      setTriggerRefresh(true)
    })
  }
  const handleFocus = e => {
    e.currentTarget.select()
  }

  useEffect(() => {
    let nameNoExtension = 'file'
    if (row && row.values && row.values.name)
      nameNoExtension = row.values.name.split('.')[0]

    setFileName(nameNoExtension)
    setOldFileName(nameNoExtension)
  }, [])

  useEffect(() => {
    let extensionOnly = 'extension'
    if (row && row.values && row.values.name) {
      extensionOnly = row.values.name.split('.')
      extensionOnly =
        extensionOnly.length > 1
          ? '.' + extensionOnly[extensionOnly.length - 1]
          : ''
    }
    setExtension(extensionOnly)
  }, [])

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={() => {
          setRenamePopupVisible(false)
        }}
        open
        aria-labelledby='document-browser-dialog'
        maxWidth='lg'
      >
        <DialogTitle
          id='document-browser-title'
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderStyle: 'none',
            background: '#314d68',
          }}
          className={scss.NewFolderPopup}
        >
          New Name
        </DialogTitle>
        <div
          style={{
            height: '75px',
            background: 'rgb(49, 77, 104)',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'center',
            color: '#314d68',
          }}
        >
          x
          <form style={{ width: '80%' }} onSubmit={handleRename}>
            <input
              style={{ background: 'white !important' }}
              className={scss.InputBox}
              type='text'
              value={fileName}
              onChange={e => setFileName(e.target.value)}
              onFocus={e => handleFocus(e)}
              autoFocus
            />
          </form>
        </div>

        <DialogActions
          style={{
            borderStyle: 'none',
            background: '#314d68',
          }}
        >
          <button
            className={scss.CancelButton}
            onClick={() => {
              setRenamePopupVisible(false)
            }}
          >
            CANCEL
          </button>
          <button className={scss.CreateButton} onClick={handleRename}>
            RENAME
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default RenamePopup
