import WebMercatorViewport from 'viewport-mercator-project'
import {
  point,
  points,
  multiPoint,
  polygon,
  lineString,
  multiLineString,
  featureCollection,
} from '@turf/helpers'
import turfLength from '@turf/length'
import turfMidpoint from '@turf/midpoint'
import turfCleanCoords from '@turf/clean-coords'
import turfLineSegment from '@turf/line-segment'
import turfExplode from '@turf/explode'
import turfArea from '@turf/area'
import turfCenterOfMass from '@turf/center-of-mass'
import turfPolygonToLine from '@turf/polygon-to-line'
import turfBuffer from '@turf/buffer'
import turfEnvelope from '@turf/envelope'
import turfBBox from '@turf/bbox'
import turfBBoxPoly from '@turf/bbox-polygon'
import turfPointsWithinPolygon from '@turf/points-within-polygon'
import turfPointOnFeature from '@turf/point-on-feature'
import turfNearestPointOnLine from '@turf/nearest-point-on-line'
import turfNearestPoint from '@turf/nearest-point'
import turfDissolve from '@turf/dissolve'
import turfLineToPolygon from '@turf/line-to-polygon'
import { coordAll } from '@turf/meta'
import { POINT_FEATURE } from '../data/Constants/Constants'

export const clone = json => JSON.parse(JSON.stringify(json))

/**
 * convert screen coords to lat long and round the lat long to 5 decimal degrees
 * @param {int} x - Screen Coords for X
 * @param {int} y - Screen Coords for y
 */
export const getMouseCoords = (x, y, viewport) => {
  if (!viewport) return null
  const round = (value, decimals) =>
    Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  const wmv = new WebMercatorViewport(viewport)
  return wmv.unproject([x, y]).map(coord => round(coord, 7))
}

export const getMapCenter = viewport => {
  const lat = viewport.latitude.toFixed(6).toString()
  const lng = viewport.longitude.toFixed(6).toString()
  return lat + lng
}

export const getPointAtClick = (event, viewport) => {
  const coordinates = getMouseCoords(
    event.layerX || event.offsetX,
    event.layerY || event.offsetY,
    viewport
  )
  const pointClone = clone(POINT_FEATURE)
  pointClone.geometry.coordinates = coordinates
  return pointClone
}

export const getCoordsAtClick = (event, viewport) => {
  const coordinates = getMouseCoords(
    event.layerX || event.offsetX,
    event.layerY || event.offsetY,
    viewport
  )
  return coordinates
}

export const getLength = (feature, units) => {
  const options = { units }
  const len = turfLength(feature, options)
  return len
}

export const getArea = feature => {
  const area = turfArea(feature)
  return area
}

export const getLineMidPoint = feature => {
  const point1 = point(feature.geometry.coordinates[0])
  const point2 = point(feature.geometry.coordinates[1])
  const mid = turfMidpoint(point1, point2)
  mid.properties.pt1 = point1.geometry.coordinates.join(',')
  mid.properties.pt2 = point2.geometry.coordinates.join(',')
  return mid
}

export const getLineEndPoint = feature => {
  const endPoints = turfExplode(feature)
  const endPoint = endPoints.features[endPoints.features.length - 1]
  return endPoint
}

export const getCleanCoords = feature => {
  const clean = turfCleanCoords(feature)
  return clean
}

export const getLineString = coords => {
  const line = lineString(coords)
  return line
}

export const getMultiLineString = coords => {
  const multiLine = multiLineString(coords)
  return multiLine
}

export const getPolygon = coords => {
  const poly = polygon(coords)
  return poly
}

export const getPoint = coords => {
  const pt = point(coords)
  return pt
}

export const getFeatureCollection = features => {
  const fc = featureCollection(features)
  return fc
}

export const getCoordinates = (feature, precision) => {
  // Check for multiPoint or singlePoint with coordinates array.
  const coordinates =
    feature.geometry.coordinates.length === 1
      ? feature.geometry.coordinates[0]
      : feature.geometry.coordinates

  const lat = coordinates[1]
  const lng = coordinates[0]
  return { lat: lat.toFixed(precision), lng: lng.toFixed(precision) }
}

export const getLineSegments = feature => {
  const segments = turfLineSegment(feature)
  return segments
}

export const lineToPolygon = feature => {
  return turfLineToPolygon(feature)
}
export const polygonToLine = feature => {
  const line = turfPolygonToLine(feature)
  return line
}

export const getBuffer = (feature, distance, units) => {
  if (units !== 'feet' && units !== 'miles') {
    console.warn('Units must be feet || miles')
    return null
  }
  let dist = distance
  if (units === 'feet') {
    dist = distance / 5280
  }
  const buffered = turfBuffer(feature, dist, { units: 'miles' })
  return buffered
}

export const getEnvelope = features => {
  const enveloped = turfEnvelope(features)
  return enveloped
}

export const getBBox = features => {
  const bBox = turfBBox(features)
  return bBox
}

export const getBBoxPoly = bbox => {
  const poly = turfBBoxPoly(bbox)
  return poly
}

export const getPointsWithinPolygon = (pointsToCheck, polygonToCheck) => {
  const ptsWithin = turfPointsWithinPolygon(pointsToCheck, polygonToCheck)
  return ptsWithin
}

export const getMultiPointFeatureFromCoords = coords => {
  return multiPoint([coords])
}

export const getPointsFromCoords = coords => {
  return points(coords)
}

export const explodeFeature = feature => {
  return turfExplode(feature)
}

export const getAllCoords = coordFeatureCollection => {
  return coordAll(coordFeatureCollection)
}

export const getCenterOfMass = feature => {
  let center
  try {
    if (feature.geometry.type === 'MultiPolygon') {
      const allCoords = getAllCoords(feature)
      const allPoints = allCoords.map(coords => point(coords))
      const collection = featureCollection(allPoints)
      const envelope = getEnvelope(collection)
      center = turfCenterOfMass(envelope)
    } else {
      center = turfCenterOfMass(feature)
    }
  } catch (err) {
    console.log('turf error ', err)
    center = null
  }
  return center
}

export const firstAndLastCoordEquivalent = coords => {
  const first = coords[0]
  const last = coords[coords.length - 1]
  if (JSON.stringify(first) === JSON.stringify(last)) return true
  return false
}

export const getNearestPointOnLine = (line, pt) => {
  const nearest = turfNearestPointOnLine(line, pt, { units: 'miles' })
  return nearest
}

export const getNearestVertex = (line, pt) => {
  const allPoints = explodeFeature(line)
  const nearest = turfNearestPoint(pt, allPoints)
  return nearest
}

export const dissolveFeatures = (features, propertyObject = {}) => {
  const dissolved = turfDissolve(features, propertyObject)
  return dissolved
}

export const getPointOnFeature = feature => {
  const pointOnFeature = turfPointOnFeature(feature)
  return pointOnFeature
}
