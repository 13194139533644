import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Auth, Storage } from 'aws-amplify'
import AWS from 'aws-sdk'
import { addQueryVar, signRequest } from './api'
import { Alert } from '../actions'
import authConfig from '../config/authConfig'

// ========================================================================
// DOCUMENTATION
// ========================================================================
//
// Receives an array of 'fetchObjects' that house parameters
// ie ([{ url, method, body }]), or ([{ url, method, body }, { url, method, body}])
//
// Returns an array of responses that then need to be mapped through
//
// EXAMPLE RESPONSE
// const fetchFinished = responses => {
//   return responses.map(response => {
//     console.log(response)
//   })
// }

export default function AsyncFetch({
  fetchObjects,
  fetchFinished,
  fetchTitle,
}) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    const getAuth = async url => {
      return await Auth.currentSession().then(result => {
        const cogID = result.idToken.payload.sub
        const { mapID } = user
        let userURL = addQueryVar(url, 'userID', cogID)
        userURL = addQueryVar(userURL, 'mapID', mapID)

        return userURL
      })
    }

    const doFetch = async (userUrl, params) => {
      const contentType = 'application/json'

      if (params.body.mapId) {
        params.body.mapID = user.mapID
      }
      let options = null

      if (params.method === 'GET') {
        options = {
          method: params.method,
          headers: signRequest(userUrl).headers,
        }
      } else if (params.method === 'POST') {
        options = {
          method: params.method,
          body: JSON.stringify(params.body),
          headers: signRequest(
            userUrl,
            params.method,
            JSON.stringify(params.body),
            contentType
          ).headers,
        }
      }

      const response = await fetch(userUrl, options).catch(err => {
        console.log('err', err)
      })

      // if (!response) return {}

      const json = await response.json()
      // for testing purposes
      if (json.success) {
        const message = json.message
        if (message !== null && message !== '')
          dispatch(Alert({ success: message }))
      }

      if (!json.success) {
        const message = json.message
        if (message !== null && message !== '')
          dispatch(Alert({ error: message }))
      }

      //Check for Hydration
      if (json.data && json.data.hydrate) {
        //Hydration Needed
        // console.log('json.data.hydrate :>>', json.data.hydrate)

        const s3 = new AWS.S3()

        var params = {
          Bucket: authConfig.s3Config.bucketName, // your bucket name,
          Key: json.data.hydrate, // path to the object you're looking for
        }

        const result = await s3
          .getObject(params)
          .promise()
          .catch(err => {
            console.log('err', err)
          })
        
        console.log(result)
        const object = result.Body.toString('utf-8')

        //Delete the key
        await s3.deleteObject(params).promise()

        try {
          json.data = JSON.parse(object)
          return json
        } catch (err) {
          json.data = object
          return json
        }
      } else return json
    }

    const processFetchObjects = async fetchObjs => {
      try {
        const promises = fetchObjs.map(async obj => {
          if (obj.paginate) {
            let responseData = []
            let keepGoing = true
            let start = 0

            while (keepGoing) {
              let userUrl = await getAuth(obj.url)
              userUrl = userUrl + `&limit=${obj.paginate}&start=${start}`
              let response = await doFetch(userUrl, obj).catch(err =>
                console.error(err)
              )

              if (
                typeof response.data !== 'undefined' &&
                Array.isArray(response.data)
              ) {
                responseData = [...responseData, ...response.data]
              } else {
                keepGoing = false
                return response
              }

              start += obj.paginate
              if (response.data.length < obj.paginate) {
                keepGoing = false
                // build and return object
                return await {
                  success: response.success,
                  message: response.message,
                  data: responseData,
                }
              }
              if (!response.success) keepGoing = false
              if (!response) keepGoing = false
            }
          } else {
            let userUrl = await getAuth(obj.url)
            return await doFetch(userUrl, obj).catch(err => console.error(err))
          }
        })
        return Promise.all(promises)
      } catch (err) {
        console.log(`error fetching:::, ${err}`)
      }
    }

    processFetchObjects(fetchObjects).then(results => {
      fetchFinished(results)
    })
  }, [])

  return null
}
