export const MEASURE = {
  sourcesArray: [
    {
      id: 'measure_label_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'measure_label_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      layer: {
        id: 'measure_label_hot',
        type: 'symbol',
        source: 'measure_label_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': [
            'format',
            ['get', 'measurement'],
            {},
            '\n',
            {},
            ['get', 'measurementSecondLine'],
            {},
          ],
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            14,
            10,
            15,
            13,
            16,
          ],
        },
        paint: {
          'text-color': '#FFFFFF',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': 'hsl(224, 2%, 18%)',
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'measure_label_cold',
        type: 'symbol',
        source: 'measure_label_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': [
            'format',
            ['get', 'measurement'],
            {},
            '\n',
            {},
            ['get', 'measurementSecondLine'],
            {},
          ],
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            11,
            10,
            13,
            13,
            14,
            14,
            15,
            15,
            16,
          ],
        },
        paint: {
          'text-color': '#FFFFFF',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': 'hsl(224, 2%, 18%)',
        },
        metadata: {},
      },
    },
  ],
}
