export default function (url, keys) {
  return {
    apis: {
      public_data: {
        uri: 'https://my-asset-map-data.s3.amazonaws.com/public_data/',
      },
      apiDatabase: {
        uri: `${url}`,
      },
      userPreferences: {
        uri: `${url}user/preferences/`,
      },
    },
    keys: {
      google: `${keys.google}`,
      agGrid: `${keys.agGrid}`,
    },
    build: process.env.NODE_ENV,
  }
}

/* DATA URLS

Alpha: https://hp29salvwe.execute-api.us-east-1.amazonaws.com/alpha/
Dev: https://rg91b1juf3.execute-api.us-east-1.amazonaws.com/dev/
QA: https://99qya3ubuj.execute-api.us-east-1.amazonaws.com/qa/
Prod: https://egd4avkav9.execute-api.us-east-1.amazonaws.com/prod/

*/
