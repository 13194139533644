import React, { Component } from 'react'
import LogRocket from 'logrocket'

import LogoIcon from '../../assets/logo/drk-trim-main.svg'

import styles from './ErrorBoundary.scss'

import ErrorFallback from './ErrorFallback.jsx'

export default class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      seconds: 5,
    }
  }

  componentDidMount() {
    const { refreshPage } = this.props
    const { hasError } = this.state
    if (hasError && refreshPage)
      this.interval = setInterval(() => {
        this.setState(
          prevState => ({
            seconds: prevState.seconds - 1,
          }),
          () => {
            const { seconds } = this.state
            console.log('seconds', seconds)
            if (seconds === 0) {
              clearInterval(this.interval)
              window.location.reload() // Refresh the page
            }
          }
        )
      }, 1000)
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack)

    const details = info.componentStack.trim().split('\n')
    this.setState({
      error: {
        code: error.number,
        file: error.fileName,
        line: error.lineNumber,
        message: error.message.toString() + ' ' + details.shift(),
        details,
      },
    })

    // Output error to console.
    console.error('Fatal Error', error)
    console.warn('Fatal Stack', info.componentStack)

    // Output error to LogRocket
    if (error) LogRocket.captureException(error)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { hasError, seconds, error } = this.state
    const { refreshPage, fallback, children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      if (fallback) return fallback

      return <ErrorFallback refreshPage={refreshPage} error={error} />
    }

    return children
  }
}
