import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import MapGL, { NavigationControl } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { connect } from 'react-redux'
import { apiFetch } from '../../utilities/api'
import { apis } from '../../config/apiConfig'
import profileScss from './Profile.scss'
import scss from './Layers.scss'
import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

// mapboxgl.accessToken = {mapboxApiAccessToken};

class DefaultMapView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 5,
      lat: 34,
      zoom: 2,
    }
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    })

    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      })
    })

  }

  render() {
    return (
      <div>
        <div className='sidebarStyle'>
          <div>
            Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom:{' '}
            {this.state.zoom}
          </div>
        </div>
        <div ref={el => (this.mapContainer = el)} className='mapContainer' />
      </div>
    )
  }
}

export default DefaultMapView
