import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import { refreshDetails } from '../../../utilities/user'
import * as utils from '../../../utilities/util'
import scss from './MapSelector.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMapHomeLocation } from '../../../utilities/user'
import { setViewPort } from '../../../actions/index'
import ZoomToPoint from '../../../components/ZoomToPoint/ZoomToPoint'

const MapSelector = React.memo(() => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  //const viewport = useSelector(state => state.viewport)
  const [mapList, setMapList] = useState([])
  const [loading, setLoading] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [homeCoordinates, setHomeCoordinates] = useState(null)
  const [homeZoom, setHomeZoom] = useState(null)
  const [zoomToPointKey, setZoomToPointKey] = useState(null)

  let account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  const getMapList = () => {
    let account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
    let mapListNew = account.maps
    setMapList(mapListNew)
  }

  const getHomeLocation = () => {
    const location = getMapHomeLocation(user)
    const coordsArray = [location.y, location.x]
    const zoom = parseFloat(location.z)
    setHomeCoordinates(coordsArray)
    setHomeZoom(zoom)
  }

  const goHome = () => {
    let newKey = zoomToPointKey
    if (!newKey) newKey = 1
    newKey++
    setZoomToPointKey(newKey)
  }

  const buildFetchParams = e => {
    const mapID = e.target.value

    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/update'
    let body = {
      mapID: mapID,
    }

    setFetchObjects([{ url, method, body }])
    setLoading(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setMountMakeDataConfig(true)
    })

    setFetchObjects(null)
  }

  const updateMap = e => {
    buildFetchParams(e)
  }

  const makeDataConfigFinished = dataConfig => {
    setMountMakeDataConfig(false)
    setLoading(false)
    if (homeCoordinates) goHome()
    setLoading(false)
  }

  useEffect(() => {
    getMapList()
    getHomeLocation()
  }, [user])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent
          onFinish={makeDataConfigFinished}
          updateStyle={false}
          resetStyle={true}
        />
      )}
      {zoomToPointKey && (
        <ZoomToPoint
          key={zoomToPointKey}
          //viewport={viewport}
          coordinates={homeCoordinates}
          zoom={homeZoom}
        />
      )}
      <div className={scss.mapSelectorContainer}>
        <div className={scss.mapSelectorHeader}>
          <span className={scss.mapSelectorAccount}>{account.name}</span>
        </div>

        {loading ? (
          <div className={scss.mapSelectorSubhead}>
            <label className={scss.mapSelectorLabel}>Map</label>
            <div className={scss.loadingDiv}>Loading...</div>
          </div>
        ) : (
          <div className={scss.mapSelectorSubhead}>
            <label className={scss.mapSelectorLabel}>Map</label>
            <div className={scss.mapSelector}>
              <select
                key={'map_selector_' + user.accountID + '_' + user.mapID}
                value={user.mapID ? user.mapID : ''}
                onChange={updateMap}
              >
                <option key={'default'} value={'default'}>
                  Map
                </option>
                {mapList.map(map => (
                  <option key={map.id} value={map.id}>
                    {map.name}
                  </option>
                ))}
              </select>
              <FontAwesomeIcon
                icon='chevron-down'
                size='1x'
                pull='right'
                className={scss.mapSelectorIcon}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
})

export default MapSelector
