import React from 'react'
import { useSelector } from 'react-redux'

const Message = () => {
  const mapStyle = useSelector(state => state.mapStyle)
  const style = mapStyle.toJS()

  let polygonColdCount = 0
  let verticesColdCount = 0
  let unsavedFeaturesCount = 0

  if (style.sources.draw_polygon_cold.data.features) {
    polygonColdCount = style.sources.draw_polygon_cold.data.features.length
  }

  if (style.sources.draw_vertices_cold) {
    verticesColdCount = style.sources.draw_vertices_cold.length
  }

  unsavedFeaturesCount = polygonColdCount + verticesColdCount

  return (
    <>
      {unsavedFeaturesCount > 0 ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#213445',
            padding: '0.3em',
            fontSize: 'small',
            borderRadius: '4px',
          }}
        >
          {unsavedFeaturesCount === 1 ? (
            <span> * {unsavedFeaturesCount} Unsaved change </span>
          ) : (
            <span>* {unsavedFeaturesCount} Unsaved changes </span>
          )}
        </div>
      ) : null}
    </>
  )
}

export default Message
