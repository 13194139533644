export const GEO_SPATIAL_RESULTS = {
  toc: {
    label: 'GEOSPATIAL RESULT',
  },
  sourcesArray: [
    {
      id: 'geospatial_results',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      beforeLayer: null,
      layer: {
        id: 'geospatial_results_fill',
        type: 'fill',
        source: 'geospatial_results',
        interactive: true,
        metadata: {
          label: 'GeoSpatial Result',
          popup: {
            label: 'GeoSpatial Result',
          },
        },
        filter: ['==', ['geometry-type'], 'Polygon'],
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-color': 'red',
          'fill-opacity': 0.2,
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'geospatial_results_line',
        type: 'line',
        source: 'geospatial_results',
        interactive: true,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': 'red',
          'line-opacity': 1,
          'line-width': 3,
          'line-dasharray': [2, 2],
        },
        metadata: {},
      },
    },
  ],
}
