import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import {
  setToolConfig,
  setDataTable,
  mountLayerSettings,
} from '../../../../actions/index'
import { getLayerConfigUsingTocId } from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'

import ParentGroupSelect from '../../ParentGroupSelect/ParentGroupSelect'
// import ShareMapView from "./ShareMapView";
import MapViewDelete from './MapViewDelete'
import ShareModal from '../../ShareModal/ShareModal'

import scss from './MapViewInfo.scss'
import appScss from '../../../App/App.scss'
import toolScss from '../../../../_tools.scss'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../../components/MaterialUi/Tool'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'

import { ReactComponent as SharedLayerIcon } from '../../../../assets/icons/tools/SharedLayerGroup.svg'

import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/tools/Archive.svg'
import { ReactComponent as SaveIcon } from '../../../../assets/icons/tools/Save.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/icons/tools/Share.svg'

import logoDark from '../../../../assets/logo/logo-dark.svg'
import organizationLayerGroup from '../../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../../assets/icons/userLayerGroup.svg'

import mainPanelTheme from '../../../../utilities/componentConstants/mainPanelTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ParentMapViewGroupSelect from '../ParentMapViewGroupSelect'

export default function MapViewFooter({ closeTool, config, mountKey }) {
  if (!config || !config.targetMapView) return null
  const toolConfig = useSelector(state => state.toolConfig)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const currentView = config.targetMapView
  const currentViewID = currentView.id
  var owner = currentView.owner
  var role = currentView.role
  var defaultRole = 'Viewer'

  const sharedType = currentView.type
  const canView = currentView.canView || user.isAdmin
  const canEdit = currentView.canEdit || user.isAdmin
  const canDelete = currentView.canDelete || user.isAdmin

  //   const layerId = config.layer.layersArray[0].layer.id;
  //   const type = config.layer.layersArray[0].layer.type;

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mapViewDeleteVisible, setMapViewDelete] = useState(false)
  const [mapViewLabel, setMapViewLabel] = useState(currentView.label)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [mapViewDescription, setMapViewDescription] = useState(
    currentView.description
  )
  const [shareMapViewVisible, setShareMapView] = useState(false)
  const [enableSave, setEnableSave] = useState(false)

  const onLabelChange = e => {
    setMapViewLabel(e.target.value)
    setEnableSave(true)
  }
  const onDescriptionChange = e => {
    setMapViewDescription(e.target.value)
    setEnableSave(true)
  }

  const openMapViewDelete = mapViewDeleteVisible => {
    setMapViewDelete(mapViewDeleteVisible)
  }

  const openShareMapView = shareMapViewVisible =>
    setShareMapView(shareMapViewVisible)

  const doCancel = () => {
    setMapViewLabel(currentView.label)
    setMapViewDescription(currentView.description)
  }

  const buildFetchParams = id => {
    const mapViewID = currentViewID
    let method
    let url
    let body = {}

    if (mapViewID.substr(0, 6) === 'group_') {
      if (id === 'delete') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/group/delete'
        body = {
          groupID: mapViewID,
        }
      } else if (id === 'save') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/group/update'
        body = {
          groupID: mapViewID,
          label: mapViewLabel,
          description: mapViewDescription,
        }
      }
    } else {
      if (id === 'delete') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/delete'
        body = {
          mapViewID: mapViewID,
        }
      } else if (id === 'save') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/update'
        body = {
          mapViewID: mapViewID,
          label: mapViewLabel,
          description: mapViewDescription,
        }
      }
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = () => {
    // archive response
    setMountMakeDataConfig(true)
    closeTool(config.name)

    config.updateMapViews()
    setFetchObjects(null)
  }

  const doSave = () => {
    buildFetchParams('save')
  }

  const doArchive = () => {
    buildFetchParams('archive')
  }

  const doDelete = () => {
    buildFetchParams('delete')
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const handleOnFinish = dataConfig => {
    setFetching(false)
    updateToolConfig(dataConfig)
  }

  const updateToolConfig = dataConfig => {
    // the data for the layer has changed (a new description or layer name)
    // the purpose of this function is to update app state data config with the new layer information
    // and remount the component to preserve the changes
    const updatedLayer = getLayerConfigUsingTocId(dataConfig, currentView)

    // add the newly updated layer to the toolConfig
    if (updatedLayer) {
      toolConfig.forEach(config => {
        if (config.name === 'MapViewSettings') {
          config.layer = updatedLayer
        }
      })
      dispatch(setToolConfig(toolConfig))
      // force the component to remount by updating the mount key
      mountKey++
      dispatch(mountLayerSettings(mountKey))
    }
  }

  //   if (sharedType === "user") {
  //     layerShare = userLayerGroup;
  //     layerStyle = { width: "30px", marginLeft: "-10px", marginBottom: "-4px" };
  //   } else if (sharedType === "shared") {
  //     layerShare = sharedLayerGroup;
  //   } else if (sharedType === "org") {
  //     layerShare = organizationLayerGroup;
  //   } else if (sharedType === "mam") {
  //     owner = "MyAssetMap";
  //     layerShare = logoDark;
  //     layerStyle = { width: "20px", marginBottom: "5px" };
  //   } else {
  //     layerShare = logoDark;
  //     layerStyle = { width: "20px", marginBottom: "5px" };
  //   }

  // let role = "Viewer";
  // if (canView) role = "Viewer";
  // if (canEdit) role = "Editor";

  //   let layerIdForDisplay = layerId.split("_");
  //   layerIdForDisplay = layerIdForDisplay[layerIdForDisplay.length - 1];

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={handleOnFinish} />
      )}
      {mapViewDeleteVisible && (
        <MapViewDelete
          fetching={fetching}
          setFetching={setFetching}
          doDelete={doDelete}
          onClose={() => openMapViewDelete(false)}
          mapViewLabel={mapViewLabel}
          mapViewID={currentViewID}
        />
      )}
      {shareMapViewVisible && (
        <ShareModal
          onClose={() => openShareMapView(false)}
          itemId={currentViewID}
          dataType={
            currentViewID.substr(0, 6) === 'group_' ? 'mapViewGroup' : 'mapView'
          }
        />
      )}

      <div className={[scss.layerFooter, toolScss.toolIcons].join(' ')}>
        <div className={toolScss.toolIconLeftRow}>
          <div
            className={[toolScss.toolIconBox, toolScss.toolTrashIcon].join(' ')}
            onClick={() => openMapViewDelete(true)}
            data-tip='Delete'
            data-for='delete'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <FontAwesomeIcon icon={['fas', 'trash']} size='1x' />
            <div className={appScss.reactToolTip}>
              <ReactTooltip id='delete' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
        </div>

        <div className={toolScss.toolIconRightRow}>
          <div
            className={[
              toolScss.toolIconBox,
              toolScss.toolCloneIcon,
              toolScss[!canEdit ? 'inactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => openShareMapView(true) : null}
            data-tip='Share'
            data-for='share'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <FontAwesomeIcon icon={['fa', 'share-alt']} size='1x' />
            <div className={appScss.reactToolTip}>
              <ReactTooltip id='share' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
