import {
  STYLE_EXPRESSION_SEARCH_COORDINATE,
  STYLE_EXPRESSION_SEARCH_GEOCODE,
} from '../../Expressions/SEARCH_Expressions'

export const COORDINATE_SEARCH = {
  toc: {
    label: 'COORDINATE RESULT',
  },
  sourcesArray: [
    {
      id: 'coordiantes_results',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      layer: {
        id: 'coordiantes_results',
        type: 'symbol',
        source: 'coordiantes_results',
        interactive: true,
        metadata: {
          label: 'Coordinate',
          popup: {
            label: 'Coordinate',
          },
        },
        layout: {
          visibility: 'visible',
          'text-field': String.fromCharCode('0xf276'),
          'text-font': ['Font Awesome 5 Free Solid 2'],
          'text-allow-overlap': true,
          'text-size': STYLE_EXPRESSION_SEARCH_COORDINATE,
          'text-offset': [0, -0.25],
        },
        paint: {
          'text-color': '#fcb000',
          'text-halo-color': '#000000',
          'text-halo-width': 1,
        },
      },
    },
  ],
}

export const GEOCODE = {
  toc: {
    label: 'GEOCODE RESULT',
  },
  sourcesArray: [
    {
      id: 'geocode_results',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      layer: {
        id: 'geocode_results',
        type: 'symbol',
        source: 'geocode_results',
        interactive: true,
        metadata: {
          label: 'Search Result',
          popup: {
            label: 'Search Result',
          },
        },
        layout: {
          visibility: 'visible',
          'text-field': String.fromCharCode('0xf041'),
          'text-font': ['Font Awesome 5 Free Solid 2'],
          'text-allow-overlap': true,
          'text-size': STYLE_EXPRESSION_SEARCH_GEOCODE,
          'text-offset': [0, -0.25],
        },
        paint: {
          'text-color': '#fcb000',
          'text-halo-color': '#000000',
          'text-halo-width': 1,
        },
      },
    },
  ],
}
