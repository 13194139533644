import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import scss from './BlockPicker.scss'
export default function BlockPicker({ blocks, onClick, selected }) {
  return (
    <Grid container direction='row' alignItems='center' spacing={1}>
      {blocks.map(item => {
        let activeClass = ''
        if (selected === item) {
          activeClass = 'active'
        }

        return (
          <Grid item xs={3} key={item.label}>
            <div
              className={[scss.block, scss[activeClass]].join(' ')}
              onClick={() => onClick(item)}
            >
              <div>{item.label}</div>
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}
