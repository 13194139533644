import { SET_BOUNDS_SETTINGS } from '../actions/types'

export default function ExportSettings(
  state = {
    bounds: false,
  },
  action
) {
  switch (action.type) {
    case SET_BOUNDS_SETTINGS: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
