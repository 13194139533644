import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

// Create and export the TabItem component
export const TabItem = props => {
  const { item, isClone, index } = props

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // isClone={ isClone }
          ref={provided.innerRef}
          // isDragging={snapshot.isDragging}
        >
          {/* The content of the TabItem */}
          {item.content}
        </div>
      )}
    </Draggable>
  )
}
