/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import { apiFetch } from '../../../../../utilities/api'
import { apis } from '../../../../../config/apiConfig'
import { processDateFormat } from '../../../../Profile/ProfileDate'
import { Alert } from '../../../../../actions'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'

// scss files
import scss from '../AttributeRow/AttributeRow.scss'
import './LayerAttributes.css'
import appScss from '../../../../App/App.scss'
import 'react-datepicker/dist/react-datepicker.css'

export const fieldTypes = [
  { label: 'Text', value: 'text' },
  { label: 'Long Text', value: 'longtext' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Dropdown', value: 'dropdown' },
  { label: 'Currency', value: 'currency' },
]

const AddAttribute = ({
  config,
  targetProperty,
  cancelEdit,
  getProperties,
  finishUpdate,
  allProperties,
}) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [showCreateButton, setShowCreateButton] = useState(true)
  const [propertyName, setPropertyName] = useState('New Feature Attribute')
  const [propertyType, setPropertyType] = useState('text')
  const [propertyDefault, setPropertyDefault] = useState('')
  const [defaultTitleForEdit] = useState('Default')
  const [dateValue, setDateValue] = useState(null)
  const [dropdownValue, setDropdownValue] = useState(null)

  const getDropDownOptionList = () => {
    let textAreaVal = dropdownValue || ''

    if (targetProperty) {
      const { value } = targetProperty
      console.log('b', value)

      if (Array.isArray(value)) {
        textAreaVal = targetProperty.value.join('\n')
      }
    }

    return (
      <div className={scss.propertyDropdownList}>
        <div>Option List (One per line)</div>
        <textarea
          name='value'
          className={scss.dropDownTextArea}
          value={textAreaVal}
          onChange={e => {
            setDropdownValue(e.target.value)
          }}
          placeholder='Enter Dropdown Items (one per line)...'
        ></textarea>
      </div>
    )
  }

  const createProperty = () => {
    if (propertyName === '') {
      setError('name')
      return
    }

    const allPropertyNames = allProperties.map(property => property.name)
    const isDuplicate = allPropertyNames.includes(propertyName)
    let newPropertyName = propertyName

    if (isDuplicate) {
      let count = 1
      let base = newPropertyName
      const match = base.match(/_(\d+)$/)
      if (match) {
        base = attribute.substring(0, base.lastIndexOf('_'))
        count = parseInt(match[1], 10) + 1
      }
      newPropertyName = `${base}_${count}`
      while (allPropertyNames.includes(newPropertyName)) {
        newPropertyName = `${base}_${count}`
        count++
      }
      dispatch(
        Alert({
          success: `Feature attribute already exists. Duplicate was renamed to ${newPropertyName}`,
        })
      )
    }

    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/add'

    let value = ''

    if (targetProperty && targetProperty.value) {
      if (propertyType === 'dropdown') {
        if (!Array.isArray(targetProperty.value)) {
          if (value === '') {
            value = []
          } else {
            // Split text area into new line and remove invalid attributes
            value = targetProperty.value.split('\n').filter(val => val !== '')
          }
        }
      } else if (Array.isArray(targetProperty.value)) {
        value = [value]
      }
    }

    if (dropdownValue) {
      value = dropdownValue.split('\n').filter(val => val !== '')
    }

    const bodyParams = {
      layerID: layerId,
      name: newPropertyName,
      type: propertyType,
      default: propertyDefault,
      value: Array.isArray(value) ? value : [value],
    }

    if (targetProperty && targetProperty.key)
      bodyParams.key = targetProperty.key

    apiFetch(url, method, bodyParams, result => {
      if (result && result.data && result.success === false) {
        result.data = JSON.parse(result.data)
        dispatch(
          Alert({
            error:
              'Reserved words cannot be used to create Feature Attribute: ' +
              result.data,
          })
        )
      }
      getProperties()
      cancelEdit()
    })
  }

  const getDefaultEditor = () => {
    if (propertyType === 'date') {
      if (propertyDefault === '' || propertyDefault instanceof Date) {
        const userDateFormat = processDateFormat(user.profile.dateFormat)

        let keyVal = ''
        if (targetProperty) {
          keyVal = targetProperty.key
        }

        return (
          <DatePicker
            name={keyVal}
            value={dateValue}
            className={scss.CustomDatePicker}
            placeholderText='MM/DD/YYYY'
            selected={propertyDefault}
            onChange={date => {
              let isoDate = null
              if (date instanceof Date)
                isoDate = date.toISOString().substring(0, 10)
              setDateValue(isoDate || date)
              setPropertyDefault(dateValue || date)
            }}
            dateFormat={userDateFormat}
            isClearable
          />
        )
      }
      return null
    }
    if (propertyType === 'currency') {
      // $ has to be removed because it breaks the defaultValue property
      if (propertyDefault.includes('$'))
        setPropertyDefault(propertyDefault.replace('$', ''))
      return (
        <CurrencyInput
          placeholder='$0.00'
          name='default'
          defaultValue={propertyDefault || ''}
          prefix='$'
          onValueChange={value => {
            setPropertyDefault(value ? '$' + value : '')
          }}
          style={{ width: '100%', textAlign: 'left' }}
          decimalsLimit={2}
          allowDecimals
        />
      )
    }
    if (propertyType === 'longtext') {
      return (
        <textarea
          id='property-default'
          name='property-default'
          value={propertyDefault || ''}
          onChange={e => setPropertyDefault(e.target.value)}
          autoComplete='off'
          rows={5}
          style={{ maxHeight: '300px' }}
        />
      )
    }
    return (
      <input
        id='property-default'
        name='property-default'
        value={propertyDefault || ''}
        onChange={e => setPropertyDefault(e.target.value)}
        autoComplete='off'
      />
    )
  }

  useEffect(() => {
    if (propertyType === 'date' && propertyDefault && propertyDefault !== '') {
      const newDate = new Date(propertyDefault)
      setPropertyDefault(newDate)
    }
  }, [])

  useEffect(() => {
    const propertyNameCheck = propertyName.split(' ').join('')
    if (propertyNameCheck) {
      setShowCreateButton(true)
    } else {
      setShowCreateButton(false)
    }
  })

  const nameError = error === 'name' ? 'error' : ''
  const dropDownOptions =
    propertyType === 'dropdown' ? getDropDownOptionList() : null
  const defaultEditor = getDefaultEditor()

  const handleFocus = e => {
    e.currentTarget.select()
  }

  return (
    <div className={[scss.flexColumn, scss.layerPropertiesEdit].join(' ')}>
      <span className={scss.layerPropertiesEditText}>
        New Feature Attribute
      </span>
      <div
        className={[scss.flexColumn, scss.layerPropertiesEditInputs].join(' ')}
      >
        <label htmlFor='property-name' className={scss.globalLabel}>
          Name
          <input
            id='property-name'
            name='name'
            value={propertyName}
            onChange={e => {
              let propertyNameInput = e.target.value
              // The back-end creates scripts with this name so we restrict it to normal characters
              propertyNameInput = propertyNameInput.replace(/[^\w\s]/gi, '')
              setPropertyName(propertyNameInput)
            }}
            className={scss[nameError]}
            onFocus={e => handleFocus(e)}
            autoFocus
          />
        </label>
        <label
          htmlFor='property-type'
          className={scss.globalLabel}
          style={{ marginBottom: 0 }}
        >
          Type
          <div className={scss.globalCustomSelect}>
            <select
              id='property-type'
              value={propertyType}
              onChange={e => setPropertyType(e.target.value)}
            >
              {fieldTypes.map(property => (
                <option
                  key={property.value}
                  value={property.value}
                  title={
                    property.value === 'dropdown'
                      ? 'List of options to choose from'
                      : null
                  }
                >
                  {property.label}
                </option>
              ))}
            </select>
            <FontAwesomeIcon
              icon='chevron-down'
              size='1x'
              pull='right'
              className={scss.globalCustomSelectIcon}
            />
          </div>
          {dropDownOptions}
        </label>

        {/* Specific Inputs for Dates and Currency */}
        {
          <label htmlFor='property-default' className={scss.globalLabel}>
            {defaultTitleForEdit}
            {defaultEditor}
          </label>
        }

        {/* Cancel and Save Buttons */}
        <div
          className={[scss.flexRow, scss.flexCenter, scss.flexJustify].join(
            ' '
          )}
        >
          <button
            type='button'
            className={appScss.altBlueButton}
            onClick={cancelEdit}
          >
            Cancel
          </button>
          {showCreateButton ? (
            <button
              type='button'
              className={appScss.blueButton}
              onClick={createProperty}
            >
              Create
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AddAttribute
