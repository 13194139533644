import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const mainPanelTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),

    color: '#fff',
    fontSize: 22,
  },

  palette: {
    primary: {
      main: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#fff',
    },
    background: {
      paper: '#1a2937',
      default: '#1a2937',
    },
  },

  overrides: {
    // Style sheet name ⚛️
    MuiList: {
      root: {
        backgroundColor: '#213445',
        border: 'solid 1px #314d68',
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: '#213445',
        '&&:hover': {
          backgroundColor: '#d8d8d815',
        },
        '&$selected': {
          backgroundColor: '#88c0d630',
        },
      },
    },
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: '#fff',
      },
    },
    MuiSelect: {
      text: {
        color: '#fff',
      },
      root: {
        padding: '9px !important',
        backgroundColor: '#213445',
      },
    },
    MuiInput: {
      text: {
        color: '#fff',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },

    // Label on Text Fields
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '12px',
        fontWeight: 'normal',

        '&:hover': {
          color: 'rgba(255, 255, 255, 0.6)',
        },

        '&$focused': {
          color: 'rgba(255, 255, 255, 0.6)',
        },
      },
    },
    MuiOutlinedInput: {
      // root: {
      //   borderColor: '#41678b',

      //   // Notch Outlined in Hover
      //   '&:hover $notchedOutline': {
      //     borderColor: '#41678b',
      //     borderWidth: 1,
      //   },
      //   // Notch Outlined in Focus
      //   '&$focused $notchedOutline': {
      //     borderColor: '#41678b',
      //     borderWidth: 1,
      //   },
      //   // Notch Outlined in Error
      //   '&$error $notchedOutline': {
      //     borderColor: '#41678b',
      //   },
      //   // Notch Outlined in Disabled
      //   '&$disabled $notchedOutline': {
      //     borderColor: '#41678b',
      //   },
      // },
      // focused: {},
      // disabled: {},
      // error: {},
      // marginDense: {
      //   padding: 0,
      // },
      // multiline: {
      //   padding: 0,
      // },
      // notchedOutline: {
      //   borderColor: '#41678b',
      //   padding: 0,
      // },
      // input: {
      //   padding: 0,
      // },
      // inputMultiline: {
      //   padding: 0,
      // },
      notchedOutline: {
        borderColor: '#41678b',
      },
      root: {
        borderColor: '#41678b',
        width: '100%',
        '&:hover': {
          borderColor: '#24c0db',
          borderWidth: '1px',

          '& *': {
            borderColor: '#24c0db',
          },
        },
        '&$focused': {
          borderColor: '#24c0db',
          borderWidth: '1px',

          '& *': {
            borderColor: '#24c0db',
          },
        },
      },
    },
  },
})

export default mainPanelTheme
