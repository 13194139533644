const myAssetMapColors = [
  '#ffffff',
  '#000000',
  '#086788',
  '#0884A6',
  '#07A0C3',
  '#F0C808',
  '#F8DD6C',
  '#F8CD88',
  '#EE8775',
  '#E65248',
  '#E23731',
  '#DD1C1A',
  '#00E07F',
  '#5CFF92',
  '#ADFFB8',
  '#D3F1C9',
  '#F3E158',
  '#FF9F31',
  '#FFB760',
  '#BDACB7',
  '#B096AC',
  '#845C82',
  '#E17CD2',
  'TRANSPARENT',
]

export default myAssetMapColors
