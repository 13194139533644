import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clone } from '../../../utilities/geospatial'
import { updateMapStyle } from '../../../actions'
import {
  addLayerToStyle,
  addSourceToStyle,
} from '../../../utilities/mapStyle'
import scss from './BaseStylePicker.scss'
import { fromJS } from 'immutable'
import {
  MAP_STYLE_ARRAY,
  styleToggleObject,
} from '../../../mapStyles/style_config'  

const BaseStylePicker = ({ currentStyle, updateStyle }) => {
  const dispatch = useDispatch()
  const dataConfig = useSelector(state => state.updateDataConfig)
  const mapStyle = useSelector(state => state.mapStyle)

  const getNewBase = id => {
    let oldStyle = mapStyle.toJS()

    const addLayers = (layersArray, style) => {
      layersArray.forEach(layerObj => {
        // get old style layer and use it to define props for layer in new style
        let oldStyleLayer = oldStyle.layers.filter(
          oldLayer => oldLayer.id === layerObj.layer.id
        )
        let layout = oldStyleLayer[0].layout
        let paint = oldStyleLayer[0].paint
        let filter = oldStyleLayer[0].filter || []
        let metadata = oldStyleLayer[0].metadata

        layerObj.layer.layout = layout
        layerObj.layer.paint = paint
        layerObj.layer.filter = filter
        layerObj.layer.metadata = metadata

        if (layerObj.layer.filter && !layerObj.layer.filter.length) {
          delete layerObj.layer.filter
        }

        style = addLayerToStyle(style, layerObj, layout.visibility)
      })

      return style
    }

    const addSources = (sourcesArray, style) => {
      sourcesArray.forEach(source => {
        if (source.tiles) {
          // if source is a tile url and comming from https://tiles.myassetmap.com
          // bust the browser cache for each tile url by add dt=${Date.now() as query param
          console.log('request made!')
          source.tiles = source.tiles.map(tileUrl => {
            if (tileUrl.includes(`https://tiles.myassetmap.com`)) {
              if (tileUrl.indexOf('?') === -1) {
                return tileUrl + `?dt=${Date.now()}`
              } else {
                let url = tileUrl.replace(/&dt.*$/, '')
                return url + `&dt=${Date.now()}`
              }
            }
            return tileUrl
          })
        }
        style = addSourceToStyle(style, source)
      })
      return style
    }

    const processConfigObj = config => {
      // add groups
      if (config.children) {
        config.children.items.forEach(item => {
          if (item.children) {
            processConfigObj(item)
          } else {
            if (item.layersArray) {
              newMapStyle = addLayers(item.layersArray, newMapStyle)
            }
            if (item.sourcesArray) {
              newMapStyle = addSources(item.sourcesArray, newMapStyle)
            }
          }
        })
      }
      // add layers
      if (config.layersArray) {
        newMapStyle = addLayers(config.layersArray, newMapStyle)
      }
      // add sources
      if (config.sourcesArray) {
        newMapStyle = addSources(config.sourcesArray, newMapStyle)
      }
    }
    const dConfig = clone(dataConfig)
    let newMapStyle = clone(styleToggleObject[id].toJS()) //TODO - check and use current base style instead of BASIC

    dConfig['tocLayers'].reverse().forEach(config => {
      processConfigObj(config)
    })

    dConfig['auxiliaryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = layerObj.layer.layout.visibility
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible)
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })

    dConfig['imageryLayers'].forEach(config => {
      if (config.layersArray) {
        config.layersArray.forEach(layerObj => {
          let visible = oldStyle.layers
            .filter(layer => layer.id === layerObj.layer.id)
            .map(layer => layer.layout.visibility)
          newMapStyle = addLayerToStyle(newMapStyle, layerObj, visible[0])
        })
      }
      // add sources
      if (config.sourcesArray) {
        config.sourcesArray.forEach(
          source => (newMapStyle = addSourceToStyle(newMapStyle, source))
        )
      }
    })
    return newMapStyle
  }

  const handleStyleClick = baseStyle => {
    const newBaseStyle = getNewBase(baseStyle.id)
    dispatch(updateMapStyle(fromJS(newBaseStyle)))
    updateStyle(baseStyle.id, baseStyle.icon)
  }

  const styleButtons = MAP_STYLE_ARRAY.map(layer => {
    return (
      <div
        className={[
          scss.mapStyleLayerContainer,
          currentStyle == layer.id ? scss.selectedStyle : '',
        ].join(' ')}
        onClick={() => handleStyleClick(layer)}
      >
        <img src={layer.icon} className={scss.mapIcon} />
        <span>{layer.layerLabel}</span>
      </div>
    )
  })

  return <div className={scss.mapStyleContainer}>{styleButtons}</div>
}

export default BaseStylePicker