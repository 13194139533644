import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-balham.css'
import { apis } from '../../config/apiConfig'
import CurrencyInput from 'react-currency-input'
import DatePicker from 'react-datepicker'
import moment from 'moment'

// utilities
import * as utils from '../../utilities/util'
import { processDateFormat, profileDateFormat } from '../Profile/ProfileDate'
import AsyncFetch from '../../utilities/AsyncFetch'
import { getBBox } from '../../utilities/geospatial'
import { setMapCursor, updateMapStyle, Alert } from '../../actions/index'

// components
import TableControls from './TableControls'
import ZoomToBounds from '../../components/ZoomToBounds/ZoomToBounds'

// scss files
import scss from './DataTable.scss'
import './ag-grid-enterprise-overrides.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SELECTED_FEATURES } from '../../data/Layers/Auxiliary/SelectedFeatures'
const SELECTED_FEATURES_LAYER = SELECTED_FEATURES.layersArray[0].layer

const DataTable = React.memo(({ height, onClose }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const dataTable = useSelector(state => state.dataTable)
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const mapRef = useSelector(state => state.mapRef)

  const [fetchObjects, setFetchObjects] = useState(null)
  const [getRowParams, setGetRowParams] = useState(null)
  const [propertyLookupObj, setPropertyLookupObj] = useState({})
  const [gridApi, setGridApi] = useState(null)
  const [columnDefs, setColumnDefs] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [rowCount, setRowCount] = useState(null)
  const [zoomToIndex, setZoomIndex] = useState(null)
  const [zoomToBoundsKey, setZoomToBoundsKey] = useState(null)
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [filterChecked, setFilterChecked] = useState(false)
  const [currentFilter, setCurrentFilter] = useState(null)
  const [tableControlKey, setTableControlKey] = useState(1)
  const [error, setError] = useState(null)
  const { layerId } = dataTable

  const cacheBlockSize = 100

  const buildFetchParams = () => {
    let url = apis['apiDatabase'].uri + 'layer/properties/get'
    const method = 'POST'
    const body = {
      layerID: layerId,
      unique: true,
      includeIntrinsicGeom: true,
    }
    setFetching('Table')
    setFetchObjects([{ url, method, body }])
  }

  const buildUpdateParams = (featureId, properties) => {
    const json = {
      type: 'Feature',
      properties: properties,
      geometry: {},
    }
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/update'
    const body = {
      layerID: layerId,
      featureID: featureId,
      json: json,
    }
    setFetching('UpdateFeature')
    setFetchObjects([{ url, method, body }])
  }

  // ========================= //
  // ======== AG-GRID ======== //
  // ========================= //
  const createServerSideDatasource = () => {
    return {
      getRows: function (params) {
        console.log(
          '[Datasource]',
          layerId,
          'Rows requested by grid: ',
          params.request
        )
        setGetRowParams(params)
      },
    }
  }

  const onGridReady = params => {
    params.columnApi.autoSizeColumns()
    setGridApi(params.api)
    checkForExistingFilter(params.api)
    let datasource = new createServerSideDatasource()
    params.api.setServerSideDatasource(datasource)
  }

  const onFilterChanged = e => {
    const filter = gridApi.getFilterModel()
    setCurrentFilter(filter)
    setFilterChecked(true)
  }

  const checkForExistingFilter = gridApi => {
    const { layerId } = dataTable
    if (!mapStyle) return
    let style = mapStyle.toJS()
    const targetLayer = style.layers.filter(layer => layer.id === layerId)
    // Note:  we save the filterModel to the layers metadata in the ApplyFilterToMap component
    // if the filterModel exists, set it to the table
    if (targetLayer && targetLayer[0] && targetLayer[0].metadata.filterModel) {
      gridApi.setFilterModel(targetLayer[0].metadata.filterModel)
      gridApi.onFilterChanged()
      const filter = gridApi.getFilterModel()
      let newKey = tableControlKey // force remount of table controls to reflect existing filter
      newKey++
      setCurrentFilter(filter)
      setFilterChecked(true)
      setTableControlKey(newKey)
    }
  }

  const onCellEditing = params => {
    const featureId = params.data.mamid

    if (params.type === 'cellEditingStarted') {
      // const default-value = params.data.default_date_field
    } else if (params.type === 'cellEditingStopped') {
      const featureID = params.data.mamid
      const newField = params.colDef.field
      const oldValue = params.oldValue
      let newValue = params.newValue

      if (params.colDef.cellEditor === 'dateCellEditor')
        newValue = moment(newValue).toISOString()

      if (featureID && newField && newValue != null && oldValue != newValue) {
        const properties = { [newField]: newValue }
        buildUpdateParams(featureID, properties)
      }
    }
  }

  const onRowSelected = params => {
    if (!params.node.selected) {
      removeSelectedFeature([params.data.mamid])
    }
  }

  const selectionChanged = e => {
    // get all currently selected rows
    // check if the rows id is already in the selected feature source data
    // if not add to fetch array
    let style = mapStyle.toJS()
    const selectedNodes = gridApi.getSelectedNodes()
    const selectedFeatures = style.sources.selected_features.data.features
    const selectedIds = selectedNodes
      .filter(node => {
        const alreadySelected = selectedFeatures.filter(
          feature => feature.properties.mamid === node.data.mamid
        )
        if (!alreadySelected[0]) return true
        return false
      })
      .map(node => node.data.mamid)

    getFeatureGeoJson(selectedIds)
  }

  const removeSelectedFeature = removeIds => {
    let style = mapStyle.toJS()
    style.sources.selected_features.data.features =
      style.sources.selected_features.data.features.filter(
        feature => !removeIds.includes(feature.properties.mamid)
      )
    dispatch(updateMapStyle(fromJS(style)))
  }

  const getFeatureGeoJson = selectedids => {
    if (!selectedids.length) return

    //Build bounds
    const map = mapRef.getMap()
    const bounds = map.getBounds()

    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/get'

    let fetchList = []

    let splitCount = 250

    for (let index = 0; index < selectedids.length; index += splitCount) {
      let partialFeatureList = selectedids.slice(index, index + splitCount)
      const body = {
        layerID: layerId,
        featureID: partialFeatureList.join(','),
        attributes: false,
      }

      body['bounds'] = [
        bounds._sw.lng,
        bounds._sw.lat,
        bounds._ne.lng,
        bounds._ne.lat,
      ]

      fetchList.push({ url, body, method })
    }

    dispatch(setMapCursor('wait'))
    setFetching('FeatureGeoJson')
    setFetchObjects(fetchList)
  }

  const getRowsFromAPI = () => {
    if (!getRowParams) return
    const { request } = getRowParams
    if (!request) return

    dispatch(setMapCursor('wait'))
    setFetching('getRows')

    const method = 'POST'
    const url =
      apis['apiDatabase'].uri +
      'layer/export' +
      `?limit=${request.endRow - request.startRow}&start=${request.startRow}`
    const body = {
      layerID: layerId,
      exportAs: 'AgGridData',
      filter: request.filterModel,
      sort: request.sortModel,
    }

    setFetchObjects([{ url, body, method }])
  }

  const updateSelectLayer = results => {
    let style = mapStyle.toJS()
    const targetLayer = style.layers.filter(layer => layer.id === layerId)

    const selectedFeatureCollection = buildSelectedFeatureCollection(results)
    style.layers
      .filter(layer => layer.id === 'selected_features')
      .forEach(layer => {
        layer

        if (targetLayer[0].type === 'circle') {
          layer.type = 'circle'
          layer.paint = {
            'circle-color': SELECTED_FEATURES_LAYER.paint['line-color'],
            'circle-opacity': 0,
            'circle-stroke-color': SELECTED_FEATURES_LAYER.paint['line-color'],
            'circle-stroke-width': 3,
            'circle-radius': targetLayer[0].paint['circle-radius'] + 1,
          }
        } else {
          layer.type = 'line'
          layer.paint = SELECTED_FEATURES_LAYER.paint
        }
      })
    style.sources.selected_features.data.features = [
      ...style.sources.selected_features.data.features,
      ...selectedFeatureCollection,
    ]
    dispatch(setMapCursor('grab'))
    dispatch(updateMapStyle(fromJS(style)))
  }

  const buildSelectedFeatureCollection = fetchResults => {
    let newFeatures = []
    fetchResults.map(result => {
      if (utils.verifyResult(result)) {
        const data = utils.resultReturn(result)
        if (data.features) data.features.map(layer => newFeatures.push(layer))
      }
    })
    return newFeatures
  }

  const zoomToFeature = featureId => {
    const { layerId } = dataTable
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }

    setFetching('Bounds')
    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = result => {
    if (fetching === 'Bounds') doZoomToBounds(result)
    if (fetching === 'Table') setTableData(result)
    if (fetching === 'UpdateFeature') updateFeature(result)
    if (fetching === 'getRows') setRows(result)
    if (fetching === 'FeatureGeoJson') updateSelectLayer(result)
    setFetchObjects(null)
    setFetching(null)
  }

  const updateFeature = results => {
    return results.map(result => {
      let success = utils.verifyResult(result)
      if (!success) setError('Unable to update feature')
    })
  }

  const setTableData = results => {
    return results.map(result => {
      let response = utils.resultReturn(result)
      if (!response) {
        setError('No Properties Exist For This Layer')
        return
      }

      const pLookupObj = {}
      response.forEach(property => {
        pLookupObj[property.key] = property
      })

      setPropertyLookupObj(pLookupObj)
      const columns = response.map(col => {
        let filterType
        let editType
        let dropdownOptions
        let filterParams = {
          filterOptions: [
            'equals',
            'notEqual',
            // 'contains',
            // 'notContains',
            //  'startsWith',
            //  'endsWith',
            // 'lessThan',
            // 'lessThanOrEqual',
            // 'greaterThan',
            // 'greaterThanOrEqual',
            // 'inRange',
            // 'empty'
          ],
        }

        if (col.type === 'dropdown') {
          editType = 'agRichSelect'
          dropdownOptions = col.value
        }
        if (col.type === 'date') editType = 'dateCellEditor'
        if (col.type === 'currency') editType = 'currencyCellEditor'
        if (col.type === 'longtext') editType = 'agLargeTextCellEditor'
        if (col.type === 'number') {
          filterType = 'agNumberColumnFilter'
          editType = 'numericCellEditor'
          filterParams.filterOptions.push('lessThan')
          filterParams.filterOptions.push('lessThanOrEqual')
          filterParams.filterOptions.push('greaterThan')
          filterParams.filterOptions.push('greaterThanOrEqual')
          filterParams.filterOptions.push('inRange')
        } else if (
          col.unique &&
          Array.isArray(col.unique) &&
          col.unique.length <= 10
        ) {
          filterType = 'agSetColumnFilter'
          filterParams.values = col.unique ? col.unique : null
        } else {
          filterType = 'agTextColumnFilter'
          filterParams.filterOptions.push('contains')
          filterParams.filterOptions.push('notContains')
        }

        return {
          headerName: col.name,
          field: col.key,
          sortable: true,
          editable: col.type === 'document' ? false : true,
          resizable: true,
          filter: filterType,
          filterParams: filterParams,
          enablePivot: true,
          enableRowGroup: true,
          enableValue: true,
          cellEditor: editType,
          cellEditorParams: { values: dropdownOptions },
        }
      })

      // add mam id to columns
      columns.unshift({
        headerName: 'mamid',
        field: 'mamid',
        sortable: true,
        editable: false,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',

            'greaterThanOrEqual',
            'inRange',
          ],
        },
        hide: false,
        pinned: 'left',
        width: 100,
      })

      // add select column
      columns.unshift({
        headerName: '',
        field: 'select',
        hide: false,
        checkboxSelection: true,
        resizable: false,
        pinned: 'left',
        width: 40,
      })

      // add zoom column
      columns.unshift({
        headerName: '',
        field: 'zoomTo',
        cellRenderer: 'zoomToFeatureButton',
        hide: false,
        checkboxSelection: false,
        resizable: false,
        pinned: 'left',
        width: 40,
      })

      setColumnDefs(columns)
    })
  }

  const setRows = results => {
    return results.map(result => {
      if (!getRowParams) return

      if (!utils.verifyResult(result)) return getRowParams.failCallback()

      let response = utils.resultReturn(result)

      let rowCountNew = response.count || null
      let rowData = response.data || []

      // ================================== //
      // ======== PRE-PROCESS ROWS ======== //
      // ================================== //
      if (!response) {
        setError('No layer data on this layer!')
        return
      }
      const rows = rowData.map(row => {
        let rowObj = {}
        columnDefs.forEach(col => {
          if (col.field === 'select') return
          if (col.field === 'zoomTo') {
            rowObj[col.field] = <FontAwesomeIcon icon='file' />
          } else {
            let type =
              col.field === 'mamid'
                ? 'mamid'
                : propertyLookupObj[col.field].type
            if (type == 'date') {
              if (row[col.field] !== null && row[col.field] !== '') {
                let savedDate = row[col.field].split('T')
                let date = savedDate[0].split('-')
                rowObj[col.field] = `${date[1]}/${date[2]}/${date[0]}`
              } else {
                rowObj[col.field] = row[col.field]
              }
            } else {
              rowObj[col.field] = row[col.field]
            }
            // check for default values
            if (rowObj[col.field] === null || rowObj[col.field] === '') {
              if (
                type == 'date' &&
                propertyLookupObj[col.field].default !== ''
              ) {
                if (propertyLookupObj[col.field].default) {
                  let defaultDate =
                    propertyLookupObj[col.field].default.split('T')
                  let date = defaultDate[0].split('-')
                  rowObj[col.field] = `${date[1]}/${date[2]}/${date[0]}`
                }
              } else {
                rowObj[col.field] = propertyLookupObj[col.field].default
              }
            }
          }
        })
        return rowObj
      })

      //Update Row Count
      setRowCount(rowCountNew)

      //Update Cursor
      dispatch(setMapCursor('auto'))

      // supply rows for requested block to grid
      //If number of rows is less than the total, disable lazy loading
      if (rowCountNew <= cacheBlockSize || rows.length < cacheBlockSize) {
        getRowParams.successCallback(
          rows,
          rowCountNew //Number of Total Rows Returned - Enabled List View
        )
      } else getRowParams.successCallback(rows)
    })
  }

  const doZoomToBounds = result => {
    if (!result[0].data) return
    if (!result[0].data.features[0]) return
    const feature = result[0].data.features[0]
    if (!feature.geometry.type === 'Polygon') return
    let newZoomIndex = zoomToIndex ? zoomToIndex : 1
    const bounds = getBBox(feature)
    const formattedBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    const zoomToBoundsKey = JSON.stringify(formattedBounds) + newZoomIndex
    const zoomToBounds = formattedBounds
    newZoomIndex++
    setZoomIndex(newZoomIndex)
    setZoomToBounds(zoomToBounds)
    setZoomToBoundsKey(zoomToBoundsKey)
  }

  useEffect(() => {
    buildFetchParams()
  }, [dataTable])

  useEffect(() => {
    if (getRowParams) getRowsFromAPI()
  }, [getRowParams])

  useEffect(() => {
    if (error) dispatch(Alert({ error }))
  }, [error])

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  }

  const style = {
    height: height + 'px',
  }

  // React Date Picker for Date Editing
  const datePickerEditor = forwardRef((props, ref) => {
    const [date, setDate] = useState(props.value)
    const refInput = useRef(null)

    useEffect(() => {
      if (date && date !== '') {
        let newDate = new Date(date)
        setDate(newDate)
      }
    }, [])

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          let isoDate = null
          if (date instanceof Date)
            isoDate = profileDateFormat({
              dateFormat: user.profile.dateFormat,
              date: moment(date).format('MM/DD/YYYY'),
            })

          return isoDate
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false
        },

        // Gets called once when editing is finished (eg if enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // our editor will reject any value greater than 1000
          return false
        },
      }
    })

    if (date === null || date instanceof Date) {
      return (
        <DatePicker
          wrapperClassName='react-datepicker__wrapper-data'
          className='react-datepicker__input-data'
          ref={refInput}
          selected={date}
          onChange={date => setDate(date)}
          dateFormat={processDateFormat(user.profile.dateFormat)}
          isClearable
        />
      )
    }

    return
  })

  // Number Input for Numbers
  const numericEditor = forwardRef((props, ref) => {
    const [numericValue, setNumericValue] = useState(props.value)
    const refInput = useRef(null)

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          // this simple editor doubles any value entered into the input
          return numericValue
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false
        },

        // Gets called once when editing is finished (eg if enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // our editor will reject any value greater than 1000
          return false
        },
      }
    })

    return (
      <input
        type='number'
        ref={refInput}
        value={numericValue}
        onChange={e => setNumericValue(e.target.value)}
        style={{ width: '100%' }}
      />
    )
  })

  // Currency Input for Currency Editing
  const currencyEditor = forwardRef((props, ref) => {
    const [currencyValue, setCurrencyValue] = useState(props.value)

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          // this simple editor doubles any value entered into the input
          return currencyValue
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false
        },

        // Gets called once when editing is finished (eg if enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // our editor will reject any value greater than 1000
          return false
        },
      }
    })

    const handleCurrencyChange = (e, maskedValue, floatValue) => {
      if (maskedValue === '') {
        setCurrencyValue(null)
      } else setCurrencyValue(floatValue)
    }

    return (
      <CurrencyInput
        prefix='$'
        value={currencyValue}
        onChangeEvent={handleCurrencyChange}
        style={{ width: '100%' }}
      />
    )
  })

  // Number Input for Numbers
  const zoomToFeatureButton = props => {
    return (
      <button
        className={scss['zoom-to-feature-button']}
        onClick={() => zoomToFeature(props.data.mamid)}
        type='button'
      >
        <FontAwesomeIcon icon={['fal', 'arrows-alt']} />
      </button>
    )
  }

  return (
    <>
      <ZoomToBounds
        key={zoomToBoundsKey}
        viewport={viewport}
        bounds={zoomToBounds}
      />
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <div style={style} className={scss.dataTableWrapper}>
        <TableControls
          key={tableControlKey}
          fetching={fetching}
          rowCount={rowCount}
          gridApi={gridApi}
          currentFilter={currentFilter}
          filterChecked={filterChecked}
          onClose={onClose}
        />
        <div className={scss.tableContainer}>
          {columnDefs ? (
            <div
              style={{ height: '100%', width: '100%' }}
              className='ag-theme-balham'
            >
              <AgGridReact
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                onCellEditingStarted={onCellEditing}
                onCellEditingStopped={onCellEditing}
                onRowSelected={onRowSelected}
                onSelectionChanged={selectionChanged}
                columnDefs={columnDefs}
                frameworkComponents={{
                  dateCellEditor: datePickerEditor,
                  numericCellEditor: numericEditor,
                  currencyCellEditor: currencyEditor,
                  zoomToFeatureButton,
                }}
                suppressRowClickSelection='true'
                rowSelection='multiple'
                groupHeaders='true'
                rowHeight='22'
                sideBar={sideBar}
                pivotMode={false}
                rowModelType={'serverSide'}
                pagination={false}
                cacheBlockSize={cacheBlockSize}
                blockLoadDebounceMillis={1000}
                animateRows={true}
                paginateChildRows={true}
              />
            </div>
          ) : error ? (
            error
          ) : null}
        </div>
      </div>
    </>
  )
})

export default DataTable
