import aws4 from 'aws4'
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import authConfig from '../config/authConfig'
import * as utils from './util'

const mamApis = [
  new RegExp('https://(dv|qa|pd)-fot-tiles.s3.amazonaws.com/'),
  new RegExp('https://my-asset-map-data.s3.amazonaws.com/'),
  new RegExp('https://hp29salvwe.execute-api.us-east-1.amazonaws.com/'), //alpha
  new RegExp('https://rg91b1juf3.execute-api.us-east-1.amazonaws.com/'), //dev
  new RegExp('https://egd4avkav9.execute-api.us-east-1.amazonaws.com/'), //production
  new RegExp('https://99qya3ubuj.execute-api.us-east-1.amazonaws.com/'), //qa
]

function apiCheck(url) {
  return mamApis.map(mamApi => mamApi.test(url)).includes(true)
}

function signRequest(
  url,
  method = 'GET',
  body = null,
  contentType = null,
  resourceType = null
) {
  let url_obj = new URL(url)
  let opts = {
    host: url_obj.host,
    path: url_obj.pathname + url_obj.search,
  }

  if (body !== null && method !== 'GET') {
    opts['body'] = body
    opts['method'] = method
    opts['headers'] = { 'Content-Type': contentType }
  }
  if (method === 'DELETE' || method === 'GET') {
    opts['method'] = method
  }

  if (apiCheck(url)) {
    opts.region = 'us-east-1'
    if (new RegExp('.*.?s3.amazonaws.com').test(opts.host)) {
      opts.service = 's3'
    } else {
      opts.service = 'execute-api'
    }

    // credentials set in createCredentials function in user.js
    const accessKeyId = localStorage.getItem('accessKeyId')
    const secretAccessKey = localStorage.getItem('secretAccessKey')
    const sessionToken = localStorage.getItem('sessionToken')

    aws4.sign(opts, {
      secretAccessKey: secretAccessKey,
      accessKeyId: accessKeyId,
      sessionToken: sessionToken,
    })
  }
  const headers = opts.headers || {}

  if ('Host' in headers) {
    delete headers.Host // consider an unsafe header by browsers
  }
  if ('Content-Length' in headers) {
    delete headers['Content-Length'] // consider an unsafe header by browsers
  }
  return { url: url, headers: headers, opts: opts }
}

function addQueryVar(url, key, value) {
  key = encodeURI(key)
  value = encodeURI(value)

  var querySplit = url.split('?')
  var urlRoot = querySplit[0]
  var query = ''
  if (querySplit.length == 2) query = querySplit[1]

  var kvp = query.split('&')

  var i = kvp.length
  var x

  while (i--) {
    x = kvp[i].split('=')

    if (x[0] == key) {
      x[1] = value
      kvp[i] = x.join('=')
      break
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join('=')
  }

  return (urlRoot + '?' + kvp.join('&')).replace('?&', '?')
}

/**
 * Make request to url.
 * @param {string} url - url for request
 * @param {string} method - http method type
 * @param {object} body - object of key value pairs for post body
 * @callback requestCallback
 */
const apiFetch = (url, method = 'GET', body = null, callback) => {
  Auth.currentSession()
    .then(function (result) {
      const contentType = 'application/json'
      const cogID = result.idToken.payload.sub
      var userUrl = addQueryVar(url, 'userID', cogID)

      let options = null
      if (method === 'GET') {
        //If GET, append QUERY params to URL instead
        if (body != null && typeof body === 'object') {
          Object.keys(body).forEach(function (key) {
            userUrl = addQueryVar(userUrl, key, body[key])
          })
        }

        options = {
          method,
          headers: signRequest(userUrl).headers,
        }
      }
      if (method === 'POST') {
        options = {
          method,
          body: JSON.stringify(body),
          headers: signRequest(
            userUrl,
            method,
            JSON.stringify(body),
            contentType
          ).headers,
        }
      }

      return fetch(userUrl, options)
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            return Promise.resolve(response.json())
          }
          throw Error(response.statusText)
        })
        .then(json => {
          const { data } = json
          // check for hydration
          if (typeof data !== 'undefined' && data.hydrate) {
            const s3 = new AWS.S3()

            const params = {
              Bucket: authConfig.s3Config.bucketName, // your bucket name,
              Key: data.hydrate, // path to the object you're looking for
            }

            s3.getObject(params, (objectErr, objectRes) => {
              if (objectErr) {
                return callback(json)
              }

              let object = objectRes.Body.toString('utf-8')
              s3.deleteObject(params, (delErr, delRes) => {
                if (delErr) {
                  callback(json)
                }
                try {
                  object = JSON.parse(object)
                  return callback(object)
                } catch (err) {
                  return callback(json)
                }
              })
            })
          } else {
            return callback(json)
          }
        })
        .catch(e => {
          // Network Error
          console.error(e)
          callback({ success: false, message: e.message, error: e })
        })
    })
    .catch(function (e) {
      if (e !== 'No current user') console.log('SESSION ERROR', e)
      utils.showMessage('Session Fetch Error', e.message)
    })
}

// const enhancedApiFetch = withDispatch(apiFetch)

/**
 * Make request to url.
 * @param {string} url - url for request
 * @param {string} method - http method type
 * @param {object} body - object of key value pairs for post body
 * @callback requestCallback
 */
function apiFetchBlob(url, method = 'GET', body = null, callback) {
  Auth.currentSession()
    .then(function (result) {
      const contentType = 'application/json'
      const cogID = result.idToken.payload.sub
      const userUrl = addQueryVar(url, 'userID', cogID)
      let options = null
      if (method === 'GET') {
        options = {
          method,
          headers: signRequest(userUrl).headers,
        }
      }
      if (method === 'POST') {
        options = {
          method,
          body: JSON.stringify(body),
          headers: signRequest(
            userUrl,
            method,
            JSON.stringify(body),
            contentType
          ).headers,
        }
      }

      return fetch(userUrl, options)
        .then(response => {
          callback(response.blob)
        })

        .catch(e => {
          // Network Error
          console.log(e)
          callback({ success: false, message: e.message, error: e })
        })
    })
    .catch(function (e) {
      if (e !== 'No current user')
        utils.showMessage('Session Fetch Error', e.message)
    })
}

export { apiCheck, signRequest, apiFetch, apiFetchBlob, addQueryVar }
