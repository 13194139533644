import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import appScss from './App/App.scss'
import scss from './error404.scss'
import LogoIcon from '../assets/logo/logo-dark.svg'
import backgroundImg from '../assets/img/background.png'

const Error404 = ({ location, type }) => {
  const { pathname } = location

  const user = useSelector(state => state.user)

  const history = useHistory()

  var mapList = ['/']
  var loginList = ['/']

  // ======================================================================= //
  // ======== Add any possible route within application to the list ======== //
  // ======================================================================= //

  //Login ONLY Routes
  loginList.push('/login')
  loginList.push('/logout')
  loginList.push('/reset')
  loginList.push('/create')
  loginList.push('/verify')

  //Map ONLY Routes
  mapList.push('/debug')
  mapList.push('/profile')
  mapList.push('/layers')
  mapList.push('/maps')
  mapList.push('/users')
  mapList.push('/accounts')

  // ============================================= //
  // ======== Logic for error or auto-fix ======== //
  // ============================================= //
  let errorMessage = 'Page Not Found (404)'

  switch (type) {
    case 'login':
      if (mapList.includes(pathname)) {
        errorMessage = 'You must be logged in to access this page.'
        return null
      }
      if (loginList.includes(pathname)) return null
      break

    case 'map':
      if (loginList.includes(pathname)) {
        errorMessage = 'You are already logged in.'
      }
      if (mapList.includes(pathname)) return null
      break
  }

  return (
    <div className={scss.errorContainer}>
      <div className={scss.error}>
        <div className={scss.errorColumn}>
          <span className={scss.errorHeading}>Oops!</span>
          <span className={scss.errorText}>
            We can't find the page you're looking for.
          </span>
          <span className={scss.errorText}>Error: {errorMessage}</span>
          {user.isLoggedIn ? (
            <>
              <button
                className={[appScss.blueButton, scss.errorButton].join(' ')}
                onClick={() => history.push('/')}
              >
                Back to Home
              </button>
            </>
          ) : (
            <div className={scss.errorButton}>
              <span className={scss.errorText}></span>
              <button
                className={[appScss.blueButton].join(' ')}
                onClick={() => history.push('/')}
              >
                Back to Login
              </button>
            </div>
          )}
        </div>
        <div>
          <img src={LogoIcon} alt='My Asset Map Logo' />
        </div>
      </div>
    </div>
  )
}

export default Error404
