import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import scss from './MapPopup.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAttribute, getEditableElement, processValue } from './Utilities'

const AttributeNoTab = ({
  config,
  layerProperties,
  layerId,
  edit,
  featureProperties,
  popupInfo,
  index,
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  handleCurrencyInputChange,
}) => {

  if(layerProperties[layerId]){
    layerProperties[layerId].sort((a, b) => (a.key > b.key) ? 1 : -1)
  }
  
  const user = useSelector(state => state.user)

  let rows = []
  let keys = Object.keys(featureProperties)
  let n = keys.length
  let lowerCaseObj = {}

  while (n--) {
    let key = keys[n]
    lowerCaseObj[key.toLowerCase()] = featureProperties[key]
  }

  const getPopupRow = (property, propertyConfigName = null) => {
    //NOTE : propertyConfigName is used by Common Layers to select and override propertie keys in the data
    // this allows us to display friendlier data in popup without manipulating the data

    return getAttribute({
      lowerCaseObj,
      property,
      propertyConfigName,
      featureProperties,
      edit,
      popupInfo,
      index,
      layerProperties,
      handleInputChange,
      handleSelectChange,
      handleDateChange,
      handleCurrencyInputChange,
      user,
    })
  }

  if (layerProperties[layerId]) {
    if (config.properties) {
      rows = layerProperties[layerId]
        .filter(property => config.properties[property.key])
        .map(property => getPopupRow(property, config.properties[property.key]))
    } else {
      rows = layerProperties[layerId].map(property => {
        return getPopupRow(property)
      })
    }
  }

  const checkForAttributes = () => {
    if (!layerProperties[layerId]) return false

    let attributeList = layerProperties[layerId].filter(
      attr => attr && attr.type !== 'document'
    )
    return attributeList.length > 0
  }


  return (
    <>
      {checkForAttributes() ? (
        <div className={scss['feature-attribute-grid']}>{rows}</div>
      ) : (
        <div className={scss['feature-empty-grid']}>
          <div className={scss['feature-empty-square']}>
            <FontAwesomeIcon icon='question' />
          </div>
          <span className={scss['feature-empty-title']}>
            No attributes found
          </span>
          <span className={scss['feature-empty-description']}>
            Use the layer settings panel to add attributes, then come back here.
          </span>
        </div>
      )}
    </>
  )
}

export default AttributeNoTab
