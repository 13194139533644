export const SELECTED_FEATURES = {
  sourcesArray: [
    {
      id: 'selected_features',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      beforeLayer: null,
      layer: {
        id: 'selected_features',
        type: 'line',
        source: 'selected_features',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#00ddff',
          'line-opacity': 1,
          'line-width': 4,
        },
        metadata: {},
      },
    },
  ],
}
