/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import scss from './CwdStyle.scss'

const Cwd = ({
  cwd,
  accountID,
  setCwd,
  showSearchResults,
  setShowSearchResults,
}) => {
  const setCWDValue = cwdClicked => {
    setShowSearchResults(false)
    setCwd('client_data/' + accountID + '/' + cwdClicked)
  }

  let cwdArray = cwd.replace('client_data/' + accountID + '/', '')
  cwdArray = cwdArray.split('/').filter(item => item !== '')
  const linkPaths = []
  let pathString = ''
  for (let index = 0; index < cwdArray.length; index++) {
    if (index === 0) {
      pathString += cwdArray[index]
    } else {
      pathString += '/' + cwdArray[index]
    }
    linkPaths.push(pathString)
  }

  return showSearchResults ? (
    <span>Search Results</span>
  ) : (
    <span>
      {cwdArray.map((item, i) => (
        <span key={i}>
          <button
            style={{ color: 'white' }}
            className={scss.cwdLink}
            onClick={() => {
              setCWDValue(linkPaths[i])
            }}
          >
            {item}
          </button>{' '}
          /{' '}
        </span>
      ))}
    </span>
  )
}

export default Cwd
