import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle } from '../../../actions/index'
import { FEATURE_COLLETION } from '../../../data/Constants/Constants'
import { fromJS } from 'immutable'
import {
  MEASURE_ACTIVE_POINT,
  MEASURE_LINE_HOT,
  MEASURE_AREA_HOT,
  MEASURE_AREA_HOT_OUTLINE,
  MEASURE_LABEL_HOT,
  MEASURE_POINT_COLD,
  MEASURE_LINE_COLD,
  MEASURE_AREA_COLD,
  MEASURE_LABEL_COLD,
} from './MeasureConstants'

const MeasureClear = () => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  let style = mapStyle.toJS()

  const clearMeasureLayers = () => {
    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    const hotMeasureLine = style.sources[MEASURE_LINE_HOT]
    const hotMeasureLabel = style.sources[MEASURE_LABEL_HOT]
    const hotMeasureArea = style.sources[MEASURE_AREA_HOT]
    const hotMeasureAreaOutline = style.sources[MEASURE_AREA_HOT_OUTLINE]

    const coldMeasureLine = style.sources[MEASURE_LINE_COLD]
    const coldMeasureArea = style.sources[MEASURE_AREA_COLD]
    const coldMeasurePoint = style.sources[MEASURE_POINT_COLD]
    const coldMeasureLabel = style.sources[MEASURE_LABEL_COLD]

    activePoint.data = FEATURE_COLLETION
    hotMeasureLine.data = FEATURE_COLLETION
    hotMeasureLabel.data = FEATURE_COLLETION
    hotMeasureArea.data = FEATURE_COLLETION
    hotMeasureAreaOutline.data = FEATURE_COLLETION

    coldMeasureLine.data = FEATURE_COLLETION
    coldMeasureArea.data = FEATURE_COLLETION
    coldMeasurePoint.data = FEATURE_COLLETION
    coldMeasureLabel.data = FEATURE_COLLETION

    dispatch(updateMapStyle(fromJS(style)))
  }

  useEffect(() => {
    clearMeasureLayers()
  }, [])

  return null
}

export default MeasureClear
