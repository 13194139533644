import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from '../../Legend/LegendSwatch/Utilities'

// Create styles
const styles = StyleSheet.create({
  swatchGroupItemContainer: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchGroupItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    marginHorizontal: 0,
    marginVertical: 4,
    padding: 0,
  },

  swatchItemRowContainer: {
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    margin: 0,
    padding: 0,
  },
  swatchItemRowTitle: {
    marginRight: 10,
  },
})

const ViewerFillSwatch = ({ layer, outline, singleItemTitles, viewport }) => {
  const getProperty = lineProp => {
    if (Array.isArray(lineProp)) {
      const expressionType = getExpressionType(lineProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(lineProp, viewport)
      }
      if (expressionType === 'dataBased') {
        return getDataBasedValue(lineProp)
      }
    } else {
      return lineProp
    }
  }

  const getFillStyle = (outlineWidth, outlineColor, fillColor, fillOpacity) => {
    const outlineRgbaColor = outlineColor || '#000000'
    const fillRgbaColor = fillColor
    let borderColor = outlineRgbaColor
    let borderWidth = outlineWidth || 1
    if (borderWidth > 1) borderWidth = 1
    let border = `${borderWidth}pt solid ${borderColor}`
    //TODO: Opacity effects the border,
    // need to come up with a way to display border independ of the style
    // probably another style postioned ontop??
    return {
      width: '20px',
      height: '12px',
      border: border,
      backgroundColor: fillRgbaColor,
      opacity: fillOpacity,
    }
  }

  const getFillSwatch = (
    outlineWidth,
    outlineColor,
    fillColor,
    fillOpacity,
    label
  ) => {
    let expressionValues = []
    let outlineWidthObject = {}
    let outlineColorObject = {}
    let fillColorObject = {}
    let fillOpacityObject = {}
    if (Array.isArray(outlineWidth)) {
      outlineWidth.forEach(arr => {
        outlineWidthObject[arr[0]] = arr[1]
      })
      let widthValues = outlineWidth.map(arr => arr[0])
      expressionValues = [...expressionValues, ...widthValues]
    }
    if (Array.isArray(outlineColor)) {
      outlineColor.forEach(arr => {
        outlineColorObject[arr[0]] = arr[1]
      })
      let colorValues = outlineColor.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(fillColor)) {
      fillColor.forEach(arr => {
        fillColorObject[arr[0]] = arr[1]
      })
      let colorValues = fillColor.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(fillOpacity)) {
      fillOpacity.forEach(arr => {
        fillOpacityObject[arr[0]] = arr[1]
      })
      let opacityValues = fillOpacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    const uniqueExpressionValues = [...new Set(expressionValues)]

    let swatchItems = []
    // const expressionItemStyle = {
    //   display: `flex`,
    //   flexDirection: `row`,
    //   justifyContent: `space-between`,
    //   alignItems: `center`,
    //   fontSize: 10,
    //   fontWeight: `normal`,
    //   padding: 0
    // };
    // const swatchListStyle = {
    //   margin: '0 12px',
    //   width: `100%`
    // };
    // const swatchListItemsStyle = {
    //   display: `flex`,
    //   flexDirection: `column`,
    //   justifyContent: `space-between`,
    //   padding: '6px 8px',
    //   width: `100%`
    // };

    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        let itemOutlineWidth = outlineWidthObject[value] || outlineWidth
        let itemOutlineColor = outlineColorObject[value] || outlineColor
        let itemFillColor = fillColorObject[value] || fillColor
        let itemFillOpacity = fillOpacityObject[value] || fillOpacity
        const fillStyle = getFillStyle(
          itemOutlineWidth,
          itemOutlineColor,
          itemFillColor,
          itemFillOpacity
        )
        return (
          <View key={value}>
            <View style={styles.swatchGroupItemRow}>
              <View>
                <Text style={styles.swatchItemRowTitle}>
                  {value === 'default' ? 'Default Style' : value}
                </Text>
              </View>
              <View>
                <Text style={fillStyle}></Text>
              </View>
            </View>
          </View>
        )
      })
      swatchItems.push(
        <View
          key={uniqueExpressionValues.join(',')}
          style={styles.swatchGroupItemContainer}
        >
          {items}
        </View>
      )
    } else {
      const fillStyle = getFillStyle(
        outlineWidth,
        outlineColor,
        fillColor,
        fillOpacity
      )

      if (singleItemTitles) {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text style={styles.swatchItemRowTitle}>
                    {' '}
                    {singleItemTitles && label}{' '}
                  </Text>
                </View>
                <View>
                  <Text style={fillStyle}></Text>
                </View>
              </View>
            </View>
          </View>
        )
      } else {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text style={styles.swatchItemRowTitle}> </Text>
                </View>
                <View>
                  <Text style={fillStyle}></Text>
                </View>
              </View>
            </View>
          </View>
        )
      }
    }

    return swatchItems
  }
  let label = getSwatchLabel(layer)
  let outlineWidth = getProperty(outline.paint['line-width'])
  let outlineColor = getProperty(outline.paint['line-color'])
  let fillColor = getProperty(layer.paint['fill-color'])
  let fillOpacity = getProperty(layer.paint['fill-opacity'])
  let swatch = getFillSwatch(
    outlineWidth,
    outlineColor,
    fillColor,
    fillOpacity,
    label
  )

  return <>{swatch}</>
}

export default ViewerFillSwatch
