import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import scss from './Tab.scss'

const Tab = React.memo(({
  activeTab,
  icon = null,
  label,
  column,
  customClass,
  onClick,
}) => {
  const tabClass = column === 'column' ? 'tabListItemColumn' : 'tabListItem'
  const tabClassActive = activeTab === label ? 'tabListActive' : ''
  const tabCustomClass = customClass ? customClass : ''

  return (
    <>
      <li
        className={[
          scss[tabClass],
          scss[tabClassActive],
          scss[tabCustomClass],
        ].join(' ')}
        onClick={() => onClick(label)}
        data-tip={label}
        data-for={'for' + label}
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0)'
        data-place='bottom'
      >
        {icon ? <FontAwesomeIcon icon={icon} size='1x' /> : ''}
        <span>{label}</span>
      </li>
     
        <div className={scss.reactToolTip}>
          <ReactTooltip
            id={'for' + label}
            getContent={dataTip => `${dataTip}`}
          />
        </div>
     
    </>
  )
})

export default Tab
