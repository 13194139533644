export const POINT_FEATURE = {
  type: 'Feature',
  properties: {},
  geometry: { coordinates: [], type: 'Point' },
}

export const LINE_FEATURE = {
  type: 'Feature',
  properties: {},
  geometry: { coordinates: [], type: 'LineString' },
}

export const POLYGON_FEATURE = {
  type: 'Feature',
  properties: {},
  geometry: { coordinates: [], type: 'Polygon' },
}

export const FEATURE_COLLETION = {
  type: 'FeatureCollection',
  features: [],
}
