import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import DropZone from '../../../components/DropZone/DropZone'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ organizationImg, visible, accountID, accountName }) {
  const [open, setOpen] = useState(true)

  const [message, setMessage] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [organizationImageState, setOrganizationImage] =
    useState(organizationImg)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = imageData => {
    const method = 'POST'
    let body

    let pImage = imageData.key
    let pImageNew = pImage.split('\\').pop().split('/').pop()
    let url = apis['apiDatabase'].uri + 'account/update'

    if (!accountID) {
      body = {
        profileImg: pImageNew,
        accountID: user.accountID,
      }
    } else {
      body = {
        profileImg: pImageNew,
        accountID: accountID,
      }
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
    setMountMakeDataConfig(true)
  }

  const handleUpload = imageData => {
    buildFetchParams(imageData)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const configFinished = () => {
    setOpen(false)
    onClose(false)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const dirName = `client_data/static/images`

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='account-logo-dialog'
          open={open}
        >
          <DialogTitle id='account-logo'>
            {accountName ? 'Change ' + accountName + "'s" : 'Upload Account'}{' '}
            Logo
            <FontAwesomeIcon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent className={modalScss.MuiDialogBoxes}>
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              <DropZone
                key='dropzone'
                doka
                maxSize={10048576}
                maxFiles={1}
                dirName={dirName}
                handleUploadResponse={handleUpload}
                fileType='.jpg,.jpeg,.png'
                successButtonLabel='Upload'
              />
            )}
            {message && (
              <div className={scss.message}>
                <div>{message}</div>
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function OrganizationImage(props) {
  return <SimpleDialog {...props} />
}
