export const TOC_WIDTH = 302
export const SIDE_BAR_WIDTH = 56
export const DEFAULT_LATITUDE = 39.742043
export const DEFAULT_LONGITUDE = -104.991531
export const DEFAULT_ZOOM = 5

import { CLICKED_FEATURE } from '../../../data/Layers/Auxiliary/ClickedFeature'
import { DELETE } from '../../../data/Layers/Auxiliary/Delete'
import { DRAW } from '../../../data/Layers/Auxiliary/Draw'
import { GEO_SPATIAL_RESULTS } from '../../../data/Layers/Auxiliary/GeoSpatialResults'
import { MEASURE } from '../../../data/Layers/Auxiliary/Measure'
import { MEASURE_CONTROL } from '../../../data/Layers/Auxiliary/MeasureControl'
import { COORDINATE_SEARCH } from '../../../data/Layers/Auxiliary/Search'
import { GEOCODE } from '../../../data/Layers/Auxiliary/Search'
import { SELECTED_FEATURES } from '../../../data/Layers/Auxiliary/SelectedFeatures'
