import React from 'react'
import { Storage } from 'aws-amplify'
// UTIL for processing utilities and general helper functions

export function generateRandomString(length, numOnly) {
  const allCapsAlpha = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
  const allLowerAlpha = [...'abcdefghijklmnopqrstuvwxyz']
  const allNumbers = [...'0123456789']

  let base = [...allNumbers]
  if (!numOnly) {
    base = [...base, ...allCapsAlpha, ...allLowerAlpha]
  }
  return [...Array(length)]
    .map(() => base[(Math.random() * base.length) | 0])
    .join('')
}

export function capitalize(string) {
  if (typeof string !== 'string') return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function unToSlug(slug) {
  if (typeof slug !== 'string') return slug
  slug = slug.replace(/[^a-zA-Z ]/g, ' ')

  return capitalize(slug)
}

export function generateUUID() {
  return (
    generateRandomString(8) +
    '-' +
    generateRandomString(4) +
    '-' +
    generateRandomString(4) +
    '-' +
    generateRandomString(4) +
    '-' +
    generateRandomString(12)
  )
}

export function makeUniqueIDNumbersOnly(length) {
  let result = ''
  const characters = '0123456789'
  const charactersLength = characters.length
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function verifyResult(resultArray) {
  const verify = resultArray

  if (Array.isArray(verify)) {
    if (verify.length >= 2) {
      if (verify[0]) {
        return true
      }
      return false
    }
  }
  if (typeof verify === 'object') {
    if ('success' in verify) {
      return verify.success
    }
  }
  return false
}

export function resultMessage(resultArray) {
  const verify = resultArray
  if (Array.isArray(verify)) {
    if (verify.length >= 2) {
      return verify[1]
    }
  }
  if (typeof verify === 'object') {
    if ('message' in verify) {
      return verify.message
    }
  }
  return null
}

export function resultReturn(resultArray) {
  const verify = resultArray
  if (Array.isArray(verify)) {
    if (verify.length >= 3) {
      return verify[2]
    }
  }
  if (typeof verify === 'object') {
    if ('data' in verify) {
      return verify.data
    }
  }
  return null
}

export function showMessage(title = '', msg = '') {
  let partCount = 0
  if (title !== '') partCount += 1
  if (msg !== '') partCount += 1

  if (partCount === 2) {
    console.log(title, msg)
    console.log(title + '\n' + msg)
  } else if (partCount === 1) {
    if (title !== '') console.log(title)
    if (msg !== '') console.log(msg)
  }
}

export function alertMessage(title = '', msg = '') {
  let partCount = 0
  if (title !== '') partCount += 1
  if (msg !== '') partCount += 1

  if (partCount === 2) {
    alert(title, msg)
    alert(title + '\n' + msg)
  } else if (partCount === 1) {
    if (title !== '') alert(title)
    if (msg !== '') alert(msg)
  }
}

export function printJS(o) {
  let str = ''

  for (const p in o) {
    if (typeof o[p] === 'string') {
      str += p + ': "' + o[p] + '"; \n'
    } else {
      str += p + ': { \n' + printJS(o[p]) + '}'
    }
  }

  return str
}

export function objectKeyValCheck(val, object, key) {
  const matches = object.filter(i => {
    if (key in i) return i[key] == val
    return false
  })
  return matches.length === 0
}

export function objectKeyVal(val, object, key) {
  const matches = object.filter(
    i => {
      if (key in i) return i[key] == val
      return false
    }
    // console.log(i[key],val,i[key] == val)
  )

  if (matches.length === 1) {
    return matches[0]
  }
  return matches
}

export function addVal(array, key, val) {
  if (Array.isArray(array)) {
    if (!array.includes(key)) {
      return [...array, key]
    }
    return array
  }
  if (typeof array === 'object') {
    if (!(key in array)) {
      array[key] = val
      return array
    }
    return array
  }
  return null
}

export function unVal(array, key) {
  if (Array.isArray(array)) {
    if (array.includes(key)) {
      return array.filter(function (item) {
        return item !== key
      })
    }
    return array
  }

  if (typeof array === 'object') {
    if (key in array) {
      delete array[key]
      return array
    }
    return array
  }
  return null
}

export function toggleVal(array, key, val) {
  if (Array.isArray(array)) {
    if (!array.includes(key)) {
      return [...array, key]
    }
    return array.filter(function (item) {
      return item !== key
    })
  }
  if (typeof array === 'object') {
    if (!(key in array)) {
      array[key] = val
      return array
    }
    delete array[key]
    return array
  }
  return null
}

export async function getImage(UUID, defaultImage) {
  // console.info('getImage',UUID)
  // Already Processed URL
  if (typeof UUID === 'string' && UUID && UUID.substr(0, 8) === 'https://')
    return Promise.resolve(UUID)

  if (defaultImage == null) defaultImage = 'default.png'

  const imageUrl = 'client_data/static/images/'
  let filePath

  if (UUID === '' || UUID == null) {
    filePath = imageUrl + defaultImage
  } else filePath = imageUrl + UUID

  return Storage.get(filePath, { expires: 60 * 60 }) // Expires after 60 minutes
  // .then((result) => {
  //   console.log('getImage',filePath,result)
  //   if (typeof callback === 'function') callback(false,result)
  // })
  // .catch(err => {
  //   console.log('getImage error',filePath,err)
  //   if (typeof callback === 'function') callback(true,err)
  // });
}

export function getImageAsStyle(img, options) {
  const size = typeof options.size !== 'undefined' ? options.size : '32px'

  const imgStyle = {
    backgroundImage: "url('" + img + "')",
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'default',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }

  if (size !== false) {
    imgStyle.height = size
    imgStyle.width = size
  }

  return imgStyle
}

/**
 * A utility to find the tier pricing for the Stripe plan, returns the full amount due
 * If unitPrice is passed, returns the unit price for the current tier
 * @param {*} plan The plan object returned from Stripe
 * @param {number} [planQuantity] Quanity involved in this plan
 * @param {boolean} [unitPrice] return unit price?
 */
export const getStripePlanPrice = (plan, planQuantity, unitPrice = false) => {
  let targetTier

  plan.tiers.some(tier => {
    targetTier = tier
    return !tier.up_to || tier.up_to >= planQuantity
  })
  if (unitPrice) return targetTier.unit_amount / 100

  return parseFloat(((targetTier.unit_amount / 100) * planQuantity).toFixed(2))
}

export function isSuperAdmin(user) {
  if (typeof user.isSuperAdmin !== 'undefined') return user.isSuperAdmin
  throw new Error('Unable to identify isSuperAdmin on user.') // Throw error if isSuperADmin param does not exist.
}

export function buildImageStack(imgArray, options) {
  console.log('buildImageArray', imgArray)
  if (!Array.isArray(imgArray)) imgArray = [imgArray]
  if (typeof options !== 'object') options = {}

  const all = typeof options.all !== 'undefined' ? options.all : true
  const imgStackClass =
    typeof options.imgStackClass !== 'undefined'
      ? options.imgStackClass
      : 'imageStack'
  const imgStackItemClass =
    typeof options.imgStackItemClass !== 'undefined'
      ? options.imgStackItemClass
      : 'imageStackItem'
  const profileImgClass =
    typeof options.profileImgClass !== 'undefined'
      ? options.profileImgClass
      : 'projectEmployeePic'

  const returnTxt = []
  const itemList = []
  let curItem = 0
  let curColumn = 0

  // var stackOptions = ['default' => 'user'];
  // if (size !== false) stackoptions.width = size;
  // if (size !== false) stackoptions.height = size;

  imgArray.forEach(function (item) {
    if (typeof item !== 'object') return

    let itemName = ''
    let itemURL = ''

    if (item.name) itemName = item.name
    if (item.displayName) itemName = item.displayName

    if (item.image) itemURL = item.image
    if (item.profileImg) itemURL = item.profileImg

    // If invalid, skip.
    if (itemURL === '') return
    curItem += 1
    curColumn += 1

    if (all || curItem <= 5) {
      returnTxt.push({
        className: [imgStackItemClass, 'imageStackItem_' + curItem].join(' '),
        style: {
          gridColumn: (curColumn += 1) + ' / span 3',
          gridRow: 1,
          zIndex: curItem,
        },
        id: item.id,
        name: item.displayName,
        imgStyle: getImageAsStyle(itemURL, 'user.png', options),
      })

      itemList.push(itemName)
    }
  })

  if (!all && imgArray.length > 5) {
    returnTxt.push({
      className: [imgStackItemClass, 'imageStackItem_showMore'].join(' '),
      style: { gridColumn: '11 / span 3', gridRow: 1, zIndex: 6 },
      imgContents: '+' + (imgArray.length - 5).toString(),
    })
  }

  return (
    <div className={imgStackClass}>
      {returnTxt.map(txt => {
        return (
          <div
            className={txt.className}
            style={txt.style}
            data-entryid={txt.id}
            data-entryname={txt.name}
            key={txt.id + '_' + txt.name}
          >
            <div
              className={profileImgClass}
              style={txt.imgStyle}
              title={txt.name}
            >
              {txt.imgContents != null && txt.imgContents}
            </div>
          </div>
        )
      })}
    </div>
  )
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @example arrayOfObjects.sort(utils.objectSort("key"))
 * @param {String} property Key of the object to sort.
 */
export function objectSort(property) {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property])
    }
    return a[property].localeCompare(b[property])
  }
}

export function getRole(role, userRole) {
  if (typeof role === 'undefined') role = userRole
  if (role === 'owner') return 'Owner'
  if (role === 'admin') return 'Admin'
  if (role === 'read-only') return 'Read-Only'
  if (role === 'viewer') return 'Viewer'
  if (role === 'editor') return 'Editor'
  if (role === 'full-user') return 'Full User'
  if (role === 'no-access') return 'No Access'
  throw new Error('Unable to calculate role of user.')
}

export const checkTOCValue = idString => {
  if (typeof idString === 'string') {
    const target = idString.split('_').pop()
    const targetInt = parseInt(target)
    return targetInt - 11
  }
  throw new Error('Passed id string value must be of type string')
}

export const buildOptions = (currentValue, selectOptions) => {
  let currentIDFound = false

  // Build initial list of options
  const optionsList = selectOptions.map(option => {
    if (currentValue && option.value == currentValue) currentIDFound = true

    return (
      <option key={`selectKey_${option.value}`} value={option.value}>
        {option.label}
      </option>
    )
  })

  // Check for Empty Options
  if (!optionsList.length)
    optionsList.push(
      <option ley='selectKey_noOptions' value=''>
        No Options Available
      </option>
    )

  // If value is not on the list, add it.
  if (!currentIDFound && currentValue) {
    optionsList.push(<option value=''>———</option>)
    optionsList.push(
      <option value={currentValue}>{unToSlug(currentValue)}</option>
    )
  }

  return optionsList
}

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  // Strip all characters but numerical ones.
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''

  const toFixedFix = function (n, precision) {
    const k = precision ** 10
    return '' + Math.round(n * k) / k
  }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export const extractLayerInt = layerID => {
  // console.log('StartingLayer',layerID);

  const returnList = !!(typeof layerID === 'object' && Array.isArray(layerID))
  let layerList = []

  if (typeof layerID === 'object' && Array.isArray(layerID)) layerList = layerID
  else layerList.push(layerID)

  const finalReturn = []
  layerList.forEach(layer => {
    let procLayer = layer

    if (typeof layer === 'string' && isNaN(layer) && layer != null) {
      const layerIDArr = layer.split('_')
      if (layerIDArr.length > 1)
        if (isNumeric(layerIDArr[layerIDArr.length - 1]))
          procLayer = parseInt(layerIDArr[layerIDArr.length - 1])
    }

    finalReturn.push(procLayer)
  })

  if (!returnList) return finalReturn[0]
  return finalReturn
}

export function deepCopy(array) {
  const copy = JSON.parse(JSON.stringify(array))
  return copy
}

export const dynamicDisplayUsage = usageMB => {
  if (Math.floor(Number.parseFloat(usageMB)) >= 100) {
    return Number.parseFloat(usageMB / 1000).toFixed(2) + ' GB'
  }
  return Number.parseFloat(usageMB).toFixed(1) + ' MB'
}
