import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ErrorMessage = () => {
  const user = useSelector(state => state.user)

  let message = null
  if (!user.mapID) {
    message = (
      <p
        style={{
          textAlign: 'center',
          fontWeight: 400,
          fontSize: '12px',
          marginBottom: '25px',
        }}
      >
        You are not a part of any maps on this account.
        {user.isAdmin ? (
          <>
            {' '}
            Please go to the{' '}
            <Link style={{ color: 'white' }} to='/maps'>
              maps area
            </Link>{' '}
            to add yourself to one or more maps.
          </>
        ) : (
          ' Please contact your administrator to add to one or more maps.'
        )}
      </p>
    )
  } else if (!user.isActive) {
    message = (
      <p
        style={{
          textAlign: 'center',
          fontWeight: 400,
          fontSize: '12px',
          marginBottom: '25px',
        }}
      >
        You can no longer access this map or account. Please contact your
        administrator to reinstate your access.
      </p>
    )
  }

  return message
}

export default ErrorMessage
