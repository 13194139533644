import React from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'
import moment from 'moment'

// =========================================================================================== //
// ======== UTIL/COMPONENT for processing profile dates to match the user-preference ======== //
// =========================================================================================== //

const ProfileDate = ({
  date,
  time = false,
  datePickerDate = false,
  jsx = true,
}) => {
  const user = useSelector(state => state.user)
  const dateFormat = user.profile.dateFormat

  let newDate = ''
  if (datePickerDate) return processDateFormat(dateFormat)

  if (date) newDate = profileDateFormat({ dateFormat, date, time })

  if (jsx) return <span>{newDate}</span>
  return newDate
}

export default ProfileDate

export function profileDateFormat({ dateFormat = null, date, time = false }) {
  if (!date) return null

  if (!dateFormat) dateFormat = 'MM/DD/YYYY'
  dateFormat = dateFormat.toUpperCase()

  let newDate
  if (dateFormat === 'MM/DD/YYYY') newDate = moment(date).format('MM/DD/YYYY')
  if (dateFormat === 'DD/MM/YYYY') newDate = moment(date).format('DD/MM/YYYY')
  if (dateFormat === 'YYYY-MM-DD') newDate = moment(date).format('YYYY-MM-DD')
  if (dateFormat === 'YYYY-DD-MM') newDate = moment(date).format('YYYY-DD-MM')

  //Time Handling
  if (time) newDate += ', ' + moment(date).format('h:mma')

  return newDate
}

export function processDateFormat(dateFormat) {
  if (!dateFormat) dateFormat = 'MM/DD/YYYY'
  return dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
}
