import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as mapUtils from '../../../../../utilities/mapStyle'
import { getMapCenter } from '../../../../../utilities/geospatial'
import {
  updateMapStyle,
  setMapCursor,
  setMapToolTip,
} from '../../../../../actions'
import { apis } from '../../../../../config/apiConfig'
import AsyncFetch from '../../../../../utilities/AsyncFetch'

export default function SelectFeaturesForDelete({ geometry, id, mapRef }) {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const overPanel = useSelector(state => state.setOverPanel)
  const mapToolTip = useSelector(state => state.mapToolTip)
  const [featuresToDelete, setFeaturesToDelete] = useState([])
  const [mouseDownCenter, setMouseDownCenter] = useState(false)
  const [clickedFeatureIndex, setClickedFeatureIndex] = useState(0)
  const [urlObjects, setUrlObjects] = useState(null)
  let style = mapStyle.toJS()
  let targetLayers = [
    'draw_line_cold',
    'draw_polygon_cold',
    'draw_verticies_cold',
  ]
  if (
    id !== 'draw_line_cold' &&
    id !== 'draw_polygon_cold' &&
    id !== 'draw_verticies_cold'
  ) {
    targetLayers.push(id)
  }

  const findFeaturesUnderClick = event => {
    const mapObj = mapRef.getMap()
    const bbox = [
      [event.offsetX - 5, event.offsetY - 5],
      [event.offsetX + 5, event.offsetY + 5],
    ]
    const clickedFeatures = mapObj.queryRenderedFeatures(bbox, {
      layers: targetLayers,
    })
    Promise.all(clickedFeatures).then(() => {
      getFeatureGeoJson(clickedFeatures)
    })
  }

  const getFeatureGeoJson = clickedFeatures => {
    const targetFeature = clickedFeatures[clickedFeatureIndex]
    if (!targetFeature) return
    dispatch(setMapCursor('wait'))
    const featureId = targetFeature.properties.mamid
    const targetType = targetFeature.layer.type
    const targetSourceId = targetFeature.source
    const targetSource = mapUtils.getSource(style, targetSourceId)

    if (targetSource.type !== 'geojson') {
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'layer/geojson/get'
      const body = {
        layerID: targetFeature.layer.id,
        featureID: featureId,
      }
      setUrlObjects([{ url, body, method }])
    } else {
      const hotFeature = targetSource.data.features.filter(
        feature => feature.id === targetFeature.id
      )

      const clicked = hasFeatureBeenCliked(hotFeature[0])

      if (clicked) removeFeatureFromHotSource(hotFeature[0])
      if (!clicked) makeFeatureDeletable(hotFeature[0], targetType)
      dispatch(setMapCursor('crosshair'))
    }
  }

  const hasFeatureBeenCliked = clickedFeature => {
    let hotLayer
    if (geometry === 'circle') {
      hotLayer = mapUtils.getLayerObject(style, 'delete_circle')
    } else {
      hotLayer = mapUtils.getLayerObject(style, 'delete_line')
    }

    const hotSourceId = hotLayer.source
    const hotSource = mapUtils.getSource(style, hotSourceId)

    const inHotSource = hotSource.data.features.filter(hotFeature => {
      if (clickedFeature.properties.mamid) {
        return hotFeature.properties.mamid === clickedFeature.properties.mamid
      } else {
        return hotFeature.id === clickedFeature.id
      }
    })
    if (inHotSource.length) return true
    return false
  }

  const removeFeatureFromHotSource = clickedFeature => {
    let hotLayer
    if (geometry === 'circle') {
      hotLayer = mapUtils.getLayerObject(style, 'delete_circle')
    } else {
      hotLayer = mapUtils.getLayerObject(style, 'delete_line')
    }
    const newFeaturesToDelete = featuresToDelete.filter(featureToDelete => {
      if (clickedFeature.properties.mamid) {
        return (
          featureToDelete.properties.mamid !== clickedFeature.properties.mamid
        )
      } else {
        return featureToDelete.id !== clickedFeature.id
      }
    })
    const hotSourceId = hotLayer.source
    const hotSource = mapUtils.getSource(style, hotSourceId)
    const newHotSource = hotSource.data.features.filter(hotFeature => {
      if (clickedFeature.properties.mamid) {
        return hotFeature.properties.mamid !== clickedFeature.properties.mamid
      } else {
        return hotFeature.id !== clickedFeature.id
      }
    })

    style.sources[hotSourceId].data.features = newHotSource
    setFeaturesToDelete(newFeaturesToDelete)
    dispatch(updateMapStyle(fromJS(style)))
  }

  const makeFeatureDeletable = geojson => {
    let hotLayer

    if (geometry === 'line' || geometry === 'fill') {
      hotLayer = mapUtils.getLayerObject(style, 'delete_line')
    }
    if (geometry === 'circle') {
      hotLayer = mapUtils.getLayerObject(style, 'delete_circle')
    }

    const hotSourceId = hotLayer.source
    const hotSource = mapUtils.getSource(style, hotSourceId)
    hotSource.data.features.push(geojson)
    style.sources[hotSourceId] = hotSource
    setFeaturesToDelete([...featuresToDelete, geojson])
    dispatch(updateMapStyle(fromJS(style)))
  }

  const handleMouseDown = e => {
    const center = getMapCenter(viewport)
    if (overPanel) return
    setMouseDownCenter(center)
  }

  const handleMouseUp = e => {
    const center = getMapCenter(viewport)
    if (JSON.stringify(center) !== JSON.stringify(mouseDownCenter)) return
    if (overPanel) return
    findFeaturesUnderClick(e)
  }

  const enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousedown', handleMouseDown, true)
    main.addEventListener('mouseup', handleMouseUp, true)
  }

  const disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousedown', handleMouseDown, true)
    main.removeEventListener('mouseup', handleMouseUp, true)
  }

  const showDrawToolTip = () => {
    if (!mapToolTip.show) {
      dispatch(
        setMapToolTip({
          show: true,
          tipHtml: (
            <>
              <div>Press Delete Key to Delete Features</div>
            </>
          ),
        })
      )
    }
  }

  const hideDrawToolTip = () => {
    if (mapToolTip.show) {
      dispatch(
        setMapToolTip({
          show: false,
          tipHtml: null,
        })
      )
    }
  }

  const fetchFinished = result => {
    setUrlObjects(null)
    dispatch(setMapCursor('crosshair'))
    if (result[0].success) {
      const clicked = hasFeatureBeenCliked(result[0].data.features[0])
      if (clicked) removeFeatureFromHotSource(result[0].data.features[0])
      if (!clicked) makeFeatureDeletable(result[0].data.features[0])
    } else {
      console.log('geojson fetch failed')
    }
  }

  useEffect(
    function () {
      enableEventListeners()
      return function cleanup() {
        disableEventListeners()
      }
    },
    [handleMouseDown]
  )

  useEffect(function () {
    enableEventListeners()
    return function cleanup() {
      dispatch(
        setMapToolTip({
          show: false,
          tipHtml: null,
        })
      )
      dispatch(updateMapStyle(fromJS(style)))
    }
  }, [])

  useEffect(
    function () {
      if (featuresToDelete.length) {
        showDrawToolTip()
      } else {
        hideDrawToolTip()
      }
    },
    [featuresToDelete]
  )

  useEffect(() => {
    dispatch(setMapCursor('crosshair'))
  }, [])

  return (
    urlObjects && (
      <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
    )
  )
}
