import React from 'react'
import ReactTooltip from 'react-tooltip'
import appScss from '../../App/App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ExpandSelect = ({
  selectFeaturesOnScreen,
  targetLayer,
  disabled = false,
}) => {
  return (
    <>
      <button
        onClick={() => !disabled && selectFeaturesOnScreen(targetLayer)}
        className={disabled ? appScss['disabled-button'] : null}
        data-tip='Select All Features on Screen'
        data-for='expand-selected'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        <FontAwesomeIcon icon={['fal', 'expand-alt']} size='1x' />
      </button>
      <div className={appScss.reactToolTip}>
        <ReactTooltip
          id={'expand-selected'}
          getContent={dataTip => `${dataTip}`}
        />
      </div>
    </>
  )
}

export default ExpandSelect
