import React, { useState, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import AsyncFetch from '../../../utilities/AsyncFetch'
import scss from '../../Profile/Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import mapViewScss from './MapViews.scss'
import MakeDataConfigComponent from '../../../utilities/dataConfig'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

function SimpleDialog({ visible, needPermissionLayers, onHandleClose}) {
  const [open, setOpen] = useState(true)

  const [setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(null)

  const handleOnClose = () => {
    setOpen(false)
    if (visible) visible(false)
    onHandleClose()
  }

  const fetchFinished = () => {
    setFetchObjects(null)
    setFetching(null)
    setMountMakeDataConfig(true)
    handleOnClose()
  }

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}

      {mountMakeDataConfig && (
        <MakeDataConfigComponent updateStyle onFinish={() => {}} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-new-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-new-account-dialog'>
          Layer permissions
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>

        <DialogContent
          className={modalScss.MuiDialogBoxes}
          style={{ width: '500px' }}
        >
          {needPermissionLayers ? (
            <div>
              <div>
                You do not have permissions to access the following layers.
                Please contact the owner of the layer(s) to get access to the
                layers:{' '}
                <div
                  className={[scss.handleContainer, 'handle'].join(' ')}
                ></div>
                <br />
              </div>

              <div>
                {needPermissionLayers.map(layer => {
                  return (
                    <div key={layer.layerID}>
                      {layer.labelName ? (
                        <>
                          <span className={mapViewScss.label}>Name: </span>
                          <span>{layer.labelName}</span>
                        </>
                      ) : null}{' '}
                      {mapViewScss.label ? (
                        <>
                          <span className={mapViewScss.label}>ID: </span>{' '}
                          <span>{layer.layerID}</span>{' '}
                        </>
                      ) : null}
                      {layer.layerOwnerName ? (
                        <>
                          <span className={mapViewScss.label}>Owner:</span>{' '}
                          <span>{layer.layerOwnerName}</span>
                        </>
                      ) : null}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions style={{backgroundColor:'#1a2937'}}>
          <button
            className={modalScss.modalMainButton}
            style={{ width: '100%', placeContent: 'center' }}
            onClick={handleOnClose}
          >
            OK
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function mapViewsPermissionLayers(props) {
  return <SimpleDialog {...props} />
}
