/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import scss from './Tabs.scss'
import Tab from './Tab'

import { generateRandomString } from '../../utilities/util'

const TabsV2 = React.memo(
  ({ children, customClass, setIsAttributeWindow, setIsDocumentWindow }) => {
    const [activeTab, setActiveTab] = useState(null)
    const [newChildren, setNewChildren] = useState(null)

    useEffect(() => {
      if (activeTab === 'Attributes') {
        setIsDocumentWindow(false)
        setIsAttributeWindow(true)
      } else if (activeTab === 'Documents') {
        setIsDocumentWindow(true)
        setIsAttributeWindow(false)
      }
    }, [activeTab])

    const tabUUID = generateRandomString(8)
    // if children are updated, process children into newChildren
    useEffect(() => {
      if (!Array.isArray(children)) children = [children]

      setNewChildren(children)
    }, [children])

    // On initial load, set active tab to first tab
    useEffect(() => {
      if (!Array.isArray(children)) children = [children]

      if (children && children[0] && children[0].props.label)
        setActiveTab(children[0].props.label)
    }, [])

    const directionClass = 'row'
    const tabDisplay = ''
    const tabContentMinHeight = ''

    if (!newChildren) return null

    return (
      <div className={[scss.tabs, scss[tabDisplay]].join(' ')}>
        <ol
          data-uuid={tabUUID}
          className={[
            scss.tabList,
            scss[directionClass],
            scss[customClass],
          ].join(' ')}
        >
          {newChildren.map(child => {
            if (!child) return null
            const { icon, label } = child.props

            return (
              <Tab
                key={label}
                activeTab={activeTab}
                icon={icon}
                label={label}
                column={directionClass}
                customClass={customClass}
                onClick={setActiveTab}
              />
            )
          })}
        </ol>
        <div className={[scss.tabContent, scss[tabContentMinHeight]].join(' ')}>
          {newChildren.map(child => {
            if (!child) return null
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
)

export default TabsV2
