import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAlert } from 'react-alert'

export default function Alert() {
  const alert = useSelector(state => state.alert)

  const displayAlert = useAlert()

  useEffect(() => {
    if (alert !== null) {
      if (typeof alert.success !== 'undefined') {
        displayAlert.success(alert.success, { timeout: 5000 }) // 5 sec timeout
      } else if (typeof alert.error !== 'undefined') {
        displayAlert.error(alert.error, { timeout: 10000 }) // 10 sec timeout
        console.error('React-Alert displayed an error', alert.error)
      } else if (typeof alert.info !== 'undefined') {
        displayAlert.info(alert.info, { timeout: 10000 }) // 5 sec timeout
      } else
        console.error(
          'Invalid alert type. Please check your alert configuration',
          alert
        )
    }
  }, [alert])

  return null
}
