import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMapCursor,
  disablePopup,
  updateMapStyle,
  setAppDrawMode,
  setAppMeasureMode,
} from '../../../../actions'
import New from './New/New'
import Edit from './Edit/Edit'
import Clear from './Clear/Clear'
import Delete from './Delete/Delete'
import Save from './Save/Save'
import Settings from './Settings/Settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import appScss from '../../../App/App.scss'
import scss from './DrawMode.scss'
import { HOT_SOURCES, DELETE_SOURCES } from '../Constants'
import * as mapUtils from '../../../../utilities/mapStyle'
import { fromJS } from 'immutable'
import Message from './Message/Message'

const DrawMode = React.memo(({ targetLayer, geometry, mapRef, saveCount, setSaveCount }) => {

  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  
  const [mode, setMode] = useState('PAUSE')
  const [clearEdits, setClearEdits] = useState(false)
  const handleClick = e => {
    if (e === mode) {
      setMode('PAUSE')
    } else {
      setMode(e)
    }
  }

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setMode('PAUSE');
      }
    }
  
    document.addEventListener('keydown', handleEscapeKey);
  
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    }
  }, [])

  const emptyHotLayerSources = style => {
    HOT_SOURCES.forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    DELETE_SOURCES.forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    return style
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  useEffect(() => {
    if (mode === 'PAUSE') {
      let style = mapStyle.toJS()
      style = emptyHotLayerSources(style)
      dispatch(setMapCursor('grab'))
      dispatch(disablePopup(false))
      dispatch(updateMapStyle(fromJS(style)))
    }
    if (mode === 'NEW') {
      dispatch(setMapCursor('crosshair'))
      dispatch(disablePopup(true))
    }
    if (mode === 'EDIT') {
      dispatch(setMapCursor('crosshair'))
      dispatch(disablePopup(true))
    }
    if (mode === 'DELETE') {
      dispatch(setMapCursor('crosshair'))
      dispatch(disablePopup(true))
    }
    dispatch(setAppDrawMode(mode))
    dispatch(setAppMeasureMode(null))
  }, [mode])

  useEffect(() => {
  
  }, [targetLayer])

  useEffect(() => {
    return () => {
      dispatch(setMapCursor('grab'))
      dispatch(disablePopup(false))
      dispatch(setAppDrawMode('PAUSE'))
    }
  }, [])

  return (
    <div className={scss.col} style={{ display: 'flex' }}>
      {targetLayer ? (
        <>
          <div className={scss.wrapper}>
            <div
              style={{
                fontSize: mode === 'NEW' ? '15px' : '13px',
                color: mode === 'NEW' ? '#f1cb57' : 'white',
              }}
              onClick={() => handleClick('NEW')}
              data-tip='New Features'
            >
              <FontAwesomeIcon icon={'plus'} size='1x' />
            </div>
            <div
              style={{
                fontSize: mode === 'EDIT' ? '15px' : '13px',
                color: mode === 'EDIT' ? '#f1cb57' : 'white',
              }}
              onClick={() => handleClick('EDIT')}
              data-tip='Edit Existing Features'
            >
              <FontAwesomeIcon icon={'edit'} size='1x' />
            </div>

            <Clear geometry={geometry} setClearEdits={setClearEdits} />

            <span
              style={{
                width: '13px',
              }}
            ></span>

            <Save 
              geometry={geometry} 
              id={targetLayer.value} 
              saveCount={saveCount} 
              setSaveCount={setSaveCount}
              drawMode={mode}
            />

            <div
              style={{
                fontSize: mode === 'DELETE' ? '15px' : '13px',
                color: mode === 'DELETE' ? '#f1cb57' : 'white',
              }}
              onClick={() => handleClick('DELETE')}
              data-tip='Select Features to Delete'
            >
              <FontAwesomeIcon icon={'trash-alt'} size='1x' />
            </div>
            <div
              style={{
                fontSize: mode === 'SETTINGS' ? '15px' : '13px',
                color: mode === 'SETTINGS' ? '#f1cb57' : 'white',
              }}
              onClick={() => handleClick('SETTINGS')}
              data-tip='Settings'
            >
              <FontAwesomeIcon icon={'cog'} size='1x' />
            </div>
          </div>
          <div className={appScss.reactToolTip}>
            <ReactTooltip
              place='bottom'
              effect='solid'
              offset={{ bottom: 10 }}
            />
          </div>
        </>
      ) : null}
      {mode === 'NEW' ? <New geometry={geometry} mapRef={mapRef} /> : null}
      {mode === 'EDIT' ? (
        <Edit
          targetLayer={targetLayer}
          geometry={geometry}
          mapRef={mapRef}
          clearEdits={clearEdits}
        />
      ) : null}
      {mode === 'DELETE' ? (
        <Delete
          targetLayer={targetLayer}
          geometry={geometry}
          mapRef={mapRef}
          clearEdits={clearEdits}
        />
      ) : null}
      {mode === 'SETTINGS' ? (
        <Settings
          targetLayer={targetLayer}
          geometry={geometry}
          mapRef={mapRef}
          setMode={setMode}
        />
      ) : null}
      <Message></Message>
    </div>
  )
})

export default DrawMode
