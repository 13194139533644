/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import scss from './ManageAttributeContextMenuStyle.scss'
import appScss from '../../../App/App.scss'

const ManageAttributeContextMenu = ({
  y,
  setDisplayManageAttributesMenu,
  setDisplayManageAttributes,
  setDisplayManageDocuments,
}) => {
  const style = { top: y + 'px', left: '50px' }

  useEffect(() => {
    setDisplayManageDocuments(false)
    setDisplayManageAttributes(false)
  }, [])

  return (
    <div className={scss.ManageAttributeContextMenu} style={style}>
      <button
        type='button'
        className={scss.ManageAttributesButton}
        onClick={() => {
          setDisplayManageAttributes(true)
          setDisplayManageDocuments(false)
          setDisplayManageAttributesMenu(false)
        }}
      >
        Feature Attributes{' '}
        <FontAwesomeIcon
          icon='question-circle'
          size='1x'
          data-tip='Add, edit, and delete Feature Attributes on this layer.'
          data-for='data-driven'
          data-effect='solid'
          data-place='right'
          data-arrow-color='rgba(0, 0, 0, 0.68)'
          style={{ fontSize: '12px', padding: '0 5px' }}
        />
      </button>
      <button
        type='button'
        className={scss.ManageDocumentsButton}
        onClick={() => {
          setDisplayManageDocuments(true)
          setDisplayManageAttributes(false)
          setDisplayManageAttributesMenu(false)
        }}
      >
        Document Attributes{' '}
        <FontAwesomeIcon
          icon='question-circle'
          size='1x'
          data-tip='Add, edit, and delete Document Attributes on this layer.'
          data-for='data-driven'
          data-effect='solid'
          data-place='right'
          data-arrow-color='rgba(0, 0, 0, 0.68)'
          style={{ fontSize: '12px', padding: '0 5px' }}
        />
      </button>
      <div className={appScss.reactToolTip}>
        <ReactTooltip id='data-driven' getContent={dataTip => `${dataTip}`} />
      </div>
    </div>
  )
}

export default ManageAttributeContextMenu
