
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../../../utilities/geospatial'
import * as mapUtils from '../../../../../utilities/mapStyle'
import _ from 'lodash'
import {
  updateMapStyle,
  setMapToolTip,
} from '../../../../../actions'
import FindFeatureVertex from './FindFeatureVertex'

const HOT_SOURCES = [
  'draw_polygon_hot',
  'draw_polygon_outline_hot',
  'draw_line_hot',
  'draw_verticies_hot',
  'draw_midpoints_hot',
]
import { getHotLayers, getSnappingGeom, getSnapPoint } from '../../Utils'

const EditFeature = ({
    type,
    mapRef,
    clickedFeatures,
    finishEdit
}) => {

    const dispatch = useDispatch()
    const viewport = useSelector(state => state.viewport)
    const mapStyle = useSelector(state => state.mapStyle)
    const overPanel = useSelector(state => state.setOverPanel)
    const mapToolTip = useSelector(state => state.mapToolTip)
    const snapLayer = useSelector(state => state.snapSettings.snapLayer)
    const snapLine = useSelector(state => state.snapSettings.snapLine)
    const snapVertex = useSelector(state => state.snapSettings.snapVertex)
    const [mouseEvent, setMouseEvent] = useState(null)
    const [clickedState, setClickedState] = useState(false)
    let style = mapStyle.toJS()
    let hotLayers = getHotLayers(type)
  
    const getMidPoints = feat => {
      // convert feature to line segments
      // get midpoint for each line segment
      const lineSegments = geoUtils.getLineSegments(feat)
      return lineSegments.features.map(feature => {
        return geoUtils.getLineMidPoint(feature)
      })
    }
  
    const findVertexFinished = ({ clickedCoords, clickedIndex }) => {
      setClickedState({ clickedCoords, clickedIndex })
    }
  
    const finishFeature = () => {
      hideDrawToolTip()
      const layers = mapUtils.getLayerObjects(hotLayers, style)
      layers.forEach(layer => {

        let hotSourceId = layer.hot.source
        let hotSource = mapUtils.getSource(style, hotSourceId)
   
        if(hotSource.data.features.length > 0){
          hotSource.data.features[0].properties = clickedFeatures.properties
          hotSource.data.features[0].id = clickedFeatures.id
  
          let coldSourceId = null
          let coldSource = null
          if (layer.cold) {
            coldSourceId = layer.cold.source
            coldSource = mapUtils.getSource(style, coldSourceId)
          }
          let coldDataIndex = null
          if (coldSource && coldSource.data.features.length) {
            if( hotSource.data.features[0].properties.mamid){
              // feature has a mam id and exits in database
              coldDataIndex = coldSource.data.features.findIndex(
                feature =>
                  feature.properties.mamid ===
                  hotSource.data.features[0].properties.mamid
              )
            } else {
              // the feature has been created during the current edit session and not yet saved to db
              // in this case check for id prop at root of feature
              coldDataIndex = coldSource.data.features.findIndex(
                feature =>
                  feature.id ===
                  hotSource.data.features[0].id
              )
      
            }
          }
          if (coldDataIndex === -1) coldDataIndex = null
          mapUtils.moveHotToCold(hotSource, coldSource, coldDataIndex)
          style.sources[hotSourceId] = hotSource
          if (coldSourceId) {
            style.sources[coldSourceId] = coldSource
          }
        }
       
      })
      style.sources['draw_clicked_verticie'] = mapUtils.emptySource(
        style.sources['draw_clicked_verticie']
      )
  
      finishEdit()
      style = emptyHotLayerSources(style)
      dispatch(updateMapStyle(fromJS(style)))
    }
  
    const editSelectedFeature = e => {
      const { clickedIndex } = clickedState
      if (clickedIndex === null) return
      let newPoint = geoUtils.getPointAtClick(e, viewport)
      // check for snapping
      if (snapLayer) {
        if(snapLine || snapVertex) {
          const snappingGeom = getSnappingGeom(e, mapRef, snapLayer)
          if (snappingGeom) {
            newPoint = getSnapPoint(snappingGeom, newPoint, snapLine, snapVertex)
          }
        }
      }
      const layers = mapUtils.getLayerObjects(hotLayers, style)
      let verticies = mapUtils.getSource(style, 'draw_verticies_hot')
  
      layers.forEach(layer => {
        let sourceId = layer.hot.source
        let source = mapUtils.getSource(style, sourceId)
        if (sourceId === 'draw_midpoints_hot') {
          // TODO:  Refactor this to be more streamlined
          const coords = []
          verticies.data.features.forEach(feature => {
            if (feature.geometry.coordinates[0]) {
              coords.push(feature.geometry.coordinates)
            }
          })
          let midpoints
          if (type === 'fill') {
            const poly = geoUtils.getPolygon([coords])
            midpoints = getMidPoints(poly)
          }
          if (type === 'line') {
            const line = geoUtils.getLineString(coords)
            midpoints = getMidPoints(line)
          }
          source.data.features = midpoints
          style.sources[sourceId] = source
        } else {
          source = mapUtils.replaceVerticie(
            source,
            layer.hot.type,
            verticies.data.features,
            newPoint,
            clickedIndex,
            sourceId
          )
          style.sources[sourceId] = source
        }
      })
      // update draw_clicked_verticie
      let source = mapUtils.getSource(style, 'draw_clicked_verticie')
      source = mapUtils.replaceVerticie(
        source,
        'circle',
        source.data.features,
        newPoint,
        0,
        'draw_clicked_verticie'
      )
      style.sources['draw_clicked_verticie'] = source
      dispatch(updateMapStyle(fromJS(style)))
    }
  
    const showDrawToolTip = () => {
      if (!mapToolTip.show) {
        dispatch(
          setMapToolTip({
            show: true,
            tipHtml: (
              <>
                <div>Double Click to Finish</div>
                <div>Escape Key to Cancel</div>
              </>
            ),
          })
        )
      }
    }
  
    const hideDrawToolTip = () => {
      if (mapToolTip.show) {
        dispatch(
          setMapToolTip({
            show: false,
            tipHtml: null,
          })
        )
      }
    }
  
    const emptyHotLayerSources = style => {
      HOT_SOURCES.forEach(sourceId => {
        style.sources[sourceId] = emptyLayerSource(style, sourceId)
      })
      return style
    }
  
    const emptyLayerSource = (style, sourceId) => {
      let source = mapUtils.getSource(style, sourceId)
      source = mapUtils.emptySource(source)
      return source
    }
  
    const handleMouseDown = e => {
      if (overPanel) return
      setMouseEvent(e)
    }
  
    const handleMouseUp = e => {
      setClickedState(false)
      setMouseEvent(null)
    }
  
    // debounced callback for handling mousemove
    const debouncedCallback = useCallback(
      _.debounce(
        e => {
          handleMouseMove(e), 500
        },
        [clickedState]
      )
    )
  
    const handleMouseMove = e => {
      // Note: clickedIndex can === 0, so explicitly check for null
      if (clickedState.clickedIndex !== null && clickedState.clickedCoords) {
        editSelectedFeature(e)
      }
    }
  
    const handleDoubleClick = () => finishFeature()
    
    // NOTE:
    // For Mouse Events, since we are using a debounced callback, we need to re register the events whenever handleMouseDown is called
    const enableMouseEvents = () => {
      const main = document.getElementById('main')
      main.addEventListener('mousedown', handleMouseDown, true)
      main.addEventListener('mouseup', handleMouseUp, true)
      main.addEventListener('mousemove', debouncedCallback, true)
      main.addEventListener('dblclick', handleDoubleClick, true)
    }
  
    const disableMouseEvents = () => {
      const main = document.getElementById('main')
      main.removeEventListener('mousedown', handleMouseDown, true)
      main.removeEventListener('mouseup', handleMouseUp, true)
      main.removeEventListener('mousemove', debouncedCallback, true)
      main.removeEventListener('dblclick', handleDoubleClick, true)
    }
  
    
    useEffect(
      function () {
        enableMouseEvents()
        return function cleanup() {
          disableMouseEvents()
        }
      },
      [handleMouseDown]
    )
  
    useEffect(() => {
      setMouseEvent(null)
    }, [mouseEvent])
  
    useEffect(() => {
      if (clickedFeatures) {
        showDrawToolTip()
      }
    }, [clickedFeatures])
  
    useEffect(() => {
      return () => {
        style = emptyHotLayerSources(style)
        dispatch(updateMapStyle(fromJS(style)))
        dispatch(
          setMapToolTip({
            show: false,
            tipHtml: null,
          })
        )
      }
    }, [])
  
    return (
      <>
        {mouseEvent && (
          <FindFeatureVertex
            type={type}
            event={mouseEvent}
            finished={findVertexFinished}
          />
        )}
        
      </>
    )

}


export default EditFeature