import { TOC_COLLAPSED } from '../actions/types'

export function TocCollapsed(state = false, action) {
  switch (action.type) {
    case TOC_COLLAPSED: {
      return action.payload
    }
    default:
      return state
  }
}

export function TocFilter(state = '', action) {
  switch (action.type) {
    case 'SET_TOC_LAYERS_FILTER': {
      return action.payload
    }
    default:
      return state
  }
}

export function GroupClicked(state = 1, action) {
  switch (action.type) {
    case 'GROUP_CLICKED': {
      const newState = state + 1
      return newState
    }
    default:
      return state
  }
}

export function LayerClicked(state = 1, action) {
  switch (action.type) {
    case 'LAYER_CLICKED': {
      const newState = state + 1
      return newState
    }
    default:
      return state
  }
}

export function FilterChanged(state = 1, action) {
  switch (action.type) {
    case 'FILTER_CHANGED': {
      const newState = state + 1
      return newState
    }
    default:
      return state
  }
}
