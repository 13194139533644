import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './BaseStylePickerBtn.scss'
import { MAP_STYLE_ARRAY } from '../../../mapStyles/style_config'

function BaseStylePicker({
  toggleMapStyle,
  BaseStylePickerVisible,
  currentStyle,
}) {
  const currentStyleIcon = MAP_STYLE_ARRAY.filter(
    style => style.id === currentStyle
  ).map(style => style.icon)

  return (
    <button
      className={[
        scss.mapStyleBtn,
        BaseStylePickerVisible ? scss.mapStyleBtnActive : '',
      ].join(' ')}
      onClick={toggleMapStyle}
      title='Map Style'
    >
      <span>
        <img src={currentStyleIcon} className={scss.mapIcon} />
        {currentStyle}
      </span>
      <FontAwesomeIcon
        icon={['fa', BaseStylePickerVisible ? 'chevron-up' : 'chevron-down']}
        size='1x'
      />
    </button>
  )
}

export default BaseStylePicker