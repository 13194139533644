import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as mapUtils from '../../../../../utilities/mapStyle'
import { updateMapStyle } from '../../../../../actions'
import { fromJS } from 'immutable'
import { apis } from '../../../../../config/apiConfig'
import AsyncFetch from '../../../../../utilities/AsyncFetch'

const SaveFeatures = ({ id, geometry, finishSave }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const mapStyle = useSelector(state => state.mapStyle)
  const [layerSourceId, setLayerSourceId] = useState(null)
  const [fetchObjects, setFetchObjects] = useState(null)
  let coldLayer = null

  if (geometry === 'fill') {
    coldLayer = 'draw_polygon_cold'
  }

  if (geometry === 'line') {
    coldLayer = 'draw_line_cold'
  }

  if (geometry === 'circle') {
    coldLayer = 'draw_verticies_cold'
  }
  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const emptySources = (style, sourceId) => {
    let emptySource = emptyLayerSource(style, sourceId)
    style.sources[sourceId] = emptySource
    if (sourceId === 'draw_polygon_cold') {
      // empty outline
      emptySource = emptyLayerSource(style, 'draw_polygon_outline_cold')
      style.sources['draw_polygon_outline_cold'] = emptySource
    }
    return style
  }

  const bustCache = style => {
    //  Add cache buster to the tile url to prevent browser caching of old tiles

    style.sources[layerSourceId].tiles = style.sources[layerSourceId].tiles.map(
      tileUrl => {
        if (tileUrl.indexOf('?') === -1) {
          return tileUrl + `?dt=${Date.now()}`
        } else {
          let url = tileUrl.replace(/&dt.*$/, '')
          return url + `&dt=${Date.now()}`
        }
      }
    )
    return style
    /*
        Note: the code below is a cleaner way to bust the tile cache but results in 
        a weird side effect that removes the layer styling???  
        For now the above code will be used
        TODO:  figure out the cause of the side effect
        */
    /*style.sources[layerSourceId].tiles = style.sources[layerSourceId].tiles.map(
          tileUrl => {
            const baseUrl = tileUrl.split('?').shift()
            return `${baseUrl}?dt=${Date.now()}`
          }
        )*/

    return style
  }

  const doSave = () => {
    let style = mapStyle.toJS()
    const layer = style.layers.filter(layer => layer.id === id)
    const layerId = layer[0].id

    const coldSource = mapUtils.getSource(style, coldLayer)
    const urlObjects = coldSource.data.features.map(feature => {
      // delete props added during snapping
      if (feature.properties.isSnapPoint) {
        delete feature.properties.isSnapPoint
      }
      if (feature.properties.dist) {
        delete feature.properties.dist
      }
      if (feature.properties.index) {
        delete feature.properties.index
      }
      if (feature.properties.location) {
        delete feature.properties.location
      }
      const featureCollection = {
        type: 'FeatureCollection',
        features: [feature],
      }
      const method = 'POST'
      let url = null
      let body = {
        mapID: user.mapID,
        layerID: layerId,
        json: featureCollection,
      }
      if (feature.properties.mamid) {
        url = apis['apiDatabase'].uri + 'layer/geojson/update'
        body.featureID = feature.properties.mamid
        body.json = feature
      } else {
        url = apis['apiDatabase'].uri + 'layer/geojson/add'
      }
      return {
        url,
        body,
        method,
      }
    })
    setLayerSourceId(layer[0].source)
    setFetchObjects(urlObjects)
  }
  const fetchFinished = () => {
    let style = mapStyle.toJS()
    style = emptySources(style, coldLayer)
    style = bustCache(style)
    setFetchObjects(null)
    dispatch(updateMapStyle(fromJS(style)))
    finishSave()
  }

  useEffect(() => {
    doSave()
  }, [])

  return (
    fetchObjects && (
      <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
    )
  )
}

export default SaveFeatures
