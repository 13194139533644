import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateMapStyle,
  setSelectFeatures,
  setMapCursor,
} from '../../../actions/index'
import { verifyResult, resultReturn } from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import TargetLayerSelect from './TargetLayerSelect'
import ExpandSelect from './ExpandSelected'
import ClearSelect from './ClearSelected'
import ExportSelectedFeatures from './ExportSelectedFeatures'
import SaveSelectedFeatures from './SaveSelectedFeatures'
import SettingsSelect from './SettingsSelect'
import { apis } from '../../../config/apiConfig'
import { fromJS } from '../../../../node_modules/immutable'
import scss from './FeatureSelect.scss'

import { SELECTED_FEATURES } from '../../../data/Layers/Auxiliary/SelectedFeatures'

const SELECTED_FEATURES_LAYER = SELECTED_FEATURES.layersArray[0].layer

const FeatureSelect = ({
  selectedFeatures,
  selectFeaturesOnScreen,
  clearSelectedFeatures,
  disableMap,
  enableMap,
}) => {
  const dispatch = useDispatch()
  const selectFeatures = useSelector(state => state.selectFeatures)
  const mapStyle = useSelector(state => state.mapStyle)
  const mapRef = useSelector(state => state.mapRef)

  const [targetLayer, setTargetLayer] = useState({
    value: 'none',
    layerIdList: [],
    label: 'Select Target Layer',
    type: 'none',
  })
  const [fetchObjects, setFetchObjects] = useState(null)
  const [selectedFeatureCount, setSelectedFeatureCount] = useState(0)

  const updateSelection = selectedTargetFeatures => {
    const removeFeatures = []
    let style = mapStyle.toJS()
    // identify features that have been selected but are already in the selected_features layer source
    // these features will be removed from the selected_features layer source
    selectedTargetFeatures.forEach(feature => {
      style.sources.selected_features.data.features.forEach(feat => {
        if (feat.properties.mamid === feature.properties.mamid) {
          removeFeatures.push(feat.properties.mamid)
        }
      })
    })
    style.sources.selected_features.data.features =
      style.sources.selected_features.data.features.filter(
        feature => !removeFeatures.includes(feature.properties.mamid)
      )

    const geoJsonFeatures = selectedTargetFeatures.filter(
      feature => !removeFeatures.includes(feature.properties.mamid)
    )

    setSelectedFeatureCount(
      style.sources.selected_features.data.features.length
    )

    if (style.sources.selected_features.data.features.length === 0) {
      if (selectedFeatureCount > 0) {
        setSelectedFeatureCount(0)
        clearSelectedFeatures()
      }
    }

    dispatch(updateMapStyle(fromJS(style)))
    getFeatureGeoJson(geoJsonFeatures)
  }

  const getFeatureGeoJson = selectedFeatures => {
    if (!selectedFeatures.length) return
    console.log('selectedFeatures :>>', selectedFeatures.length)

    //Build bounds
    const map = mapRef.getMap()
    const bounds = map.getBounds()

    const layerId = selectedFeatures[0].layer.id
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/geojson/get'

    let fetchList = []

    let splitCount = 250

    for (let index = 0; index < selectedFeatures.length; index += splitCount) {
      let partialFeatureList = selectedFeatures.slice(index, index + splitCount)
      const body = {
        layerID: layerId,
        featureID: partialFeatureList
          .map(feature => feature.properties.mamid)
          .join(','),
        attributes: false,
      }

      body['bounds'] = [
        bounds._sw.lng,
        bounds._sw.lat,
        bounds._ne.lng,
        bounds._ne.lat,
      ]

      fetchList.push({ url, body, method })
    }

    dispatch(setMapCursor('wait'))
    setFetchObjects(fetchList)
  }

  const fetchFinished = results => {
    let style = mapStyle.toJS()
    setFetchObjects(null)
    dispatch(setMapCursor('crosshair'))
    const selectedFeatureCollection = buildSelectedFeatureCollection(results)
    style.layers
      .filter(layer => layer.id === 'selected_features')
      .forEach(layer => {
        if (!targetLayer.value) return
        if (targetLayer.type === 'circle') {
          layer.type = 'circle'
          layer.paint = {
            'circle-color': SELECTED_FEATURES_LAYER.paint['line-color'],
            'circle-opacity': 0,
            'circle-stroke-color': SELECTED_FEATURES_LAYER.paint['line-color'],
            'circle-stroke-width': 3,
            'circle-radius': targetLayer.paint['circle-radius'] + 1,
          }
        } else {
          layer.type = 'line'
          layer.paint = SELECTED_FEATURES_LAYER.paint
        }
      })
    style.sources.selected_features.data.features = [
      ...style.sources.selected_features.data.features,
      ...selectedFeatureCollection,
    ]
    dispatch(updateMapStyle(fromJS(style)))
  }

  const buildSelectedFeatureCollection = fetchResults => {
    let newFeatures = []
    fetchResults.map(result => {
      if (verifyResult(result)) {
        const data = resultReturn(result)
        if (data.features) data.features.map(layer => newFeatures.push(layer))
      }
    })
    return newFeatures
  }

  useEffect(() => {
    let style = mapStyle.toJS()
    if (!style.sources.selected_features) return
    if (!targetLayer) return
    const selectedTargetFeatures = selectedFeatures.filter(feature => {
      const splitId = feature.layer.id.split('_')
      const dbId = splitId[splitId.length - 1]
      return dbId === targetLayer.dbId
    })

    updateSelection(selectedTargetFeatures)
  }, [selectedFeatures])

  useEffect(() => {
    dispatch(setSelectFeatures(true))
    dispatch(setMapCursor('crosshair'))
    return () => {
      dispatch(setSelectFeatures(false))
      dispatch(setMapCursor('grab'))
    }
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <div
        className={scss.featureSelectWrapper}
        onMouseEnter={disableMap}
        onMouseLeave={enableMap}
      >
        {selectFeatures && (
          <>
            <TargetLayerSelect
              style={{
                right: selectedFeatures.length > 0 ? `245px` : `215px;`,
              }}
              targetLayer={targetLayer}
              setTargetLayer={setTargetLayer}
              selectedFeatures={selectedFeatures}
              clearSelectedFeatures={clearSelectedFeatures}
            />
            {targetLayer.value !== 'none' ? (
              <>
                <div className={scss.featureSelectControls}>
                  <ExpandSelect
                    selectFeaturesOnScreen={selectFeaturesOnScreen}
                    targetLayer={targetLayer}
                    disabled={selectedFeatures.length > 0}
                  />
                  <ClearSelect
                    clearSelectedFeatures={clearSelectedFeatures}
                    disabled={selectedFeatures.length <= 0}
                  />
                </div>
                <div className={scss.featureSelectControls}>
                  <ExportSelectedFeatures
                    targetLayer={targetLayer}
                    disabled={selectedFeatures.length <= 0}
                  />
                  <SaveSelectedFeatures
                    targetLayer={targetLayer}
                    disabled={selectedFeatures.length <= 0}
                  />
                </div>
                <div className={scss.featureSelectControls}>
                  <SettingsSelect></SettingsSelect>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}

export default FeatureSelect
