import { SET_VIEWPORT, GET_VIEW_PORT } from '../actions/types'

export default function ViewPortReducer(state = null, action) {
  switch (action.type) {
    case SET_VIEWPORT: {
      return action.payload.viewport
    }

    case GET_VIEW_PORT: {
      return state
    }

    default:
      return state
  }
}
