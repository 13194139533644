import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../../../utilities/geospatial'
import * as mapUtils from '../../../../../utilities/mapStyle'
import _ from 'lodash'
import {
  updateMapStyle,
  setDoubleClickZoom,
  setDragPan,
} from '../../../../../actions'

export const ZOOM_BUFFER_RATIO = {
  9: 500,
  10: 500,
  11: 500,
  12: 500,
  13: 500,
  14: 300,
  15: 150,
  16: 75,
  17: 25,
  18: 10,
  19: 10,
  20: 5,
  21: 2.5,
  22: 1,
  23: 0.5,
  24: 0.25,
}
export default function FindFeatureVertex({ type, event, finished }) {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  let style = mapStyle.toJS()
  const drawVeticiesLayer = mapUtils.getLayerObject(style, 'draw_verticies_hot')

  dispatch(setDoubleClickZoom(false))
  let hotLayers = []
  if (type === 'line') {
    hotLayers = [
      { hot: 'draw_line_hot', cold: 'draw_line_cold' },
      { hot: 'draw_midpoints_hot' },
      { hot: 'draw_verticies_hot' },
    ]
  }
  if (type === 'fill') {
    hotLayers = [
      { hot: 'draw_polygon_hot', cold: 'draw_polygon_cold' },
      { hot: 'draw_polygon_outline_hot', cold: 'draw_polygon_outline_cold' },
      { hot: 'draw_midpoints_hot' },
      { hot: 'draw_verticies_hot' },
    ]
  }
  if (type === 'circle') {
    hotLayers = [{ hot: 'draw_verticies_hot', cold: 'draw_verticies_cold' }]
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const updateDrawClickedVerticie = (style, verticies, clickedCoords) => {
    const hotLayer = mapUtils.getLayerObject(style, 'draw_clicked_verticie')
    const hotSourceId = hotLayer.source
    let hotSource = mapUtils.getSource(style, hotSourceId)
    hotSource = style.sources[hotSourceId] = mapUtils.addDataToDrawSource(
      hotSource,
      verticies,
      clickedCoords,
      hotSourceId
    )
    return hotSource
  }

  const findVertexUnderClick = () => {
    const zoom = viewport.zoom.toFixed(0)
    const bufferDistance = ZOOM_BUFFER_RATIO[zoom] ? ZOOM_BUFFER_RATIO[zoom] : 1
    const clickPoint = geoUtils.getPointAtClick(event, viewport)
    const bufferedClickPoint = geoUtils.getBuffer(
      clickPoint,
      bufferDistance,
      'feet'
    )
    const drawVerticiesSource = mapUtils.getSource(
      style,
      drawVeticiesLayer.source
    )
    let verticies = drawVerticiesSource.data
    const midpoints = mapUtils.getSource(style, 'draw_midpoints_hot')
    const clickedVerticies = geoUtils.getPointsWithinPolygon(
      verticies,
      bufferedClickPoint
    )
    const clickedMidpoints = geoUtils.getPointsWithinPolygon(
      midpoints.data,
      bufferedClickPoint
    )
    let clickedIndex = null
    let clickedCoords
    let dragPan = true
    if (clickedVerticies && clickedVerticies.features.length) {
      // a verticie has been clicked
      // get verticie position
      clickedCoords = clickedVerticies.features[0].geometry.coordinates
      verticies.features.forEach((feature, index) => {
        if (
          JSON.stringify(feature.geometry.coordinates) ===
          JSON.stringify(clickedCoords)
        ) {
          clickedIndex = index
        }
      })
      verticies = null
      dragPan = false
    } else if (clickedMidpoints && clickedMidpoints.features.length) {
      const midPoint = clickedMidpoints.features[0]
      clickedCoords = midPoint.geometry.coordinates
      const beforeMidPointCoords = midPoint.properties.pt1
      let updated = false
      let updateIndex = null
      verticies.features.forEach((feature, index) => {
        let coords = feature.geometry.coordinates.join(',')
        if (coords === beforeMidPointCoords) {
          if (!updated) updateIndex = index + 1
          updated = true
        }
      })

      verticies.features.splice(updateIndex, 0, midPoint)
      clickedIndex = updateIndex
      const newCoords = verticies.features.map(
        feature => feature.geometry.coordinates
      )
      let newFeature = null
      if (type === 'fill') {
        newFeature = geoUtils.getPolygon([newCoords])
      }
      if (type === 'line') {
        newFeature = geoUtils.getLineString(newCoords)
      }
      const layers = mapUtils.getLayerObjects(hotLayers, style)
      layers.forEach(layer => {
        let sourceId = layer.hot.source
        let source = mapUtils.getSource(style, sourceId)
        if (sourceId === 'draw_midpoints_hot') {
          style.sources[sourceId] = emptyLayerSource(style, sourceId)
        } else if (sourceId === 'draw_verticies_hot') {
          let verticiesSource = emptyLayerSource(style, sourceId)
          verticiesSource.data.features = verticies.features
          style.sources['draw_verticies_hot'] = verticiesSource
        } else {
          source.data.features[0] = newFeature
          style.sources[sourceId] = source
        }
      })
      dragPan = false
    }
    style.sources['draw_clicked_verticie'] = updateDrawClickedVerticie(
      style,
      verticies,
      clickedCoords
    )

    dispatch(updateMapStyle(fromJS(style)))
    dispatch(setDragPan(dragPan))
    finished({ clickedCoords, clickedIndex })
  }

  useEffect(() => {
    findVertexUnderClick()
  }, [])

  return null
}
