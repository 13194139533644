import React, { useEffect, useState } from 'react'

import LogoIcon from '../../assets/logo/drk-trim-main.svg'

import styles from './ErrorBoundary.scss'

function ErrorFallback({ error, refreshPage }) {
  const [seconds, setSeconds] = useState(5)

  useEffect(() => {
    if (refreshPage) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (seconds === 0) {
      clearInterval()
      window.location.reload()
    }
  }, [seconds])

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.container}>
        <div className={styles.box}>
          <img
            // className={scss['login-logo']}
            style={{ marginBottom: '54px' }}
            src={LogoIcon}
            alt='My Asset Map Logo'
          />
          <h1>Something went wrong!</h1>
          <p>
            <span>It looks like something went wrong.</span>
          </p>
          <p>
            We have saved your work, and will attempt to refresh the page to
            resolve the issue.
          </p>
          <p>
            If you continue to see this message, contact MyAssetMap support at
            support@myassetmap.com.
          </p>
          {refreshPage && (
            <p>
              We will automatically{' '}
              <a href='javascript:window.location.reload(1)'>
                refresh this page
              </a>{' '}
              in <span id='refresh-timer'>{seconds}</span> second
              {seconds > 1 ? 's' : ''}.
            </p>
          )}
          <hr />
          <p>
            Error: <em>{error ? error.message : 'No message available'}</em>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
