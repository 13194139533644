import React, { useState } from 'react'
import scss from './MapPopup.scss'
import { useDispatch } from 'react-redux'

import { apiFetch } from '../../utilities/api'
import { apis } from '../../config/apiConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Alert } from '../../actions'

import DocumentBrowserV2 from '../DocumentBrowserV2/DocumentBrowserV2'

import { getDocument } from './Utilities'

import Tabs from '../../components/Tabs/Tabs'

const DocumentTab = ({
  config,
  layerProperties,
  featureProperties,
  layerId,
  popupInfo,
  index,
  dataConfigLayer,
  handleDocumentChange,
  getProperties,
  addDocumentRowVisible,
  handleAddDocumentRowVisible,
  reRender,
}) => {
  let tabUi = []
  const featureId = popupInfo.features[index].properties.mamid
  const [documentFeature, setDocumentFeature] = useState(null)
  const [typeDocumentKey, setTypeDocumentKey] = useState(null)

  // Add Document Row States
  // const [addDocumentRowVisible, setAddDocumentRowVisible] = useState(false)
  const [addDocumentRow, setAddDocumentRow] = useState(null)
  const [addDocumentRowName, setAddDocumentRowName] = useState('')
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  const handleSuccess = msg => dispatch(Alert({ success: msg }))
  const handleError = error => dispatch(Alert({ error }))

  let canEdit = false
  if (dataConfigLayer && dataConfigLayer.toc)
    canEdit = dataConfigLayer.toc.canEdit

  const setDocumentInfo = key => {
    setDocumentFeature(featureId)
    setTypeDocumentKey(key)
  }

  /**
   * @brief Add the document row by passing the name
   **/
  const handleAddDocumentRow = () => {
    setSaving(true)
    let documentName = addDocumentRowName.trim()
    if (documentName === '') return

    setAddDocumentRow(false)
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/properties/add'
    // const value = targetProperty.value
    const bodyParams = {
      default: '',
      layerID: layerId,
      name: addDocumentRowName,
      type: 'document',
      value: [],
    }
    apiFetch(url, method, bodyParams, result => {
      getProperties()
      setSaving(false)
      setAddDocumentRowName('')
      handleAddDocumentRowVisible()
      handleSuccess(
        'Document Attribute has been added. Please go to the tab settings to place it in a tab'
      )
      reRender()
    })
  }

  if (config && layerProperties[layerId]) {
    for (const tab in config.tabs) {
      const rows = []
      // create a new object with all lowercase keys from FeatureProperties
      // we will convert all layer property keys to lowercase to ensure case insensitivity
      let keys = Object.keys(featureProperties)
      let n = keys.length
      let lowerCaseObj = {}
      while (n--) {
        let key = keys[n]
        lowerCaseObj[key.toLowerCase()] = featureProperties[key]
      }
      const label = config.tabs[tab].title
      const tabKey = config.tabs[tab].id
      if (config.tabs[tab].id !== 'propertiesHolder') {
        config.tabs[tab].itemsIds.forEach(id => {
          let property = layerProperties[layerId].filter(
            layerProperty => layerProperty.id === parseInt(id)
          )
          //Note:  if there is no property[0] than the property has been deleted
          // but the popupConfig has not been saved to reflect this change
          if (property[0]) {
            const document = getDocument({
              lowerCaseObj,
              property: property[0],
              propertyConfigName: null,
              featureProperties,
              setDocumentInfo,
              handleDocumentChange,
              canEdit,
            })

            if (document) rows.push(document)
          }
        })

        tabUi.push(
          <div key={tabKey} label={label}>
            <div className={scss['feature-document-grid']}>
              {rows}
              {addDocumentRowVisible ? (
                <div className={scss['feature-add-document-grid-row']}>
                  <input
                    onChange={e => setAddDocumentRowName(e.target.value)}
                    // onKeyUp={handleAddDocumentRow}
                    value={addDocumentRowName}
                    className={scss['feature-document-grid-input']}
                    autoFocus
                  />
                  <button
                    className={scss['feature-document-grid-button']}
                    onClick={handleAddDocumentRowVisible}
                  >
                    <FontAwesomeIcon icon='times' />
                  </button>
                  <button
                    className={scss['feature-document-grid-button']}
                    onClick={handleAddDocumentRow}
                  >
                    <FontAwesomeIcon icon='check' />
                  </button>
                </div>
              ) : null}
              {documentFeature && (
                <DocumentBrowserV2
                  featureProperties={featureProperties}
                  layerId={layerId}
                  mamId={featureId}
                  documentFeature={documentFeature}
                  layerProperties={layerProperties}
                  onClose={() => setDocumentFeature(null)}
                  isSelectDocument={true}
                  typeDocumentKey={typeDocumentKey}
                  handleDocumentChange={handleDocumentChange}
                  popupKey={layerId}
                  canEdit={canEdit}
                  linkMode
                />
              )}
            </div>
          </div>
        )
      }
    }
  }

  const checkForAttributes = () => {
    if (!layerProperties[layerId]) return false

    let attributeList = layerProperties[layerId].filter(
      attr => attr && (attr.type === 'document (Feature)')
    )
    return attributeList.length > 0
  }

  return (
    <>
      {config && (checkForAttributes() || addDocumentRowVisible) ? (
        <div className={scss['feature-attribute-tab']}>
          <Tabs key={layerId} column={false} customClass='primaryTabs' scroll>
            {tabUi}
          </Tabs>
        </div>
      ) : (
        <div className={scss['feature-empty-grid']}>
          <div className={scss['feature-empty-square']}>
            <FontAwesomeIcon icon='question' />
          </div>
          <span className={scss['feature-empty-title']}>
            No documents found
          </span>
          <span className={scss['feature-empty-description']}>
            Use the button below to add a document type.
          </span>
          {/* <a>Help?</a> */}
        </div>
      )}
    </>
  )
}

export default DocumentTab
