export const DELETE = {
  sourcesArray: [
    {
      id: 'delete_line',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'delete_circle',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      beforeLayer: null,
      layer: {
        id: 'delete_line',
        type: 'line',
        source: 'delete_line',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#FF0000',
          'line-opacity': 1,
          'line-width': 5,
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'delete_circle',
        type: 'circle',
        source: 'delete_circle',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': '#FF0000',
          'circle-opacity': .1,
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#FF0000',
        },
        metadata: {},
      },
    },
  ],
}
