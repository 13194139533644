import React from 'react'
import ReactTooltip from 'react-tooltip'
import appScss from '../../App/App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ClearSelect = ({ clearSelectedFeatures, disabled = false }) => {
  return (
    <>
      <button
        onClick={disabled ? null : clearSelectedFeatures}
        className={disabled ? appScss['disabled-button'] : null}
        data-tip='Clear Selected'
        data-for='clear-selected'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        <FontAwesomeIcon icon={['fal', 'sync']} size='1x' />
      </button>
      <div className={appScss.reactToolTip}>
        <ReactTooltip
          id={'clear-selected'}
          getContent={dataTip => `${dataTip}`}
        />
      </div>
    </>
  )
}

export default ClearSelect
