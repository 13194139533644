import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updateMapStyle,
  setDoubleClickZoom,
  setMapCursor,
} from '../../../actions/index'
import {
  clone,
  getMouseCoords,
  getMapCenter,
  getLength,
  getLineMidPoint,
} from '../../../utilities/geospatial'
import { LINE_FEATURE, POINT_FEATURE } from '../../../data/Constants/Constants'
import { fromJS } from 'immutable'
import {
  MEASURE_ACTIVE_POINT,
  MEASURE_LINE_COLD,
  MEASURE_LABEL_HOT,
  MEASURE_LABEL_COLD,
  MEASURE_LINE_HOT,
} from './MeasureConstants'

const UNITS_OF_PERCISION = 2
const UNITS_OF_MEASURE = 'imperial'

// NOTE:  We are using React Class component due to
// complexity and issues with hooks and window events
// this is not ideal but for now delivers the best results
class MeasureLine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mouseDownCenter: null,
    }
  }

  handleMouseDown = e => {
    if (this.props.overPanel) return
    const mouseDownCenter = getMapCenter(this.props.viewport)
    this.setState({ mouseDownCenter })
  }

  handleMouseUp = e => {
    if (this.props.overPanel) return
    if (this.state.mouseDownCenter !== getMapCenter(this.props.viewport)) return
    this.addPointToHotMeasureSource(e)
  }

  handleMouseMove = e => {
    if (this.props.overPanel) {
      const style = this.props.mapStyle.toJS()
      const activePoint = style.sources[MEASURE_ACTIVE_POINT]
      const hotMeasureLine = style.sources[MEASURE_LINE_HOT]
      if (
        activePoint.data.features.length ||
        hotMeasureLine.data.features.length
      ) {
        this.clearHotLayers(style)
        this.props.updateMapStyle(fromJS(style))
      }
    } else {
      this.updateHotMeasureLayers(e)
    }
  }

  addPointToHotMeasureSource = e => {
    const style = this.props.mapStyle.toJS()
    const coords = getMouseCoords(
      e.layerX || e.offsetX,
      e.layerY || e.offsetY,
      this.props.viewport
    )
    const hotMeasureData = style.sources[MEASURE_LINE_HOT]
    const hotMeasureLabel = style.sources[MEASURE_LABEL_HOT]
    const coldMeasureData = style.sources[MEASURE_LINE_COLD]
    const coldMeasureLabel = style.sources[MEASURE_LABEL_COLD]
    if (hotMeasureData.data.features[0].geometry.coordinates.length === 2) {
      hotMeasureData.data.features[0].geometry.coordinates[1] = coords
      coldMeasureData.data.features.push(hotMeasureData.data.features[0])
      coldMeasureLabel.data.features.push(hotMeasureLabel.data.features[0])
      hotMeasureData.data.features = []
    } else {
      hotMeasureData.data.features[0].geometry.coordinates.push(coords)
    }
    this.props.updateMapStyle(fromJS(style))
  }

  updateHotMeasureLayers = e => {
    const style = this.props.mapStyle.toJS()
    const coords = getMouseCoords(
      e.layerX || e.offsetX,
      e.layerY || e.offsetY,
      this.props.viewport
    )

    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    const hotMeasureData = style.sources[MEASURE_LINE_HOT]
    const hotMeasureLabel = style.sources[MEASURE_LABEL_HOT]
    // check for features in coldData, if no features add an empty one
    if (!activePoint.data.features.length) {
      activePoint.data.features.push(clone(POINT_FEATURE))
    }
    // check for features in hotData, if no features add an empty one
    if (!hotMeasureData.data.features.length) {
      hotMeasureData.data.features.push(clone(LINE_FEATURE))
    }
    activePoint.data.features[0].geometry.coordinates = coords

    if (hotMeasureData.data.features[0].geometry.coordinates.length === 1) {
      hotMeasureData.data.features[0].geometry.coordinates.push(coords)
    } else if (
      hotMeasureData.data.features[0].geometry.coordinates.length === 2
    ) {
      hotMeasureData.data.features[0].geometry.coordinates[1] = coords
      hotMeasureLabel.data.features[0] = this.getLengthFeature(
        hotMeasureData.data.features[0]
      )
    }

    this.props.updateMapStyle(fromJS(style))
  }

  getLengthFeature = feature => {
    const len = getLength(
      feature,
      UNITS_OF_MEASURE === 'imperial' ? 'miles' : 'kilometers'
    )
    const mid = getLineMidPoint(feature)
    mid.properties['measurement'] = this.formatLength(len)
    return mid
  }

  formatLength = len => {
    let formatted = len

    if (UNITS_OF_MEASURE === 'imperial') {
      if (formatted < 1) {
        // units is in miles, convert to feat
        formatted = formatted * 5280
        formatted = formatted.toFixed(UNITS_OF_PERCISION)
        formatted = formatted + ' ft'
      } else {
        formatted = formatted.toFixed(UNITS_OF_PERCISION)
        formatted = formatted + ' mi'
      }
    }

    return formatted
  }

  clearHotLayers = style => {
    const activePoint = style.sources[MEASURE_ACTIVE_POINT]
    const hotMeasureLine = style.sources[MEASURE_LINE_HOT]
    const hotMeasureLabel = style.sources[MEASURE_LABEL_HOT]

    if (
      activePoint.data.features.length &&
      hotMeasureLine.data.features.length
    ) {
      activePoint.data.features = []
      hotMeasureLine.data.features = []
      hotMeasureLabel.data.features = []
    }
  }

  enableEventListeners = () => {
    const main = document.getElementById('main')
    main.addEventListener('mousedown', this.handleMouseDown, true)
    main.addEventListener('mouseup', this.handleMouseUp, true)
    main.addEventListener('mousemove', this.handleMouseMove, true)
  }

  disableEventListeners = () => {
    const main = document.getElementById('main')
    main.removeEventListener('mousedown', this.handleMouseDown, true)
    main.removeEventListener('mouseup', this.handleMouseUp, true)
    main.removeEventListener('mousemove', this.handleMouseMove, true)
  }

  componentDidMount = () => {
    this.enableEventListeners()
    this.props.setMapCursor('crosshair')
  }

  componentWillUnmount = () => {
    let style = this.props.mapStyle.toJS()
    this.clearHotLayers(style)
    this.disableEventListeners()
    this.props.updateMapStyle(fromJS(style))
    this.props.setMapCursor('grab')
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
      setDoubleClickZoom: setDoubleClickZoom,
      setMapCursor: setMapCursor,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
    viewport: state.viewport,
    overPanel: state.setOverPanel,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureLine)
