import {
  SET_DRAW_MODE,
  SET_SNAP_SETTINGS,
  SET_DRAW_MEASURE_SETTINGS,
} from './types'

export function setAppDrawMode(mode) {
  return {
    type: SET_DRAW_MODE,
    payload: mode,
  }
}

export function setSnapSettings(settings) {
  return {
    type: SET_SNAP_SETTINGS,
    payload: settings,
  }
}

export function setDrawMeasureSettings(settings) {
  return {
    type: SET_DRAW_MEASURE_SETTINGS,
    payload: settings,
  }
}
