import React, { useState, useEffect } from 'react'
import scss from './Print.scss'
import appScss from '../../App/App.scss'
import dropdownScss from '../../CSSModules/Dropdown.scss'

import SaveLayout from './Modals/SaveLayout/SaveLayout'
import ConfirmDelete from './Modals/ConfirmDelete/ConfirmDelete'
import Select from 'react-select'
import { selectPrintStyle } from '../../../utilities/componentConstants/selectComponentStyle'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DEFAULT_SELECT_OPTION = { label: 'Default Layout', value: 'default' }

const PrintLayouts = ({
  toggleFileDropdown,
  showFileDropdown,
  setInitialMap,
  setPrintState,
  dataToSave,
  toggleMode,
}) => {
  const [fetching, setFetching] = useState(true)
  const [selectedLayout, setSelectedLayout] = useState(DEFAULT_SELECT_OPTION)
  const [newLayoutId, setNewLayoutId] = useState(null)
  const [layouts, setLayouts] = useState([])
  const [fetchObjects, setFetchObjects] = useState(null)
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [fetchType, setFetchType] = useState('layouts')
  const getLayouts = () => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'user/preferences/get'
    const body = {
      key: 'tool.print.layout',
    }
    return { url, method, body }
  }

  const deleteLayout = () => {
    if (selectedLayout.value === 'default') {
      setOpenDeleteModal(false)
    } else {
      setOpenDeleteModal(true)
    }
  }

  const saveLayout = () => {
    if (selectedLayout.value === 'default') {
      // save new layout
      setOpenSaveModal(true)
    } else {
      // update existing
      updateExistingLayout(selectedLayout.value)
    }
  }

  /**
   * @description Save to JSON all elements settings and styles
   * need TODO: Api
   */
  const saveNewLayout = layoutName => {
    const newLayout = {
      mode: dataToSave.mode,
      orientPortrait: dataToSave.orientPortrait,
      elements: dataToSave.elements,
      dpi: dataToSave.dpi,
      editMode: dataToSave.editMode,
      activeElement: dataToSave.activeElement,
      editToolPosition: dataToSave.editToolPosition,
      width: dataToSave.width,
      height: dataToSave.height,
    }
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'user/preferences/add'
    const body = {
      key: 'tool.print.layout',
      name: layoutName,
      value: newLayout,
    }
    setFetching(true)
    setFetchType('save')
    setFetchObjects([{ url, method, body }])
  }

  const updateExistingLayout = prefId => {
    //TODO: change dataToSave to be an object with only layout elements to allow for destructing
    const updatedLayout = {
      mode: dataToSave.mode,
      orientPortrait: dataToSave.orientPortrait,
      elements: dataToSave.elements,
      dpi: dataToSave.dpi,
      editMode: dataToSave.editMode,
      activeElement: dataToSave.activeElement,
      editToolPosition: dataToSave.editToolPosition,
      width: dataToSave.width,
      height: dataToSave.height,
    }

    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'user/preferences/update'
    const body = {
      key: 'tool.print.layout',
      prefID: prefId,
      name: selectedLayout.label,
      value: updatedLayout,
    }
    setFetching(true)
    setFetchType('update')
    setFetchObjects([{ url, method, body }])
  }

  const doDeleteLayout = () => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'user/preferences/delete'
    const body = {
      key: 'tool.print.layout',
      prefID: selectedLayout.value,
    }
    setFetching(true)
    setFetchType('delete')
    setFetchObjects([{ url, method, body }])
  }

  const handleSelectChange = selectedLayout => {
    setSelectedLayout(selectedLayout)
    if (selectedLayout.value === 'default') {
      setInitialMap()
    } else {
      setPrintState(selectedLayout.elements)
    }
  }

  const fetchFinished = result => {
    setFetchObjects(null)
    setOpenSaveModal(false)
    setOpenDeleteModal(false)
    if (fetchType === 'save') {
      const fetchParams = getLayouts()
      setNewLayoutId(result[0].data)
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }
    if (fetchType === 'delete') {
      const fetchParams = getLayouts()
      setInitialMap()
      setNewLayoutId('default')
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }

    if (fetchType === 'update') {
      const fetchParams = getLayouts()
      setNewLayoutId(null)
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }

    if (fetchType === 'layouts') {
      const returnedLayouts = result[0]
      if (returnedLayouts.success) {
        const layouts = returnedLayouts.data.map(layout => {
          return {
            label: layout.name,
            value: layout.id,
            elements: layout.value,
          }
        })
        layouts.unshift(DEFAULT_SELECT_OPTION)
        setLayouts(layouts)
        setFetching(false)
        if (newLayoutId) {
          if (newLayoutId === 'default') {
            setSelectedLayout(DEFAULT_SELECT_OPTION)
          } else {
            const selected = returnedLayouts.data
              .filter(layout => layout.id == newLayoutId)
              .map(layout => {
                return {
                  label: layout.name,
                  value: layout.id,
                  elements: layout.value,
                }
              })
            setSelectedLayout(selected[0])
          }
          setNewLayoutId(null)
        }
      }
    }
  }

  useEffect(() => {
    const fetchParams = getLayouts()
    setFetchObjects([fetchParams])
    return function cleanup() {}
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {openSaveModal && (
        <SaveLayout
          modalOpen={openSaveModal}
          closeModal={() => setOpenSaveModal(false)}
          message={<p>Enter a name for the layout you wish to save.</p>}
          doSaveLayout={saveNewLayout}
        />
      )}
      {openDeleteModal && (
        <ConfirmDelete
          modalOpen={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          targetLabel={selectedLayout.label}
          message={
            <p>
              You have selected to delete the layout:{' '}
              <b>{selectedLayout.label}</b>.
            </p>
          }
          title='Delete Layout'
          doDeleteLayout={doDeleteLayout}
        />
      )}

      {layouts.length && (
        <div className={scss.layoutSelect}>
          {fetching ? (
            <FontAwesomeIcon icon='spinner' spin fixedWidth />
          ) : (
            <Select
              className={scss.printLayoutSelect}
              type='select'
              styles={selectPrintStyle}
              value={selectedLayout}
              onChange={handleSelectChange}
              options={layouts}
            />
          )}
        </div>
      )}

      <div // FILE DROPDOWN
        className={[
          scss.fileBtn,
          scss.printDropdown,
          dropdownScss.dropdown,
        ].join(' ')}
        onClick={toggleFileDropdown}
      >
        File
        <FontAwesomeIcon
          icon='chevron-down'
          size='xs'
          pull='right'
          color='white'
          className={scss.printArrowIcon}
        />
        {showFileDropdown && (
          <div
            className={[
              scss.printDropdownContent,
              dropdownScss.dropdownContent,
            ].join(' ')}
          >
            <div
              className={[scss.printDropdownItem].join(' ')}
              onClick={saveLayout}
            >
              Save Layout
            </div>

            {selectedLayout.value === 'default' ? (
              <div
                className={[scss.printDropdownItem].join(' ')}
                onClick={null}
              >
                Delete Layout
              </div>
            ) : (
              <div
                className={[scss.printDropdownItem].join(' ')}
                onClick={deleteLayout}
              >
                Delete Layout
              </div>
            )}
            <div
              className={[scss.printDropdownItem, scss.viewPdf].join(' ')}
              onClick={toggleMode}
            >
              View PDF
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PrintLayouts
