import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewPort } from '../../../actions/index'
import { LinearInterpolator } from 'react-map-gl'
import useWindowSize from '../../../utilities/Hooks/useWindowSize'
import { clone } from '../../../utilities/geospatial'
import { TOC_WIDTH, SIDE_BAR_WIDTH } from './Constants'

const Dimensions = () => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const footerHeight = useSelector(state => state.footerHeight)
  const tocCollapsed = useSelector(state => state.tocCollapsed)

  const size = useWindowSize()

  const handleResize = () => {
    let vp = clone(viewport)
    let toc = tocCollapsed ? SIDE_BAR_WIDTH : TOC_WIDTH + SIDE_BAR_WIDTH
    vp.width = size.width - toc
    vp.height = size.height - footerHeight
    vp.transitionInterpolator = new LinearInterpolator()
    dispatch(setViewPort(vp))
  }

  useEffect(() => {
    if (viewport && size) {
      handleResize()
    }
  }, [size])

  useEffect(() => {
    if (viewport && size) {
      handleResize()
    }
  }, [tocCollapsed, footerHeight])

  return null
}

export default Dimensions
