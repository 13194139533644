import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import MapGL, { NavigationControl } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import DefaultMapView from './DefaultMapView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { connect } from 'react-redux'
import { signRequest } from '../../utilities/api'
import AsyncFetch from '../../utilities/AsyncFetch'

import * as utils from '../../utilities/util'
import { apis } from '../../config/apiConfig'
import profileScss from './Profile.scss'
import appScss from '../App/App.scss'
import scss from './Maps.scss'
import ReactTooltip from 'react-tooltip'

import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { fromJS } from 'immutable'
import { BASIC_STYLE } from '../../mapStyles/basic/style.js'

import AssignUsersModal from './Popups/assignUsersModal'
import MapEditModal from './Popups/mapEditModal'
import MapAddModal from './Popups/mapAddModal'
import MapDeleteModal from './Popups/mapDeleteModal'

import logoDark from '../../assets/logo/logo-dark.svg'
import { MapboxEvent } from 'mapbox-gl'

export default function Maps(map) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  // const mapStyle = fromJS(BASIC_STYLE);

  const [assignUsersVisible, setAssignUsersVisible] = useState(false)
  const [mapAddVisible, setMapAddVisible] = useState(false)
  const [mapEditVisible, setMapEditVisible] = useState(false)
  const [mapDeleteVisible, setMapDeleteVisible] = useState(false)

  const [mapList, setMapList] = useState([])
  const [mapUserObj, setMapUserObj] = useState({})
  const [loading, setLoading] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [viewportObj, setViewportObj] = useState({})
  const transformRequest = url => {
    return signRequest(url)
  }

  var width = 700
  var height = 440

  const onViewportChange = (viewport, id) => {
    //console.log(viewport,id)
    const newViewportObj = JSON.parse(JSON.stringify(viewportObj))
    viewport.width = width
    viewport.height = height
    newViewportObj[id] = viewport
    setViewportObj(newViewportObj)
    //// this.props.setViewPort(viewport);
  }

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/maps'
    let body = {
      accountID: user.accountID,
    }

    setFetchObjects([{ url, method, body }])
    console.log('fetchObjects', fetchObjects)
    setLoading(true)
  }

  const fetchFinished = async results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setMapList(utils.resultReturn(result))

        getUserURLS(utils.resultReturn(result))
        setLoading(false)
      }

      setFetchObjects(null)
    })
  }

  const updateMapList = () => {
    buildFetchParams()
  }

  useEffect(() => {
    updateMapList()
  }, [user])

  //User Images
  const getUserImages = async userList => {
    return Promise.all(
      userList.map(async user => {
        const imgURL = await utils.getImage(user.profileImg, 'user.png')

        return {
          id: user.id,
          name: user.displayName,
          image: imgURL,
        }
      })
    )
  }

  //USER URL LOGIC
  const getUserURLS = maps => {
    if (!maps || !Array.isArray(maps)) return

    const promiseList = maps.map(map => {
      const { users } = map
      return new Promise((resolve, reject) => {
        getUserImages(users)
          .then(userURLs => {
            // console.log(map,userURLs)
            resolve({ id: map.id, users: userURLs })
          })
          .catch(userURLs => {
            reject(userURLs)
          })
      })
    })

    Promise.all(promiseList)
      .then(mapUsers => {
        let mapUserObj = {}
        if (Array.isArray(mapUsers)) {
          mapUsers.forEach(item => {
            mapUserObj[item.id] = item.users
          })
        }

        console.log('FINAL MAP USERS', mapUserObj)
        setMapUserObj(mapUserObj)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    getUserURLS()
  }, [mapList])

  const getMap = map => {
    let viewport = {
      width: width,
      height: height,
      latitude: map.location != null ? map.location.x : 39,
      longitude: map.location != null ? map.location.y : -98,
      zoom: map.location != null ? map.location.z : 8,
    }
    if (!viewportObj[map.id]) viewportObj[map.id] = viewport

    var lat = '',
      lon = '',
      zoom = ''
    if (typeof map.location === 'object') {
      if (map.location && map.location.x != null) lat = map.location.x
      if (map.location && map.location.y != null) lon = map.location.y
      if (map.location && map.location.z != null) zoom = map.location.z
    }
    return (
      <div className={scss.mapRow}>
        <div className={scss.mapText}>
          <p>
            {map.name}{' '}
            <FontAwesomeIcon
              icon='info-circle'
              size='1x'
              data-tip={'Map ID: ' + map.id}
              data-for='mamid'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
            />
            <div className={appScss.reactToolTip}>
              <ReactTooltip id='mamid' getContent={dataTip => `${dataTip}`} />
            </div>
          </p>
          {/* <a onClick={() => setMapAddVisible(map.id)}>Add</a><br /> */}
          {user.adminAccounts.indexOf(map.accountID) !== -1 && (
            <>
              <a onClick={() => setMapEditVisible(map.id)}>Edit</a>
              <br />
              <a onClick={() => setMapDeleteVisible(map.id)}>Delete</a>
              <br />
              <a onClick={() => setAssignUsersVisible(map.id)}>
                Assign to Users
              </a>
              <br />
            </>
          )}
          <div style={{ marginTop: '12px' }}>
            {mapUserObj &&
              mapUserObj[map.id] &&
              Array.isArray(mapUserObj[map.id]) &&
              utils.buildImageStack(mapUserObj[map.id], {
                imgStackClass: scss.imageStack,
                profileImgClass: scss.mapProfileImg,
                all: false,
              })}
          </div>

          <div className={scss.mapOtherItems}>
            <p>Latitude: {lat}</p>
            <p>Longitude: {lon}</p>
            <p>Zoom: {zoom}</p>
          </div>

          {assignUsersVisible == map.id && (
            <AssignUsersModal
              mapUsers={map.users}
              mapID={map.id}
              visible={setAssignUsersVisible}
            />
          )}
          {mapEditVisible == map.id && (
            // EDIT MAP MODAL
            <MapAddModal
              name={map.name}
              desc={map.description}
              lon={lon}
              lat={lat}
              zoom={zoom}
              mapID={map.id}
              visible={setMapEditVisible}
              mapViewport={viewport}
              mapStyle={BASIC_STYLE}
              mapTransformRequest={transformRequest}
              mapOnViewportChange={viewport =>
                onViewportChange(viewport, map.id)
              }
              mapViewportObj={viewportObj}
            />
          )}
          {mapDeleteVisible == map.id && (
            // EDIT MAP MODAL
            <MapDeleteModal
              mapLabel={map.name}
              mapID={map.id}
              visible={setMapDeleteVisible}
            />
          )}
        </div>

        <div className={scss.mapSection}>
          <div>
            <MapGL
              key={map.id}
              {...viewport}
              mapStyle={BASIC_STYLE}
              transformRequest={transformRequest}
              onViewportChange={viewport => onViewportChange(viewport, map.id)}
              // onLoad={onMapLoad}
              // onNativeClick={this.onMapClickNative}
              dragPan={false}
              // doubleClickZoom={doubleClickZoom}
              scrollZoom={false}
              // ref={(map) => (this.mapRef = map)}
              // transformRequest={this.transformRequest}
              // onTransitionEnd={this.onTransitionEnd}
              // interactiveLayerIds={interactiveLayerIds}
              // attributionControl={false}
              // getCursor={this.getCursor}
              // keyboard={false}
            />
          </div>
        </div>
      </div>
    )
  }

  var mapsArea
  if (!loading && !mapList.length) {
    mapsArea = <p>No maps found for this account</p>
  } else {
    mapsArea = mapList.map(map => getMap(map))
  }

  let viewport = {
    width: width,
    height: height,
    latitude: 37.69567410934385,
    longitude: -93.87665386366527,
    zoom: 2.5594055571155927,
  }

  if (!viewportObj[0]) viewportObj[0] = viewport

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Tabs className={profileScss.userMaps}>
        <h1>Manage Maps</h1>
        <h3>Select or edit default load position for your map.</h3>

        {/* IF THIS TABLIST DOESN'T HAVE ONE TAB, THEN IT PRODUCES AN ERROR */}
        <TabList>
          <Tab></Tab>
        </TabList>

        <TabPanel className={scss.mapContainer}>
          <div className={scss.mapRowOne}>
            <div className={scss.mapHeading}>
              <p>Load Views</p>
            </div>

            <div className={scss.mapSection}>
              <p></p>
            </div>

            <div className={scss.mapLinkTwo}>
              <button
                onClick={() => setMapAddVisible(true)}
                className={scss.mapButton}
              >
                ADD <span>+</span>
              </button>
            </div>
            {mapAddVisible && (
              // ADD MAP MODAL
              <MapAddModal
                visible={setMapAddVisible}
                name={null}
                desc={null}
                lat={37.69567410934385}
                lon={-93.87665386366527}
                zoom={2.5594055571155927}
                mapID={0}
                mapStyle={BASIC_STYLE}
                mapTransformRequest={transformRequest}
                mapOnViewportChange={viewport => onViewportChange(viewport, 0)}
                mapViewportObj={viewportObj}
              />
            )}
          </div>
          {loading && (
            <>
              <Skeleton height={510} count={2} />
            </>
          )}

          {mapsArea}

          {/* <div className={scss.filterBox}>
            <div className={scss.filterSubBox}>
              <button className={scss.filterButton}></button>
              <div className={scss.filterText}>
                My Asset Map
              </div>
              <img src={logoDark} className={scss.filterShare}></img>
            </div>
          </div> */}
        </TabPanel>
      </Tabs>
    </>
  )
}
