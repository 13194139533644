import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'

// Amplify
import Storage from '@aws-amplify/storage'

import * as utils from '../../utilities/util'
import { SetS3Config } from '../../services'

// Actions
import { Alert } from '../../actions'

// SCSS
import scss from './DropZone.scss'
import appScss from '../../containers/App/App.scss'
import ImageEditor from './ImageEditor'

// This function is called when the user taps the edit button, it opens the editor and returns the modified file when done
let uploadProgressAmount = {}
let uploadProgressTotal = {}
let uploadProgressTime = {}

export default function DropZone({
  uuid = true,
  doka = false,
  maxSize,
  maxFiles,
  dirName,
  handleUploadResponse,
  fileType,
  successButtonLabel,
  width,
  height,
  message = null,
}) {
  const [droppedFiles, setDroppedFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  const [imageEditorVisible, setImageEditorVisible] = useState(false)

  const [uploadProgress, setUploadProgress] = useState({})

  const dispatch = useDispatch()

  const handleSuccess = msg => dispatch(Alert({ success: msg }))
  const handleError = error => dispatch(Alert({ error }))

  const getMaxSize = () => {
    return (maxSize / 1024 / 1024).toFixed(0) + ' MB'
  }

  const onDrop = acceptedFiles => {
    // Only proceed if files were accepted
    if (acceptedFiles && acceptedFiles.length) {
      let newFiles
      if (maxFiles && maxFiles === 1) {
        newFiles = [...acceptedFiles]
      } else {
        if (maxFiles && maxFiles < acceptedFiles.length + droppedFiles.length) {
          handleError('Error: Too many files')
          return
        }

        newFiles = [...droppedFiles]

        const currentFileKeys = droppedFiles.map(file => file.name)
        acceptedFiles.forEach(file => {
          if (!currentFileKeys.includes(file.name)) {
            newFiles = utils.addVal(newFiles, file)
          } else {
            handleError(
              'Error: A file with this name is already being uploaded. If this is a different file, please rename the file before uploading.'
            )
          }
        })
      }

      // Save Files to State
      if (doka) {
        setDroppedFiles(
          newFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      } else {
        setDroppedFiles(newFiles)
      }
    }
  }

  const onDropRejected = rejectedFiles => {
    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach(file => {
        const errorList = file.errors.map(err => {
          if (err.code === 'file-too-large')
            return 'File exceeds size limit of ' + getMaxSize()
          return err.message
        })
        handleError(`Error: \n` + errorList.join(';'))
      })
    }
  }

  const doUpload = () => {
    setUploading(true)
    SetS3Config('my-asset-map-data', 'public')
    let nameVariable

    droppedFiles.forEach(uploadedFile => {
      if (!uuid) {
        nameVariable = uploadedFile.name
      } else {
        const fileUuid = utils.generateUUID()
        const ext = uploadedFile.name.split('.').pop()
        nameVariable = `${fileUuid}.${ext}`
      }

      Storage.put(`${dirName}/${nameVariable}`, uploadedFile, {
        contentType: uploadedFile.type,
        progressCallback(progress) {
          setUploadProgress(progress)
          const uploadedFileName = uploadedFile.name
          uploadProgressAmount = {
            ...uploadProgressAmount,
            [uploadedFileName]: progress.loaded,
          }
          uploadProgressTotal = {
            ...uploadProgressTotal,
            [uploadedFileName]: progress.total,
          }
          uploadProgressTime = {
            ...uploadProgressTime,
            [uploadedFileName]: progress.timeStamp,
          }
        },
      })
        .then(result => handleUploadResponse(result, droppedFiles))
        .then(() => setUploading(false))
        .catch(err => {
          handleError('There was an issue uploading your file: ' + err.message)
          console.error('There was an issue uploading your file: ', err)
          setUploading(false)
        })
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    minSize: 0,
    maxSize,
    maxFiles,
    onDrop,
    onDropRejected,
  })

  let style = null
  if (width || height) {
    style = {
      width,
      height,
    }
  }
  const files = droppedFiles.map(file => {
    return (
      <li className={scss['uploaded-file']} key={file.path}>
        <div className={scss['uploaded-file-text']}>
          <FontAwesomeIcon icon='file' />
          {file.path} - {(file.size / 1024 / 1024).toFixed(2) + ' MB'}
          {uploadProgressTotal[file.name]
            ? ' - ' +
              (
                (uploadProgressAmount[file.name] /
                  uploadProgressTotal[file.name]) *
                100
              ).toFixed(1) +
              '%'
            : ''}
        </div>
        {uploadProgressTotal[file.name] !== 0 && (
          <div className={scss['dropzone-upload-bar']}>
            <div className={scss['dropzone-upload-bar-total']} />
            <div
              className={scss['dropzone-upload-bar-progress']}
              style={{
                width: `${Math.round(
                  (uploadProgressAmount[file.name] /
                    uploadProgressTotal[file.name]) *
                    100
                )}%`,
              }}
            />
          </div>
        )}
      </li>
    )
  })

  const dokaFiles = droppedFiles.map((file, index) => {
    return (
      <>
        <div
          key={file.name}
          className={[
            scss.flexRow,
            scss.flexCenter,
            scss.flexJustify,
            scss['uploaded-doka-row'],
          ].join(' ')}
        >
          <div
            className={[
              scss.flexRow,
              scss.flexCenter,
              scss['uploaded-doka-text'],
            ].join(' ')}
          >
            <img
              src={file.preview}
              className={scss['uploaded-doka-image']}
              alt={file.name}
            />
            <span className={scss.ellipsis}>{file.name}</span>
          </div>
          <div
            className={[
              scss.flexRow,
              scss.flexCenter,
              scss['uploaded-doka-button'],
            ].join(' ')}
          >
            <button
              type='button'
              className={[appScss.altBlueButton].join(' ')}
              onClick={() => setImageEditorVisible(true)}
            >
              Edit
            </button>
            {imageEditorVisible && (
              <ImageEditor
                image={file}
                onCancel={() => setImageEditorVisible(false)}
                onFinish={output => {
                  const updatedFiles = [...droppedFiles]

                  // replace original image with new image
                  updatedFiles[index] = output

                  // revoke preview URL for old image
                  if (file.preview) URL.revokeObjectURL(file.preview)

                  // set new preview URL
                  Object.assign(output, {
                    preview: URL.createObjectURL(output),
                  })

                  // update view
                  setDroppedFiles(updatedFiles)
                }}
              />
            )}
            {successButtonLabel ? (
              <button
                type='button'
                className={appScss.blueButton}
                onClick={uploading ? null : doUpload}
              >
                {uploading && uploadProgressTotal
                  ? (
                      (uploadProgressAmount / uploadProgressTotal) *
                      100
                    ).toFixed(1) + '%'
                  : successButtonLabel}
              </button>
            ) : null}
          </div>
        </div>
        {uploadProgressTotal !== 0 && (
          <div className={scss['dropzone-upload-bar']}>
            <div className={scss['dropzone-upload-bar-total']} />
            <div
              className={scss['dropzone-upload-bar-progress']}
              style={{
                width: `${Math.round(
                  (uploadProgressAmount / uploadProgressTotal) * 100
                )}%`,
              }}
            />
          </div>
        )}
      </>
    )
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      droppedFiles.forEach(
        file => file.preview && URL.revokeObjectURL(file.preview)
      )
    },
    [droppedFiles]
  )

  return (
    <>
      <section className={scss.dropzone} style={style}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p style={{ textAlign: 'center' }}>
            Drop your file{maxFiles && maxFiles !== 1 ? 's' : ''} here, or{' '}
            <span style={{ color: '#2d9ed2', cursor: 'pointer' }}>browse</span>
          </p>
          {fileType && message === null && (
            <span
              style={{
                fontSize: '80%',
                fontWeight: '200',
                textAlign: 'center',
              }}
            >
              Accepts {fileType} files
            </span>
          )}
          {message !== null && (
            <span
              style={{
                fontSize: '80%',
                fontWeight: '200',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '100%',
              }}
            >
              <span
                style={{ maxWidth: '50%', width: '50%', textAlign: 'center' }}
              >
                {message}
              </span>
            </span>
          )}
        </div>
      </section>
      <aside>
        {doka ? (
          <div className={[scss.flexColumn, scss['uploaded-doka']].join(' ')}>
            {dokaFiles}
          </div>
        ) : (
          files.length > 0 && (
            <>
              <ul>{files}</ul>
              {successButtonLabel ? (
                <button
                  type='button'
                  className={[
                    appScss.blueButton,
                    scss['dropzone-upload-button'],
                  ].join(' ')}
                  onClick={uploading ? null : doUpload}
                >
                  {uploading ? (
                    <CircularProgress size={10} />
                  ) : (
                    successButtonLabel
                  )}
                </button>
              ) : null}
            </>
          )
        )}
      </aside>
    </>
  )
}
