import React, { useState } from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import { PropTypes } from 'prop-types'
import { apis } from '../../../config/apiConfig'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import * as utils from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'

function SimpleDialog({
  accountID,
  accountName,
  accountNearmap,
  doUpdate,
  visible,
}) {
  const [open, setOpen] = useState(true)

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  // Fields and their States
  const [emailAddressState, setEmailAddress] = useState([])
  const [organizationState, setOrganization] = useState(
    accountName == null ? '' : accountName
  )
  const [nearmapState, setNearmap] = useState(accountNearmap !== '')

  const nearMapAPIKey = 'MDFhZGVjOGItNjIxNS00ZWVkLTgwMTEtZDJkYzAyYjAxMWMw'

  const buildFetchParams = () => {
    const method = 'POST'
    let url
    let body
    if (accountID == null) {
      url = apis.apiDatabase.uri + 'account/add'
      body = {
        accountName: organizationState,
        emailArray: emailAddressState,
        nearmap: nearmapState ? nearMapAPIKey : '',
      }
    } else {
      url = apis.apiDatabase.uri + 'account/update'
      body = {
        accountID,
        name: organizationState,
        nearmap: nearmapState ? nearMapAPIKey : '',
      }
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const fetchFinished = results => {
    if (utils.verifyResult(results[0])) doUpdate()

    handleOnClose()
    setFetchObjects(null)
    setFetching(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const handleEmail = e => {
    let emails = e.target.value
    emails = emails.replace(/[;\n]/g, ',').split(',')
    const procArray = emails.map(email => {
      return email.toString().trim()
    })

    setEmailAddress(procArray)
  }

  const verifyForm = () => {
    return organizationState !== ''
  }

  const handleOrg = e => {
    setOrganization(e.target.value)
  }

  const handleNearmap = e => {
    setNearmap(e.target.checked)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-new-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-new-account-dialog'>
          {accountID == null ? 'Add New' : 'Edit'} Account
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent
          className={[
            modalScss.MuiDialogBoxes,
            appScss.flexColumn,
            modalScss.fieldWidths,
            modalScss.addUserModal,
          ].join(' ')}
        >
          <label
            htmlFor='new-account'
            className={appScss['input-notched']}
            style={{ marginTop: '12px' }}
          >
            <span>Organization Name</span>
            <input
              id='new-account'
              onChange={handleOrg}
              placeholder='Organization Name'
              value={organizationState}
            />
          </label>
          {accountID ? null : (
            <label
              htmlFor='email-account'
              className={appScss['input-notched']}
              style={{ marginTop: '12px' }}
            >
              <span>Email Addresses</span>
              <textarea
                id='email-account'
                onChange={handleEmail}
                placeholder='user1@company.com, user2@company.com'
              />
            </label>
          )}
          <label
            htmlFor='nearmap-account'
            style={{ marginTop: '12px', display: 'inline' }}
          >
            Enable Nearmap?
            <input
              id='nearmap-account'
              type='checkbox'
              style={{ width: 'auto', marginLeft: '8px' }}
              onChange={handleNearmap}
              checked={nearmapState}
            />
          </label>
        </DialogContent>

        <DialogActions>
          <button
            className={modalScss.modalMainButton}
            type='button'
            style={{ width: '100%', placeContent: 'center' }}
            disabled={!verifyForm()}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : accountID == null ? (
              'Add Account'
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

SimpleDialog.propTypes = {
  accountID: PropTypes.number,
  accountName: PropTypes.string,
  accountNearmap: PropTypes.string,
  doUpdate: PropTypes.func,
  visible: PropTypes.func,
}

export default function AddNewAccountModal(props) {
  return <SimpleDialog {...props} />
}
