import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from '../../Legend/LegendSwatch/Utilities'

// Create styles
const styles = StyleSheet.create({
  swatchGroupItemContainer: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchGroupItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    marginHorizontal: 0,
    marginVertical: 4,
    padding: 0,
  },

  swatchItemRowContainer: {
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    margin: 0,
    padding: 0,
  },
  swatchItemRowTitle: {
    marginRight: 10,
  },
})

const ViewerCircleSwatch = ({
  layer,
  singleItemTitles,
  viewport,
  zoomLabel,
}) => {
  const getProperty = circleProp => {
    if (Array.isArray(circleProp)) {
      const expressionType = getExpressionType(circleProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(circleProp, viewport)
      }
      if (expressionType === 'dataBased') {
        return getDataBasedValue(circleProp)
      }
    } else {
      return circleProp
    }
  }

  const getCircleStyle = (radius, fillColor, opacity) => {
    const outlineRgbaColor = '#000000'
    const fillRgbaColor = fillColor
    let borderColor = outlineRgbaColor
    let borderWidth = 1
    let border = `${borderWidth}pt solid ${borderColor}`
    return {
      width: radius,
      height: radius,
      minWidth: '12px',
      minHeight: '12px',
      maxWidth: '20px',
      maxHeight: '20px', // Max and Min Sizing Here
      border: border,
      borderRadius: '50pt', // 50% wont work
      backgroundColor: fillRgbaColor,
      marginLeft: '5pt',
    }
  }

  const getCircleSwatch = (radius, color, opacity, label) => {
    let expressionValues = []
    let radiusObject = {}
    let colorObject = {}
    let opacityObject = {}
    if (Array.isArray(radius)) {
      radius.forEach(arr => {
        radiusObject[arr[0]] = arr[1]
      })
      let radiusValues = radius.map(arr => arr[0])
      expressionValues = [...expressionValues, ...radiusValues]
    }
    if (Array.isArray(color)) {
      color.forEach(arr => {
        colorObject[arr[0]] = arr[1]
      })
      let colorValues = color.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(opacity)) {
      opacity.forEach(arr => {
        opacityObject[arr[0]] = arr[1]
      })
      let opacityValues = opacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    const uniqueExpressionValues = [...new Set(expressionValues)]

    let swatchItems = []
    const expressionItemStyle = {
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `space-between`,
      alignItems: `center`,
    }
    const swatchListStyle = {
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      width: `100%`,
    }
    const swatchListItemsStyle = {
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      padding: '6px 8px',
      width: `100%`,
    }
    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        let itemRadius = widthObject[value] || radius
        let itemColor = colorObject[value] || color
        let itemOpacity = opacityObject[value] || opacity
        const style = getCircleStyle(itemRadius, itemColor, itemOpacity)
        return (
          <View key={value}>
            <View style={styles.swatchGroupItemRow}>
              <View>
                <Text style={styles.swatchItemRowTitle}>
                  {value === 'default' ? 'Default Style' : value}
                </Text>
              </View>
              <View>
                <Text style={style}></Text>
              </View>
            </View>
          </View>
        )
      })
      swatchItems.push(
        <View
          key={uniqueExpressionValues.join(',')}
          style={styles.swatchGroupItemContainer}
        >
          {items}
        </View>
      )
    } else {
      const style = getCircleStyle(radius, color, opacity)
      if (singleItemTitles) {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text style={styles.swatchItemRowTitle}>
                    {' '}
                    {singleItemTitles && zoomLabel}{' '}
                  </Text>
                </View>
                <View>
                  <Text style={style}></Text>
                </View>
              </View>
            </View>
          </View>
        )
      } else {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text style={styles.swatchItemRowTitle}>{zoomLabel}</Text>
                </View>
                <View>
                  <Text style={style}></Text>
                </View>
              </View>
            </View>
          </View>
        )
      }
    }

    return swatchItems
  }
  let label = getSwatchLabel(layer)
  let radius = getProperty(layer.paint['circle-radius'])
  let color = getProperty(layer.paint['circle-color'])
  let opacity = getProperty(layer.paint['circle-opacity'])
  let swatch = getCircleSwatch(radius, color, opacity, label)

  return <>{swatch}</>
}

export default ViewerCircleSwatch
