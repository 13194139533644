import React from 'react'
import NewLayer from './NewLayer/NewLayer'
import NewGroup from './NewGroup/NewGroup'
import appScss from '../../../App/App.scss'

const HeaderButtons = React.memo(() => {
  return (
    <div
      className={[
        appScss['flex-row'],
        appScss['flex-align-center'],
        appScss['flex-justify-space-between'],
      ].join(' ')}
    >
      <NewLayer />
      <NewGroup />
    </div>
  )
})

export default HeaderButtons
