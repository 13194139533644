import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'

// utils
import { setToolConfig } from '../../../actions/index'
import { deepCopy } from '../../../utilities/util'

// components
import Button from '../../../components/Button/Button'
import GroupInfo from './GroupInfo/GroupInfo'
import GroupFooter from './GroupInfo/GroupFooter'

// scss files
import scss from './GroupSettings.scss'
import appScss from '../../App/App.scss'
import toolScss from '../../../_tools.scss'

export default function GroupSettings({ onClose, onCollapse, config, dataConfig }) {
  const toolConfig = useSelector(state => state.toolConfig)
  const dispatch = useDispatch()

  const closeTool = () => {
    let newConfig = deepCopy(toolConfig)
    newConfig.forEach(tool => {
      if (tool.name === 'GroupSettings') {
        tool.visible = false
      }
    })
    // to maintain immutable redux state we need to clone toolConfig and send cloned to reducer
    dispatch(setToolConfig(newConfig))
    // call on close method to enable all map interactions
    onClose()
  }

  const getCollapsedUI = () => {
    return (
      <div
        className={[
          toolScss.toolQuickActionHeader,
          scss.toolQuickActionHeader,
        ].join(' ')}
      >
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <FontAwesomeIcon icon={config.icon} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>
          <div
            className={[
              toolScss.toolQuickActionButtonWrapper,
              scss.toolQuickActionButtonWrapper,
            ].join(' ')}
          >
            <button
              title='Expand'
              onClick={() => onCollapse(config.name, false)}
              className={[scss.btn, scss.toolHeaderButton].join(' ')}
            >
              <FontAwesomeIcon icon='expand-alt' size='lg' />
            </button>
          </div>
        </div>
      </div>
    )
  }

  const getFullUI = () => {
    if (!config.group) return null
    const label = config.group.label || ''

    const groupId = config.group.id
    let groupIdForDisplay = groupId.split('_')
    groupIdForDisplay = groupIdForDisplay[groupIdForDisplay.length - 1]

    return (
      <div className={scss.toolExpanded}>
        <div
          className={[toolScss.toolHeader, scss.toolHeader, 'handle'].join(' ')}
        >
          <div className={scss.toolAlign}>
            <div className={[toolScss.toolTitle, scss.toolTitle].join(' ')}>
              {label}
            </div>
            <FontAwesomeIcon
              icon='info-circle'
              size='1x'
              data-html={true}
              data-tip={['Group ID: ', groupIdForDisplay].join(' ')}
              data-for='groupid'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
              data-place='bottom'
              style={{ margin: '0 15px' }}
            />
            <div className={appScss.reactToolTip}>
              <ReactTooltip id='groupid' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
          <div
            className={[
              toolScss.toolHeaderButtons,
              scss.toolHeaderButtonWrapper,
            ].join(' ')}
          >
            <Button
              onClick={() => onCollapse(config.name, true)}
              leadingIcon='minus'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Minimize'
            />
            <Button
              onClick={() => closeTool(config.name)}
              leadingIcon='times'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Close'
            />
          </div>
        </div>
        <div className={[scss.toolBody].join(' ')}>
          <GroupInfo
            config={config}
            closeTool={closeTool}
            dataConfig={dataConfig}
          />
        </div>
        <GroupFooter
          config={config}
          closeTool={closeTool}
          dataConfig={dataConfig}
        />
      </div>
    )
  }
  return config.collapsed ? getCollapsedUI() : getFullUI()
}
