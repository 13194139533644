import { SET_MAP_CURSOR } from '../actions/types'
export default function SetMapCursor(state = 'grab', action) {
  switch (action.type) {
    case SET_MAP_CURSOR: {
      return action.payload
    }
    default:
      return state
  }
}
