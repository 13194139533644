import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMapHomeLocation } from '../../../utilities/user'
import UrlParams from './UrlParams'
import Dimensions from './Dimensions'
import { setViewPort, setMapZoom } from '../../../actions/index'

import {
  TOC_WIDTH,
  SIDE_BAR_WIDTH,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
} from './Constants'

const Viewport = ({ history }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const tocCollapsed = useSelector(state => state.tocCollapsed)
  const [home, setHome] = useState({})
  const [urlParams, setUrlParams] = useState({})

  const setViewportHome = () => {
    // if user has a home location set the home lat, lng, and zoom will be passed in as props
    // if the home lat, lng, and zoom props exist use these for the initial viewport, else use the defaults
    let { lat, lng, zoom } = urlParams
    if (!lat || !lng || !zoom) {
      lat = home.x
      lng = home.y
      zoom = home.z
    }
    if (!lat || !lng || !zoom) {
      lat = DEFAULT_LATITUDE
      lng = DEFAULT_LONGITUDE
      zoom = DEFAULT_ZOOM
    }

    let width = tocCollapsed ? 35 : TOC_WIDTH + SIDE_BAR_WIDTH

    const vp = {
      latitude: lat,
      longitude: lng,
      zoom: zoom,
      bearing: 0,
      pitch: 0,
      width: window.innerWidth - width,
      height: window.innerHeight,
    }
    dispatch(setViewPort(vp))
    dispatch(setMapZoom(zoom))
  }

  useEffect(() => {
    const homeLocation = getMapHomeLocation(user)
    if (homeLocation.x && homeLocation.y && homeLocation.z) {
      setHome(homeLocation)
    }
  }, [])

  useEffect(() => {
    setViewportHome()
  }, [home, urlParams])

  return (
    <>
      <UrlParams history={history} setUrlParams={setUrlParams} />
      <Dimensions />
    </>
  )
}

export default Viewport
