import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './AddTabButton.scss'

export default function AddTabButton({ addTab }) {
  return (
    <div className={scss.propertyTabWrapper}>
      <span>Add Tab</span>
      <button className={scss.addTabBtn} onClick={addTab}>
        <FontAwesomeIcon icon={'plus'} size='1x' />
      </button>
    </div>
  )
}
