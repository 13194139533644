import { SWITCH_PAGE } from '../actions/types'

export default function SwitchPage(state = 'map', action) {
  switch (action.type) {
    case SWITCH_PAGE: {
      return action.payload
    }
    default:
      return state
  }
}
