/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Range } from 'react-range'
import { fromJS } from 'immutable'
import Switch from '@material-ui/core/Switch'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { setMapStyle } from '../../../../../actions'

import scss from './Style.scss'
import appScss from '../../../../App/App.scss'

const LabelFormatting = ({
  layerId,
  horizontalVal,
  verticalVal,
  overlapText,
  setDisplaySaveButtonOnMenu,
}) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const style = mapStyle.toJS()

  const [allowOverlap, setAllowOverlap] = useState(overlapText)

  const [horizontalValues, setHorizontalValues] = useState(
    [horizontalVal] || [0]
  )
  const [verticalValues, setVerticalValues] = useState([verticalVal] || [0])

  const [maxVerticalOffset] = useState(5)
  const [minVerticalOffset] = useState(0)
  const [maxHorizontalOffset] = useState(5)
  const [minHorizontalOffset] = useState(0)
  const [step] = useState(0.25)

  const handleTextOverlap = () => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.layout['text-allow-overlap'] = !allowOverlap
        setAllowOverlap(!allowOverlap)

      })
    setDisplaySaveButtonOnMenu(true)
    dispatch(setMapStyle(fromJS(style)))
  }

  const handleTextOffset = () => {
    style.layers
      .filter(layer => layer.id === layerId)
      .forEach(layer => {
        layer.layout['text-offset'] = [horizontalValues[0], verticalValues[0]]
      })
    setDisplaySaveButtonOnMenu(true)
    dispatch(setMapStyle(fromJS(style)))
  }

  const handleChange = () => {
    handleTextOverlap()
  }

  const theme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: 'white', // silver
        },
        colorSecondary: {
          '&$checked': {
            // Controls checked color for the thumb
            color: 'white',
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.8,
          color: '#a30202',
          '$checked$checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: '#00e07f',
          },
        },
      },
    },
  })

  return (
    <div style={{ display: 'flex', paddingTop: '15px' }}>
      <div>
        <div style={{ fontSize: '14px', width: '100%' }}>Text Formatting</div>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <div className={scss.offsetContainer}>
            <div style={{ marginBottom: '15px' }}>
              <div style={{ fontSize: '12px', marginBottom: '10px' }}>
                Text Vertical Offset
              </div>
              <div className={scss.offsetValueContainer}>
                <Range
                  step={step}
                  min={minVerticalOffset}
                  max={maxVerticalOffset}
                  values={verticalValues}
                  onChange={values => {
                    setVerticalValues(values)
                    handleTextOffset()
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetTrack}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetThumb}
                    />
                  )}
                  renderMark={({ props, index }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetMark}
                    />
                  )}
                />
                <div style={{ minWidth: '15px', marginLeft: '10px' }}>
                  {verticalValues}
                </div>
              </div>

              <div style={{ fontSize: '12px', marginBottom: '10px' }}>
                Text Horizontal Offset
              </div>
              <div className={scss.offsetValueContainer}>
                <Range
                  step={step}
                  min={minHorizontalOffset}
                  max={maxHorizontalOffset}
                  values={horizontalValues}
                  onChange={values => {
                    setHorizontalValues(values)
                    handleTextOffset()
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetTrack}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetThumb}
                    />
                  )}
                  renderMark={({ props, index }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className={scss.offsetMark}
                    />
                  )}
                />
                <div style={{ minWidth: '15px', marginLeft: '10px' }}>
                  {horizontalValues}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <div style={{ fontSize: '10px' }}>
              Allow Text Overlap{'  '}
              <FontAwesomeIcon
                icon='question-circle'
                size='1x'
                data-tip='If set to false, Labels will be shown even when colliding with other labels. If set to true, Labels will be hidden when colliding with other labels.'
                data-for='data-driven'
                data-effect='solid'
                data-place='right'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              />
              <div className={appScss.reactToolTip}>
                <ReactTooltip
                  id='data-driven'
                  getContent={dataTip => `${dataTip}`}
                  wrapper='div'
                  className={scss.toolTipForLayerFormatting}
                />
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <FormGroup>
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Switch
                      checked={allowOverlap}
                      onChange={handleChange}
                      theme={theme}
                    />
                  }
                  label={allowOverlap ? 'Yes' : 'No'}
                />
              </FormGroup>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LabelFormatting
