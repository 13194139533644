import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setToolConfig,
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  mountLayerSettings,
  setOverPanel,
} from '../../actions/index'

class CloseTool extends Component {
  componentDidMount = () => {
    let { toolName, toolConfig } = this.props
    toolConfig.forEach(tool => {
      if (tool.name === toolName) {
        tool.visible = false
        tool.layer = null
      }
    })
    let newToolConfig = JSON.parse(JSON.stringify(toolConfig))
    this.props.mountLayerSettings(null)
    this.props.setToolConfig(newToolConfig)
    this.props.setDragPan(true)
    this.props.setDoubleClickZoom(true)
    this.props.setScrollZoom(true)
    this.props.setOverPanel(false)
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      mountLayerSettings: mountLayerSettings,
      setToolConfig: setToolConfig,
      setDragPan: setDragPan,
      setDoubleClickZoom: setDoubleClickZoom,
      setScrollZoom: setScrollZoom,
      setOverPanel: setOverPanel,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    toolConfig: state.toolConfig,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseTool)
