/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import environment from '_environments'

// import { apiFetch } from "../../utilities/api";
import AsyncFetch from '../../utilities/AsyncFetch'

import { Alert } from '../../actions'

import { apis } from '../../config/apiConfig'
import appScss from '../App/App.scss'

import * as utils from '../../utilities/util'

export default function Profile() {
  const user = useSelector(state => state.user)

  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  // ========================
  // = PROFILE POPUP STATES =
  // ========================

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  const [apiVersion, setApiVersion] = useState('N/A')

  //Fields and their States
  const profile = useSelector(state => state.profile)

  const dispatch = useDispatch()

  const buildFetchParams = (id, val) => {
    const method = 'POST'
    let body = {}

    if (id === 'dateFormat') {
      body = { dateFormat: val }
    } else if (id === 'language') {
      body = { locale: val }
    } else if (id === 'timezone') {
      body = { timezone: val }
    }

    let url = apis['apiDatabase'].uri + 'user/update'

    setFetchObjects([{ url, method, body }])
    console.log('fetchObjects', fetchObjects)
    setFetching(true)
  }

  const fetchFinished = results => {
    results = results[0]

    let { message } = results

    message = message.split('Welcome to the MY ASSET MAP endpoint v')[1] || 'NA'

    setApiVersion(message || 'NA')
    setFetchObjects(null)
    setFetching(null)
  }

  // TESTING ALERT FUNCTIONALITY
  const alertHandler = () => {
    console.log('Dispatching Test Alert')
    dispatch(Alert({ success: 'You have successfully sent an alert test!' }))
  }

  const buildFetchParamsForBackEnd = () => {
    const method = 'GET'
    const body = {}
    const url = apis.apiDatabase.uri

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  useEffect(() => {
    buildFetchParamsForBackEnd()
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <div style={{ margin: '20px' }}>
        <b style={{ fontSize: '18px' }}>Debug Page</b>
        <br />
        <i>
          This page allows our development team to know more about the session
          you are using. Please screenshot this image and send it to the support
          team if applicable.
        </i>
        <br />
        <br />
        <button
          className={appScss.blueButton}
          onClick={() => error_has_occurred()}
        >
          Validate Error Boundary
        </button>
        <br />
        <br />
        <p>
          <b>User: </b>
          {user.profile.displayName}
        </p>
        <p>
          <b>UUID: </b>
          {user.UUID}
        </p>
        <p>
          <b>User ID: </b>
          {user.profile.userID}
        </p>
        <p>
          <b>Email: </b>
          {user.profile.emailAddress}
        </p>
        <p>
          <b># of Accounts: </b>
          {user.accounts.length}
        </p>
        <br />
        <p>
          <b>Current Account ID: </b>
          {user.accountID}
        </p>
        <p>
          <b>Current Map ID: </b>
          {user.mapID}
        </p>
        <br />
        <p>
          <b>Current Role: </b>
          {user.role.name}
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            <b>
              Create Layers:{' '}
              {user.permissions.layerAdd ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          <li>
            <b>
              View Organization Layers:{' '}
              {user.permissions.layerOrg ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          <li>
            <b>
              Administer Account:{' '}
              {user.permissions.admin ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          <li>
            <b>
              Manage Billing:{' '}
              {user.permissions.billing ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          {utils.isSuperAdmin(user) && (
            <li>
              <b>
                MyAssetMap Team Member:{' '}
                <span style={{ color: 'darkgreen' }}>YES</span>
              </b>
            </li>
          )}
        </ul>
        <br />
        <br />
        <b>Environment</b>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            <b>
              Development:{' '}
              {environment.isDev ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          <li>
            <b>
              QA/Staging:{' '}
              {environment.isQa ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
          <li>
            <b>
              Production:{' '}
              {environment.isPrd ? (
                <span style={{ color: 'darkgreen' }}>YES</span>
              ) : (
                <span style={{ color: 'darkred' }}>NO</span>
              )}
            </b>
          </li>
        </ul>
        <br />
        <br />
        <p>
          <b>Front-End version: </b>
          {process.env.VERSION} on {environment.env} [{process.env.NODE_ENV}{' '}
          build]
        </p>
        <p>
          <b>Back-End version: </b>
          {apiVersion}
        </p>
      </div>
    </>
  )
}
