import * as geoUtils from '../../../utilities/geospatial'

export const getHotLayers = type => {
  let hotLayers = []
  if (type === 'line') {
    hotLayers = [
      { hot: 'draw_line_hot', cold: 'draw_line_cold' },
      { hot: 'draw_midpoints_hot' },
      { hot: 'draw_verticies_hot' },
    ]
  }
  if (type === 'fill') {
    hotLayers = [
      { hot: 'draw_polygon_hot', cold: 'draw_polygon_cold' },
      { hot: 'draw_polygon_outline_hot', cold: 'draw_polygon_outline_cold' },
      { hot: 'draw_midpoints_hot' },
      { hot: 'draw_verticies_hot' },
    ]
  }
  if (type === 'circle') {
    hotLayers = [{ hot: 'draw_verticies_hot', cold: 'draw_verticies_cold' }]
  }
  return hotLayers
}

export const getSnappingGeom = (e, mapRef, snappingLayer) => {
  const mapObj = mapRef.getMap()
  const targetSnappingLayers = snappingLayer.snappingIds
  const width = 25
  const height = 25
  const bbox = [
    [e.offsetX - width / 2, e.offsetY - height / 2],
    [e.offsetX + width / 2, e.offsetY + height / 2],
  ]
  const features = mapObj.queryRenderedFeatures(bbox, {
    layers: targetSnappingLayers,
  })

  if (!features.length) return null
  const lineCoords = []
  let snapgeoms = null

  if (features[0].layer.type === 'circle') {
    const feature = {
      type: 'Feature',
      properties: {},
      geometry: features[0].geometry,
    }
    snapgeoms = feature
  } else {
    features.forEach(feature => {
      if (feature.geometry.type === 'Polygon') {
        lineCoords.push(feature.geometry.coordinates[0])
      }
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates[0].forEach(coords => {
          lineCoords.push(coords)
        })
      }
      if (feature.geometry.type === 'LineString') {
        lineCoords.push(feature.geometry.coordinates)
      }
      if (feature.geometry.type === 'MultiLineString') {
        feature.geometry.coordinates.forEach(coords => {
          lineCoords.push(coords)
        })
      }
    })
    snapgeoms = geoUtils.getMultiLineString(lineCoords)
  }

  return snapgeoms
}

export const getSnapPoint = (snapGeom, point, snapLine, snapVertex) => {
  let snapPoint = null
  if (
    snapGeom.geometry.type === 'Point' ||
    snapGeom.geometry.type === 'MultiPoint'
  ) {
    if (snapVertex) {
      snapPoint = snapGeom
    }
  } else {
    const nearestPointOnLine = geoUtils.getNearestPointOnLine(snapGeom, point)
    const nearestVertex = geoUtils.getNearestVertex(snapGeom, point)
    if (snapLine && !snapVertex) {
      // only snap line
      snapPoint = nearestPointOnLine
    } else if (snapVertex && !snapLine) {
      // only snap vertex
      snapPoint = nearestVertex
    } else {
      // both snap line and snap vertex, return closesed
      const pointOnLineDistance = nearestPointOnLine.properties.dist
      const vertexDistance = nearestVertex.properties.distanceToPoint
      if (pointOnLineDistance < vertexDistance) {
        // point on line is closer
        snapPoint = nearestPointOnLine
      } else {
        // vertex is closer
        snapPoint = nearestVertex
      }
    }
  }
  snapPoint.properties.isSnapPoint = 'true'

  return snapPoint
}
