export const SET_VIEWPORT = 'SET_VIEWPORT'
export const GET_VIEW_PORT = 'GET_VIEW_PORT'
export const SET_MAP_STYLE = 'SET_MAP_STYLE'
export const TOGGLE_MAP_STYLE = 'TOGGLE_MAP_STYLE'
export const UPDATE_MAP_STYLE = 'UPDATE_MAP_STYLE'
export const UPDATE_TOOL_CONFIG = 'UPDATE_TOOL_CONFIG'
export const SET_TOOL_CONFIG = 'SET_TOOL_CONFIG'
export const DOUBLE_CLICK_ZOOM = 'DOUBLE_CLICK_ZOOM'
export const SET_DRAG_PAN = 'DRAG_PAN'
export const CREATE_POPUP = 'CREATE_POPUP'
export const SET_MAP_CURSOR = 'SET_MAP_CURSOR'
export const SET_MAP_MODE = 'SET_MAP_MODE'
export const UPDATE_DATA_CONFIG = 'UPDATE_DATA_CONFIG'
export const SWITCH_PAGE = 'SWITCH_PAGE'
export const SET_DATA_CONFIG = 'SET_DATA_CONFIG'
export const REBUILD_MAP_STYLE = 'REBUILD_MAP_STYLE'
export const MOUNT_LAYER_SETTINGS = 'MOUNT_LAYER_SETTINGS'
export const MOUNT_MAP_VIEWS = 'MOUNT_MAP_VIEWS'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const OPEN_NOTES = 'OPEN_NOTES'
export const TOC_COLLAPSED = 'TOC_COLLAPSED'
export const ENABLE_MAP = 'ENABLE_MAP'
export const SET_SCROLL_ZOOM = 'SET_SCROLL_ZOOM'
export const SET_OVER_PANEL = 'SET_OVER_PANEL'
export const DISABLE_POPUP = 'DISABLE_POPUP'
export const SET_POPUP_DOCKED = 'SET_POPUP_DOCKED'
export const SET_DRAW_MODE = 'SET_DRAW_MODE'
export const SET_MEASURE_MODE = 'SET_MEASURE_MODE'
export const SET_SNAP_SETTINGS = "SET_SNAP_SETTINGS"
export const SET_DRAW_MEASURE_SETTINGS  = "SET_DRAW_MEASURE_SETTINGS"
export const SET_BOUNDS_SETTINGS = "SET_BOUNDS_SETTINGS"
export const INITIATE_TRACKING = "INITIATE_TRACKING"
export const UPDATE_CURRENT_LOCATION = "UPDATE_CURRENT_LOCATION"
export const TERMINATE_TRACKING = "TERMINATE_TRACKING"

//User
export const GET_INFO = 'GET_INFO'
export const LOGIN = 'LOGIN'
export const REFRESH = 'REFRESH'
export const ALERT = 'ALERT'
export const LOGOUT = 'LOGOUT'

export const ERROR = 'ERROR'


