import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCodeInput from 'react-verification-code-input'

import LogoIcon from '../../assets/logo/drk-trim-main.svg'

import { Alert } from '../../actions'

// scss files
import appScss from '../App/App.scss'
import scss from './Login.scss'

export default function ResetPassword(props) {
  const [isLoading, setIsLoading] = useState(false)

  const [confirmationCode, setConfirmationCode] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = scss.boxedFormPage
    return () => {
      document.getElementsByTagName('body')[0].className = ''
    }
  }, [])

  const validateCodeForm = () => {
    return email.length > 0
  }

  const validateResetForm = () => {
    return (
      confirmationCode.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    )
  }

  const handleSendCodeClick = e => {
    e.preventDefault()

    setIsLoading(true)

    Auth.forgotPassword(email)
      .then(function () {
        setIsLoading(false)
        setCodeSent(true)
      })
      .catch(function (err) {
        setIsLoading(false)

        dispatch(Alert({ error: 'Send Failed: ' + err.message }))
      })
  }

  const handleConfirmClick = e => {
    e.preventDefault()

    setIsLoading(true)

    var state = this
    Auth.forgotPasswordSubmit(email, confirmationCode, password)
      .then(function () {
        setIsLoading(false)
        setConfirmed(true)
      })
      .catch(function (err) {
        setIsLoading(false)

        dispatch(Alert({ error: 'Reset Failed: ' + err.message }))
      })
  }

  const handleSendCodeClickEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSendCodeClick(e)
    }
  }

  const handleConfirmClickEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleConfirmClick(e)
    }
  }

  const renderRequestCodeForm = () => {
    return (
      <>
        <img
          className={scss['login-logo']}
          style={{ marginBottom: '54px' }}
          src={LogoIcon}
          alt='My Asset Map Logo'
        />
        <label
          htmlFor='reset-email'
          className={appScss['input-notched']}
          style={{ marginBottom: '24px' }}
        >
          <span>Email</span>
          <input
            autoFocus
            id='reset-email'
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleSendCodeClickEnter}
            value={email}
            type='text'
            style={{ textAlign: 'left' }}
          />
        </label>
        <button
          type='button'
          onClick={handleSendCodeClick}
          disabled={!validateCodeForm()}
          className={[
            scss['login-submit-button'],
            scss[isLoading ? 'login-submit-button-loading' : ''],
          ].join(' ')}
        >
          {isLoading ? 'Sending...' : 'Send Confirmation'}
        </button>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/login'
        >
          Back to login
        </Link>
      </>
    )
  }

  const renderConfirmationForm = () => {
    return (
      <>
        <img
          className={scss['login-logo']}
          style={{ marginBottom: '54px' }}
          src={LogoIcon}
          alt='My Asset Map Logo'
        />
        <span
          className={scss['login-helper-text']}
          style={{ marginBottom: '17px' }}
        >
          Please check your email ({email}) for the confirmation code.
        </span>

        <ReactCodeInput
          autoFocus
          className={scss['react-single-code-inputs']}
          type={'number'}
          fields={6}
          values={confirmationCode.split('')}
          // onComplete={handleConfirmationSubmit}
          onChange={setConfirmationCode}
        />
        <label
          htmlFor='reset-password'
          className={appScss['input-notched']}
          style={{ marginTop: '32px' }}
        >
          <span>New Password</span>
          <input
            id='reset-password'
            onChange={e => setPassword(e.target.value)}
            onKeyDown={handleConfirmClickEnter}
            value={password}
            type='password'
            style={{ textAlign: 'left' }}
          />
        </label>
        <label
          htmlFor='reset-password-confirm'
          className={appScss['input-notched']}
          style={{ margin: '32px 0 24px 0' }}
        >
          <span>Confirm Password</span>
          <input
            id='reset-password-confirm'
            onChange={e => setConfirmPassword(e.target.value)}
            onKeyDown={handleConfirmClickEnter}
            value={confirmPassword}
            type='password'
          />
        </label>
        <button
          type='button'
          onClick={handleConfirmClick}
          disabled={!validateResetForm()}
          className={[
            scss['login-submit-button'],
            scss[isLoading ? 'login-submit-button-loading' : ''],
          ].join(' ')}
        >
          {isLoading ? 'Confirming...' : 'Confirm'}
        </button>
        <Link
          className={scss['login-link']}
          style={{ marginTop: '12px' }}
          to='/login'
        >
          Back to login
        </Link>
      </>
    )
  }

  const renderSuccessMessage = () => {
    return (
      <>
        <span
          className={scss['login-heading']}
          style={{ marginBottom: '12px' }}
        >
          Success!
        </span>
        <div
          className={scss['login-success-icon']}
          style={{ marginBottom: '17px' }}
        >
          <FontAwesomeIcon icon={('fal', 'check')} />
        </div>
        <span
          className={[
            scss['login-helper-text'],
            appScss['text-align-center'],
          ].join(' ')}
          style={{ marginBottom: '27px' }}
        >
          Password Reset{' '}
          {email ? (
            <>
              for <br /> {email}
            </>
          ) : (
            'Successful'
          )}
        </span>
        <Link className={scss['login-link-button']} to='/login'>
          Login
        </Link>
      </>
    )
  }

  return (
    <div
      className={[
        scss['login-container'],
        confirmed
          ? (scss['login-success-container'], scss['flex-align-center'])
          : '',
      ].join(' ')}
    >
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  )
}
