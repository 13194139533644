import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as mapUtils from '../../../../../utilities/mapStyle'
import { updateMapStyle, setMapCursor } from '../../../../../actions'
import { fromJS } from 'immutable'
import { apis } from '../../../../../config/apiConfig'
import AsyncFetch from '../../../../../utilities/AsyncFetch'

const Delete = ({ geometry, id, finishDelete }) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)

  const style = mapStyle.toJS()
  let deleteLayer = []
  if (geometry === 'line') {
    deleteLayer = 'delete_line'
  }
  if (geometry === 'fill') {
    deleteLayer = 'delete_line'
  }
  if (geometry === 'circle') {
    deleteLayer = 'delete_circle'
  }

  let layerId = null
  let layerSource = null
  if (
    id !== 'draw_line_cold' &&
    id !== 'draw_polygon_cold' &&
    id !== 'draw_verticies_cold'
  ) {
    const layer = style.layers.filter(layer => layer.id === id)
    if (!layer[0]) return null
    layerId = id
    layerSource = layer[0].source
  }

  const deleteSource = mapUtils.getSource(style, deleteLayer)
  // NOTE:
  // features with a mamid in properties have been saved to the database
  // create a POST request for each to be deleted
  // features that do not have a mamid in properties have not been saved
  // and can simply be removed from the draw source
  const urlObjects = deleteSource.data.features
    .filter(feature => feature.properties.mamid)
    .map(feature => {
      const method = 'POST'
      const url = apis['apiDatabase'].uri + 'layer/geojson/delete'
      const body = {
        layerID: layerId,
        featureID: feature.properties.mamid,
      }

      return {
        url,
        body,
        method,
      }
    })

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const updateSource = sourceId => {
    const featureIds = deleteSource.data.features
      .filter(feature => !feature.properties.mamid)
      .map(feature => feature.id)

    let emptySource = emptyLayerSource(style, sourceId)
    style.sources[sourceId] = emptySource

    if (sourceId === 'delete_circle') {
      const remainingFeatures = style.sources[
        'draw_verticies_cold'
      ].data.features
        .filter(feature => !feature.properties.mamid)
        .filter(feature => !featureIds.includes(feature.id))
      style.sources['draw_verticies_cold'].data.features = remainingFeatures
    }
    if (sourceId === 'delete_line') {
      const remainingLineFeatures = style.sources[
        'draw_line_cold'
      ].data.features
        .filter(feature => !feature.properties.mamid)
        .filter(feature => !featureIds.includes(feature.id))
      const remainingPolygonFeatures = style.sources[
        'draw_polygon_cold'
      ].data.features
        .filter(feature => !feature.properties.mamid)
        .filter(feature => !featureIds.includes(feature.id))
      style.sources['draw_polygon_outline_cold'].data.features =
        remainingPolygonFeatures
      style.sources['draw_polygon_cold'].data.features =
        remainingPolygonFeatures
      style.sources['draw_line_cold'].data.features = remainingLineFeatures
    }
    //  Add cache buster to the tile url to prevent browser caching of old tiles
    style.sources[layerSource].tiles = style.sources[layerSource].tiles.map(
      tileUrl => {
        if (tileUrl.indexOf('?') === -1) {
          return tileUrl + `?dt=${Date.now()}`
        } else {
          let url = tileUrl.replace(/&dt.*$/, '')
          return url + `&dt=${Date.now()}`
        }
      }
    )
    dispatch(updateMapStyle(fromJS(style)))
    finishDelete()
  }

  const fetchFinished = () => {
    updateSource(deleteLayer)
  }

  useEffect(() => {
    dispatch(setMapCursor('wait'))
    return () => dispatch(setMapCursor('grab'))
  }, [])

  return (
    urlObjects && (
      <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
    )
  )
}

export default Delete
