import { INITIATE_TRACKING, UPDATE_CURRENT_LOCATION, TERMINATE_TRACKING } from '../actions/types'

const initialState = {
    isTrackingLocation: false,
    currentLocation: null
}

const Geolocation = (state = initialState, action) => {
    switch (action.type) {
        case INITIATE_TRACKING:
            return {
                ...state,
                isTrackingLocation: true
            }
        case UPDATE_CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: action.payload
            }
        case TERMINATE_TRACKING:
            return {
                ...state,
                isTrackingLocation: false
            }
        default:
            return state
    }
}

export default Geolocation