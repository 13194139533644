import React from 'react'
import styled from 'styled-components'
import scss from './PropertyTab.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modalScss from '../../../CSSModules/Modal.scss'
import { Draggable } from 'react-beautiful-dnd'
import { PropertyList } from './TabItem/PropertyList/PropertyList'

// styld component TODO:  Not Sure if i want to keep this.

export const Handle = styled.div`
  text-align: right;
  width: auto;
  height: auto;
`

// Create and export the BoardColumn component
export const PropertyTab = props => {
  const title =
    props.column.id === 'propertiesHolder' ? (
      <div className={scss.inputContainer}>
        <div className={scss.addOnGroup}>
          <div className={modalScss.modalInput}>Unassigned</div>
        </div>
      </div>
    ) : (
      <div className={scss.inputContainer}>
        <div className={scss.addOnGroup}>
          <input
            className={[modalScss.modalInput, scss.tabTitle].join(' ')}
            type='text'
            name={props.column.id}
            value={props.column.title}
            onChange={props.handleTitleChange}
          ></input>
          <FontAwesomeIcon
            onClick={() => props.deleteTab(props.column.id)}
            icon={['fal', 'trash-alt']}
            size='1x'
          />
        </div>
      </div>
    )

  return (
    <Draggable draggableId={props.column.title} index={props.index}>
      {provided => (
        <div
          className={scss.PropertyTabWrapper}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {/* Title of the column */}
          {title}

          <PropertyList
            className={scss.propertyTabText}
            index={props.index}
            listId={props.column.id}
            listType={'PROPERTY'}
            useClone={props.useClone}
            items={props.items}
          />

          <div
            style={
              props.title !== 'propertiesHolder' ? {} : { display: 'inline' }
            }
          >
            <Handle {...provided.dragHandleProps}>
              <FontAwesomeIcon icon={['fal', 'arrows-h']} size='1x' />
            </Handle>
          </div>
        </div>
      )}
    </Draggable>
  )
}
