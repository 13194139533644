import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFooterHeight } from '../../actions'
import { Resizable } from 're-resizable'
import DataTable from '../DataTable/DataTable'
import { setDataTable } from '../../actions'

export default function Footer() {
  const dispatch = useDispatch()
  const [height, setHeight] = React.useState(0)
  const dataTable = useSelector(state => state.dataTable)

  const onResizeStop = (e, direction, ref, d) => {
    setHeight(height + d.height)
    dispatch(setFooterHeight(height + d.height))
  }

  const onClose = () => {
    localStorage.removeItem('dataTable')
    setHeight(0)
    dispatch(setFooterHeight(0))
  }

  useEffect(() => {
    const dataTable = JSON.parse(localStorage.getItem('dataTable'))
    if (dataTable) {
      dispatch(setDataTable(dataTable))
    }
  }, [])

  useEffect(() => {
    if (dataTable) {
      localStorage.setItem('dataTable', JSON.stringify(dataTable))
      const { foooterHeight } = dataTable
      setHeight(foooterHeight)
      dispatch(setFooterHeight(foooterHeight))
    }
    return () => {}
  }, [dataTable])

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #ddd',
    background: '#f0f0f0',
  }

  return (
    <>
      <Resizable
        style={style}
        size={{ width: '100%', height }}
        minWidth='100%'
        onResizeStop={onResizeStop}
      >
        {dataTable && <DataTable height={height} onClose={onClose} />}
      </Resizable>
    </>
  )
}
