import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import appScss from '../../../App/App.scss'
import scss from '../../Toc.scss'
import ReactTooltip from 'react-tooltip'
import LayerButton from './LayerButton'
import LayerSettingsButton from './LayerSettingsButton.jsx'

const TocLayer = React.memo(({ layer, imagery, filtered, labeled }) => {
  const user = useSelector(state => state.user)
  const tocFilter = useSelector(state => state.tocFilter)
  const sourceLoaded = false

  const [layerIds, setLayerIds] = useState(null)

  let showLoadingIcon = false
  // Note if the TOC layer is of type raster (but not an imagery layer), it is an image layer
  // Image layers need to be loaded from S3 before they are useable
  // check if the layer is loaded, if not show the loading icon
  if (
    !imagery &&
    layer.layersArray[0] &&
    layer.layersArray[0].layer.type === 'raster'
  ) {
    if (sourceLoaded && !sourceLoaded.includes(layer.toc.id)) {
      showLoadingIcon = true
    }
  }

  if (!user.isActive) disabled = true

  const labelLength = layer.toc.label.length

  useEffect(() => {
    if (tocFilter !== '') {
      const re = new RegExp(tocFilter, 'gi')
      const res = layer.toc.label.match(re)
      if (!res) {
        // Layer Name does not match, do not render in TOC
        setLayerIds(null)
        return
      }
    }
    let ids = layer.layersArray.map(layerObj => {
      return Object.values(layerObj)
        .filter(layer => layer && layer.id)
        .map(layer => layer.id)
    })

    const join = ids.join(',')
    const split = join.split(',')

    setLayerIds(split)
  }, [tocFilter])

  return (
    <>
      {layerIds && (
        <div className={[scss.addOnGroup, scss.addOnLayer].join(' ')}>
          <>
            <LayerButton
              layerIds={layerIds}
              layer={layer}
              filtered={filtered}
              labeled={labeled}
            />
            <LayerSettingsButton
              layerIds={layerIds}
              layer={layer}
              imagery={imagery}
            />
          </>
        </div>
      )}
      {layerIds && labelLength > 26 && (
        <div className={appScss.reactToolTip}>
          <ReactTooltip
            id={layerIds.join(',')}
            getContent={dataTip => `${dataTip}`}
          />
        </div>
      )}
    </>
  )
})

export default TocLayer
