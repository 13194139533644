import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from '../../Legend/LegendSwatch/Utilities'

// Create styles
const styles = StyleSheet.create({
  swatchGroupItemContainer: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchGroupItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    marginHorizontal: 0,
    marginVertical: 4,
    padding: 0,
  },

  swatchItemRowContainer: {
    margin: 0,
    padding: 0,
    width: `100%`,
  },
  swatchItemRow: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    alignItems: `center`,

    fontSize: 9,
    fontWeight: `normal`,
    margin: 0,
    padding: 0,
  },
})

const ViewerLineSwatch = ({ layer, singleItemTitles, viewport }) => {
  const getLineProperty = lineProp => {
    if (Array.isArray(lineProp)) {
      const expressionType = getExpressionType(lineProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(lineProp, viewport)
      }
      if (expressionType === 'dataBased') {
        return getDataBasedValue(lineProp)
      }
    } else {
      return lineProp
    }
  }

  const getLineStyle = (width, color, opacity) => {
    if (width > 20) width = 20

    return {
      width: '20px',
      borderBottom: `${width}pt solid ${color}`,
      opacity: opacity,
      marginLeft: '5pt',
    }
  }

  const getLineSwatch = (width, color, opacity, label) => {
    let expressionValues = []
    let widthObject = {}
    let colorObject = {}
    let opacityObject = {}
    if (Array.isArray(width)) {
      width.forEach(arr => {
        widthObject[arr[0]] = arr[1]
      })
      let widthValues = width.map(arr => arr[0])
      expressionValues = [...expressionValues, ...widthValues]
    }
    if (Array.isArray(color)) {
      color.forEach(arr => {
        colorObject[arr[0]] = arr[1]
      })
      let colorValues = color.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(opacity)) {
      opacity.forEach(arr => {
        opacityObject[arr[0]] = arr[1]
      })
      let opacityValues = opacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    const uniqueExpressionValues = [...new Set(expressionValues)]

    let swatchItems = []
    const expressionItemStyle = {
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `space-between`,
      alignItems: `center`,
    }
    const swatchListStyle = {
      width: `100%`,
    }
    const swatchListItemsStyle = {
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      padding: '6px 8px',
      width: `100%`,
    }
    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        let itemWidth = widthObject[value] || width
        let itemColor = colorObject[value] || color
        let itemOpacity = opacityObject[value] || opacity
        const lineStyle = getLineStyle(itemWidth, itemColor, itemOpacity)

        return (
          <View key={value}>
            <View style={styles.swatchGroupItemRow}>
              <Text>{value === 'default' ? 'Default Style' : value}</Text>
              <Text style={lineStyle}></Text>
            </View>
          </View>
        )
      })
      swatchItems.push(
        <View
          key={uniqueExpressionValues.join(',')}
          style={styles.swatchGroupItemContainer}
        >
          {items}
        </View>
      )
    } else {
      const lineStyle = getLineStyle(width, color, opacity)
      if (singleItemTitles) {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text>{singleItemTitles && label}</Text>
                </View>
                <View>
                  <Text style={lineStyle} />
                </View>
              </View>
            </View>
          </View>
        )
      } else {
        swatchItems.push(
          <View
            key={layer.id + '_legendItem'}
            style={styles.swatchItemRowContainer}
          >
            <View>
              <View style={styles.swatchItemRow}>
                <View>
                  <Text> </Text>
                </View>
                <View>
                  <Text style={lineStyle} />
                </View>
              </View>
            </View>
          </View>
        )
      }
    }

    return swatchItems
  }
  let label = getSwatchLabel(layer)
  let width = getLineProperty(layer.paint['line-width'])
  let color = getLineProperty(layer.paint['line-color'])
  let opacity = getLineProperty(layer.paint['line-opacity'])
  let swatch = getLineSwatch(width, color, opacity, label)

  return <>{swatch}</>
}

export default ViewerLineSwatch
