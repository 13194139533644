import { UPDATE_DATA_CONFIG } from '../actions/types'

export default function UpdateDataConfig(state = null, action) {
  switch (action.type) {
    case UPDATE_DATA_CONFIG: {
      return action.payload
    }
    default:
      return state
  }
}
