import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditorState, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

// utils
import { setToolConfig } from '../../../actions'
import { deepCopy } from '../../../utilities/util'

// components
import Button from '../../../components/Button/Button'

// scss files
import scss from './Notes.scss'
import './Quill.css'
import './draftEditor.css'

export default function Notes({ onClose, config }) {
  const dispatch = useDispatch()

  const mapViewNotes = useSelector(state => state.mapViewNotes)
  const toolConfig = useSelector(state => state.toolConfig)

  const [collapsed, setCollapsed] = useState(config.collapsed)

  // If no notes have been set, hide the panel
  if (!mapViewNotes) return null

  // NOTE:  We transitioned from Quill.js to Draft.js for Notes Editor,
  // Draft.js is built with immutable and will not work by using dangerouslySetInnerHTML
  // Existing Notes that were created with Quill are not compatible in the Draft.js Editor (used to render the notes)
  // To allow for previous notes saved with Quill to continue to work we have added a flag called draftJS that is set to true on save
  // Any notes without this flag will render using dangerouslySetInnerHTML
  const newNotes = mapViewNotes.draftJS
    ? EditorState.createWithContent(convertFromRaw(mapViewNotes.notes))
    : mapViewNotes.notes

  const closeTool = () => {
    const newConfig = deepCopy(toolConfig)
    newConfig.forEach(tool => {
      if (tool.name === 'Notes') {
        tool.visible = false
      }
    })
    // to maintain immutable redux state we need to clone toolConfig and send cloned to reducer
    dispatch(setToolConfig(newConfig))
    // call on close method to enable all map interactions
    onClose()
  }

  const getCollapsedUI = () => {
    const ui = (
      <div className={scss.toolQuickActionButtonWrapper}>
        <button
          title='Expand'
          onClick={() => setCollapsed(false)}
          className={[scss.btn, scss.toolHeaderButton].join(' ')}
        >
          <FontAwesomeIcon icon='expand-alt' size='lg' />
        </button>
      </div>
    )
    
    return (
      <div className={[scss.toolQuickActionHeader].join(' ')}>
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <FontAwesomeIcon icon={['fas', 'sticky-note']} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>{ui}</div>
      </div>
    )
  }
  const getFullUI = () => {
    const ui = (
      <div className={[scss.toolBody, scss.noteContainer].join(' ')}>
        <div></div>
        {mapViewNotes.draftJS ? (
          <Editor toolbarHidden editorState={newNotes} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: mapViewNotes.notes }} />
        )}
      </div>
    )

    return (
      <div className={scss.toolExpanded}>
        <div className={[scss.toolHeader, 'handle'].join(' ')}>
          <FontAwesomeIcon icon={['fas', 'sticky-note']} size='1x' fixedWidth />
          <div className={scss.toolTitle}>{config.title}</div>
          <div className={scss.toolHeaderButtonWrapper}>
            <Button
              onClick={() => setCollapsed(true)}
              leadingIcon='minus'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Minimize'
            />
            <Button
              onClick={() => closeTool(config.name)}
              leadingIcon='times'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Close'
            />
          </div>
        </div>
        {ui}
      </div>
    )
  }
  return collapsed ? getCollapsedUI() : getFullUI()
}
