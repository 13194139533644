import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import * as utils from '../../../utilities/util'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  layerLabel,
  layerId,
  visible,
  layerList,
  setLayerList,
}) {
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [urlObjects, setUrlObjects] = useState(null)
  console.log('Layer List', layerList)

  const handleDelete = () => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/delete'
    const body = {
      layerID: layerId,
    }

    setFetching(true)
    setUrlObjects([{ url, body, method }])
  }
  const fetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        console.log(' availableLayersAPI result ', result)
        setLayerList(layerList.filter(layer => layer && layer.id !== layerId))
        setMountMakeDataConfig(true)
      }
    })
  }

  const handleOnFinish = () => {
    setFetching(false)
    setFetchObjects(null)
    handleOnClose()
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObjects(null)
    },
    [urlObjects]
  )

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={handleOnFinish}
            layerDeletedId={layerId}
          />
        )}
        {urlObjects && (
          <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
        )}
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='delete-layer-dialog'
          open={open}
          maxWidth='sm'
        >
          <DialogTitle id='delete-layer-title'>
            Delete Layer
            <FontAwesomeIcon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent>
            <p>
              You are deleting{' '}
              <i>
                <b>{layerLabel}</b>
              </i>{' '}
              layer. This action cannot be undone. Proceed?
            </p>
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Delete'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DeleteLayer(props) {
  return <SimpleDialog {...props} />
}
