import React from 'react'
import { clone } from '../../../../../utilities/geospatial'
import scss from './StyleInput.scss'

import NumberInput from './NumberInput'
import ColorInput from './ColorInput'

export const StyleInput = ({
  layerId,
  layerPaintProps,
  outLinePaintProps,
  type: dataType,
  level = null,
  databased = null,
  updateLayer = null,
  defaultPaint,
  setDefaultPaint
}) => {
  const propData = {}
  const outlineData = {}
  const expressions = []

  const paintProps = clone(layerPaintProps)
  const outlineProps = clone(outLinePaintProps)

  const handleColorChange = (id, value) => {
    let prop
    if (id === 'outline') {
      Object.keys(outlineProps).forEach(key => {
        if (key.includes('color')) prop = key
      })
    } else {
      Object.keys(paintProps).forEach(key => {
        if (key.includes('color')) prop = key
      })
    }
    updateLayer(layerId, prop, value, level, id)
    updateDefaultPaintProps(prop,value)
  }

  const handleInputChange = (id, value, type) => {
    const paintProps = clone(layerPaintProps)
    let prop

    if (id === 'outline') {
      const outlineCopy = clone(outLinePaintProps)
      Object.keys(outlineCopy).forEach(key => {
        if (key.includes('color') === false) {
          if (key.includes(`${type}`)) {
            prop = key

            updateLayer(layerId, prop, value, level, id)
          }
        }
      })
    } else {
      for (const [key, val] of Object.entries(paintProps)) {
        if (key.includes('color') === false) {
          if (key.includes(type)) {
            prop = key
            updateLayer(layerId, prop, value, level, id)
          }
        }
      }
    }
    updateDefaultPaintProps(prop,value)
  }

  const updateDefaultPaintProps = (prop,value) => {
    if(defaultPaint){
      defaultPaint[prop] = value
      setDefaultPaint(defaultPaint)
    }
  }

  for (const [key, val] of Object.entries(paintProps)) {
    if (Array.isArray(val)) {
      expressions.push([key, val])
    } else {
      let k = key.split('-')
      k = k.pop()
      propData[k] = val
    }
  }

  if (outLinePaintProps) {
    for (const [key, val] of Object.entries(outlineProps)) {
      if (Array.isArray(val)) {
        expressions.push([key, val])
      } else {
        let k = key.split('-')
        k = k.pop()
        outlineData[k] = val
      }
    }
  }

  const layerOpacity = (propData.opacity * 100).toFixed(0)
  const outlineOpacity = (outlineData.opacity * 100).toFixed(0)

  let layerColorInput
  let layerLineColorInput
  let layerWidthInput
  let layerOpacityInput
  let layerRadiusInput
  let outlineWidthInput
  let outlineColorInput
  let outlineOpacityInput

  if (databased) {
    layerColorInput = ColorInput(
      propData.color,
      handleColorChange,
      'fill',
      databased
    )
    layerLineColorInput = ColorInput(
      propData.color,
      handleColorChange,
      'fill',
      databased,
      'line'
    )
    layerWidthInput = NumberInput(
      'width',
      handleInputChange,
      'fill',
      propData.width,
      0,
      20,
      0.5,
      databased
    )
    layerOpacityInput = NumberInput(
      'opacity',
      handleInputChange,
      'fill',
      layerOpacity,
      0,
      100,
      10,
      databased
    )
    layerRadiusInput = NumberInput(
      'radius',
      handleInputChange,
      'fill',
      propData.radius,
      0,
      100,
      1,
      databased
    )

    if (outLinePaintProps) {
      outlineColorInput = ColorInput(
        outlineData.color,
        handleColorChange,
        'outline',
        databased
      )
      outlineWidthInput = NumberInput(
        'width',
        handleInputChange,
        'outline',
        outlineData.width,
        0,
        20,
        0.5,
        databased
      )
      outlineOpacityInput = NumberInput(
        'opacity',
        handleInputChange,
        'outline',
        outlineOpacity,
        0,
        100,
        10,
        databased
      )
    }
  } else {
    layerColorInput = ColorInput(propData.color, handleColorChange)
    layerLineColorInput = ColorInput(propData.color, handleColorChange)
    layerWidthInput = NumberInput(
      'width',
      handleInputChange,
      'fill',
      propData.width,
      0,
      20,
      0.5
    )
    layerOpacityInput = NumberInput(
      'opacity',
      handleInputChange,
      'fill',
      layerOpacity,
      0,
      100,
      10
    )
    layerRadiusInput = NumberInput(
      'radius',
      handleInputChange,
      'fill',
      propData.radius,
      0,
      100,
      1
    )

    if (outLinePaintProps) {
      outlineColorInput = ColorInput(
        outlineData.color,
        handleColorChange,
        'outline'
      )
      outlineWidthInput = NumberInput(
        'width',
        handleInputChange,
        'outline',
        outlineData.width,
        0,
        20,
        0.5
      )
      outlineOpacityInput = NumberInput(
        'opacity',
        handleInputChange,
        'outline',
        outlineOpacity,
        0,
        100,
        10
      )
    }
  }

  if (dataType === 'fill') {
    return (
      <div className={scss.simpleFillBorderContainer}>
        <div className={scss.stylesColumn}>
          {/* Fill Element */}
          <span className={databased ? scss.displayNone : scss.stylesHeading}>
            Fill:
          </span>
          <div className={scss.stylesRow}>
            {layerColorInput || null}
            {layerOpacityInput || null}
          </div>
        </div>

        <div className={scss.stylesColumn}>
          {/* Border Element */}
          <span className={databased ? scss.displayNone : scss.stylesHeading}>
            Stroke:
          </span>
          <div className={scss.stylesRow}>
            {outlineColorInput || null}
            {outlineWidthInput || null}
            {outlineOpacityInput || null}
          </div>
        </div>
      </div>
    )
  }
  if (dataType === 'line') {
    return (
      <div>
        <span className={databased ? scss.displayNone : scss.stylesHeading}>
          Line:
        </span>
        <div className={scss.stylesRow}>
          {layerLineColorInput || null}
          {layerWidthInput || null}
          {layerOpacityInput || null}
        </div>
      </div>
    )
  }
  if (dataType === 'circle') {
    return (
      <div className={scss.simpleTripleContainer}>
        <div className={scss.stylesColumn}>
          {/* ----------- Fill Element ----------- */}
          <span className={databased ? scss.displayNone : scss.stylesHeading}>
            Fill:
          </span>
          <div className={scss.stylesRow}>
            {layerColorInput || null}
            {layerOpacityInput || null}
            {layerRadiusInput || null}
          </div>
        </div>
      </div>
    )
  }
  if (dataType === 'raster') {
    return (
      <div className={scss.simpleFillBorderContainer}>
        <div className={scss.stylesColumn}>
          {/* Fill Element */}
          <span className={databased ? scss.displayNone : scss.stylesHeading}>
            Image:
          </span>
          <div className={scss.stylesRow}>{layerOpacityInput || null}</div>
        </div>
      </div>
    )
  }
}
