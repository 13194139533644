import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle, filterChanged } from '../../actions'
import { fromJS } from 'immutable'
import { getLayerConfigUsingTocId } from '../../utilities/dataConfig'

const ApplyFilterToMap = ({
  doApplyFilter,
  currentFilter,
  gridApi,
  children,
}) => {
  const dispatch = useDispatch()
  const dataTable = useSelector(state => state.dataTable)
  const mapStyle = useSelector(state => state.mapStyle)
  const dataConfig = useSelector(state => state.updateDataConfig)

  const getExpressionType = type => {
    if (type === 'contains') {
      return 'in'
    }

    // TODO: FIND Solution for Not Contains.  Probably use index-of == -1
    if (type === 'notContains') {
      dispatch({
        type: 'ALERT',
        payload: {
          error: 'Not Contains Map Filter not yet supported, use Equals',
        },
      })
      return 'in'
    }

    if (type === 'equals') {
      return '=='
    }

    if (type === 'notEqual') {
      return '!='
    }
  }
  const addFitlerToStyleLayer = (style, layerId, expression, filterModel) => {
    style.layers.forEach(layer => {
      if (layer.id === layerId) {
        layer.filter = expression
        layer.metadata.filterModel = filterModel
      }
    })
  }

  const deleteFitlerFromStyleLayer = (style, layerId) => {
    style.layers.forEach(layer => {
      if (layer.id === layerId) {
        delete layer.filter
        delete layer.metadata.filterModel
      }
    })
  }

  useEffect(() => {
    const { tocId } = dataTable
    const tocObject = getLayerConfigUsingTocId(dataConfig, tocId)
    if (!tocObject) return
    const layersArray = tocObject.layersArray
    if (!mapStyle) return
    let style = mapStyle.toJS()

    if (!layersArray) return

    if (doApplyFilter) {
      // Note:  we save the filterModel to the layers metadata
      const filterModel = gridApi.getFilterModel()
      /*
       NOTE: Expressions with the IN Operator take the following form
        ["in", FILTER_VALUE, ["get", FILTER_PROPERTY]]
        so filtering streetname prop that contains MIM would be
        ["in", "MIM", ["get","streetname"]]
        To search for many, wrap with any expression
       [
          "any",
          ["in", "MIM", ["get","streetname"]],
          ["in", "PEOR", ["get","streetname"]]
        ]
      */
      let expression = ['all']
      let containsExpression = []
      for (const prop in currentFilter) {
        if (currentFilter[prop].filterType === 'set') {
          containsExpression.push('in')
          containsExpression.push(['get', prop])
          containsExpression.push(['literal'])
          containsExpression[2].push(currentFilter[prop].values)
          expression.push(containsExpression)
        }
        if (currentFilter[prop].filterType === 'text') {
          if (currentFilter[prop].operator) {
            if (currentFilter[prop].operator) {
              expression =
                currentFilter[prop].operator === 'OR' ? ['any'] : ['all']
              const condition1 = []
              const condition2 = []
              if (currentFilter[prop].condition1) {
                const type = getExpressionType(
                  currentFilter[prop].condition1.type
                )
                condition1.push(type)
                condition1.push([
                  'upcase',
                  currentFilter[prop].condition1.filter,
                ])
                condition1.push(['upcase', ['get', prop]])
              }
              if (currentFilter[prop].condition2) {
                const type = getExpressionType(
                  currentFilter[prop].condition2.type
                )
                condition2.push(type)
                condition2.push([
                  'upcase',
                  currentFilter[prop].condition2.filter,
                ])
                condition2.push(['upcase', ['get', prop]])
              }
              expression.push(condition1)
              expression.push(condition2)
            }
          } else {
            const type = getExpressionType(currentFilter[prop].type)
            containsExpression.push(type)
            containsExpression.push(['upcase', currentFilter[prop].filter])
            containsExpression.push(['upcase', ['get', prop]])
            expression.push(containsExpression)
          }
        }

        containsExpression = []
      }
      if (expression.length > 1) {
        layersArray.forEach(layerObj => {
          if (layerObj.layer)
            addFitlerToStyleLayer(
              style,
              layerObj.layer.id,
              expression,
              filterModel
            )
          if (layerObj.outline)
            addFitlerToStyleLayer(
              style,
              layerObj.outline.id,
              expression,
              filterModel
            )
          if (layerObj.symbol)
            addFitlerToStyleLayer(
              style,
              layerObj.symbol.id,
              expression,
              filterModel
            )
        })
      } else {
        layersArray.forEach(layerObj => {
          if (layerObj.layer)
            deleteFitlerFromStyleLayer(style, layerObj.layer.id)
          if (layerObj.outline)
            deleteFitlerFromStyleLayer(style, layerObj.outline.id)
          if (layerObj.symbol)
            deleteFitlerFromStyleLayer(style, layerObj.symbol.id)
        })
      }
    } else {
      layersArray.forEach(layerObj => {
        if (layerObj.layer) deleteFitlerFromStyleLayer(style, layerObj.layer.id)
        if (layerObj.outline)
          deleteFitlerFromStyleLayer(style, layerObj.outline.id)
        if (layerObj.symbol)
          deleteFitlerFromStyleLayer(style, layerObj.symbol.id)
      })
    }

    dispatch(updateMapStyle(fromJS(style)))
    dispatch(filterChanged())
  }, [currentFilter, doApplyFilter])

  return children
}

export default ApplyFilterToMap
