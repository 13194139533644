import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle, setVisibleLayers } from '../../../actions/index'
import { setLayerVisibility } from '../../../utilities/mapStyle'
import { fromJS } from 'immutable'
import { clone } from '../../../utilities/geospatial'
import { DRAW } from '../../../data/Layers/Auxiliary/Draw'
import { MEASURE } from '../../../data/Layers/Auxiliary/Measure'
import { SELECTED_FEATURES } from "../../../data/Layers/Auxiliary/SelectedFeatures" 
import { DELETE } from '../../../data/Layers/Auxiliary/Delete'
import {
  COORDINATE_SEARCH,
  GEOCODE,
} from '../../../data/Layers/Auxiliary/Search'
import * as mapUtils from '../../../utilities/mapStyle'
import { ReactComponent as ClearAllIcon } from '../../../assets/icons/map/clear-all.svg'
import scss from '../../Map/Map.scss'

const ClearMap = React.memo(({ dataConfig, setClickedFeature, closePopup }) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  let style = mapStyle.toJS()

  const emptyDrawSources = style => {
    DRAW.sourcesArray.forEach(drawSource => {
      let source = mapUtils.getSource(style, drawSource.id)
      source = mapUtils.emptySource(source)
      style.sources[drawSource.id] = source
    })
    return style
  }

  const emptyDeleteSources = style => {
    DELETE.sourcesArray.forEach(deleteSource => {
      let source = mapUtils.getSource(style, deleteSource.id)
      source = mapUtils.emptySource(source)
      style.sources[deleteSource.id] = source
    })
    return style
  }

  const emptyMeasureSources = style => {
    MEASURE.sourcesArray.forEach(measureSource => {
      let source = mapUtils.getSource(style, measureSource.id)
      source = mapUtils.emptySource(source)
      style.sources[measureSource.id] = source
    })
    return style
  }

  const emptySearchSources = style => {
    COORDINATE_SEARCH.sourcesArray.forEach(searchSource => {
      let source = mapUtils.getSource(style, searchSource.id)
      source = mapUtils.emptySource(source)
      style.sources[searchSource.id] = source
    })
    GEOCODE.sourcesArray.forEach(searchSource => {
      let source = mapUtils.getSource(style, searchSource.id)
      source = mapUtils.emptySource(source)
      style.sources[searchSource.id] = source
    })
    return style
  }

  const emptySelectSources = style => {
    SELECTED_FEATURES.sourcesArray.forEach(measureSource => {
      let source = mapUtils.getSource(style, measureSource.id)
      source = mapUtils.emptySource(source)
      style.sources[measureSource.id] = source
    })
    return style
  }


  const doClear = () => {
    // set clicekd feature in map state to null
    setClickedFeature(null)
    // close popup
    closePopup()
    // get all tocLayers and set visibility to none
    let layerIds = []
    const processConfigObj = configObj => {
      if (configObj.children) {
        configObj.children.items.forEach(item => {
          if (item.children) {
            processConfigObj(item)
          } else {
            if (item.layersArray) {
              const layers = []
              item.layersArray.forEach(layerObj => {
                layers.push(layerObj.layer.id)
                if (layerObj.outline) {
                  layers.push(layerObj.outline.id)
                }
                if (layerObj.symbol) {
                  layers.push(layerObj.symbol.id)
                }
              })
              layerIds = [...layerIds, ...layers]
            }
          }
        })
      }
      if (configObj.layersArray) {
        const layers = []
        configObj.layersArray.forEach(layerObj => {
          layers.push(layerObj.layer.id)
          if (layerObj.outline) {
            layers.push(layerObj.outline.id)
          }
          if (layerObj.symbol) {
            layers.push(layerObj.symbol.id)
          }
        })
        layerIds = [...layerIds, ...layers]
      }
    }
    dataConfig['tocLayers'].forEach(config => {
      processConfigObj(config)
    })

    layerIds.forEach(layerId => {
      style = setLayerVisibility(style, layerId, 'none')
    })
    // get clicked feature layer and set filter to show nothing
    let clickedFeatureLayer = style.layers.filter(
      layer => layer.id === 'clicked_feature'
    )
    clickedFeatureLayer[0].source = 'clicked_feature'
    delete clickedFeatureLayer[0]['source-layer']
    clickedFeatureLayer[0].filter = [
      'match',
      ['get', 'id'],
      ['null'],
      true,
      false,
    ]
    style = emptyDrawSources(style)
    style = emptyDeleteSources(style)
    style = emptyMeasureSources(style)
    style = emptySearchSources(style)
    style = emptySelectSources(style)

    dispatch(updateMapStyle(fromJS(style)))
    dispatch(setVisibleLayers(clone([])))
  }

  return (
    <button
      className={scss['map-button']}
      onClick={doClear}
      title='Clear Map'
      data-tip='Clear All'
      data-for='clear-map'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
      style={{ borderBottom: 'solid 0.5px #314d68', borderRadius: '10px 10px 0 0'}}
    >
      <ClearAllIcon />
    </button>
  )
})

export default ClearMap
