import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as utils from '../../../utilities/util'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import checkboxScss from '../../CSSModules/Checkbox.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'
import scss from '../Maps.scss'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const assignUsersMuiTheme = createMuiTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '8px 0',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      label: {
        color: '#fff !important',
      },
    },
    MuiButtonBase: {
      root: {
        // Name of the rule
        height: '33px',
        color: '#fff !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          //Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
  },
})

function SimpleDialog({ mapUsers, mapID, visible }) {
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)
  const [users, setUsers] = useState([])
  const [userList, setUserList] = useState([])

  //Fields and their States
  // const [organizationNameState, setOrganizationName] = useState(organizationName);

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = id => {
    let method
    let url
    let body = {}

    if (id === 'update') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'account/users'
      body = {
        accountID: user.accountID,
      }
      setLoading(true)
    } else if (id === 'save') {
      let processedUserList = []
      userList.forEach(checkedUserID => {
        users.forEach(accountUser => {
          if (accountUser.id == checkedUserID)
            processedUserList.push(checkedUserID)
        })
      })

      method = 'POST'
      url = apis['apiDatabase'].uri + 'map/user/update'
      body = {
        mapID: mapID,
        userID: processedUserList,
      }
      setFetching(true)
    }

    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    return results.map(result => {
      if (fetchObjects[0].url.includes('account/users')) {
        // on updateUser
        if (utils.verifyResult(result)) {
          setUsers(utils.resultReturn(result))
          setLoading(false)
        }
      } else if (fetchObjects[0].url.includes('map/user/update')) {
        // on handleSave
        refreshDetails(user, newUser => {
          dispatch({ type: 'REFRESH', payload: newUser })
          setFetching(false)
          handleOnClose()
        })
      }
      setFetchObjects(null)
    })
  }

  const updateUsers = () => {
    var checkedList = []
    mapUsers.map(map => {
      checkedList.push(parseInt(map.id))
    })

    setUserList(checkedList)
    buildFetchParams('update')
  }

  useEffect(() => {
    updateUsers()
  }, [user])

  const handleSave = () => {
    buildFetchParams('save')
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const handleCheck = e => {
    var isChecked = e.target.checked
    var val = e.target.value
    //console.log(isChecked,val)

    val = parseInt(val)

    if (!userList.includes(val)) {
      setUserList([...userList, val])
    } else {
      var newVal = userList
      newVal = newVal.filter(function (item) {
        return item !== val
      })
      setUserList(newVal)
    }
  }

  const getUser = u => {
    var checked = userList.includes(u.id)

    return (
      // <div className={scss.userAssignUsers}>
      //   <div>
      //     <div>
      //       <input type="checkbox" defaultChecked={checked} value={u.id} onChange={handleCheck}/>
      //     </div>
      //     <div>
      //       <span className={scss.assignUserName}> {u.displayName} </span>
      //     </div>
      //   </div>
      //   <div>
      //     <span className={scss.assignUserRole}>{u.isAdmin ? 'Admin' : 'User'}</span>
      //   </div>
      // </div>
      <div key={'assignUser_' + u.id} className={modalScss.selectMultipleRow}>
        <div>
          <div>
            {/* ALT CUSTOM CHECKBOX BELOW */}
            <label
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
            >
              <input
                className={checkboxScss.checkbox}
                type='checkbox'
                defaultChecked={checked}
                value={u.id}
                onChange={handleCheck}
              />
              <span
                className={[
                  checkboxScss.checkboxMark,
                  checkboxScss.altCheckboxMark,
                ].join(' ')}
              ></span>
            </label>
          </div>

          <div className={modalScss.selectRowContainer}>
            <span className={modalScss.selectRowSm}>{u.displayName}</span>
          </div>
        </div>

        <div>
          <span className={modalScss.selectRowTitle}>
            {u.isAdmin
              ? 'Admin'
              : u.firstName === u.emailAddress
              ? 'Invited'
              : 'User'}
          </span>
        </div>
      </div>
    )
  }

  var usersArea
  if (!loading && !users.length) {
    usersArea = <p>No users found for this account</p>
  } else {
    usersArea = users.map(u => getUser(u))
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-user-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='assign-user-dialog'>
          Assign Map to Users
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <p>Users</p>
          <div className={modalScss.selectMultipleBox}>
            {loading && (
              <>
                <Skeleton height={59} count={12} />
              </>
            )}

            {usersArea}
          </div>
        </DialogContent>

        <DialogActions>
          <button className={modalScss.modalAltButton} onClick={handleOnClose}>
            Cancel
          </button>

          <button
            className={modalScss.modalMainButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? <CircularProgress size={10} /> : 'Save'}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AssignUsersModal(props) {
  return <SimpleDialog {...props} />
}
