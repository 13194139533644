import * as geoUtils from './geospatial'
import {
  POINT_FEATURE,
  LINE_FEATURE,
  POLYGON_FEATURE,
  FEATURE_COLLETION,
} from '../data/Constants/Constants'
import WebMercatorViewport from 'viewport-mercator-project'
import { clone } from './geospatial'

export const getLayerIndex = (style, layerId) => {
  let layerIdx = null
  style.layers.forEach((layer, index) => {
    if (layer && layer.id === layerId) {
      layerIdx = index
    }
  })
  return layerIdx
}

export const getLayerType = (mapStyle, layerId) => {}

export const getLayerStyleLayerObject = (mapStyle, layerId) => {
  // layerId can be 'layername_1234' OR 'layer_name_1234_outline'
  // so we have to pop the last val after split, and if it is not a number
  // pop again
  const layerIdArray = layerId.split('_')
  let idNumber = layerIdArray.pop()
  // eslint-disable-next-line
  if (isNaN(idNumber)) idNumber = layerIdArray.pop()

  const layerArray = mapStyle.layers.filter(layer => {
    const targetLayerIdArray = layer.id.split('_')
    let targetLayerID = targetLayerIdArray.pop()
    // eslint-disable-next-line
    if (isNaN(targetLayerID) && targetLayerIdArray.length)
      targetLayerID = targetLayerIdArray.pop()
    // eslint-disable-next-line
    if (isNaN(targetLayerID)) return null
    if (idNumber === targetLayerID) {
      return geoUtils.clone(layer)
    }
    return null
  })

  if (layerArray.length) return layerArray
  return null
}

export const getLayerObject = (mapStyle, layerId) => {
  const layerObject = mapStyle.layers.filter(layer => {
    if (layer.id === layerId) {
      return geoUtils.clone(layer)
    }
  })
  return layerObject[0] || null
}

export const getLayerObjects = (hotLayers, style) => {
  return hotLayers.map(layer => {
    let hotLayer = getLayerObject(style, layer.hot)
    let coldLayer = getLayerObject(style, layer.cold)
    return { hot: hotLayer, cold: coldLayer }
  })
}

export const updateLayer = (mapStyle, layerId, newLayerObject) => {
  mapStyle.layers = mapStyle.layers.map((layer, index) => {
    if (layer.id === layerId) return newLayerObject
    return layer
  })
  return mapStyle
}

export const getSource = (mapStyle, sourceId) => {
  let source = geoUtils.clone(mapStyle.sources[sourceId])
  return source
}

export const addPointToVerticies = (hotSource, coordinates) => {
  const point = geoUtils.clone(POINT_FEATURE)
  point.geometry.coordinates = coordinates
  hotSource.data.features = [...hotSource.data.features, point]
}

export const addDataToDrawSource = (
  hotSource,
  verticies,
  coordinates,
  sourceId
) => {
  let source = geoUtils.clone(hotSource)
  if (sourceId === 'draw_line_hot') {
    if (!verticies.data.features) return source
    let verticieCount = verticies.data.features.length
    if (verticieCount === 1) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      const line = geoUtils.clone(LINE_FEATURE)
      line.geometry.coordinates.push(coords[0])
      source.data.features.push(line)
    }
    if (verticieCount > 1) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      const line = geoUtils.getLineString(coords)
      source.data.features[0] = line
    }
  }

  if (sourceId === 'draw_polygon_outline_hot') {
    if (!verticies.data.features) return source
    let verticieCount = verticies.data.features.length
    if (verticieCount === 1) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      const line = geoUtils.clone(LINE_FEATURE)
      line.geometry.coordinates.push(coords[0])
      source.data.features.push(line)
    }
    if (verticieCount > 1) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      let firstCoord = coords[0]
      coords.push(firstCoord)
      const line = geoUtils.getLineString(coords)
      source.data.features[0] = line
    }
  }

  if (sourceId === 'draw_polygon_hot') {
    let featureCount = source.data.features.length
    if (featureCount === 0) {
      const polygon = geoUtils.clone(POLYGON_FEATURE)
      polygon.geometry.coordinates.push([coordinates])
      source.data.features.push(polygon)
    } else {
      source.data.features[0].geometry.coordinates[0].push(coordinates)
    }
  }

  if (sourceId === 'draw_text_hot') {
    const point = geoUtils.clone(POINT_FEATURE)
    point.geometry.coordinates = coordinates
    point.properties = {}
    point.properties.label = ''
    source.data.features = [...source.data.features, point]
  }

  if (sourceId === 'draw_find_vertex_buffer') {
    const polygon = geoUtils.clone(POLYGON_FEATURE)
    polygon.geometry.coordinates.push(coordinates)
    source.data.features = [polygon]
  }

  if (sourceId === 'draw_clicked_verticie') {
    const point = geoUtils.clone(POINT_FEATURE)
    point.geometry.coordinates = coordinates
    source.data.features = [point]
  }
  return source
}

export const createPointFeature = (coordinates, properties) => {
  const point = geoUtils.clone(POINT_FEATURE)
  point.geometry.coordinates = coordinates
  point.properties = properties
  return point
}

// TODO - add create polygwon and creat line functuions

export const addFeatureToSource = (originalSource, feature) => {
  const source = geoUtils.clone(originalSource)
  source.data.features.push(feature)
  return source
}

export const moveHotToCold = (
  hotSource,
  coldSource,
  coldSourceFeatureIndex = null
) => {
  // add to cold data
  if (!coldSource) return
  if (coldSourceFeatureIndex !== null) {
    hotSource.data.features[0].id = coldSourceFeatureIndex
    coldSource.data.features[coldSourceFeatureIndex] =
      hotSource.data.features[0]
  } else {
    hotSource.data.features.forEach(feature => {
      feature.id = coldSource.data.features.length
      coldSource.data.features.push(feature)
    })
  }
  hotSource.data.features = []
}

export const updateLastData = (mapSource, verticies, coordinates, sourceId) => {
  let source = geoUtils.clone(mapSource)
  // note: we recreate all features using verticies and adding coordinates
  if (sourceId === 'draw_line_hot') {
    if (verticies.data.features.length > 0) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      const line = geoUtils.getLineString([...coords, ...[coordinates]])
      source.data.features[0] = line
    }
  }
  if (sourceId === 'draw_polygon_outline_hot') {
    if (verticies.data.features.length > 0) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      coords = [...coords, ...[coordinates]]
      let firstCoord = coords[0]
      coords.push(firstCoord)
      const line = geoUtils.getLineString(coords)
      source.data.features[0] = line
    }
  }
  if (sourceId === 'draw_polygon_hot') {
    if (verticies.data.features.length > 1) {
      let coords = verticies.data.features.map(
        feature => feature.geometry.coordinates
      )
      coords = [...coords, ...[coordinates]]
      let firstCoord = coords[0]
      coords.push(firstCoord)
      const poly = geoUtils.getPolygon([coords])
      source.data.features[0] = poly
    }
  }

  return source
}

export const replaceVerticie = (
  dataSource,
  type,
  vertices,
  newPoint,
  index,
  sourceId
) => {
  let source = geoUtils.clone(dataSource)
  let point = geoUtils.clone(newPoint)
  if (type === 'fill') {
    const first = 0
    const last = vertices.length - 1

    if (index === first || index === last) {
      // first or last index in polygon is being updated
      // to create valid polygon first and last index must be identical
      // ensure valid polygon by updating both index's
      vertices[first] = point
      vertices[last] = point
    }
    vertices[index] = point
    const coords = vertices.map(vert => vert.geometry.coordinates)
    const poly = geoUtils.getPolygon([coords])
    if (dataSource.data.features[0] && dataSource.data.features[0].properties) {
      poly.properties = dataSource.data.features[0].properties
    }
    source.data.features[0] = poly
  }
  if (type === 'line') {
    vertices[index] = point
    const coords = vertices.map(vert => vert.geometry.coordinates)
    const line = geoUtils.getLineString(coords)
    if (dataSource.data.features[0] && dataSource.data.features[0].properties) {
      line.properties = dataSource.data.features[0].properties
    }
    source.data.features[0] = line
  }
  if (type === 'circle') {
    vertices[index] = point
    if (dataSource.data.features[0] && dataSource.data.features[0].properties) {
      vertices[index].properties = dataSource.data.features[0].properties
      if (newPoint.properties.isSnapPoint) {
        vertices[index].properties['isSnapPoint'] = true
      }
    }
    source.data.features = vertices
  }
  return source
}

export const addVerticieBetweenCoords = (
  dataSource,
  feature,
  newCoords,
  coords1
) => {
  let source = geoUtils.clone(dataSource)
  if (feature.geometry.type === 'Polygon') {
    let coords = feature.geometry.coordinates[0]

    let updatedCoords = []
    let updated = false
    coords.forEach(coord => {
      updatedCoords.push(coord)
      if (coord.join(',') === coords1) {
        if (!updated) updatedCoords.push(newCoords)
        updated = true
      }
    })
    const poly = geoUtils.getPolygon([updatedCoords])
    source.data.features[0] = poly
  }
  return source
}

export const emptySource = source => {
  const fc = geoUtils.clone(FEATURE_COLLETION)
  source.data = fc
  return source
}

export const mouseClickToCoordinates = (x, y, vp) => {
  if (!vp) return
  const round = (value, decimals) =>
    Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  const wmv = new WebMercatorViewport(vp)
  return wmv.unproject([x, y]) //.map(coord => round(coord, 7));
}

export const setLayerVisibility = (style, layerId, visible) => {
  let newStyle = geoUtils.clone(style)
  newStyle.layers.forEach(layer => {
    if (layer.id === layerId) {
      layer.layout.visibility = visible
    }
  })
  return newStyle
}

export const getVisibleTocLayers = (style, tocLayers) => {
  // find each layer in the toc that is visible and
  let visibleLayers = style.layers
    .filter(
      styleLayer =>
        styleLayer.layout && styleLayer.layout.visibility === 'visible'
    )
    .map(styleLayer => styleLayer.id)
  let visibleTocLayers = []
  const checkVisibility = layersArray => {
    let visible = false
    layersArray.forEach(layerObj => {
      if (visibleLayers.includes(layerObj.layer.id)) {
        visible = true
      }
    })
    return visible
  }
  const processTocLayer = tocLayer => {
    if (tocLayer.layersArray) {
      const visible = checkVisibility(tocLayer.layersArray)
      if (visible) {
        visibleTocLayers.push(tocLayer.toc.id)
      }
    }
    if (tocLayer.children) {
      tocLayer.children.items.forEach(tocItem => {
        if (tocItem.groupId) {
          processTocLayer(tocItem)
        } else {
          if (tocItem.layersArray) {
            const visible = checkVisibility(tocItem.layersArray)
            if (visible) {
              visibleTocLayers.push(tocItem.toc.id)
            }
          }
        }
      })
    }
  }
  tocLayers.forEach(tocLayer => {
    processTocLayer(tocLayer)
  })

  return visibleTocLayers
}

export const getVisibleImageryLayers = (style, imageryLayers) => {
  // find each layer in the toc that is visible and
  let visibleLayers = style.layers
    .filter(
      styleLayer =>
        styleLayer.layout && styleLayer.layout.visibility === 'visible'
    )
    .map(styleLayer => styleLayer.id)
  let visibleImageryLayers = []
  imageryLayers
    .filter(tocObject => tocObject.layersArray)
    .forEach(tocObject => {
      return tocObject.layersArray.filter(layerObject => {
        if (visibleLayers.includes(layerObject.layer.id)) {
          visibleImageryLayers.push(tocObject.toc.id)
        }
      })
    })
  return visibleImageryLayers
}

export const removeDuplicatesFromArray = arr => {
  let unique = arr
  unique = Array.from(new Set(arr.map(JSON.stringify)), JSON.parse)
  return unique
}

export const generateUID = () => {
  // Generate the UID from two parts
  // to ensure the random number provide enough bits.
  let firstPart = (Math.random() * 46656) | 0
  let secondPart = (Math.random() * 46656) | 0
  firstPart = ('000' + firstPart.toString(36)).slice(-3)
  secondPart = ('000' + secondPart.toString(36)).slice(-3)
  return firstPart + secondPart
}

export const addLayerToStyle = (style, layerObj, visible = 'none') => {
  // get before layer
  let beforeLayer = layerObj.beforeLayer
  // set beforeLayerIndex to the end of the layers array
  // if there is no before layer, the layer will be added to the top of the layer stack
  let beforeLayerIndex = style.layers.length - 1
  if (beforeLayer) {
    // if there is a before layer get its current index in the layers array
    style.layers.forEach((layer, index) => {
      if (layer && layer.id === beforeLayer) {
        beforeLayerIndex = index + 1
      }
    })
  }
  Object.values(layerObj)
    .filter(layer => layer && layer.id)
    .forEach(layer => {
      if (layer.filter && layer.filter.constructor === Object) {
        // layers filters are being returned from api as object, per spec they must be array, delete
        delete layer.filter
      }
      layer.layout.visibility = visible
      style.layers.splice(beforeLayerIndex, 0, layer)
      beforeLayerIndex++
    })
  return style
}

export const removeLayersFromStyle = (style, layerId) => {
  let styleClone = clone(style)
  let layers = styleClone.layers.filter(layer => {
    return layer.id.substr(layer.id.lastIndexOf('_')) !== layerId
  })

  styleClone.layers = layers
  return styleClone
}

export const addSourceToStyle = (style, source, sourceIdFromLayer) => {
  // get source id
  let id = source.id
  // note: there is a bug in the profile layer tool that adds a source without an id
  // when this happens an undefined source is added to the style and will break the app
  // to prevent this from happening we send in the sourceIdFromLayer to use as the source id
  // TODO: find out what is casuing the source to sometimes not have an id
  if (!id) id = sourceIdFromLayer

  // delete source id prior to adding to style
  delete source.id
  if (typeof style.sources !== 'undefined') {
    style.sources[id] = source
  }
  return style
}

export const addTocLayersToMapStyle2 = (
  mapStyle,
  oldStyle,
  dataConfig,
  visibleLayers = []
) => {
  const addLayers = (layersArray, style) => {
    layersArray.forEach(layerObj => {
      if (oldStyle) {
        // if old style get old layer and use it to define props for layer in new style
        // this is done to preserve layer props like visibiltiy, filter, and metadata
        let oldStyleLayer = oldStyle.layers.filter(
          oldLayer => oldLayer.id === layerObj.layer.id
        )

        let layout = oldStyleLayer[0].layout
        let paint = oldStyleLayer[0].paint
        let filter = oldStyleLayer[0].filter || []
        let metadata = oldStyleLayer[0].metadata

        layerObj.layer.layout = layout
        layerObj.layer.paint = paint
        layerObj.layer.filter = filter
        layerObj.layer.metadata = metadata

        if (layerObj.layer.filter && !layerObj.layer.filter.length) {
          delete layerObj.layer.filter
        }

        style = addLayerToStyle(style, layerObj, layout.visibility)
      } else {
        let visible = 'none'

        if (visibleLayers && visibleLayers.includes(layerObj.layer.id)) {
          visible = 'visible'
        }

        if (layerObj.layer.filter && !layerObj.layer.filter.length) {
          delete layerObj.layer.filter
        }
        style = addLayerToStyle(style, layerObj, visible)
      }
    })

    return style
  }

  const addSources = (sourcesArray, style, sourceId) => {
    sourcesArray.forEach(source => {
      if (source.tiles) {
        // if source is a tile url and comming from https://tiles.myassetmap.com
        // bust the browser cache for each tile url by add dt=${Date.now() as query param
        source.tiles = source.tiles.map(tileUrl => {
          if (tileUrl.includes(`https://tiles.myassetmap.com`)) {
            if (tileUrl.indexOf('?') === -1) {
              return tileUrl + `?dt=${Date.now()}`
            } else {
              let url = tileUrl.replace(/&dt.*$/, '')
              return url + `&dt=${Date.now()}`
            }
          }
          return tileUrl
        })
      }
      style = addSourceToStyle(style, source, sourceId)
    })
    return style
  }

  const processConfigObj = config => {
    // add groups
    if (config.children) {
      // Note: reversing the tocLayers is needed to maintain proper layer order
      config.children.items.reverse().forEach(item => {
        if (item.children) {
          processConfigObj(item)
        } else {
          if (item.layersArray) {
            newMapStyle = addLayers(item.layersArray, newMapStyle)
          }
          if (item.sourcesArray) {
            const sourceId = item.layersArray[0].layer.source
            newMapStyle = addSources(item.sourcesArray, newMapStyle, sourceId)
          }
        }
      })
    }
    // add layers
    if (config.layersArray) {
      newMapStyle = addLayers(config.layersArray, newMapStyle)
    }
    // add sources
    if (config.sourcesArray) {
      if (config.layersArray[0]) {
        const sourceId = config.layersArray[0].layer.source
        newMapStyle = addSources(config.sourcesArray, newMapStyle, sourceId)
      } else console.warn('Invalid Layer (Source) Identified: ', config)
    }
  }

  let newMapStyle = mapStyle
  // Note: reversing the tocLayers is needed to maintain proper layer order
  dataConfig['tocLayers'].reverse().forEach(config => {
    processConfigObj(config)
  })

  return newMapStyle
}
