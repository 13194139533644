import { ALERT } from '../actions/types'

export default function AlertReducer(state = null, action) {
  switch (action.type) {
    case ALERT: {
      return action.payload
    }
    default:
      return state
  }
}
