import { SET_MAP_MODE } from '../actions/types'
export default function SetMapMode(state = 'MAP', action) {
  switch (action.type) {
    case SET_MAP_MODE: {
      return action.payload
    }
    default:
      return state
  }
}
