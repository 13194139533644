import { SET_MAP_STYLE, TOGGLE_MAP_STYLE, UPDATE_MAP_STYLE } from './types'

export function setMapStyle(style) {
  return {
    type: SET_MAP_STYLE,
    payload: style,
  }
}

export function toggleMapLayer(ids) {
  return {
    type: 'TOGGLE_MAP_LAYER',
    payload: ids,
  }
}

export function toggleMapLayersOn(ids) {
  return {
    type: 'TOGGLE_MAP_LAYERS_ON',
    payload: ids,
  }
}

export function toggleMapLayersOff(ids) {
  return {
    type: 'TOGGLE_MAP_LAYERS_OFF',
    payload: ids,
  }
}

export function toggleMapStyle(style) {
  return {
    type: TOGGLE_MAP_STYLE,
    payload: style,
  }
}

export function updateMapStyle(mapStyle) {
  return {
    type: UPDATE_MAP_STYLE,
    payload: mapStyle,
  }
}
