/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './PopupFooterV2.scss'

const PopupFooterV2 = ({
  totalTabPageNumber,
  tabsPerPage,
  currentPage,
  setCurrentPage,
  setActiveTabIndex,
  setDisplayPopUpSettings,
  isCommonLayer,
}) => {
  const nextPage = () => {
    if (currentPage + 1 <= totalTabPageNumber) {
      setCurrentPage(currentPage + 1)
      setActiveTabIndex(currentPage * tabsPerPage)
    }
  }

  const prevPage = () => {
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1)
      setActiveTabIndex((currentPage - 2) * tabsPerPage)
    }
  }

  const handleDisplayPopupSettings = () => {
    setDisplayPopUpSettings(true)
  }

  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'default',
      }}
    >
      <span>
        <button type='button' onClick={prevPage} className={scss.ArrowButton}>
          {'< '}
        </button>
        {currentPage}/{totalTabPageNumber}
        <button type='button' onClick={nextPage} className={scss.ArrowButton}>
          {' >'}
        </button>
      </span>
      {isCommonLayer ? (
        ''
      ) : (
        <button
          type='button'
          style={{ padding: '10px' }}
          onClick={handleDisplayPopupSettings}
          className={scss.EllipsisMenu}
          title='Create/edit Tabs'
        >
          <FontAwesomeIcon icon='ellipsis-v' />
        </button>
      )}
    </span>
  )
}

export default PopupFooterV2
