import { SET_MEASURE_MODE } from '../actions/types'

export default function MeasureMode(state = null, action) {
  switch (action.type) {
    case SET_MEASURE_MODE: {
      return action.payload
    }
    default:
      return state
  }
}
