export const DRAW = {
  sourcesArray: [
    {
      id: 'draw_verticies_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_verticies_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_midpoints_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_line_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_line_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_polygon_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_polygon_outline_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_polygon_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_polygon_outline_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_text_hot',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_text_cold',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_find_vertex_buffer',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
    {
      id: 'draw_clicked_verticie',
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    },
  ],
  layersArray: [
    {
      beforeLayer: null,
      layer: {
        id: 'draw_polygon_cold',
        type: 'fill',
        source: 'draw_polygon_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-color': '#e6e9ed',
          'fill-opacity': 0.5,
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_polygon_outline_cold',
        type: 'line',
        source: 'draw_polygon_outline_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#ffbb00',
          'line-opacity': 1,
          'line-width': 2,
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_polygon_hot',
        type: 'fill',
        source: 'draw_polygon_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-color': '#e6e9ed',
          'fill-opacity': 0.5,
          'fill-outline-color': 'black',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_polygon_outline_hot',
        type: 'line',
        source: 'draw_polygon_outline_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': 'black',
          'line-opacity': 1,
          'line-width': 2,
          'line-dasharray': [1, 2],
        },
        metadata: {},
      },
    },

    {
      beforeLayer: null,
      layer: {
        id: 'draw_line_cold',
        type: 'line',
        source: 'draw_line_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': '#ffbb00',
          'line-width': 2,
          'line-opacity': 1,
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_line_hot',
        type: 'line',
        source: 'draw_line_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'line-color': 'black',
          'line-opacity': 1,
          'line-width': 2,
          'line-dasharray': [1, 2],
        },
        metadata: {},
      },
    },

    {
      beforeLayer: null,
      layer: {
        id: 'draw_verticies_cold',
        type: 'circle',
        source: 'draw_verticies_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
       paint: {
          'circle-color': '#FF6C00',
          'circle-radius': 5,
          'circle-opacity': 0.5,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#000000',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_verticies_cold_halo',
        type: 'circle',
        source: 'draw_verticies_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
       paint: {
          'circle-color': '#FF6C00',
          'circle-radius': 7,
          'circle-opacity': 0.0,
          'circle-stroke-width': 1,
          'circle-stroke-color': 'black',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_verticies_hot',
        type: 'circle',
        source: 'draw_verticies_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'blue',
          'circle-radius': 5,
          'circle-opacity': 0.8,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#000000',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_midpoints_hot',
        type: 'circle',
        source: 'draw_midpoints_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'red',
          'circle-radius': 3,
          'circle-opacity': 0.8,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#000000',
        },
        metadata: {},
      },
    },
    {
      layer: {
        id: 'draw_text_hot',
        type: 'symbol',
        source: 'draw_text_hot',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': '{label}',
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            14,
            10,
            15,
            13,
            16,
          ],
        },
        paint: {
          'text-color': '#FFFFFF',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': 'hsl(224, 2%, 18%)',
        },
        metadata: {
          popup: {
            title: 'Text Input',
            buttons: [
              {
                name: 'SaveInputText',
                onClick: 'saveInputText',
                label: '',
                params: [
                  { paramName: 'targetLayer', paramValue: 'plot_maps' },
                  {
                    paramName: 'source',
                    paramValue: ['getMapStyleProp', 'draw_text_hot'],
                  },
                ],
                faIcon: 'check',
              },
            ],
          },
        },
      },
    },
    {
      layer: {
        id: 'draw_text_cold',
        type: 'symbol',
        source: 'draw_text_cold',
        interactive: false,
        layout: {
          visibility: 'visible',
          'symbol-placement': 'point',
          'text-anchor': 'top',
          'text-rotation-alignment': 'map',
          'text-field': [
            'format',
            ['get', 'measurement'],
            {},
            '\n',
            {},
            ['get', 'measurementSecondLine'],
            {},
          ],
          'text-font': ['Noto Sans Regular'],
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9,
            11,
            10,
            13,
            13,
            14,
            14,
            15,
            15,
            16,
          ],
        },
        paint: {
          'text-color': '#FFFFFF',
          'text-halo-width': 2,
          'text-halo-blur': 0,
          'text-halo-color': 'hsl(224, 2%, 18%)',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_find_vertex_buffer',
        type: 'fill',
        source: 'draw_find_vertex_buffer',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-color': 'green',
          'fill-opacity': 0.2,
          'fill-outline-color': 'black',
        },
        metadata: {},
      },
    },
    {
      beforeLayer: null,
      layer: {
        id: 'draw_clicked_verticie',
        type: 'circle',
        source: 'draw_clicked_verticie',
        interactive: false,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-color': 'white',
          'circle-radius': 8,
          'circle-opacity': 0.1,
          'circle-stroke-width': ['case', ['has', 'isSnapPoint'], 2, 1],
          'circle-stroke-color': [
            'case',
            ['has', 'isSnapPoint'],
            'red',
            'black',
          ],
        },
        metadata: {},
      },
    },
  ],
}
