/**
 * Ideally, the given tab should come with its attributes but because we don't live in a perfect world,
 * and I didn't code this, we have to obtain it from here.
 */
export const getAttributesThatBelongToTab = (tab, attributes) => {
  if (!tab || tab.length === 0) return null
  if (!attributes || attributes.length === 0) return null

  let thisTabAttributes = []
  const itemsIds = tab.itemsIds.map(itemId => parseInt(itemId))

  attributes.forEach(att => {
    if (itemsIds.includes(parseInt(att.id))) thisTabAttributes.push(att)
  })

  /// Sort based on user preference
  thisTabAttributes = thisTabAttributes.sort(
    (a, b) =>
      itemsIds.indexOf(parseInt(a.id)) - itemsIds.indexOf(parseInt(b.id))
  )
  

  return thisTabAttributes
}
