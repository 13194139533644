import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import blueGrey from '@material-ui/core/colors/blueGrey'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiSelect-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiInput-root': {
      fontSize: 14,
      lineHeight: 0,
    },
    '& .MuiFormLabel-root': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'left',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
    '& .MuiInputBase-root': {
      width: '100%',
      lineHeight: 0,
    },
    '& .MuiButton-root': {
      fontSize: 14,
    },
  },
  buttonGroupCentered: {
    '& .MuiButtonGroup-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiButtonBase-root': {
      fontSize: 12,
    },
  },
  buttonGroupLeft: {
    '& .MuiButtonGroup-root': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    '& .MuiButtonBase-root': {
      fontSize: 12,
    },
  },
  multiline: {
    '& .MuiTextField-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiSelect-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiInput-root': {
      fontSize: 14,
      lineHeight: 0,
    },
    '& .MuiFormLabel-root': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: '#1A2937',
  },
  palette: {
    secondary: red,
  },
  palette: {
    default: blueGrey,
  },
  typography: {
    button: {
      fontSize: '1rem',
    },
  },
  spacing: 4,
})

export const MuiButtonGroup = ({
  center,
  variant,
  color,
  buttons,
  fullWidth,
}) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <div
        className={
          center ? classes.buttonGroupCentered : classes.buttonGroupLeft
        }
        noValidate
        autoComplete='off'
      >
        {fullWidth ? (
          <ButtonGroup variant={variant} color={color} fullWidth display='flex'>
            {buttons.map(button => {
              return (
                <Button key={button.key} onClick={button.onClick}>
                  {button.label}
                </Button>
              )
            })}
          </ButtonGroup>
        ) : (
          <ButtonGroup variant={variant} color={color} display='flex'>
            {buttons.map(button => {
              return (
                <Button key={button.key} onClick={button.onClick}>
                  {button.label}
                </Button>
              )
            })}
          </ButtonGroup>
        )}
      </div>
    </ThemeProvider>
  )
}

export const MuiButton = ({ variant, onClick, label, color, size }) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Button variant={variant} onClick={onClick} color={color} size={size}>
          {label}
        </Button>
      </div>
    </ThemeProvider>
  )
}

export const MuiFormControlText = ({
  onChange,
  onSubmit,
  value,
  label,
  placeholder,
  disabled,
  type = 'text',
  required = false,
  min = null,
  max = null,
}) => {
  const classes = useStyles()
  const onConfirm = e => {
    e.preventDefault()

    if (onSubmit) onSubmit()
  }

  return disabled ? (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.root}
        noValidate
        autoComplete='off'
      >
        <TextField
          disabled
          label={label}
          value={value}
          variant='outlined'
          onChange={onChange}
          size='small'
          placeholder={placeholder}
          margin='normal'
          InputLabelProps={{
            shrink: true,
          }}
          required={required}
          type={type}
          min={min}
          max={max}
        />
      </form>
    </Box>
  ) : (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.root}
        noValidate
        autoComplete='off'
      >
        <TextField
          label={label}
          value={value}
          variant={'outlined'}
          onChange={onChange}
          size='small'
          placeholder={placeholder}
          margin='normal'
          InputLabelProps={{
            shrink: true,
          }}
          type={type}
          required={required}
          min={min}
          max={max}
        />
      </form>
    </Box>
  )
}

export const MuiFormControlTextMultiline = ({
  onChange,
  onSubmit,
  value,
  label,
  placeholder,
}) => {
  const classes = useStyles()
  const onConfirm = e => {
    e.preventDefault()

    if (onSubmit) onSubmit()
  }

  return (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.multiline}
        noValidate
        autoComplete='off'
      >
        <TextField
          label={label}
          value={value}
          variant={'outlined'}
          onChange={onChange}
          size='small'
          margin='normal'
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows='4'
        />
      </form>
    </Box>
  )
}

// TODO: DEPRECATED
// We are now using React Select
export const MuiFormControlSelect = ({
  onChange,
  onSubmit,
  selected,
  inputLabel,
  selectOptions,
  disabled,
  placeholder,
}) => {
  const classes = useStyles()
  const onConfirm = e => {
    e.preventDefault()

    if (onSubmit) onSubmit()
  }

  if (!disabled) disabled = false
  if (!placeholder) placeholder = ''

  return (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.root}
        noValidate
        autoComplete='off'
      >
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          disabled={disabled}
          variant={'outlined'}
          displayEmpty
          value={selected && selected.value ? selected.value : ''}
          onChange={onChange}
        >
          {placeholder == '' ? (
            ''
          ) : (
            <MenuItem value='' disabled>
              {placeholder}
            </MenuItem>
          )}
          {selectOptions.map(option => {
            return (
              <MenuItem
                key={option.value}
                style={{ fontSize: 14 }}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </form>
    </Box>
  )
}
