import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlyToInterpolator } from 'react-map-gl'
import { Alert, setViewPort } from '../../actions/index'
import WebMercatorViewport from 'viewport-mercator-project'

const ZoomToBounds = ({bounds}) => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)

  const doZoom = (latitude, longitude, zoom, width, height) => {
    const vp = {
      latitude: latitude,
      longitude: longitude,
      zoom: zoom,
      bearing: 0,
      pitch: 0,
      width: width,
      height: height,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    }
    // set the map viewPort with new viewPort and transition map using the FlyToInterpolator
    dispatch(setViewPort(vp))
  }


  const getLatLngZoom =  padding => {
    try {
      const { longitude, latitude, zoom, width, height } =
        new WebMercatorViewport(viewport).fitBounds(bounds, {
          padding: padding,
          offset: [0, 0],
        })

      doZoom(latitude, longitude, zoom, width, height)
    } catch (err) {
      switch (err.message) {
        case 'invalid latitude':
          dispatch(Alert({
            error: `Unable to zoom to bounds: Invalid Bounds — The projection on this layer is invalid. Please check the projection and/or contact MyAssetMap Support for assistance.`,
          }))
          break

        default:
          dispatch(Alert({
            error: 'Unable to zoom to bounds: ' + err.message,
          }))
          break
      }
    }
  }


  useEffect(()=>{
    const padding = 20
    if (bounds) {
      getLatLngZoom( padding)
    }
  },[])

  return null
}

export default ZoomToBounds