import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable } from 'react-beautiful-dnd'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'

// scss files
import scss from '../../Toc/Group/Group.scss'

const MapViewGroup = ({
  group,
  groupViews,
  getIndView,
  getViewGroup,
  onSettingsClick,
  activePanel,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleGroup = () => setIsOpen(!isOpen)
  const dataPanelIcon = isOpen ? 'chevron-up' : 'chevron-down'

  const views = groupViews.map((view, index) => {
    if (view && view.id.substr(0, 6) === 'group_') {
      return getViewGroup(view, index)
    } else {
      return getIndView(view, index)
    }
  })

  return (
    <div className={scss['layer-group']} id={group.groupId}>
      <div onClick={toggleGroup}>
        <div
          className={[scss['layer-group-row'], scss['map-view-group-row']].join(
            ' '
          )}
        >
          <span
            className={[
              scss['ellipsis'],
              scss['layer-group-label'],
              scss['map-view-group-label'],
            ].join(' ')}
          >
            {group.label}
          </span>

          {/* BUTTON SHOWING WHO SHARED IS BELOW
          <button
            className={[scss.btn, scss.groupShareButton].join(" ")}
          >
            <FontAwesomeIcon className={scss.toggleIcon} icon={groupShareIcon} size="1x" />
          </button> */}

          <button
            onClick={() => onSettingsClick(group)}
            className={[
              scss['layer-group-button'],
              scss['layer-group-button-settings'],
              scss[activePanel && activePanel == group.groupId ? 'active' : ''],
            ].join(' ')}
          >
            <FontAwesomeIcon icon='cog' size='1x' />
          </button>

          <button
            className={[
              scss['layer-group-button'],
              scss['layer-group-button-collapse'],
            ].join(' ')}
            onClick={toggleGroup}
          >
            <FontAwesomeIcon icon={dataPanelIcon} size='1x' />
          </button>
        </div>
      </div>
      <Collapse className={scss['group-collapse']} in={isOpen}>
        <Paper
          className={[scss['group-collapse-paper']].join(' ')}
          elevation={4}
        >
          <Droppable droppableId={group.groupId} type='inner'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                placeholder={provided.placeholder}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? 'none' : 'none',
                }}
                {...provided.droppableProps}
              >
                {views}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Paper>
      </Collapse>
    </div>
  )
}

export default MapViewGroup
