/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapGL from 'react-map-gl'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import scss from '../Maps.scss'
import modalScss from '../../CSSModules/Modal.scss'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
} from '../../../components/MaterialUi/Tool'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

const mapEditMuiTheme = createMuiTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '8px 10px',
      },
    },
    MuiButton: {
      label: {
        color: '#fff !important',
      },
    },
    MuiButtonBase: {
      root: {
        // Name of the rule
        height: '33px',
        color: '#fff !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          // Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      root: {
        // padding: "0 0px 0 0px !important",
      },
    },
    MuiFormLabel: {
      root: {
        // Name of the rule
        fontSize: '12px !important',
      },
    },
    MuiInputBase: {
      root: {
        // Name of the rule
        height: 'auto',
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          // Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
  },
})

function SimpleDialog({
  lat,
  lon,
  zoom,
  name,
  desc,
  mapID,
  visible,
  mapStyle,
  mapTransformRequest,
  mapOnViewportChange,
  mapViewportObj,
}) {
  const [open, setOpen] = useState(true)

  const [fetching, setFetching] = useState(false)

  const [mapName, setMapName] = useState(name || 'New Map')
  const [mapDesc, setMapDesc] = useState(desc)
  const [mapLat, setMapLat] = useState(lat)
  const [mapLon, setMapLon] = useState(lon)
  const [mapZoom, setMapZoom] = useState(zoom)
  const [fetchObjects, setFetchObjects] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const mapViewportNew = mapViewportObj[mapID]
  if (typeof mapViewportNew === 'object' && mapViewportNew != null) {
    mapViewportNew.width = 510
    mapViewportNew.height = 300
  }

  const buildFetchParams = () => {
    if (mapID === 0) {
      const method = 'POST'
      const url = apis.apiDatabase.uri + 'map/add'
      const body = {
        accountID: user.accountID,
        name: mapName,
        description: mapDesc || '',
        lat: mapLat,
        lon: mapLon,
        zoom: mapZoom,
      }

      setFetchObjects([{ url, method, body }])
      setFetching(true)
    } else {
      const method = 'POST'
      const url = apis.apiDatabase.uri + 'map/update'
      const body = {
        mapID,
        name: mapName,
        description: mapDesc || '',
        lat: parseFloat(mapLat) || 0,
        lon: parseFloat(mapLon) || 0,
        zoom: mapZoom,
      }

      setFetchObjects([{ url, method, body }])
      setFetching(true)
    }
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const onNameChange = e => {
    setMapName(e.target.value)
  }

  const onDescChange = e => {
    setMapDesc(e.target.value)
  }

  const onLatChange = e => {
    let { value } = e.target

    if (
      value === '' ||
      typeof value === 'undefined' ||
      (!value && value !== 0)
    ) {
      setMapLat('')
      return
    }

    const numberOfDecimalPoints = value.includes('.')
      ? value.split('.').length - 1
      : 0

    if (numberOfDecimalPoints > 1) return
    if (numberOfDecimalPoints === 1 && value.charAt(value.length - 1) === '.') {
      setMapLat(value)
      return
    }

    if (Number.isNaN(parseFloat(value)) && value !== '-') return
    if (value >= 90) value = '90'
    if (value <= -90) value = '-89.999999'

    if (value === '-') {
      setMapLat(value)
    } else if (value !== '-') {
      if (numberOfDecimalPoints === 1) {
        let numberOfDigitsAfterDecimal = value.split('.')

        if (numberOfDigitsAfterDecimal.length > 1) {
          numberOfDigitsAfterDecimal = numberOfDigitsAfterDecimal[1].length
        } else {
          numberOfDigitsAfterDecimal = numberOfDigitsAfterDecimal[0].length
        }

        if (numberOfDigitsAfterDecimal >= 6) return
      }

      const numberOfDashes = value.includes('-')
        ? value.split('-').length - 1
        : 0

      if (numberOfDashes > 1) return

      if (value.charAt(value.length - 1) === '.')
        value = value.split('.').join('')

      setMapLat(value)
      const newViewport = mapViewportNew
      newViewport.latitude = parseFloat(value)
      mapOnViewportChange(newViewport, mapID)
    }
  }

  const onLonChange = e => {
    let { value } = e.target

    if (
      value === '' ||
      typeof value === 'undefined' ||
      (!value && value !== 0)
    ) {
      setMapLon('')
      return
    }

    const numberOfDecimalPoints = value.includes('.')
      ? value.split('.').length - 1
      : 0

    if (numberOfDecimalPoints > 1) return
    if (numberOfDecimalPoints === 1 && value.charAt(value.length - 1) === '.') {
      setMapLon(value)
      return
    }

    if (Number.isNaN(parseFloat(value)) && value !== '-') return
    if (value >= 180) value = '180'
    if (value <= -180) value = '-179.999999'

    if (value === '-') {
      setMapLon(value)
    } else if (value !== '-') {
      if (numberOfDecimalPoints === 1) {
        let numberOfDigitsAfterDecimal = value.split('.')

        if (numberOfDigitsAfterDecimal.length > 1) {
          numberOfDigitsAfterDecimal = numberOfDigitsAfterDecimal[1].length
        } else {
          numberOfDigitsAfterDecimal = numberOfDigitsAfterDecimal[0].length
        }

        if (numberOfDigitsAfterDecimal >= 6) return
      }

      const numberOfDashes = value.includes('-')
        ? value.split('-').length - 1
        : 0

      if (numberOfDashes > 1) return

      if (value.charAt(value.length - 1) === '.')
        value = value.split('.').join('')

      setMapLon(value)
      const newViewport = mapViewportNew
      newViewport.longitude = parseFloat(value)
      mapOnViewportChange(newViewport, mapID)
    }
  }

  const onZoomChange = e => {
    if (e.target.value === '') {
      setMapZoom('')
      return
    }
    let convert = Math.round(parseFloat(e.target.value))

    if (Number.isNaN(convert)) return

    if (convert >= 24) convert = 24
    if (convert <= 1) convert = 1

    setMapZoom(convert)
    const newViewport = mapViewportNew
    newViewport.zoom = convert
    mapOnViewportChange(newViewport, mapID)
  }

  const updateViewport = viewport => {
    setMapLat(parseFloat(viewport.latitude).toFixed(5))
    setMapLon(parseFloat(viewport.longitude).toFixed(5))
    setMapZoom(parseFloat(viewport.zoom).toFixed(1))
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-user-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='assign-user-dialog'>
          {mapID === 0 ? 'New' : 'Edit'} Map
          <FontAwesomeIcon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
            style={{ cursor: 'pointer' }}
          />
        </DialogTitle>

        <DialogContent
          className={modalScss.MuiDialogBoxes}
          style={{ padding: '20.5px 13px 80px 13px' }}
        >
          <div className={scss.mapEdit}>
            <div className={scss.mapEditMapName}>
              {/* <label>Map Name</label><input id="mapName" name="mapName" value="" /> */}
              <MuiFormControlText
                style={{
                  border: '1px solid #41678b',
                  boxShadow: 'none',
                }}
                // className={toolScss.toolBodyTab.button}
                disabled={false}
                label='Map Name'
                placeholder=''
                onChange={onNameChange}
                onSubmit={fetching ? null : handleSave}
                value={mapName}
              />
            </div>
            <div className={scss.mapEditMapName}>
              <MuiFormControlTextMultiline
                style={{ border: '1px solid #41678b' }}
                // className={toolScss.toolBodyTab.button}
                disabled={false}
                label='Map Description'
                placeholder='Map Description (Optional)'
                onChange={onDescChange}
                onSubmit={fetching ? null : handleSave}
                value={mapDesc}
              />
            </div>
            {/* <div className={scss.mapEditMap}> */}
            <span className={scss.panAndZoom}>
              Pan and/or Zoom to set Home <FontAwesomeIcon icon='home' />
            </span>
            <div
              style={{
                borderColor: 'rgb(65, 103, 139)',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '5px',
                height: '325px',
                width: '530px',
                display: 'flex',
                justifyContent: 'center',
                margin: '10px',
              }}
            >
              <MapGL
                style={{
                  padding: '10px 0',
                }}
                key={mapID}
                {...mapViewportNew}
                mapStyle={mapStyle}
                transformRequest={mapTransformRequest}
                onViewportChange={viewport => {
                  mapOnViewportChange(viewport, mapID)
                  updateViewport(viewport)
                }}
                // onLoad={onMapLoad}
                // onNativeClick={this.onMapClickNative}
                dragPan
                // doubleClickZoom={doubleClickZoom}
                scrollZoom
                // ref={(map) => (this.mapRef = map)}
                // transformRequest={this.transformRequest}
                // onTransitionEnd={this.onTransitionEnd}
                // interactiveLayerIds={interactiveLayerIds}
                // attributionControl={false}
                // getCursor={this.getCursor}
                // keyboard={false}
              />
            </div>
            {/* </div> */}
            <div className={scss.mapEditLatLon}>
              <div className={scss.mapEditLat}>
                {/* <label>Latitude</label><input id="latitude" name="latitude" value="" /> */}
                <MuiFormControlText
                  style={{ border: '1px solid #41678b' }}
                  // className={toolScss.toolBodyTab.button}
                  disabled={false}
                  label='Latitude'
                  placeholder='-90 to 90'
                  onChange={onLatChange}
                  onSubmit={fetching ? null : handleSave}
                  value={mapLat}
                />
              </div>
              <div className={scss.mapEditLon}>
                <MuiFormControlText
                  style={{ border: '1px solid #41678b' }}
                  // className={toolScss.toolBodyTab.button}
                  disabled={false}
                  label='Longitude'
                  placeholder='-180 to 180'
                  onChange={onLonChange}
                  onSubmit={fetching ? null : handleSave}
                  value={mapLon}
                />
              </div>
            </div>
            <div className={scss.mapEditLatLon}>
              <MuiFormControlText
                style={{ border: '1px solid #41678b' }}
                // className={toolScss.toolBodyTab.button}
                disabled={false}
                label='Zoom Level'
                placeholder='1 to 24'
                onChange={e => {
                  onZoomChange(e)
                }}
                onSubmit={fetching ? null : handleSave}
                value={mapZoom}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type='button'
            className={modalScss.modalAltButton}
            onClick={handleOnClose}
          >
            Cancel
          </button>
          {mapName &&
          !Number.isNaN(parseFloat(mapLat)) &&
          !Number.isNaN(parseFloat(mapLon)) &&
          !Number.isNaN(parseFloat(mapZoom)) ? (
            <button
              type='button'
              className={modalScss.modalMainButton}
              onClick={fetching ? null : handleSave}
            >
              {fetching ? (
                <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Save'
              )}
            </button>
          ) : null}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function MapAddModal(props) {
  return <SimpleDialog {...props} />
}
