import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAppMeasureMode,
  setAppDrawMode,
  disablePopup,
} from '../../../actions/index'
import MeasureLine from './MeasureLine'
import MeasureArea from './MeasureArea'
import MeasurePoint from './MeasurePoint'
import MeasureClear from './MeasureClear'
import Coordinates from './Coordinates'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './MeasureMode.scss'

const MeasureMode = React.memo(({ disableMap, enableMap }) => {
  const dispatch = useDispatch()
  const drawMode = useSelector(state => state.drawMode)

  const [measureMode, setMeasureMode] = useState(null)
  const [doClear, setDoClear] = useState(false)
  const [showCoordinates, setShowCoordinates] = useState(false)

  const handleButtonClick = mode => {
    if (mode === measureMode) {
      setMeasureMode(null)
    } else {
      setMeasureMode(mode)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(disablePopup(false))
    }
  }, [])

  useEffect(() => {
    dispatch(setAppMeasureMode(measureMode))
    if (measureMode) {
      dispatch(setAppDrawMode(null))
      dispatch(disablePopup(true))
    } else {
      dispatch(disablePopup(false))
    }
  }, [measureMode])

  useEffect(() => {
    if (drawMode) {
      setMeasureMode(null)
    }
  }, [drawMode])

  useEffect(() => {
    if (doClear) {
      setDoClear(false)
    }
  }, [doClear])

  return (
    <>
      <div
        className={scss['measure-tool-wrapper']}
        onMouseEnter={disableMap}
        onMouseLeave={enableMap}
      >
        <div className={scss['measure-buttons']}>
          <button
            className={
              measureMode === 'MEASURE_LINE' ? scss.active : scss.notActive
            }
            onClick={() => handleButtonClick('MEASURE_LINE')}
            data-tip='Measure Line'
            data-for='measure-button'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
            data-place='top'
          >
            <FontAwesomeIcon icon='ruler-vertical' size='1x' />
          </button>
          <button
            className={
              measureMode === 'MEASURE_AREA' ? scss.active : scss.notActive
            }
            onClick={() => handleButtonClick('MEASURE_AREA')}
            data-tip='Measure Area'
            data-for='measure-button'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
            data-place='top'
          >
            <FontAwesomeIcon icon='ruler-combined' size='1x' />
          </button>
          <button
            className={
              measureMode === 'MEASURE_POINT' ? scss.active : scss.notActive
            }
            onClick={() => handleButtonClick('MEASURE_POINT')}
            data-tip='Measure Point'
            data-for='measure-button'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
            data-place='top'
          >
            <FontAwesomeIcon icon='globe-americas' size='1x' />
          </button>
          <button
            onClick={() => setDoClear(true)}
            data-tip='Clear Measurements'
            data-for='measure-button'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
            data-place='top'
          >
            <FontAwesomeIcon icon={['fal', 'sync']} size='1x' />
          </button>
          {
            <button
              onClick={() => setShowCoordinates(!showCoordinates)}
              data-tip='Show Coordinates'
              data-for='measure-button'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
              data-place='top'
            >
              <FontAwesomeIcon icon={['fal', 'cog']} size='1x' />
            </button>
          }
        </div>
        {showCoordinates && <Coordinates />}
      </div>
      <div className={scss.reactToolTip}>
        <ReactTooltip
          id='measure-button'
          getContent={dataTip => `${dataTip}`}
        />
      </div>
      {measureMode === 'MEASURE_LINE' ? <MeasureLine /> : null}
      {measureMode === 'MEASURE_AREA' ? <MeasureArea /> : null}
      {measureMode === 'MEASURE_POINT' ? <MeasurePoint /> : null}

      {doClear ? <MeasureClear /> : null}
    </>
  )
})

export default MeasureMode
