import axios from 'axios'
import { apiFetch } from './api'
import { apis } from '../config/apiConfig'
import { Auth } from 'aws-amplify'
//Action Handlers
import { Login as _Login, Logout as _Logout, defaultUser } from '../actions'

import * as utils from './util'

const DEFAULT_LATITUDE = 39.742043
const DEFAULT_LONGITUDE = -104.991531
const DEFAULT_ZOOM = 5

//USER LOGIN
export function loginInitialize(userPayload, callback) {
  const { sub, email } = userPayload

  if (sub == null)
    return callback(true, 'loginInitialize: userPayload was not send')

  let userUUID = sub
  if (userUUID === null)
    return callback(true, 'loginInitialize: User UUID is null')
  if (userUUID < 0) return callback(true, 'User is not valid')

  let user = defaultUser()

  user.UUID = userUUID
  user.isLoggedIn = true
  user.profile.emailAddress = email

  const url = apis.apiDatabase.uri + 'user/init'
  const method = 'GET'

  apiFetch(url, method, null, result => {
    if (utils.verifyResult(result)) {
      if (
        typeof result.data !== 'undefined' &&
        typeof result.data.profile !== 'undefined'
      ) {
        let profile = result.data.profile
        user.isSuperAdmin = result.data.isSuperAdmin
        user.isAdmin = result.data.isAdmin
        user.isActive = result.data.isActive
        user.role = result.data.role
        user.permissions = result.data.permissions

        user.adminAccounts = result.data.adminAccounts

        user.profile = { ...user.profile, ...profile }
        user.accounts = result.data.accounts
        user.preferences = result.data.preferences

        let account
        if (result.data.accountID !== null) {
          user.accountID = result.data.accountID

          account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

          if (!account || (Array.isArray(account) && !account.length)) {
            user.accountID =
              result.data.accounts.length > 0
                ? result.data.accounts[0].id
                : null

            //Set Account to matching account based on ID
            account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
          }
        } else {
          // ========================================================================================== //
          // ======== If accountID is not specified, build the top level user on the front-end ======== //
          // ========================================================================================== //

          user.accountID =
            result.data.accountID == null
              ? result.data.accounts.length > 0
                ? result.data.accounts[0].id
                : null
              : result.data.accountID

          //Set Account to matching account based on ID
          account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

          if (!result.data.accounts.length) {
            return callback(
              true,
              'You are not a part of any accounts. Please contact support@myassetmap.com.'
            )
          }

          //Continue Overwriting settings
          user.isAdmin = result.data.adminAccounts.includes(user.accountID)
          user.isActive = !result.data.disabledAccounts.includes(user.accountID)
          user.role = account.role
          user.permissions = account.permissions
        }

        if (!account || (Array.isArray(account) && !account.length)) {
          return callback(
            true,
            'No active accounts have been found. Please have your admin add to you an account. If you need additional support, please contact support@myassetmap.com.'
          )
        }

        //Set Map based on Account and Data
        user.mapID =
          result.data.mapID == null
            ? result.data.accounts.length > 0
              ? typeof account === 'object'
                ? account.maps && account.maps.length > 0
                  ? account.maps[0].id
                  : null
                : null
              : null
            : result.data.mapID
      }
    }
    console.log('init user ', user)
    createLog(user)
    callback(false, user)
  })
}

export async function createLog(user) {
  try {
    const res = await axios.get('https://geolocation-db.com/json/')

    const url = apis.apiDatabase.uri + 'logs'
    const method = 'POST'
    const bodyParams = {
      latitude: res.data.longitude,
      longitude: res.data.latitude,
      state: res.data.state,
      userID: user.UUID,
      city: res.data.city,
      ip: res.data.IPv4,
    }

    if (user.mapID) bodyParams.mapID = user.mapID

    await apiFetch(url, method, bodyParams, result => {
      console.log('Log created successfully')
    })
  } catch (error) {
    console.warn('Unable to create log')
  }
}

//USER LOGIN
export function refreshDetails(userPayload, callback) {
  const { UUID, profile } = userPayload

  userPayload.sub = UUID
  userPayload.email = profile.emailAddress
  console.log('Refreshing User Details')

  loginInitialize(userPayload, (err, user) => {
    if (err) return console.error('Unable to refresh user details: ' + user)

    callback(user)
  })
}

export async function createCredentials() {
  const session = await Auth.currentSession()
  const credentials = await Auth.currentCredentials()
  const essential = await Auth.essentialCredentials(credentials)
  localStorage.setItem('accessKeyId', essential.accessKeyId)
  localStorage.setItem('secretAccessKey', essential.secretAccessKey)
  localStorage.setItem('sessionToken', essential.sessionToken)
  localStorage.setItem('token', session.idToken.jwtToken)
  return await essential
}

export const getMapHomeLocation = user => {
  const accountId = user.accountID
  const mapId = user.mapID
  const activeAccount = user.accounts.filter(
    account => account.id === accountId
  )
  const location = activeAccount[0].maps
    .filter(map => map.id === mapId)
    .map(map => map.location)
  if (!location[0]) {
    location[0] = {}
    location[0].x = DEFAULT_LATITUDE
    location[0].y = DEFAULT_LONGITUDE
    location[0].z = DEFAULT_ZOOM
  }
  return location[0]
}

export const getActiveMap = user => {
  const accountId = user.accountID
  const mapId = user.mapID
  const activeAccount = user.accounts.filter(
    account => account.id === accountId
  )
  const activeMap = activeAccount[0].maps.filter(map => map.id === mapId)

  return activeMap[0]
}
