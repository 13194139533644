import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Rnd } from 'react-rnd'

import Draw from './Draw/Draw'
import Legend from './Legend/Legend'
import LayerSettingsV2 from './LayerSettingsV2/LayerSettingsV2.jsx'
import GroupSettings from './GroupSettings/GroupSettings'
import MapViewSettings from './MapViewGroups/MapViewSettings/MapViewSettings'
import DataImport from './DataImport/DataImport'
import Notes from './Notes/Notes'
import { defaultToolConfig } from '../../config/toolConfig'

import { setToolConfig } from '../../actions/'
import scss from './Tools.scss'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const newMuiTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      // Name of the rule
      root: {
        color: '#fff',
        backgroundColor: '#213445',
        fontFamily: 'Montserrat !important',
        padding: '17px 12px',
      },
      icon: {
        fill: '#fff',
        color: '#fff',
        marginTop: '4px',
      },
    },
    MuiInputLabel: {
      root: {
        // Name of the rule
        color: 'rgba(255, 255, 255, 0.6) !important',
        backgroundColor: '#1a2937 !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        padding: '0 6px 0 6px',
        '&$focused': {
          //Input Label Text Focused CSS
          color: 'rgba(255, 255, 255, 0.6) !important',
        },
      },
    },
    MuiFormLabel: {
      root: {
        // Name of the rule
        fontSize: '12px !important',
      },
    },
    MuiInputBase: {
      root: {
        // Name of the rule
        color: '#fff !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        border: 'solid 1px #41678b !important',
        borderRadius: 4,
        '&$focused': {
          //Input Box Focused CSS
          borderColor: '#24c0db !important',
          color: '#fff',
        },
      },
    },
  },
})

const Tools = ({
  dataConfig,
  enableMap,
  disableMap,
  componentOnTop,
  setComponentOnTop,
}) => {
  const user = useSelector(state => state.user)
  const viewport = useSelector(state => state.viewport)
  const mapRef = useSelector(state => state.mapRef)
  const mapStyle = useSelector(state => state.mapStyle)
  const toolConfig = useSelector(state => state.toolConfig)
  const overPanel = useSelector(state => state.overPanel)
  const doubleClickZoom = useSelector(state => state.doubleClickZoom)

  const dispatch = useDispatch()

  const [isOnTool, setIsOnTool] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [toolConfigPrevious, setToolConfigPrevious] = useState([])

  const dataImportKey = 'dataImport'

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const handleToolDragStart = () => {
    setIsDragging(true)
    if (!isOnTool) {
      disableMap()
    }
  }

  const handleToolDragStop = () => {
    setIsDragging(false)
    if (!isOnTool) {
      enableMap()
    }
  }

  const handleEnterTool = () => {
    setIsOnTool(true)
    if (!isDragging) {
      disableMap()
    }
  }

  const handleLeaveTool = () => {
    setIsOnTool(false)
    if (!isDragging) {
      enableMap()
    }
  }

  const handleCollapse = (toolname, collapsed = true) => {
    if (collapsed) enableMap()

    toolConfig.forEach(config => {
      if (config.name === toolname) {
        config.collapsed = collapsed
      }
    })

    dispatch(setToolConfig(toolConfig))
    forceUpdate()
  }

  const buildTool = config => {
    let toolLookUp = {
      Draw: (
        <Draw
          mapStyle={mapStyle}
          config={config}
          dataConfig={dataConfig}
          viewport={viewport}
          overPanel={overPanel}
          mapRef={mapRef}
          onClose={enableMap}
          onCollapse={handleCollapse}
          enableMap={enableMap}
          doubleClickZoom={doubleClickZoom}
        />
      ),
      Legend: (
        <Legend
          mapStyle={mapStyle}
          config={config}
          dataConfig={dataConfig}
          viewport={viewport}
          overPanel={overPanel}
          mapRef={mapRef}
          onClose={enableMap}
          onCollapse={handleCollapse}
        />
      ),
      LayerSettings: (
        <LayerSettingsV2
          key='LayerSettingsV2'
          config={config}
          onCollapse={handleCollapse}
          dataConfig={dataConfig}
        />
      ),
      DataImport: (
        <DataImport
          key={dataImportKey}
          mapStyle={mapStyle}
          config={config}
          viewport={viewport}
          overPanel={overPanel}
          mapRef={mapRef}
          onClose={enableMap}
          onCollapse={handleCollapse}
        />
      ),
      GroupSettings: (
        <GroupSettings
          mapStyle={mapStyle}
          config={config}
          viewport={viewport}
          overPanel={overPanel}
          mapRef={mapRef}
          onClose={enableMap}
          onCollapse={handleCollapse}
          dataConfig={dataConfig}
        />
      ),
      MapViewSettings: (
        <MapViewSettings
          mapStyle={mapStyle}
          config={config}
          overPanel={overPanel}
          mapRef={mapRef}
          onClose={enableMap}
          onCollapse={handleCollapse}
        />
      ),
      Notes: (
        <Notes
          key={'mapViewNotes'}
          config={config}
          overPanel={overPanel}
          onClose={enableMap}
          onCollapse={handleCollapse}
        />
      ),
    }

    if (config.visible) {
      let transparent = false
      if (config.name === 'LayerSettings' && !config.layer) transparent = true
      if (config.name === 'GroupSettings' && !config.group) transparent = true
      if (config.name === 'MapViewSettings' && !config.targetMapView)
        transparent = true

      return (
        <>
          {config.fixedTop ? (
            <div key={config.name + '_Tool'} className={scss.fixedTop}>
              <div
                className={[scss.toolContainer]}
                onMouseEnter={handleEnterTool}
                onMouseLeave={handleLeaveTool}
              >
                {toolLookUp[config.name]}
              </div>
            </div>
          ) : (
            <ThemeProvider theme={newMuiTheme} key={config.name + '_ToolTheme'}>
              <Rnd
                className={[
                  scss.panelDraggableContainer,
                  transparent ? scss.toolHidden : '',
                  config.collapsed ? scss.toolCollapsed : '',
                ].join(' ')}
                key={config.name + '_Tool'}
                style={{ zIndex: componentOnTop === config.name ? 1000 : 2 }}
                dragHandleClassName='handle'
                bounds='#mapContainer'
                scale={1}
                onClick={() => setComponentOnTop(config.name)}
                onDragStart={handleToolDragStart}
                onResizeStart={handleToolDragStart}
                onDragStop={handleToolDragStop}
                onResizeStop={handleToolDragStop}
                default={{
                  x: config.positionX || 10,
                  y: config.positionY || 10,
                  height: config.minHeight,
                  width: config.minWidth,
                }}
                minHeight={config.minHeight}
                minWidth={config.minWidth}
              >
                {config.name !== 'LayerSettings' &&
                config.name !== 'GroupSettings' &&
                config.name !== 'MapViewSettings' ? (
                  config.name == 'Legend' ? (
                    // Legend Tool Container
                    <div
                      className={[
                        scss.toolContainer,
                        scss.toolLegendContainer,
                      ].join(' ')}
                      onMouseEnter={handleEnterTool}
                      onMouseLeave={handleLeaveTool}
                    >
                      {toolLookUp[config.name]}
                    </div>
                  ) : (
                    // Regular Tool Container
                    <div
                      className={[scss.toolContainer]}
                      onMouseEnter={handleEnterTool}
                      onMouseLeave={handleLeaveTool}
                    >
                      {toolLookUp[config.name]}
                    </div>
                  )
                ) : (
                  // Layer, Group and Map View Settings Container
                  <div
                    className={[
                      scss.toolContainer,
                      scss.toolLayerContainer,
                    ].join(' ')}
                    onMouseEnter={handleEnterTool}
                    onMouseLeave={handleLeaveTool}
                  >
                    {toolLookUp[config.name]}
                  </div>
                )}
              </Rnd>
            </ThemeProvider>
          )}
        </>
      )
    } else return null
  }

  useEffect(() => {
    dispatch(setToolConfig(defaultToolConfig))
  }, [])

  useEffect(() => {
    if (toolConfig) {
      let oldVisible = toolConfigPrevious.filter(tC => tC.visible)
      let newVisible = toolConfig.filter(tC => tC.visible)

      let added = [],
        removed = []

      let oldNameList = []
      oldVisible.map(res => {
        oldNameList.push(res.name)
      })

      newVisible.map(res => {
        if (!oldNameList.includes(res.name)) added.push(res.name)
      })

      let newNameList = []
      newVisible.map(res => {
        newNameList.push(res.name)
      })

      oldVisible.map(res => {
        if (!newNameList.includes(res.name)) removed.push(res.name)
      })

      if (added.length) setComponentOnTop(added[0])

      setToolConfigPrevious(JSON.parse(JSON.stringify(toolConfig)))
    }
  }, [toolConfig])

  const tools = toolConfig.map(config => buildTool(config)) || []

  return tools
}

export default Tools
