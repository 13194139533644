import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../utilities/geospatial'
import * as mapUtils from '../../../utilities/mapStyle'
import { updateMapStyle } from '../../../actions'
import { buildMeasureId, checkForFeature, getMidPointLengths } from './Utils'

export default function Line({
  layerId,
  targetFeature,
  measureType,
  precision,
}) {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const linearUnit = 'mi'
  const style = geoUtils.clone(mapStyle)
  const labelSource = mapUtils.getSource(style, 'measure_label_cold')

  const addFeature = type => {
    const measureId = buildMeasureId(
      targetFeature.properties.mamid,
      layerId,
      type
    )
    if (type === 'lineSegments') {
      const segments = geoUtils.getLineSegments(targetFeature)
      segments.features.forEach(feature => {
        let linearLabel = linearUnit
        // note: get all lengths in miles and then convert to required unit
        let length = geoUtils.getLength(feature, 'miles')
        if (linearUnit === 'mi') {
          if (length < 1) {
            // length is less than 1 mile, show length in feet
            length *= 5280
            linearLabel = 'ft'
          }
        }
        const midPoint = getMidPointLengths(feature, linearUnit)
        midPoint.properties.measureType = type
        midPoint.properties.measurement =
          length.toFixed(precision) + ` ${linearLabel}`
        midPoint.properties.measureId = measureId
        midPoint.properties.mamid = targetFeature.properties.mamid
        midPoint.properties.layerId = layerId

        labelSource.data.features.push(midPoint)
      })
      style.sources.measure_label_cold = labelSource
    }

    if (type === 'lineLength') {
      let linearLabel = linearUnit
      const endPoint = geoUtils.getLineEndPoint(targetFeature)
      // note: get all lengths in miles and then convert to required unit
      let length = geoUtils.getLength(targetFeature, 'miles')
      if (linearUnit === 'mi') {
        if (length < 1) {
          // length is less than 1 mile, show length in feet
          length *= 5280
          linearLabel = 'ft'
        }
      }
      endPoint.properties.measureType = 'lineLength'
      endPoint.properties.measurement =
        'T: ' + length.toFixed(precision) + ` ${linearLabel}`
      endPoint.properties.measureId = measureId
      endPoint.properties.mamid = targetFeature.properties.mamid
      endPoint.properties.layerId = layerId
      labelSource.data.features.push(endPoint)
      style.sources.measure_label_cold = labelSource
    }
    dispatch(updateMapStyle(fromJS(style)))
  }

  const removeFeature = type => {
    const measureId = buildMeasureId(
      targetFeature.properties.mamid,
      layerId,
      type
    )
    const newFeatures = labelSource.data.features.filter(
      feature => feature.properties.measureId !== measureId
    )
    labelSource.data.features = newFeatures
    style.sources.measure_label_cold = labelSource
    dispatch(updateMapStyle(fromJS(style)))
  }

  useEffect(() => {
    measureType.forEach(type => {
      const featureInSource = checkForFeature(
        mapStyle,
        buildMeasureId(targetFeature.properties.mamid, layerId, type)
      )
      if (featureInSource.length) {
        removeFeature(type)
      } else {
        addFeature(type)
      }
    })
  }, [targetFeature])

  return null
}
