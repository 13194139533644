import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import mainModalTheme from '../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import appScss from '../App/App.scss'
import AsyncFetch from '../../utilities/AsyncFetch'
import { apis } from '../../config/apiConfig'
import * as utils from '../../utilities/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SimpleDialog({ user, respond }) {
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  const userState = useSelector(state => state.user)
  const accountID = userState.accountID

  useEffect(() => {
    setOpen(true)
  }, [user])

  const handleOnClose = () => {
    respond()
    setOpen(false)
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        respond('reload')
      } else respond()
    })
    setFetchObjects(null)
    setOpen(false)
    setFetching(false)
  }

  const handleDelete = () => {
    const url = apis['apiDatabase'].uri + 'account/user/delete'
    const method = 'POST'
    const body = {
      userID: user.id,
      accountID: accountID,
    }

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          onClose={handleOnClose}
          open={open}
          aria-labelledby='delete-user-dialog'
          maxWidth='sm'
        >
          <DialogTitle id='delete-user-title'>
            Remove User
            <FontAwesomeIcon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent>
            <p>
              You are removing{' '}
              <i>
                <b>{user.displayName ? user.displayName : 'user'}</b>
              </i>{' '}
              from this account. Proceed?
            </p>
          </DialogContent>
          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Remove User'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function UserDeleteModal(props) {
  return <SimpleDialog {...props} />
}
