import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../../../utilities/geospatial'
import * as mapUtils from '../../../../../utilities/mapStyle'
import _ from 'lodash'
import { updateMapStyle } from '../../../../../actions'
import { getHotLayers } from '../../Utils'

const DeleteVertex = ({ geometry, setDeleteVertex }) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  let style = mapStyle.toJS()
  const verticiesLayer = mapUtils.getLayerObject(style, 'draw_verticies_hot')
  const verticiesLayerSourceId = verticiesLayer.source
  let verticies = mapUtils.getSource(style, verticiesLayerSourceId)
  const clickedPoint = mapUtils.getLayerObject(style, 'draw_clicked_verticie')
  const clickedPointSourceId = clickedPoint.source
  let clickedPointSource = mapUtils.getSource(style, clickedPointSourceId)
  let hotLayers = getHotLayers(geometry)

  const deleteCoord = position => {
    const length = verticies.data.features.length - 1
    let midpoints = null
    // polygons must have 4 or more Positions, if not return
    if (geometry === 'fill' && length === 3) return
    // lines must have 2 or more Positions, if not return
    if (geometry === 'line' && length === 1) return

    if (
      (geometry === 'fill' && position === 0) ||
      (geometry === 'fill' && position === length)
    ) {
      // if polygon the first or last coord is removed
      // we must remove both coords and then close the polygon
      verticies.data.features.splice(length, 1)
      verticies.data.features.splice(0, 1)
      verticies.data.features.push(verticies.data.features[0])
    } else {
      verticies.data.features.splice(position, 1)
    }

    const newCoords = verticies.data.features.map(
      feature => feature.geometry.coordinates
    )
    let newFeature = null
    if (geometry === 'fill') {
      newFeature = geoUtils.getPolygon([newCoords])
      midpoints = getMidPoints(newFeature)
    }
    if (geometry === 'line') {
      newFeature = geoUtils.getLineString(newCoords)
      midpoints = getMidPoints(newFeature)
    }
    const layers = mapUtils.getLayerObjects(hotLayers, style)
    layers.forEach(layer => {
      let sourceId = layer.hot.source
      let source = mapUtils.getSource(style, sourceId)
      if (sourceId === 'draw_midpoints_hot') {
        style.sources[sourceId] = emptyLayerSource(style, sourceId)
        style.sources[sourceId].data.features = midpoints
      } else if (sourceId === 'draw_verticies_hot') {
        let verticiesSource = emptyLayerSource(style, sourceId)
        verticiesSource.data.features = verticies.features
        style.sources['draw_verticies_hot'] = verticiesSource
      } else {
        source.data.features[0] = newFeature
        style.sources[sourceId] = source
      }
    })

    clickedPointSource.data.features = []
    style.sources[clickedPointSourceId] = clickedPointSource
    style.sources[verticiesLayerSourceId] = verticies
    dispatch(updateMapStyle(fromJS(style)))
    setDeleteVertex(null)
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const getMidPoints = feature => {
    // convert feature to line segments
    // get midpoint for each line segment
    const lineSegments = geoUtils.getLineSegments(feature)
    return lineSegments.features.map(feature =>
      geoUtils.getLineMidPoint(feature)
    )
  }

  const getCoordPositon = () => {
    let position = null
    verticies.data.features.forEach((vertex, index) => {
      if (!clickedPointSource.data.features[0]) return
      if (
        JSON.stringify(vertex.geometry.coordinates) ===
        JSON.stringify(clickedPointSource.data.features[0].geometry.coordinates)
      ) {
        position = index
      }
    })
    return position
  }

  useEffect(() => {
    const postion = getCoordPositon()
    if (postion) {
      deleteCoord(postion)
    }
  }, [])

  return null
}

export default DeleteVertex