/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import LabelSize from './LabelSize'
import LabelColor from './LabelColor'
import LabelFormatting from './LabelFormatting'

const LabelStyle = React.memo(
  ({ config, selectedZoom, setDisplaySaveButtonOnMenu }) => {

    const mapStyle = useSelector(state => state.mapStyle)
    const style = mapStyle.toJS()

    const symbolLayerId = config.layer.layersArray[0].symbol.id
    const layerId = config.layer.layersArray[0].layer.id

    const layer = style.layers.filter(layer => layer.id === symbolLayerId)

    return (
      <>
        {layer && layer[0].layout['text-size'] && (
          <LabelSize
            layerId={layer[0].id}
            textSize={layer[0].layout['text-size']}
            selectedZoom={selectedZoom}
            setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
          />
        )}
        {layer && layer[0].paint['text-color'] && (
          <LabelColor
            layerId={layer[0].id}
            textColor={layer[0].paint['text-color']}
            textBorder={layer[0].paint['text-halo-color']}
            textBorderWidth={layer[0].paint['text-halo-width']}
            setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
          />
        )}
        {layer && layer[0].layout['text-size'] && (
          <LabelFormatting
            layerId={layer[0].id}
            horizontalVal={
              layer[0].layout['text-offset'] &&
              layer[0].layout['text-offset'][0]
                ? layer[0].layout['text-offset'][0]
                : 0
            }
            verticalVal={
              layer[0].layout['text-offset'] &&
              layer[0].layout['text-offset'][1]
                ? layer[0].layout['text-offset'][1]
                : 0
            }
            overlapText={layer[0].layout['text-allow-overlap']}
            setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
          />
        )}
      </>
    )
  }
)

export default LabelStyle
