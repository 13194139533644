import React from 'react'
import * as animationData from './loading_temp.json'

import loadingScreen from '../../assets/logo/MAM---Loading-Globe_Compressed.gif'

export default class LottieControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isStopped: false, isPaused: false }
  }

  render() {
    const containerStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      paddingTop: 210,
      backgroundColor: '#1a2937',
    }

    const buttonStyle = {
      display: 'block',
      margin: '10px auto',
    }
    const loadingStyle = {
      textAlign: 'center',
      fontSize: '18px',
      color: '#fff',
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    }

    return (
      <div style={containerStyle}>
        <img src={loadingScreen}></img>
        <div style={loadingStyle}>Loading...</div>
      </div>
    )
  }
}
