import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import scss from './LoadingDiv.scss'
export default function LoadingDiv({}) {
  return (
    <div className={scss.container}>
      <FontAwesomeIcon icon='spinner' size='lg' fixedWidth spin />
    </div>
  )
}
