import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { setToolConfig, mountMapViews } from '../../../actions/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from '../MapViewGroups/MapViewGroup.scss'
import tocScss from '../../Toc/Toc.scss'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import MapViewGroup from '../MapViewGroups/MapViewGroup'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
    '& .MuiListItemIcon-root': {
      minWidth: 25,
    },
    '& .MuiListItemText-root': {
      maxWidth: 200,
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: '#314d68',
    },
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  active: {
    backgroundColor: '#314d68',
  },
  listItemText: {
    fontSize: 16,
    display: 'block',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    marginRight: '25px',
  },
}))

const newMuiTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      // Name of the rule
      root: {
        color: '#fff',
        height: '20px',
        width: '20px',
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
})

export default function MapViewList({
  mapViews,
  currentOrder,
  mountKey,
  activeMapView,
  setMapView,
  editMapView,
  closeTool,
  editNotesActive,
  updateMapViews,
}) {
  // State Selectors
  const toolConfig = useSelector(state => state.toolConfig)
  const [activePanel, setActivePanel] = useState(false)
  const user = useSelector(state => state.user)

  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSettingsClick = mapView => {
    let toolConfigClone = toolConfig.reduce((acc, tool) => {
      let toolClone = _.cloneDeep(tool)
      if (toolClone.name === 'MapViewSettings') {
        if (toolClone.visible === true) {
          toolClone.visible = false
          setActivePanel(false)
          closeTool()
        } else {
          setActivePanel(mapView.id)
          toolClone.visible = true
          toolClone.targetMapView = mapView
          toolClone.mapViewList = mapViews
          toolClone.updateMapViews = updateMapViews
          toolClone.updatePanel = updatePanel
        }
      }
      acc.push(toolClone)
      return acc
    }, [])

    dispatch(setToolConfig(toolConfigClone))
    mountKey++
    dispatch(mountMapViews(mountKey))
  }

  const updatePanel = () => {
    setActivePanel(false)
  }

  // ==========================================================
  // HELPER FUNCTIONS
  // ==========================================================

  const getIndView = (view, index) => {
    if (view && typeof view != 'string') {
      if (!view.id) return null
      return (
        <Draggable draggableId={view.id} index={index} key={view.id}>
          {provided => (
            <div
              key={view.id}
              className={[
                scss.addOnGroup,
                tocScss.addOnLayer,
                activeMapView && activeMapView.id == view.id
                  ? scss.activeNotes
                  : '',
              ].join(' ')}
              style={{ borderWidth: '1px', marginBottom: '4px' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                onClick={() => setMapView(view.id)}
                className={[
                  activeMapView && activeMapView.id == view.id
                    ? scss.activeText
                    : '',
                  scss.titleWrapper,
                ].join(' ')}
              >
                <div
                  style={{ width: '178px' }}
                  className={tocScss.tocLabelText}
                >
                  <span>{view.name}</span>
                </div>
              </div>

              <button
                onClick={() => handleSettingsClick(view)}
                className={
                  activePanel && activePanel == view.id ? scss.active : ''
                }
              >
                <FontAwesomeIcon icon={'cog'} size='xs' />
              </button>

              {view.canEdit !== false || user.isAdmin ? (
                <button
                  onClick={() => editMapView(view.id)}
                  className={[
                    scss.groupEditButton,
                    editNotesActive && editNotesActive == view.id
                      ? scss.active
                      : '',
                  ].join(' ')}
                >
                  <FontAwesomeIcon icon={'pencil'} size='xs' />
                </button>
              ) : null}
            </div>
          )}
        </Draggable>
      )
    }
  }

  const getViewGroup = (group, index) => {
    if (!group) return null

    if (typeof group != 'string') {
      const children = group.children.order

      // pass to mapviewgroup through children var
      return (
        <Draggable draggableId={group.id} index={index} key={group.id}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <MapViewGroup
                key={group.groupId}
                group={group}
                groupViews={children}
                getIndView={getIndView}
                getViewGroup={getViewGroup}
                onSettingsClick={handleSettingsClick}
                activePanel={activePanel}
              />
            </div>
          )}
        </Draggable>
      )
    }
  }

  const makeDraggableViewButton = (view, index) => {
    if (view && view.children) {
      return getViewGroup(view, index)
    } else {
      return getIndView(view, index)
    }
  }

  let views = []
  if (Array.isArray(currentOrder)) {
    views = currentOrder.map((view, index) => {
      return makeDraggableViewButton(view, index)
    })
  }

  return (
    <ThemeProvider theme={newMuiTheme}>
      <Droppable droppableId='mapviewDroppable' type='root'>
        {droppableProvided => (
          <div ref={droppableProvided.innerRef} className={classes.root}>
            {views}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </ThemeProvider>
  )
}
