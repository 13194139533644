import React from 'react'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import blueGrey from '@material-ui/core/colors/blueGrey'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiSelect-root': {
      margin: 2,
      width: '100%',
    },
    '& .MuiInput-root': {
      fontSize: 16,
      lineHeight: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 16,
    },
    '& .MuiInputLabel-root': {
      fontSize: 16,
    },
    '& .MuiInputBase-root': {
      width: '100%',
      lineHeight: 0,
    },
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: indigo,
  },
  palette: {
    secondary: red,
  },
  palette: {
    default: blueGrey,
  },
  typography: {
    button: {
      fontSize: '1rem',
    },
  },
  spacing: 4,
})

export const MuiButton = ({ variant, onClick, label, color, size }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button variant={variant} onClick={onClick} color={color} size={size}>
        {label}
      </Button>
    </ThemeProvider>
  )
}

export const MuiFormControlText = ({
  onChange,
  onSubmit,
  placeholder,
  value,
  label,
}) => {
  const classes = useStyles()
  const onConfirm = e => {
    e.preventDefault()

    if (onSubmit) onSubmit()
  }

  return (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.root}
        noValidate
        autoComplete='off'
      >
        <TextField
          label={label}
          value={value}
          variant={'outlined'}
          placeholder={placeholder}
          onChange={onChange}
          size='small'
        />
      </form>
    </Box>
  )
}

// TODO: DEPRECATED
// We are now using React Select
export const MuiFormControlSelect = ({
  onChange,
  onSubmit,
  selected,
  inputLabel,
  selectOptions,
}) => {
  const classes = useStyles()
  const onConfirm = e => {
    e.preventDefault()

    if (onSubmit) onSubmit()
  }

  return (
    <Box m={1}>
      <form
        onSubmit={onConfirm}
        className={classes.root}
        noValidate
        autoComplete='off'
      >
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          variant={'outlined'}
          value={selected.value}
          onChange={onChange}
          IconComponent={KeyboardArrowDownIcon}
        >
          {selectOptions.map(option => {
            return (
              <MenuItem
                key={option.value}
                style={{ fontSize: 16 }}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </form>
    </Box>
  )
}
