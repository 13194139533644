export const hexToRgba = (hex, opacity = 1) => {
  let c = null
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    c = '0x' + c.join('')
  }
  if (c) {
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    )
  } else {
    return ''
  }
}

export const CURRENT_DATA_YYYY_MM_DD = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
