import { StyleSheet, Font } from '@react-pdf/renderer'

// Available Pages Sizes are at
// https://github.com/diegomura/react-pdf/blob/master/src/utils/pageSizes.js
export const PAGE_SIZE_LOOKUP = {
  LEGAL: {
    width: 612,
    height: 1008,
  },
  LETTER: {
    width: 612,
    height: 792,
  },
  TABLOID: {
    width: 792,
    height: 1224,
  },
}

export const DEFAULT_SELECT_OPTION = {
  label: 'Default Layout',
  value: 'default',
}

export const MAP_ELEMENT = {
  type: 'map',
  x: 5,
  y: 5,
  width: 782,
  height: 540,
  borderWidth: 2,
  borderColor: '#000000',
  borderTransparent: false,
}

export const TEXT_ELEMENT = {
  type: 'text',
  text: 'Enter Text...',
  x: 5,
  y: 555,
  width: 150,
  height: 50,
  borderWidth: 1,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  fontSize: 12,
  fontFamily: 'Montserrat',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  textAlign: 'left',
  padding: 5,
}

export const NORTH_ARROW = {
  type: 'northArrow',
  x: 730,
  y: 550,
  borderWidth: 0,
  borderColor: '#000000',
  borderTransparent: false,
  width: 50,
  height: 50,
}

export const SCALE_BAR = {
  type: 'scaleBar',
  text: '',
  x: 350,
  y: 550,
  width: 110,
  height: 20,
  borderWidth: 0,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  fontSize: 12,
  fontFamily: 'Montserrat',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  textAlign: 'left',
  padding: 5,
}

export const LEGEND_ELEMENT = {
  type: 'legend',
  x: 10,
  y: 10,
  borderWidth: 1,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  padding: 0,
}

export const PDF_STYLE_SHEET = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Gilroy-Light',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Gilroy-Light',
  },

  image: {
    marginVertical: 1,
    marginHorizontal: 1,
    width: '300pt',
    height: '300pt',
    position: 'absolute',
  },
  imageWrapper: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  map: {
    position: 'absolute',
  },
})
