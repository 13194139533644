import { CREATE_POPUP, SET_POPUP_DOCKED } from '../actions/types'

export default function PopupReducer(state = null, action) {
  switch (action.type) {
    case CREATE_POPUP: {
      return action.payload
    }
    default:
      return state
  }
}

export function DockPopupReducer(state = false, action) {
  switch (action.type) {
    case SET_POPUP_DOCKED: {
      return action.payload
    }
    default:
      return state
  }
}
