import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import ZoomToBounds from '../../../../components/ZoomToBounds/ZoomToBounds'
import {
  createPointFeature,
  addFeatureToSource,
  getSource,
} from '../../../../utilities/mapStyle'
import { updateMapStyle } from '../../../../actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './GeoCode.scss'
import { fromJS } from 'immutable'

class GeoCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      searching: false,
      zoomToBoundsKey: 'bounds',
      zoomToBounds: null,
    }
  }

  handleChange = address => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.props.enableMap
    this.setState({ address, searching: true })
    geocodeByAddress(address)
      .then(results => this.setResultToState(results))
      .then(results => this.doZoomToBounds(results))
      .then(results => getLatLng(results[0]))
      .then(latLng => this.updateGeoCodeLayer(latLng))
      .then(() => this.setState({ searching: false }))
      .then(() => this.props.enableMap())
      .catch(error => console.error('Error', error))
  }

  setResultToState = results => {
    this.setState({ result: results[0] })
    return results
  }

  doZoomToBounds = results => {
    let bounds = null
    // The result may contain a bounds object or a viewport object
    if (results[0].geometry.bounds) {
      bounds = this.formatBounds(results[0].geometry.bounds)
    }
    if (results[0].geometry.viewport) {
      bounds = this.formatBounds(results[0].geometry.viewport)
    }

    let { zoomToIndex } = this.state
    zoomToIndex++
    const zoomToBoundsKey = JSON.stringify(bounds) + zoomToIndex
    const zoomToBounds = bounds
    this.setState({
      zoomToBoundsKey,
      zoomToBounds,
      zoomToIndex,
    })
    // return results back to promise chain
    return results
  }

  formatBounds = bounds => {
    /*
    we need to format the bounds returned from the google api to work with the WebMercatorViewport
      google bounds are formatted as:
        bounds: {
          pa: {
            g: 39.614431
            h: 39.91424689999999
          }
          ka: {
            g: -105.109927
            h: -104.6002959
          }
        }

      we need to convert to:
      const formattedBounds = [
        [northeast.lng, northeast.lat],
        [southwest.lng, southwest.lat]
      ];
    */

    let formatted = []
    let lats = []
    let lngs = []
    for (let key in bounds) {
      if (bounds.hasOwnProperty(key)) {
        Object.values(bounds[key]).forEach(value => {
          if (lats.length < 2) {
            lats.push(value)
          } else {
            lngs.push(value)
          }
        })
      }
    }
    let northEast = [
      parseFloat(lngs[0].toFixed(6)),
      parseFloat(lats[0].toFixed(6)),
    ]
    let southWest = [
      parseFloat(lngs[1].toFixed(6)),
      parseFloat(lats[1].toFixed(6)),
    ]
    formatted.push(northEast)
    formatted.push(southWest)
    return formatted
  }

  updateGeoCodeLayer = latLng => {
    const { result } = this.state
    const { mapStyle } = this.props
    let style = mapStyle.toJS()
    let source = getSource(style, 'geocode_results')
    const lng = latLng.lng
    const lat = latLng.lat
    const coordinates = [lng, lat]
    const properties = {
      id: result.place_id,
      location: result.formatted_address,
    }

    let feature = createPointFeature(coordinates, properties)
    // check if feature exists in source, if it does dont add the feature (this would result in douplicates)
    let check = source.data.features.filter(
      sourceFeature => sourceFeature.properties.id === feature.properties.id
    )
    if (!check.length) {
      source = addFeatureToSource(source, feature)
      style.sources['geocode_results'] = source
      this.props.updateMapStyle(fromJS(style))
    }
  }

  render() {
    const { zoomToBoundsKey, zoomToBounds, searching } = this.state
    const { viewport } = this.props
    const inputStyle = {
      border: 'none',
      outline: 'none',
      borderRadius: '4px',
      width: '100%',
      height: '40px',
      padding: '0 12px',
      fontSize: '16px',
      letterSpacing: '-0.43px',
      color: '#fff',
      textAlign: 'left',
      /*height: "30px"*/
    }

    const searchIcon = searching ? (
      <FontAwesomeIcon icon={'spinner'} size='1x' spin />
    ) : (
      <FontAwesomeIcon icon={['fas', 'search-location']} size='1x' />
    )
    return (
      <div>
        <ZoomToBounds
          key={zoomToBoundsKey}
          viewport={viewport}
          bounds={zoomToBounds}
        />
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={scss.geoCodeWrapper}>
              <div className={scss.addOnGroup}>
                <input
                  style={inputStyle}
                  {...getInputProps({
                    placeholder: 'Enter address...',
                    className: 'location-search-input',
                  })}
                />
                <button className={scss.geocodeBtn}> {searchIcon}</button>
              </div>

              <div
                className='autocomplete-dropdown-container'
                onMouseEnter={this.props.disableMap}
                onMouseLeave={this.props.enableMap}
              >
                {loading && (
                  <div className={scss.autoCompleteLoading}>Loading...</div>
                )}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '2px',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '2px',
                      }
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span className={scss.suggestionSpan}>
                        <FontAwesomeIcon
                          icon={['fal', 'map-marker']}
                          size='1x'
                        />
                        {suggestion.description}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(GeoCode)
